import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IUserMockTest } from '@quesmed/types-rn/models';

import { useBuildMockTestMutation, useMockTestsQuery } from './hooks';
import MockTestsTimerModal from './MockTestsTimerModal';
import { PreExaminationGuidance } from './PreExaminationGuidance';
import { useMockTestStateSetters } from './mockTestsState';
import { Button } from 'components/Button';
import { ArrowRightIcon } from 'components/Icons';

const MockTestsQuizIntro = (): JSX.Element => {
  const { mockTestId = '' } = useParams<{ mockTestId: string }>();
  const { mockTests } = useMockTestsQuery();
  const [showMockTestModal, setShowMockTestModal] = useState(false);
  const { setRightFooterControls, resetState } = useMockTestStateSetters();

  const { buildMockTest, loading: buildLoading } = useBuildMockTestMutation(
    mockTestId ? Number(mockTestId) : null
  );

  const handleBuildMockTest = (hours: number, minutes: number) =>
    buildMockTest(hours, minutes);

  const handleCloseMockTestModal = () => setShowMockTestModal(false);

  const selectedTest =
    mockTests.find(({ id }) => Number(id) === Number(mockTestId)) ||
    ({} as IUserMockTest);

  const { intro } = selectedTest;

  const controls = useMemo(
    () => [
      <Button
        contrast
        key={1}
        onClick={() => setShowMockTestModal(true)}
        variant="text"
      >
        next
        <ArrowRightIcon />
      </Button>,
    ],
    []
  );

  useEffect(() => {
    resetState();
    setRightFooterControls(controls);
  }, [controls, setRightFooterControls, resetState]);

  return (
    <>
      <PreExaminationGuidance guidance={intro} />
      <MockTestsTimerModal
        loading={buildLoading}
        onBuildTest={handleBuildMockTest}
        onClose={handleCloseMockTestModal}
        open={showMockTestModal}
      />
    </>
  );
};

export default MockTestsQuizIntro;
