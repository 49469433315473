import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardKey } from 'types';
import { ExerciseLaunchedButton } from 'components/ExerciseLaunchedButton';
import { useIsMobile } from 'hooks';
import ExerciseSummaryExportButton from './ExerciseSummaryExportButton';

const Container = styled(Box)(
  ({ theme: { spacing, breakpoints, palette, mixins } }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: spacing(2),
    position: 'fixed',
    maxHeight: `${mixins.bottombar.maxHeight}px`,
    bottom: 0,
    left: 0,
    backgroundColor: palette.background.paper,
    padding: spacing(4),
    border: `1px solid ${palette.stroke.main}`,

    [breakpoints.up('md')]: {
      position: 'relative',
      border: 'none',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      padding: 0,
      gap: spacing(6),
    },
  })
);

export interface ExerciseSummaryActionsProps {
  backToLobby?: () => void;
  backToLobbyLabel?: string;
  backToLobbyLoading?: boolean;
  showExport?: boolean;
}

const ExerciseSummaryActions = ({
  backToLobby,
  backToLobbyLabel = 'Back To Lobby',
  backToLobbyLoading,
  showExport,
}: ExerciseSummaryActionsProps): JSX.Element => {
  const { isMobile } = useIsMobile();

  useHotkeys(
    KeyboardKey.Space,
    e => {
      e.preventDefault();

      if (backToLobby) {
        backToLobby();
      }
    },
    {},
    [backToLobby]
  );

  return (
    <Container>
      {showExport && isMobile ? <ExerciseSummaryExportButton /> : null}
      {backToLobby ? (
        <ExerciseLaunchedButton
          loading={backToLobbyLoading}
          onClick={backToLobby}
          size="medium"
        >
          {backToLobbyLabel}
        </ExerciseLaunchedButton>
      ) : null}
    </Container>
  );
};

export default ExerciseSummaryActions;
