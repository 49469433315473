import React, { Fragment } from 'react';
import { Box } from '@mui/material';

import {
  Footer,
  RightFooterControls,
  ToolsDivider,
} from './MockTestsLayoutStyles';
import { useMockTestStateValues } from '../mockTestsState';

export const MockTestsFooter = () => {
  const { leftFooterControls, rightFooterControls } = useMockTestStateValues();

  return (
    <Footer>
      {leftFooterControls ? (
        <Box>
          {leftFooterControls?.map((control, index) => (
            <Fragment key={index}>
              {control}
              {index < leftFooterControls.length - 1 && (
                <ToolsDivider orientation="vertical" />
              )}
            </Fragment>
          ))}
        </Box>
      ) : null}
      {rightFooterControls ? (
        <RightFooterControls>
          {rightFooterControls?.map((control, index) => (
            <Fragment key={index}>
              {control}
              {index < rightFooterControls.length - 1 && (
                <ToolsDivider orientation="vertical" />
              )}
            </Fragment>
          ))}
        </RightFooterControls>
      ) : null}
    </Footer>
  );
};
