import {
  IMarksheet,
  IOsceMarksheet,
  IOsceStation,
  IQuestion,
  IQuestionEMQ,
  IQuestionQA,
  IQuestionRanking,
  IQuestionSelect3,
  ITodo,
  ITopic,
} from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import {
  ActivityData,
  Nilable,
  QuestionsActivity,
  StationsActivity,
} from 'types/general';

type Data<T = {}> = Nilable<T>;
type Collection<T = {}> = Nilable<T[]>;

export const checkOwnProperty = (data: Data, property: string) =>
  Object.prototype.hasOwnProperty.call(data, property);

export const isFlashcard = (data: Data): data is ITodo =>
  !isNil(data) && checkOwnProperty(data, 'dailyTask');

export const isFlashcards = (data?: Collection): data is ITodo[] =>
  Array.isArray(data) && isFlashcard(data[0]);

export const isTopic = (data: Data): data is ITopic =>
  !isNil(data) && checkOwnProperty(data, 'concepts');

export const isTopics = (data: Collection): data is ITopic[] =>
  Array.isArray(data) && isTopic(data[0]);

export const isStation = (data: Data): data is IOsceStation =>
  !isNil(data) && checkOwnProperty(data, 'candidateBrief');

export const isStations = (data: Collection): data is IOsceStation[] =>
  Array.isArray(data) && isStation(data[0]);

export const isOsceMarksheet = (data: Data): data is IOsceMarksheet =>
  !isNil(data) && checkOwnProperty(data, 'osceStationId');

export const isOsceMarksheets = (data: Collection): data is IOsceMarksheet[] =>
  Array.isArray(data) && isOsceMarksheet(data[0]);

export const isMarksheet = (data?: Data): data is IMarksheet =>
  !isNil(data) && checkOwnProperty(data, 'isTestMarksheet');

export const isMarksheets = (data: Collection): data is IMarksheet[] =>
  Array.isArray(data) && isMarksheet(data[0]);

export const isSelect3AnsweresQuestion = (
  question: IQuestion
): question is IQuestionSelect3 =>
  !isNil(question) && checkOwnProperty(question, 'select3Answer');

export const isRankingQuestion = (
  question: IQuestion
): question is IQuestionRanking =>
  !isNil(question) && checkOwnProperty(question, 'rankingAnswer');

export const isQuestionQA = (question: IQuestion): question is IQuestionQA =>
  !isNil(question) && checkOwnProperty(question, 'qaAnswer');

export const isEMQuestion = (question: IQuestion): question is IQuestionEMQ =>
  !isNil(question) && checkOwnProperty(question, 'emqAnswer');

export const isQuestionActivity = (
  data: ActivityData
): data is QuestionsActivity =>
  Array.isArray(data) && data.some(({ result }) => isMarksheet(result[0]));

export const isStationActivity = (
  data: ActivityData
): data is StationsActivity =>
  Array.isArray(data) && data.some(({ result }) => isOsceMarksheet(result[0]));
