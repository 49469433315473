import React from 'react';

import { TableData } from 'types';
import { GridRow } from 'components/GridRow';
import { GridCell } from 'components/GridCell';
import { setSummaryColumnTemplate } from './setSummaryColumnTemplate';
import { SummaryColumn, SummaryTableKey } from './types';
import ExerciseSummaryTableCell from './ExerciseSummaryTableCell';

interface ExerciseSummaryTableRowProps<T extends TableData> {
  columns: SummaryColumn<T>[];
  item: T;
  number: number;
  onClick?: (item: T) => void;
  withoutOffset?: boolean;
}

function ExerciseSummaryTableRow<T extends TableData>({
  columns,
  item,
  number,
  onClick,
  withoutOffset,
}: ExerciseSummaryTableRowProps<T>): JSX.Element {
  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <GridRow
      className={withoutOffset ? '' : 'with-offset'}
      columns={columns}
      hover={Boolean(onClick)}
      onClick={handleClick}
      setColumnTemplate={setSummaryColumnTemplate}
    >
      {columns.map(({ align, key, type, ...rest }) => (
        <GridCell
          align={align}
          className={key === SummaryTableKey.Number ? 'nowrap' : ''}
          key={key as string}
          type={type}
        >
          <ExerciseSummaryTableCell
            columnKey={key}
            item={item}
            key={key}
            number={number}
            type={type}
            {...rest}
          />
        </GridCell>
      ))}
    </GridRow>
  );
}

export default ExerciseSummaryTableRow;
