import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EAppType } from '@quesmed/types-rn/models';
import Skeleton from '@mui/material/Skeleton';
import { Helmet } from 'react-helmet';

import { Body, H4 } from 'components/Typography';
import { APPS } from 'types';
import { CreditCardOutlineIcon } from 'components/Icons';
import { Button } from 'components/Button';
import { entries } from 'utils';
import AppCard, { CARD_HEIGHT, CARD_WIDTH } from './AppCard';
import { useSettingsModalState } from 'components/Settings';
import { MenuLabel } from 'components/Settings/useSettingsModalState';
import { useSubscriptions } from 'hooks';
import { useAuth } from 'Auth';
import { usePlatform } from 'context/PlatformContext';

const Container = styled(Box)(
  ({ theme: { mixins, spacing, breakpoints } }) => ({
    maxWidth: `calc(${mixins.container.maxWidth}px + 32px)`,
    width: '100%',
    margin: '0 auto',
    padding: spacing(8, 4),

    [breakpoints.up('md')]: {
      padding: spacing(8),
      maxWidth: '720px',
    },

    [breakpoints.up('xl')]: {
      padding: spacing(8),
      maxWidth: `calc(${mixins.container.maxWidth}px + 64px)`,
    },
  })
);

const Wrapper = styled(Box)(({ theme: { mixins, breakpoints } }) => ({
  overflow: 'auto',
  maxHeight: `calc(100dvh - ${mixins.appBar.mobile.minHeight}px)`,

  [breakpoints.up('md')]: {
    maxHeight: `calc(100dvh - ${mixins.appBar.desktop.minHeight}px)`,
  },
}));

const Header = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  gap: spacing(4),
  marginBottom: spacing(8),

  [breakpoints.up('xl')]: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
}));

const Subheading = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const ManageSubscriptionButton = styled(Button)(
  ({ theme: { breakpoints } }) => ({
    position: 'relative',

    [breakpoints.up('xl')]: {
      position: 'absolute',
      right: 0,
    },
  })
);
const CardsContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: spacing(6),

  [breakpoints.up('xl')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },

  '& .MuiSkeleton-rectangular': {
    width: '100%',
    borderRadius: '9px',

    [breakpoints.up('md')]: {
      maxWidth: `${CARD_WIDTH}px`,
      minHeight: `${CARD_HEIGHT}px`,
    },
  },
}));

const AppsOverview = (): JSX.Element => {
  const { setIsSettingsOpen, setActiveMenuItem } = useSettingsModalState();
  const { uniqueProductTypes, loading } = useSubscriptions();
  const { isDemo, setIsDemo } = useAuth();
  const { clearState } = usePlatform();
  const wrapperRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (isDemo && !loading) {
      clearState();
      setIsDemo(false);
    }
  }, [clearState, isDemo, loading, setIsDemo]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo({ top: 0 });
    }
  }, []);

  const handleOpenSubscriptionModal = () => {
    setIsSettingsOpen(true);
    setActiveMenuItem(MenuLabel.Subscriptions);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Helmet>
        <title>Quesmed</title>
      </Helmet>
      <Container>
        <Header>
          <H4>Apps management</H4>
          <Subheading bold>
            This is a hub of all things Quesmed. You’ll find all available apps
            and products that we offer.
          </Subheading>
          <ManageSubscriptionButton
            color="secondary"
            onClick={handleOpenSubscriptionModal}
            secondary
          >
            Manage Subscriptions
            <CreditCardOutlineIcon />
          </ManageSubscriptionButton>
        </Header>
        <CardsContainer>
          {loading ? (
            Array.from({ length: 3 }).map((_, i) => (
              <Skeleton height={650} key={i} variant="rectangular" />
            ))
          ) : (
            <>
              {entries(APPS).map(([key, { products, active }]) =>
                active ? (
                  <AppCard
                    appType={Number(key) as EAppType}
                    key={key}
                    products={products}
                    uniqueProductTypes={uniqueProductTypes}
                  />
                ) : null
              )}
            </>
          )}
        </CardsContainer>
      </Container>
    </Wrapper>
  );
};

export default AppsOverview;
