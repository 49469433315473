import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const TableContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    borderRadius: '0 0 7px 7px',
    overflow: 'auto',
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    margin: spacing(0, -6),
    width: `calc(100% + ${spacing(12)})`,

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
    },

    [breakpoints.up('xl')]: {
      overflow: 'hidden',
    },
  })
);

export const TableContentWrapper = styled(Box)(
  ({ theme: { breakpoints, mixins } }) => ({
    width: '100%',
    minWidth: `${mixins.selectionTable.minWidth}px`,
    overflow: 'auto',

    '&.widget': {
      height: '490px',

      [breakpoints.up('md')]: {
        height: '550px',
      },
    },

    [breakpoints.up('xl')]: {
      minWidth: 'initial',
    },

    '&.sticky-header > :first-of-type': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  })
);

export const SkeletonContainer = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
}));

export const Fallback = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '240px',
});
