import React, { useEffect } from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';
import { EOsceMarksheetState } from '@quesmed/types-rn/models';
import { useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from 'components/CircularProgress';
import { useStationGroupStudyData } from './StationsGroupStudyController';
import { GroupStudyGuard } from 'components/GroupStudyGuard';
import { ExerciseLayout } from 'components/Layout';
import { ParticipantList } from 'components/ParticipantCard';
import { useIsMobile, useJoinSearchParam, useLeaveExerciseState } from 'hooks';
import { useCurrentUser } from 'Auth';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { H5, H6 } from 'components/Typography';
import { Button } from 'components/Button';
import ReadyToJoinModal from './ReadyToJoinModal';
import { paths } from 'Router';
import { useAgoraAPI } from 'components/Agora';

const { stations } = paths;
const { root, groupLobby } = stations;

const Container = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar, content },
      spacing,
    },
  }) => ({
    width: '100%',
    maxWidth: content.maxWidth,
    margin: '0 auto',
    height: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: spacing(4),

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
    },
  })
);

const StyledH6 = styled(H6)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
  marginBottom: spacing(8),
}));

const StyledButton = styled(Button)({
  width: '100%',
  maxWidth: '380px',
});

type GroupStudyRouteParams = {
  sessionId?: string;
  osceMarksheetId?: string;
};

const StationsGroupStudyWaitingRoom = () => {
  const { loading, participants, osceMarksheet } = useStationGroupStudyData();
  const { id: osceMarksheetId, state } = osceMarksheet || {};
  const { isMobile } = useIsMobile();
  const { id: currentUserId } = useCurrentUser();
  const { openLeaveModal } = useLeaveExerciseState();
  const navigate = useNavigate();
  const joinSessionURL = useJoinSearchParam();
  const { sessionId: sessionIdParam } = useParams<GroupStudyRouteParams>();
  const { disconnect } = useAgoraAPI();
  const sessionId = sessionIdParam || joinSessionURL || '';

  const handleEnterRoom = () => {
    navigate(`${root}/${groupLobby}/study/${sessionId}/${osceMarksheetId}`);
  };

  const disabled = osceMarksheet.state !== EOsceMarksheetState.STATION;

  const { id: participantId, displayName } =
    participants?.find(participant => participant.id === currentUserId) || {};

  useEffect(() => {
    disconnect();
  }, [disconnect]);

  if (loading) {
    return <CircularProgress description="Loading..." />;
  }

  return (
    <>
      <GroupStudyGuard checkVideo noLoading permissionOptional />
      <ExerciseLayout
        bottomOffsetVariant={isMobile ? 'medium' : 'tiny'}
        controlPanelContent={
          <ParticipantList
            currentUserId={Number(currentUserId)}
            isCandidate
            osceMarksheetId={osceMarksheetId}
            participants={participants}
            state={state}
            variant="panel"
            withAudio
            withRoleHeader
            withVideo
          />
        }
        exerciseContent={
          <Container>
            <H5>Waiting to be invited into the room. Please stand by.</H5>
            <StyledH6>
              To simulate a real OSCE environment, you will stand outside the
              audio channel and wait for the examiner to prepare their
              materials. Please wait for Examiner to finish getting ready.
            </StyledH6>
            <StyledButton disabled={disabled} onClick={handleEnterRoom}>
              Enter the room
            </StyledButton>
          </Container>
        }
        exerciseItemsCount={participants.length}
        onLeave={openLeaveModal}
        {...PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS}
        withCamera
        withSound
      />
      <ReadyToJoinModal
        connected
        displayName={displayName}
        loading={loading}
        participantId={participantId}
      />
    </>
  );
};

export default StationsGroupStudyWaitingRoom;
