import React, { ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';

import { CheckboxState, TableData } from 'types';
import { ClickHandler, SelectionColumn } from './types';
import { GridCell } from 'components/GridCell';
import { GridRow } from 'components/GridRow';
import SelectionCellContent from './SelectionCellContent';
import setSelectionColumnTemplate from './setSelectionColumnTemplate';

type InputOnChangeHandler<T = HTMLInputElement | HTMLTextAreaElement> = (
  event: ChangeEvent<T> | React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;
interface SelectionRowProps<T extends TableData> {
  checked?: boolean;
  checkedState?: CheckboxState;
  columns: SelectionColumn<T>[];
  data: T;
  handleOpenModal?: ClickHandler;
  hover?: boolean;
  globalLock?: boolean;
  locked?: boolean;
  isParent?: boolean;
  onClick?: () => void;
  onSelect?: InputOnChangeHandler;
  parentId?: number;
  checkedIcon?: ReactNode;
  icon?: ReactNode;
  withOffset?: boolean;
}

function SelectionRow<T extends TableData>({
  checkedIcon,
  icon,
  checkedState,
  checked,
  columns,
  handleOpenModal,
  hover,
  isParent = false,
  locked,
  data,
  parentId,
  onClick,
  onSelect,
  withOffset,
}: SelectionRowProps<T>): JSX.Element {
  return (
    <GridRow
      className={clsx({ 'with-offset': withOffset, locked })}
      columns={columns}
      hover={hover}
      nested={!isParent}
      onClick={onClick}
      setColumnTemplate={setSelectionColumnTemplate}
    >
      {columns.map(column => {
        const { key, align, type } = column;

        return (
          <GridCell align={align} key={key as string} type={type}>
            <SelectionCellContent
              checked={checked}
              checkedIcon={checkedIcon}
              checkedState={checkedState}
              currentColumn={column}
              data={data}
              handleOpenModal={handleOpenModal}
              icon={icon}
              locked={locked}
              nested={!isParent}
              onChange={onSelect}
              parentId={parentId}
            />
          </GridCell>
        );
      })}
    </GridRow>
  );
}

export default SelectionRow;
