import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IConcept } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbsProps,
  ReadingContentContainer,
} from 'components/LearningMaterials';
import { WithDemo } from 'types';
import { SkeletonList } from 'components/Skeleton';
import { Lightbox } from 'components/Lightbox';
import { StationsTimerModal } from 'pages/Stations';
import { useDemo } from 'Auth';
import { DemoGuard } from 'components/Demo';
import QbankConceptReading from '../qbank/QbankConceptReading';
import { useUpsertChapterNote } from '../hooks';
import { useUserSettings } from 'hooks';

interface PacesKnowledgeLibraryReadingProps {
  loading: boolean;
  concept?: IConcept;
  activeCategoryLabel: string;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
}

const PacesKnowledgeLibraryReading = ({
  breadcrumbs,
  loading,
  concept,
}: PacesKnowledgeLibraryReadingProps): JSX.Element => {
  const isDemo = useDemo();
  const { chapterId: conceptId, entitlementId } =
    useParams<KnowledgeLibraryRouterParams>();
  const { expandedReading } = useUserSettings();

  const {
    chapter,
    name = '',
    videos,
    status,
    demo: useInDemo = true,
    id,
    userNote,
  } = (concept || {}) as WithDemo<IConcept>;
  const { explanation, pictures } = chapter || {};

  const upsertNote = useUpsertChapterNote();

  const handleSaveNotes = (value: string) => {
    upsertNote(value, Number(conceptId || id));
  };

  const locked = isDemo && !useInDemo;

  const [showStationsPrebuildModal, setShowStationsPrebuildModal] =
    useState(false);

  const handleCloseStationsPrebuildModal = () =>
    setShowStationsPrebuildModal(false);

  return (
    <>
      <ReadingContentContainer>
        {loading ? (
          <SkeletonList height={128} spacing={4} />
        ) : (
          <>
            {locked ? (
              <DemoGuard contentName="Station" />
            ) : (
              <QbankConceptReading
                autoFocus
                breadcrumbs={breadcrumbs}
                conceptId={Number(conceptId || concept?.id)}
                conceptName={name}
                entitlementId={Number(entitlementId)}
                expandedReading={expandedReading}
                explanation={explanation}
                hideExerciseInvitation
                hideFlashcardInvitation
                onSaveNotes={handleSaveNotes}
                pictures={pictures}
                status={status}
                userNote={userNote}
                videos={videos}
              />
            )}
          </>
        )}
      </ReadingContentContainer>
      <Lightbox pictures={pictures} />
      {showStationsPrebuildModal ? (
        <StationsTimerModal
          onClose={handleCloseStationsPrebuildModal}
          open={showStationsPrebuildModal}
          solo
          stationId={Number(conceptId || concept?.id)}
        />
      ) : null}
    </>
  );
};

export default PacesKnowledgeLibraryReading;
