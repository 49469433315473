import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { constSize } from 'utils';

const StyledPill = styled(Button)<{ scale: number }>(
  ({ scale, theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 2, 2, 4),
    boxSizing: 'border-box',
    borderRadius: '9px',
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    color: palette.stroke.dark,
    textTransform: 'capitalize',
    width: '100%',

    '& .MuiSvgIcon-root': {
      ...constSize('20px'),
    },

    '& .left-side': {
      display: 'flex',
      alignItems: 'center',
      fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
      lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,
    },

    '& .start-adornment': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .start-adornment > svg': {
      color: palette.primary.main,
      marginLeft: '5px',
    },

    '& .start-adornment:focus > svg': {
      color: palette.primary.contrastText,
    },

    '& .end-adornment': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      borderRadius: '8px',

      '& .MuiSvgIcon-root': {
        ...constSize('28px'),
      },
    },

    '&.not-answered, &.answered, &.stations': {
      '& .end-adornment > svg': {
        color: palette.icon.light,
      },
    },

    '&.answered': {
      '& .end-adornment > svg': {
        color: palette.icon.main,
      },
    },

    '&.not-answered:hover, &.answered:hover, &.stations:hover': {
      border: `1px solid ${palette.stroke.dark}`,
      cursor: 'pointer',
    },

    '&.not-answered:focus, &.answered:focus, &.not-answered-active, &.answered-active':
      {
        border: `1px solid ${palette.primary.main}`,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,

        '.MuiSvgIcon-root': {
          color: palette.primary.contrastText,
        },
      },

    '&.correct': {
      color: palette.stroke.dark,

      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.success.dark,
        },
      },
    },

    '&.correct:hover': {
      borderColor: palette.secondary.dark,
    },

    '&.correct:focus, &.correct-active': {
      backgroundColor: palette.success.main,
      color: palette.primary.contrastText,
      border: `1px solid ${palette.success.dark}`,

      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.primary.contrastText,
        },
      },
    },

    '&.moderately': {
      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.warning.dark,
        },
      },
    },

    '&.moderately:hover': {
      borderColor: palette.secondary.dark,
    },

    '&.moderately:focus, &.moderately-active': {
      color: palette.primary.contrastText,
      backgroundColor: palette.warning.main,
      border: `1px solid ${palette.warning.main}`,

      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.primary.contrastText,
        },
      },
    },

    '&.incorrect': {
      color: palette.stroke.dark,

      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.error.main,
        },
      },
    },

    '&.incorrect:hover': {
      borderColor: palette.secondary.dark,
    },

    '&.incorrect:focus, &.incorrect-active': {
      color: palette.primary.contrastText,
      backgroundColor: palette.error.main,
      border: `1px solid ${palette.error.main}`,

      '.end-adornment': {
        '.MuiSvgIcon-root': {
          color: palette.primary.contrastText,
        },
      },
    },

    '&.test-mode': {
      color: palette.stroke.dark,
      backgroundColor: palette.primary.contrastText,
      border: `1px solid ${palette.stroke.main}`,

      '& .end-adornment > svg': {
        color: palette.icon.main,
      },
    },

    '&.test-mode:hover': {
      borderColor: palette.secondary.dark,
    },

    '&.test-mode:focus, &.test-mode-active': {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,

      '.MuiSvgIcon-root': {
        color: palette.primary.contrastText,
      },
    },

    '&.stations:focus, &.stations-active': {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,

      '.MuiSvgIcon-root': {
        color: palette.primary.contrastText,
      },
    },
  })
);

export default StyledPill;
