import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SkeletonList } from 'components/Skeleton';
import { navigate } from 'Router/Router';
import { switchPath } from './utils';

const ItemsContainer = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  paddingBottom: spacing(6),
  overflowY: 'auto',
}));

const RedirectPresetLink = () => {
  const { pathname } = useLocation();
  const { productType, presetId } = useParams();
  const pathnameSections = pathname.split('/');
  const [, section] = pathnameSections;

  useEffect(() => {
    navigate(switchPath(section), {
      state: {
        productType: productType,
        presetId: presetId,
        isInitial: true,
      },
    });
  }, [presetId, productType, section]);

  return (
    <>
      <Skeleton height={80} variant="rectangular" />
      <ItemsContainer>
        <SkeletonList count={10} height={48} spacing={4} />
      </ItemsContainer>
    </>
  );
};

export default RedirectPresetLink;
