import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { EProductType } from '@quesmed/types-rn/models';

import { Button } from 'components/Button';
import { ProfileIcon, SchoolIcon } from 'components/Icons';
import { Tabs } from './types';
import { usePlatform } from 'context/PlatformContext';
import { Nullable } from 'types';

const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  padding: spacing(0, 0, 6),
}));

const TabButton = styled(Button)(({ theme: { palette } }) => ({
  '&.MuiLoadingButton-root.MuiButton-textSecondary': {
    '&.static-tab': {
      '&:hover': {
        cursor: 'default',
      },
    },
    '&.row-tab': {
      '&.active': {
        color: palette.primary.main,
        borderBottom: `2px solid ${palette.primary.main}`,
        borderRadius: 0,
      },
      '&:hover': {
        background: 'none',
        color: palette.primary.main,
        cursor: 'pointer',
      },
    },
  },
}));

interface ModeTabs {
  switchTab: (tab: Tabs) => void;
  activeTab: Tabs;
}

interface Tab {
  title: Tabs;
  icon: JSX.Element;
}

const setTabClass = (
  clickedTab: Tabs,
  tab: Tabs,
  product: Nullable<EProductType>
) => {
  if (product !== EProductType.QBANK) {
    return 'static-tab';
  }

  return clickedTab === tab ? 'row-tab active' : 'row-tab';
};

const noPresetTab = [{ title: Tabs.Builder, icon: <SchoolIcon /> }];

const tabs = [...noPresetTab, { title: Tabs.Presets, icon: <ProfileIcon /> }];

const ModeTabs = ({ switchTab, activeTab }: ModeTabs) => {
  const { product } = usePlatform();
  const [tabFields, setTabFields] = useState<Tab[]>([]);

  const handleSwitchTab = (tab: Tabs) => () => {
    switchTab(tab);
  };

  useEffect(() => {
    setTabFields(product !== EProductType.QBANK ? noPresetTab : tabs);
  }, [product]);

  return (
    <Wrapper>
      {tabFields.map((tab: Tab) => (
        <TabButton
          className={setTabClass(activeTab, tab.title, product)}
          color="secondary"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          key={tab.title}
          onClick={handleSwitchTab(tab.title)}
          variant="text"
        >
          {tab.icon} {tab.title}
        </TabButton>
      ))}
    </Wrapper>
  );
};

export default ModeTabs;
