import { Nullable, QuestionAnswerStatus } from 'types';

const setAnswerState = (
  isAnswered: boolean,
  isChoiceCorrect: boolean,
  label: string,
  chosenAnswers?: Nullable<string[]>
): QuestionAnswerStatus => {
  if (!isAnswered) {
    return QuestionAnswerStatus.NOT_ANSWERED;
  }

  if (chosenAnswers) {
    if (chosenAnswers.includes(label)) {
      return isChoiceCorrect
        ? QuestionAnswerStatus.VALID
        : QuestionAnswerStatus.INVALID;
    }
  } else if (isChoiceCorrect) {
    return QuestionAnswerStatus.VALID;
  }

  return QuestionAnswerStatus.NOT_ANSWERED;
};

export default setAnswerState;
