import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  IOsceStationsData,
  IOsceStationsVar,
  OSCE_STATIONS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { EProductType } from '@quesmed/types-rn/models';

import { entries } from 'utils';

const useStationsQuery = (solo: boolean) => {
  const { data, loading } = useQuery<IOsceStationsData, IOsceStationsVar>(
    OSCE_STATIONS,
    {
      variables: {
        solo,
      },
    }
  );

  const { osceStations } = data?.restricted || {};

  const categories = useMemo(() => {
    const osceTypes = {} as {
      [key in EProductType]: string;
    };

    osceStations?.forEach(({ entitlement }) => {
      const { id } = entitlement || {};
      if (id) {
        if (!osceTypes[entitlement.id]) {
          osceTypes[entitlement.id] = entitlement.name;
        }
      }
    });

    return entries(osceTypes).map(([key, value]) => ({
      value: key,
      label: value,
    }));

    // const order = isPaces ? PACES_TYPE_ORDER : STATION_TYPE_ORDER;

    // return order.map(category => ({
    //   value: Number(category),
    //   label: osceTypes[category],
    // }));
  }, [osceStations]);

  return { loading, stations: osceStations, categories };
};

export default useStationsQuery;
