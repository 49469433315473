import { IOsceStationMark, IPicture } from '@quesmed/types-rn/models';
import groupBy from 'lodash/groupBy';

import { OsceKnowledgeLibraryStation, StationKey } from '../types';
import { Brief, BriefData } from 'types';
import { entries } from 'utils';

const BRIEF_MAP: { [key: string]: Brief } = {
  candidate: Brief.CANDIDATE,
  actor: Brief.ACTOR,
  patient: Brief.PATIENT,
  examiner: Brief.EXAMINER,
  marks: Brief.MARKSCHEME,
  walkthrough: Brief.WALKTHROUGH,
};

const getKey = (key: string, sufix: string): StationKey =>
  `${key}${sufix}` as StationKey;

const getContentKey = (key: string): StationKey => {
  if (key === 'walkthrough') {
    return 'explanation';
  }
  if (key === 'marks') {
    return key;
  }

  return getKey(key, 'Brief');
};

const groupMarks = (marks?: IOsceStationMark[]) =>
  marks ? groupBy(marks, ({ header }) => header) : null;

const parseOsceReading = (
  station?: OsceKnowledgeLibraryStation,
  isPaces?: boolean
): [BriefData[], Brief[], IPicture[]] => {
  if (!station) {
    return [[], [], []];
  }

  const pictures: IPicture[] = [];
  const briefs: BriefData[] = [];
  const titles: Brief[] = [];

  entries(BRIEF_MAP).forEach(([core, title]) => {
    const key = getContentKey(core);

    if (key === 'marks' && station[key]) {
      titles.push(title);
      briefs.push({
        title,
        marks: groupMarks(station[key]),
        content: null,
      });
    } else if (station[key]) {
      const picturesKey = getKey(core, 'Pictures');
      const briefPictures = (station[picturesKey] ?? []) as IPicture[];

      pictures.push(...briefPictures);
      titles.push(title);
      briefs.push({
        marks: null,
        title: isPaces && title === Brief.ACTOR ? Brief.PATIENT : title,
        content: station[key] as string,
        pictures: briefPictures,
      });
    }
  });

  return [briefs, titles, pictures];
};

export default parseOsceReading;
