import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BadgeContainer = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
    ...typography.body2,

    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
    },

    '&.correct': {
      color: palette.success.main,
      '& .score': {
        borderColor: palette.success.main,
      },
      '& .MuiSvgIcon-root': {
        color: palette.success.main,
      },
    },

    '&.incorrect': {
      '& .MuiSvgIcon-root': {
        color: palette.error.main,
      },
    },

    '&.in-progress': {
      '& .MuiSvgIcon-root': {
        color: palette.primary.main,
      },
    },
  })
);

const Badge = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  padding: spacing(0, 2.5),
  border: `1px solid ${palette.stroke.main}`,
  backgroundColor: palette.background.default,
  height: spacing(7),
}));

export enum BadgeScore {
  NotStarted = 'not-started',
  Correct = 'correct',
  Incorrect = 'incorrect',
  InProgress = 'in-progress',
}

export interface ProgressScoreBadgeProps {
  icon: ReactNode;
  score: number;
  status: BadgeScore;
}

export const ProgressScoreBadge = ({
  icon,
  score,
  status,
}: ProgressScoreBadgeProps) => (
  <BadgeContainer className={status}>
    {icon}
    <Badge className="score">{`${score}%`}</Badge>
  </BadgeContainer>
);
