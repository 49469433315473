import { useCallback, useEffect, useRef, useState } from 'react';

import { StateCallback, StateInput } from 'types';

const useStateCb = <T>(initialState: StateInput<T>): [T, StateCallback<T>] => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef<any>(null);

  const setStateCallback: StateCallback<T> = useCallback((state, callback) => {
    callbackRef.current = callback;
    setState(state);
  }, []);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

export default useStateCb;
