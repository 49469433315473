import { useCallback } from 'react';
import { EQuestionType, IHighlightNode } from '@quesmed/types-rn/models';
import { useApolloClient } from '@apollo/client';
import { handleSampleQuestionHighlights } from '@quesmed/types-rn/resolvers/mutation';

import useAddQuestionHighlightMutation from './useAddQuestionHighlightMutation';

const useQuestionHighlight = (
  marksheetId: number,
  questionId: number,
  questionTypeId: EQuestionType,
  highlights: IHighlightNode[] = [],
  isSample?: boolean
) => {
  const highlightQuestion = useAddQuestionHighlightMutation(marksheetId);
  const client = useApolloClient();

  const handleHighlights = useCallback(
    async (highlights: IHighlightNode[]) => {
      if (isSample) {
        handleSampleQuestionHighlights(
          client,
          highlights,
          questionId,
          questionTypeId
        );
      } else {
        await highlightQuestion(questionId, questionTypeId, highlights);
      }
    },
    [isSample, client, questionId, questionTypeId, highlightQuestion]
  );

  const handleRemoveHighlight = useCallback(
    (highlightIndex: number) => {
      const item = highlights[highlightIndex];
      const newHighlights = item.part
        ? highlights.filter(n => n.part !== item.part)
        : highlights.filter((_, index) => index !== highlightIndex);

      handleHighlights(newHighlights);
    },
    [highlights, handleHighlights]
  );

  const handleAddHighlight = useCallback(
    (newHighlights: IHighlightNode[] | null) => {
      if (newHighlights) {
        handleHighlights(newHighlights);
      }
    },
    [handleHighlights]
  );

  return {
    handleAddHighlight,
    handleRemoveHighlight,
  };
};

export default useQuestionHighlight;
