import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Card } from 'components/Card';

const Container = styled(Card)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(2),
  padding: spacing(4),
  textAlign: 'center',
}));

interface ExerciseDetailCardProps {
  title: string;
  value: string;
}

const Title = styled(Box)(({ theme: { typography, palette } }) => ({
  ...typography.button,
  color: palette.text.disabled,
}));

const Value = styled(Box)(({ theme: { typography, palette } }) => ({
  ...typography.body1Medium,
  color: palette.text.primary,
}));

const ExerciseDetailCard = ({
  title,
  value,
}: ExerciseDetailCardProps): JSX.Element => (
  <Container>
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Container>
);

export default ExerciseDetailCard;
