import React from 'react';

import { CircularProgress } from 'components/CircularProgress';
import { useStationGroupStudyData } from './StationsGroupStudyController';
import StudyBuilder from './StudyBuilder';

const StationsGroupStudyStationBuilder = () => {
  const { osceMarksheet, isExaminer, isActor, isCandidate } =
    useStationGroupStudyData();

  return osceMarksheet ? (
    <StudyBuilder
      isActor={isActor}
      isCandidate={isCandidate}
      isExaminer={isExaminer}
      osceMarksheet={osceMarksheet}
    />
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default StationsGroupStudyStationBuilder;
