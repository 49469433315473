import React from 'react';
import clsx from 'clsx';

import { H6 } from 'components/Typography';
import {
  CardIconContainer,
  ContentContainer,
  Header,
  RootContainer,
  TitleContainer,
} from './WidgetCard.styles';
import { WidgetCardType } from './WidgetCard.types';
import { isValentinesDay } from 'utils';

interface WidgetCardProps {
  cardIcon: JSX.Element;
  content: JSX.Element;
  headerAction?: JSX.Element;
  fullWidth?: boolean;
  loading: boolean;
  loadingSkeleton: JSX.Element;
  title: string;
  type: WidgetCardType;
  overflow?: boolean;
  className?: string;
  heatmapcolourname?: string | null;
}

const WidgetCard = ({
  cardIcon,
  content,
  fullWidth = false,
  headerAction,
  loading,
  loadingSkeleton,
  title,
  type,
  className,
  overflow = false,
  heatmapcolourname,
}: WidgetCardProps) => (
  <RootContainer className={className}>
    <Header>
      <TitleContainer>
        <CardIconContainer
          className={clsx({
            'daily-flashcards': type === WidgetCardType.DailyFlashcards,
            heatmap: type === WidgetCardType.Heatmap,
            heart: isValentinesDay(),
          })}
          heatmapcolourname={heatmapcolourname}
        >
          {cardIcon}
        </CardIconContainer>
        <H6>{title}</H6>
      </TitleContainer>
      {headerAction ? headerAction : null}
    </Header>
    {loading ? (
      loadingSkeleton
    ) : (
      <ContentContainer
        className={clsx({
          fullWidth,
          overflow,
          performance: type === WidgetCardType.Performance,
        })}
      >
        {content}
      </ContentContainer>
    )}
  </RootContainer>
);

export default WidgetCard;
