import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Units } from './PrescriptionAnswer.styles';
import { LockOutlineIcon } from 'components/Icons';

interface PrescriptionFieldEndAdornmentProps {
  locked?: boolean;
  units?: string | number;
  display?: boolean;
}

export const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
  minWidth: 'fit-content',
  paddingRight: '0px',
}));

const PrescriptionFieldEndAdornment = ({
  units,
  locked,
}: PrescriptionFieldEndAdornmentProps) => (
  <Container>
    <Units>{units}</Units> {locked ? <LockOutlineIcon /> : null}
  </Container>
);

export default PrescriptionFieldEndAdornment;
