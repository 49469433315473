import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing(4),
  marginBottom: spacing(-2),

  [breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '& .MuiButton-root': {
      width: '100%',
    },
  },
}));

const Title = styled(Box)(({ theme: { typography, palette } }) => ({
  ...typography.button,
  color: palette.text.disabled,
}));

export interface ExerciseSummaryDetailsTitleProps {
  detailsTitle?: string;
}

const ExerciseSummaryDetailsTitle = ({
  detailsTitle = 'Detailed Information',
}: ExerciseSummaryDetailsTitleProps): JSX.Element => (
  <Container>
    <Title>{detailsTitle}</Title>
  </Container>
);

export default ExerciseSummaryDetailsTitle;
