import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EProductType } from '@quesmed/types-rn/models';
import { useLocation } from 'react-router-dom';
import isNil from 'lodash/isNil';

import {
  BuilderViewMode,
  CheckboxState,
  ExerciseMode,
  ExerciseType,
  ExtendedTopic,
  Nullable,
  QuizTopicType,
  SelectionState,
  ToggleEvent,
  ToggleOptions,
  TopicSelection,
  UIColumn,
} from 'types';
import { SelectionColumn, Tabs } from './types';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import AccountGroupIcon from 'components/Icons/AccountGroupIcon';
import ExerciseBuilder from './ExerciseBuilder';
import { AccountIcon } from 'components/Icons';
import SelectionCateorySwitchModal from './SelectionCateorySwitchModal';
import { BUILDER_VIEWS } from 'constants/general';
import ModeTabs from './ModeTabs';
import TopicPresetsBuilder from 'components/PresetsBuilder/TopicPresetsBuilder';
import { PresetsTopicType, PresetsType } from 'components/PresetsBuilder/types';
import { categoryOptions } from 'components/PresetsBuilder/constants';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import { useDemo } from 'Auth';
import { clearTimeoutSafely } from 'utils';
import { usePrevious } from 'hooks/usePrevious';

export type QuestionColumns = ExtendedTopic & UIColumn;

type LimitedTopicMap = {
  [key in EProductType]: ExtendedTopic[];
};

const getStrictCategories = (typeId: EProductType) => {
  switch (typeId) {
    case 5:
      return EProductType.MEDICAL_SCIENCES;
    case 15:
      return EProductType.CLINICAL;
    case 4:
      return EProductType.ANATOMY;
    case 16:
      return EProductType.DATA_INTERPRETATION;
  }
};

const findCategoryLabels = (
  previousCategory: EProductType | null,
  categories: ToggleOptions<EProductType>,
  activeCategory?: EProductType
): [string, string] => {
  let activeLabel = '';
  let previousLabel = '';

  categories.forEach(({ value, label }) => {
    if (value === activeCategory) {
      activeLabel = label;
    }

    if (value === previousCategory) {
      previousLabel = label;
    }
  });

  return [activeLabel, previousLabel];
};

type ExtendedTopicMap = {
  [key in EProductType]: ExtendedTopic[];
};

const splitPerCategory = (topics: ExtendedTopic[]): LimitedTopicMap =>
  topics.reduce((acc, topic) => {
    const { entitlement } = topic;

    if (entitlement.id === null) {
      return acc;
    }

    if (acc[entitlement.id]) {
      acc[entitlement.id].push(topic);
    } else {
      acc[entitlement.id] = [topic];
    }

    return acc;
  }, {} as ExtendedTopicMap);

interface TempSelection {
  itemId: Nullable<number>;
  subItemId: Nullable<number>;
  all: boolean;
}

interface TopicQuizBuilderProps extends TopicSelection {
  activeCategory?: EProductType;
  categories: ToggleOptions<EProductType>;
  detailsColumns: SelectionColumn<QuestionColumns>[];
  exerciseType: ExerciseType;
  header?: string;
  solo?: boolean;
  loading: boolean;
  mlaColumns?: boolean;
  mlaConditionColumns?: SelectionColumn<QuestionColumns>[];
  mlaPatientPresentationColumns?: SelectionColumn<QuestionColumns>[];
  nestedItemsKey: keyof ExtendedTopic;
  overviewColumns: SelectionColumn<QuestionColumns>[];
  searchLabel: string;
  selectedItemsInfo: string;
  topics: Nullable<ExtendedTopic[]>;
  displayTopics: Nullable<ExtendedTopic[]>;
  setActiveTopics: (topics: ExtendedTopic[]) => void;
  setDisplayTopics: (topics: Nullable<ExtendedTopic[]>) => void;
}

const TopicQuizBuilder = ({
  activeCategory: sourceActiveCategory,
  allSelected,
  categories,
  changeConceptState,
  setActiveTopics,
  changeTopicState,
  deselectAll,
  detailsColumns,
  exerciseType,
  header,
  solo,
  loading,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  nestedItemsKey,
  overviewColumns,
  searchLabel,
  selectAll,
  setSelectionState,
  setDisplayTopics,
  selectedItemsInfo,
  selectionState,
  displayTopics,
  topics,
}: TopicQuizBuilderProps): JSX.Element => {
  // "tempSelection" is saved when user selects something after category was changed
  // to set new selection after they confirm their action in a modal
  const [tempSelection, setTempSelection] = useState<TempSelection | null>(
    null
  );

  // "categoryOdPreviousSelection" is saved when user changes category with already
  // existing selection to show confirmation modal, when starting new selection
  // within the new active category.
  const [categoryOfPreviousSelection, setCategoryOldPreviousSelection] =
    useState<Nullable<EProductType>>(null);
  const [previousSelection, setPreviousSelection] =
    useState<Nullable<SelectionState>>(null);
  const [activeCategory, setActiveCategory] = useState(sourceActiveCategory);
  const [activeView, setActiveView] = useState(BUILDER_VIEWS[0].value);

  const { preset, isPresetEditOpen, tab, setTab } = usePresetGlobalState();
  const isDemo = useDemo();
  const selectionRestoreTimeout = useRef<number>(0);
  const [previousInfo, setPreviousInfo] = useState(selectedItemsInfo);

  const { state } = useLocation<{
    category: QuizTopicType;
    isInitial: boolean;
  }>();
  const { category, isInitial } = state || {};
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(isInitial);
  const [activePresetsCategory, setActivePresetsCategory] = useState(
    openLinkModal ? PresetsTopicType.CUSTOM : PresetsTopicType.PRE_DEFINED
  );
  const prevActiveCategory = usePrevious(activeCategory);

  useEffect(() => {
    if (isInitial) {
      setTab(Tabs.Presets);
    }
  }, [isInitial, setTab]);

  const topicsByCategory = useMemo(
    () => (topics ? splitPerCategory(topics) : undefined),
    [topics]
  );

  const handleActiveCategory = useCallback(
    (e: SyntheticEvent, type: EProductType | PresetsType) => {
      // Set new category
      // depending on Builder or Presets
      if (tab === Tabs.Builder) {
        setActiveCategory(Number(type));
      } else {
        setActivePresetsCategory(type as PresetsType);
      }

      clearTimeoutSafely(selectionRestoreTimeout.current);

      // set new "categoryOdPreviousSelection" if there is no one and
      // there is already a selection from the previous category
      if (
        allSelected !== CheckboxState.UNCHECKED &&
        categoryOfPreviousSelection === null &&
        !isNil(activeCategory)
      ) {
        setPreviousSelection(selectionState);
        setCategoryOldPreviousSelection(activeCategory);
        setPreviousInfo(selectedItemsInfo);
      }
      // remove "categoryOdPreviousSelection" if user returns to
      // the category of the selection and restore previous selection
      if (type === categoryOfPreviousSelection) {
        setCategoryOldPreviousSelection(null);
        setPreviousInfo('');

        if (previousSelection) {
          // TODO investigate better solution
          // when we switch categories we pass new topics to the useTopicSelection hook
          // so the selection state is cleared. So we save the previous one as a local
          // "previousSelection" to be able to restore it when user is back to this previous
          // selection's category. Because on every cateogry switch the selectionState is
          // created with new topics that are passed so we have to restore previous selection
          // after the topics from the cateogroy are parsed to the new selection state schema.
          // With using useEffect and state setters, the logic was very complex and unstable.
          // It required additional helper states and caused additional re-renders with UI flashing.
          selectionRestoreTimeout.current = window.setTimeout(
            () =>
              setSelectionState(previousSelection, () =>
                setPreviousSelection(null)
              ),
            200
          );
        }
      }
    },
    [
      tab,
      allSelected,
      categoryOfPreviousSelection,
      activeCategory,
      selectionState,
      selectedItemsInfo,
      previousSelection,
      setSelectionState,
    ]
  );

  const [activeCategoryLabel, previousCategoryLabel] = useMemo(
    () =>
      findCategoryLabels(
        categoryOfPreviousSelection,
        categories,
        activeCategory
      ),
    [activeCategory, categoryOfPreviousSelection, categories]
  );

  const handleSelect = useCallback(
    (
      selectionState: SelectionState,
      all: boolean,
      itemId: number | null,
      subItemId: number | null
    ) => {
      // When user select item on new category, their choice is saved
      // as a tempSelection to display confiramtionModal before new
      // selection with new category is created.
      if (categoryOfPreviousSelection !== null) {
        setTempSelection({ all, itemId, subItemId });
      } else {
        if (all) {
          selectAll(selectionState);
        }

        if (subItemId && itemId) {
          changeConceptState(selectionState, Number(itemId), Number(subItemId));
        }

        if (itemId && !subItemId) {
          changeTopicState(selectionState, Number(itemId));
        }

        setTempSelection(null);
        setPreviousSelection(null);
      }
    },
    [
      categoryOfPreviousSelection,
      changeConceptState,
      changeTopicState,
      selectAll,
    ]
  );

  const handleSelectAll = useCallback(
    (selectionState: SelectionState) => () => {
      handleSelect(selectionState, true, null, null);
    },
    [handleSelect]
  );

  const handleSelectRow = useCallback(
    (selectionState: SelectionState) => (itemId: number) => () => {
      handleSelect(selectionState, false, itemId, null);
    },
    [handleSelect]
  );

  const handleSelectSubRow = useCallback(
    (selectionState: SelectionState) =>
      (itemId: number) =>
      (subItemId: number) => {
        handleSelect(selectionState, false, Number(itemId), Number(subItemId));
      },
    [handleSelect]
  );

  const handleSubmit = useCallback(
    (selectionState: SelectionState) => () => {
      // When user confirms their action in a modal the selection action
      // is continued using tempSelection from a state. After that
      // tempSelection and categoryOfPreviousSelectionsSelection are reset.
      if (tempSelection) {
        const { all, itemId, subItemId } = tempSelection;

        // The selection has to be reset as it includes items from the previous category.
        const cleanSelectionState = deselectAll(selectionState);

        if (all) {
          selectAll(cleanSelectionState);
        }

        if (itemId && subItemId) {
          changeConceptState(
            cleanSelectionState,
            Number(itemId),
            Number(subItemId)
          );
        }

        if (itemId && !subItemId) {
          changeTopicState(cleanSelectionState, Number(itemId));
        }

        setTempSelection(null);
        setCategoryOldPreviousSelection(null);
        setPreviousInfo('');
      }
    },
    [
      changeConceptState,
      changeTopicState,
      deselectAll,
      selectAll,
      tempSelection,
    ]
  );

  const handleActiveView = useCallback(
    (e: ToggleEvent, view: BuilderViewMode) => {
      setActiveView(view);
    },
    []
  );

  const handleClose = useCallback(() => {
    setTempSelection(null);
  }, []);

  const handleSearch = useCallback(
    (searchString: string) => {
      // Filters topics base on string build from topics' names and their concepts
      // names "searchNames". After thatc Concepts are filteres if their names don't
      // include search phrase
      const normalizedSearchString = searchString.toLowerCase().trim();
      if (topicsByCategory && searchString && !isNil(activeCategory)) {
        const filtered = topicsByCategory[activeCategory]
          .filter(({ searchNames }) =>
            searchNames.toLowerCase().includes(normalizedSearchString)
          )
          .map(({ concepts, ...rest }) => ({
            ...rest,
            concepts: concepts
              ? concepts.filter(({ name }) =>
                  name.toLowerCase().includes(normalizedSearchString)
                )
              : undefined,
          }));

        setDisplayTopics(filtered);

        return;
      }

      setDisplayTopics(
        topicsByCategory && !isNil(activeCategory)
          ? topicsByCategory[activeCategory]
          : null
      );
    },
    [activeCategory, setDisplayTopics, topicsByCategory]
  );

  useEffect(() => {
    return () => {
      clearTimeoutSafely(selectionRestoreTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (topicsByCategory && !isNil(activeCategory)) {
      const activeTopics = topicsByCategory[activeCategory];
      setDisplayTopics(activeTopics);
      setActiveTopics(activeTopics);
    }
  }, [topicsByCategory, activeCategory, setDisplayTopics, setActiveTopics]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (tab === Tabs.Presets) {
      deselectAll(selectionState);
    }
    // Disabled due to infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, deselectAll]);

  useEffect(() => {
    if (category !== undefined) {
      setActiveCategory(Number(category));
    }
  }, [category]);

  useEffect(() => {
    if (categories?.length && !activeCategory && !category) {
      setActiveCategory(Number(categories[0]?.value));
    }
  }, [activeCategory, categories, category]);

  const columns = useMemo(
    () =>
      activeView === BuilderViewMode.Details ? detailsColumns : overviewColumns,
    [activeView, detailsColumns, overviewColumns]
  );

  const mlaLeftColumn = useMemo(
    () => mlaPatientPresentationColumns,
    [mlaPatientPresentationColumns]
  );

  const mlaRightColumn = useMemo(
    () => mlaConditionColumns,
    [mlaConditionColumns]
  );

  useEffect(() => {
    if (isPresetEditOpen) {
      //TODO: To be adjusted when presets is added to other products.
      setTab(Tabs.Builder);
      setActiveCategory(Number(getStrictCategories(preset.entitlementId)));
    }
  }, [isPresetEditOpen, preset.entitlementId, setTab]);

  const renderSection = () => {
    switch (tab) {
      case Tabs.Builder:
        return (
          <>
            <ExerciseBuilder
              activeCategory={activeCategory}
              allSelected={allSelected}
              categoryOptions={mlaColumns ? [] : categories}
              columns={columns}
              data={displayTopics || []}
              globalLock={isDemo}
              headerLock={isDemo}
              loading={loading}
              mlaColumns={mlaColumns}
              mlaConditionColumns={mlaRightColumn}
              mlaPatientPresentationColumns={mlaLeftColumn}
              nestedItemsKey={nestedItemsKey}
              onSearch={handleSearch}
              onSelectAll={handleSelectAll(selectionState)}
              onSelectRow={handleSelectRow(selectionState)}
              onSelectSubRow={handleSelectSubRow(selectionState)}
              onToggleCategory={handleActiveCategory}
              onToggleView={handleActiveView}
              resetSearch={prevActiveCategory !== activeCategory}
              searchLabel={searchLabel}
              selectedView={activeView}
              selectionState={selectionState}
              title={activeCategoryLabel}
              viewOptions={BUILDER_VIEWS}
            />
            <SelectionCateorySwitchModal
              currentSelectionCategory={previousCategoryLabel}
              newCategory={activeCategoryLabel}
              onClose={handleClose}
              onSubmit={handleSubmit(selectionState)}
              open={Boolean(tempSelection)}
              selectedItemsInfo={previousInfo}
            />
          </>
        );
      case Tabs.Presets:
        return (
          <TopicPresetsBuilder
            activeCategory={activePresetsCategory}
            categoryOptions={categoryOptions}
            onToggleCategory={handleActiveCategory}
            openLinkModal={openLinkModal}
            setOpenLinkModal={setOpenLinkModal}
          />
        );
    }
  };

  return (
    <>
      {isPresetEditOpen ? null : (
        <>
          <ExerciePreBuildHeader
            buttonLabel="load preset"
            exerciseMode={solo ? ExerciseMode.Solo : ExerciseMode.Group}
            exerciseType={exerciseType}
            icon={solo ? <AccountIcon /> : <AccountGroupIcon />}
            mainHeader={header}
          />
          {!mlaColumns && <ModeTabs activeTab={tab} switchTab={setTab} />}
        </>
      )}
      {renderSection()}
    </>
  );
};

export default TopicQuizBuilder;
