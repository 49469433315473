import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { Children } from 'types';

const Row = styled(Box)(
  ({ theme: { palette, spacing, typography, breakpoints, transitions } }) => ({
    display: 'grid',
    minHeight: spacing(15),
    backgroundColor: palette.background.paper,
    padding: spacing(2),
    position: 'relative',
    transition: transitions.create(['background-color']),

    '&.sticky': {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },

    '&.with-offset': {
      padding: spacing(0, 4, 0, 6),
    },

    '&:not(:nth-last-of-type(1)), &.nested': {
      borderBottom: `1px solid ${palette.stroke.main}`,
    },

    '&:not(.nested):nth-last-of-type(1))': {
      borderBottom: `1px solid ${palette.stroke.main}`,
    },

    '&.has-hover:hover': {
      cursor: 'pointer',
      backgroundColor: palette.background.default,
    },

    '&.nested': {
      backgroundColor: palette.background.default,

      '& > *': {
        backgroundColor: palette.background.default,
      },
    },

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      backgroundColor: palette.background.paper,
      overflow: 'hidden',
      ...typography.body1Medium,
      transition: transitions.create(['background-color']),

      '&:not(.chevron)': {
        padding: spacing(0, 4, 0, 0),
      },

      '&:nth-of-type(1):not(.chevron):not(.input)': {
        [breakpoints.up('md')]: {
          padding: spacing(0, 4),
        },
      },

      '&.chevron, &.input:nth-of-type(1)': {
        position: 'sticky',
        left: 0,

        [breakpoints.up('xl')]: {
          left: 'initial',
          position: 'initital',
        },
      },

      '&.chevron': {
        '& .MuiSvgIcon-root path': {
          transition: transitions.create(['color']),
          color: palette.icon.main,
        },
      },

      '&.input:nth-of-type(1)': {
        paddingLeft: spacing(8),
      },

      '&.input:nth-of-type(2)': {
        position: 'sticky',
        left: 56,

        [breakpoints.up('xl')]: {
          left: 'initial',
          position: 'initial',
        },
      },

      '&.left': {
        justifyContent: 'flex-start',
      },

      '&.right': {
        justifyContent: 'flex-end',
      },

      '&.center': {
        justifyContent: 'center',
      },
    },

    '&.has-hover:hover > *': {
      backgroundColor: palette.background.default,

      '&.chevron': {
        '& .MuiSvgIcon-root path': {
          color: palette.icon.dark,
        },
      },
    },

    '&.with-offset > *': {
      '&:nth-of-type(1):not(.chevron):not(.input)': {
        [breakpoints.up('md')]: {
          padding: spacing(0, 4, 0, 0),
        },
      },
    },

    '&.locked': {
      cursor: 'not-allowed',
    },
  })
);

interface GridRowProps<T> {
  columns: T[];
  children: Children;
  onClick?: () => void;
  hover?: boolean;
  nested?: boolean;
  open?: boolean;
  setColumnTemplate: (c: T[]) => string;
  className?: string;
}

export function GridRow<T>({
  children,
  className,
  hover,
  columns,
  nested,
  onClick,
  setColumnTemplate,
}: GridRowProps<T>): JSX.Element {
  const gridTemplateColumns = setColumnTemplate(columns);

  return (
    <Row
      className={clsx(className, {
        nested,
        'has-hover': hover,
      })}
      onClick={onClick}
      sx={{ gridTemplateColumns }}
    >
      {children}
    </Row>
  );
}
