import { IMarksheet, IOsceMarksheet, IPreset } from '@quesmed/types-rn/models';

export enum Tabs {
  History = 'History',
  Flags = 'Flags',
  Tracker = 'Tracker',
}

export type TableResults = IMarksheet | IOsceMarksheet | IPreset;

export type TableRowComponent<T extends IOsceMarksheet | IMarksheet | IPreset> =
  ({ result }: { result: T }) => JSX.Element;
