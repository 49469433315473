import { IQuestionQA } from '@quesmed/types-rn/models';
import React, { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import OpenAnswerExplanation from './OpenAnswerExplanation';
import OpenAnswerSection from './OpenAnswerSection';
import { PrescriptionField } from '../types';
import { parseQaAnswer } from 'utils';

interface OpenAnswerProps {
  question: IQuestionQA;
  isTestFinished: boolean;
  userAnswer: string;
  setUserAnswer: (answer: string) => void;
}

const OpenAnswer = ({
  question,
  isTestFinished,
  userAnswer,
  setUserAnswer,
}: OpenAnswerProps): JSX.Element => {
  const { qaAnswer } = question;
  const [{ units }] = qaAnswer;
  const answer = parseQaAnswer(userAnswer);
  const { reset, control } = useForm<Pick<PrescriptionField, 'dose'>>({
    defaultValues: { dose: answer },
  });

  useEffect(() => {
    if (userAnswer) {
      setUserAnswer(userAnswer);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    reset({
      dose: answer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const formattedValue = [
      value
        .trim()
        .replaceAll(/\s{2,}/g, ' ')
        .toLowerCase(),
    ];
    setUserAnswer(JSON.stringify(formattedValue));
  };

  return isTestFinished ? (
    <OpenAnswerExplanation answer={answer} question={question as IQuestionQA} />
  ) : (
    <OpenAnswerSection
      control={control}
      isTestFinished={isTestFinished}
      onChange={handleChange}
      units={units}
    />
  );
};

export default OpenAnswer;
