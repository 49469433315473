import React from 'react';
import { mdiLightbulbOn20 } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LightbulbOn20Icon = (props: IconProps) => (
  <Icon path={mdiLightbulbOn20} {...props} />
);

export default LightbulbOn20Icon;
