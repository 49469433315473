import React from 'react';
import { Chart, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { EProductType, IUserStats } from '@quesmed/types-rn/models';
import { Skeleton, styled, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { WidgetCard, WidgetCardType } from '../WidgetCard';
import { WebIcon } from 'components/Icons';
import { constSize } from 'utils/index';
import linePlugin from './plugins/LinePlugin';
import pointPlugin from './plugins/PointPlugin';
import { Body } from 'components/Typography';
import getOptions from './helpers/getOptions';
import { useIsMobile } from 'hooks/useDeviceSize';
import { usePlatform } from 'context/PlatformState';

Chart.register(PointElement);
Chart.register(LineElement);

interface PerformanceChartProps {
  data?: IUserStats;
  loading: boolean;
  toggleArgs: () => void;
}

const ButtonWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&.sm': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const StyledBody = styled(Body)({
  width: '170px',
});

const GraphWrapper = styled(Box)({
  height: '200px',
  '&.sm': {
    height: '150px',
  },
});

const LegendWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(8),
  '&.sm': {
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

const Dot = styled(Box)(({ theme: { palette } }) => ({
  ...constSize('12px'),
  display: 'inline-block',
  borderRadius: '6px',

  '&.score': {
    backgroundColor: palette.success.main,
  },

  '&.median': {
    backgroundColor: palette.primary.main,
  },
}));

const PerformanceChart = ({
  data,
  loading,
  toggleArgs,
}: PerformanceChartProps) => {
  const { isMobile } = useIsMobile();
  const { product } = usePlatform();
  const { series = [], median = 0, score = 0, percentile = 0 } = data || {};
  const xValues = series?.map(item => ({ x: item[0] }));
  const theme = useTheme();
  const chartData = {
    labels: xValues,
    datasets: [
      {
        data: series,
        borderColor: theme.palette.image.main,
        backgroundColor: 'transparent',
        radius: 0,
      },
    ],
  };
  const height = isMobile ? 120 : 70;
  if (!data?.series || data.series.length < 1) {
    return null;
  }

  return (
    <WidgetCard
      cardIcon={<WebIcon />}
      content={
        <>
          <GraphWrapper className={isMobile ? 'sm' : ''}>
            <Line
              data={chartData}
              height={height}
              options={getOptions(series, median, isMobile)}
              plugins={[
                linePlugin(series, median, theme),
                pointPlugin(series, score, percentile, theme),
              ]}
            />
          </GraphWrapper>
          <LegendWrapper className={isMobile ? 'sm' : ''}>
            <StyledBody component="span">
              <Dot className="median" /> Median score (percentile)
            </StyledBody>
            <StyledBody component="span">
              <Dot className="score" /> Your score (percentile)
            </StyledBody>
          </LegendWrapper>
        </>
      }
      fullWidth
      headerAction={
        product === EProductType.QBANK ? (
          <ButtonWrapper style={{ alignSelf: 'flex-end' }}>
            <Body component="span">Compare to my year group</Body>{' '}
            <Switch onChange={toggleArgs} />
          </ButtonWrapper>
        ) : undefined
      }
      loading={loading}
      loadingSkeleton={
        <Skeleton height={280} variant="rectangular" width="100%" />
      }
      title="Your Stats"
      type={WidgetCardType.Performance}
    />
  );
};

export default PerformanceChart;
