import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IUpsertStationNoteData,
  IUpsertStationNoteVar,
  optimisticUpsertStationNote,
  updateCacheOnUpsertStationNote,
  UPSERT_STATION_NOTE,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useCurrentUser } from 'Auth';

const useUpsertStationNote = () => {
  const { id: userId } = useCurrentUser();
  const [mutate] = useMutation<IUpsertStationNoteData, IUpsertStationNoteVar>(
    UPSERT_STATION_NOTE,
    {
      update: updateCacheOnUpsertStationNote,
    }
  );

  const upsertNote = useCallback(
    (note: string, stationId: number) => {
      mutate({
        variables: {
          stationId,
          note,
        },
        optimisticResponse: optimisticUpsertStationNote({
          stationId,
          note,
          userId,
        }),
      });
    },
    [mutate, userId]
  );

  return upsertNote;
};

export default useUpsertStationNote;
