import { useInfiniteHits } from 'react-instantsearch';

export const useSearchResults = <T>() => {
  const searchResult = useInfiniteHits();

  const { items, results } = searchResult;
  const { query } = results || {};

  return query && items ? (items as unknown as T) : null;
};
