import { useMutation } from '@apollo/client';
import {
  DUPLICATE_PRESET,
  IDuplicatePresetData,
  IDuplicatePresetVar,
  updateCacheOnDuplicatePreset,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';

const useDuplicatePresetMutation = (presetId: number) => {
  const { enqueueSnackbar } = useSnackbar();

  const [duplicatePreset] = useMutation<
    IDuplicatePresetData,
    IDuplicatePresetVar
  >(DUPLICATE_PRESET, {
    variables: {
      presetId: Number(presetId),
    },
    onCompleted: () => {
      enqueueSnackbar('Preset duplicated successfully!');
    },
    update: updateCacheOnDuplicatePreset(),
  });

  return { duplicatePreset };
};
export default useDuplicatePresetMutation;
