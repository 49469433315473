import { useQuery } from '@apollo/client';
import {
  IOsceMarksheetData,
  IOsceMarksheetVar,
  OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useMarksheetData = (loading: boolean, marksheetId?: number) => {
  const { data, loading: marksheetLoading } = useQuery<
    IOsceMarksheetData,
    IOsceMarksheetVar
  >(OSCE_MARKSHEET, {
    fetchPolicy: 'cache-and-network',
    skip: !marksheetId || loading,
    variables: { id: Number(marksheetId) },
  });

  return {
    marksheetData: data?.restricted.osceMarksheet,
    marksheetLoading,
  };
};

export default useMarksheetData;
