import dayjs from 'dayjs';

export const formatCommentDate = (
  date: Date | string | number,
  locale = 'en-GB'
) => {
  const commentDate = dayjs(date).toDate();

  if (dayjs().isSame(commentDate, 'day')) {
    return dayjs(commentDate).fromNow();
  }

  if (dayjs().isSame(commentDate, 'month')) {
    return new Intl.DateTimeFormat(locale, {
      month: 'long',
      day: 'numeric',
    }).format(commentDate);
  }

  return new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: '2-digit',
  }).format(commentDate);
};
