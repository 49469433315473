import { useCallback } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  CONCEPT_NOTES,
  IConceptNotesData,
  IConceptNotesVar,
} from '@quesmed/types-rn/resolvers/query/restricted';

interface ConceptNotesParams {
  limit?: number;
  offset?: number;
  onAllConceptNotesFetched?: () => void;
}

const DEFAULT_INFINITE_SCROLL_LIMIT = 20;

const useConceptNotesQuery = (params?: ConceptNotesParams) => {
  const {
    limit = DEFAULT_INFINITE_SCROLL_LIMIT,
    offset = 0,
    onAllConceptNotesFetched,
  } = params || {};

  const { data, loading, fetchMore } = useQuery<
    IConceptNotesData,
    IConceptNotesVar
  >(CONCEPT_NOTES, {
    variables: {
      limit,
      offset,
      search: null,
    },
    onCompleted: data =>
      data.restricted.conceptNotes.length < DEFAULT_INFINITE_SCROLL_LIMIT &&
      onAllConceptNotesFetched
        ? onAllConceptNotesFetched()
        : null,
  });

  const loadMoreConceptNotes = useCallback(
    (offset: number) => {
      return fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          const { conceptNotes } = previousResult.restricted;
          const { conceptNotes: moreConcepts } = fetchMoreResult.restricted;

          const mergedData = {
            ...fetchMoreResult,
            restricted: {
              ...fetchMoreResult.restricted,
              conceptNotes: conceptNotes.concat(moreConcepts),
            },
          };

          if (
            moreConcepts.length < DEFAULT_INFINITE_SCROLL_LIMIT &&
            onAllConceptNotesFetched
          ) {
            onAllConceptNotesFetched();
          }

          return mergedData;
        },
      });
    },
    [fetchMore, onAllConceptNotesFetched]
  );

  const [query, { data: searchData, loading: searchLoading }] = useLazyQuery<
    IConceptNotesData,
    IConceptNotesVar
  >(CONCEPT_NOTES, {
    variables: {
      limit,
      offset,
      search: null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const searchConceptNotes = useCallback(
    (search: string) =>
      query({
        variables: {
          search,
          limit,
          offset,
        },
      }),
    [query, limit, offset]
  );

  return {
    notes: data?.restricted.conceptNotes || [],
    loading,
    searchedNotes: searchData?.restricted.conceptNotes || [],
    loadMoreConceptNotes,
    searchConceptNotes,
    searchLoading,
  };
};

export default useConceptNotesQuery;
