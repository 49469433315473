import React from 'react';
import { Control } from 'react-hook-form';
import clsx from 'clsx';

import { StyledInputField } from 'pages/MockTests/PrescriptionAnswer/PrescriptionAnswer.styles';
import { capitalizeFirst } from 'utils';
import { TextInputProps } from 'components/TextField';
import { PrescriptionField, PrescriptionFieldNames } from '../types';
import { InputOnChangeHandler } from 'types';
import PrescriptionFieldEndAdornment from 'pages/MockTests/PrescriptionAnswer/PrescriptionFieldEndAdornment';

export interface OpenAnswerFieldProps {
  display?: boolean;
  fullWidth?: boolean;
  onChange?: InputOnChangeHandler;
  name: PrescriptionFieldNames;
  defaultValue?: string | number;
  units?: string;
  control?: Control<PrescriptionField>;
  type?: TextInputProps['type'];
}

const OpenAnswerField = ({
  display,
  name,
  onChange,
  defaultValue,
  control,
  type,
  units,
  fullWidth,
}: OpenAnswerFieldProps): JSX.Element => {
  const label = capitalizeFirst(name === 'drug' ? 'medicine' : name);
  const displayAnswer = display && Boolean(defaultValue);
  const { register, _formValues } =
    control || ({} as Control<PrescriptionField>);
  // _formValues[name] is needed to control shrink of label dynamically
  const fieldValue = _formValues && _formValues[name];
  // because we have control props optional we have to check if register is defined
  // we do that because we do not want to use controlled input to display answer
  // when input was controlled displaying the answer was cousing a bit of a mess
  // eg. the answers were displaying same value in every answer (because name of fields is the same in every form) form
  // and it was hard to set default value for each answer
  const registerProps = register
    ? { ...register(name, { onChange, shouldUnregister: true }) }
    : null;

  return (
    <StyledInputField
      {...registerProps}
      InputLabelProps={{
        shrink: Boolean(fieldValue) || Boolean(displayAnswer),
      }}
      InputProps={{
        endAdornment: (
          <PrescriptionFieldEndAdornment display={display} units={units} />
        ),
      }}
      className={clsx(name, { 'full-width': fullWidth })}
      disabled={display}
      inputProps={{
        autoComplete: 'off',
      }}
      label={label}
      type={type}
      value={displayAnswer ? defaultValue : fieldValue ?? ''}
    />
  );
};

export default OpenAnswerField;
