import React from 'react';
import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  styled,
} from '@mui/material';
import Box from '@mui/system/Box';
import PaginationItem from '@mui/material/PaginationItem';

import { ArrowLeftThinIcon, ArrowRightThinIcon } from 'components/Icons';
import { Button } from 'components/Button';
import { constSize } from 'utils';
import { useIsMobile } from 'hooks';

const PaginationWrapper = styled(Box)(
  ({ theme: { palette, spacing, typography, breakpoints } }) => ({
    '& .MuiPagination-ul': {
      padding: spacing(5),
      gap: spacing(2),
      alignItems: 'center',

      [breakpoints.up('md')]: {
        padding: spacing(5, 4),
      },
    },
    '& .MuiPaginationItem-previousNext': {
      '& > .MuiButtonBase-root': {
        padding: spacing(2),
        width: '40px',

        [breakpoints.up('md')]: {
          width: '100%',
        },
      },

      '& > .MuiTouchRipple-root': {
        display: 'none',
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '.MuiTablePagination-toolbar': {
      width: '100%',
    },
    // it has to be done this way because of the way MUI handles pagination
    li: {
      '&:nth-of-type(1) ~ li:not(&:last-of-type, &:first-of-type)': {
        width: '32px',
      },

      '&:first-of-type, &:last-of-type': {
        flex: 1,
      },

      '&:last-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },

    '& .MuiPaginationItem-root': {
      borderRadius: '4px',
      ...typography.body2Medium,
    },

    '& .Mui-selected': {
      backgroundColor: palette.toggle.light,
      border: `1px solid ${palette.stroke.main}`,
    },
  })
);

const Span = styled('span')(({ theme: { breakpoints } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'block',
  },
}));

const ARROW_ICON_SIZE = '16px';

const NavigationButton = styled(Button)(
  ({ theme: { spacing, breakpoints } }) => ({
    gap: spacing(1),

    [breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
    },

    '& .MuiSvgIcon-root': {
      ...constSize(ARROW_ICON_SIZE),
    },
  })
);

const navigationButtons = {
  previous: () => (
    <NavigationButton color="secondary" secondary>
      <ArrowLeftThinIcon /> <Span>Previous</Span>
    </NavigationButton>
  ),
  next: () => (
    <NavigationButton color="secondary" secondary>
      <Span>Next</Span> <ArrowRightThinIcon />
    </NavigationButton>
  ),
};

const Pagination = ({ page = 0, count = 0, ...props }: MuiPaginationProps) => {
  const { isMobile } = useIsMobile();
  const currentPage = page + 1;

  return (
    <PaginationWrapper>
      <MuiPagination
        {...props}
        boundaryCount={isMobile ? 1 : 2}
        count={count}
        page={currentPage}
        renderItem={item => (
          <PaginationItem {...item} component="div" slots={navigationButtons} />
        )}
        siblingCount={0}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
