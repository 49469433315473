import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { EOsceStage, IOsceMarksheet } from '@quesmed/types-rn/models';
import clsx from 'clsx';

import { STUDY_STAGES } from './constants';
import { useStationTimerState } from './useStationTimerState';
import {
  CheckboxMarkedCircleOutlineIcon,
  RadioBlankIcon,
  TimerIcon,
} from 'components/Icons';
import { entries } from 'utils';
import { TimeMeasures } from 'types';

const StagesContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItem: 'center',
  gap: spacing(4),
  width: '100%',
}));

const StageItem = styled(Box)(
  ({ theme: { spacing, palette, typography } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: '9px',
    color: palette.text.primary,
    ...typography.body1Medium,
    padding: spacing(3, 4),
    cursor: 'pointer',

    '&.completed': {
      '& .MuiSvgIcon-root': {
        color: palette.success.dark,
      },
    },

    '&.time': {
      '& .MuiSvgIcon-root': {
        color: palette.primary.contrastText,
      },
    },

    '&.blank': {
      '& .MuiSvgIcon-root': {
        color: palette.text.disabled,
      },
    },

    '&.active': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      '& .MuiSvgIcon-root': {
        color: palette.primary.contrastText,
      },
    },
  })
);

const StageTime = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(2),
}));

type OsceMarksheetStages = Pick<
  IOsceMarksheet,
  'feedbackTime' | 'stationTime' | 'readingTime'
>;

type OsceMarksheetStagesKeys = keyof Pick<
  IOsceMarksheet,
  'feedbackTime' | 'stationTime' | 'readingTime'
>;

const getStageTimeKey = (stageName: string): OsceMarksheetStagesKeys =>
  `${stageName.toLowerCase()}Time` as OsceMarksheetStagesKeys;

interface StagesProps {
  stageTimes: OsceMarksheetStages;
}

const Stages = ({ stageTimes }: StagesProps): JSX.Element => {
  const {
    currentStage,
    selectedStage,
    completedStages,
    setSelectedStage,
    timeIsUp,
  } = useStationTimerState();

  const completedIcon = (stage: EOsceStage) => {
    if (timeIsUp || completedStages.includes(stage)) {
      return [CheckboxMarkedCircleOutlineIcon, 'completed'];
    }

    if (stage === currentStage) {
      return [TimerIcon, 'timer'];
    }

    return [RadioBlankIcon, 'blank'];
  };

  const handleSelectStage = useCallback(
    (stage: EOsceStage) => () => {
      setSelectedStage(stage);
    },
    [setSelectedStage]
  );

  return (
    <StagesContainer>
      {entries(STUDY_STAGES).map(([key, stageName]) => {
        const stageKey = Number(key);
        const stageTimeKey = getStageTimeKey(stageName);
        const [Icon, iconClass] = completedIcon(stageKey);

        return (
          <StageItem
            className={clsx(iconClass, { active: selectedStage === stageKey })}
            key={stageKey}
            onClick={handleSelectStage(stageKey)}
          >
            {stageName}
            <StageTime>
              {stageTimes[stageTimeKey]}&nbsp;
              {stageKey === EOsceStage.READING
                ? TimeMeasures.Seconds
                : TimeMeasures.Minutes}
              <Icon />
            </StageTime>
          </StageItem>
        );
      })}
    </StagesContainer>
  );
};

export default Stages;
