import React from 'react';
import { mdiFileDocument } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FileDocumentIcon = (props: IconProps) => (
  <Icon path={mdiFileDocument} {...props} />
);

export default FileDocumentIcon;
