import React from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';

import { ButtonOnClickHandler, Children } from 'types';
import { Button } from 'components/Button';
import { Body, H5 } from 'components/Typography';

const Container = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  maxWidth: '620px',
  margin: '0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const Title = styled(H5)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
}));

const ButtonBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '380px',
  marginTop: spacing(8),
}));
interface InfoStatusProps {
  label?: string;
  onClick?: ButtonOnClickHandler;
  children?: Children;
  title?: string;
  disabled?: boolean;
}

const InfoStatus = ({
  children,
  disabled,
  label,
  onClick,
  title,
}: InfoStatusProps): JSX.Element => (
  <Container>
    {title ? <Title>{title}</Title> : null}
    <Body>{children}</Body>
    {label && onClick && (
      <ButtonBox>
        <Button disabled={disabled} fullWidth onClick={onClick}>
          {label}
        </Button>
      </ButtonBox>
    )}
  </Container>
);

export default InfoStatus;
