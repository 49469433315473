import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import {
  EOsceMarksheetAction,
  EOsceMarksheetState,
  EOsceRoles,
  EOsceStage,
  EOsceType,
  EPaceMarkType,
  EProductType,
  IOsceMarksheet,
  IOsceMarksheetMark,
  IOsceMarksheetMember,
  IUser,
} from '@quesmed/types-rn/models';
import {
  IStartOsceTimerData,
  IStartOsceTimerVar,
  START_OSCE_TIMER,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IOsceMarksheetAction } from '@quesmed/types-rn/resolvers/subscription';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { INIT_TIMER } from 'config/constants';
import { FIELDS_TO_TABS, SOLO_TABS, TABS, TABS_MAP } from './constants';
import StationTabsContent from './StationTabsContent';
import { QuestionButtonType, Tab, Utils } from 'types';
import { ProgressBar, ProgressBarData } from 'components/ProgressBar';
import { ExerciseBottomBar, ExerciseLayout } from 'components/Layout';
import { OsceKnowledgeLibraryStation } from 'components/LearningMaterials';
import ExerciseContent from 'components/Layout/ExerciseContent';
import { useStationTimerState } from './useStationTimerState';
import Stages from './Stages';
import StationTimer from './StationTimer';
import { paths } from 'Router';
import { useEndStation } from './hooks/useEndStation';
import { useExerciseState, useIsMobile, useUserSettings } from 'hooks';
import { ExerciseLaunchedButton } from 'components/ExerciseLaunchedButton';
import { GLOBAL_SCORE_MAX, MarkschemeFeedbackData } from './MarkScore';
import CompleteStationModal from './CompleteStationModal';
import { LOBBY_PANELS } from 'constants/general';
import { ParticipantList } from 'components/ParticipantCard';
import filterNullStationUsers from './filterNullStationUsers';
import { EndExerciseModal } from 'components/ExerciseModals';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { usePlatform } from 'context/PlatformState';

export interface StationData {
  currentUserId: number;
  members?: IOsceMarksheetMember[];
  users?: IUser[];
  osceStation: OsceKnowledgeLibraryStation;
  osceMarksheet: IOsceMarksheet;
  actionData?: IOsceMarksheetAction;
  refetchLoading?: boolean;
  actionLoading?: boolean;
  role: EOsceRoles | null;
  isExaminer: boolean;
  isCandidate: boolean;
  rebuildStudy?: () => Promise<void>;
  review?: boolean;
}
const { stations, dashboard } = paths;

// TODO use in the next tasks
// const GROUP_STUDY_QUIT_MODAL_MESSAGE =
//   'Please note that ending the session will stop the whole activity and other participants will not be able to continue.';

interface StationProps {
  data: StationData;
}

const setButtonClass = (item: ProgressBarData, activeTabId: number) => {
  const { id } = item;
  if (id === activeTabId) {
    return QuestionButtonType.unknownSelected;
  }
};

const parseRolesToObjects = (roles: string[]) => {
  return roles.map((role, index) => ({ id: index, name: role }));
};

const getControlPanelProps = (participantsCount: number, showStages: boolean) =>
  showStages
    ? {
        exerciseItemsCount: 3,
        exerciseLabel: 'Stages',
      }
    : {
        exerciseItemsCount: participantsCount,
        exerciseLabel: 'Participants',
      };
const STAGES_PANELS = [{ label: 'Stages', value: 'Stages' }];
const PANELS: Tab[] = [...LOBBY_PANELS, ...STAGES_PANELS];
const UTILS = [Utils.Notes];
const MIN_STATIONS_BUTTON_WIDTH = 180;
const GLOBAL_SCORE_MIN = 1;

const pacesSchema = Joi.object<MarkschemeFeedbackData>({
  cumulativeScore: Joi.number(),
});

const osceSchema = Joi.object<MarkschemeFeedbackData>({
  cumulativeScore: Joi.number(),
  globalScore: Joi.number()
    .min(GLOBAL_SCORE_MIN)
    .max(GLOBAL_SCORE_MAX)
    .required()
    .messages({
      'any.required': 'Global Score should not be empty',
      'number.base': 'Global Score must be number',
      'number.max': `Global Score should not be greater than ${GLOBAL_SCORE_MAX}`,
      'number.min': `Global Score should not be lower than ${GLOBAL_SCORE_MIN}`,
    }),
});

const getLeaveLabel = (isCompleted: boolean, isExaminer: boolean) => {
  if (isCompleted) {
    return 'Go to Summary';
  }
  if (isExaminer) {
    return 'End & Summary';
  }

  return 'Leave Session';
};

const calcPacesScore = (marks: IOsceMarksheetMark[] = []) =>
  marks.reduce((sum, mark) => {
    const { selectedChoice } = mark;

    switch (selectedChoice) {
      case EPaceMarkType.SATISFACTORY:
        return sum + 2;
      case EPaceMarkType.BORDERLINE:
        return sum + 1;
      default:
        return sum;
    }
  }, 0);

const Station = ({
  data: {
    currentUserId,
    role,
    osceStation,
    actionData,
    osceMarksheet,
    isExaminer,
    isCandidate,
    review,
  },
}: StationProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(TABS.CANDIDATE);
  const [tabs, setTabs] = useState<ProgressBarData[]>([]);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const reviewMode = Boolean(review);
  const { setLeaveLabel, setOnLeave } = useExerciseState();
  const { disabledColors } = useUserSettings();

  const {
    id: osceMarksheetId,
    sessionId,
    marks,
    feedback: globalFeedback,
    globalScore,
    solo,
    state,
    members,
    completed: stationCompleted,
    timeRemaining,
    stationTime,
    feedbackTime,
    readingTime,
    isTimed,
  } = osceMarksheet;
  const stageTimes = {
    stationTime,
    feedbackTime,
    readingTime,
  };

  usePrealoadImages(osceMarksheet);

  const {
    setCurrentStage,
    currentStage,
    selectedStage,
    setSelectedStage,
    setCompletedStages,
  } = useStationTimerState();
  const groupStudy = !solo;
  const { isCompleted, endStation, endStationLoading } = useEndStation({
    solo,
    state,
    osceMarksheet,
  });
  const { osceType } = osceStation;
  const { product } = usePlatform();
  const bottomBarRef = useRef<HTMLDivElement>(null);

  const tabsToDisplay =
    solo || stationCompleted
      ? parseRolesToObjects(SOLO_TABS).filter(({ name }) =>
          Boolean(
            osceStation?.[
              FIELDS_TO_TABS?.[
                name
              ] as unknown as keyof OsceKnowledgeLibraryStation
            ]
          )
        )
      : tabs;

  const completed = stationCompleted || isCompleted;
  const [startOsceTimer] = useMutation<IStartOsceTimerData, IStartOsceTimerVar>(
    START_OSCE_TIMER
  );
  const { isMobile } = useIsMobile();
  const [feedback, setFeedback] = useState(globalFeedback || '');
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [activePanel, setActivePanel] = useState(PANELS[0].value);
  const [showEndStudyModal, setShowEndStudyModal] = useState(false);
  // TODO use osceMarksheet type for that when BE is ready
  const isPaces = product === EProductType.PACES;

  const activeTabIndex = tabsToDisplay.findIndex(tab => tab.name === activeTab);

  const handleShowEndStudyModal = () => setShowEndStudyModal(true);

  const handleHideEndStudyModal = () => setShowEndStudyModal(false);

  const handleLeaveStation = useCallback(async () => {
    if (reviewMode || completed) {
      const path = pathname.includes('group')
        ? `${stations.root}/group/study/summary/${sessionId}/${osceMarksheetId}`
        : `${stations.root}/solo/study/summary/${osceMarksheetId}`;

      navigate(path);
    } else {
      if (isExaminer) {
        handleShowEndStudyModal();
      } else {
        navigate(dashboard);
      }
    }
  }, [
    reviewMode,
    completed,
    pathname,
    sessionId,
    osceMarksheetId,
    navigate,
    isExaminer,
  ]);

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      setActiveTab(tabsToDisplay[tabIndex].name as keyof typeof TABS);

      if (hash) {
        navigate(pathname);
      }
    },
    [hash, navigate, pathname, tabsToDisplay]
  );

  const handlePanelChange = useCallback(() => {
    setActivePanel(prev =>
      prev === PANELS[0].value ? PANELS[1].value : PANELS[0].value
    );
  }, []);

  const endStudyDisabled =
    completed || state !== EOsceMarksheetState.STATION || hasError;

  const cumulativeScore = isPaces
    ? calcPacesScore(marks)
    : marks?.filter(({ mark }) => mark).length;

  const defaultValues = isPaces
    ? { cumulativeScore }
    : {
        cumulativeScore,
        globalScore: globalScore || '',
      };

  const { control, handleSubmit, setValue, getValues, watch } =
    useForm<MarkschemeFeedbackData>({
      defaultValues,
      resolver: joiResolver(isPaces ? pacesSchema : osceSchema),
      mode: 'onTouched',
    });

  const enteredScore = watch('globalScore');

  const handleEndStation = useCallback(() => {
    const globalScore = getValues();

    const variables: Parameters<typeof endStation>[0] = {};

    if (globalScore) {
      variables.globalScore = Number(globalScore);
    }

    if (feedback) {
      variables.feedback = feedback;
    }

    endStation(variables);
  }, [endStation, feedback, getValues]);

  const handlePublishFeedback: SubmitHandler<MarkschemeFeedbackData> =
    useCallback(
      ({ globalScore }) => {
        if (!isPaces && !globalScore) {
          return setHasError(true);
        }

        if (endStudyDisabled) {
          return;
        }

        endStation({ feedback, globalScore: Number(globalScore) });
      },
      [isPaces, endStudyDisabled, endStation, feedback]
    );

  useEffect(() => {
    if (groupStudy && role) {
      setTabs(parseRolesToObjects(TABS_MAP[role][selectedStage]));
    }
  }, [role, selectedStage, groupStudy]);

  useEffect(() => {
    setLeaveLabel(getLeaveLabel(reviewMode || stationCompleted, isExaminer));
    setOnLeave(handleLeaveStation);
  }, [
    handleLeaveStation,
    isExaminer,
    reviewMode,
    setLeaveLabel,
    setOnLeave,
    stationCompleted,
  ]);

  const startStation = useCallback(async () => {
    if ((isCandidate || solo) && timeRemaining === INIT_TIMER && isTimed) {
      await startOsceTimer({
        variables: { osceMarksheetId: Number(osceMarksheetId) },
      });
    }
  }, [
    isCandidate,
    isTimed,
    osceMarksheetId,
    solo,
    startOsceTimer,
    timeRemaining,
  ]);

  useEffect(() => {
    startStation();
  }, [startStation]);

  useEffect(() => {
    if (solo) {
      if (!completed) {
        setActiveTab(TABS.CANDIDATE);
      } else {
        setActiveTab(TABS.MARKSCHEME);
      }
    } else {
      if (role) {
        setTabs(parseRolesToObjects(TABS_MAP[role][currentStage]));
        setActiveTab(TABS_MAP[role][currentStage][0]);
      }
    }
  }, [completed, currentStage, role, solo]);

  const setStationCompletedState = useCallback(() => {
    setCompletedStages([
      EOsceStage.READING,
      EOsceStage.STATION,
      EOsceStage.FEEDBACK,
    ]);
    setSelectedStage(EOsceStage.FEEDBACK);
    setActiveTab(TABS.MARKSCHEME);
  }, [setCompletedStages, setSelectedStage, setActiveTab]);

  useEffect(() => {
    if (actionData) {
      const { action } = actionData;
      if (action === EOsceMarksheetAction.END && !completed) {
        setStationCompletedState();
        setCurrentStage(EOsceStage.FEEDBACK);
        setShowCompleteModal(true);
      }
    }
  }, [actionData, completed, setCurrentStage, setStationCompletedState]);

  useEffect(() => {
    if (completed) {
      setStationCompletedState();
    }
  }, [completed, setStationCompletedState]);

  const reviewStudy = () => {
    setActiveTab(TABS.MARKSCHEME);
  };

  useEffect(() => {
    setValue('cumulativeScore', cumulativeScore);
  }, [cumulativeScore, setValue]);

  const isPacesMarkingComplete = isPaces
    ? marks.every(mark => mark.selectedChoice)
    : true;

  const disabled =
    completed ||
    state !== EOsceMarksheetState.STATION ||
    (!isPaces && !enteredScore) ||
    !isPacesMarkingComplete;

  const primaryControl =
    isExaminer || solo ? (
      <ExerciseLaunchedButton
        disabled={disabled}
        onClick={handleSubmit(handlePublishFeedback)}
      >
        Submit Score
      </ExerciseLaunchedButton>
    ) : undefined;

  const showStages = solo || activePanel === PANELS[1].value;

  const leaveLabel = getLeaveLabel(reviewMode || stationCompleted, isExaminer);

  const participants = members
    .filter(filterNullStationUsers)
    .map(({ role, user: { id, displayName } }) => ({
      role,
      id: Number(id),
      displayName,
    }));

  const controlPanelProps = useMemo(
    () => getControlPanelProps(participants.length, showStages),
    [showStages, participants.length]
  );

  const withoutRole =
    !solo && osceMarksheet.state !== EOsceMarksheetState.PRESTART;

  return (
    <ExerciseLayout
      activeToggleOption={activePanel}
      bottomOffsetVariant={
        isMobile && activeTab === TABS.MARKSCHEME ? 'large' : 'small'
      }
      collapsedPanel={false}
      controlPanelContent={
        showStages ? (
          <Stages stageTimes={stageTimes} />
        ) : (
          <ParticipantList
            currentUserId={currentUserId}
            isExaminer={isExaminer}
            osceMarksheetId={osceMarksheetId}
            participants={participants || []}
            state={state}
            variant="panel"
            withAudio
            withRoleHeader={!solo}
            withVideo
            withoutRole={withoutRole}
          />
        )
      }
      exerciseContent={
        <>
          <ProgressBar
            activeItemId={activeTabIndex}
            activeItemNumber={activeTabIndex}
            data={tabsToDisplay}
            disabledColors={disabledColors}
            minButtonWidth={MIN_STATIONS_BUTTON_WIDTH}
            onClick={handleTabChange}
            setButtonClass={setButtonClass}
            withName
          />
          <ExerciseContent activeTab={activeTab}>
            <StationTabsContent
              control={control}
              currentTab={activeTab}
              feedback={feedback}
              isCandidate={isCandidate}
              isCompleted={completed}
              isExaminer={isExaminer}
              isPaces={isPaces}
              loading={endStationLoading}
              marks={marks}
              notStarted={state !== EOsceMarksheetState.STATION}
              runningStep={currentStage}
              setFeedback={setFeedback}
              setHasError={setHasError}
              solo={solo}
              station={osceStation}
              stationType={osceType?.id as EOsceType}
            />
          </ExerciseContent>

          <ExerciseBottomBar
            activeTab={activeTab}
            primaryControl={primaryControl}
            ref={bottomBarRef}
            utils={UTILS}
          />
          <CompleteStationModal
            onSubmit={reviewStudy}
            open={showCompleteModal && !review}
          />
          <EndExerciseModal
            onClose={handleHideEndStudyModal}
            onSubmit={handleEndStation}
            open={showEndStudyModal}
            submitLoading={endStationLoading}
          />
        </>
      }
      noOverflow
      onToggle={solo ? undefined : handlePanelChange}
      timer={
        <StationTimer
          completed={completed}
          handleLeaveStation={handleLeaveStation}
          leaveLabel={leaveLabel}
          onTimeIsUp={reviewStudy}
          osceMarksheet={osceMarksheet}
          reviewMode={reviewMode}
        />
      }
      toggleLabel={solo ? undefined : 'Stages and Participants'}
      toggleOptions={solo ? undefined : PANELS}
      {...controlPanelProps}
      withCamera={groupStudy}
      withSound={groupStudy}
    />
  );
};

export default Station;
