import { QueryResult, useLazyQuery, useQuery } from '@apollo/client';
import { IPreset } from '@quesmed/types-rn/models';
import {
  IPreDefinedPresetsData,
  IPreDefinedPresetsVar,
  PRE_DEFINED_PRESETS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

interface PresetsQueryResult {
  preDefinedPresets?: IPreset[];
  searchedPresets?: IPreset[];
  searchLoading: boolean;
  loading: boolean;
  searchPresets: (
    search?: string
  ) => Promise<QueryResult<IPreDefinedPresetsData, IPreDefinedPresetsVar>>;
}

const usePreDefinedPresetsQuery = (): PresetsQueryResult => {
  const { data, loading } = useQuery<
    IPreDefinedPresetsData,
    IPreDefinedPresetsVar
  >(PRE_DEFINED_PRESETS);

  const [query, { data: searchData, loading: searchLoading }] = useLazyQuery<
    IPreDefinedPresetsData,
    IPreDefinedPresetsVar
  >(PRE_DEFINED_PRESETS);

  const searchPresets = useCallback(
    (search?: string) =>
      query({
        variables: {
          search,
        },
      }),
    [query]
  );

  const { preDefinedPresets } = data?.restricted || {};
  const { preDefinedPresets: searchedPresets } = searchData?.restricted || {};

  return {
    preDefinedPresets,
    searchedPresets,
    loading,
    searchPresets,
    searchLoading,
  };
};

export default usePreDefinedPresetsQuery;
