import { useSubscription } from '@apollo/client';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import {
  IOnPreStartMarksheetChangeData,
  IOnPreStartMarksheetChangeVar,
  ON_PRESTART_MARKSHEET_CHANGE,
} from '@quesmed/types-rn/resolvers/subscription';
import { isNil } from 'lodash';

const useOnPreStartMarksheetChange = (sessionId = '') => {
  useSubscription<
    IOnPreStartMarksheetChangeData,
    IOnPreStartMarksheetChangeVar
  >(ON_PRESTART_MARKSHEET_CHANGE, {
    variables: {
      sessionId,
    },
    skip: !sessionId,
    onData: ({ client, data: subscriptionData }) => {
      const { builderConfig, preBuildData, topicConceptData, marksheetId } =
        subscriptionData.data?.onPreStartMarksheetChange || {};

      if (marksheetId) {
        const prevData = client.readQuery<IMarksheetData, IMarksheetVar>({
          variables: { id: marksheetId },
          query: MARKSHEET,
        });
        if (prevData) {
          const { marksheet } = prevData.restricted || {};

          const newMarksheet = { ...marksheet };

          if (!isNil(topicConceptData)) {
            newMarksheet.topicConceptData = topicConceptData;
          }

          if (!isNil(builderConfig)) {
            newMarksheet.builderConfig = builderConfig;
          }

          if (!isNil(preBuildData)) {
            newMarksheet.preBuildData = preBuildData;
          }

          client.writeQuery({
            query: MARKSHEET,
            variables: { id: marksheetId },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                marksheet: {
                  ...newMarksheet,
                },
              },
            },
          });
        }
      }
    },
  });
};

export default useOnPreStartMarksheetChange;
