import React from 'react';

import { Nullable, TableData } from 'types';
import SelectionCategories, {
  SelectionCategoriesProps,
} from './SelectionCategories';
import SelectionHeading, { SelectionHeadingProps } from './SelectionHeading';
import SelectionTable, { SelectionTableProps } from './SelectionTable';
import EditPreset from 'components/PresetsBuilder/EditPreset';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import { LearningMaterialItem } from 'components/LearningMaterials';
import { SearchItemsList } from './SearchItemsList';

type ExerciseBuilderProps<
  T extends TableData,
  K,
  N
> = SelectionCategoriesProps<K> &
  SelectionHeadingProps<N> &
  SelectionTableProps<T> & {
    searchItems?: Nullable<LearningMaterialItem[]>;
    searchTitle?: string;
  };

function ExerciseBuilder<T extends TableData, K, N>({
  activeCategory,
  allSelected,
  categoryOptions,
  checkedIcon,
  columns,
  data,
  globalLock,
  headerLock,
  icon,
  loading,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  onSelectAll,
  onSelectRow,
  onSelectSubRow,
  nestedItemsKey,
  onSearch,
  onToggleCategory,
  onToggleView,
  resetSearch,
  searchLabel,
  searchItems,
  searchTerm,
  searchTitle,
  selectedView,
  selectedItemId,
  selectionState,
  title,
  subtitle,
  noCategories,
  viewOptions,
  withOffset,
  selectOnRowClick,
  isCandidate,
}: ExerciseBuilderProps<T, K, N>): JSX.Element {
  const { isPresetEditOpen } = usePresetGlobalState();

  return (
    <>
      {isPresetEditOpen ? (
        <EditPreset />
      ) : (
        <SelectionCategories
          activeCategory={activeCategory}
          categoryOptions={categoryOptions}
          onToggleCategory={onToggleCategory}
        />
      )}
      <SelectionHeading
        loading={loading}
        noCategories={noCategories}
        onSearch={onSearch}
        onToggleView={onToggleView}
        resetSearch={resetSearch}
        searchLabel={searchLabel}
        searchTerm={searchItems ? searchTerm : undefined}
        selectedView={selectedView}
        showViewToggle={!mlaColumns}
        subtitle={subtitle}
        title={title}
        viewOptions={viewOptions}
      />
      {searchItems ? (
        <SearchItemsList searchItems={searchItems} title={searchTitle} />
      ) : (
        <SelectionTable
          allSelected={allSelected}
          checkedIcon={checkedIcon}
          columns={columns}
          data={data}
          globalLock={globalLock}
          headerLock={headerLock}
          icon={icon}
          loading={loading}
          mlaColumns={mlaColumns}
          mlaConditionColumns={mlaConditionColumns}
          mlaPatientPresentationColumns={mlaPatientPresentationColumns}
          nestedItemsKey={nestedItemsKey}
          onSelectAll={onSelectAll}
          onSelectRow={onSelectRow}
          onSelectSubRow={onSelectSubRow}
          selectOnRowClick={selectOnRowClick}
          selectedItemId={!isCandidate ? selectedItemId : undefined}
          selectionState={selectionState}
          withOffset={withOffset}
        />
      )}
    </>
  );
}

export default ExerciseBuilder;
