import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IUpsertUserVideoStatusData,
  IUpsertUserVideoStatusVar,
  optimisticVideoLearningStatus,
  updateCacheOnVideoLearningStatusUpdate,
  UPSERT_USER_VIDEO_STATUS,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import { randomId } from 'utils';
import { useCurrentUser } from 'Auth';

export const useUpdateVideoLearningStatus = () => {
  const [upsertStatus, { loading }] = useMutation<
    IUpsertUserVideoStatusData,
    IUpsertUserVideoStatusVar
  >(UPSERT_USER_VIDEO_STATUS);
  const { id: userId } = useCurrentUser();

  const updateStatus = useCallback(
    (
      videoIds: number[],
      status: EUserLearningStatus,
      prevStatus?: Nullable<EUserLearningStatus>
    ) =>
      upsertStatus({
        variables: { videoIds, status },
        update: updateCacheOnVideoLearningStatusUpdate(prevStatus),
        optimisticResponse: optimisticVideoLearningStatus(
          randomId(8),
          videoIds,
          status,
          userId
        ),
      }),
    [upsertStatus, userId]
  );

  return { loading, updateStatus };
};
