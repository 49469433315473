import {
  ApolloCache,
  DocumentNode,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import {
  IModifyOsceMarksheetData,
  IModifyOsceMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  ApolloUpdateOptions,
  ApolloUpdateResultRestricted,
} from '@quesmed/types-rn';

export function useModifyOsceMarksheetMutation(
  query: DocumentNode,
  fragment: DocumentNode,
  baseOptions?: MutationHookOptions<
    IModifyOsceMarksheetData,
    IModifyOsceMarksheetVar
  >
) {
  const options = { ...baseOptions };

  return useMutation<IModifyOsceMarksheetData, IModifyOsceMarksheetVar>(query, {
    ...options,
    update: (
      cache: ApolloCache<any>,
      result: ApolloUpdateResultRestricted<IModifyOsceMarksheetData>,
      options: ApolloUpdateOptions
    ) => {
      const { modifyOsceMarksheet } = result?.data?.restricted || {};
      const { variables } = options || {};

      if (!variables || !modifyOsceMarksheet) {
        return;
      }
      const { id, ...data } = modifyOsceMarksheet || {};

      cache.writeFragment({
        id: cache.identify({ id, __typename: 'OsceMarksheet' }),
        data,
        fragment,
      });
    },
  });
}

export default useModifyOsceMarksheetMutation;
