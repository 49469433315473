import { useCallback } from 'react';
import {
  EOsceMarksheetAction,
  EOsceMarksheetState,
} from '@quesmed/types-rn/models';
import { MODIFY_OSCE_MARKSHEET } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { MODIFY_MARKSHEET_STATE_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyOsceMarksheetMutation from './useModifyOsceMarksheetMutation';

const useChangeOsceMarksheetState = (osceMarksheetId?: number) => {
  const [modify, { loading }] = useModifyOsceMarksheetMutation(
    MODIFY_OSCE_MARKSHEET,
    MODIFY_MARKSHEET_STATE_FRAGMENT
  );

  const changeMarksheetState = useCallback(
    async (state: EOsceMarksheetState) => {
      if (osceMarksheetId) {
        return modify({
          variables: {
            input: {
              action: EOsceMarksheetAction.STATE_CHANGE,
              osceMarksheetId,
              state,
            },
          },
        });
      }
    },
    [osceMarksheetId, modify]
  );

  return { changeMarksheetState, loading };
};

export default useChangeOsceMarksheetState;
