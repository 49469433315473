import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import { IEntitlement, IOsceType } from '@quesmed/types-rn/models';

import {
  LearningMaterialItem,
  LearningMaterialsData,
  LearningMaterialsDataItemsField,
} from '../types';
import PanelList from './PanelList';
import { FilterField } from 'components/FilterField';
import { SkeletonList } from 'components/Skeleton';
import FilterFallback from './FilterFallback';
import { Nullable } from 'types';
import PanelItemName from './PanelItemName';
import { paths } from 'Router';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
  overflow: 'hidden',
}));

const ItemsContainer = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  paddingBottom: spacing(6),
  overflowY: 'auto',
}));

const StyledList = styled(List)({
  width: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled(Box)(({ theme: { typography, palette } }) => ({
  width: '100%',
  border: `1px solid ${palette.stroke.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...typography.button,
  color: palette.text.primary,
  backgroundColor: palette.background.paper,
  height: '40px',
}));

const { knowledgeLibrary, videoLibrary } = paths;

interface PanelItemsProps {
  loading: boolean;
  title: string;
  resetSearch: boolean;
  searchBy: LearningMaterialsDataItemsField;
  searchLabel: string;
  items: LearningMaterialsData[];
  searchItems?: Nullable<LearningMaterialItem[]>;
  searchTerm?: string;
  onSearch?: (searchString: string) => void;
  sectionId?: number;
  entitlementId?: number;
  showSearch?: boolean;
}

const PanelItems = ({
  title,
  loading,
  resetSearch,
  showSearch = true,
  searchBy,
  searchItems,
  searchLabel,
  searchTerm,
  onSearch,
  entitlementId,
  items,
}: PanelItemsProps): JSX.Element => {
  const [displayedItems, setDisplayedItems] =
    useState<LearningMaterialsData[]>(items);
  const navigate = useNavigate();
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setDisplayedItems(items);
  }, [items]);

  useEffect(() => {
    if (searchItems && ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchItems]);

  const handleSearch = useCallback(
    (fieldName: LearningMaterialsDataItemsField) => (searchString: string) => {
      const normalizedSearchString = searchString.toLowerCase().trim();

      if (items && searchString) {
        const filtered = items
          .map(item => ({
            ...item,
            [fieldName]: item[fieldName]
              ? item[fieldName]?.filter(({ name, title }) => {
                  const text = name || title;

                  return text?.toLowerCase().includes(normalizedSearchString);
                })
              : undefined,
          }))
          .filter(item => item[fieldName]?.length);

        return setDisplayedItems(filtered);
      }

      setDisplayedItems(items);
    },
    [items]
  );

  const handleNavigate =
    (
      isVideo: boolean,
      id: number,
      topicId?: number,
      entitlement?: Nullable<IEntitlement> | IOsceType
    ) =>
    () => {
      if (entitlementId || topicId) {
        let path = `${knowledgeLibrary.root}/chapter/${entitlement?.id}/${
          entitlementId || topicId
        }/${id}`;
        if (isVideo) {
          path = `${videoLibrary.root}/video/${entitlementId}/${
            entitlementId || topicId
          }/${id}`;
        }
        navigate(path);
      }
    };

  const forceExpanded = items?.length !== displayedItems?.length;

  return (
    <Container>
      {loading ? (
        <>
          <Skeleton height={80} variant="rectangular" />
          <ItemsContainer>
            <SkeletonList count={10} height={48} spacing={4} />
          </ItemsContainer>
        </>
      ) : (
        <>
          {showSearch ? (
            <>
              <FilterField
                loading={loading}
                onSearch={onSearch || handleSearch(searchBy)}
                resetSearch={resetSearch}
                searchLabel={searchLabel}
                searchTerm={searchItems ? searchTerm : undefined}
              />
              <Divider />
            </>
          ) : null}
          <Title>{title}</Title>
          <ItemsContainer>
            <StyledList ref={ref}>
              {searchItems?.length
                ? searchItems.map(
                    ({
                      id,
                      name = '',
                      topicId,
                      title = '',
                      entitlement,
                      osceType,
                    }) => (
                      <PanelItemName
                        active={false}
                        expandable={false}
                        expanded={false}
                        key={id}
                        name={name || title}
                        onClick={handleNavigate(
                          Boolean(title),
                          Number(id),
                          topicId,
                          entitlement || osceType
                        )}
                      />
                    )
                  )
                : null}
              {!searchItems && displayedItems?.length
                ? displayedItems.map(item => (
                    <PanelList
                      entitlementId={entitlementId}
                      forceExpanded={forceExpanded}
                      item={item}
                      key={item.id}
                    />
                  ))
                : null}
              {(searchItems && !searchItems.length) ||
              !displayedItems?.length ? (
                <FilterFallback>No results found</FilterFallback>
              ) : null}
            </StyledList>
          </ItemsContainer>
        </>
      )}
    </Container>
  );
};

export default PanelItems;
