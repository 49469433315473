import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Children } from 'types';

const Container = styled(Box)(
  ({ theme: { palette, shape, spacing, breakpoints } }) => ({
    width: '100%',
    backgroundColor: palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    padding: spacing(4),
    boxSizing: 'border-box',
    border: `1px solid ${palette.stroke.main}`,

    [breakpoints.up('md')]: {
      borderRadius: shape.borderRadius,
      padding: spacing(8),
    },
  })
);

interface ExerciseSummaryCardProps {
  children: Children;
}

const ExerciseSummaryCard = ({
  children,
}: ExerciseSummaryCardProps): JSX.Element => <Container>{children}</Container>;

export default ExerciseSummaryCard;
