import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { IQuestionChoice } from '@quesmed/types-rn/models';

import { compareByField } from 'utils';
import { Body } from 'components/Typography';

const List = styled('ul')(
  ({ theme: { typography, spacing, breakpoints } }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: spacing(2, 4),
    ...typography.body1Medium,

    [breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  })
);

const ListItem = styled('li')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: spacing(2),
}));

const Label = styled(Body)({
  textTransform: 'uppercase',
});

interface MatchingQuestionChoicesListProps {
  choices: IQuestionChoice[];
}

const MatchingQuestionChoicesList = ({
  choices,
}: MatchingQuestionChoicesListProps): JSX.Element => {
  const items = useMemo(
    () => [...choices].sort(compareByField('label')),
    [choices]
  );

  return (
    <List>
      {items.map(({ id, name, label }) => (
        <ListItem key={id}>
          <Label>{label}.</Label>
          <Body>{name}</Body>
        </ListItem>
      ))}
    </List>
  );
};

export default MatchingQuestionChoicesList;
