import { useLazyQuery } from '@apollo/client';
import {
  IMarksheetsWindowData,
  IMarksheetsWindowVar,
  MARKSHEETS_WINDOW,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useLazyMarksheetsWindowQuery = (
  soloMode: boolean,
  updatedAt?: number | Date
) => {
  const [getMarksheets, { data, loading, client }] = useLazyQuery<
    IMarksheetsWindowData,
    IMarksheetsWindowVar
  >(MARKSHEETS_WINDOW, {
    variables: {
      filter: {
        limit: 1000,
        solo: soloMode,
        updatedAt: updatedAt === 0 ? undefined : updatedAt,
        period: 90,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [getMarksheets, { data, loading, client }] as const;
};

export default useLazyMarksheetsWindowQuery;
