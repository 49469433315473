import React from 'react';

import StationsSummary from './StationsSummary';
import { CircularProgress } from 'components/CircularProgress';
import { useStationGroupStudyData } from './StationsGroupStudyController';

const StationsGroupStudySummary = () => {
  const { osceMarksheet, restartSession, participants } =
    useStationGroupStudyData();

  return osceMarksheet ? (
    <StationsSummary
      marksheetLoading={false}
      osceMarksheet={osceMarksheet}
      participants={participants}
      restartSession={restartSession}
    />
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default StationsGroupStudySummary;
