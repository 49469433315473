import React from 'react';
import { styled } from '@mui/material';

import { GridRow } from 'components/GridRow';
import { GridCell } from 'components/GridCell';
import setMultiAnswerColumnTemplate from './setMultiAnswerColumnTemplate';
import { MultiAnswerColumn, MultiAnswerValues } from '../types';

interface MultiAnswerTableRowProps {
  columns: MultiAnswerColumn[];
  values: MultiAnswerValues[];
  onClick?: (id: number) => void;
}

export const TableCell = styled(GridCell)(({ theme: { spacing } }) => ({
  overflow: 'visible',

  '&:nth-of-type(1):not(.chevron)': {
    padding: spacing(0, 4, 0, 8),
  },
  '&:first-of-type': {
    padding: spacing(0, 4, 0, 8),
  },
}));

const MultiAnswerTableRow = ({
  columns,
  values,
}: MultiAnswerTableRowProps): JSX.Element => {
  return (
    <GridRow columns={columns} setColumnTemplate={setMultiAnswerColumnTemplate}>
      {columns.map(({ align, key }, i) => {
        const value = values[i];

        return (
          <TableCell align={align} key={key}>
            {value}
          </TableCell>
        );
      })}
    </GridRow>
  );
};

export default MultiAnswerTableRow;
