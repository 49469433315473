import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  EDIT_PRESET,
  IEditPresetData,
  IEditPresetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';

const useEditPresetMutation = (presetId: number) => {
  const { enqueueSnackbar } = useSnackbar();

  const [edit, { loading }] = useMutation<IEditPresetData, IEditPresetVar>(
    EDIT_PRESET,
    {
      onCompleted: () => {
        enqueueSnackbar('Preset edited successfully!');
      },
    }
  );

  const editPreset = useCallback(
    (data: IEditPresetVar['data']) =>
      edit({
        variables: {
          presetId: Number(presetId),
          data,
        },
      }),
    [edit, presetId]
  );

  return { editPreset, loading };
};
export default useEditPresetMutation;
