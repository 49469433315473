import React, { useMemo } from 'react';

import { mapIds } from 'utils';
import { paths } from 'Router';
import QuestionsQuiz from './QuestionsQuiz';
import { useQuestionGroupStudyData } from './QuestionsGroupStudyController';
import { useOnMarksheetMarkChange } from './hooks';
import { CircularProgress } from 'components/CircularProgress';

const { questions } = paths;
const { root, groupLobby } = questions;

const getIndex = (
  markIds: Map<number, number>,
  id?: number
): number | undefined => markIds.get(Number(id));

const QuestionsGroupStudyQuiz = () => {
  const { marksheet, sessionId } = useQuestionGroupStudyData();

  const { id, marks = [], currentMarkId } = marksheet || {};
  const markIds = useMemo(() => mapIds(marks), [marks]);
  const markIndex = currentMarkId ? getIndex(markIds, currentMarkId) : 0;

  useOnMarksheetMarkChange(sessionId || '');

  return marksheet ? (
    <QuestionsQuiz
      baseUrl={`${root}/${groupLobby}/quiz/${sessionId}/${id}`}
      marksheet={marksheet}
      questionIndex={Number(markIndex)}
      review={false}
    />
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default QuestionsGroupStudyQuiz;
