import React from 'react';
import { useParams } from 'react-router-dom';

import { ExerciseType, QuestionsRouterParams } from 'types';
import { useMarksheetQuery } from 'hooks';
import QuestionsSummary from './QuestionsSummary';
import LearningPointsContextProvider from './context/LearningPointsContext';

export const getSummary = (total: number, correct: number, incorrect: number) =>
  `${ExerciseType.Questions} answered: ${correct + incorrect} out of ${total}`;

const QuestionsSoloStudySummary = (): JSX.Element => {
  const { marksheetId = '' } = useParams<QuestionsRouterParams>();
  const { marksheet, loading } = useMarksheetQuery(marksheetId);

  return (
    <LearningPointsContextProvider>
      <QuestionsSummary loading={loading} marksheet={marksheet} />
    </LearningPointsContextProvider>
  );
};

export default QuestionsSoloStudySummary;
