import { useLazyQuery, useQuery } from '@apollo/client';
import {
  IOsceMarksheetData,
  IOsceMarksheetVar,
  OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

const useOsceMarksheet = (loading: boolean, osceMarksheetId?: number) => {
  const {
    data,
    error: osceMarksheetError,
    loading: osceMarksheetLoading,
  } = useQuery<IOsceMarksheetData, IOsceMarksheetVar>(OSCE_MARKSHEET, {
    fetchPolicy: 'cache-and-network',
    skip: !osceMarksheetId || loading,
    variables: { id: Number(osceMarksheetId) },
  });

  const [lazyOsceMarksheetQuery, { data: lazyData, loading: fetchLoading }] =
    useLazyQuery<IOsceMarksheetData, IOsceMarksheetVar>(OSCE_MARKSHEET);

  const fetchOsceMarksheet = useCallback(
    (id: number) => lazyOsceMarksheetQuery({ variables: { id } }),
    [lazyOsceMarksheetQuery]
  );

  const { osceMarksheet } = data?.restricted || {};
  const { osceMarksheet: lazyOsceMarksheet } = lazyData?.restricted || {};

  return {
    fetchLoading,
    osceMarksheet: osceMarksheet || lazyOsceMarksheet,
    fetchOsceMarksheet,
    osceMarksheetLoading,
    osceMarksheetError,
  };
};

export default useOsceMarksheet;
