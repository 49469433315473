import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { CellLabel, CoreCellLabelProps } from './CellLabel';

const Container = styled(Box)(
  ({ theme: { breakpoints, spacing, typography } }) => ({
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: spacing(2),
    ...typography.body1,

    [breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: spacing(4),
    },

    '&.nowrap': {
      flexDirection: 'row',
    },
  })
);

const Labels = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  gap: spacing(4),

  [breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: spacing(8),
  },

  '&.nowrap': {
    flexDirection: 'row',
  },
}));

interface ProgressCellLabelsProps {
  labels?: CoreCellLabelProps[];
  withTitle?: boolean;
  nowrap?: boolean;
}

const ProgressCellLabels = ({
  labels,
  withTitle = true,
  nowrap,
}: ProgressCellLabelsProps): JSX.Element => (
  <Container className={clsx({ nowrap })}>
    {withTitle ? <Box>Progress</Box> : null}
    {labels ? (
      <Labels className={clsx({ nowrap })}>
        {labels.map(({ label, ...rest }) => (
          <CellLabel key={label} label={label} {...rest} />
        ))}
      </Labels>
    ) : null}
  </Container>
);

export default ProgressCellLabels;
