import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Fallback = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: '0 0 7px 7px',
  border: `1px solid ${palette.stroke.main}`,
  backgroundColor: palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '240px',
}));

export const SearchItemsContainer = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: '0 0 7px 7px',
  border: `1px solid ${palette.stroke.main}`,
  backgroundColor: palette.background.paper,
  overflow: 'hidden',

  '& :nth-last-of-type(1)': {
    borderBottom: 0,
  },
}));
