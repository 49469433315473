import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalProps } from 'components/Modal';
import { useLeaveExerciseState } from 'hooks';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import { paths } from 'Router';
import { usePlatform } from 'context/PlatformContext';
import { LeaveExerciseModal } from 'components/ExerciseModals';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import { Tabs } from 'components/ExerciseBuilder/types';

const { dashboard } = paths;

interface FlashcardsLeaveModalProps
  extends Omit<
    ModalProps,
    'cancelLabel' | 'submitLabel' | 'onSubmit' | 'title'
  > {
  discard?: boolean;
  fromBackButton?: boolean;
}

const FlashcardsLeaveModal = ({
  onClose,
  discard,
  open,
  fromBackButton,
}: FlashcardsLeaveModalProps): JSX.Element => {
  const { setProduct } = usePlatform();
  const { leaveState, setLeaveState, setCanLeave, closeLeaveModal } =
    useLeaveExerciseState();
  const { isPresetEditOpen, setIsPresetEditOpen, setTab } =
    usePresetGlobalState();
  const { path, state = {} } = leaveState;
  const { newProduct } = state;
  const navigate = useNavigate();

  const handleClose = () => {
    setLeaveState({ path: '' });

    if (onClose) {
      onClose();
    } else {
      closeLeaveModal();
    }
  };

  useEffect(() => {
    return () => {
      closeLeaveModal();
    };
  }, [closeLeaveModal]);

  const handleSubmit = () => {
    setCanLeave(true);

    if (newProduct !== undefined) {
      setProduct(newProduct);
    }

    const editStateChange = () => {
      setIsPresetEditOpen(false);
      fromBackButton
        ? setTab(Tabs.Presets)
        : navigate(path || dashboard, { state: { leave: true } });
    };

    isPresetEditOpen
      ? editStateChange()
      : navigate(path || dashboard, { state: { leave: true } });
  };

  return discard ? (
    <DiscardChangesModal
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
      showBackButton={false}
    />
  ) : (
    <LeaveExerciseModal
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};

export default FlashcardsLeaveModal;
