import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IMarksheetMark } from '@quesmed/types-rn/models';

import { useStateCb } from 'hooks';
import { noop, setQuestionStatus } from 'utils';
import { CheckboxState, ExerciseProgressStatus } from 'types';

interface SelectedLearningPoint {
  isAnswered: boolean;
  learningPoint: string;
  selected: boolean;
  status: ExerciseProgressStatus;
}

interface LearningPointsContextState {
  learningPointsSelectionState: Map<number, SelectedLearningPoint>;
  setMarks: (marks: IMarksheetMark[]) => void;
  allSelected: CheckboxState;
  deselectAll: () => void;
  selectAll: () => void;
  selectAllAnswered: () => void;
  toggleSelectLearningPoint: (id: number) => void;
}

const LearningPointsContext = createContext<LearningPointsContextState>({
  learningPointsSelectionState: new Map<number, SelectedLearningPoint>(),
  setMarks: () => noop,
  allSelected: CheckboxState.UNCHECKED,
  deselectAll: () => noop,
  selectAll: () => noop,
  selectAllAnswered: () => noop,
  toggleSelectLearningPoint: () => noop,
});

interface LearningPointsContextProviderProps {
  children: ReactNode;
}

const LearningPointsContextProvider = ({
  children,
}: LearningPointsContextProviderProps): JSX.Element => {
  const [marks, setMarks] = useState<IMarksheetMark[]>([]);
  const [allSelected, setAllSelected] = useState<CheckboxState>(
    CheckboxState.UNCHECKED
  );
  const [learningPointsSelectionState, setLearningPointsSelectionState] =
    useStateCb<Map<number, SelectedLearningPoint>>(
      new Map<number, SelectedLearningPoint>()
    );

  const deselectAll = () => {
    const stateMap = new Map<number, SelectedLearningPoint>();

    learningPointsSelectionState.forEach((value, key) => {
      stateMap.set(key, {
        ...value,
        selected: false,
      });
    });

    setLearningPointsSelectionState(stateMap);
  };

  const selectAll = () => {
    const stateMap = new Map<number, SelectedLearningPoint>();

    learningPointsSelectionState.forEach((value, key) => {
      stateMap.set(key, {
        ...value,
        selected: true,
      });
    });

    setLearningPointsSelectionState(stateMap);
  };

  const selectAllAnswered = () => {
    const stateMap = new Map<number, SelectedLearningPoint>();

    let answeredCount = 0;

    learningPointsSelectionState.forEach((value, key) => {
      stateMap.set(key, {
        ...value,
        selected: value.isAnswered,
      });
      if (value.isAnswered) {
        answeredCount++;
      }
    });

    if (answeredCount === 0) {
      learningPointsSelectionState.forEach((value, key) => {
        stateMap.set(key, {
          ...value,
          selected: true,
        });
      });
    }

    setLearningPointsSelectionState(stateMap);
  };

  const toggleSelectLearningPoint = (id: number) => {
    const currentState = learningPointsSelectionState.get(id);

    if (currentState) {
      setLearningPointsSelectionState(
        prev =>
          new Map(
            prev.set(id, {
              ...currentState,
              selected: !currentState.selected,
            })
          )
      );
    }
  };

  useEffect(() => {
    if (marks) {
      const stateMap = new Map<number, SelectedLearningPoint>();

      marks.forEach(mark => {
        const status = setQuestionStatus(mark);
        const { id, question, isAnswered } = mark;
        stateMap.set(id, {
          selected: false,
          learningPoint: question.learningPoint || '',
          isAnswered,
          status,
        });
      });

      setLearningPointsSelectionState(stateMap);
    }
  }, [marks, setLearningPointsSelectionState]);

  useEffect(() => {
    const allValues = [...learningPointsSelectionState.values()];

    if (allValues.every(val => Boolean(val.selected))) {
      setAllSelected(CheckboxState.CHECKED);
    } else if (allValues.some(val => Boolean(val.selected))) {
      setAllSelected(CheckboxState.INTERMEDIATE);
    } else {
      setAllSelected(CheckboxState.UNCHECKED);
    }
  }, [learningPointsSelectionState]);

  return (
    <LearningPointsContext.Provider
      value={{
        allSelected,
        learningPointsSelectionState,
        setMarks,
        deselectAll,
        selectAll,
        selectAllAnswered,
        toggleSelectLearningPoint,
      }}
    >
      {children}
    </LearningPointsContext.Provider>
  );
};

export const useLearningPointsCheckState = () =>
  useContext(LearningPointsContext);

export default LearningPointsContextProvider;
