import { useQuery } from '@apollo/client';
import {
  DAILY_FEED,
  IDailyFeedData,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useDailyFeed = () => {
  const { data, loading, error } = useQuery<IDailyFeedData>(DAILY_FEED, {
    fetchPolicy: 'cache-and-network',
  });

  const { dailyFeed } = data?.restricted.user || {};

  return { dailyFeed, error, loading };
};

export default useDailyFeed;
