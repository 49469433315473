import { ApolloClient, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import { strikeSampleChoice } from '@quesmed/types-rn/resolvers/mutation';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { MODIFY_MARKSHEET_MARK_STRIKED } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { MODIFY_MARKSHEET_MARK_STRIKED_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetMarkMutation from './useModifyMarksheetMarkMutation';

const optimisticStrickenChoices = (
  client: ApolloClient<any>,
  marksheetId: number,
  questionIndex: number,
  strickenOutChoices: number[]
) => {
  const data = client.readQuery<IMarksheetData, IMarksheetVar>({
    variables: { id: marksheetId },
    query: MARKSHEET,
  });

  const { marksheet } = data?.restricted || {};

  if (marksheet) {
    const { marks = [] } = marksheet || {};

    const updatedMark = {
      ...marks[questionIndex],
      striked: strickenOutChoices,
    };

    return {
      restricted: {
        __typename: 'RestrictedMutation',
        modifyMarksheetMark: {
          ...updatedMark,
        },
      },
    };
  }
};

const useToggleStrikeChoice = (
  marksheetId: number,
  markId: number,
  isSample = false
) => {
  const client = useApolloClient();
  const [modify] = useModifyMarksheetMarkMutation(
    MODIFY_MARKSHEET_MARK_STRIKED,
    MODIFY_MARKSHEET_MARK_STRIKED_FRAGMENT
  );

  const toggleStrikeChoice = useCallback(
    async (striked: number[], questionIndex: number) => {
      if (isSample) {
        strikeSampleChoice(client, markId, striked);
      } else {
        if (marksheetId) {
          return modify({
            variables: {
              input: {
                action: EMarksheetAction.MARK_CHECK_UNCHECK,
                markId,
                striked,
                marksheetId,
              },
            },
            optimisticResponse: optimisticStrickenChoices(
              client,
              marksheetId,
              questionIndex,
              striked
            ),
          });
        }
      }
    },
    [client, isSample, markId, marksheetId, modify]
  );

  return toggleStrikeChoice;
};

export default useToggleStrikeChoice;
