import React from 'react';
import { mdiFolderLockOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FolderLockOutlineIcon = (props: IconProps) => (
  <Icon path={mdiFolderLockOutline} {...props} />
);

export default FolderLockOutlineIcon;
