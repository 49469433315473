import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IQuestionLikeData,
  IQuestionLikeVar,
  optimisticQuestionLike,
  QUESTION_LIKE,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IQuestion } from '@quesmed/types-rn/models';

import { setLikeState } from './setLikeState';

export const useQuestionLikeMutation = (
  question: IQuestion,
  marksheetId?: number
) => {
  const { id: questionId, isLikedByMe, likes, dislikes } = question;

  const [questionLike, { loading: questionLikeLoading }] = useMutation<
    IQuestionLikeData,
    IQuestionLikeVar
  >(QUESTION_LIKE);

  const handleLikeQuestion = useCallback(
    (likeClick: boolean) => () => {
      if (questionLikeLoading) {
        return;
      }
      const variables = {
        marksheetId: Number(marksheetId),
        questionId: Number(questionId),
        like: setLikeState(likeClick, isLikedByMe),
      };

      questionLike({
        variables,
        optimisticResponse: optimisticQuestionLike(variables, question),
      });
    },

    [
      questionLikeLoading,
      marksheetId,
      questionId,
      isLikedByMe,
      questionLike,
      question,
    ]
  );

  return {
    handleLikeQuestion,
    likes,
    dislikes,
  };
};
