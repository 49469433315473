import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { joiResolver } from '@hookform/resolvers/joi';
import InputAdornment from '@mui/material/InputAdornment';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { EDifficultyType } from '@quesmed/types-rn/models';
import Skeleton from '@mui/material/Skeleton';
import {
  IBuildConfigData,
  IBuildMarksheetData,
  IBuildMarksheetInput,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { FormCheckboxGroup } from 'components/Checkbox';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { FormField } from 'components/TextField';
import ModalSection from 'components/Modal/ModalSection';
import { FormSelect } from 'components/SelectField';
import {
  PRE_BUILD_DIFFICULTY_OPTIONS,
  QUESTION_DIFFICULTY_LEVELS,
} from 'config/constants';
import {
  QuestionAttempt,
  QuestionFormInput,
  QuestionsMode,
  Undefinable,
} from 'types';
import { useChangeBuilderConfig } from '../Questions/hooks';
import { SelectionLabel } from 'components/SelectionLabel';
import {
  calcMax,
  distributeQuestions,
  keys,
  parseBuilderConfig,
  round,
} from 'utils';
import { paths } from 'Router';
import { useSnackbar } from 'components/Snackbar';
import useBuildMLAQuestions from './hooks/useBuildMLAQuestions';

export const SelectionCheckboxContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    '& .MuiFormGroup-root': {
      position: 'relative',
      paddingBottom: spacing(5),
    },

    [breakpoints.up('md')]: {
      '& .MuiFormGroup-root': {
        paddingBottom: 0,
      },
    },
  })
);

const MINIMUM_QUESTIONS_NUMBER = 1;
const DEFAULT_QUESTION_NUMBER = 20;
const MAXIMUM_QUESTIONS_NUMBER = 200;
const MINIMUM_QUESTION_TIME = 50; // seconds
const DEFAULT_QUESTION_TIME = 70; // seconds
const MAXIMUM_QUESTION_TIME = 600; // seconds

const MODE_OPTIONS = [
  { label: 'Quiz mode', value: QuestionsMode.Quiz },
  {
    label: 'Test mode',
    value: QuestionsMode.Test,
  },
];

const DEFAULT_FORM_FALUES = {
  numberOfQuestions: DEFAULT_QUESTION_NUMBER,
  questionsAttempt: [
    QuestionAttempt.SeenCorrect,
    QuestionAttempt.SeenIncorrect,
    QuestionAttempt.Unseen,
  ],
  difficulty: QUESTION_DIFFICULTY_LEVELS,
  mode: QuestionsMode.Quiz,
  secondsPerQuestion: DEFAULT_QUESTION_TIME,
};

const INIT_QUESTIONS_DISTRIBUTION = {
  unseen: 0,
  seenIncorrect: 0,
  seenCorrect: 0,
};

const TOTAL_QUESTIONS_DISTRIBUTION = {
  totalUnseen: 0,
  totalSeenIncorrect: 0,
  totalSeenCorrect: 0,
};

const isFormDataDifferent = (
  newFormData: QuestionFormInput,
  oldFormData: QuestionFormInput
) =>
  keys(oldFormData).some(key => !isEqual(oldFormData[key], newFormData[key]));

const isBuilderDifferent = (
  newBuilder: IBuildConfigData,
  oldBuilder?: IBuildConfigData
) =>
  !oldBuilder ||
  keys(oldBuilder).some(key => !isEqual(oldBuilder[key], newBuilder[key]));

const getSchema = (max = MAXIMUM_QUESTIONS_NUMBER) =>
  Joi.object<QuestionFormInput>({
    numberOfQuestions: Joi.number()
      .integer()
      .min(MINIMUM_QUESTIONS_NUMBER)
      .max(max)
      .required()
      .messages({
        'any.required': 'Enter a number of questions',
        'number.base': 'Amount of questions must be a number',
        'number.min': `Select at least ${MINIMUM_QUESTIONS_NUMBER} question`,
        'number.max': `Select maximum ${max} questions`,
        'number.integer': 'Enter an integer',
      }),
    questionsAttempt: Joi.array().items(Joi.string()).min(1).messages({
      'array.min': 'Select at least one question type',
    }),
    difficulty: Joi.array().items(Joi.number()).min(1).messages({
      'array.min': 'Select at least one difficulty level',
    }),
    mode: Joi.string()
      .valid(QuestionsMode.Quiz, QuestionsMode.Test)
      .default(QuestionsMode.Quiz),
    secondsPerQuestion: Joi.alternatives().conditional('mode', {
      is: QuestionsMode.Test,
      then: Joi.number()
        .integer()
        .min(MINIMUM_QUESTION_TIME)
        .max(MAXIMUM_QUESTION_TIME)
        .required()
        .messages({
          'any.required': 'Enter question time',
          'number.base': 'Question time must be number',
          'number.min': `Enter minimum ${MINIMUM_QUESTION_TIME} seconds`,
          'number.max': `Enter maximum ${MAXIMUM_QUESTION_TIME} seconds (10 minute)`,
          'number.integer': 'Enter an integer',
        }),
      otherwise: Joi.number(),
    }),
  });

const parseQuestionsAttempted = (
  questionsAttempt?: (QuestionAttempt | undefined)[]
) => {
  const unseen = Boolean(questionsAttempt?.includes(QuestionAttempt.Unseen));
  const seenIncorrect = Boolean(
    questionsAttempt?.includes(QuestionAttempt.SeenIncorrect)
  );
  const seenCorrect = Boolean(
    questionsAttempt?.includes(QuestionAttempt.SeenCorrect)
  );

  return { unseen, seenIncorrect, seenCorrect };
};

const calcMaxAndDistributeQuestions = (
  totalUnseen: number,
  totalCorrect: number,
  totalIncorrect: number,
  requestedNumber: number,
  questionsAttempt?: (QuestionAttempt | undefined)[]
) => {
  const { unseen, seenIncorrect, seenCorrect } =
    parseQuestionsAttempted(questionsAttempt);

  const max = calcMax(
    totalUnseen,
    totalIncorrect,
    totalCorrect,
    unseen,
    seenIncorrect,
    seenCorrect
  );

  const distribution = distributeQuestions(
    round(Number(requestedNumber)),
    totalUnseen,
    totalIncorrect,
    totalCorrect,
    unseen,
    seenIncorrect,
    seenCorrect
  );

  return { max, ...distribution };
};

interface QuestionsPreBuildModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onBack'> {
  builderConfig?: IBuildConfigData;
  search?: string;
  selection?: [number[], number[]];
  source?: string;
  marksheetId?: number;
  onStartQuiz?: () => void;
}

const MLAPreBuildModal = ({
  onClose,
  onStartQuiz,
  onBack,
  builderConfig,
  marksheetId,
  selection,
  open,
  search = '',
  source = '',
}: QuestionsPreBuildModalProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { changeBuilderConfig } = useChangeBuilderConfig(marksheetId);
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentMaxValue, setCurrentMaxValue] = useState<number>(0);
  const [distibution, setDistribution] = useState(INIT_QUESTIONS_DISTRIBUTION);
  const [totalDistribution, setTotalDistribution] = useState(
    TOTAL_QUESTIONS_DISTRIBUTION
  );
  const schema = useMemo(() => getSchema(currentMaxValue), [currentMaxValue]);

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<QuestionFormInput>({
    defaultValues: DEFAULT_FORM_FALUES,
    values: builderConfig ? parseBuilderConfig(builderConfig) : undefined,
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const { isValid } = formState;
  const watchForm = watch();
  const { mode } = watchForm;
  const formDataRef = useRef<QuestionFormInput>(watchForm);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const builderConfigRef = useRef<Undefinable<IBuildConfigData>>(builderConfig);

  useEffect(() => {
    let timeout = 0;
    if (isValid && isFormDataDifferent(formDataRef.current, watchForm)) {
      timeout = window.setTimeout(() => {
        const {
          questionsAttempt,
          numberOfQuestions,
          secondsPerQuestion,
          mode,
        } = watchForm;

        formDataRef.current = watchForm;

        changeBuilderConfig({
          ...parseQuestionsAttempted(questionsAttempt),
          numberOfQuestions: Number(numberOfQuestions),
          secondsPerQuestion: Number(secondsPerQuestion),
          isTest: mode === QuestionsMode.Test,
        });
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [changeBuilderConfig, isValid, watchForm]);

  useEffect(() => {
    if (mode === QuestionsMode.Test && timeInputRef.current) {
      timeInputRef.current.focus();
    }
  }, [mode]);

  const startQuestions = (data?: IBuildMarksheetData) => {
    const { id } = data?.restricted?.buildMarksheet || {};

    if (id) {
      navigate(`${paths.questions.root}/solo/quiz/${id}`, {
        state: { leave: true },
      });

      if (onStartQuiz) {
        onStartQuiz();
      }
    } else {
      enqueueSnackbar('Unable to start expired session. Please try again.');
    }
  };

  const {
    preBuildData,
    preBuildLoading,
    buildLoading,
    buildQuestions,
    preBuildQuestions,
  } = useBuildMLAQuestions({
    source,
    search,
    marksheetId,
    onBuildComplete: startQuestions,
  });

  const { buildRef = 0 } = preBuildData || {};
  const { totalUnseen, totalSeenCorrect, totalSeenIncorrect } =
    totalDistribution;

  const globalLoading = preBuildLoading || buildLoading;
  const initailLoading = preBuildLoading && !isInitialized;

  useEffect(() => {
    if (preBuildLoading) {
      return;
    }

    const {
      unseen = 0,
      seenCorrect = 0,
      seenIncorrect = 0,
    } = preBuildData || {};

    setTotalDistribution({
      totalUnseen: unseen,
      totalSeenCorrect: seenCorrect,
      totalSeenIncorrect: seenIncorrect,
    });
    const newQuestionsAttmepted = [];

    if (unseen) {
      newQuestionsAttmepted.push(QuestionAttempt.Unseen);
    }

    if (seenCorrect) {
      newQuestionsAttmepted.push(QuestionAttempt.SeenCorrect);
    }

    if (seenIncorrect) {
      newQuestionsAttmepted.push(QuestionAttempt.SeenIncorrect);
    }

    setValue('questionsAttempt', newQuestionsAttmepted);
  }, [preBuildLoading, preBuildData, setValue]);

  useEffect(() => {
    if (
      builderConfig &&
      isBuilderDifferent(builderConfig, builderConfigRef.current)
    ) {
      reset(parseBuilderConfig(builderConfig), { keepErrors: true });
    }
  }, [builderConfig, reset]);

  useEffect(() => {
    if (globalLoading) {
      return;
    }

    const { max, ...rest } = calcMaxAndDistributeQuestions(
      totalUnseen,
      totalSeenCorrect,
      totalSeenIncorrect,
      getValues('numberOfQuestions'),
      getValues('questionsAttempt')
    );

    setCurrentMaxValue(max);
    setDistribution(rest);

    const subscription = watch((data, { name, type }) => {
      const { questionsAttempt = [], numberOfQuestions = 0, difficulty } = data;
      if (name === 'numberOfQuestions') {
        trigger();
      }

      if (
        name === 'difficulty' &&
        type === 'change' &&
        difficulty &&
        selection
      ) {
        const [conditionIds, presentationIds] = selection;
        preBuildQuestions(
          conditionIds,
          presentationIds,
          difficulty as EDifficultyType[]
        );
      }

      const { max, ...rest } = calcMaxAndDistributeQuestions(
        totalUnseen,
        totalSeenCorrect,
        totalSeenIncorrect,
        numberOfQuestions,
        questionsAttempt
      );

      setCurrentMaxValue(max);
      setDistribution(rest);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    builderConfig,
    getValues,
    globalLoading,
    isValid,
    preBuildQuestions,
    selection,
    totalSeenCorrect,
    totalSeenIncorrect,
    totalUnseen,
    trigger,
    watch,
  ]);

  useEffect(() => {
    if (!isInitialized) {
      if (currentMaxValue) {
        setValue(
          'numberOfQuestions',
          Math.min(currentMaxValue, DEFAULT_QUESTION_NUMBER)
        );
        setIsInitialized(true);
        trigger();
      }
    } else {
      setValue(
        'numberOfQuestions',
        Math.min(Number(getValues('numberOfQuestions')), currentMaxValue)
      );
    }
  }, [getValues, isInitialized, currentMaxValue, setValue, trigger]);

  useEffect(() => {
    if (
      open &&
      !isInitialized &&
      (selection || (search && source)) &&
      !preBuildLoading &&
      !preBuildData
    ) {
      const [conditionIds, presentationIds] = selection || [];

      preBuildQuestions(
        conditionIds,
        presentationIds,
        QUESTION_DIFFICULTY_LEVELS
      );
    }
  }, [
    preBuildData,
    isInitialized,
    open,
    preBuildQuestions,
    selection,
    preBuildLoading,
    search,
    source,
  ]);

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  const handleBuild = useMemo(
    () =>
      handleSubmit(data => {
        if (data) {
          const { mode, secondsPerQuestion } = data;
          const params: IBuildMarksheetInput = {
            buildRef,
            isTest: mode === QuestionsMode.Test,
            secondsPerQuestion: Number(secondsPerQuestion),
            ...distibution,
          };

          buildQuestions(params);
        }
      }),
    [buildQuestions, buildRef, distibution, handleSubmit]
  );

  const QUESTION_ATTEMPT_OPTIONS = useMemo(
    () => [
      {
        label: (
          <SelectionLabel
            label="Not answered questions"
            selectedCount={distibution.unseen}
            totalCount={totalUnseen}
          />
        ),
        value: QuestionAttempt.Unseen,
        id: QuestionAttempt.Unseen,
      },

      {
        label: (
          <SelectionLabel
            label="Previously incorrectly answered"
            selectedCount={distibution.seenIncorrect}
            totalCount={totalSeenIncorrect}
          />
        ),
        value: QuestionAttempt.SeenIncorrect,
        id: QuestionAttempt.SeenIncorrect,
      },
      {
        label: (
          <SelectionLabel
            label="Previously correctly answered"
            selectedCount={distibution.seenCorrect}
            totalCount={totalSeenCorrect}
          />
        ),
        value: QuestionAttempt.SeenCorrect,
        id: QuestionAttempt.SeenCorrect,
      },
    ],
    [distibution, totalSeenCorrect, totalSeenIncorrect, totalUnseen]
  );

  const submitDisabled = (!isValid || globalLoading) && !search && !source;

  return (
    <Modal
      loading={buildLoading}
      maxWidth="md"
      noPaddingY
      onBack={onBack}
      onClose={handleClose}
      onSubmit={handleBuild}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={submitDisabled}
      submitLabel="start exercise"
      title="Set up the exercise"
    >
      <Box>
        <ModalSection sx={{ marginTop: 0 }} title="Number of Questions">
          {initailLoading ? (
            <Skeleton height={80} variant="rectangular" />
          ) : (
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    maximum: {currentMaxValue}
                  </InputAdornment>
                ),
                readOnly: globalLoading,
              }}
              control={control}
              fullWidth
              helperText="Total number of questions to be included in the exercise."
              label="Questions"
              name="numberOfQuestions"
              type="number"
            />
          )}
        </ModalSection>
        <ModalSection
          subTitle="You can choose more than one option."
          sx={{ marginBottom: 0 }}
          title="Questions type"
        >
          {initailLoading ? (
            <Skeleton height={100} variant="rectangular" />
          ) : (
            <SelectionCheckboxContainer>
              <FormCheckboxGroup
                control={control}
                controlSx={{
                  '& .MuiTypography-root': { width: '100%' },
                }}
                name="questionsAttempt"
                options={QUESTION_ATTEMPT_OPTIONS}
                readOnly={globalLoading}
              />
            </SelectionCheckboxContainer>
          )}
        </ModalSection>
        <ModalSection
          subTitle="You can choose more than one option."
          sx={{ marginBottom: 0 }}
          title="Difficulty level"
        >
          {initailLoading ? (
            <Skeleton height={100} variant="rectangular" />
          ) : (
            <FormCheckboxGroup
              control={control}
              name="difficulty"
              numericValue
              options={PRE_BUILD_DIFFICULTY_OPTIONS}
              readOnly={globalLoading}
              withInfo
            />
          )}
        </ModalSection>
        <ModalSection
          className="mode-type"
          subTitle="Test mode starts a timer and shows your answers at the end."
          sx={{ marginBottom: 0 }}
          title="Mode type"
          variant="row"
        >
          {initailLoading ? (
            <Skeleton height={48} variant="rectangular" />
          ) : (
            <FormSelect
              InputProps={{
                readOnly: globalLoading,
              }}
              control={control}
              dropdownPosition="top"
              name="mode"
              options={MODE_OPTIONS}
              select
              size="small"
            />
          )}
        </ModalSection>
        {mode === QuestionsMode.Test ? (
          <FormField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">seconds</InputAdornment>
              ),
              readOnly: globalLoading,
            }}
            control={control}
            fullWidth
            helperText="Time taken to answer one question."
            inputRef={timeInputRef}
            label="Question time"
            name="secondsPerQuestion"
            placeholder="ss"
            sx={{ marginTop: 6 }}
            type="number"
          />
        ) : null}
      </Box>
    </Modal>
  );
};

export default MLAPreBuildModal;
