import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  EProductType,
  IEntitlementStats,
  IEntitlementType,
  IOsceType,
  ITopicType,
} from '@quesmed/types-rn/models';

import { Body } from 'components/Typography';
import { ChevronRightIcon } from 'components/Icons';
import ProgressChart from './ProgressChart';
import { NonNullableFields, Nullable } from 'types';
import { isStationsProduct } from 'utils';

interface PerformanceItemCardProps {
  data: IEntitlementType | IOsceType | IEntitlementStats;
  onClick: () => void;
  product: Nullable<EProductType>;
}

const Card = styled(Box)(({ theme: { palette, spacing } }) => ({
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: '7px',
  cursor: 'pointer',
  padding: spacing(3, 4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ProgressAndTopic = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
}));

const ProgressChartContainer = styled(Box)({
  width: '48px',
});

const SecondaryBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

// TODO this new names should be set based on ETopicType but this is not ready yet on the BE
// After the BE is prepared for that we should updated this component and remove this helper

const PerformanceItemCard = ({
  data,
  onClick,
  product,
}: PerformanceItemCardProps) => {
  const { completed, total } = data as IOsceType;
  const {
    totalQuestions = 0,
    correctQuestions = 0,
    incorrectQuestions = 0,
    completedQuestions = 0,
  } = data as NonNullableFields<ITopicType>;
  const { name } = data;

  const isStations = isStationsProduct(product);
  const isPaces = product === EProductType.PACES;

  const {
    correctCount,
    incomplete,
    incorrectCount,
    totalCount,
    textInformation,
  } = useMemo(() => {
    if (isStations) {
      return {
        incorrectCount: 0,
        correctCount: completed,
        totalCount: total,
        incomplete: total - completed,
        textInformation: `${completed} / ${total} ${
          isPaces ? 'Videos' : 'Stations'
        }`,
      };
    }

    return {
      incorrectCount: incorrectQuestions,
      correctCount: correctQuestions,
      totalCount: completedQuestions,
      incomplete: completedQuestions === 0 ? 100 : 0,
      textInformation: `${completedQuestions} / ${totalQuestions} Questions`,
    };
  }, [
    completed,
    completedQuestions,
    correctQuestions,
    incorrectQuestions,
    isStations,
    total,
    isPaces,
    totalQuestions,
  ]);

  const chart = useMemo(
    () => (
      <ProgressChartContainer>
        <ProgressChart
          correct={correctCount}
          incomplete={incomplete}
          incorrect={incorrectCount}
          total={totalCount}
        />
      </ProgressChartContainer>
    ),
    [correctCount, incomplete, incorrectCount, totalCount]
  );

  if (isStations && total === 0) {
    return null;
  }

  return (
    <Card onClick={onClick}>
      <ProgressAndTopic>
        {chart}
        <Box>
          <Body bold small>
            {name}
          </Body>
          <SecondaryBody small>{textInformation}</SecondaryBody>
        </Box>
      </ProgressAndTopic>
      <ChevronRightIcon />
    </Card>
  );
};

export default PerformanceItemCard;
