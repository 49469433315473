import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import { LearningStatusLabels } from '../types';

const getReadingStatusLabel = (
  status?: Nullable<EUserLearningStatus>,
  video = false
) => {
  switch (status) {
    case EUserLearningStatus.URGENT:
      return LearningStatusLabels.Urgent;
    case EUserLearningStatus.REVISING:
      return LearningStatusLabels.Revising;
    case EUserLearningStatus.COMPLETED:
      return video
        ? LearningStatusLabels.Watched
        : LearningStatusLabels.Complete;
    case EUserLearningStatus.UNREAD:
    default:
      return video
        ? LearningStatusLabels.Unwatched
        : LearningStatusLabels.Unread;
  }
};

export default getReadingStatusLabel;
