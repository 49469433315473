import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EQuestionType } from '@quesmed/types-rn/models';

import { Body } from 'components/Typography';

export const Footer = styled('footer')(
  ({
    theme: {
      breakpoints,
      palette,
      mixins: { mockTest },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100dvw',
    height: mockTest.footer.minHeight.mobile,
    backgroundColor: palette.mockTest.main,
    flexDirection: 'column',

    [breakpoints.up('md')]: {
      height: mockTest.footer.minHeight.desktop,
      flexDirection: 'row',
    },
  })
);

export const Content = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar, mockTest },
    },
  }) => ({
    width: '100dvw',
    paddingTop: `calc(${appBar.mobile.minHeight}px + ${mockTest.toolBar.minHeight}px)`,
    minHeight: `calc(100dvh - ${mockTest.footer.minHeight.mobile}px)`,
    maxHeight: `calc(100dvh - ${mockTest.footer.minHeight.mobile}px)`,
    height: `calc(100dvh - ${mockTest.footer.minHeight.mobile}px )`,
    overflowY: 'auto',

    [breakpoints.up('md')]: {
      minHeight: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px)`,
      maxHeight: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px)`,
      height: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px )`,
      paddingTop: `calc(${appBar.desktop.minHeight}px + ${mockTest.toolBar.minHeight}px)`,
    },
  })
);

export const TopToolBar = styled(Box)(
  ({
    theme: {
      breakpoints,
      palette,
      spacing,
      zIndex,
      mixins: { mockTest, appBar },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    justifyContent: 'space-between',
    top: appBar.mobile.minHeight,
    width: '100dvw',
    height: mockTest.toolBar.minHeight,
    backgroundColor: palette.mockTest.light,
    zIndex: zIndex.appBar,
    padding: spacing(0, 4),

    [breakpoints.up('md')]: {
      padding: spacing(0, 6),
      top: appBar.desktop.minHeight,
    },
  })
);

export const ToolBarControls = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),

    [breakpoints.up('md')]: {
      gap: spacing(4),
    },
  })
);

export const ViewContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    width: '100%',
    padding: spacing(0, 4),

    [breakpoints.up('md')]: {
      padding: spacing(0, 6),
    },
  })
);

export const ToolsDivider = styled(Divider)(
  ({ theme: { spacing, palette } }) => ({
    width: '2px',
    margin: spacing(0, 2),
    backgroundColor: palette.mockTest.contrastText,
  })
);

export const IconButtonLabel = styled(Body)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginLeft: spacing(2),
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  })
);

export const QuestionsCountBox = styled(Box)(
  ({ theme: { palette, breakpoints, spacing } }) => ({
    color: palette.mockTest.contrastText,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),

    '& .label': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
        display: 'none',
      },

      '& .label': {
        display: 'block',
      },
    },
  })
);

export const QuestionsCount = styled(Body)(({ theme: { palette } }) => ({
  color: palette.mockTest.contrastText,
}));

export const RightControls = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: spacing(3),

    [breakpoints.up('md')]: {
      gap: spacing(6),
    },
  })
);

export const QuizContainer = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar, mockTest },
      spacing,
    },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    height: `calc(100dvh - ${mockTest.footer.minHeight.mobile}px - ${appBar.mobile.minHeight}px - ${mockTest.toolBar.minHeight}px)`,

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px - ${appBar.desktop.minHeight}px - ${mockTest.toolBar.minHeight}px)`,
    },

    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      gap: spacing(6),
    },
  })
);

export const LeftQuizColumn = styled(Box)<{ questiontype: EQuestionType }>(
  ({
    questiontype,
    theme: {
      breakpoints,
      spacing,
      mixins: { appBar, mockTest },
    },
  }) => ({
    width: '100%',
    padding: spacing(2, 0),

    [breakpoints.up('lg')]: {
      padding: spacing(4, 0),
      height: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px - ${appBar.desktop.minHeight}px - ${mockTest.toolBar.minHeight}px)`,
    },

    [breakpoints.up('lg')]: {
      overflowY: 'auto',
      padding: spacing(6, 6, 6, 0),
      width: `${questiontype === EQuestionType.MULTIPLE_ANSWERS ? 45 : 65}%`,
      marginRight: `-${spacing(6)}`,
    },

    [breakpoints.up('xxl')]: {
      width: `${questiontype === EQuestionType.MULTIPLE_ANSWERS ? 50 : 65}%`,
    },
  })
);

export const RightQuizColumn = styled(Box)<{ questiontype: EQuestionType }>(
  ({
    questiontype,
    theme: {
      breakpoints,
      spacing,
      palette,
      mixins: { appBar, mockTest },
    },
  }) => ({
    width: '100%',
    padding: spacing(2, 0),

    [breakpoints.up('md')]: {
      padding: spacing(4, 0),
      height: `calc(100dvh - ${mockTest.footer.minHeight.desktop}px - ${appBar.desktop.minHeight}px - ${mockTest.toolBar.minHeight}px)`,
    },

    [breakpoints.up('lg')]: {
      width: `calc(${
        questiontype === EQuestionType.MULTIPLE_ANSWERS ? 55 : 35
      }% + ${spacing(6)})`,
      overflowY: 'auto',
      padding: spacing(6),
      borderLeft: `${spacing(6)} solid ${palette.mockTest.main}`,
      marginRight: `-${spacing(6)}`,
    },

    [breakpoints.up('xxl')]: {
      width: `calc(${
        questiontype === EQuestionType.MULTIPLE_ANSWERS ? 50 : 35
      }% + ${spacing(6)})`,
    },
  })
);

export const RelatedContentContainer = styled(Box)(
  ({ theme: { spacing, breakpoints, mixins } }) => ({
    width: '100%',
    maxWidth: `${mixins.content.maxWidth}px`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 0, 20, 0),

    [breakpoints.down('md')]: {
      '&.withBackToLobby': {
        padding: spacing(0, 0, 40, 0),
      },
    },

    [breakpoints.up('md')]: {
      padding: spacing(8),
      maxWidth: `calc(${mixins.content.maxWidth}px + 64px)`,
    },

    [breakpoints.up('xxl')]: {
      padding: spacing(10),
      maxWidth: `calc(${mixins.content.maxWidth}px + 80px)`,
    },
  })
);

export const RightFooterControls = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { mockTest },
      palette,
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    height: mockTest.footer.minHeight.mobile,
    borderTop: `2px solid ${palette.mockTest.contrastText}`,
    width: '100%',
    justifyContent: 'end',

    [breakpoints.up('md')]: {
      borderTop: 0,
      marginLeft: 'auto',
      width: 'unset',
      height: mockTest.footer.minHeight.desktop,
    },
  })
);
