import React from 'react';
import { mdiPauseCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const PauseCircleOutlineIcon = (props: IconProps) => (
  <Icon path={mdiPauseCircleOutline} {...props} />
);

export default PauseCircleOutlineIcon;
