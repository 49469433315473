import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Modal, ModalProps } from 'components/Modal/Modal';
import { TableData } from 'types';
import ExerciseSummaryTable, {
  ExerciseSummaryTableProps,
} from './ExerciseSummaryTable';

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing(1, -8, 0),
  gap: spacing(6),
}));

interface ExerciseSummaryModalProps<T extends TableData>
  extends Pick<ModalProps, 'open' | 'onClose'>,
    ExerciseSummaryTableProps<T> {
  title: string;
}
function ExerciseSummaryModal<T extends TableData>({
  open,
  title,
  onClose,
  ...tableProps
}: ExerciseSummaryModalProps<T>): JSX.Element {
  return (
    <Modal
      disableContentScroll
      maxWidth="xl"
      noPaddingY
      onClose={onClose}
      open={open}
      showCloseButton
      showControls={false}
      sizeVariant="xl"
      title={title}
    >
      <StyledBox>
        <ExerciseSummaryTable {...tableProps} />
      </StyledBox>
    </Modal>
  );
}

export default ExerciseSummaryModal;
