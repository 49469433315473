import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Children } from 'types';
import ExerciseSummaryCard from './ExerciseSummaryCard';

const Container = styled(Box)(
  ({ theme: { spacing, breakpoints, mixins } }) => ({
    width: '100%',
    maxWidth: `${mixins.content.maxWidth}px`,
    margin: '0 auto',
    display: 'flex',
    padding: spacing(0, 0, 20, 0),

    [breakpoints.down('md')]: {
      '&.withBackToLobby': {
        padding: spacing(0, 0, 40, 0),
      },
    },

    [breakpoints.up('md')]: {
      padding: spacing(8),
      maxWidth: `calc(${mixins.content.maxWidth}px + 64px)`,
    },

    [breakpoints.up('xl')]: {
      padding: spacing(10),
      maxWidth: `calc(${mixins.exerciseSummary.content.maxWidth}px + 80px)`,
    },
  })
);

interface ExerciseSummaryLayoutProps {
  children: Children;
  className?: string;
}

const ExerciseSummaryLayout = ({
  children,
  className,
}: ExerciseSummaryLayoutProps): JSX.Element => (
  <Container className={className}>
    <ExerciseSummaryCard>{children}</ExerciseSummaryCard>
  </Container>
);

export default ExerciseSummaryLayout;
