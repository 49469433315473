import React from 'react';
import { IPrescribeAnswer, IQuestionPrescribe } from '@quesmed/types-rn/models';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import PrescriptionForm from './PrescriptionForm';

export const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(4),
  gap: spacing(4),
}));

interface PrescriptionExplanationProps {
  isPreview?: boolean;
  question: IQuestionPrescribe;
}

const PrescriptionExplanation = ({
  isPreview,
  question,
}: PrescriptionExplanationProps): JSX.Element => {
  const { prescribeAnswer, id } = question;

  return (
    <Container>
      {prescribeAnswer
        ? prescribeAnswer.map((answer, index) => {
            const title = answer.drug.label;
            const key = JSON.stringify(answer + String(index));
            const formattedAnswer = Object.entries(answer).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: { value: value.label },
              }),
              {} as IPrescribeAnswer
            );

            return (
              <PrescriptionForm
                answer={formattedAnswer}
                correct
                defaultExpanded={isPreview}
                key={key}
                preview={isPreview}
                questionId={id}
                title={title}
              />
            );
          })
        : null}
    </Container>
  );
};

export default PrescriptionExplanation;
