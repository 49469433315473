import React from 'react';

import OcsceKnowledgeLibrarySection from './OsceKnowledgeLibrarySection';

// There is no separate home page in the OSCEcase as there are no topics
// in the OSCE, just stations and their categories that don't have its own
// data or stats. This Home view is used to displaye Section view by default
// to follow the same route pattern in both library cases
const OsceKnowledgeLibraryHome = () => <OcsceKnowledgeLibrarySection />;

export default OsceKnowledgeLibraryHome;
