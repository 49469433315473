import { useSubscription } from '@apollo/client';
import {
  IOsceMarksheetData,
  IOsceMarksheetVar,
  OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import {
  IOsceRoleChangedData,
  IOsceRoleChangedVar,
  OSCE_ROLE_CHANGE,
} from '@quesmed/types-rn/resolvers/subscription';
import isNil from 'lodash/isNil';

const useOnRoleChange = (sessionId = '') => {
  useSubscription<IOsceRoleChangedData, IOsceRoleChangedVar>(OSCE_ROLE_CHANGE, {
    variables: {
      sessionId,
    },
    fetchPolicy: 'no-cache',
    skip: !sessionId,
    shouldResubscribe: true,
    onData: ({ client, data: subscriptionData }) => {
      const { members, users, osceMarksheetId } =
        subscriptionData.data?.osceRoleChange || {};

      if (osceMarksheetId) {
        const prevData = client.readQuery<
          IOsceMarksheetData,
          IOsceMarksheetVar
        >({
          variables: { id: Number(osceMarksheetId) },
          query: OSCE_MARKSHEET,
        });

        if (prevData) {
          const { osceMarksheet } = prevData.restricted || {};
          const newOsceMarksheet = { ...osceMarksheet };

          if (!isNil(users)) {
            newOsceMarksheet.users = users;
          }

          if (!isNil(members)) {
            newOsceMarksheet.members = members;
          }

          client.writeQuery({
            query: OSCE_MARKSHEET,
            variables: { id: Number(osceMarksheetId) },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                osceMarksheet: {
                  ...newOsceMarksheet,
                },
              },
            },
          });
        }
      }
    },
  });
};

export default useOnRoleChange;
