import React from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Body } from 'components/Typography';

const CONTENT =
  'It looks like the session is still going on. Before you go, are you sure you want to leave?';

type LeaveExerciseModalProps = Omit<
  ModalProps,
  'cancelLabel' | 'submitLabel' | 'title'
>;

const LeaveExerciseModal = ({
  onClose,
  onSubmit,
  open,
  ...props
}: LeaveExerciseModalProps): JSX.Element => (
  <Modal
    cancelLabel="Cancel"
    danger
    noPaddingY
    onClose={onClose}
    onSubmit={onSubmit}
    open={open}
    showCloseButton
    sizeVariant="md"
    submitLabel="Leave Session"
    title="Are you sure you want to exit?"
    {...props}
  >
    <Body>{CONTENT}</Body>
  </Modal>
);

export default LeaveExerciseModal;
