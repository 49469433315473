import { IOsceMarksheet } from '@quesmed/types-rn/models';

import { formatSeconds, minutesToSeconds } from 'utils';

const SAMPLE_DEFAULT_TIMER_DATA = {
  feedbackTimeSec: 300,
  readingTimeSec: 30,
  stationTimeSec: 600,
  totalTimeNumber: 930,
  totalTimeString: '15:30',
};

export interface TimerDataValues {
  totalTimeString: string;
  totalTimeNumber: number;
  readingTimeSec: number;
  stationTimeSec: number;
  feedbackTimeSec: number;
}

export const setTimerDataValues = (
  data: IOsceMarksheet,
  isSample = false
): TimerDataValues => {
  if (isSample) {
    return SAMPLE_DEFAULT_TIMER_DATA;
  }
  const { feedbackTime, readingTime: readingTimeSec, stationTime } = data;

  const feedbackTimeSec = minutesToSeconds(feedbackTime);
  const stationTimeSec = minutesToSeconds(stationTime);
  const totalTimeNumber = readingTimeSec + stationTimeSec + feedbackTimeSec;

  return {
    totalTimeString: formatSeconds(totalTimeNumber),
    totalTimeNumber,
    readingTimeSec,
    feedbackTimeSec,
    stationTimeSec,
  };
};
