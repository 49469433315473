import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EProductType,
  EStudyAction,
  IMarksheet,
  IMarksheetMark,
} from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import {
  calcQuizTimeTaken,
  calculatePercent,
  formatDuration,
  setQuestionStatus,
} from 'utils';
import {
  ExerciseProgressStatus,
  ExerciseType,
  StartOrJoinMutation,
  Undefinable,
} from 'types';
import {
  ExerciseSummary,
  SummaryColumn,
  SummaryTableKey,
  SummaryTabs,
} from 'components/ExerciseSummary';
import { setQuestionsProgressData } from './setQuestionsProgressData';
import { paths } from 'Router';
import constants from 'config/constants';
import QuestionValidationMark from './QuestionValidationMark';
import { ParticipantList } from 'components/ParticipantCard';
import { ExerciseLayout } from 'components/Layout';
import { useAuth, useCurrentUser } from 'Auth';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { useIsMobile, useLeaveExerciseState } from 'hooks';
import SavePresetsModal from 'components/PresetsBuilder/SavePresetsModal';
import {
  HeadSnowflakeOutlineIcon,
  StickerTextOutlineIcon,
} from 'components/Icons';
import { usePlatform } from 'context/PlatformState';
import { useLearningPointsCheckState } from './context/LearningPointsContext';

const { questions } = paths;

const { MILISECONDS_IN_SECOND } = constants;

export const checkResult = (marks?: IMarksheetMark[]) => {
  let correct = 0;
  let incorrect = 0;

  if (!marks) {
    return { correct, incorrect, total: 0 };
  }

  marks.forEach(mark => {
    const status = setQuestionStatus(mark, true);
    switch (status) {
      case ExerciseProgressStatus.NotAnswered:
        break;
      case ExerciseProgressStatus.Correct:
        correct += 1;
        break;
      case ExerciseProgressStatus.Incorrect:
      default:
        incorrect += 1;
        break;
    }
  });

  return { correct, incorrect, total: marks.length };
};

export const columns: SummaryColumn<IMarksheetMark>[] = [
  {
    key: SummaryTableKey.Number,
    align: 'left',
    label: SummaryTableKey.Number,
    formatter: (mark, number) => `Question ${number}`,
    type: 'default',
  },
  {
    key: SummaryTableKey.Content,
    align: 'left',
    label: SummaryTableKey.Content,
    formatter: ({ question }) => question.question,
    type: 'default',
  },
  {
    key: SummaryTableKey.Topic,
    align: 'left',
    label: SummaryTableKey.Topic,
    formatter: ({ question }) => {
      const { topic } = question.concept || {};
      const { name = 'Topic name' } = topic || {};

      return name;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Concept,
    align: 'left',
    label: SummaryTableKey.Concept,
    formatter: ({ question }) => {
      const { concept } = question;
      const { name = '' } = concept || {};

      return name;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Result,
    align: 'left',
    label: SummaryTableKey.Result,
    resultFormatter: mark => {
      const status = setQuestionStatus(mark, true);

      return {
        icon: <QuestionValidationMark showAsNotAnswered status={status} />,
        status,
        label: status.replace('-', ' '),
      };
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Chevron,
    align: 'left',
    label: 'chevron',
    type: 'chevron',
  },
];

const learningPointsColumns: SummaryColumn<IMarksheetMark>[] = [
  {
    align: 'left',
    key: SummaryTableKey.Checkbox,
    type: 'input',
    label: 'learning-points',
  },
  {
    key: SummaryTableKey.Number,
    align: 'left',
    label: SummaryTableKey.Number,
    formatter: (_, number) => `Question ${number}`,
    type: 'default',
  },
  {
    key: SummaryTableKey.LearningPoint,
    align: 'left',
    label: SummaryTableKey.LearningPoint,
    formatter: ({ question }) => question.learningPoint,
    type: 'default',
  },
  {
    key: SummaryTableKey.Concept,
    align: 'left',
    label: SummaryTableKey.Concept,
    formatter: ({ question }) => {
      const { concept } = question;
      const { name = '' } = concept || {};

      return name;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Result,
    align: 'left',
    label: SummaryTableKey.Result,
    resultFormatter: mark => {
      const status = setQuestionStatus(mark, true);

      return {
        icon: <QuestionValidationMark showAsNotAnswered status={status} />,
        status,
        label: status.replace('-', ' '),
      };
    },
    type: 'default',
  },
];

const getBackLabel = (
  isSample: boolean,
  isMla: boolean,
  isAuthenticated: boolean
) => {
  if (isSample) {
    return isAuthenticated ? 'Back to app management' : 'Back to login';
  }

  return isMla ? 'Back to content map' : 'Back to lobby';
};

export const getSummary = (total: number, correct: number, incorrect: number) =>
  `${ExerciseType.Questions} answered: ${correct + incorrect} out of ${total}`;
const extractConceptIdsFromMarks = (marks: Undefinable<IMarksheetMark[]>) => {
  return [...new Set((marks || []).map(mark => mark.question.conceptId))];
};
interface QuestionsSummaryProps {
  marksheet?: IMarksheet;
  loading: boolean;
  restartSession?: StartOrJoinMutation;
  isSample?: boolean;
}

const tabs = [
  { title: SummaryTabs.LearningPoints, icon: <HeadSnowflakeOutlineIcon /> },
  { title: SummaryTabs.Content, icon: <StickerTextOutlineIcon /> },
];

const QuestionsSummary = ({
  loading,
  marksheet,
  restartSession,
  isSample = false,
}: QuestionsSummaryProps): JSX.Element => {
  const { id } = useCurrentUser();
  const navigate = useNavigate();
  const {
    id: marksheetId,
    sessionId,
    marks,
    solo,
    timeTaken = 0,
    activeUsers = [],
    completed,
    source,
    entitlement,
  } = marksheet || {};
  const { correct, incorrect, total } = checkResult(marks);
  const [openSavePresetModal, setOpenSavePresetModal] = useState(false);
  const { openLeaveModal } = useLeaveExerciseState();
  const { isMobile } = useIsMobile();
  const { isAuthenticated } = useAuth();
  const { product } = usePlatform();
  const [activeTab, setActiveTab] = useState(
    product === EProductType.QBANK ? tabs?.[0].title : tabs?.[1].title
  );
  const { setMarks } = useLearningPointsCheckState();

  const duration = formatDuration(
    (completed ? timeTaken : calcQuizTimeTaken(marks)) * MILISECONDS_IN_SECOND
  );

  const handleBackToReview = (mark: IMarksheetMark) => {
    const { id: markId, question } = mark;
    const { id: questionId } = question;

    const index = marks?.findIndex(({ id }) => Number(id) === Number(markId));

    if (!isNil(index)) {
      const questionNumber = index + 1;

      let path = '';

      if (completed) {
        path = solo
          ? `${questions.root}/review/${marksheetId}/${markId}/${questionId}/${questionNumber}`
          : `${questions.root}/group/quiz/${sessionId}/${marksheetId}/${markId}/${questionId}/${questionNumber}`;
      } else {
        path = solo
          ? `${questions.root}/solo/quiz/${marksheetId}/${markId}/${questionId}/${questionNumber}`
          : `${questions.root}/group/quiz/${sessionId}/${marksheetId}`;
      }

      navigate(path);
    }
  };

  const handleLeave = () => {
    openLeaveModal();
  };

  const handleBackToLobby = () => {
    if (source === 'mla') {
      navigate(paths.mlaContentMap.root);

      return;
    }
    if (solo) {
      navigate(paths.questions.root);
    } else {
      restartSession?.(EStudyAction.START);
    }
  };

  const handleClosePresetModal = () => {
    setOpenSavePresetModal(false);
  };

  const conceptIds = extractConceptIdsFromMarks(marks);

  useEffect(() => {
    if (marks) {
      setMarks(marks);
    }
  }, [marks, setMarks]);

  return (
    <ExerciseLayout
      bottomOffsetVariant={isMobile ? 'large' : 'tiny'}
      controlPanelContent={
        activeUsers && !solo ? (
          <ParticipantList
            currentUserId={Number(id)}
            participants={activeUsers}
            variant="panel"
            withAudio
          />
        ) : null
      }
      exerciseContent={
        <>
          <ExerciseSummary
            activeTab={activeTab}
            backToLobby={handleBackToLobby}
            backToLobbyLabel={getBackLabel(
              isSample,
              source === 'mla',
              isAuthenticated
            )}
            correctCount={correct}
            correctLabel="Correct answers"
            data={setQuestionsProgressData(correct, incorrect)}
            duration={duration}
            incorrectCount={incorrect}
            loading={loading}
            score={calculatePercent(correct, total)}
            setActiveTab={setActiveTab}
            showExport
            tableData={{
              columns:
                activeTab === SummaryTabs.Content
                  ? columns
                  : learningPointsColumns,
              data: marks,
              onClick:
                isSample || activeTab === SummaryTabs.LearningPoints
                  ? undefined
                  : handleBackToReview,
              withoutOffset: activeTab === SummaryTabs.LearningPoints,
            }}
            tabs={product === EProductType.QBANK ? tabs : [tabs[1]]}
            title={ExerciseType.Questions}
            total={total}
          />
          {!isSample && (
            <SavePresetsModal
              closePresetModal={handleClosePresetModal}
              conceptIds={conceptIds}
              entitlementId={entitlement?.id}
              loading={loading}
              openSavePresetModal={openSavePresetModal}
            />
          )}
        </>
      }
      exerciseItemsCount={activeUsers?.length}
      onLeave={handleLeave}
      withSound={!solo}
      withoutPanel={solo}
      withoutVerticalOffset
      {...(!solo && PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS)}
    />
  );
};

export default QuestionsSummary;
