import { MutableRefObject } from 'react';

import { Undefinable } from 'types';

const THREE_ITEMS_HEIGHT = 225;

export const isNear = (
  ref: MutableRefObject<Undefinable<HTMLElement>>
): boolean => {
  if (ref.current) {
    const { scrollHeight, scrollTop, clientHeight } = ref.current;

    return clientHeight + scrollTop + THREE_ITEMS_HEIGHT >= scrollHeight;
  }

  return false;
};
