import React from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { Modal, ModalProps } from 'components/Modal';
import { FormField } from 'components/TextField';
import { useUpdateUserSettings } from 'hooks';

interface DailyFlashcardsLimitModalProps extends ModalProps {
  flashcardsLimit: number;
  onClose: () => void;
  open: boolean;
}

interface FlashcardsInput {
  limit: number;
}

const DAILY_FEED_MAX_MIN_VALUE = 0;
const DAILY_FEED_MAX_MAX_VALUE = 250;

const dailyFeedMaxMessage = `Max daily feed must be an integer between ${DAILY_FEED_MAX_MIN_VALUE} and ${DAILY_FEED_MAX_MAX_VALUE}`;

const schema = Joi.object<FlashcardsInput>({
  limit: Joi.number()
    .integer()
    .min(DAILY_FEED_MAX_MIN_VALUE)
    .max(DAILY_FEED_MAX_MAX_VALUE)
    .messages({
      'number.base': dailyFeedMaxMessage,
      'number.integer': dailyFeedMaxMessage,
      'number.min': dailyFeedMaxMessage,
      'number.max': dailyFeedMaxMessage,
    }),
});

const DailyFlashcardsLimitModal = ({
  flashcardsLimit,
  onClose,
  open,
}: DailyFlashcardsLimitModalProps): JSX.Element => {
  const defaultValues = {
    limit: flashcardsLimit,
  };
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const { loading, updateUserSettings } = useUpdateUserSettings();

  const handleEditCardsLimit = ({ limit }: FlashcardsInput) => {
    updateUserSettings(
      {
        globalSettings: {
          dailyFeedMax: Number(limit),
        },
      },
      {
        successMsg: `Updated daily cards limit to ${limit}`,
        successCallback: onClose,
      }
    );
  };

  return (
    <Modal
      description="Note: Changes take effect the following day."
      noPaddingY
      onClose={onClose}
      onSubmit={handleSubmit(handleEditCardsLimit)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={!isValid || !isDirty || loading}
      submitLabel="Edit cards limit"
      title="Edit cards limit"
    >
      <FormField
        InputProps={{
          inputProps: {
            min: DAILY_FEED_MAX_MIN_VALUE,
            max: DAILY_FEED_MAX_MAX_VALUE,
          },
        }}
        control={control}
        fullWidth
        helperText="Maximum number of daily flashcards to review in 24 hours"
        label="Daily cards limit"
        name="limit"
        type="number"
      />
    </Modal>
  );
};

export default DailyFlashcardsLimitModal;
