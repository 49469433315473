import React from 'react';

import { usePlatform } from 'context/PlatformContext';
import { QbankVideoLibrarySection } from './qbank';
import { OsceVideoLibrarySection } from './osce';
import { isStationsProduct } from 'utils';

const VideoLibrarySection = (): JSX.Element => {
  const { product } = usePlatform();

  return isStationsProduct(product) ? (
    <OsceVideoLibrarySection />
  ) : (
    <QbankVideoLibrarySection />
  );
};

export default VideoLibrarySection;
