import React from 'react';

import QuestionsQuizLeaveModal from 'pages/Questions/QuestionsQuizLeaveModal';
import { useLeaveExerciseState, useMarksheetLeavingBlockade } from 'hooks';
import QuestionsQuizContainer from './QuestionsQuizContainer';
import { useFlaggedQuestionsMarksheetQuery } from './hooks/useFlaggedQuestionsMarksheetQuery';

const FlaggedQuestionsReview = (): JSX.Element => {
  const { open } = useLeaveExerciseState();
  const { data, loading, error } = useFlaggedQuestionsMarksheetQuery();

  const { id, completed } = data || {};

  useMarksheetLeavingBlockade({
    marksheetId: Number(id),
    shouldBlock: Boolean(id),
    ignoreBlockade: Boolean(completed),
    solo: true,
  });

  return (
    <>
      <QuestionsQuizContainer
        error={error}
        flagged
        isSampleGame={false}
        loading={loading}
        marksheet={data}
        review
      />
      {open ? (
        <QuestionsQuizLeaveModal
          marksheet={data}
          marksheetId={id}
          open={open}
        />
      ) : null}
    </>
  );
};

export default FlaggedQuestionsReview;
