import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EProductType } from '@quesmed/types-rn/models';

import { usePlatform } from 'context/PlatformContext';
import { APPS, Nullable } from 'types';
import { Layout } from 'components/Layout';
import { NAVIGATION_ENTRIES } from './navigation';
// import UniversityLeaderboardList from 'pages/dashboard/components/UniversityLeaderboard/UniversityLeaderboardList';
import { Dashboard } from 'pages/dashboard';
import {
  MockTestsLayout,
  MockTestsQuizBuilder,
  MockTestsQuizIntro,
  MockTestsQuizQuery,
  MockTestSummary,
} from 'pages/MockTests';
import StudyBuilder from 'pages/Stations/StudyBuilder';
import {
  FlaggedQuestionsReview,
  QuestionQuery,
  QuestionsGroupStudyController,
  QuestionsGroupStudyLobby,
  QuestionsGroupStudyQuiz,
  QuestionsGroupStudyQuizBuilder,
  QuestionsGroupStudySummary,
  QuestionsQuizBuilder,
  QuestionsQuizQuery,
  QuestionsReview,
  QuestionsSoloStudySummary,
} from 'pages/Questions';
import { MLAContentMap } from 'pages/MLAContentMap';
import ComponentTestPage from 'pages/Internal/Testing/ComponentTestPage';
import NotFound from 'pages/NotFound/NotFound';
import { FLASHCARDS_PRODUCTS, isDev } from 'config/constants';
import { AppsOverview } from 'pages/AppsOverview';
import { paths } from 'Router';
import { RootViewContainer } from 'components/RootViewContainer';
import {
  FlashcardsQuizBuilder,
  FlashcardsQuizQuery,
  FlashcardsSummary,
} from 'pages/Flashacards';
import {
  StationsGroupStudy,
  StationsGroupStudyController,
  StationsGroupStudyLobby,
  StationsGroupStudyStationBuilder,
  StationsGroupStudySummary,
  StationsGroupStudyWaitingRoom,
  StationsReview,
  StationsSoloStudy,
  StationsSoloStudySummary,
} from 'pages/Stations';
import {
  KnowledgeLibrary,
  KnowledgeLibraryChapter,
  KnowledgeLibraryHome,
  KnowledgeLibrarySection,
} from 'pages/KnowledgeLibrary';
import {
  VideoLibrary,
  VideoLibraryHome,
  VideoLibrarySection,
  VideoLibraryVideoDetails,
} from 'pages/VideoLibrary';
import { Pricing } from 'pages/Pricing';
import { PaymentComplete } from 'pages/PaymentComplete';
import { useDemo, useSubscription } from 'Auth';
import { RedirectPresetLink } from 'components/PresetsBuilder';
import { CircularProgress } from 'components/CircularProgress';
import { MyNotes } from 'pages/PracticeMaterials';

const Sample = lazy(() => import('../pages/Sample/Sample'));
const SampleQuestionsQuery = lazy(
  () => import('../pages/Sample/SampleQuestionsQuery')
);
const SampleQuestionsSummary = lazy(
  () => import('../pages/Sample/SampleQuestionsSummary')
);
const SampleStationsQuery = lazy(
  () => import('../pages/Sample/SampleStationsQuery')
);
const SampleStationsSummary = lazy(
  () => import('../pages/Sample/SampleStationsSummary')
);
const SampleMockTestsQuery = lazy(
  () => import('../pages/Sample/SampleMockTestsQuery')
);
const KnowledgeLibraryEdit = lazy(
  () => import('../pages/KnowledgeLibrary/KnowledgeLibraryEdit')
);

const {
  flashcards,
  questions,
  mockTests,
  stations,
  videoLibrary,
  knowledgeLibrary,
  mlaContentMap,
  practiceMaterials,
} = paths;

const checkModule =
  (products: EProductType | EProductType[]) =>
  (availableProducts: EProductType[]) => {
    if (!availableProducts.length || !products) {
      return false; // we do not have demo yet
    }

    if (Array.isArray(products)) {
      return availableProducts.some(product => products.includes(product));
    }

    return availableProducts.includes(products);
  };

const checkFlashcards = checkModule(FLASHCARDS_PRODUCTS);
const checkMockTest = checkModule([
  EProductType.QBANK,
  EProductType.ANATOMY,
  EProductType.MEDICAL_SCIENCES,
  EProductType.PLAB1,
  EProductType.MRCP_PART1,
  EProductType.MRCP_PART2,
]);
const checkQuestions = checkModule([
  EProductType.QBANK,
  EProductType.MSRA,
  EProductType.ANATOMY,
  EProductType.MEDICAL_SCIENCES,
  EProductType.MRCP_PART1,
  EProductType.MRCP_PART2,
  EProductType.PLAB1,
]);
const checkMlaContentMap = checkModule([
  EProductType.QBANK,
  EProductType.PLAB1,
]);
const checkStations = checkModule([
  EProductType.OSCE,
  EProductType.PACES,
  EProductType.PLAB2,
]);
const checkVideos = checkModule([
  EProductType.QBANK,
  EProductType.OSCE,
  EProductType.MRCP_PART1,
  EProductType.MRCP_PART2,
  EProductType.PACES,
  EProductType.ANATOMY,
  EProductType.MEDICAL_SCIENCES,
  EProductType.INTERVIEW_ANAESTHETICS,
  EProductType.INTERVIEW_IMT,
  EProductType.INTERVIEW_CST,
  EProductType.INTERVIEW_RADIOLOGY,
  EProductType.PLAB1,
  EProductType.PLAB2,
]);

const checkQuestionGroupStudy = checkModule([
  EProductType.QBANK,
  EProductType.ANATOMY,
  EProductType.MEDICAL_SCIENCES,
  EProductType.PLAB1,
]);

const checkIsInterviewProduct = (product: Nullable<EProductType>) => {
  if (!product) {
    return false;
  }

  return [
    EProductType.INTERVIEW_ANAESTHETICS,
    EProductType.INTERVIEW_IMT,
    EProductType.INTERVIEW_CST,
    EProductType.INTERVIEW_RADIOLOGY,
    EProductType.INTERVIEW_PAEDIATRICS,
  ].includes(product);
};

export const ProvisionedApp = (): JSX.Element => {
  const isDemo = useDemo();
  const { availableProducts, app, product } = usePlatform();
  const showQuestions = checkQuestions(availableProducts);
  const showMockTests = checkMockTest(availableProducts);
  const showFlashcards = checkFlashcards(availableProducts);
  const showMlaContentMap = checkMlaContentMap(availableProducts);
  const showStations = checkStations(availableProducts);
  const showVideos = checkVideos(availableProducts);
  const subscription = useSubscription();
  const hasSubscription =
    Boolean(subscription.apps && subscription.products) || isDemo;
  const questionsGroupStudy =
    showQuestions && product && checkQuestionGroupStudy([product]);

  const isInterviewProduct = checkIsInterviewProduct(product);
  const isPaces = product === EProductType.PACES;

  const getDashboardElement = () => {
    if (isInterviewProduct) {
      return <Navigate replace to={knowledgeLibrary.root} />;
    }

    if (isPaces) {
      return <Navigate replace to={videoLibrary.root} />;
    }

    return <Dashboard />;
  };

  return (
    <Routes>
      <Route
        element={<Layout appData={app ? APPS[app] : undefined} simplified />}
      >
        {/* APP MANAGEMENT AND PRICING */}
        <Route element={<AppsOverview />} path={paths.appsManagement} />
        <Route element={<Pricing />} path={paths.pricing.appType} />
        <Route element={<PaymentComplete />} path={paths.paymentComplete} />
        {/* SAMPLE */}
        <Route
          element={
            <Suspense fallback={<CircularProgress />}>
              <Sample />
            </Suspense>
          }
          path={paths.sample.root}
        >
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsQuery />
              </Suspense>
            }
            path={paths.sample.questions}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsSummary />
              </Suspense>
            }
            path={paths.sample.questionsSummary}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleStationsQuery />
              </Suspense>
            }
            path={paths.sample.stations}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleStationsSummary />
              </Suspense>
            }
            path={paths.sample.stationsSummary}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleMockTestsQuery />
              </Suspense>
            }
            path={paths.sample.mockTests}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsSummary />
              </Suspense>
            }
            path={paths.sample.mockTestsSummary}
          />
        </Route>
      </Route>
      <Route
        element={
          <Layout
            appData={app ? APPS[app] : undefined}
            simplified={product === null}
          />
        }
      >
        {/* COMMON */}
        <Route
          element={<Navigate replace to={paths.dashboard} />}
          path={paths.root}
        />

        <Route
          element={<Navigate replace to={paths.appsManagement} />}
          path={paths.login}
        />

        <Route
          element={<Navigate replace to={paths.appsManagement} />}
          path={paths.register}
        />

        <Route
          element={<Navigate replace to={paths.appsManagement} />}
          path={paths.onboarding.root}
        />
        <Route element={<NotFound />} path={paths.notFound} />
        <Route element={<NotFound />} path="*" />
      </Route>

      {hasSubscription && app ? (
        <>
          {/* MOCK TESTS DEDICATED LAYOUT */}
          <Route element={<MockTestsLayout />} path={paths.root}>
            <Route element={<RootViewContainer />} path={mockTests.root}>
              <Route element={<MockTestSummary />} path={mockTests.summary} />
              <Route element={<MockTestsQuizQuery />} path={mockTests.test} />
              <Route element={<MockTestsQuizIntro />} path={mockTests.intro} />
              <Route element={<NotFound />} path="*" />
            </Route>
          </Route>

          <Route
            element={
              <Layout
                appData={APPS[app]}
                navigationEntries={
                  product ? NAVIGATION_ENTRIES[product] : undefined
                }
              />
            }
            path={paths.root}
          >
            {/* MOCK TESTS */}
            {showMockTests ? (
              <Route element={<RootViewContainer />} path={mockTests.root}>
                <Route element={<MockTestsQuizBuilder />} index />
                <Route element={<NotFound />} path="*" />
              </Route>
            ) : null}

            {/* FLASHCARDS */}
            {showFlashcards ? (
              <Route element={<RootViewContainer />} path={flashcards.root}>
                <Route element={<FlashcardsQuizBuilder />} index />
                <Route
                  element={<FlashcardsQuizQuery />}
                  path={flashcards.quiz}
                />
                <Route
                  element={<FlashcardsSummary />}
                  path={flashcards.summary}
                />
                <Route
                  element={<RedirectPresetLink />}
                  path={flashcards.presetShareLink}
                />
                <Route element={<NotFound />} path="*" />
              </Route>
            ) : null}

            {/* QUESTIONS */}
            {showQuestions ? (
              <Route element={<RootViewContainer />} path={questions.root}>
                <Route element={<QuestionsQuizBuilder />} index />
                <Route
                  element={<QuestionsSoloStudySummary />}
                  path={questions.soloQuizSummary}
                />
                <Route
                  element={<QuestionsQuizQuery />}
                  path={questions.soloQuiz}
                />
                <Route
                  element={<RedirectPresetLink />}
                  path={questions.presetShareLink}
                />
                {/* QUESTIONS GROUP */}
                {questionsGroupStudy && !isDemo ? (
                  <Route
                    element={<QuestionsGroupStudyController />}
                    path={questions.groupLobby}
                  >
                    <Route element={<QuestionsGroupStudyLobby />} index />
                    <Route
                      element={<QuestionsGroupStudyQuizBuilder />}
                      path={questions.groupQuizBuilder}
                    />
                    <Route
                      element={<QuestionsGroupStudySummary />}
                      path={questions.groupQuizSummary}
                    />
                    <Route
                      element={<QuestionsGroupStudyQuiz />}
                      path={questions.groupQuiz}
                    />
                  </Route>
                ) : null}
                <Route element={<QuestionQuery />} path={questions.question} />
                <Route element={<QuestionsReview />} path={questions.review} />
                <Route
                  element={<FlaggedQuestionsReview />}
                  path={questions.flagged}
                />
                <Route element={<NotFound />} path="*" />
              </Route>
            ) : null}

            {/* MLA CONTENT MAP */}
            {showMlaContentMap ? (
              <Route element={<RootViewContainer />} path={mlaContentMap.root}>
                <Route element={<MLAContentMap />} index />
              </Route>
            ) : null}

            {/* STATIONS */}
            {showStations ? (
              <Route element={<RootViewContainer />} path={stations.root}>
                <Route element={<StudyBuilder />} index />
                <Route
                  element={<StationsSoloStudy />}
                  path={stations.soloStudy}
                />
                <Route
                  element={<StationsSoloStudySummary />}
                  path={stations.soloStudySummary}
                />
                <Route element={<StationsReview />} path={stations.review} />
                <Route
                  element={<StudyBuilder />}
                  path={stations.soloStudybuilder}
                />
                {/* STATIONS GROUP */}
                {!isDemo ? (
                  <Route
                    element={<StationsGroupStudyController />}
                    path={stations.groupLobby}
                  >
                    <Route element={<StationsGroupStudyLobby />} index />
                    <Route
                      element={<StationsGroupStudyStationBuilder />}
                      path={stations.groupStudyBuilder}
                    />
                    <Route
                      element={<StationsGroupStudyWaitingRoom />}
                      path={stations.waitingRoom}
                    />
                    <Route
                      element={<StationsGroupStudy />}
                      path={stations.groupStudy}
                    />
                    <Route
                      element={<StationsGroupStudySummary />}
                      path={stations.groupStudySummary}
                    />
                  </Route>
                ) : null}
                <Route element={<NotFound />} path="*" />
              </Route>
            ) : null}

            {/* PRACTICE MATERIALS */}
            <Route path={practiceMaterials.root}>
              <Route element={<MyNotes />} path={practiceMaterials.myNotes} />
            </Route>

            {/* VIDEO MATERIALS */}
            {showVideos ? (
              <Route element={<VideoLibrary />} path={videoLibrary.root}>
                <Route element={<VideoLibraryHome />} index />
                <Route
                  element={<VideoLibrarySection />}
                  path={videoLibrary.section}
                />
                <Route
                  element={<VideoLibraryVideoDetails />}
                  path={videoLibrary.video}
                />
                <Route element={<NotFound />} path="*" />
              </Route>
            ) : null}

            {/* COMMON */}

            {/* KNOWLEDGE LIBRARY */}
            <Route element={<KnowledgeLibrary />} path={knowledgeLibrary.root}>
              <Route element={<KnowledgeLibraryHome />} index />
              <Route
                element={<KnowledgeLibrarySection />}
                path={knowledgeLibrary.section}
              />
              <Route
                element={<KnowledgeLibraryChapter />}
                path={knowledgeLibrary.chapter}
              />
              <Route
                element={<KnowledgeLibraryEdit />}
                path={knowledgeLibrary.edit}
              />
              <Route element={<NotFound />} path="*" />
            </Route>

            {/* TODO: Uncomment when Leaderboard view is ready */}
            {/* <Route
            element={<UniversityLeaderboardList />}
            path={paths.leaderboard}
          /> */}
            {isDev ? (
              <Route element={<ComponentTestPage />} path={paths.testing} />
            ) : null}
            <Route element={<Navigate replace to={paths.dashboard} />} index />
            <Route element={getDashboardElement()} path={paths.dashboard} />
            <Route
              element={<Navigate replace to={paths.dashboard} />}
              path={paths.login}
            />
            <Route
              element={<Navigate replace to={paths.dashboard} />}
              path={paths.register}
            />
            <Route element={<NotFound />} path={paths.notFound} />
            <Route element={<NotFound />} path="*" />
          </Route>
        </>
      ) : null}
    </Routes>
  );
};
