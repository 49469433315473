import React, { Key, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  EProductType,
  IOsceStationMark,
  IPaceScore,
} from '@quesmed/types-rn/models';
import { Dictionary } from 'lodash';

import { ExpandableSection } from 'components/ExpandableSection';
import Markdown, { MarkdownProps } from 'components/Markdown/Markdown';
import { entries } from 'utils';
import { StyledCheckbox } from 'components/Checkbox/Checkbox.styles';
import { usePlatform } from 'context/PlatformState';
import { RadioGroup, RadioOption } from 'components/RadioGroup';
import { LABELS } from 'types/osce';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(8),
}));

const MarksBox = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(-6, -8),
}));

const Mark = styled(Box)(({ theme: { palette, spacing } }) => ({
  padding: spacing(6, 8),
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:not(:nth-last-of-type(1))': {
    borderBottom: `1px solid ${palette.stroke.main}`,
  },

  '& .MuiCheckbox-root': {
    padding: spacing(0, 0, 0, 6),
  },
}));

const RadioBox = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'column',
    },

    '& .MuiFormControlLabel-root': {
      margin: 0,
      marginTop: '-9px',
      display: 'flex',
      justifyContent: 'space-between',
    },

    '& .osce-mark-choice': {
      backgroundColor: palette.background.paper,
      border: `1px solid ${palette.stroke.main}`,
      borderTop: 0,
      flex: '1 1 0',
      padding: spacing(4, 6),
    },

    [breakpoints.up('md')]: {
      '& .osce-mark-choice': {
        '&:first-of-type, &.last-of-type': {
          flex: '1 0 unset',
        },

        '&:nth-of-type(2)': {
          flex: '0 0 fit-content',
        },
      },

      '& .MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
      },
    },

    [breakpoints.up('xl')]: {
      '& .MuiFormGroup-root .osce-mark-choice': {
        padding: spacing(6, 8),
      },
    },
  })
);

const getMarkOptions = (choices: IPaceScore[]): RadioOption[] =>
  choices.map(({ type, name }) => ({
    label: LABELS[type],
    value: type,
    complementaryContent: name ? <Markdown text={name} /> : undefined,
  }));

interface MarschemeReadingSectionProps {
  marks: Dictionary<IOsceStationMark[]>;
}

interface MarkMarkdownProps {
  key: Key;
  text: MarkdownProps['text'];
  children?: ReactNode;
}

const MarkMarkdown = ({
  children,
  key,
  text,
}: MarkMarkdownProps): JSX.Element => (
  <Mark key={key}>
    <Markdown
      markdownStylesProps={{
        bottomOffset: false,
      }}
      text={text}
    />
    {children}
  </Mark>
);

const MarschemeReadingSection = ({
  marks,
}: MarschemeReadingSectionProps): JSX.Element => {
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;

  return (
    <Container>
      {entries(marks).map(([title, subset]) => (
        <ExpandableSection expanded key={title} size="small" title={title}>
          <MarksBox>
            {isPaces ? (
              <RadioBox>
                <RadioGroup
                  className="osce-mark-choice"
                  disabled
                  id="mark-choice"
                  labelPlacement="start"
                  options={getMarkOptions(subset[0].choices || [])}
                  readOnly
                />
              </RadioBox>
            ) : (
              subset.map(({ id, name }) => {
                const index = name?.indexOf('\n\n**');

                if (index > 0) {
                  const [label, header] = name.split('\n\n');

                  return (
                    <>
                      <MarkMarkdown key={`${id}-label`} text={label}>
                        <StyledCheckbox disabled />
                      </MarkMarkdown>
                      <MarkMarkdown key={`${id}-header`} text={header} />
                    </>
                  );
                }

                return (
                  <MarkMarkdown key={id} text={name}>
                    <StyledCheckbox disabled />
                  </MarkMarkdown>
                );
              })
            )}
          </MarksBox>
        </ExpandableSection>
      ))}
    </Container>
  );
};

export default MarschemeReadingSection;
