import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  EPracticeIntensity,
  IDailyProgressData,
} from '@quesmed/types-rn/models';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import clsx from 'clsx';

import { Tooltip } from 'components/Tooltip';
import { Body } from 'components/Typography';
import {
  constSize,
  formatDate,
  isValentinesDay,
  localStorageTyped,
} from 'utils';
import { useIsDesktop, useIsMobile } from 'hooks';
import { Undefinable } from 'types';

dayjs.extend(isToday);

const HeatmapSection = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(10, minmax(0, 1fr))',
  gap: spacing(1),
  padding: spacing(4, 0),

  [breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(20, minmax(0, 1fr))',
  },

  [breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(24, minmax(0, 1fr))',
    padding: spacing(7, 0),
  },
}));

const HeatmapItem = styled(Box)<{
  heatmapcolourname: string | null;
  intensity: number;
  today: 1 | 0;
}>(({ heatmapcolourname, intensity, theme: { palette }, today }) => {
  const getBackgroundColor = (isHeart?: boolean) => {
    switch (intensity) {
      case EPracticeIntensity.MED:
        return heatmapcolourname
          ? palette.heatmap[
              heatmapcolourname as unknown as keyof typeof palette.heatmap
            ].med
          : isHeart
          ? palette.error.main
          : palette.heatmap.amber.med;
      case EPracticeIntensity.HIGH:
        return heatmapcolourname
          ? palette.heatmap[
              heatmapcolourname as unknown as keyof typeof palette.heatmap
            ].high
          : isHeart
          ? palette.error.dark
          : palette.heatmap.amber.high;
      default:
        return palette.background.default;
    }
  };

  return {
    aspectRatio: 1,
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: today ? palette.text.primary : getBackgroundColor(),
    borderRadius: '7px',
    cursor: 'pointer',

    '&.heart': {
      backgroundColor: 'unset',
      borderImage: `radial-gradient(${
        today ? palette.text.primary : getBackgroundColor(true)
      } 69%, #0000 70%) 84.5%/50%`,
      clipPath: 'polygon(-41% 0, 50% 91%, 141% 0)',
    },
  };
});

const TooltipAgenda = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1.5),
}));

const TooltipAgendaIcon = styled(Box)<{ heatmapcolourname?: string | null }>(
  ({ heatmapcolourname, theme: { palette } }) => ({
    borderRadius: '4px',
    ...constSize('16px'),
    backgroundColor: heatmapcolourname
      ? palette.heatmap[
          heatmapcolourname as unknown as keyof typeof palette.heatmap
        ].high
      : palette.warning.main,
  })
);

const TooltipCorrectIcon = styled(TooltipAgendaIcon)(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.success.main,
  })
);

interface TooltipTitleComponentProps {
  date: number | Date;
  totalAttempted: number;
  totalCorrect: number;
}

const { getItem } = localStorageTyped<string>('heatmapColorScheme');

const TooltipTitleComponent = ({
  date,
  totalAttempted,
  totalCorrect,
}: TooltipTitleComponentProps) => {
  const correctPercentage =
    Math.round((totalCorrect / totalAttempted) * 100) || 0;

  return (
    <>
      <Body small>{formatDate(date, 'ddd, MMM DD, YYYY')}</Body>
      <TooltipAgenda>
        <TooltipAgendaIcon heatmapcolourname={getItem()} />
        <Body small>Questions answered: {totalAttempted}</Body>
      </TooltipAgenda>
      <TooltipAgenda>
        <TooltipCorrectIcon />
        <Body small>Correctly answered: {correctPercentage}%</Body>
      </TooltipAgenda>
    </>
  );
};

interface HeatmapContentProps {
  data: Undefinable<IDailyProgressData[]>;
}

const HeatmapContent = ({ data }: HeatmapContentProps) => {
  const { isDesktop } = useIsDesktop();
  const { isMobile } = useIsMobile();

  if (!data) {
    return null;
  }

  const items = isDesktop ? data : isMobile ? data.slice(-60) : data.slice(-90);

  return (
    <HeatmapSection>
      {items.map(({ date, intensity, totalAttempted, totalCorrect }, i) => (
        <Tooltip
          key={i}
          placement="bottom"
          textunset={1}
          title={
            <TooltipTitleComponent
              date={date}
              totalAttempted={totalAttempted}
              totalCorrect={totalCorrect}
            />
          }
        >
          <HeatmapItem
            className={clsx({ heart: isValentinesDay() })}
            heatmapcolourname={getItem()}
            intensity={intensity}
            key={i}
            today={dayjs(Number(date) * 1000).isToday() ? 1 : 0}
          />
        </Tooltip>
      ))}
    </HeatmapSection>
  );
};

export default HeatmapContent;
