import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { TableData } from 'types';
import ExerciseSummaryTableHead from './ExerciseSummaryTableHead';
import { SummaryColumn } from './types';
import ExerciseSummaryTableRow from './ExerciseSummaryTableRow';
import { Pagination, usePagination } from 'components/Pagination';
import { CoreCellLabelProps, ProgressCellLabels } from 'components/GridCell';

const Header = styled(Box)(
  ({ theme: { palette, spacing, typography, breakpoints } }) => ({
    backgroundColor: palette.background.paper,
    borderTop: `1px solid ${palette.stroke.main}`,
    borderBottom: `1px solid ${palette.stroke.main}`,
    padding: spacing(4, 8),
    display: 'flex',
    gap: spacing(4),
    justifyContent: 'space-between',
    flexDirection: 'column',
    ...typography.body2Medium,

    [breakpoints.up('md')]: {
      height: '64px',
      padding: spacing(0, 10),
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
);

const ScrollableHorizontally = styled(Box)({
  overflowX: 'auto',
});

const Scrollable = styled(Box)({
  maxHeight: '650px',
  overflowY: 'auto',
});

const TableContainer = styled(Box)({
  width: '100%',
  minWidth: '720px',
});

export interface ExerciseSummaryTableProps<T extends TableData> {
  data: T[];
  columns: SummaryColumn<T>[];
  onClick?: (item: T) => void;
  summary?: string;
  labels?: CoreCellLabelProps[];
  withPagination?: boolean;
  withoutOffset?: boolean;
}

const ITEMS_PER_PAGE = 10;

function ExerciseSummaryTable<T extends TableData>({
  columns,
  data,
  onClick,
  withPagination = true,
  labels,
  summary,
  withoutOffset,
}: ExerciseSummaryTableProps<T>): JSX.Element {
  const { paginatedData, currentPage, pagesCount, handlePageChange } =
    usePagination(ITEMS_PER_PAGE, data.length, data);

  const rows = withPagination ? paginatedData : data;

  return (
    <Box>
      {labels ? (
        <Header>
          {summary ? <Box>{summary}</Box> : null}
          <ProgressCellLabels labels={labels} withTitle={false} />
        </Header>
      ) : null}
      <ScrollableHorizontally>
        <TableContainer>
          <ExerciseSummaryTableHead
            columns={columns}
            withoutOffset={withoutOffset}
          />
          <Scrollable>
            {rows?.map(item => (
              <ExerciseSummaryTableRow
                columns={columns}
                item={item}
                key={item.id}
                number={item.index}
                onClick={onClick}
                withoutOffset={withoutOffset}
              />
            ))}
          </Scrollable>
        </TableContainer>
      </ScrollableHorizontally>
      {withPagination && (
        <Pagination
          count={pagesCount}
          onChange={handlePageChange}
          page={currentPage}
        />
      )}
    </Box>
  );
}

export default ExerciseSummaryTable;
