import React from 'react';
import { EProductType } from '@quesmed/types-rn/models';

import { usePlatform } from 'context/PlatformContext';
import { QbankKnowledgeLibraryHome } from './qbank';
import { OsceKnowledgeLibraryHome } from './osce';
import { PacesKnowledgeLibrarySection } from './paces';

const KnowledgeLibraryHome = (): JSX.Element => {
  const { product } = usePlatform();

  if (product === EProductType.OSCE || product === EProductType.PLAB2) {
    return <OsceKnowledgeLibraryHome />;
  }
  if (product === EProductType.PACES) {
    return <PacesKnowledgeLibrarySection />;
  }

  return <QbankKnowledgeLibraryHome />;
};

export default KnowledgeLibraryHome;
