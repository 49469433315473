import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useLeaveExerciseState,
  useMarksheetLeavingBlockade,
  useMarksheetQuery,
  usePrevious,
} from 'hooks';
import { checkIfFinished } from 'utils';
import QuestionsQuizContainer from './QuestionsQuizContainer';
import QuestionsQuizLeaveModal from './QuestionsQuizLeaveModal';

const QuestionsQuizQuery = (): JSX.Element => {
  const { marksheetId = '' } = useParams<{ marksheetId: string }>();
  const navigate = useNavigate();
  const { open } = useLeaveExerciseState();
  const { marksheet, loading } = useMarksheetQuery(marksheetId);
  const { id, completed } = marksheet || {};

  const prev = usePrevious(marksheet);

  useMarksheetLeavingBlockade({
    marksheetId: id,
    shouldBlock: Boolean(id),
    ignoreBlockade: Boolean(completed),
    solo: true,
  });

  useEffect(() => {
    if (!prev && marksheet) {
      if (checkIfFinished(marksheet)) {
        navigate(`/questions/review/${marksheet.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marksheet]);

  return (
    <>
      <QuestionsQuizContainer
        isSampleGame={false}
        loading={loading}
        marksheet={marksheet}
        review={false}
      />
      {open ? (
        <QuestionsQuizLeaveModal
          marksheet={marksheet}
          marksheetId={id}
          open={open}
        />
      ) : null}
    </>
  );
};

export default QuestionsQuizQuery;
