import { FetchResult } from '@apollo/client';
import { EQuestionLike, IQuestionComment } from '@quesmed/types-rn/models';
import { IQuestionCommentsData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';

import { Nullable } from 'types';
import ChevronDownIcon from 'components/Icons/ChevronDownIcon';
import Comment from '../Comment/Comment';
import CommentInput from '../CommentInput/CommentInput';
import { scrollToBottom, sortByCreatedAt } from 'utils';
import {
  CollapseCommentInput,
  CommentsBox,
  RepliesWrapper,
  ShowMoreButton,
} from './CommentWithReplies.styles';

export interface CommentWithRepliesProps {
  commentData: IQuestionComment;
  addReply: (
    parentId: number,
    comment: string
  ) => Promise<FetchResult<IQuestionCommentsData>>;
  addCommentLoading: boolean;
  removeCommentLoading: boolean;
  removeComment: (commentId: number) => void;
  scrollToComment: (elementOffsetTop: number) => void;
  checkIsRemoveCommentLoading: (commentId?: number) => boolean;
  removeReply: (parentId: number) => (commentId: number) => void;
  likeComment: (
    likeClick: boolean,
    commentId: number,
    parentId: Nullable<number>,
    isLikedByMe?: EQuestionLike
  ) => () => void;
}

const COLLAPSE_ANIMATION_DURATION = 200;

const CommentWithReplies = ({
  commentData,
  addReply,
  addCommentLoading,
  removeCommentLoading,
  removeComment,
  checkIsRemoveCommentLoading,
  removeReply,
  likeComment,
  scrollToComment,
}: CommentWithRepliesProps): JSX.Element => {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showMoreReplies, setShowMoreReplies] = useState(false);
  const commentInputRef = useRef<HTMLInputElement>(null);
  const repliesWrapperRef = useRef<HTMLDivElement>(null);
  const timeout = useRef(0);

  const { id, replies: sourceReplies = [] } = commentData;
  const replies = useMemo(
    () =>
      sourceReplies
        ? sortByCreatedAt<IQuestionComment>(sourceReplies)
        : sourceReplies,
    [sourceReplies]
  );

  const [firstReply, ...moreReplies] = replies;

  const toggleReplies = () => {
    setShowMoreReplies(prevValue => !prevValue);
    if (showMoreReplies) {
      setShowReplyInput(false);
    }
  };

  const scrollToRepliesBottom = (scrollToParentComment = true) => {
    const repliesWrapper = repliesWrapperRef.current;
    if (repliesWrapper) {
      timeout.current = window.setTimeout(() => {
        const repliesParent = repliesWrapper.parentNode as HTMLElement;
        if (scrollToParentComment) {
          scrollToComment(repliesParent.offsetTop);
        }

        scrollToBottom<HTMLDivElement>(repliesWrapperRef);
        if (commentInputRef.current) {
          commentInputRef.current.focus();
        }
      }, COLLAPSE_ANIMATION_DURATION);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const handleShowReplyInput = () => {
    if (firstReply) {
      setShowMoreReplies(true);
    }
    setShowReplyInput(true);
    scrollToRepliesBottom();
  };

  const handleHideReplyInput = () => {
    setShowReplyInput(false);
    scrollToRepliesBottom(false);
  };

  const handleOnTransitionEnd = () => {
    scrollToRepliesBottom();
  };

  const handleReplyOnSubmit = async (comment: string) => {
    await addReply(Number(id), comment);
    handleHideReplyInput();
  };

  return (
    <CommentsBox>
      <Comment
        {...commentData}
        likeComment={likeComment}
        removeComment={removeComment}
        removeCommentLoading={removeCommentLoading}
        showReplyInput={handleShowReplyInput}
      />
      <RepliesWrapper ref={repliesWrapperRef}>
        {firstReply && (
          <Comment
            {...firstReply}
            key={firstReply?.id}
            likeComment={likeComment}
            removeComment={removeReply(Number(id))}
            removeCommentLoading={checkIsRemoveCommentLoading(firstReply?.id)}
            reply
          />
        )}
        <Collapse
          in={showMoreReplies}
          timeout={COLLAPSE_ANIMATION_DURATION}
          unmountOnExit
        >
          {moreReplies.map(reply => (
            <Comment
              {...reply}
              key={reply.id}
              likeComment={likeComment}
              removeComment={removeReply(Number(id))}
              removeCommentLoading={checkIsRemoveCommentLoading(reply.id)}
              reply
            />
          ))}
        </Collapse>

        <CollapseCommentInput
          addEndListener={handleOnTransitionEnd}
          in={showReplyInput}
          timeout={COLLAPSE_ANIMATION_DURATION}
          unmountOnExit
        >
          <CommentInput
            helperText="Reply Required!"
            label="Write your reply here..."
            loading={addCommentLoading}
            onSubmit={handleReplyOnSubmit}
            ref={commentInputRef}
          />
        </CollapseCommentInput>
      </RepliesWrapper>

      {replies.length > 1 && (
        <ShowMoreButton
          expanded={showMoreReplies}
          onClick={toggleReplies}
          variant="text"
        >
          Show {showMoreReplies ? 'less' : 'more'} replies ({moreReplies.length}
          ) <ChevronDownIcon />
        </ShowMoreButton>
      )}
    </CommentsBox>
  );
};

export default CommentWithReplies;
