import { IQuestionMultiQ } from '@quesmed/types-rn/models';
import React, { useEffect, useState } from 'react';

import { values } from 'utils';
import MultiAnswerContent from './MultiAnswerContent';
import { IAnswerObject } from 'types';

const initialAnswer = {
  firstColumn: [],
  secondColumn: [],
};

interface MultiAnswerProps {
  isTestFinished: boolean;
  question: IQuestionMultiQ;
  setUserAnswer: (answer: string) => void;
  userAnswer?: string;
}

const MultiAnswer = ({
  isTestFinished,
  question,
  setUserAnswer,
  userAnswer,
}: MultiAnswerProps): JSX.Element => {
  const [combinedAnswer, setCombinedAnswer] =
    useState<IAnswerObject>(initialAnswer);
  useEffect(() => {
    if (userAnswer) {
      const parsedUserAnswers = JSON.parse(userAnswer) as IAnswerObject;

      const touched = values(parsedUserAnswers).some(answers => answers.length);

      if (touched) {
        setUserAnswer(userAnswer);
      }

      setCombinedAnswer({
        firstColumn: parsedUserAnswers[0],
        secondColumn: parsedUserAnswers[1],
      });
    } else {
      setCombinedAnswer(initialAnswer);
    }
  }, [setUserAnswer, userAnswer]);

  const handleMarkCheck = (isFirstColumn: boolean, markLabel: string) => {
    const columnIndex = isFirstColumn ? 'firstColumn' : 'secondColumn';
    const reverseColumnIndex = isFirstColumn ? 'secondColumn' : 'firstColumn';

    const preAnswerObject = {
      [reverseColumnIndex]: [...combinedAnswer[reverseColumnIndex]],
      [columnIndex]: [
        ...(combinedAnswer[columnIndex].find(mark => mark === markLabel)
          ? combinedAnswer[columnIndex].filter(mark => mark !== markLabel)
          : [...combinedAnswer[columnIndex], markLabel].sort()),
      ],
    };

    setCombinedAnswer(preAnswerObject);
    setUserAnswer(
      JSON.stringify([
        [...preAnswerObject.firstColumn],
        [...preAnswerObject.secondColumn],
      ])
    );
  };

  return (
    <MultiAnswerContent
      combinedAnswer={combinedAnswer}
      isTestFinished={isTestFinished}
      onChange={handleMarkCheck}
      question={question}
    />
  );
};

export default React.memo(MultiAnswer);
