import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { IHighlightNode } from '@quesmed/types-rn/models';
import {
  ADD_CHAPTER_HIGHLIGHT,
  getChapterHighlightOptimisticResponse,
  IAddChapterHighlightData,
  IAddChapterHighlightVar,
  updateChapterHighlights,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';
import useCurrentUser from 'Auth/useCurrentUser';

const useAddChapterHighlight = (marksheetId: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: userId } = useCurrentUser();

  const [questionHighlight] = useMutation<
    IAddChapterHighlightData,
    IAddChapterHighlightVar
  >(ADD_CHAPTER_HIGHLIGHT, {
    onError: () => {
      enqueueSnackbar('Text highlight failed');
    },
  });

  const highlightText = useCallback(
    async (chapterId: number, highlights: IHighlightNode[]) => {
      if (marksheetId) {
        return questionHighlight({
          variables: {
            marksheetId,
            input: [
              {
                chapterId: Number(chapterId),
                highlights: highlights.map(
                  ({ __typename, ...rest }: any) => rest
                ),
              },
            ],
          },
          update: updateChapterHighlights,
          optimisticResponse: getChapterHighlightOptimisticResponse(
            userId,
            highlights,
            chapterId
          ),
        });
      }
    },
    [marksheetId, questionHighlight, userId]
  );

  return highlightText;
};

export default useAddChapterHighlight;
