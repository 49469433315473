import { EAppType } from '@quesmed/types-rn/models';

import AMSProductImage from './AMSProductImage';
import CMProductImage from './CMProductImage';
import InterviewProductImage from './InterviewProductImage';
import MRCPProductImage from './MRCPProductImage';
import MSRAProductImage from './MSRAProductImage';
import PLABProductImage from './PLABProductImage';

// TODO try to use path to the images in public to not include them in the bundle
export const setAppImage = (appType: EAppType) => {
  switch (appType) {
    case EAppType.AMS:
      return AMSProductImage;
    case EAppType.MRCP:
      return MRCPProductImage;
    case EAppType.CM_UKMLA:
      return CMProductImage;
    case EAppType.MSRA:
      return MSRAProductImage;
    case EAppType.INTERVIEW:
      return InterviewProductImage;
    case EAppType.PLAB:
      return PLABProductImage;
    default:
      return null;
  }
};
