import React from 'react';

import { usePlatform } from 'context/PlatformContext';
import { QbankVideoLibraryHome } from './qbank';
import { OsceVideoLibraryHome } from './osce';
import { isStationsProduct } from 'utils';

const VideoLibraryHome = (): JSX.Element => {
  const { product } = usePlatform();

  return isStationsProduct(product) ? (
    <OsceVideoLibraryHome />
  ) : (
    <QbankVideoLibraryHome />
  );
};

export default VideoLibraryHome;
