import { Theme } from '@mui/system';
import { Chart } from 'chart.js';

import { formatOrdinals } from 'utils/intl';
import findClosestIndex from '../helpers/findClosestIndex';

//TODO: Add background gradient color under chart from point

const pointPlugin = (
  series: [number, number][],
  point: number,
  percentile: number,
  theme: Theme
) => ({
  id: 'point',
  beforeDraw: (chart: Chart) => {
    const { ctx } = chart;
    chart.update();
    const index = findClosestIndex(series, point);
    ctx.beginPath();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.lineWidth = 4;
    ctx.strokeStyle = theme.palette.success.main;
    ctx.fillStyle = theme.palette.chart.successLight;
    ctx.arc(
      chart.getDatasetMeta(0).data[index].x,
      chart.getDatasetMeta(0).data[index].y,
      4,
      0,
      8 * Math.PI,
      true
    );
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = theme.palette.image.main;
    ctx.fillText(
      `${formatOrdinals(percentile)}`,
      chart.getDatasetMeta(0).data[index].x,
      chart.getDatasetMeta(0).data[index].y - 10
    );
    ctx.fill();
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';
    ctx.closePath();
    ctx.restore();
    ctx.setLineDash([]);
  },
});

export default pointPlugin;
