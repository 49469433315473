import { useEffect } from 'react';

import { useSnackbar } from 'components/Snackbar';
import { useAgoraAPI } from './AgoraAPI';
import { useAgoraSetState, useAgoraState } from './AgoraState';
import { AgoraConnectionState, AgoraMessages } from './types';
import { logError } from 'utils';

export const useAgoraSession = () => {
  const {
    userId,
    sessionExpired,
    videoEnabled,
    audioEnabled,
    initSession,
    initAudio,
    initVideo,
    mutedUsers,
    willExpired,
    preview,
  } = useAgoraState();
  const { setMutedUsers } = useAgoraSetState();
  const {
    client,
    endSession,
    connect,
    startAudio,
    audioReady,
    audioTrack,
    videoReady,
    startVideo,
    publishAudio,
    videoTrack,
    publishVideo,
  } = useAgoraAPI();
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  // Ends session: closes tracks, leaves channel and removes listeners
  useEffect(() => {
    return () => {
      endSession();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Starts session: connects and joins to the channel
  useEffect(() => {
    if (initSession) {
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSession]);

  // Inits audio track
  useEffect(() => {
    if (initAudio && audioReady) {
      startAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAudio, audioReady]);

  // Inits video track for preview
  useEffect(() => {
    if (preview && videoReady) {
      startVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, videoReady]);

  const disconnected =
    client.connectionState !== AgoraConnectionState.CONNECTED;

  // Publishes video track on study rejoin
  useEffect(() => {
    const publishOnRejoin = async () => {
      if (initVideo && videoReady && !preview) {
        if (disconnected) {
          await connect();
        }
        if (!disconnected) {
          await publishVideo(true);
        }
      }
    };
    publishOnRejoin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initVideo, preview, videoReady, disconnected]);

  // Publishes audio track
  useEffect(() => {
    if (
      client.connectionState === AgoraConnectionState.CONNECTED &&
      audioReady
    ) {
      publishAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.connectionState, audioReady]);

  // Toggles video enabled state
  useEffect(() => {
    if (userId && videoTrack?.enabled !== videoEnabled) {
      try {
        videoTrack?.setEnabled(videoEnabled);
      } catch (e) {
        logError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoEnabled]);

  // Toggles audio enabled state
  useEffect(() => {
    if (userId && audioTrack?.enabled !== audioEnabled) {
      try {
        audioTrack?.setEnabled(audioEnabled);
      } catch (e) {
        logError(e);
      }

      const newMutedUsers = audioEnabled
        ? mutedUsers.filter((id: number) => id !== userId)
        : [...mutedUsers, userId];

      setMutedUsers(newMutedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioEnabled]);

  // Displays session will expired warning
  useEffect(() => {
    if (
      willExpired &&
      client.connectionState === AgoraConnectionState.CONNECTED
    ) {
      enqueueSnackbar(AgoraMessages.WILL_EXPIRED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willExpired]);

  // Displays session did expired warning
  useEffect(() => {
    if (
      sessionExpired &&
      client.connectionState === AgoraConnectionState.CONNECTED
    ) {
      enqueueSnackbar(AgoraMessages.DID_EXPIRED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpired]);
};

export default useAgoraSession;
