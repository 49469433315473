import React from 'react';
import { mdiStickerOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const StickerOutlineIcon = (props: IconProps) => (
  <Icon path={mdiStickerOutline} {...props} />
);

export default StickerOutlineIcon;
