import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ADD_TO_DAILY_STACK,
  IAddToDailyStackData,
  IAddToDailyStackVar,
  IRemoveFromDailyStackData,
  IRemoveFromDailyStackVar,
  REMOVE_FROM_DAILY_STACK,
  updateCacheOnAddToDailyStack,
  updateCacheOnRemoveFromDailyStack,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import useCheckDailyFeed from './useCheckDailyFeed';

const useAddToDailyStack = (
  conceptId: number,
  isIncorrect: boolean,
  skip: boolean
) => {
  const [itemsRemoved, setItemsRemoved] = useState<Set<number>>(new Set());

  const { isInDailyFeedAlready, checkingLoading } = useCheckDailyFeed(
    conceptId,
    skip
  );

  const [addToStack, { loading: addToStackLoading }] = useMutation<
    IAddToDailyStackData,
    IAddToDailyStackVar
  >(ADD_TO_DAILY_STACK, {
    onCompleted: data => {
      if (data && itemsRemoved.has(conceptId)) {
        setItemsRemoved(prev => {
          prev.delete(conceptId);

          return new Set(prev);
        });
      }
    },
    update: updateCacheOnAddToDailyStack,
  });

  const [removeFromStack, { loading: removeFromStackLoading }] = useMutation<
    IRemoveFromDailyStackData,
    IRemoveFromDailyStackVar
  >(REMOVE_FROM_DAILY_STACK, {
    onCompleted: data => {
      if (data) {
        setItemsRemoved(prev => prev.add(conceptId));
      }
    },
    update: updateCacheOnRemoveFromDailyStack,
  });

  const loading =
    checkingLoading || addToStackLoading || removeFromStackLoading;

  const handleAddToDailyStack = useCallback(
    (conceptId: number) => {
      if (loading || isInDailyFeedAlready) {
        return;
      }

      return addToStack({
        variables: { conceptIds: [conceptId] },
      });
    },
    [addToStack, loading, isInDailyFeedAlready]
  );

  const handleRemoveFromDailyStack = useCallback(
    async (conceptId: number) => {
      if (loading || !isInDailyFeedAlready) {
        return;
      }

      return removeFromStack({
        variables: {
          conceptId,
        },
      });
    },
    [loading, removeFromStack, isInDailyFeedAlready]
  );

  const toggleDailyStack = useCallback(
    async (conceptId: number) => {
      if (checkingLoading || removeFromStackLoading || addToStackLoading) {
        return;
      }
      if (isInDailyFeedAlready) {
        return handleRemoveFromDailyStack(conceptId);
      }

      return handleAddToDailyStack(conceptId);
    },
    [
      addToStackLoading,
      checkingLoading,
      handleAddToDailyStack,
      handleRemoveFromDailyStack,
      removeFromStackLoading,
      isInDailyFeedAlready,
    ]
  );

  useEffect(() => {
    if (!skip && isIncorrect && !isInDailyFeedAlready) {
      if (!itemsRemoved.has(conceptId)) {
        handleAddToDailyStack(conceptId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncorrect, isInDailyFeedAlready, skip]);

  return {
    addedToDailyStack: isInDailyFeedAlready,
    dailyStackloading: loading,
    toggleDailyStack,
  };
};

export default useAddToDailyStack;
