import React from 'react';

import { Modal } from 'components/Modal';
import { Body } from 'components/Typography';

interface CompleteStationModalProps {
  open: boolean;
  onSubmit: () => void;
}

const CompleteStationModal = ({
  open,
  onSubmit,
}: CompleteStationModalProps): JSX.Element => (
  <Modal
    onSubmit={onSubmit}
    open={open}
    showCloseButton={false}
    submitLabel="Check Feedback"
  >
    <Body>Station completed</Body>
  </Modal>
);

export default CompleteStationModal;
