import React, { useState } from 'react';
import { EAppType, EProductType } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

import { AppProductType, ExtendedProduct, Nullable } from 'types';
import ProductCard from './ProductCard';
import { PricingSubscriptions } from 'hooks/useSubscriptions';

export const ProductsContainer = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(6),

    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'initial',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  })
);

interface ProductCardsProps {
  products: ExtendedProduct[];
  appType: EAppType;
  subscriptions: PricingSubscriptions;
  onCheckout: (priceId: string) => void;
  paymentLoading: boolean;
}

const ProductCards = ({
  products,
  onCheckout,
  appType,
  subscriptions,
  paymentLoading,
}: ProductCardsProps): JSX.Element => {
  const [chosenProduct, setChosenProduct] =
    useState<Nullable<EProductType>>(null);

  const handleCheckout = (stripePriceId: string, productId: EProductType) => {
    if (paymentLoading) return;
    setChosenProduct(productId);
    onCheckout(stripePriceId);
  };

  return (
    <ProductsContainer>
      {products.map(({ typeId, ...rest }) => (
        <ProductCard
          appType={appType}
          key={typeId}
          onCheckout={handleCheckout}
          paymentLoading={chosenProduct === typeId && paymentLoading}
          subscriptions={subscriptions}
          typeId={typeId as AppProductType}
          {...rest}
        />
      ))}
    </ProductsContainer>
  );
};

export default ProductCards;
