import React, { useMemo } from 'react';
import { EOsceRoles } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { CircularProgress } from 'components/CircularProgress';
import { useStationGroupStudyData } from './StationsGroupStudyController';
import Station from './Station';
import { GroupStudyGuard } from 'components/GroupStudyGuard';
import { useCurrentUser } from 'Auth';
import ReadyToJoinModal from './ReadyToJoinModal';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const StationsGroupStudy = () => {
  const {
    osceMarksheet,
    isActor,
    isCandidate,
    isExaminer,
    loading,
    participants,
  } = useStationGroupStudyData();
  const { id: currentUserId } = useCurrentUser();
  const { members, osceStation, completed, users } = osceMarksheet || {};

  const data = useMemo(() => {
    if (osceMarksheet && members && osceStation) {
      const user = members.find(({ userId }) => userId === currentUserId);

      const { userId, role } = user || {};

      // TODO will be fixed during rebranding commented due to reduce amount of diffs in this
      // task that is about files and routes preparations
      return {
        currentUserId: userId ? userId : 1,
        osceStation,
        osceMarksheet,
        // rebuildStudy,
        // actionData,
        // actionLoading,
        members,
        role: !isNil(role) ? role : EOsceRoles.CANDIDATE, //TODO this is just for preparing group study components
        isExaminer,
        isCandidate,
        users,
      };
    }

    return undefined;
  }, [
    currentUserId,
    isCandidate,
    isExaminer,
    members,
    osceMarksheet,
    osceStation,
    users,
  ]);

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!data || !osceMarksheet) {
    return <ErrorMessage />;
  }

  const { id: participantId, displayName } =
    participants?.find(participant => participant.id === currentUserId) || {};

  const actorExists = osceMarksheet.members.find(
    member => member.role === EOsceRoles.ACTOR
  )?.user;

  const checkVideo = isCandidate || isActor || (!actorExists && isExaminer);

  return data ? (
    <>
      <GroupStudyGuard checkVideo={checkVideo} noLoading permissionOptional />
      <Station data={data} />
      {checkVideo && !isCandidate && !completed ? (
        <ReadyToJoinModal
          connected
          displayName={displayName}
          loading={loading}
          participantId={participantId}
        />
      ) : null}
    </>
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default StationsGroupStudy;
