import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import isNil from 'lodash/isNil';
import { EProductType } from '@quesmed/types-rn/models';

import { CellValue, CheckboxState, TableData } from 'types';
import { Checkbox } from 'components/Checkbox';
import { ChevronDownIcon, KnowledgeLibraryIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import {
  ClickHandler,
  KeyField,
  SelectionColumn,
  SelectItemHandler,
} from './types';
import { Radio } from 'components/RadioGroup';
import { ProgressLineChart } from 'components/ProgressLineChart';
import { ProgressScoreBadge } from 'components/ProgressScoreBadge';
import { CellLockIcon } from './CellLockIcon';
import { usePlatform } from 'context/PlatformState';

interface SelectionCellContentProps<T extends TableData> {
  checked?: boolean;
  checkedState?: CheckboxState;
  currentColumn: SelectionColumn<T>;
  data: T;
  locked?: boolean;
  nested?: boolean;
  onChange?: SelectItemHandler;
  parentId?: number;
  handleOpenModal?: ClickHandler;
  checkedIcon?: ReactNode;
  icon?: ReactNode;
}

const Label = styled(Box)(({ theme: { typography } }) => ({
  textWrap: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  ...typography.body1,

  '&.withIcon': {
    display: 'flex',
    gap: '2px',
  },
}));

const KnowledgeIcon = styled(KnowledgeLibraryIcon)(
  ({ theme: { palette } }) => ({
    color: palette.icon.main,
    '&:hover': {
      cursor: 'pointer',
      color: palette.icon.dark,
    },
  })
);

function SelectionCellContent<T extends TableData>({
  checked,
  checkedIcon,
  checkedState,
  currentColumn,
  handleOpenModal,
  locked,
  data,
  icon,
  nested,
  onChange,
  parentId,
}: SelectionCellContentProps<T>) {
  const { key, formatter, valueFormatter, progressFormatter, scoreFormatter } =
    currentColumn;
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;

  const value = formatter
    ? formatter(data, parentId)
    : valueFormatter
    ? valueFormatter(data[key])
    : (data[key] as CellValue);

  switch (key) {
    case KeyField.Progress:
      return progressFormatter ? (
        <Box sx={{ width: '100%' }}>
          <ProgressLineChart {...progressFormatter(data, isPaces)} />
        </Box>
      ) : null;
    case KeyField.Chevron:
      return nested ? null : <IconButton icon={<ChevronDownIcon />} />;
    case KeyField.Score:
      return scoreFormatter ? (
        <ProgressScoreBadge {...scoreFormatter(data)} />
      ) : null;
    case KeyField.Checkbox:
      return locked ? (
        <CellLockIcon />
      ) : (
        <Checkbox
          checked={checked}
          checkedState={checkedState}
          controlSx={{ marginRight: 0 }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onChange={onChange}
        />
      );
    case KeyField.Radio:
      return locked ? (
        <CellLockIcon />
      ) : (
        <Radio
          checked={checked}
          checkedIcon={checkedIcon}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          icon={icon}
          onChange={onChange}
        />
      );
    case KeyField.Label:
      return <Label onClick={locked ? undefined : onChange}>{value}</Label>;
    case KeyField.LabelIcon:
      return (
        <Label className="withIcon" onClick={locked ? undefined : onChange}>
          {value}
        </Label>
      );

    case KeyField.KnowledgeIcon:
      return value ? (
        <Box onClick={handleOpenModal}>
          <KnowledgeIcon />
        </Box>
      ) : null;
    default:
      return !isNil(value) ? <Box>{value}</Box> : null;
  }
}

export default SelectionCellContent;
