import { useCallback } from 'react';
import { IHighlightNode } from '@quesmed/types-rn/models';
import { useApolloClient } from '@apollo/client';
import { handleSampleChapterHighlights } from '@quesmed/types-rn/resolvers/mutation';

import useAddChapterHighlight from './useAddChapterHighlight';

const useChapterHighlight = (
  marksheetId: number,
  chapterId: number,
  highlights: IHighlightNode[] = [],
  isSample?: boolean
) => {
  const highlightQuestion = useAddChapterHighlight(marksheetId);
  const client = useApolloClient();

  const handleHighlights = useCallback(
    async (highlights: IHighlightNode[]) => {
      if (isSample) {
        handleSampleChapterHighlights(client, highlights, chapterId);
      } else {
        await highlightQuestion(chapterId, highlights);
      }
    },
    [chapterId, client, highlightQuestion, isSample]
  );

  const handleRemoveHighlight = useCallback(
    (highlightIndex: number) => {
      const item = highlights[highlightIndex];
      const newHighlights = item.part
        ? highlights.filter(n => n.part !== item.part)
        : highlights.filter((_, index) => index !== highlightIndex);

      handleHighlights(newHighlights);
    },
    [highlights, handleHighlights]
  );

  const handleAddHighlight = useCallback(
    (newHighlights: IHighlightNode[] | null) => {
      if (newHighlights) {
        handleHighlights(newHighlights);
      }
    },
    [handleHighlights]
  );

  return {
    handleAddHighlight,
    handleRemoveHighlight,
  };
};

export default useChapterHighlight;
