import React, { useEffect } from 'react';
import { EAppType, EProductType } from '@quesmed/types-rn/models';
import ReactGA from 'react-ga4';
import Box from '@mui/material/Box';

import { CircularProgress } from 'components/CircularProgress';
import { fetchPageViewEvent, localStorageTyped } from 'utils';
import { Nullable } from 'types';
import { usePlatform } from 'context/PlatformContext';
import { useTimer } from 'hooks';
import { Body, H5 } from 'components/Typography';
import { Container } from './PaymentCompleteStyles';
import { logoutUser } from 'Auth';

const { removeItem: removeStoredProduct } =
  localStorageTyped<Nullable<EProductType>>('product');

const { removeItem: removeStoredApp } =
  localStorageTyped<Nullable<EAppType>>('app');

const PaymentComplete = () => {
  const { clearState } = usePlatform();
  const timeLeft = useTimer({ duration: 15 });

  useEffect(() => {
    fetchPageViewEvent('Purchase');
    ReactGA.send({
      hitType: 'pageview',
      page: '/payment-complete',
    });
  }, []);

  useEffect(() => {
    removeStoredProduct();
    removeStoredApp();
    clearState();
  }, [clearState]);

  useEffect(() => {
    if (timeLeft <= 0) {
      logoutUser();
    }
  }, [timeLeft]);

  return (
    <Container>
      <CircularProgress fullscreen={false} />
      <H5>Payment pending...</H5>
      <Box>
        <Body>We are waiting for notification to confirm the payment.</Body>
        <Body bold>You will be logged out in {timeLeft} seconds...</Body>
        <Body>Please log in again to refresh your session.</Body>
      </Box>
    </Container>
  );
};

export default PaymentComplete;
