import React from 'react';
import { EProductType, IOsceStation } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { ItemStatusLabel, Nullable, ToggleOptions } from 'types';
import { round } from 'utils';
import { MAX_SCORE } from 'config/constants';
import {
  BadgeScore,
  ProgressScoreBadgeProps,
} from 'components/ProgressScoreBadge';
import {
  ProgressCorrectIcon,
  ProgressInitialIcon,
  ProgressStartedIcon,
} from 'components/Icons';
import {
  LineChartItem,
  ProgressLineChartProps,
} from 'components/ProgressLineChart';
import SelectionInputCellLabel from 'components/ExerciseBuilder/SelectionInputCellLabel';

type StationsMap = {
  [key in EProductType]: IOsceStation[];
};

export const getStationType = (id?: EProductType): Nullable<EProductType> => {
  const type = Number(id);
  if (isNil(id) || isNaN(type) || type === EProductType.ALL) {
    return null;
  }

  return type;
};

export const splitPerCategory = (stations: IOsceStation[]): StationsMap => {
  const map = {} as StationsMap;

  stations.forEach(station => {
    const { id } = station.entitlement || {};

    const type = getStationType(id);

    if (type) {
      if (!map[type]) {
        map[type] = [station];
      } else {
        map[type].push(station);
      }
    }
  });

  return map;
};

export const findCategoryLabel = (
  categories: ToggleOptions<EProductType>,
  activeCategory?: EProductType
): string =>
  categories.find(({ value }) => Number(value) === Number(activeCategory))
    ?.label || '';

export const RadioLabelFormatter = ({ name }: IOsceStation) => (
  <SelectionInputCellLabel name={name} />
);

export const scoreFormatter = ({
  score: sourceScore = 0,
}: IOsceStation): ProgressScoreBadgeProps => {
  const score = round(sourceScore * MAX_SCORE);

  let status = BadgeScore.NotStarted;
  let icon = <ProgressInitialIcon />;
  if (score === MAX_SCORE) {
    status = BadgeScore.Correct;
    icon = <ProgressCorrectIcon />;
  } else if (score > 0) {
    status = BadgeScore.InProgress;
    icon = <ProgressStartedIcon />;
  }

  return { score, icon, status };
};

export const setStationsProgressData = (
  total: number,
  score?: number,
  isPaces?: boolean
): LineChartItem[] => [
  {
    type: ItemStatusLabel.Confident,
    value: isPaces ? score : round(total * (score ?? 0)),
    label: ItemStatusLabel.Performed,
  },
];

export const progressFormatter = (
  { score, totalMarks, completedMarks, name }: IOsceStation,
  isPaces?: boolean
): ProgressLineChartProps => ({
  title: name,
  data: setStationsProgressData(
    totalMarks,
    isPaces ? completedMarks : score,
    isPaces
  ),
  total: isPaces ? totalMarks * 2 : totalMarks,
  notAnsweredLabel: ItemStatusLabel.NotPerformed,
});
