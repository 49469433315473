import React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material';

import { CardButton } from 'components/Button';
import { RadioBoxBlankIcon, RadioBoxMarkedIcon } from 'components/Icons';

interface ExerciseModeButtonProps extends ButtonProps {
  selected: boolean;
}

const ModeButton = styled(CardButton)(
  ({ theme: { breakpoints, palette, spacing, transitions } }) => ({
    width: '100%',
    height: spacing(38),
    minHeight: spacing(38),
    maxHeight: spacing(38),

    transition: transitions.create(['border-color']),

    '&.selected.MuiButton-outlinedSecondary': {
      borderColor: palette.primary.main,
    },

    '&.selected.MuiButton-outlinedSecondary:hover': {
      borderColor: palette.primary.main,
    },

    [breakpoints.up('md')]: {
      width: 'unset',
      minWidth: 'unset',
      maxWidth: 'unset',
      flex: 1,
    },
  })
);

const Content = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(2.5),
}));

const IconBox = styled(Box)(({ theme: { palette, spacing, transitions } }) => ({
  position: 'absolute',
  top: spacing(3),
  right: spacing(3),

  transition: transitions.create(['color']),

  '&.selected': {
    color: palette.primary.main,
  },
}));

const ExerciseModeButton = ({
  children,
  onClick,
  selected,
}: ExerciseModeButtonProps): JSX.Element => {
  return (
    <ModeButton
      className={clsx({
        selected,
      })}
      onClick={onClick}
    >
      <Content>{children}</Content>
      <IconBox
        className={clsx('icon-box', {
          selected,
        })}
      >
        {selected ? <RadioBoxMarkedIcon /> : <RadioBoxBlankIcon />}
      </IconBox>
    </ModeButton>
  );
};

export default ExerciseModeButton;
