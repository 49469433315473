import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import ChoiceContainer from './ChoiceContainer';
import { Checkbox } from 'components/Checkbox';
import { Nullable, QuestionAnswerStatus } from 'types';
import { HotKey } from 'components/HotKey';
import { Markdown } from 'components/Markdown';
import setIcon from './setIcon';

interface ChoiceProps {
  explanation: Nullable<string>;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  name: string;
  id: number;
  checked: boolean;
  choiceVariant: number;
  isAnswered: boolean;
  state: QuestionAnswerStatus;
  preview?: boolean;
}

const Label = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledHotKey = styled(HotKey)(({ theme: { breakpoints, spacing } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'flex',
    marginLeft: spacing(4),
  },
}));

const Choice = ({
  checked,
  choiceVariant,
  name,
  id,
  onChange,
  isAnswered,
  state,
  preview,
}: ChoiceProps): JSX.Element => (
  <ChoiceContainer
    className={clsx({
      checked,
      valid: state === QuestionAnswerStatus.VALID,
      invalid: state === QuestionAnswerStatus.INVALID,
      preview,
    })}
  >
    <Checkbox
      checked={checked}
      disabled={isAnswered}
      icon={setIcon(state)}
      label={
        <Label>
          <Markdown markdownStylesProps={{ bottomOffset: false }} text={name} />
          {choiceVariant < 10 ? (
            <StyledHotKey className="secondary" hotKey={choiceVariant} />
          ) : null}
        </Label>
      }
      onChange={onChange}
      value={id}
    />
  </ChoiceContainer>
);

export default Choice;
