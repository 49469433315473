import { PreloadedImage } from 'pwa/types';
import { Undefinable } from 'types';

export const createImage = async (
  data?: PreloadedImage
): Promise<Undefinable<HTMLImageElement>> => {
  const { imageURL } = data || {};
  if (imageURL) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = error => reject(error);

      image.src = imageURL;
    });
  }
};

export const createImages = async (data: PreloadedImage[]) =>
  Promise.allSettled(data.map(imageData => createImage(imageData)));
