import React, { memo } from 'react';
import { EQuestionLike } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { ThumbDownIcon, ThumbUpIcon } from 'components/Icons';
import { ButtonOnClickHandler } from 'types';
import { Body } from 'components/Typography';
import { constSize } from 'utils';
import { Button } from 'components/Button';

const THUMB_ICON_SIZE = '16px';

const StyledButton = styled(Button)(
  ({ theme: { shape, palette, spacing } }) => ({
    '&.MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: shape.borderRadius,
      color: palette.primary.main,
      border: 'none',
      cursor: 'pointer',
      gap: spacing(2),
      padding: spacing(3),

      '&.MuiButton-containedSecondary': {
        background: palette.toggle.light,
      },

      '&.active': {
        background: palette.primary.extraLight,

        '& > .MuiSvgIcon-root': {
          fill: palette.primary.main,
        },
        '&:hover': {
          background: palette.primary.light,
        },
      },

      '&:hover': {
        background: palette.toggle.light,
      },

      '& > .MuiSvgIcon-root': {
        fill: palette.icon.main,
        ...constSize(THUMB_ICON_SIZE),
      },
    },
  })
);

interface LikeButtonProps {
  type: Exclude<EQuestionLike, EQuestionLike.NONE>;
  likesCount?: number;
  onClick: ButtonOnClickHandler;
  like?: EQuestionLike;
  showCount?: boolean;
}

const LikeButton = ({
  like,
  type,
  likesCount,
  onClick,
  showCount = true,
}: LikeButtonProps): JSX.Element => {
  const Icon = type === EQuestionLike.LIKE ? ThumbUpIcon : ThumbDownIcon;
  const active = type === like;

  return (
    <StyledButton
      className={clsx({ active })}
      color="secondary"
      onClick={onClick}
    >
      <Icon />
      {likesCount && showCount ? (
        <Body bold small>
          {likesCount}
        </Body>
      ) : null}
    </StyledButton>
  );
};

export default memo(LikeButton);
