import { useQuery } from '@apollo/client';
import { IUserData, USER } from '@quesmed/types-rn/resolvers/query/restricted';

const useDailyFlashcardLimit = () => {
  const { data, loading } = useQuery<IUserData>(USER, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    dailyFlashcardLimit:
      data?.restricted?.user?.settings?.globalSettings?.dailyFeedMax || 0,
    loading,
  };
};

export default useDailyFlashcardLimit;
