import { SelectionState } from 'types';

export const getConcepts = (selectionState: SelectionState) => {
  const result: Set<number> = new Set();

  [...selectionState.values()].forEach(({ selectedConceptsIds }) => {
    selectedConceptsIds.forEach(id => result.add(id));
  });

  return result;
};
