import React, { useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Dictionary } from 'lodash';
import { IOsceMarksheetMark } from '@quesmed/types-rn/models';
import { useLocation } from 'react-router-dom';

import { ExpandableSection } from 'components/ExpandableSection';
import { Markdown } from 'components/Markdown';
import { entries } from 'utils';
import { Checkbox } from 'components/Checkbox';
import { Children, Nullable } from 'types';
import { ReadingBriefTitle } from 'components/LearningMaterials';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(8),
}));

const MarksBox = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(-6, -8),
}));

const MarkBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  padding: spacing(5, 8),
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:not(:nth-last-of-type(1))': {
    borderBottom: `1px solid ${palette.stroke.main}`,
  },

  '& .MuiFormControlLabel-root': {
    marginRight: 0,
    marginLeft: spacing(4),
  },
  '& .MuiCheckbox-root': {
    padding: spacing(1),
  },
}));

interface Mark2Props {
  children: Children;
  id: number;
}

const Mark = ({ children, id, ...props }: Mark2Props): JSX.Element => {
  const { hash } = useLocation();
  const [markNode, setMarkNode] = useState<Nullable<HTMLDivElement>>(null);

  useLayoutEffect(() => {
    if (hash && Number(hash.replace('#', '')) === id) {
      if (markNode) {
        markNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [markNode, id, hash]);

  return (
    <MarkBox ref={setMarkNode} {...props} id={`${id}`}>
      {children}
    </MarkBox>
  );
};

interface StationMarkschemeContentProps {
  marks: Nullable<Dictionary<IOsceMarksheetMark[]>>;
  disabled?: boolean;
  expandedAll?: boolean;
  isCompleted?: boolean;
  onMarkChange?: (
    checked: Nullable<number>
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StationMarkschemeContent = ({
  marks,
  disabled,
  onMarkChange,
  expandedAll = true,
  isCompleted,
}: StationMarkschemeContentProps): JSX.Element => (
  <Container>
    {marks
      ? entries(marks).map(([title, subset]) => (
          <ExpandableSection
            expanded={expandedAll}
            key={title}
            size="small"
            title={<ReadingBriefTitle title={title} />}
            variant={isCompleted ? 'normal' : 'info'}
          >
            <MarksBox>
              {subset.map(({ mark, id, osceStationMark }) => {
                const { name = '' } = osceStationMark || {};
                const onChange = onMarkChange ? onMarkChange(mark) : undefined;

                return (
                  <Mark id={Number(id)} key={id}>
                    <Markdown
                      markdownStylesProps={{
                        bottomOffset: false,
                      }}
                      text={name}
                    />
                    <Checkbox
                      checked={Boolean(mark)}
                      disabled={disabled}
                      onChange={onChange}
                      value={id}
                    />
                  </Mark>
                );
              })}
            </MarksBox>
          </ExpandableSection>
        ))
      : null}
  </Container>
);

export default StationMarkschemeContent;
