import React, { ChangeEvent, MouseEvent, TouchEvent, useCallback } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { EProductType, IQuestionChoice } from '@quesmed/types-rn/models';

import { useAuth } from 'Auth';
import QuestionChoice from './QuestionChoice';
import { usePlatform } from 'context/PlatformState';

interface QuestionChoicesProps {
  choices: IQuestionChoice[];
  crossedOutChoiceIds?: number[];
  isAnswered?: boolean;
  isPreview?: boolean;
  name?: string;
  onChange?: (id: number) => void;
  onStrike?: (choiceId: number) => void;
  selectedChoiceId?: null | number;
  showAnswers: boolean;
  votes: number[];
  isSample?: boolean;
}

const QuestionChoices = ({
  choices,
  crossedOutChoiceIds = [],
  isAnswered = false,
  isPreview,
  name,
  onChange,
  onStrike,
  selectedChoiceId,
  showAnswers,
  votes,
  isSample,
}: QuestionChoicesProps): JSX.Element => {
  const { product } = usePlatform();
  const { isAuthenticated } = useAuth();

  const handleCrossOut = useCallback(
    (id: number) => (event: MouseEvent | TouchEvent) => {
      if (event.cancelable) {
        event.preventDefault();
      }

      if (showAnswers || (!isSample && !isAuthenticated)) {
        return;
      }

      if (onStrike) {
        onStrike(Number(id));
      }
    },
    [isAuthenticated, onStrike, showAnswers, isSample]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    if (!showAnswers && onChange) {
      onChange(Number(id));
    }
    event.target.blur();
  };

  const letterChoices = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  return (
    <RadioGroup
      name={name}
      onChange={handleChange}
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(4),
      })}
    >
      {choices.map(
        ({ id, name: label, answer, explanation, picture }, index) => (
          <QuestionChoice
            checked={selectedChoiceId === Number(id)}
            choiceVariant={
              product === EProductType.MSRA ? letterChoices[index] : index + 1
            }
            explanation={explanation}
            isAnswered={isAnswered}
            isChoiceCorrect={answer}
            isCrossOut={crossedOutChoiceIds.includes(Number(id))}
            isPreview={isPreview}
            key={id}
            label={label}
            onContextMenu={handleCrossOut(id)}
            picture={picture}
            showAnswers={showAnswers}
            value={Number(id)}
            votes={votes[index]}
          />
        )
      )}
    </RadioGroup>
  );
};

export default QuestionChoices;
