import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IUpsertUserStationStatusData,
  IUpsertUserStationStatusVar,
  optimisticStationLearningStatus,
  updateCacheOnStationLearningStatusUpdate,
  UPSERT_USER_STATION_STATUS,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { randomId } from 'utils';
import { useCurrentUser } from 'Auth';

export const useUpdateStationLearningStatus = () => {
  const [upsertStatus, { loading }] = useMutation<
    IUpsertUserStationStatusData,
    IUpsertUserStationStatusVar
  >(UPSERT_USER_STATION_STATUS);
  const { id: userId } = useCurrentUser();

  const updateStatus = useCallback(
    (osceStationIds: number[], status: EUserLearningStatus) =>
      upsertStatus({
        variables: { osceStationIds, status },
        update: updateCacheOnStationLearningStatusUpdate,
        optimisticResponse: optimisticStationLearningStatus(
          randomId(8),
          osceStationIds,
          status,
          userId
        ),
      }),
    [upsertStatus, userId]
  );

  return { loading, updateStatus };
};
