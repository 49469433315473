import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ArrowLeftThinIcon, ArrowRightThinIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import { HelperControl } from 'components/Layout/ExerciseBottomBar';
import { UtilButton } from 'components/UtilButton/UtilButton';
import IconButton from 'components/IconButton/IconButton';
import { constSize } from 'utils/index';
import { useMarkdownTextScale } from 'components/Markdown';

const StyledProgressBarContainer = styled(Box)(
  ({ theme: { breakpoints, elevation, mixins, palette, spacing } }) => ({
    backgroundColor: palette.background.paper,
    maxWidth: mixins.quizProgressBar.maxWidth,
    padding: spacing(2, 3),
    borderRadius: '9px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: elevation[1],
    position: 'relative',

    [breakpoints.up('md')]: {
      padding: spacing(3, 6),
    },

    [breakpoints.up('lg')]: {
      margin: spacing(0, -8),
    },
  })
);

const StyledProgressContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'center',
});

const StyledBody = styled(Body)<{ scale: number }>(
  ({ theme: { spacing, typography }, scale }) => ({
    padding: spacing(2, 4),
    textAlign: 'center',
    fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,
  })
);

const StyledIconContainer = styled(IconButton)<{ scale: number }>(
  ({ theme: { palette }, scale }) => ({
    backgroundColor: palette.icon.dark,
    color: palette.icon.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    ...constSize(`${24 * scale}px`),

    '&:hover': {
      color: palette.icon.light,
    },
  })
);

const HelperButtons = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  gap: spacing(1),
  justifySelf: 'left',

  '&.right': {
    justifySelf: 'right',
  },

  [breakpoints.up('md')]: {
    gap: 0,
  },
}));

const HideOnMobile = styled('span')(({ theme: { breakpoints, spacing } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'inline-block',
    marginRight: spacing(1),
  },
}));

interface QuizProgressBarProps {
  activeItemNumber: number;
  onClickNext: () => void;
  onClickPrev: () => void;
  totalQuestions: number;
  leftHelpers?: HelperControl[];
  rightHelpers?: HelperControl[];
}

export const QuizProgressBar = ({
  activeItemNumber,
  leftHelpers,
  rightHelpers,
  onClickNext,
  onClickPrev,
  totalQuestions,
}: QuizProgressBarProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const disabledPrev = activeItemNumber === 1;
  const disabledNext = activeItemNumber === totalQuestions;

  return (
    <StyledProgressBarContainer>
      <HelperButtons>
        {leftHelpers?.map(controlProps => (
          <UtilButton
            key={controlProps.label}
            quizProgressBar
            {...controlProps}
          />
        ))}
      </HelperButtons>
      <StyledProgressContainer>
        <StyledIconContainer
          disabled={disabledPrev}
          onClick={onClickPrev}
          scale={scale}
        >
          <ArrowLeftThinIcon />
        </StyledIconContainer>
        <StyledBody bold scale={scale}>
          Q<HideOnMobile>uestion</HideOnMobile>
          {activeItemNumber}/{totalQuestions}
        </StyledBody>
        <StyledIconContainer
          disabled={disabledNext}
          onClick={onClickNext}
          scale={scale}
        >
          <ArrowRightThinIcon />
        </StyledIconContainer>
      </StyledProgressContainer>
      <HelperButtons className="right">
        {rightHelpers?.map(controlProps => (
          <UtilButton
            key={controlProps.label}
            quizProgressBar
            {...controlProps}
          />
        ))}
      </HelperButtons>
    </StyledProgressBarContainer>
  );
};

QuizProgressBar.displayName = 'QuizProgressBar';

export default QuizProgressBar;
