export const areSetsEqual = (a?: Set<number>, b?: Set<number>) => {
  if (!a && !b) {
    return true;
  }

  if (!a || !b || a.size !== b.size) {
    return false;
  }

  return Array.from(a).every(element => b.has(element));
};
