import React from 'react';
import Box from '@mui/material/Box';

import StyledPill from './StyledPill';
import FlashcardScoreMark from 'pages/Flashacards/FlashcardScoreMark';
import { getScoreStatusClassname } from 'pages/Flashacards/getScoreStatusClassname';
import { useMarkdownTextScale } from 'components/Markdown';

interface FlashCardPillsInterface {
  label: string;
  score: number | null;
  selected: boolean;
  onClick: () => void;
}

const FlashCardPill = ({
  label,
  score,
  selected,
  onClick,
}: FlashCardPillsInterface): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const classname = getScoreStatusClassname(score);
  const active = selected ? '-active' : '';
  const newClass = classname + active;

  return (
    <StyledPill className={newClass} onClick={onClick} scale={scale}>
      <Box className="left-side">{label}</Box>
      <Box className="end-adornment">
        <FlashcardScoreMark score={score} showAsNotAnswered />
      </Box>
    </StyledPill>
  );
};

export default FlashCardPill;
