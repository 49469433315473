import { IQuestionQA } from '@quesmed/types-rn/models';
import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import OpenAnswerField from './OpenAnswerSection';

export const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(6),
  gap: spacing(6),
}));

interface OpenAnswerExplanationProps {
  answer?: string;
  isPreview?: boolean;
  question: IQuestionQA;
}

const OpenAnswerExplanation = ({
  answer: userAnswerDose,
  isPreview,
  question,
}: OpenAnswerExplanationProps): JSX.Element => {
  const { qaAnswer } = question;
  const [{ dose, units }] = qaAnswer;
  const isValid = userAnswerDose === dose;

  return (
    <Container>
      <OpenAnswerField
        correct={isValid}
        defaultValue={userAnswerDose}
        isTestFinished
        preview={isPreview}
        units={units}
      />
      {!isValid && !isPreview && (
        <OpenAnswerField
          correct
          defaultValue={dose}
          isTestFinished
          preview={isPreview}
          units={units}
        />
      )}
    </Container>
  );
};

export default OpenAnswerExplanation;
