import { useQuery } from '@apollo/client';
import {
  FLAGGED_QUESTIONS_MARKSHEET,
  IFlaggedQuestionsMarksheetData,
} from '@quesmed/types-rn/resolvers/query/restricted';

export const useFlaggedQuestionsMarksheetQuery = () => {
  const { data, loading, error } = useQuery<IFlaggedQuestionsMarksheetData>(
    FLAGGED_QUESTIONS_MARKSHEET
  );
  const { flaggedQuestionsMarksheet } = data?.restricted || {};

  return { data: flaggedQuestionsMarksheet, loading, error };
};
