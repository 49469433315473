import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  IProductsData,
  IProductsVar,
  PRODUCTS,
} from '@quesmed/types-rn/resolvers/query';
import { EAppType, EProductType, IProduct } from '@quesmed/types-rn/models';

import {
  AppProductType,
  Nullable,
  PRODUCT_TYPE_PER_SUBSCRIPTION_KEY,
  ProductState,
  SUBSCRIPTIONS,
  UserSubscription,
  WithSubscriptionDates,
} from 'types';
import { entries, getSubscriptions } from 'utils';
import { useAuth } from 'Auth';

interface ProductsPrices {
  subscription: EProductType;
  price: number;
  duration: number;
}

type PricingSubscription = Omit<
  UserSubscription,
  'isCanceled' | 'canBeUpdated' | 'subscriptionId'
>;

export interface PricingSubscriptions {
  [EAppType.AMS]: PricingSubscription[];
  [EAppType.CM_UKMLA]: PricingSubscription[];
  [EAppType.MRCP]: PricingSubscription[];
  [EAppType.INTERVIEW]: PricingSubscription[];
  [EAppType.MSRA]: PricingSubscription[];
  [EAppType.PLAB]: PricingSubscription[];
}

const sortSubscriptions = (subs: PricingSubscription[]) => {
  const subscriptions: PricingSubscriptions = {
    [EAppType.AMS]: [],
    [EAppType.CM_UKMLA]: [],
    [EAppType.MRCP]: [],
    [EAppType.INTERVIEW]: [],
    [EAppType.MSRA]: [],
    [EAppType.PLAB]: [],
  };

  subs.forEach(sub => {
    switch (sub.app) {
      case EAppType.AMS:
        subscriptions[EAppType.AMS].push(sub);
        break;
      case EAppType.CM_UKMLA:
        subscriptions[EAppType.CM_UKMLA].push(sub);
        break;
      case EAppType.MRCP:
        subscriptions[EAppType.MRCP].push(sub);
        break;
    }
  });

  return subscriptions;
};

const CENTS_IN_POUNDS = 100;

const createSubscriptionData = (
  subscription: AppProductType,
  renewalDate: number | Date | null,
  productsPrices?: ProductsPrices[],
  duration = 6
) => ({
  subscription,
  renewalDate,
  app: SUBSCRIPTIONS[subscription as AppProductType].app,
  price: productsPrices?.find(
    product =>
      product.subscription === subscription && product.duration === duration
  )?.price,
  duration: duration,
});

// TODO in final app we should be able to save in userSubscriptions the price for the plan that user has chosen, until BE implementation it automatically takes price per year
const getUserSubscriptions = (
  tokenDecoded: Nullable<WithSubscriptionDates>,
  productsPrices?: ProductsPrices[]
): PricingSubscriptions => {
  if (!tokenDecoded) {
    return {
      [EAppType.AMS]: [],
      [EAppType.CM_UKMLA]: [],
      [EAppType.MRCP]: [],
      [EAppType.INTERVIEW]: [],
      [EAppType.MSRA]: [],
      [EAppType.PLAB]: [],
    };
  }

  const userSubscriptions: PricingSubscription[] = [];

  entries(getSubscriptions(tokenDecoded)).forEach(
    ([subscriptionName, state]) => {
      if (state === ProductState.Active) {
        userSubscriptions.push(
          createSubscriptionData(
            PRODUCT_TYPE_PER_SUBSCRIPTION_KEY[subscriptionName],
            tokenDecoded[subscriptionName],
            productsPrices
          )
        );
      }
    }
  );

  const subscriptions = sortSubscriptions(userSubscriptions);

  return subscriptions;
};

const getProductsPrices = (productsData: IProduct[]): ProductsPrices[] =>
  productsData.map(({ typeId, price, duration }) => ({
    subscription: typeId,
    price: price / CENTS_IN_POUNDS,
    duration,
  }));

const useSubscriptions = () => {
  const { tokenDecoded } = useAuth();
  const { data, loading } = useQuery<IProductsData, IProductsVar>(PRODUCTS, {
    variables: {
      typeId: EProductType.ALL,
    },
  });

  const { products } = data || {};

  const productsPrices = useMemo(
    () =>
      products && products.length > 0 ? getProductsPrices(products) : undefined,
    [products]
  );

  const subscriptions = useMemo(
    () => getUserSubscriptions(tokenDecoded, productsPrices),
    [productsPrices, tokenDecoded]
  );

  const uniqueProductTypes = useMemo(
    () =>
      new Set(products?.map(({ typeId }) => typeId as AppProductType) || []),
    [products]
  );

  return {
    subscriptions,
    products,
    loading,
    uniqueProductTypes,
  };
};

export default useSubscriptions;
