import { IUkmlaTopic } from '@quesmed/types-rn/models';

import {
  ConditionMap,
  MLATopicItem,
  MLATopicMap,
  Nilable,
  PresentationMap,
} from 'types';

export const transformMLATopics = (
  topics: Nilable<IUkmlaTopic[]>
): [MLATopicMap] => {
  const map: MLATopicMap = {};

  if (topics) {
    topics.forEach(({ id, name, conditions, presentations }) => {
      const newItem: MLATopicItem = { id: Number(id), name };

      if (conditions) {
        newItem.conditions = conditions.reduce((acc, cur) => {
          acc[cur.id] = cur;

          return acc;
        }, {} as ConditionMap);
      }
      if (presentations) {
        newItem.presentations = presentations.reduce((acc, cur) => {
          acc[cur.id] = cur;

          return acc;
        }, {} as PresentationMap);
      }

      map[id] = newItem;
    });
  }

  return [map];
};
