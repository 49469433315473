import React from 'react';
import { EProductType, IUser } from '@quesmed/types-rn/models';

import { Nullable, Undefinable } from 'types';
import { usePlatform } from 'context/PlatformContext';
import OsceDashboard from './OsceDashboard';
import QbankDashboard from './dashboard';
import { FLASHCARDS_PRODUCTS } from 'config/constants';
import { useGetUser } from 'hooks';
import { parseDate } from 'utils';
import { ProductFeedbackModal } from './components/ProductFeedbackModal';
import { useDemo } from 'Auth';

const getDashboard = (
  user: Undefinable<IUser>,
  product: Nullable<EProductType>,
  loading: boolean
) => {
  switch (product) {
    case EProductType.OSCE:
    case EProductType.PACES:
    case EProductType.PLAB2:
      return <OsceDashboard user={user} userLoading={loading} />;
    case null:
      return null;
    default:
      // When we are on a Dashboard and switch prodcut from eg. MRCP Part 1
      // to product MRCP Part 2 we stay on the same QbankDashboard component and
      // the same route so there is no change in the UI. But because theses are
      // separate products we have to force component to fetch new dashboard data
      // for newly selected product to display updated stats and widgets. That
      // is why we have to pass  prodcut as a key to achieve that.
      return (
        <QbankDashboard
          key={product}
          withFlashcards={FLASHCARDS_PRODUCTS.includes(product)}
        />
      );
  }
};

const DashboardController = (): Nullable<JSX.Element> => {
  const { product } = usePlatform();
  const { user, loading } = useGetUser();
  const { nextFeedbackDate } = user?.settings?.productSettings || {};
  const isDemo = useDemo();

  const expirationTime = new Date(parseDate(nextFeedbackDate || 0)).getTime();
  const currentDate = new Date().getTime();
  const showFeedbackModal =
    user && !isDemo && nextFeedbackDate && expirationTime <= currentDate;

  const dashboard = getDashboard(user, product, loading);

  return (
    <>
      {dashboard}
      {product && showFeedbackModal ? <ProductFeedbackModal /> : null}
    </>
  );
};

export default DashboardController;
