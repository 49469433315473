import { useQuery } from '@apollo/client';
import { IOsceStats } from '@quesmed/types-rn/models';
import {
  IOsceStatsData,
  IOsceStatsVar,
  OSCE_STATS,
} from '@quesmed/types-rn/resolvers/query/restricted';

export const useStationsPerformance = () => {
  const { data, error, loading } = useQuery<IOsceStatsData, IOsceStatsVar>(
    OSCE_STATS,
    {
      variables: { solo: true },
    }
  );

  return {
    data: data?.restricted.osceStats || ({} as IOsceStats),
    error,
    loading,
  };
};
