import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { IConcept, ITopic } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import { ExpandableSection } from 'components/ExpandableSection';
import KnowledgeLibraryIcon from 'components/Icons/KnowledgeLibraryIcon';
import { QbankKnowledgeLibraryReading } from 'pages/KnowledgeLibrary/qbank';
import { LearningMaterialsBreadcrumbsProps } from './LearningMaterialsBreadcrumbs';

interface ConceptKnowledgeItemProps {
  concept?: IConcept;
  expanded?: boolean;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
  activeCategoryLabel: string;
  topics: Nullable<ITopic[]>;
  noBreadcrumbs?: boolean;
}

export const ItemContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
}));

const VideoDetailsBookItem = ({
  activeCategoryLabel,
  concept,
  breadcrumbs,
  expanded,
  topics,
  noBreadcrumbs,
}: ConceptKnowledgeItemProps) =>
  concept ? (
    <ItemContainer>
      <ExpandableSection
        expanded={expanded}
        icon={KnowledgeLibraryIcon}
        title={concept.name}
      >
        <QbankKnowledgeLibraryReading
          activeCategoryLabel={activeCategoryLabel}
          breadcrumbs={breadcrumbs}
          concept={concept}
          noBreadcrumbs={noBreadcrumbs}
          topics={topics}
        />
      </ExpandableSection>
    </ItemContainer>
  ) : null;

export default VideoDetailsBookItem;
