import {
  EOsceMarksheetState,
  EOsceRoles,
  EStudyAction,
} from '@quesmed/types-rn/models';
import { EPlatformId } from '@quesmed/types-rn';
import { useEffect, useMemo } from 'react';

import { useCurrentUser } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import { useAgoraState, useSetAgoraControlProps } from 'components/Agora';
import { useAgoraAPI } from 'components/Agora/AgoraAPI';
import checkVideoShouldBeEnabled from '../checkVideoShouldBeEnabled';
import { getUsersToastText } from '../getUsersToastText';
// TODO commented code is left as a reference
// import { RouterStateProps } from 'types';
// import useBuildStudy from 'pages/osce/Study/useBuildStudy';
import useMarksheetData from './useMarksheetData';
import { useGroupStudyGuardState } from 'components/GroupStudyGuard';
import useOnRoleChange from './useOnRoleChange';
import useCreateOrJoin from './useCreateOrJoin';
import useOnStudyAction from './useOnStudyAction';
import { StationModal, useStationModalState } from './useStationModalState';

const useGroupStudy = (
  sessionIdURL?: string
  // matchmaking?: boolean,
  // preselectedStationId?: string
) => {
  const { guardActive } = useGroupStudyGuardState();
  const { id: currentUserId } = useCurrentUser();
  // const [members, setCurrentMembers] =
  //   useState<IOsceMarksheetMember[]>();

  //  const [rebuild, setRebuild] = useState(false);
  const { openModal } = useStationModalState();

  // const location = useLocation<Partial<RouterStateProps>>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar({
    unique: true,
  });

  // const {
  //   agoraId,
  //   isFirstRender,
  //   isNewMarksheet,
  //   loading,
  //   marksheetId,
  //   rebuildStudy,
  //   setIsFirstRender,
  //   inviteLink,
  // } = useBuildStudy(agoraIdURL, Boolean(matchmaking), preselectedStationId);
  const {
    agoraId,
    isNewOsceMarksheet,
    loading,
    osceMarksheetId,
    sessionId,
    createOrJoin,
    inviteLink,
  } = useCreateOrJoin(sessionIdURL);
  const { marksheetData: osceMarksheetData, marksheetLoading } =
    useMarksheetData(loading, osceMarksheetId);

  const { connect } = useAgoraAPI();
  const { sessionExpired } = useAgoraState();
  useOnRoleChange(sessionId);

  const { users, members, activeUsers } = osceMarksheetData || {};

  const notNullMembers = useMemo(
    () => members?.filter(({ user }) => user) || [],
    [members]
  );

  const currentMember = useMemo(
    () => notNullMembers.find(({ user }) => user?.id === currentUserId),
    [currentUserId, notNullMembers]
  );

  const isCurrentUser = useMemo(
    () => Boolean(users?.find(({ id }) => id === currentUserId)),
    [users, currentUserId]
  );

  const isCandidate = currentMember?.role === EOsceRoles.CANDIDATE;
  const isExaminer = currentMember?.role === EOsceRoles.EXAMINER;
  const isActor = currentMember?.role === EOsceRoles.ACTOR;
  const isObserver = currentMember?.role === EOsceRoles.OBSERVER;
  const noCandidate = useMemo(
    () =>
      !notNullMembers.some(
        ({ user, role }) => user && role === EOsceRoles.CANDIDATE
      ),
    [notNullMembers]
  );

  const { state, startedAt, endedAt } = osceMarksheetData || {};
  const lobby = state === EOsceMarksheetState.LOBBY || state === null;
  const preStart = state === EOsceMarksheetState.PRESTART;
  const start = state === EOsceMarksheetState.STATION;
  const canEnterStudyView = Boolean(currentMember) && (preStart || start);
  const stationInProgress = Boolean(start && startedAt && endedAt);
  const canJoinMainChannel = Boolean(
    isCurrentUser &&
      (lobby || (currentMember && (isCandidate ? stationInProgress : true)))
  );
  const shouldRejoin = (sessionExpired && stationInProgress) || !sessionExpired;

  const initSession = Boolean(
    users &&
      users.length > 1 &&
      canJoinMainChannel &&
      agoraId &&
      currentUserId &&
      shouldRejoin
  );

  const initVideo = useMemo(
    () =>
      !lobby &&
      // !rebuild && //TODO check if still needed in the group study
      !loading &&
      checkVideoShouldBeEnabled(members, currentMember?.role),
    [lobby, loading, members, currentMember?.role]
  );

  const { action } = useOnStudyAction(sessionId, undefined, isCandidate);

  useSetAgoraControlProps(
    EPlatformId.OSCE,
    initSession,
    initSession || initVideo,
    initVideo,
    stationInProgress,
    currentUserId,
    agoraId
  );

  useEffect(() => {
    if (sessionIdURL) {
      createOrJoin(EStudyAction.JOIN);
    }
  }, [sessionIdURL, createOrJoin]);

  useEffect(() => {
    const { users: groupUsers, state } = osceMarksheetData || {};
    if (
      state === EOsceMarksheetState.LOBBY &&
      sessionIdURL &&
      // isFirstRender &&
      groupUsers &&
      groupUsers.length > 1
    ) {
      enqueueSnackbar(getUsersToastText(groupUsers, currentUserId), {
        autoHideDuration: 30000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osceMarksheetData]);

  useEffect(() => {
    if (osceMarksheetId && isNewOsceMarksheet) {
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osceMarksheetId]);

  useEffect(() => {
    if (members && currentUserId && !guardActive) {
      const roleChosen = members.find(({ user }) => user?.id === currentUserId);
      if (!roleChosen) {
        openModal(StationModal.RolesModal);
      }
    }
  }, [members, currentUserId, guardActive, openModal]);

  useEffect(() => {
    return closeSnackbar; // ensure that we close snackbar on unmounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const participants = activeUsers
    ? activeUsers.map(({ id, displayName }) => ({
        role: members?.find(({ user }) => user?.id === id)?.role || null,
        id: Number(id),
        displayName,
      }))
    : [];

  return {
    action,
    agoraId,
    buildLoading: loading, // TODO to be removed
    currentUserId,
    isCandidate,
    isActor,
    isExaminer,
    isObserver,
    loading: loading || marksheetLoading,
    marksheetId: osceMarksheetId ? Number(osceMarksheetId) : osceMarksheetId,
    marksheetLoading, // TODO to be removed
    members: members,
    joinedUsers: users,
    participants: participants,
    noCandidate,
    noEnoughMembers: notNullMembers.length < 2,
    notNullMembers,
    osceMarksheet: osceMarksheetData,
    roleAssigned: Boolean(currentMember),
    restartSession: createOrJoin,
    inviteLink: inviteLink || '',
    lobby,
    preStart,
    start,
    canEnterStudyView,
  };
};

export default useGroupStudy;
