import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { ITodo } from '@quesmed/types-rn/models';
import { isNil } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { saveSampleTodoMark } from '@quesmed/types-rn/resolvers/mutation';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import RemoveCardFromDailyStackModal from './RemoveCardFromDailyStackModal';
import FlashcardQuestion from './FlashcardQuestion';
import {
  useExerciseState,
  useIsDesktop,
  useIsMobile,
  useLeaveExerciseState,
} from 'hooks';
import { KeyboardKey } from 'types';
import { paths } from 'Router';
import {
  useFlashcardLeavingBlockade,
  useRemoveFromDailyStack,
  useSaveTodos,
} from './hooks';
import { calcTimeTaken } from 'utils';
import {
  ExerciseBottomBar,
  ExerciseContent,
  ExerciseLayout,
  HelperControl,
} from 'components/Layout';
import { ExercisePills } from 'components/ExercisePills';
import FlascardPillsList from 'components/ProgressPills/FlascardPillsList';
import locales from 'locales';
import { RemoveCardIcon } from 'components/Icons';
import FlashcardsLeaveModal from './FlashcardsLeaveModal';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { QuizProgressBar } from 'components/QuizProgressBar';
import { Button } from 'components/Button';
import { SpaceBarHotKey } from 'components/HotKey';
import ExerciseLaunchedButton from 'components/ExerciseLaunchedButton/ExerciseLaunchedButton';
import ScoreButtons from './ScoreButtons';

export interface FlashcardsQuizProps {
  baseUrl: string;
  cardId: number;
  cardIndex: number;
  isSampleGame: boolean;
  markId: number;
  todo: ITodo;
}

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

const StyledButton = styled(Button)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacing(3, 8),
  marginTop: spacing(4),
  marginBottom: spacing(2),

  '& .hot-key': {
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
}));

export const FlashcardsQuiz = ({
  baseUrl,
  cardId,
  cardIndex,
  isSampleGame,
  markId,
  todo,
}: FlashcardsQuizProps): JSX.Element => {
  const { id: todoId, dailyTask, marks } = todo;
  const currentMark = marks[cardIndex];
  const { timeTaken: prevTimeTaken, id: currentMarkId, score } = currentMark;
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState<number>(0);
  const [isReviewCardOpen, setIsReviewCardOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isRemoveFromDailyStackModal, setIsRemoveFromDailyStackModal] =
    useState(false);
  const client = useApolloClient();
  const { open, openLeaveModal } = useLeaveExerciseState();
  const { isMobile } = useIsMobile();
  const { isDesktop } = useIsDesktop();
  const { setLeaveLabel, setOnLeave } = useExerciseState();

  const { removeFromDailyStack } = useRemoveFromDailyStack();
  const { saveTodos, loading: saveTodosLoading } = useSaveTodos({
    todo,
    cardIndex,
    baseUrl,
    onCompleted: () => setShowAnswer(false),
  });

  usePrealoadImages(todo);

  const completed = !marks.some(({ score }) => isNil(score));

  const reviewWrapperRef = useRef<HTMLDivElement>(null);
  const currentFlashcardNumber = cardIndex + 1;

  useFlashcardLeavingBlockade({
    shouldBlock: Boolean(todoId),
    ignoreBlockade: completed,
  });

  useEffect(() => {
    setStartTime(new Date().getTime());

    return () => {
      setStartTime(0);
    };
  }, [cardIndex]);

  useEffect(() => {
    if (completed) {
      // TODO [WIP] to test and implement post laounch componentns`
      navigate(`${paths.flashcards.root}/quiz/summary/${todoId}`);
      // setIsExamCompletedModal(true);
    }
  }, [completed, navigate, todoId]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (
        reviewWrapperRef.current &&
        !reviewWrapperRef.current.contains(target)
      )
        setIsReviewCardOpen(false);
    }

    if (isDesktop && isReviewCardOpen)
      document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [reviewWrapperRef, isDesktop, isReviewCardOpen]);

  useEffect(() => {
    setIsReviewCardOpen(isDesktop);
  }, [isDesktop, setIsReviewCardOpen]);

  useEffect(() => {
    setLeaveLabel('Leave Session');
    setOnLeave(openLeaveModal);
  }, [openLeaveModal, setLeaveLabel, setOnLeave]);

  const navigateToCard = (newCardIndex: number) => {
    const { cardId: newCardId, id: newMarkId } = marks[newCardIndex];

    navigate(`${baseUrl}/${newMarkId}/${newCardId}/${newCardIndex + 1}`);
  };

  const navigateToIndex = (cardIndex: number) => {
    setShowAnswer(false);
    navigateToCard(cardIndex);
  };

  const handleSaveScore = (score: number) => {
    const timeTaken = calcTimeTaken(startTime, prevTimeTaken);
    const input = { todoId, markId, score, timeTaken };

    if (isSampleGame) {
      saveSampleTodoMark(client, input, markId);
      navigateToIndex(currentFlashcardNumber);
    } else {
      saveTodos(input);
    }
  };

  const handleShowRemoveFromDailyStackModal = useCallback(() => {
    setIsRemoveFromDailyStackModal(true);
  }, []);

  const handleRemoveFromDailyStack = () => {
    setIsRemoveFromDailyStackModal(false);
    removeFromDailyStack(cardId, todoId);
  };

  const handleCheckAnswer = () => {
    setShowAnswer(true);
  };

  useHotkeys(
    KeyboardKey.ArrowRight,
    () => {
      navigateToIndex(currentFlashcardNumber);
    },
    {
      enabled: () => cardIndex < marks.length - 1,
    },
    [cardIndex, marks.length]
  );

  useHotkeys(
    KeyboardKey.ArrowLeft,
    () => {
      navigateToIndex(cardIndex - 1);
    },
    {
      enabled: () => cardIndex >= 1,
    },
    [cardIndex]
  );

  useHotkeys(
    KeyboardKey.Space,
    () => {
      handleCheckAnswer();
    },
    {
      enabled: () => score === null && !showAnswer,
    },
    [score, showAnswer]
  );

  const marksMap = useMemo(() => {
    const map: Map<number, [number, number]> = new Map();

    marks.forEach(({ id }, i) => {
      map.set(id, [i, i + 1]);
    });

    return map;
    // marks changes on every question change, to create this map
    // very simplifeid map only once we can relay only on marks.length
    // to produce the new map as other changes have no influence at it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marks.length]);

  const helperControls: HelperControl[] = [
    {
      icon: <RemoveCardIcon />,
      onClick: handleShowRemoveFromDailyStackModal,
      label: 'Remove this card',
    },
  ];

  return (
    <>
      <ExerciseLayout
        bottomOffsetVariant={isMobile ? 'medium' : 'tiny'}
        collapsedPanel={false}
        controlPanelContent={
          <ExercisePills
            activeTab={locales.questions.tabs.all}
            pillIndex={marks.findIndex(
              ({ id }) => Number(currentMarkId) === Number(id)
            )}
            tabs={[
              {
                label: locales.questions.tabs.all,
                value: locales.questions.tabs.all,
              },
            ]}
          >
            <FlascardPillsList
              currentPillId={currentMarkId}
              fallback={locales.questions.fallbacks.noQuestions}
              onClick={navigateToIndex}
              pills={marks}
              pillsMap={marksMap}
            />
          </ExercisePills>
        }
        controlPanelTitle="Control Panel"
        exerciseContent={
          <>
            <ExerciseContent quizProgressBar>
              <QuizProgressBar
                activeItemNumber={cardIndex + 1}
                leftHelpers={dailyTask ? helperControls : undefined}
                onClickNext={() => navigateToIndex(cardIndex + 1)}
                onClickPrev={() => navigateToIndex(cardIndex - 1)}
                totalQuestions={marks.length}
              />
              <FlashcardQuestion
                cardNumber={currentFlashcardNumber}
                mark={currentMark}
                onShowAnswerClick={setShowAnswer}
                saveTodosLoading={saveTodosLoading}
                setScore={handleSaveScore}
                showAnswer={showAnswer}
              />
              {showAnswer || isMobile ? null : (
                <ButtonContainer>
                  <StyledButton onClick={handleCheckAnswer}>
                    Check Answer
                    <SpaceBarHotKey />
                  </StyledButton>
                </ButtonContainer>
              )}
            </ExerciseContent>
            {isMobile ? (
              <ExerciseBottomBar
                primaryControl={
                  !showAnswer ? (
                    <ExerciseLaunchedButton onClick={handleCheckAnswer}>
                      Check Answer
                    </ExerciseLaunchedButton>
                  ) : (
                    <ScoreButtons
                      cardScore={score}
                      isBottomBar
                      loading={saveTodosLoading}
                      onClick={handleSaveScore}
                    />
                  )
                }
                quizProgressBar
              />
            ) : null}
          </>
        }
        exerciseItemsCount={marks.length}
        noOverflow
      />
      {isRemoveFromDailyStackModal && !isSampleGame && (
        <RemoveCardFromDailyStackModal
          onRemove={handleRemoveFromDailyStack}
          removeCardFromDailyStackCloseModal={() =>
            setIsRemoveFromDailyStackModal(false)
          }
          removeCardFromDailyStackModalIsOpen={isRemoveFromDailyStackModal}
        />
      )}
      {open ? <FlashcardsLeaveModal open={open} /> : null}
    </>
  );
};
