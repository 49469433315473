import { ApolloError } from '@apollo/client';
import { IMarksheet, IQuestion } from '@quesmed/types-rn/models';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorBoundary, ErrorBox } from 'components/Error';
import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';
import QuestionsQuiz from './QuestionsQuiz';
import { parseMarkNumber } from 'utils';
import { CircularProgress } from 'components/CircularProgress';

const { dashboard } = paths;

export const mapIds = (items: { question: IQuestion }[]) =>
  items.reduce(
    (acc, { question }, index) => acc.set(Number(question.id), index),
    new Map<number, number>()
  );

interface QuestionsQuizContainerProps {
  marksheet?: IMarksheet;
  error?: ApolloError;
  loading: boolean;
  isSampleGame: boolean;
  review: boolean;
  flagged?: boolean;
}

const QuestionsQuizContainer = ({
  error,
  flagged,
  loading,
  marksheet,
  review,
}: QuestionsQuizContainerProps): JSX.Element => {
  const navigate = useNavigate();
  const {
    marksheetId = '',
    questionId = '',
    markId = '',
    questionNumber = '',
  } = useParams<{
    marksheetId: string;
    questionId: string;
    markId: string;
    questionNumber: string;
    gameType: string;
  }>();

  // TODO set baseURL in Query containers and pass it via props
  // instead of creating nested ternaries
  const baseUrl = flagged
    ? '/questions/flagged'
    : `/questions/${review ? 'review' : 'solo/quiz'}/${marksheetId}`;

  const { enqueueSnackbar } = useSnackbar({ retryError: error });

  const { marks = [] } = marksheet || {};

  useEffect(() => {
    if (
      marksheet &&
      questionId === '' &&
      questionNumber === '' &&
      markId === ''
    ) {
      const firstUnseenQuestionIndex = marks.findIndex(
        ({ questionChoiceId }) => questionChoiceId === null
      );

      const index =
        firstUnseenQuestionIndex <= 0 ? 0 : firstUnseenQuestionIndex;

      if (marks.length && index >= 0) {
        const {
          id: currrentMarkId,
          question: { id: currentQuestionId },
        } = marks[index];
        navigate(
          `${baseUrl}/${currrentMarkId}/${currentQuestionId}/${index + 1}`,
          { replace: true }
        );
      } else {
        navigate(dashboard);
        enqueueSnackbar('Question not found');
      }
    }
  }, [
    questionNumber,
    marks,
    marksheet,
    questionId,
    baseUrl,
    enqueueSnackbar,
    markId,
    navigate,
  ]);

  if (loading || questionNumber === '') {
    return <CircularProgress description="Getting Marksheet Info" />;
  }
  if (error) {
    return <ErrorBox description={error.message} />;
  }

  if (marksheet) {
    const { marks = [] } = marksheet;
    const validQuestionNumber = parseMarkNumber(questionNumber, marks.length);

    const validQuestionIndex = validQuestionNumber - 1;

    return (
      <ErrorBoundary>
        <QuestionsQuiz
          baseUrl={baseUrl}
          marksheet={marksheet}
          questionIndex={validQuestionIndex}
          review={review}
        />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBox description="Something wrong happened. Try refreshing page or contact administrator." />
  );
};

export default QuestionsQuizContainer;
