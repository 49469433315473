export const compareByField =
  <T extends Object>(field: keyof T) =>
  (a: T, b: T): -1 | 0 | 1 => {
    const aValue = a[field];
    const bValue = b[field];

    if (aValue > bValue) return 1;
    else if (aValue < bValue) return -1;

    return 0;
  };
