import { EUserLearningStatus } from '@quesmed/types-rn/models';

import {
  StickerAlertOutlineIcon,
  StickerCheckOutlineIcon,
  StickerOutlineIcon,
  StickerPlusOutlineIcon,
  StickerTextOutlineIcon,
} from 'components/Icons';
import { Nullable } from 'types';

const getReadingStatusIcon = (
  status?: Nullable<EUserLearningStatus>,
  add = true
) => {
  switch (status) {
    case EUserLearningStatus.URGENT:
      return StickerAlertOutlineIcon;
    case EUserLearningStatus.REVISING:
      return StickerTextOutlineIcon;
    case EUserLearningStatus.COMPLETED:
      return StickerCheckOutlineIcon;
    case EUserLearningStatus.UNREAD:
    default:
      return add ? StickerPlusOutlineIcon : StickerOutlineIcon;
  }
};

export default getReadingStatusIcon;
