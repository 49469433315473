import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  CREATE_OR_JOIN_OSCE_MARKSHEET,
  ICreateOrJoinOsceMarksheetData,
  ICreateOrJoinOsceMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EStudyAction } from '@quesmed/types-rn/models';

import { ERRORS } from 'config/constants';
import { paths } from 'Router';
import { useSnackbar } from 'components/Snackbar';
import useOsceMarksheet from './useOsceMarksheet';
import { StationModal, useStationModalState } from './useStationModalState';

const { stations, dashboard } = paths;
const { groupLobby } = stations;

const useCreateOrJoin = (sessionIdURL = '') => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isNewOsceMarksheet, setIsNewOsceMarksheet] = useState(false);
  const [currentOsceMarksheetId, setCurrentMarksheetId] = useState<number>();
  const { osceMarksheet, fetchOsceMarksheet } = useOsceMarksheet(false);
  const { openModal } = useStationModalState();
  const [start, { data, loading, error }] = useMutation<
    ICreateOrJoinOsceMarksheetData,
    ICreateOrJoinOsceMarksheetVar
  >(CREATE_OR_JOIN_OSCE_MARKSHEET, {
    onCompleted: data => {
      const { id } = data.restricted.createOrJoinOsceMarksheet;
      if (id) {
        fetchOsceMarksheet(Number(id));
      }
    },
  });

  const createOrJoin = useCallback(
    async (action: EStudyAction, cb?: (id?: string) => void) => {
      const { data } = await start({
        variables: { action, sessionId: sessionIdURL },
      });
      const { sessionId: id } =
        data?.restricted?.createOrJoinOsceMarksheet || {};
      cb?.(id);
    },
    [sessionIdURL, start]
  );

  const { id: osceMarksheetId } =
    data?.restricted?.createOrJoinOsceMarksheet || {};

  const {
    activeUsers = [],
    sessionId,
    agoraId = '',
    users = [],
  } = osceMarksheet || {};

  const inviteLink = sessionId
    ? `stations/${groupLobby}?join=${sessionId}`
    : '';

  useEffect(() => {
    if (error) {
      if (error.message === ERRORS.GROUP_STUDY_ALREADY_STARTED) {
        openModal(StationModal.ErrorModal);

        return;
      }

      if (error.message === ERRORS.MARKSHEET_COMPLETED) {
        navigate(dashboard);
      }
    }
  }, [enqueueSnackbar, error, navigate, openModal]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (osceMarksheetId) {
      setIsNewOsceMarksheet(
        Boolean(
          currentOsceMarksheetId !== undefined &&
            currentOsceMarksheetId !== Number(osceMarksheetId)
        )
      );
      setCurrentMarksheetId(Number(osceMarksheetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osceMarksheetId, loading]);

  return {
    activeUsers,
    users,
    sessionId,
    agoraId,
    isNewOsceMarksheet,
    loading,
    osceMarksheetId: currentOsceMarksheetId,
    createOrJoin,
    inviteLink,
  };
};

export default useCreateOrJoin;
