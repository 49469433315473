import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const QuestionChoiceContainer = styled(Box)(
  ({ theme: { palette, spacing, transitions } }) => ({
    borderRadius: 9,
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    transition: transitions.create(['background-color', 'border-color']),
    minHeight: spacing(14),
    overflow: 'hidden',

    '& .MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      flexGrow: 1,
      padding: spacing(4),

      '.MuiCheckbox-root': {
        alignSelf: 'baseline',
        padding: spacing(0, 4, 0, 0),
      },

      '& > .MuiTypography-root': {
        flexGrow: 1,
      },
    },

    '&:hover:not(.valid):not(.invalid):not(.preview)': {
      borderColor: palette.stroke.dark,
    },

    '&.checked:not(.valid):not(.invalid):not(.preview)': {
      borderColor: palette.primary.main,
    },

    '&.invalid': {
      borderColor: palette.error.main,

      '& .MuiFormControlLabel-root .MuiCheckbox-root': {
        '& .MuiSvgIcon-root': {
          color: palette.error.main,
        },
      },
    },

    '&.valid': {
      borderColor: palette.success.dark,

      '& .MuiFormControlLabel-root .MuiCheckbox-root': {
        '& .MuiSvgIcon-root': {
          color: palette.success.dark,
        },
      },
    },

    '&.preview': {
      borderColor: palette.stroke.main,

      '& .MuiFormControlLabel-root .MuiCheckbox-root': {
        '& .MuiSvgIcon-root': {
          color: palette.icon.main,
        },
      },
    },
  })
);

export default QuestionChoiceContainer;
