import isNil from 'lodash/isNil';

import { round } from 'utils/math/round';

export const calcTimeTaken = (
  startTime: number,
  prevTime?: number | null
): number => {
  const previousTime = isNil(prevTime) ? 0 : Number(prevTime);

  return Math.min(
    round((new Date().getTime() - startTime) / 1000) + previousTime,
    3600
  );
};
