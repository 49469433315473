import React, { useEffect } from 'react';
import { IMarksheet } from '@quesmed/types-rn/models';
import { useNavigate, useParams } from 'react-router-dom';

import MockTestsQuiz from './MockTestsQuiz';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { MockTestRouter } from 'types';

const { mockTests } = paths;

interface QuestionsQuizContainerProps {
  loading: boolean;
  marksheet?: IMarksheet;
}

const MockTestsQuizContainer = ({
  loading,
  marksheet,
}: QuestionsQuizContainerProps): JSX.Element => {
  const navigate = useNavigate();
  const {
    marksheetId = '',
    markId = '',
    questionId = '',
    mockTestId = '',
  } = useParams<MockTestRouter>();

  const baseUrl = `${mockTests.root}/test/${mockTestId}/${marksheetId}`;
  const noMarkId = !markId || markId === '' || isNaN(Number(markId));
  const noQuestionId =
    !questionId || questionId === '' || isNaN(Number(questionId));

  const { marks = [] } = marksheet || {};

  useEffect(() => {
    if (marksheet && (noQuestionId || noMarkId)) {
      const firstUnseenCardIndex = marks.findIndex(
        ({ questionChoiceId, mark }) => !questionChoiceId && !mark
      );
      const index = firstUnseenCardIndex < 0 ? 0 : firstUnseenCardIndex;
      const {
        id: currentMarkId,
        question: { id },
      } = marks[index];

      navigate(`${baseUrl}/${currentMarkId}/${id}`, { replace: true });
    }
  }, [marksheet, marks, questionId, baseUrl, noQuestionId, noMarkId, navigate]);

  if (loading || !marksheet || noQuestionId || noMarkId) {
    return <CircularProgress description="Getting Mock Test Data" />;
  }
  const questionIndex = marks.findIndex(
    ({ question }) => Number(question.id) === Number(questionId)
  );

  return (
    <MockTestsQuiz
      baseUrl={baseUrl}
      markId={Number(markId)}
      marksheet={marksheet}
      questionId={Number(questionId)}
      questionIndex={questionIndex}
      questionNumber={questionIndex + 1}
    />
  );
};

export default MockTestsQuizContainer;
