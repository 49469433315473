import React from 'react';
import { EProductType } from '@quesmed/types-rn/models';

import { usePlatform } from 'context/PlatformContext';
import { QbankKnowledgeLibraryChapter } from './qbank';
import { OsceKnowledgeLibraryChapter } from './osce';
import { PacesKnowledgeLibraryChapter } from './paces';

const KnowledgeLibraryChapter = (): JSX.Element => {
  const { product } = usePlatform();

  if (product === EProductType.OSCE || product === EProductType.PLAB2) {
    return <OsceKnowledgeLibraryChapter />;
  }
  if (product === EProductType.PACES) {
    return <PacesKnowledgeLibraryChapter />;
  }

  return <QbankKnowledgeLibraryChapter />;
};

export default KnowledgeLibraryChapter;
