import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { IItemProps } from 'react-movable';

import { CheckIcon, CloseIcon, DragIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import { Markdown } from 'components/Markdown';

const Row = styled(Box)({
  width: '100%',
  display: 'flex',
});

const NumberBox = styled(Body)(({ theme: { palette } }) => ({
  display: 'flex',
  width: '24px',
  color: palette.text.disabled,
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const Item = styled(Body)(({ theme: { palette, spacing, elevation } }) => ({
  width: '100%',
  backgroundColor: palette.background.paper,
  padding: spacing(4),
  boxSizing: 'border-box',
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: 9,
  gap: spacing(2),
  margin: spacing(2, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'grab',
  boxShadow: 'none',

  '&.dragged': {
    boxShadow: elevation[1],
    cursor: 'grabbing',
  },

  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
  },

  '&.correct .MuiSvgIcon-root': {
    color: palette.success.dark,
  },

  '&.incorrect .MuiSvgIcon-root': {
    color: palette.error.main,
  },

  '&.preview .MuiSvgIcon-root': {
    color: palette.icon.main,
  },
}));

type ItemStatus = 'correct' | 'incorrect' | 'draggable';

const getItemIcon = (status?: ItemStatus) => {
  switch (status) {
    case 'correct':
      return <CheckIcon />;
    case 'incorrect':
      return <CloseIcon />;
    case 'draggable':
    default:
      return <DragIcon />;
  }
};

interface RankingQuestionChoiceProps {
  props?: IItemProps;
  isDragged: boolean;
  index: number;
  name: string;
  status?: ItemStatus;
  preview?: boolean;
}

const RankingQuestionChoice = ({
  props,
  name,
  index,
  isDragged,
  status,
  preview,
}: RankingQuestionChoiceProps): JSX.Element => {
  const icon = getItemIcon(status);

  return (
    <Row {...props}>
      <NumberBox bold>{index + 1}</NumberBox>
      <Item bold className={clsx(status, { dragged: isDragged, preview })}>
        <Markdown
          markdownStylesProps={{
            bottomOffset: false,
          }}
          text={name}
        />
        {icon}
      </Item>
    </Row>
  );
};

export default RankingQuestionChoice;
