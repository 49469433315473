import { CheckboxState } from 'types/index';

export const getCheckboxState = (
  selectedCount: number,
  itemsCount: number
): CheckboxState => {
  if (itemsCount === 0) {
    return CheckboxState.UNCHECKED;
  }

  if (selectedCount === itemsCount) {
    return CheckboxState.CHECKED;
  }

  if (selectedCount > 0) {
    return CheckboxState.INTERMEDIATE;
  }

  return CheckboxState.UNCHECKED;
};
