import { useQuery } from '@apollo/client';
import {
  FLAGGED_QUESTIONS,
  IFlaggedQuestionsData,
  IFlaggedQuestionsVar,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useFlaggedQuestionsQuery = () => {
  const {
    data: response,
    loading,
    error,
  } = useQuery<IFlaggedQuestionsData, IFlaggedQuestionsVar>(FLAGGED_QUESTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        limit: 1000,
        offset: 0,
      },
    },
  });

  const { results } = response?.restricted?.flaggedQuestions || {};

  return { data: results, error, loading };
};

export default useFlaggedQuestionsQuery;
