import { IMarksheetMark } from '@quesmed/types-rn/models';

import locales from 'locales';
import { QUESTIONS_TAB } from 'constants/marksheet';

type QuestionTab = typeof QUESTIONS_TAB[number];

export const filterMarks = (
  marks: IMarksheetMark[],
  tab: QuestionTab
): [IMarksheetMark[], string] => {
  switch (tab) {
    case 'flagged':
      return [
        marks.filter(({ flagged }) => flagged),
        locales.questions.fallbacks.noFlagged,
      ];
    case 'all':
    default:
      return [marks, locales.questions.fallbacks.noQuestions];
  }
};
