import React from 'react';
import { mdiCreditCardCheck } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CreditCardCheckIcon = (props: IconProps) => (
  <Icon path={mdiCreditCardCheck} {...props} />
);

export default CreditCardCheckIcon;
