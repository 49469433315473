import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'components/Modal';
import { ERRORS } from 'config/constants';
import { paths } from 'Router';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct } from 'utils';

const { stations, questions } = paths;

export interface StudyAlreadyStartedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const StudyAlreadyStartedModal: FC<StudyAlreadyStartedModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const { product } = usePlatform();

  const handleLeave = () => {
    onClose();
    const path = isStationsProduct(product) ? stations.root : questions.root;
    navigate(path, { state: { leave: true } });
  };

  return (
    <Modal
      cancelLabel="Leave"
      onClose={handleLeave}
      onSubmit={handleLeave}
      open={isOpen}
      title="Session has already started"
    >
      <div>{ERRORS.GROUP_STUDY_ALREADY_STARTED}</div>
    </Modal>
  );
};

export default StudyAlreadyStartedModal;
