import React from 'react';
import { styled } from '@mui/material/styles';

import { Body } from 'components/Typography';
import { TimerIcon } from 'components/Icons';

const TimerValue = styled(Body)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    color: palette.mockTest.contrastText,
    display: 'inline-flex',
    alignSelf: 'center',
    gap: spacing(2),

    '& .MuiSvgIcon-root': {
      display: 'inline-flex',
    },

    '& .MuiTypography-root': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      '& .MuiSvgIcon-root': {
        display: 'none',
      },

      '& .MuiTypography-root': {
        display: 'inline-flex',
      },
    },
  })
);

interface MockTestsTimerProps {
  timeLeft: string;
}

export const MockTestsTimer = ({
  timeLeft,
}: MockTestsTimerProps): JSX.Element => (
  <TimerValue component="span">
    <TimerIcon /> <Body component="span">Time remaining:</Body> {timeLeft}
  </TimerValue>
);
