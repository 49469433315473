import { IBuildMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IPreBuildUkmlaMarksheetData } from '@quesmed/types-rn/resolvers/query/restricted';

import { OnRequestComplete } from 'types';
import useBuildMarksheetMutation from '../../Questions/hooks/useBuildMarksheetMutation';
import usePrebuildMLAQuestions from './usePrebuildMLAQuestions';

interface BuildQuestionsParams {
  search?: string;
  source?: string;
  marksheetId?: number;
  onPrebuildComplete?: OnRequestComplete<IPreBuildUkmlaMarksheetData>;
  onBuildComplete?: OnRequestComplete<IBuildMarksheetData>;
}

const useBuildMLAQuestions = (params: BuildQuestionsParams) => {
  const { onBuildComplete, marksheetId, ...rest } = params || {};

  const { preBuildLoading, preBuildData, preBuildQuestions } =
    usePrebuildMLAQuestions({ ...rest, marksheetId });

  const { buildLoading, buildMarksheet: buildQuestions } =
    useBuildMarksheetMutation(onBuildComplete, marksheetId);

  return {
    buildLoading,
    buildQuestions,
    preBuildData,
    preBuildLoading,
    preBuildQuestions,
  };
};

export default useBuildMLAQuestions;
