import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import Collapse from '@mui/material/Collapse';

import { Button } from 'components/Button';
import { shouldForwardProp } from 'utils';

export const CommentsBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing(2),
}));

export const RepliesWrapper = styled(Box)({
  width: '100%',
  maxHeight: '430px',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
});

export const ShowMoreButton = styled(Button, {
  shouldForwardProp: prop => shouldForwardProp(['expanded'], prop),
})<{ expanded: boolean }>(({ theme: { transitions }, expanded }) => ({
  display: 'flex',
  margin: '0 auto',
  textAlign: 'center',

  '& .MuiSvgIcon-root': {
    transform: expanded ? 'rotate(-180deg)' : 'rotate(0)',
    transition: transitions.create(['color', 'transform', 'fill']),
  },
}));

export const CollapseCommentInput = styled(Collapse)(
  ({ theme: { spacing, palette } }) => ({
    borderLeft: `1px solid ${palette.stroke.main}`,
    paddingRight: spacing(2),
    paddingLeft: spacing(6),
    marginTop: spacing(2),
  })
);
