import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'components/Modal';
import QbankConceptReading from 'pages/KnowledgeLibrary/qbank/QbankConceptReading';
import { BookshelfIcon, FolderOpenOutlineIcon } from 'components/Icons';
import { paths } from 'Router';
import useConcept from './hooks/useConcept';
import { SkeletonList } from 'components/Skeleton';
import { useUserSettings } from 'hooks';

interface ModalConceptProps {
  open: boolean;
  onClose: () => void;
  conceptId: number;
}

const { knowledgeLibrary } = paths;

const ModalConcept = ({ conceptId, open, onClose }: ModalConceptProps) => {
  const navigate = useNavigate();
  const { concept, loading } = useConcept({ conceptId });
  const { expandedReading } = useUserSettings();

  const {
    chapter,
    topic,
    id: conceptDataId,
    videos: conceptVideos,
    name: conceptName,
    status,
  } = concept || {};

  const { name: topicName = '', id: topicId, entitlement } = topic || {};

  const { explanation, pictures } = chapter || {};

  const handleNavigateToRoot = useCallback(() => {
    navigate(knowledgeLibrary.root);
  }, [navigate]);

  const handleNavigateToCategory = useCallback(() => {
    navigate(`${knowledgeLibrary.root}/section/${entitlement?.id}/${topicId}`);
  }, [navigate, topicId, entitlement]);

  const readingBreadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: entitlement?.name || '',
        onClick: handleNavigateToRoot,
      },
      {
        icon: FolderOpenOutlineIcon,
        label: topicName,
        onClick: handleNavigateToCategory,
      },
    ],
    [
      entitlement?.name,
      handleNavigateToCategory,
      handleNavigateToRoot,
      topicName,
    ]
  );

  return (
    <Modal
      heightVariant="sm"
      onClose={onClose}
      open={open}
      showCloseButton
      showHeader
      sizeVariant="lg"
      title="Knowledge library entry"
    >
      {loading ? (
        <SkeletonList count={4} height={56} spacing={4} />
      ) : (
        <QbankConceptReading
          breadcrumbs={readingBreadcrumbs}
          conceptId={Number(conceptDataId)}
          conceptName={conceptName || ''}
          expandedReading={expandedReading}
          explanation={explanation}
          hideExerciseInvitation
          hideFlashcardInvitation
          pictures={pictures}
          status={status}
          topicId={topicId}
          videos={conceptVideos}
        />
      )}
    </Modal>
  );
};

export default ModalConcept;
