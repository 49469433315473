import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Button } from 'components/Button';
import { TextField } from 'components/TextField/TextField';

const COMMENT_TEXTFIELD_PADDING = 3;

export const CommentTextField = styled(TextField)(
  ({ theme: { palette, spacing, typography, transitions } }) => ({
    '& .MuiFilledInput-root': {
      border: `1px solid ${palette.stroke.main}`,
      padding: spacing(4, COMMENT_TEXTFIELD_PADDING),
      borderRadius: '4px',
      color: palette.text.primary,
      backgroundColor: palette.background.default,
      transition: transitions.create(['border-color']),
      ...typography.body1,

      textarea: {
        overflowX: 'hidden',
      },

      button: {
        marginTop: spacing(-2),
        marginBottom: spacing(-2),
      },

      '&.multiline-active': {
        flexDirection: 'column',
        button: {
          marginTop: spacing(4),
          marginBottom: 0,
        },
      },

      '&:hover': {
        border: `1px solid ${palette.stroke.dark}`,
        backgroundColor: palette.background.default,
      },

      '&.Mui-focused': {
        border: `1px solid ${palette.primary.main}`,
        backgroundColor: palette.background.default,
      },
    },
  })
);

export const InputBox = styled(Box)(() => ({
  position: 'relative',
}));

// This is a hidden span that is used to calculate the width based on the content in textarea.
// Textarea is not able to calculate the width of content by itself
// (because it is not resizable based on the length of content).
// This span has to be hidden and positioned absolutely and hidden by zIndex: -1
// to not let user interact with it as it is for dev purposes only.
export const CalculationHelperSpan = styled('span')(
  ({ theme: { spacing } }) => ({
    visibility: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: spacing(COMMENT_TEXTFIELD_PADDING), // this value is equals to left padding of the  CommentTextField
    zIndex: -1,
  })
);

export const SubmitButton = styled(Button)({
  marginLeft: 'auto',
});
