import React, { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { hexToRgb } from '@mui/material';
import isNil from 'lodash/isNil';

import { constSize } from 'utils';

const MIN_OPTION_WIDTH = 200;

interface CategoryOption<T> {
  value: T;
  label: string;
}

const Container = styled(Box)(
  ({ theme: { breakpoints, spacing, palette } }) => ({
    margin: spacing(0, -6),
    width: `calc(100% + ${spacing(12)})`,

    '&.mla': {
      borderTop: `1px solid ${palette.stroke.main}`,
    },

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
    },
  })
);

const CategoriesContainer = styled(Box)(
  ({ theme: { palette, breakpoints } }) => ({
    width: '100%',
    borderRadius: 0,
    border: `1px solid ${palette.stroke.main}`,
    overflow: 'hidden',
    minHeight: '40px',

    [breakpoints.up('md')]: {
      borderRadius: '7px 7px 0 0',
    },
  })
);

const StyledTabs = styled(MuiTabs)<{ count: number }>(
  ({ theme: { palette, spacing, transitions }, count }) => ({
    height: '40px',
    minHeight: '40px',

    '& .MuiTabs-scroller': {
      height: spacing(10),
    },

    '& .MuiButtonBase-root.MuiTab-root': {
      height: '40px',
      minHeight: '40px',
      display: 'inline-flex',
      flexDirection: 'row',

      '&:hover:not(.Mui-selected)': {
        color: palette.secondary.dark,
      },

      '&.Mui-selected:hover': {
        color: palette.primary.dark,
      },
    },

    '&.MuiTabs-root': {
      position: 'relative',
    },

    '& .MuiTabScrollButton-root': {
      opacity: 1,
      position: 'relative',
      backgroundColor: palette.background.paper,
      ...constSize('40px'),
      cursor: 'pointer',

      '& .MuiSvgIcon-root': {
        position: 'relative',
        color: palette.icon.main,
        transition: transitions.create(['color', 'opacity']),
        backgroundColor: palette.background.paper,
        borderRadius: '12px',
        border: `1px solid ${palette.stroke.main}`,
      },

      '&:last-of-type': {
        '&::after': {
          content: '""',
          width: '16px',
          height: '40px',
          position: 'absolute',
          left: '-15px',
          background: `linear-gradient(270deg, ${hexToRgb(
            palette.background.paper || ''
          )} 25%, ${palette.opacity.gradient} 100%)`,
        },
      },

      '&:first-of-type': {
        right: 0,
        zIndex: 1,

        '&::after': {
          content: '""',
          width: '16px',
          height: '40px',
          position: 'absolute',
          right: '-15px',
          borderLeft: `1px solid ${palette.background.paper}`,
          background: `linear-gradient(90deg, ${hexToRgb(
            palette.background.paper || ''
          )} 25%, ${palette.opacity.gradient} 100%)`,
        },
      },

      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: palette.icon.dark,
        },
      },

      '&.Mui-disabled': {
        opacity: 1,

        '& .MuiSvgIcon-root': {
          opacity: 0.3,
        },
      },
    },

    '& .MuiTabs-flexContainer': {
      '& > .MuiTab-root': {
        width: `calc(100% / ${count})`,
        minWidth: `${MIN_OPTION_WIDTH}px`,
        maxWidth: 'initial',

        backgroundColor: palette.toggle.light,
        color: palette.text.disabled,
        transition: transitions.create(['color', 'background-color']),

        '&:not(:first-of-type)': {
          borderLeft: `1px solid ${palette.stroke.main}`,
        },

        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.background.paper,
          border: 'none',
          borderRadius: 0,

          '&:not(:first-of-type)': {
            borderLeft: `1px solid ${palette.stroke.main}`,
          },

          '&:hover': {
            border: 'none',
            borderRadius: 0,
            color: palette.text.primary,
            backgroundColor: palette.background.paper,

            '&:not(:first-of-type)': {
              borderLeft: `1px solid ${palette.stroke.main}`,
            },
          },
        },

        '&:hover': {
          border: 'none',
          borderRadius: 0,
          color: palette.toggle.contrastTextDark,
          backgroundColor: palette.toggle.light,

          '&:not(:first-of-type)': {
            borderLeft: `1px solid ${palette.stroke.main}`,
          },
        },
      },
    },
  })
);

export interface SelectionCategoriesProps<T> {
  activeCategory?: T;
  categoryOptions?: CategoryOption<T>[];
  onToggleCategory?: (event: SyntheticEvent, value: T) => void;
}

function SelectionCategories<T>({
  activeCategory,
  categoryOptions,
  onToggleCategory,
}: SelectionCategoriesProps<T>) {
  if (!categoryOptions) {
    return null;
  }

  return categoryOptions.length ? (
    <Container>
      <CategoriesContainer>
        {!isNil(activeCategory) ? (
          <StyledTabs
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            count={categoryOptions.length}
            onChange={onToggleCategory}
            value={activeCategory}
            variant="scrollable"
          >
            {categoryOptions.map(({ label, value }) => (
              <MuiTab
                color="secondary"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                key={String(label + value)}
                label={label}
                value={Number(value)}
              />
            ))}
          </StyledTabs>
        ) : null}
      </CategoriesContainer>
    </Container>
  ) : (
    <Container className="mla" />
  );
}

export default SelectionCategories;
