import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import isNil from 'lodash/isNil';

import { Body } from 'components/Typography';

const StyledBox = styled(Box)({
  display: 'inline-flex',
  flexDirection: 'column',
});

const SelectionCount = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

export interface SelectionInputCellLabelProps {
  name: string;
  total?: number;
  count?: number;
  labelOfTotal?: string;
}

const SelectionInputCellLabel = ({
  name,
  total,
  count,
  labelOfTotal,
}: SelectionInputCellLabelProps): JSX.Element => (
  <StyledBox component="span">
    <Body bold component="span">
      {name}
    </Body>
    {!isNil(count) && !isNil(total) ? (
      <SelectionCount component="span">
        Selected {count}/{total}
      </SelectionCount>
    ) : null}
    {!isNil(total) && labelOfTotal ? (
      <SelectionCount component="span">
        {labelOfTotal} {total}
      </SelectionCount>
    ) : null}
  </StyledBox>
);

export default SelectionInputCellLabel;
