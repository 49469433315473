import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { EProductType, IConcept } from '@quesmed/types-rn/models';
import {
  IPaceBookData,
  PACE_KNOWLEDGE_LIBRARY,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { Nullable, ToggleOptions } from 'types';
import { compareRankNameAsc, entries, sortCategoriesByTopicType } from 'utils';

interface ConceptMap {
  [key: number]: IConcept[];
}

export const usePaceKnowledgeLibraryQuery = (): {
  concepts: Nullable<IConcept[]>;
  loading: boolean;
  categories: ToggleOptions<EProductType>;
  conceptsMap: Nullable<ConceptMap>;
} => {
  const { data, loading } = useQuery<IPaceBookData>(PACE_KNOWLEDGE_LIBRARY, {
    fetchPolicy: 'cache-and-network',
  });

  const { paceBook } = data?.restricted || {};

  const [concepts, conceptsMap, categories] = useMemo(() => {
    const concepts = paceBook ? [...paceBook].sort(compareRankNameAsc) : [];
    const sortedConcepts: ConceptMap = {};
    const entitlements = {} as {
      [key in EProductType]: string;
    };
    const typeOrder: number[] = [];

    concepts.forEach(concept => {
      const { entitlement } = concept;

      if (entitlement?.id) {
        if (!typeOrder[entitlement.index]) {
          typeOrder[entitlement.index] = entitlement.id;
        }
        if (sortedConcepts[entitlement?.id]) {
          sortedConcepts[entitlement?.id].push(concept);
        } else {
          sortedConcepts[entitlement?.id] = [concept];
        }

        if (!entitlements[entitlement.id]) {
          entitlements[entitlement.id] = entitlement.name;
        }
      }
    });

    const categories = sortCategoriesByTopicType(
      entries(entitlements).map(([key, value]) => ({
        value: Number(key),
        label: value,
      })),
      typeOrder
    );

    return [concepts, sortedConcepts, categories];
  }, [paceBook]);

  return { concepts, loading, categories, conceptsMap };
};
