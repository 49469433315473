import React from 'react';
import { Skeleton } from '@mui/material';

import { SkeletonContainer, TableContainer } from './SelectionTable.styles';

const TableSkeleton = (): JSX.Element => (
  <TableContainer>
    <SkeletonContainer>
      {[...Array(7).keys()].map(item => (
        <Skeleton height={64} key={item} variant="rectangular" />
      ))}
    </SkeletonContainer>
  </TableContainer>
);

export default TableSkeleton;
