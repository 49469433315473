import { useQuery } from '@apollo/client';
import { ESortOrder } from '@quesmed/types-rn';
import {
  DASHBOARD,
  IDashboardQBank,
} from '@quesmed/types-rn/resolvers/query/restricted';

type DashboardQBankVar = {
  filter: {
    limit: number;
    date?: string;
    offset?: number;
    solo: Boolean;
    period?: number;
  };
  order?: ESortOrder;
};

const useDashboardQuery = () => {
  const { data, loading } = useQuery<IDashboardQBank, DashboardQBankVar>(
    DASHBOARD,
    {
      variables: {
        filter: { limit: 3, date: 'desc', offset: 0, solo: true },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { latestSessionId, latestPastMarksheetId, user } =
    data?.restricted || {};

  return {
    data: { latestSessionId, latestPastMarksheetId, user },
    loading,
  };
};

export default useDashboardQuery;
