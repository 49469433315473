import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';
import isNil from 'lodash/isNil';
import CircularProgress from '@mui/material/CircularProgress';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Icon, Nullable, Undefinable } from 'types';
import { constSize, values } from 'utils';
import { getReadingStatusIcon, getReadingStatusLabel } from '../utils';
import ReadingStatusIcon from './ReadingStatusIcon';
import ReadingStatusModal from './ReadingStatusModal';
import { Popper } from 'components/Popper';
import { IconButton } from 'components/IconButton';
import { useHover, usePrevious } from 'hooks';
import { ListItemButton, ListItemButtonProps } from 'components/List';
import { CheckIcon } from 'components/Icons';
import { useSnackbar } from 'components/Snackbar';
import { STATUS_ORDER } from '../constants';
import { LearningMaterialItem } from '../types';

const StyledListButton = styled(ListItemButton)(
  ({ theme: { palette, spacing, transitions } }) => ({
    '&.MuiListItemButton-root': {
      padding: spacing(2, 3),

      '&.MuiButtonBase-root.active': {
        cursor: 'default',

        '&:hover': {
          '.MuiSvgIcon-root.check-icon': {
            fill: palette.icon.main,
          },
        },
      },

      '& .MuiSvgIcon-root': {
        transition: transitions.create(['fill']),
      },
    },

    '&.urgent': {
      '.MuiSvgIcon-root:not(.check-icon)': {
        fill: palette.warning.main,
      },

      '&:not(.active):hover': {
        '.MuiSvgIcon-root:not(.check-icon)': {
          fill: palette.warning.dark,
        },
      },
    },

    '&.revising': {
      '.MuiSvgIcon-root:not(.check-icon)': {
        fill: palette.primary.main,
      },

      '&:not(.active):hover': {
        '.MuiSvgIcon-root:not(.check-icon)': {
          fill: palette.primary.dark,
        },
      },
    },

    '&.complete, &.watched': {
      '.MuiSvgIcon-root:not(.check-icon)': {
        fill: palette.success.light,
      },

      '&:not(.active):hover': {
        '.MuiSvgIcon-root:not(.check-icon)': {
          fill: palette.success.main,
        },
      },
    },

    '&.unread, &.unwatched': {
      '.MuiSvgIcon-root:not(.check-icon)': {
        fill: palette.icon.main,
      },

      '&:not(.active):hover': {
        '.MuiSvgIcon-root:not(.check-icon)': {
          fill: palette.icon.dark,
        },
      },
    },

    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
    },
  })
);

const StyledIconButton = styled(IconButton)(({ theme: { palette } }) => ({
  ...constSize('40px'),
  padding: 0,

  '&.disabled': {
    cursor: 'default',
    '& .unread, & .unwatched, & .complete, & .revising, & .urgent, & .watched':
      {
        '.MuiSvgIcon-root': {
          fill: palette.icon.light,
        },
      },
  },
}));

interface MenuItemProps
  extends Omit<ListItemButtonProps, 'expanded' | 'nested'> {
  label: string;
  icon: Icon;
  active: boolean;
}

export const StatusMenuItem = ({
  icon: Icon,
  label,
  active,
  ...props
}: MenuItemProps): JSX.Element => (
  <StyledListButton
    className={clsx(label.toLowerCase(), { active })}
    expanded={1}
    nested={0}
    {...props}
  >
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText disableTypography primary={label} />
    {active ? <CheckIcon className="check-icon" /> : null}
  </StyledListButton>
);

const statuses = values(EUserLearningStatus).filter(
  status => typeof status === 'number' && status !== 0
);

const createSuccessMessage = (
  count: number,
  status: EUserLearningStatus,
  video?: boolean
) => {
  const name = getReadingStatusLabel(status, video).toLowerCase();

  if (video) {
    return `${count > 1 ? `${count} videos` : 'Video'} marked as ${name}`;
  }

  return `${count > 1 ? `${count} articles` : 'Article'} marked as ${name}`;
};

const createErrorMessage = (status: EUserLearningStatus, video?: boolean) => {
  const name = getReadingStatusLabel(status, video).toLowerCase();

  return `Marking as ${name} failed`;
};

type StatusUpdate = [EUserLearningStatus, number[]];

interface StatusMenuProps<T> {
  id?: number;
  disabled?: boolean;
  status?: Nullable<EUserLearningStatus>;
  video?: boolean;
  add?: boolean;
  items?: LearningMaterialItem[];
  isSample?: boolean;
  onStatusChange?: (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => Undefinable<Promise<T>>;
}

function StatusMenu<T>({
  id,
  disabled,
  add,
  status: currentStatus,
  items,
  onStatusChange,
  video = false,
  isSample = false,
}: StatusMenuProps<T>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLSpanElement>>(null);
  const [loading, setLoading] = useState(false);
  const [statusUpdate, setStatusUpdate] =
    useState<Nullable<StatusUpdate>>(null);
  const [, isPopupHover, , setPopupRef] = useHover<HTMLDivElement>();
  const [buttonRef, isButtonHover] = useHover<HTMLButtonElement>();
  const { enqueueSnackbar } = useSnackbar();
  const prevIsButtonHover = usePrevious(isButtonHover);
  const prevIsPopupHover = usePrevious(isPopupHover);
  const noHover = !isPopupHover && !isButtonHover;
  const wasHover = prevIsPopupHover || prevIsButtonHover;

  const handleUpdateStatus = async (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => {
    if (onStatusChange && !disabled) {
      try {
        setLoading(true);
        await onStatusChange(newStatus, ids);
        if (!isSample) {
          enqueueSnackbar(createSuccessMessage(ids.length, newStatus, video));
        }
      } catch {
        enqueueSnackbar(createErrorMessage(newStatus, video));
      } finally {
        setStatusUpdate(null);
        setLoading(false);
      }
    }
  };

  const handleOptionClick =
    (status: EUserLearningStatus) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (onStatusChange && currentStatus !== status) {
        if (id) {
          handleUpdateStatus(status, [id]);

          return;
        }

        if (items) {
          setStatusUpdate([status, items.map(({ id }) => Number(id))]);
          setAnchorEl(null);
        }
      }
    };

  const options = statuses
    .map(status => ({
      status,
      active:
        currentStatus === status ||
        (isNil(currentStatus) && status === EUserLearningStatus.UNREAD),
      label: getReadingStatusLabel(status, video) as string,
      icon: getReadingStatusIcon(status, false),
      onClick: handleOptionClick(status),
    }))
    .sort(
      (a, b) => STATUS_ORDER.indexOf(a.status) - STATUS_ORDER.indexOf(b.status)
    );

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open && noHover && wasHover) {
      setAnchorEl(null);
    }
  }, [open, noHover, wasHover]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (loading) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [newStatus] = statusUpdate || [];

  const handleSubmit = async () => {
    if (statusUpdate && onStatusChange) {
      const [newStatus, idsToUpdate] = statusUpdate;

      handleUpdateStatus(newStatus, idsToUpdate);
    }
  };

  const handleCloseModal = () => {
    setStatusUpdate(null);
  };

  return (
    <>
      <StyledIconButton
        aria-controls={open ? 'reading-status-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="reading-status-menu"
        className={clsx({ disabled })}
        icon={
          loading ? (
            <CircularProgress size={20} />
          ) : (
            <ReadingStatusIcon add={!add} status={currentStatus} />
          )
        }
        onClick={disabled ? undefined : handleOpenMenu}
        ref={buttonRef}
      />
      <Popper
        anchorEl={anchorEl}
        disablePortal
        onClose={handleCloseMenu}
        open={open}
        paperSx={({ spacing }) => ({
          padding: spacing(0, 2),
          width: '198px',
        })}
        placement="auto"
        ref={setPopupRef}
      >
        <List>
          {options.map(({ active, label, icon, onClick }) => (
            <StatusMenuItem
              active={active}
              icon={icon}
              key={label}
              label={label}
              onClick={onClick}
            />
          ))}
        </List>
      </Popper>
      {statusUpdate && !isNil(newStatus) ? (
        <ReadingStatusModal
          loading={loading}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          open={Boolean(statusUpdate)}
          status={newStatus}
          video={video}
        />
      ) : null}
    </>
  );
}

export default StatusMenu;
