import React, { ComponentType } from 'react';
import { clsx } from 'clsx';

import { EditIcon, IconProps } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import {
  ButtonRow,
  CategoryTitle,
  HeaderContainer,
  HeaderWrapper,
  LeftControlContainer,
  RightActionButton,
  StyledIconButton,
  Title,
} from './ContentHeader.styles';
import {
  LearningMaterialsBreadcrumbs,
  LearningMaterialsBreadcrumbsProps,
} from 'components/LearningMaterials/components/LearningMaterialsBreadcrumbs';
import { useMarkdownTextScale } from 'components/Markdown';
import locales from 'locales';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';

interface QuestionHeaderProps {
  title: string;
  categoryTitle?: string;
  buttonText?: string;
  buttonTip?: string;
  icon?: ComponentType<IconProps>;
  iconHidden?: boolean;
  buttonHidden?: boolean;
  iconActive?: boolean;
  buttonIcon?: ComponentType<IconProps>;
  onIconClick?: () => void;
  onButtonClick?: () => void;
  onEditClick?: () => void;
  iconTip?: string;
  className?: string;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
  learningStatus?: JSX.Element;
  center?: boolean;
  scallable?: boolean;
}

const ContentHeader = ({
  title,
  categoryTitle,
  buttonIcon: ButtonIcon,
  buttonText,
  icon: Icon,
  iconActive,
  onIconClick,
  buttonTip,
  iconTip,
  onButtonClick,
  onEditClick,
  iconHidden,
  buttonHidden,
  breadcrumbs,
  className,
  learningStatus,
  center = false,
  scallable = false,
}: QuestionHeaderProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const titleClassNames = {
    'with-category-title': categoryTitle,
    'no-margin': learningStatus,
  };
  const isDemo = useDemo();
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  const handleEditClick = () => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      onEditClick?.();
    }
  };

  return (
    <HeaderWrapper className={clsx(className, { center })}>
      {breadcrumbs ? (
        <LearningMaterialsBreadcrumbs items={breadcrumbs} />
      ) : null}
      {categoryTitle && !breadcrumbs ? (
        <CategoryTitle>{categoryTitle}</CategoryTitle>
      ) : null}

      <HeaderContainer>
        <LeftControlContainer>
          <Title
            className={clsx('content-header-title', titleClassNames)}
            scale={scallable ? scale : undefined}
          >
            {title}
          </Title>
          {Icon && !iconHidden && !learningStatus ? (
            <Tooltip placement="bottom" textCenter title={iconTip}>
              <StyledIconButton
                className={iconActive ? 'active' : ''}
                onClick={onIconClick}
              >
                <Icon />
              </StyledIconButton>
            </Tooltip>
          ) : null}
          {learningStatus && !Icon ? learningStatus : null}
        </LeftControlContainer>
        <ButtonRow>
          {onEditClick ? (
            <Tooltip placement="bottom" textCenter title={buttonTip}>
              <RightActionButton
                onClick={handleEditClick}
                // TODO: For now, to get attention
                // color="secondary"
                // secondary
              >
                Edit {ButtonIcon && <EditIcon />}
              </RightActionButton>
            </Tooltip>
          ) : null}
          {onButtonClick && buttonText && !buttonHidden && (
            <Tooltip placement="bottom" textCenter title={buttonTip}>
              <RightActionButton
                color="secondary"
                onClick={onButtonClick}
                secondary
              >
                {buttonText} {ButtonIcon && <ButtonIcon />}
              </RightActionButton>
            </Tooltip>
          )}
        </ButtonRow>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default ContentHeader;
