import { EOsceRoles } from '@quesmed/types-rn/models';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CircularProgress } from 'components/CircularProgress';
import { useOsceMarksheet, useStationLeavingBlockade } from './hooks';
import Station from './Station';
import StationsLeaveModal from './StationsLeaveModal';
import { useLeaveExerciseState } from 'hooks';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const StationsSoloStudy = (): JSX.Element => {
  const { osceMarksheetId } = useParams<{ osceMarksheetId: string }>();
  const { open } = useLeaveExerciseState();
  const { osceMarksheet, osceMarksheetLoading } = useOsceMarksheet(
    false,
    Number(osceMarksheetId)
  );

  const { completed, id, osceStation } = osceMarksheet || {};

  useStationLeavingBlockade({
    osceMarksheetId: id,
    shouldBlock: Boolean(id && osceStation),
    ignoreBlockade: Boolean(completed),
    solo: true,
  });

  const data = useMemo(() => {
    if (osceMarksheet && osceMarksheet.members && osceMarksheet.osceStation) {
      const { members, osceStation } = osceMarksheet;
      const user = members.find(({ userId }) => userId);
      const { userId } = user || {};

      return {
        currentUserId: userId ? userId : 1,
        osceStation: osceStation,
        osceMarksheet,
        role: EOsceRoles.EXAMINER,
        isExaminer: true,
        isCandidate: false,
      };
    }

    return undefined;
  }, [osceMarksheet]);

  if (osceMarksheetLoading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!data || !osceMarksheet) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Station data={data} />
      {open ? <StationsLeaveModal open={open} osceMarksheetId={id} /> : null}
    </>
  );
};

export default StationsSoloStudy;
