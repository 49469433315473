import React from 'react';

import { Modal, ModalProps } from 'components/Modal/Modal';
import { Body } from 'components/Typography';
import { Nullable } from 'types';

type QuitExerciseModalProps = ModalProps & {
  message?: Nullable<string>;
};

const EndExerciseModal = ({
  open,
  onClose,
  onSubmit,
  message,
  ...props
}: QuitExerciseModalProps): JSX.Element => (
  <Modal
    cancelLabel="cancel"
    danger
    maxWidth="md"
    noPaddingY
    onCancel={onClose}
    onClose={onClose}
    onSubmit={onSubmit}
    open={open}
    showCloseButton
    sizeVariant="md"
    submitLabel="End session"
    title="Are you sure you want to end?"
    {...props}
  >
    <Body>
      {message ??
        'Please confirm if you want to end the session and proceed to the set summary. Any progress made will be saved.'}
    </Body>
  </Modal>
);

export default EndExerciseModal;
