import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  EProductType,
  EUserLearningStatus,
  IOsceStation,
} from '@quesmed/types-rn/models';

import {
  Buttons,
  ExerciseInvitationContainer,
  LearningMaterialsBreadcrumbsProps,
  MarschemeReadingSection,
  ReadingBriefTitle,
  ReadingContentContainer,
  StatusMenu,
} from 'components/LearningMaterials/components';
import { KnowledgeLibraryRouterParams } from 'components/LearningMaterials/types';
import { useUpdateStationLearningStatus } from 'components/LearningMaterials/hooks';
import {
  getPacesLibraryName,
  parseOsceReading,
} from 'components/LearningMaterials/utils';
import { ComplementaryReadings, Nullable, WithDemo } from 'types';
import { SkeletonList } from 'components/Skeleton';
import {
  CollapseVerticalIcon,
  ExpandVerticalIcon,
  StationIcon,
} from 'components/Icons';
import { ContentHeader } from 'components/ContentHeader';
import { ExpandableSection } from 'components/ExpandableSection';
import { Markdown } from 'components/Markdown';
import { Lightbox } from 'components/Lightbox';
import { parseFullText } from 'utils';
import StationsTimerModal from 'pages/Stations/StationsTimerModal';
import { Body } from 'components/Typography';
import { Button } from 'components/Button';
import { useDemo } from 'Auth';
import { DemoGuard } from 'components/Demo';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { usePlatform } from 'context/PlatformState';
import { useUpsertStationNote } from '../hooks';
import UserNotes from 'components/UserNotes/UserNotes';
import { useUserSettings } from 'hooks';

interface OsceKnowledgeLibraryReadingProps {
  stations?: Nullable<IOsceStation[]>;
  loading: boolean;
  locked?: boolean;
  station?: IOsceStation;
  activeCategoryLabel: string;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
}

const OsceKnowledgeLibraryReading = ({
  breadcrumbs,
  stations,
  loading,
  locked,
  station,
}: OsceKnowledgeLibraryReadingProps): JSX.Element => {
  const { expandedReading } = useUserSettings();
  const { state } = useLocation<{ fromMyNotes: boolean } | null>();
  const isDemo = useDemo();
  const { chapterId: stationId } = useParams<KnowledgeLibraryRouterParams>();
  const [expandedBriefs, setExpandedBriefs] = useState<Set<string>>(new Set());
  const [currentStation, setCurrentStation] = useState(station);
  const { updateStatus } = useUpdateStationLearningStatus();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;
  const upsertNote = useUpsertStationNote();
  const notesRef = useRef<HTMLDivElement>(null);

  const handleSaveNotes = (value: string) => {
    upsertNote(value, Number(stationId));
  };

  useEffect(() => {
    if (!station) {
      const selectedStation = stations?.find(
        station => Number(station.id) === Number(stationId)
      );

      setCurrentStation(selectedStation);
    } else {
      setCurrentStation(station);
    }
  }, [station, stationId, stations]);

  const { status, hiddenName, name, userNote } = (currentStation ||
    {}) as WithDemo<IOsceStation>;

  const [briefs, briefTitles, pictures] = useMemo(
    () => parseOsceReading(currentStation, isPaces),
    [currentStation, isPaces]
  );

  const allExpanded = briefTitles.length === expandedBriefs.size;

  const handleExpandAll = useCallback(() => {
    setExpandedBriefs(new Set([...briefTitles, ComplementaryReadings.MyNotes]));
  }, [briefTitles]);

  const handleCollapseAll = () => setExpandedBriefs(new Set());

  const handleExpandBrief = (title: string) => () => {
    setExpandedBriefs(prev => {
      if (prev.has(title)) {
        prev.delete(title);

        return new Set(prev);
      }

      return new Set(prev.add(title));
    });
  };

  useEffect(() => {
    if (!loading && briefs.length) {
      if (expandedReading) {
        handleExpandAll();

        return;
      }

      const { title } = briefs[0];
      setExpandedBriefs(new Set([title]));
    }
  }, [briefs, handleExpandAll, loading, expandedReading]);

  useEffect(() => {
    if (state?.fromMyNotes && notesRef.current) {
      if (!expandedBriefs.has(ComplementaryReadings.MyNotes)) {
        handleExpandBrief(ComplementaryReadings.MyNotes)();
      }
      setTimeout(() => {
        notesRef?.current?.scrollIntoView();
      }, 1000); // Wait for expandable sections to fully expand (animation)
    }
  }, [expandedBriefs, state?.fromMyNotes]);

  const [showStationsPrebuildModal, setShowStationsPrebuildModal] =
    useState(false);

  const handleShowStationsPrebuildModal = () => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      setShowStationsPrebuildModal(true);
    }
  };

  const handleCloseStationsPrebuildModal = () =>
    setShowStationsPrebuildModal(false);

  const handleStatusChange = async (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => updateStatus(ids, newStatus);

  const title = isPaces
    ? getPacesLibraryName({
        hiddenName,
        shownName: name,
        name,
        userViewed: status === EUserLearningStatus.COMPLETED,
      })
    : name;

  return (
    <>
      <ReadingContentContainer>
        {loading ? (
          <SkeletonList height={128} spacing={4} />
        ) : (
          <>
            {locked ? (
              <DemoGuard contentName="Station" />
            ) : (
              <>
                <ContentHeader
                  breadcrumbs={breadcrumbs}
                  buttonIcon={
                    allExpanded ? CollapseVerticalIcon : ExpandVerticalIcon
                  }
                  buttonText={allExpanded ? 'Hide All' : 'Show All'}
                  learningStatus={
                    <StatusMenu
                      id={Number(stationId || station?.id)}
                      onStatusChange={handleStatusChange}
                      status={status}
                    />
                  }
                  onButtonClick={
                    allExpanded ? handleCollapseAll : handleExpandAll
                  }
                  scallable
                  title={title}
                />
                {briefs.length
                  ? briefs.map(({ title, content, pictures, marks }) => (
                      <ExpandableSection
                        expanded={expandedBriefs.has(title)}
                        key={title}
                        onToggle={handleExpandBrief(title)}
                        title={<ReadingBriefTitle title={title} />}
                      >
                        {marks ? (
                          <MarschemeReadingSection marks={marks} />
                        ) : (
                          <Markdown text={parseFullText(content, pictures)} />
                        )}
                      </ExpandableSection>
                    ))
                  : null}
                <ExpandableSection
                  expanded={expandedBriefs.has(ComplementaryReadings.MyNotes)}
                  onToggle={handleExpandBrief(ComplementaryReadings.MyNotes)}
                  ref={notesRef}
                  size="base"
                  title={
                    <ReadingBriefTitle title={ComplementaryReadings.MyNotes} />
                  }
                >
                  <UserNotes
                    learningMaterials
                    notes={userNote}
                    onSaveNotes={handleSaveNotes}
                  />
                </ExpandableSection>

                <ExerciseInvitationContainer>
                  <Body bold>
                    Ready to practice? Start the station below to practice
                    yourself!
                  </Body>
                  <Buttons>
                    <Button onClick={handleShowStationsPrebuildModal} secondary>
                      <StationIcon />
                      Stations
                    </Button>
                  </Buttons>
                </ExerciseInvitationContainer>
              </>
            )}
          </>
        )}
      </ReadingContentContainer>
      <Lightbox pictures={pictures} />
      {showStationsPrebuildModal ? (
        <StationsTimerModal
          onClose={handleCloseStationsPrebuildModal}
          open={showStationsPrebuildModal}
          solo
          stationId={Number(stationId || station?.id)}
        />
      ) : null}
    </>
  );
};

export default OsceKnowledgeLibraryReading;
