import { useMutation } from '@apollo/client';
import { EProductType } from '@quesmed/types-rn/models';
import {
  CREATE_PRESET,
  ICreatePresetData,
  ICreatePresetVar,
  updateCacheOnCreatePreset,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';

const useCreatePresetMutation = (
  name: string,
  entitlementId: EProductType,
  conceptIds: number[]
) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createPreset] = useMutation<ICreatePresetData, ICreatePresetVar>(
    CREATE_PRESET,
    {
      variables: {
        data: {
          name,
          entitlementId,
          conceptIds,
        },
      },
      onCompleted: () => {
        enqueueSnackbar('Preset successfully saved!');
      },
      update: updateCacheOnCreatePreset,
    }
  );

  return { createPreset };
};
export default useCreatePresetMutation;
