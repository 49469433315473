import findClosestIndex from './findClosestIndex';

// TODO: Change color of the x axis for dark theme

const getOptions = (
  series: [number, number][],
  median: number,
  isMobile: boolean
) => {
  return {
    maintainAspectRatio: false,
    layout: {
      padding: isMobile ? 10 : 20,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        offset: true,
        ticks: {
          callback: (index: number | string): string => {
            if (index === 0) return '0';
            if (index === findClosestIndex(series, median)) return '';
            if (index === series.length - 1) return '100';

            return undefined as unknown as string;
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
      },
      y: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
      },
    },
  };
};

export default getOptions;
