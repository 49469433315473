import { CheckboxState, GameType, SelectionState, TopicMap } from 'types';

export const calcAllQuestions = (
  selection: SelectionState,
  topicMap: TopicMap,
  gameType = GameType.QUESTIONS
): [number, number] => {
  let questionCount = 0;
  let conceptsCount = 0;

  [...selection.values()].forEach(({ id, topicState, selectedConceptsIds }) => {
    if (topicState !== CheckboxState.UNCHECKED) {
      const { concepts } = topicMap[id];
      conceptsCount += selectedConceptsIds.size;

      [...selectedConceptsIds].forEach(id => {
        const { totalCards, totalQuestions } = concepts?.[id] || {};
        if (gameType === GameType.QUESTIONS && totalQuestions) {
          questionCount += totalQuestions ?? 0;
        }
        if (gameType === GameType.FLASHCARDS && totalCards) {
          questionCount += totalCards;
        }
      });
    }
  });

  return [questionCount, conceptsCount];
};
