import { useMutation } from '@apollo/client';
import { IMarksheet } from '@quesmed/types-rn/models';
import {
  END_MOCK_TEST,
  IEndMockTestData,
  IEndMockTestVar,
  optimisticEndMockTest,
  updateCacheOnEndMockTest,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { paths } from 'Router';
import { Nullable } from 'types';

const { mockTests } = paths;

const useEndMockTest = (
  marksheet: IMarksheet,
  mockTestId: Nullable<string>
) => {
  const { id } = marksheet;
  const navigate = useNavigate();

  const [end, { loading }] = useMutation<IEndMockTestData, IEndMockTestVar>(
    END_MOCK_TEST,
    {
      variables: {
        marksheetId: Number(id),
      },
      optimisticResponse: optimisticEndMockTest(marksheet),
      update: updateCacheOnEndMockTest,
    }
  );

  const endMockTest = useCallback(() => {
    end({
      onCompleted: () => {
        navigate(`${mockTests.root}/test/summary/${mockTestId}/${id}`);
      },
    });
  }, [end, navigate, id, mockTestId]);

  return { endMockTest, loading };
};

export default useEndMockTest;
