import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { localStorageTyped } from 'utils';

interface TimerParams {
  start?: number | string | Date;
  duration?: number;
}

const { setItem, getItem, removeItem } =
  localStorageTyped<Date>('timerStartDate');

export const useTimer = (params: TimerParams) => {
  const { duration = 10, start } = params;
  const intervalRef = useRef<number>();
  const [timeLeft, setTimeLeft] = useState<number>(() => {
    const startDate = dayjs(start);
    const storedStart = getItem();
    const end = dayjs(storedStart || startDate).unix() + duration;
    const diff = end - dayjs(dayjs()).unix();

    if (!storedStart) {
      setItem(startDate.toDate());
    }

    if (diff <= 0) {
      removeItem();
    }

    return diff;
  });

  const updateTime = useCallback(() => {
    setTimeLeft(prev => prev - 1);
  }, []);

  useEffect(() => {
    intervalRef.current = window.setInterval(updateTime, 1000);
  }, [updateTime]);

  useEffect(() => {
    if (!timeLeft && intervalRef.current) {
      clearInterval(intervalRef.current);
      removeItem();
    }
  }, [timeLeft]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return timeLeft;
};
