import React, { ReactNode, useCallback, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CheckboxState, Nullable, TableData } from 'types';
import { areSetsEqual } from 'utils';
import { useMemoizedGeneric } from 'hooks';
import { ClickHandler, SelectionColumn, SelectItemHandler } from './types';
import SelectionRow from './SelectionRow';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import SelectionColumnData from './SelectionColumnData';
import ModalConcept from 'pages/MLAContentMap/ModalConcept';

const SubTableBox = styled(Box)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.stroke.main}`,
  borderTop: `1px solid ${palette.stroke.main}`,
}));

const LeftSubTableBox = styled(Box)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.stroke.main}`,
  borderTop: `1px solid ${palette.stroke.main}`,
  borderRight: `1px solid ${palette.stroke.main}`,
  width: '50%',
}));
const RightSubTableBox = styled(Box)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.stroke.main}`,
  borderTop: `1px solid ${palette.stroke.main}`,
  width: '50%',
}));

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
}));

const Wrapper = styled(Box)({
  maxHeight: '700px',
  overflowY: 'auto',
});

export interface SelectionComplexRowProps<T extends TableData> {
  checked: boolean;
  checkedState?: CheckboxState;
  columns: SelectionColumn<T>[];
  data: T;
  globalLock?: boolean;
  onSelectRow?: () => void;
  onSelectCondition?: (subItemId: number) => void;
  onSelectSubRow?: (subItemId: number) => void;
  onSelectPresentation?: (subItemId: number) => void;
  nestedItemsKey?: keyof T;
  selectedSubitems?: Set<number>;
  selectedMLASubitems?: Set<number>[];
  checkedIcon?: ReactNode;
  icon?: ReactNode;
  withOffset?: boolean;
  selectOnRowClick?: boolean;
  mlaColumns?: boolean;
  mlaConditionColumns?: SelectionColumn<T>[];
  mlaPatientPresentationColumns?: SelectionColumn<T>[];
}

function SelectionComplexRow<T extends TableData>({
  checked,
  checkedIcon,
  checkedState,
  columns,
  globalLock,
  data,
  icon,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  nestedItemsKey,
  onSelectRow,
  onSelectCondition,
  onSelectPresentation,
  onSelectSubRow,
  selectedSubitems,
  selectedMLASubitems,
  selectOnRowClick,
  withOffset,
}: SelectionComplexRowProps<T>): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedId, setSelectedId] = useState<Nullable<number>>(null);
  const isDemo = useDemo();
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  const isNestedData = Boolean(nestedItemsKey && data[nestedItemsKey]);
  const isNestedMLA = Boolean(data['presentations'] || data['conditions']);
  const { demo: useInDemo = false } = data;
  const locked = isDemo && (!useInDemo || globalLock);

  const handleClick = useCallback(() => {
    if (isNestedData || isNestedMLA) {
      return setIsExpanded(previous => !previous);
    }

    if (locked) {
      enqueueSnackbar(locales.common.demo.select);

      return;
    }

    if (selectOnRowClick && onSelectRow) {
      onSelectRow();
    }
  }, [
    enqueueSnackbar,
    isNestedData,
    locked,
    onSelectRow,
    selectOnRowClick,
    isNestedMLA,
  ]);

  const handleSelectRow: SelectItemHandler = useCallback(
    event => {
      event.stopPropagation();
      if (onSelectRow) {
        onSelectRow();
      }
    },
    [onSelectRow]
  );

  const handleSelectSubRow = useCallback(
    (itemId: number): SelectItemHandler =>
      event => {
        event.preventDefault();
        event.stopPropagation();
        if (onSelectSubRow) {
          onSelectSubRow(itemId);
        }
      },
    [onSelectSubRow]
  );

  const handleSelectCondition = useCallback(
    (itemId: number): SelectItemHandler =>
      event => {
        event.preventDefault();
        event.stopPropagation();
        if (onSelectCondition) {
          onSelectCondition(itemId);
        }
      },
    [onSelectCondition]
  );

  const handleSelectPresentation = useCallback(
    (itemId: number): SelectItemHandler =>
      event => {
        event.preventDefault();
        event.stopPropagation();
        if (onSelectPresentation) {
          onSelectPresentation(itemId);
        }
      },
    [onSelectPresentation]
  );

  const handleOpenModal = useCallback(
    (itemId: number): ClickHandler =>
      event => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedId(itemId);
      },
    [setSelectedId]
  );

  const showMlaRows =
    (data['presentations'] && Array.isArray(data['presentations'])) ||
    (data['conditions'] && Array.isArray(data['conditions']));

  const showQuestionRows =
    nestedItemsKey &&
    data[nestedItemsKey] &&
    Array.isArray(data[nestedItemsKey]);

  return (
    <>
      {selectedId && (
        <ModalConcept
          conceptId={selectedId}
          onClose={() => setSelectedId(null)}
          open={selectedId !== null}
        />
      )}
      <SelectionRow
        checked={checked}
        checkedIcon={checkedIcon}
        checkedState={checkedState}
        columns={columns}
        data={data}
        globalLock={globalLock}
        hover={!locked}
        icon={icon}
        isParent
        locked={locked}
        onClick={handleClick}
        onSelect={handleSelectRow}
        withOffset={withOffset}
      />
      <Collapse in={isExpanded} unmountOnExit>
        {showMlaRows || showQuestionRows ? (
          <Wrapper>
            {mlaColumns &&
            mlaPatientPresentationColumns &&
            mlaConditionColumns ? (
              <FlexWrapper>
                <LeftSubTableBox>
                  <SelectionColumnData
                    columnsData={mlaPatientPresentationColumns}
                    columnsHeader={columns}
                    data={data}
                    globalLock={globalLock}
                    locked={locked}
                    nestedItemsKey="presentations"
                    onSelectSubRow={handleSelectPresentation}
                    selectedSubitems={
                      selectedMLASubitems && selectedMLASubitems[0]
                    }
                    withOffset={withOffset}
                  />
                </LeftSubTableBox>
                <RightSubTableBox>
                  <SelectionColumnData
                    columnsData={mlaConditionColumns}
                    columnsHeader={columns}
                    data={data}
                    globalLock={globalLock}
                    handleOpenModal={handleOpenModal}
                    locked={locked}
                    nestedItemsKey="conditions"
                    onSelectSubRow={handleSelectCondition}
                    selectedSubitems={
                      selectedMLASubitems && selectedMLASubitems[1]
                    }
                    withOffset={withOffset}
                  />
                </RightSubTableBox>
              </FlexWrapper>
            ) : (
              <SubTableBox>
                <SelectionColumnData
                  columnsData={columns}
                  columnsHeader={columns}
                  data={data}
                  globalLock={globalLock}
                  locked={locked}
                  nestedItemsKey={'concepts'}
                  onSelectSubRow={handleSelectSubRow}
                  selectedSubitems={selectedSubitems}
                  withOffset={withOffset}
                />
              </SubTableBox>
            )}
          </Wrapper>
        ) : null}
      </Collapse>
    </>
  );
}

function equalItemsCount<T extends TableData>(
  prevProps: SelectionComplexRowProps<T>,
  nextProps: SelectionComplexRowProps<T>
) {
  const prevPropsNestedItemsKey = prevProps.data.nestedItemsKey;
  const nextPropsNestedItemsKey = nextProps.data.nestedItemsKey;
  const prevPropsData = prevProps.data[prevPropsNestedItemsKey];
  const nextPropsData = nextProps.data[nextPropsNestedItemsKey];

  return (
    prevPropsNestedItemsKey &&
    nextPropsNestedItemsKey &&
    prevPropsData &&
    nextPropsData &&
    prevPropsData.length === nextPropsData.length
  );
}

function rowPropsEqual<T extends TableData>(
  prevProps: SelectionComplexRowProps<T>,
  nextProps: SelectionComplexRowProps<T>
) {
  return (
    prevProps.checked === nextProps.checked &&
    prevProps.checkedState === nextProps.checkedState &&
    equalItemsCount(prevProps, nextProps) &&
    areSetsEqual(prevProps.selectedSubitems, nextProps.selectedSubitems)
  );
}

// "SelectionComplexRow" component is a generic component to make it work in different
// type of exercises with different type of data. Because memo doesn't return generic
// copmponent, to memoize it and keep advantage of generic types, we use custom
// hook "src/hooks/useMemoizedGeneric.ts".
export function useMemoizedGenericSelectionComplexRow<T extends TableData>() {
  const SelectionRowComponent = useMemoizedGeneric<SelectionComplexRowProps<T>>(
    SelectionComplexRow,
    rowPropsEqual
  );

  return SelectionRowComponent;
}

export default SelectionComplexRow;
