import React from 'react';
import { mdiArrowRightThin } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowRightThinIcon = (props: IconProps) => (
  <Icon path={mdiArrowRightThin} {...props} />
);

export default ArrowRightThinIcon;
