import { RefObject } from 'react';

export const scrollToBottom = <T extends HTMLElement>(
  parentRef: RefObject<T>
) => {
  const parentElement = parentRef.current;
  if (parentElement) {
    parentElement.scrollTo({
      behavior: 'smooth',
      top: parentElement.scrollHeight,
    });
  }
};
