import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  EPaceMarkType,
  IOsceMarksheetMark,
  IPaceScore,
} from '@quesmed/types-rn/models';

import { ExpandableSection } from 'components/ExpandableSection';
import { LABELS } from 'types';
import { RadioGroup, RadioOption } from 'components/RadioGroup';
import { ReadingBriefTitle } from 'components/LearningMaterials';
import { Markdown } from 'components/Markdown';

const ExpandableSecionWrapper = styled(Box)(({ theme: { palette } }) => ({
  '& .expandable-section': {
    border: 0,
    borderRadius: '9px 9px 0 0',
  },

  '& .expandable-section__header': {
    borderRadius: '9px 9px 0 0',
    border: `1px solid ${palette.stroke.main}`,
  },

  '& .content.MuiBox-root': {
    border: 0,
    padding: 0,
  },
}));

const RadioBox = styled(Box)(
  ({ theme: { breakpoints, palette, spacing, transitions } }) => ({
    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'column',
    },

    '& .MuiFormControlLabel-root': {
      margin: 0,
      marginTop: '-9px',
      display: 'flex',
      justifyContent: 'space-between',
    },

    '& .osce-mark-choice': {
      backgroundColor: palette.background.paper,
      border: `1px solid ${palette.stroke.main}`,
      borderTop: 0,
      flex: '1 1 0',
      padding: spacing(4, 6),
      cursor: 'pointer',
      transition: transitions.create(['border, background-color']),

      '&:has(.Mui-checked)': {
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.primary.main}`,
        marginTop: '-1px',
      },

      '&.disabled': {
        border: `1px solid ${palette.stroke.main}`,
        borderTop: 0,
        cursor: 'default',
        marginTop: 0,

        '& .MuiFormControlLabel-root .MuiSvgIcon-root': {
          color: palette.icon.light,
        },
      },
    },

    [breakpoints.up('md')]: {
      '& .osce-mark-choice': {
        '&:first-of-type, &.last-of-type': {
          flex: '1 0 unset',
        },

        '&:nth-of-type(2)': {
          flex: '0 0 fit-content',
        },

        '&.disabled': {
          '&:not(:first-of-type)': {
            borderLeft: 0,
          },
        },
      },

      '& .MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
      },
    },

    [breakpoints.up('xl')]: {
      '& .MuiFormGroup-root .osce-mark-choice': {
        padding: spacing(6, 8),
        flex: '1 1 0',
      },
    },
  })
);

const getMarkOptions = (choices: IPaceScore[]): RadioOption[] =>
  choices.map(({ type, name }) => ({
    label: LABELS[type],
    value: type,
    complementaryContent: name ? <Markdown text={name} /> : undefined,
  }));

interface PacesMarkschemeMarkProps {
  disabled?: boolean;
  osceMark: IOsceMarksheetMark;
  expanded?: boolean;
  onClick: (value: EPaceMarkType) => void;
  isCompleted: boolean;
}

export const PacesMarkschemeMark = ({
  disabled,
  onClick,
  osceMark,
  expanded,
  isCompleted,
}: PacesMarkschemeMarkProps): JSX.Element => {
  const { id, osceStationMark, selectedChoice } = osceMark;
  const {
    choices = [],
    header = '',
    id: stationMarkId,
  } = osceStationMark || {};

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onClick(Number(value));
  };

  return (
    <ExpandableSecionWrapper>
      <ExpandableSection
        expanded={expanded}
        key={id}
        size="small"
        title={<ReadingBriefTitle title={header} />}
        variant={isCompleted ? 'normal' : 'info'}
      >
        <RadioBox>
          <RadioGroup
            className={clsx('osce-mark-choice', { disabled })}
            id={`${stationMarkId}:${header}:choices`}
            labelPlacement="start"
            onChange={handleChange}
            options={getMarkOptions(choices)}
            readOnly={disabled}
            value={selectedChoice}
          />
        </RadioBox>
      </ExpandableSection>
    </ExpandableSecionWrapper>
  );
};
