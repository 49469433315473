import React, { useCallback, useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useSearchBox } from 'react-instantsearch';
import {
  EAppType,
  EProductType,
  IConcept,
  IEntitlement,
  ITopic,
} from '@quesmed/types-rn/models';
import { getEntitlementFromTopicType } from '@quesmed/types-rn';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialItem,
  QbankKnowledgeLibraryState,
  useQbankKnowledge,
} from 'components/LearningMaterials';
import { AppProductType, BuilderViewMode, Nullable, ToggleEvent } from 'types';
import { BUILDER_VIEWS } from 'constants/general';
import { usePrevious } from 'hooks/usePrevious';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { checkIfCategoryExist } from 'utils';
import { usePlatform } from 'context/PlatformState';
import { TopicHit, useSearchResults } from 'components/Search';
import { ErrorMessage } from 'components/Error/ErrorMessage';

export const useQbankKnowledgeLibraryData = () => {
  return useOutletContext<QbankKnowledgeLibraryState>();
};

const setSearchTopics = (
  hits: TopicHit[],
  topics: Nullable<ITopic[]>,
  product: Nullable<AppProductType>
): Nullable<LearningMaterialItem[]> => {
  if (!topics) {
    return null;
  }

  const conceptIds = new Map<number, IEntitlement>();
  const topicIds = new Set<number>();
  const conceptOrder: number[] = [];

  hits.forEach(({ topicId, conceptId, entitlement, typeId }) => {
    topicIds.add(Number(topicId));

    const validConceptId = Number(conceptId);

    conceptIds.set(
      validConceptId,
      product
        ? (getEntitlementFromTopicType(typeId, product) as IEntitlement)
        : entitlement
    );
    conceptOrder.push(validConceptId);
  });

  return topics
    .filter(({ id }) => topicIds.has(Number(id)))
    .flatMap(({ concepts = [] }) =>
      concepts.filter(({ id }) => conceptIds.has(Number(id)))
    )
    .map(({ id, ...rest }) => {
      const entitlement = conceptIds.get(Number(id));

      return {
        id,
        ...rest,
        entitlement,
      };
    })
    .sort((a, b) => {
      const { id: aId } = a as IConcept;
      const { id: bId } = b as IConcept;

      return (
        conceptOrder.indexOf(Number(aId)) - conceptOrder.indexOf(Number(bId))
      );
    });
};

const setSearchTopicsForInterview = (
  text: string,
  topics: Nullable<ITopic[]>
) => {
  const normalizedSearchString = text.toLowerCase().trim();

  if (topics && text.length > 2) {
    const mappedTopics = topics.map(({ concepts, name, ...rest }) => {
      const filteredConcepts = concepts?.filter(({ name }) =>
        name.toLowerCase().includes(normalizedSearchString)
      );

      return {
        ...rest,
        name: `${name} (${filteredConcepts?.length || 0})`,
        concepts: filteredConcepts,
      };
    });

    return mappedTopics.filter(({ concepts }) => Boolean(concepts?.length));
  }

  return topics;
};

const useSearchTopics = (
  topics: Nullable<ITopic[]>,
  product: Nullable<AppProductType>
) => {
  const results = useSearchResults<TopicHit[]>();

  return results ? setSearchTopics(results, topics, product) : null;
};

const QbankKnowledgeLibrary = (): JSX.Element => {
  const { loading, topicsMap, categories } = useQbankKnowledge();
  const { query, refine, clear } = useSearchBox();
  const navigate = useNavigate();
  const { sectionId: topicId, entitlementId } =
    useParams<KnowledgeLibraryRouterParams>();
  const [activeCategory, setActiveCategory] = useState(
    entitlementId ? Number(entitlementId) : categories[0]?.value
  );
  const [activeView, setActiveView] = useState(BUILDER_VIEWS[0].value);
  const previousCategory = usePrevious(activeCategory);
  const previousTopicId = usePrevious(topicId);
  const { app, product } = usePlatform();
  const [displayTopics, setDisplayTopics] = useState<Nullable<ITopic[]>>(null);
  const searchItems = useSearchTopics(displayTopics, product);
  const [searchTerm, setSearchTerm] = useState(query);

  const categoryExists = checkIfCategoryExist(categories, activeCategory);

  const resetSearch =
    (Boolean(displayTopics) && previousCategory !== activeCategory) ||
    previousTopicId !== topicId;

  useEffect(() => {
    if (app === EAppType.INTERVIEW) {
      setSearchTopicsForInterview(searchTerm, displayTopics);
    }
  }, [app, searchTerm, displayTopics]);

  useEffect(() => {
    if (resetSearch || !displayTopics) {
      clear();
      setDisplayTopics(topicsMap ? topicsMap[activeCategory] : null);
    }
  }, [resetSearch, topicsMap, activeCategory, displayTopics, clear]);

  useEffect(() => {
    if (activeCategory) {
      setDisplayTopics(topicsMap ? topicsMap[activeCategory] : null);
    }
  }, [activeCategory, topicsMap]);

  useEffect(() => {
    if (categories && (!activeCategory || !categoryExists)) {
      if (entitlementId) {
        const validEntitlementId = Number(entitlementId);

        if (checkIfCategoryExist(categories, validEntitlementId)) {
          setActiveCategory(validEntitlementId);
        }
      } else {
        setActiveCategory(Number(categories[0]?.value));
      }
    }
    if (
      activeCategory &&
      Number(entitlementId) &&
      activeCategory !== Number(entitlementId)
    ) {
      setActiveCategory(Number(entitlementId));
    }
  }, [categories, activeCategory, categoryExists, entitlementId]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setSearchTerm(searchString);
      refine(searchString);
    },
    [refine]
  );

  const handleActiveView = (e: ToggleEvent, view: BuilderViewMode) =>
    setActiveView(view);

  const handleActiveCategory = useCallback(
    (_: ToggleEvent, type: EProductType) => {
      setActiveCategory(Number(type));
      navigate(paths.knowledgeLibrary.root);
    },
    [navigate]
  );

  const activeCategoryLabel =
    categories.find(({ value }) => value === activeCategory)?.label || '';

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!categoryExists) {
    return <ErrorMessage />;
  }

  return (
    <Outlet
      context={{
        activeCategory,
        activeView,
        activeCategoryLabel,
        searchItems,
        categories,
        topics: displayTopics,
        loading,
        resetSearch,
        search: handleSearch,
        searchBy: 'concepts',
        switchCategory: handleActiveCategory,
        switchView: handleActiveView,
        searchTerm,
      }}
    />
  );
};

export default QbankKnowledgeLibrary;
