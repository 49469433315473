import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

import PresetsBuilder from './PresetsBuilder';
import {
  PresetsTopicType,
  PresetTabLabel,
  TopicPresetsBuilderProps,
} from './types';
import { isNear } from 'utils/scroll/handleScroll';
import { usePrevious } from 'hooks';
import ReceivePresetLink from './ReceivePresetLink';
import { useDemo } from 'Auth';
import { usePreDefinedPresetsQuery, usePresetsQuery } from './hooks';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';

const TopicPresetsBuilder = ({
  activeCategory,
  categoryOptions,
  onToggleCategory,
  openLinkModal,
  setOpenLinkModal,
}: TopicPresetsBuilderProps<number>) => {
  const tableRef = useRef<HTMLTableElement>();
  const isDemo = useDemo();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const [limitReached, setLimitReached] = useState(false);

  const isTabPreDefined = activeCategory === PresetsTopicType.PRE_DEFINED;

  const {
    preDefinedPresets,
    loading: preDefinedLoading,
    searchPresets: searchPreDefinedPresets,
    searchedPresets: searchedPreDefinedPresets,
  } = usePreDefinedPresetsQuery();

  const { searchPresets, getMorePresets, presets, searchedPresets, loading } =
    usePresetsQuery({
      onAllPresetsFetched: () => setLimitReached(true),
    });

  const [searchTerm, setSearchTerm] = useState<string>('');
  const prevPresetCategory = usePrevious(activeCategory);
  const [category, loadingPresets] = isTabPreDefined
    ? [PresetTabLabel.PRE_DEFINED, preDefinedLoading]
    : [PresetTabLabel.CUSTOM, loading];

  const { state } = useLocation<{ productType: number; presetId: number }>();
  const { productType, presetId } = state || {};

  const queryDebounce = debounce(
    (search?: string) =>
      isTabPreDefined ? searchPreDefinedPresets(search) : searchPresets(search),
    300
  );

  const handleSearchChange = (search: string) => {
    if (isDemo && !isTabPreDefined) {
      enqueueSnackbar(locales.common.demo.feature);

      return;
    }
    if (search !== '') {
      queryDebounce(search);
    }
    setSearchTerm(search);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollDebounce = useCallback(
    debounce(() => {
      const { updatedAt } = presets[presets.length - 1];
      if (isNear(tableRef) && searchTerm === '') {
        getMorePresets(updatedAt as number);
      }
    }, 100),
    [presets, limitReached]
  );

  useEffect(() => {
    const ref = tableRef.current;
    if (!isTabPreDefined) {
      if (!limitReached) {
        ref?.addEventListener('scroll', handleScrollDebounce, true);
      } else {
        ref?.removeEventListener('scroll', handleScrollDebounce, true);
      }
    }

    return () => {
      ref?.removeEventListener('scroll', handleScrollDebounce, true);
    };
  }, [handleScrollDebounce, isTabPreDefined, limitReached]);

  useEffect(() => {
    if (prevPresetCategory !== activeCategory) {
      setSearchTerm('');
    }
  }, [prevPresetCategory, activeCategory]);

  const getDisplayData = () => {
    if (isTabPreDefined) {
      return searchTerm !== '' ? searchedPreDefinedPresets : preDefinedPresets;
    }

    return searchTerm !== '' ? searchedPresets : presets;
  };

  return (
    <>
      <PresetsBuilder
        activeCategory={activeCategory}
        category={category}
        categoryOptions={categoryOptions}
        data={getDisplayData()}
        loading={loadingPresets}
        onSearch={handleSearchChange}
        onToggleCategory={onToggleCategory}
        prevPresetCategory={prevPresetCategory}
        tableRef={tableRef}
      />

      {openLinkModal ? (
        <ReceivePresetLink
          open={openLinkModal}
          presetId={presetId}
          productType={productType}
          setOpen={setOpenLinkModal}
        />
      ) : null}
    </>
  );
};

export default TopicPresetsBuilder;
