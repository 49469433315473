import React from 'react';
import { IQuestion } from '@quesmed/types-rn/models';

import { QuestionAdditionalContent } from 'components/Layout';
import { BrainIcon } from 'components/Icons';
import { Comments } from 'components/Comments';
import { Separator } from 'components/Separator';
import { ExpandableSection } from 'components/ExpandableSection';
import MockTestsExplanation from './MockTestsExplanation';
import { QuestionLike } from 'components/QuestionLike';
import { useQuestionLikeMutation } from 'components/Likes/useQuestionLikeMutation';
import { RelatedContentContainer } from './MockTestLayout/MockTestsLayoutStyles';

interface MockTestQuestionRelatedContentProps {
  question: IQuestion;
  marksheetId: number;
}

export const MockTestQuestionRelatedContent = ({
  question,
  marksheetId,
}: MockTestQuestionRelatedContentProps): JSX.Element => {
  const { pictures = [], explanation, isLikedByMe } = question;
  const { handleLikeQuestion, likes, dislikes } =
    useQuestionLikeMutation(question);

  return (
    <RelatedContentContainer>
      <QuestionLike
        dislikes={dislikes}
        handleLike={handleLikeQuestion}
        isLikedByMe={isLikedByMe}
        likes={likes}
      />
      <Separator title="Learn more ↓" />
      <QuestionAdditionalContent>
        {explanation && (
          <ExpandableSection expanded icon={BrainIcon} title="Explanation">
            <MockTestsExplanation
              explanation={explanation}
              pictures={pictures}
            />
          </ExpandableSection>
        )}
        <Comments marksheetId={Number(marksheetId)} question={question} />
      </QuestionAdditionalContent>
    </RelatedContentContainer>
  );
};
