import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import {
  IPayload,
  IQuestion,
  IQuestionComment,
} from '@quesmed/types-rn/models';
import {
  IQuestionCommentRemoveData,
  IQuestionCommentRemoveVar,
  IQuestionCommentsData,
  IQuestionCommentsVar,
  optimisticQuestionComment,
  optimisticQuestionCommentRemove,
  QUESTION_COMMENT_REMOVE,
  QUESTION_COMMENTS,
  updateQuestionComments,
  updateQuestionCommentsRemove,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { randomId, sortByCreatedAt } from 'utils';
import { useAuth } from 'Auth';

const useComment = (question: IQuestion, marksheetId?: number) => {
  const { comments: sourceComments = [], id: questionId, typeId } = question;

  const comments = sortByCreatedAt<IQuestionComment>(sourceComments);
  const { tokenDecoded } = useAuth();
  const [currentCommentId, setCurrentCommentId] = useState<number | null>(null);

  const loadingHelper = useCallback(
    (loading: boolean, commentId?: number) =>
      Number(currentCommentId) === Number(commentId) && loading,
    [currentCommentId]
  );

  const [questionComments, { loading: questionCommentsLoading }] = useMutation<
    IQuestionCommentsData,
    IQuestionCommentsVar
  >(QUESTION_COMMENTS, {
    onCompleted: () => {
      setCurrentCommentId(null);
    },
    update: updateQuestionComments(typeId),
  });

  const [questionCommentRemove, { loading: questionCommentRemoveLoading }] =
    useMutation<IQuestionCommentRemoveData, IQuestionCommentRemoveVar>(
      QUESTION_COMMENT_REMOVE,
      {
        onCompleted: () => {
          setCurrentCommentId(null);
        },
        update: updateQuestionCommentsRemove(typeId),
      }
    );

  const checkIsReplyLoading = (commentId?: number) =>
    loadingHelper(questionCommentsLoading, commentId);

  const checkIsRemoveCommentLoading = (commentId?: number) =>
    loadingHelper(questionCommentRemoveLoading, commentId);

  const removeComment = useCallback(
    (commentId: number) => {
      if (questionCommentRemoveLoading || questionCommentsLoading) {
        return;
      }
      setCurrentCommentId(commentId);

      const variables = {
        commentId: Number(commentId),
        questionId: Number(questionId),
        marksheetId,
      };

      questionCommentRemove({
        variables,
        optimisticResponse: optimisticQuestionCommentRemove(
          comments,
          variables
        ),
      });
    },
    [
      questionCommentRemoveLoading,
      questionCommentsLoading,
      questionId,
      marksheetId,
      questionCommentRemove,
      comments,
    ]
  );

  const removeReply = useCallback(
    (parentId: number) => (commentId: number) => {
      if (questionCommentRemoveLoading || questionCommentsLoading) {
        return;
      }
      setCurrentCommentId(commentId);

      const variables = {
        commentId: Number(commentId),
        questionId: Number(questionId),
        marksheetId,
      };

      questionCommentRemove({
        variables,
        optimisticResponse: optimisticQuestionCommentRemove(
          comments,
          variables,
          parentId
        ),
      });
    },
    [
      questionCommentRemoveLoading,
      questionCommentsLoading,
      questionId,
      marksheetId,
      questionCommentRemove,
      comments,
    ]
  );

  const addComment = useCallback(
    async (comment: string) => {
      const variables = {
        questionId: Number(questionId),
        marksheetId,
        comment,
      };

      return await questionComments({
        variables,
        optimisticResponse: optimisticQuestionComment(
          randomId(8),
          tokenDecoded as IPayload,
          variables
        ),
      });
    },
    [questionId, marksheetId, questionComments, tokenDecoded]
  );

  const addReply = useCallback(
    async (parentId: number, comment: string) => {
      setCurrentCommentId(parentId);

      const variables = {
        questionId: Number(questionId),
        marksheetId,
        parentId: Number(parentId),
        comment,
      };

      return await questionComments({
        variables,
        optimisticResponse: optimisticQuestionComment(
          randomId(8),
          tokenDecoded as IPayload,
          variables
        ),
      });
    },
    [marksheetId, questionComments, questionId, tokenDecoded]
  );

  return {
    comments,
    addComment,
    addReply,
    removeComment,
    removeReply,
    questionCommentsLoading,
    questionCommentRemoveLoading,
    checkIsReplyLoading,
    checkIsRemoveCommentLoading,
    commentLoading: !currentCommentId && questionCommentsLoading,
  };
};

export default useComment;
