import React, { lazy } from 'react';

import { useCurrentUser } from 'Auth';
import { ProvisionedApp } from './ProvisionedApp';

const OnboardingAppLazy = lazy(() =>
  import('./OnboardingApp').then(module => ({
    default: module.OnboardingApp,
  }))
);

export const AuthenticatedApp = (): JSX.Element => {
  const { firstName, lastName } = useCurrentUser();

  return firstName && lastName ? <ProvisionedApp /> : <OnboardingAppLazy />;
};
