import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import clsx from 'clsx';

import { QuestionAnswerStatus } from 'types';
import { RadioProps, StyledRadio } from 'components/RadioGroup';
import {
  CheckIcon,
  CloseIcon,
  RadioBoxBlankIcon,
  RadioBoxMarkedIcon,
} from 'components/Icons';

const MatchingButton = styled(Box)(({ theme: { palette, spacing } }) => ({
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: spacing(2),

  '& .MuiFormControlLabel-root': {
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: spacing(1),
  },

  '&.readOnly': {
    border: `1px solid ${palette.stroke.main}`,

    '& .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': {
      color: palette.text.primary,
    },
  },

  '&.valid': {
    border: `1px solid ${palette.success.dark}`,

    '.MuiSvgIcon-root': {
      color: palette.success.dark,
    },
  },

  '&.invalid': {
    border: `1px solid ${palette.error.main}`,

    '.MuiSvgIcon-root': {
      color: palette.error.main,
    },
  },

  '&.not-answered': {
    '.MuiSvgIcon-root': {
      color: palette.icon.main,
    },
  },
}));

const setChoiceIcon = (status?: QuestionAnswerStatus) => {
  switch (status) {
    case QuestionAnswerStatus.VALID:
    case QuestionAnswerStatus.NOT_ANSWERED:
      return <CheckIcon />;
    case QuestionAnswerStatus.INVALID:
      return <CloseIcon />;
    default:
      return undefined;
  }
};

export const MatchingQuestionChoice = ({
  label,
  value,
  status,
  labelPlacement,
  readOnly,
  checked,
  ...rest
}: RadioProps): JSX.Element => (
  <MatchingButton
    className={clsx({
      valid: status === QuestionAnswerStatus.VALID,
      invalid: status === QuestionAnswerStatus.INVALID,
      'not-answered': status === QuestionAnswerStatus.NOT_ANSWERED,
      readOnly,
    })}
  >
    <FormControlLabel
      control={
        <StyledRadio
          checked={checked}
          checkedIcon={setChoiceIcon(status) || <RadioBoxMarkedIcon />}
          disabled={readOnly}
          icon={setChoiceIcon(status) || <RadioBoxBlankIcon />}
          {...rest}
        />
      }
      label={label}
      labelPlacement="start"
      value={value}
    />
  </MatchingButton>
);
