import {
  ApolloCache,
  DocumentNode,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import {
  IModifyMarksheetMarkData,
  IModifyMarksheetMarkVar,
  IModifyMarksheetMarkVarInput,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  ApolloUpdateOptions,
  ApolloUpdateResultRestricted,
} from '@quesmed/types-rn';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { isNil } from 'lodash';

export interface ChangeQuestionArgs {
  newMarkId?: number;
  timeTaken?: number;
  markId: number;
}

export function useModifyMarksheetMarkMutation(
  query: DocumentNode,
  fragment: DocumentNode,
  baseOptions?: MutationHookOptions<
    IModifyMarksheetMarkData,
    IModifyMarksheetMarkVar
  >
) {
  const options = { ...baseOptions };

  return useMutation<IModifyMarksheetMarkData, IModifyMarksheetMarkVar>(query, {
    ...options,
    update: (
      cache: ApolloCache<any>,
      result: ApolloUpdateResultRestricted<IModifyMarksheetMarkData>,
      options: ApolloUpdateOptions
    ) => {
      const { modifyMarksheetMark } = result?.data?.restricted || {};
      const { variables } = options || {};

      if (!variables || !modifyMarksheetMark) {
        return;
      }

      const { input } = variables;
      const { marksheetId, action, timeTaken, markId, newMarkId } =
        input as IModifyMarksheetMarkVarInput;

      const { id, ...data } = modifyMarksheetMark || {};
      cache.writeFragment({
        id: cache.identify({ id, __typename: 'MarksheetMark' }),
        data,
        fragment,
      });

      if (action === EMarksheetAction.QUESTION_CHANGE) {
        const prevData = cache.readQuery<IMarksheetData, IMarksheetVar>({
          variables: { id: marksheetId },
          query: MARKSHEET,
        });
        if (prevData) {
          const { marks } = prevData.restricted.marksheet;
          const index = marks.findIndex(
            ({ id }) => Number(id) === Number(markId)
          );
          if (index >= 0) {
            const { id: prevMarkId, timeTaken: prevTimeTaken } = marks[index];

            cache.writeFragment({
              id: cache.identify({
                id: prevMarkId,
                __typename: 'MarksheetMark',
              }),
              data: {
                timeTaken: isNil(timeTaken) ? prevTimeTaken : timeTaken,
                index,
              },
              fragment,
            });
          }

          cache.writeQuery({
            query: MARKSHEET,
            variables: { id: marksheetId },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                marksheet: {
                  ...prevData.restricted.marksheet,
                  currentMarkId: newMarkId,
                },
              },
            },
          });
        }
      }
    },
  });
}

export default useModifyMarksheetMarkMutation;
