import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  IVideoData,
  IVideoVar,
  VIDEO,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import Skeleton from '@mui/material/Skeleton';
import {
  EProductType,
  EUserLearningStatus,
  IVideo,
} from '@quesmed/types-rn/models';
import { useHotkeys } from 'react-hotkeys-hook';

import { CONTENT_MAX_WIDTH } from 'config/constants';
import OsceKnowledgeLibraryReading from 'pages/KnowledgeLibrary/osce/OsceKnowledgeLibraryReading';
import {
  KnowledgeLibraryRouterParams,
  LearningMaterialsData,
  VideoTopic,
} from '../types';
import StatusMenu from './StatusMenu';
import VideoDetailsBookItem, { ItemContainer } from './VideoDetailsBookItem';
import { SkeletonList } from 'components/Skeleton';
import { ContentHeader } from 'components/ContentHeader';
import {
  BookshelfIcon,
  FolderOpenOutlineIcon,
  KnowledgeLibraryIcon,
} from 'components/Icons';
import { ExpandableSection } from 'components/ExpandableSection';
import { paths } from 'Router';
import { KeyboardKey, WithDemo } from 'types';
import { useUpdateVideoLearningStatus } from '../hooks';
import { useDemo } from 'Auth';
import { DemoGuard } from 'components/Demo';
import { VideoContainer } from './VideoContainer';
import { usePlatform } from 'context/PlatformState';
import { getPacesLibraryName } from '../utils';
import { Button } from 'components/Button';

const { videoLibrary } = paths;

const ContentContainer = styled(Box)({
  maxWidth: `${CONTENT_MAX_WIDTH}px`,
  margin: '0 auto',
});

const SkeletonContainer = styled(Box)(({ theme: { mixins, spacing } }) => ({
  display: 'flex',
  maxWidth: mixins.content.maxWidth,
  margin: '0 auto',
  gap: spacing(6),
  flexDirection: 'column',
}));

const ReadMoreButtonContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: spacing(8),
}));

interface VideoDetailsProps {
  activeCategoryLabel: string;
  items: LearningMaterialsData[];
  enableArrowNavigation?: boolean;
}

const VideoDetails = ({
  activeCategoryLabel,
  items,
  enableArrowNavigation = false,
}: VideoDetailsProps) => {
  const [isRelatedVideosExpanded, setIsRelatedVideosExpanded] = useState(false);
  const isDemo = useDemo();
  const { sectionId, videoId, entitlementId } =
    useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const { updateStatus } = useUpdateVideoLearningStatus();
  const { product } = usePlatform();

  const section = items.find(({ id }) => Number(id) === Number(sectionId));
  const { demo: useInDemo = false } =
    section?.videos?.find(({ id }) => Number(id) === Number(videoId)) || {};

  const locked = isDemo && !useInDemo;
  const isPaces = product === EProductType.PACES;

  const { data, loading } = useQuery<IVideoData, IVideoVar>(VIDEO, {
    variables: {
      id: Number(videoId),
    },
    skip: locked,
  });

  const { video } = data?.restricted || {};

  const selectedItem = items.find(
    items => Number(items.id) === Number(sectionId)
  );

  const { name: itemName = '' } = selectedItem || {};

  const { osceStation, status } = (video || {}) as WithDemo<IVideo>;

  const topics = items as unknown as VideoTopic[];

  const selectedTopic = topics?.find(
    topic => Number(topic.id) === Number(sectionId)
  );

  const { videos } = selectedTopic || {};

  const [isVideoFocused, setIsVideoFocused] = useState(false);

  const currentVideoIndex = videos?.findIndex(
    video => Number(video.id) === Number(videoId)
  );

  const previousVideo =
    currentVideoIndex !== undefined
      ? videos?.[currentVideoIndex - 1]
      : undefined;

  const nextVideo =
    currentVideoIndex !== undefined
      ? videos?.[currentVideoIndex + 1]
      : undefined;

  const handleNavigateToPreviousVideo = useCallback(() => {
    if (previousVideo) {
      navigate(
        `${videoLibrary.root}/video/${entitlementId}/${sectionId}/${previousVideo.id}`
      );
    }
  }, [previousVideo, navigate, sectionId, entitlementId]);

  const handleNavigateToNextVideo = useCallback(() => {
    if (nextVideo) {
      navigate(
        `${videoLibrary.root}/video/${entitlementId}/${sectionId}/${nextVideo.id}`
      );
    }
  }, [nextVideo, navigate, sectionId, entitlementId]);

  useHotkeys(
    KeyboardKey.ArrowLeft,
    () => {
      handleNavigateToPreviousVideo();
    },
    {
      enabled:
        Boolean(previousVideo) && enableArrowNavigation && !isVideoFocused,
    },
    [previousVideo, enableArrowNavigation, isVideoFocused]
  );

  useHotkeys(
    KeyboardKey.ArrowRight,
    () => {
      handleNavigateToNextVideo();
    },
    {
      enabled: Boolean(nextVideo) && enableArrowNavigation && !isVideoFocused,
    },
    [nextVideo, enableArrowNavigation, isVideoFocused]
  );

  const handleNavigateToRoot = useCallback(() => {
    navigate(videoLibrary.root);
  }, [navigate]);

  const handleNavigateToCategory = useCallback(() => {
    navigate(`${videoLibrary.root}/section/${entitlementId}/${sectionId}`);
  }, [navigate, sectionId, entitlementId]);

  const handleStatusChange = async (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => updateStatus(ids, newStatus, status);

  const breadcrumbs = useMemo(() => {
    const tempBreadcrumbs = [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
        onClick: osceStation ? handleNavigateToCategory : handleNavigateToRoot,
      },
    ];

    if (!osceStation) {
      tempBreadcrumbs.push({
        icon: FolderOpenOutlineIcon,
        label: itemName,
        onClick: handleNavigateToCategory,
      });
    }

    return tempBreadcrumbs;
  }, [
    activeCategoryLabel,
    handleNavigateToCategory,
    handleNavigateToRoot,
    itemName,
    osceStation,
  ]);

  useEffect(() => {
    setIsRelatedVideosExpanded(false);
  }, [videoId]);

  if (locked) {
    return <DemoGuard contentName="Video" />;
  }

  if (!video) {
    return (
      <SkeletonContainer>
        <Skeleton height={56} variant="rectangular" />
        <Skeleton height={450} variant="rectangular" />
        <SkeletonList height={56} spacing={8} />
      </SkeletonContainer>
    );
  }

  const title = isPaces
    ? getPacesLibraryName({
        hiddenName: osceStation?.hiddenName,
        shownName: osceStation?.name,
        title: video.title,
        userViewed: status === EUserLearningStatus.COMPLETED,
      })
    : video.title;

  const handleReadMoreClick = () => {
    setIsRelatedVideosExpanded(true);
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        center
        learningStatus={
          <StatusMenu
            id={Number(videoId)}
            onStatusChange={handleStatusChange}
            status={status}
          />
        }
        title={title}
      />
      {video ? (
        <VideoContainer
          key={video.id}
          setVideoPlaying={setIsVideoFocused}
          video={video}
        />
      ) : null}
      <ContentContainer>
        {osceStation ? (
          <ItemContainer>
            <ExpandableSection
              expanded
              icon={KnowledgeLibraryIcon}
              title={title}
            >
              <OsceKnowledgeLibraryReading
                activeCategoryLabel={activeCategoryLabel}
                loading={loading}
                locked={locked}
                station={osceStation}
              />
            </ExpandableSection>
          </ItemContainer>
        ) : null}

        {isRelatedVideosExpanded ? (
          video.concepts.map((concept, index) => (
            <VideoDetailsBookItem
              activeCategoryLabel={activeCategoryLabel}
              concept={concept}
              expanded={video.concepts.length === 1 && index === 0}
              key={concept.id}
              noBreadcrumbs
              topics={(items as unknown as VideoTopic[]) || null}
            />
          ))
        ) : (
          <ReadMoreButtonContainer>
            <Button onClick={handleReadMoreClick}>Read More</Button>
          </ReadMoreButtonContainer>
        )}
      </ContentContainer>
    </>
  );
};

export default VideoDetails;
