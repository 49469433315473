import React, { useEffect } from 'react';
import { EMarksheetState, EStudyAction } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import { ExerciseType } from 'types';
import { useQuestionGroupStudyData } from './QuestionsGroupStudyController';
import { Lobby } from 'components/Lobby';
import {
  useChangeMarksheetState,
  useOnMarksheetChange,
  useStartOrJoin,
} from './hooks';
import { paths } from 'Router';
import { useLeaveExerciseState } from 'hooks';
import useCurrentUser from 'Auth/useCurrentUser';

const { questions } = paths;

const QuestionsGroupStudyLobby = () => {
  const navigate = useNavigate();
  const { marksheet, loading, initialState } = useQuestionGroupStudyData();
  const { openLeaveModal } = useLeaveExerciseState();
  const { id: currentUserId, displayName } = useCurrentUser();
  const { activeUsers, sessionId, startOrJoin, inviteLink, marksheetId } =
    useStartOrJoin();
  useOnMarksheetChange(marksheetId, sessionId);

  const { activeUsers: initialActiveUseres, inviteLink: initialInviteLink } =
    initialState || {};
  const groupStudyPath = `?join=${sessionId}`;

  const participants = initialActiveUseres ||
    marksheet?.activeUsers ||
    activeUsers || [{ id: currentUserId, displayName }];

  useEffect(() => {
    if (!sessionId) {
      startOrJoin(EStudyAction.START);
    }
  }, [sessionId, startOrJoin]);

  useEffect(() => {
    if (activeUsers && activeUsers.length > 1) {
      navigate(groupStudyPath, { state: { activeUsers, inviteLink } });
    }
  }, [activeUsers, groupStudyPath, inviteLink, navigate]);

  const { changeMarksheetState, loading: changeStateLoading } =
    useChangeMarksheetState(Number(marksheet?.id));

  const handleStartExercise = () => {
    if (participants.length > 1) {
      changeMarksheetState(EMarksheetState.PRESTART);
    } else {
      navigate(questions.root, {
        state: { marksheetId: Number(marksheet?.id) },
      });
    }
  };

  return (
    <>
      <Lobby
        currentUserId={currentUserId}
        exercise={ExerciseType.Questions}
        groupStudy
        inviteLink={inviteLink || initialInviteLink}
        joinGroupStudyLoading={loading && !initialState}
        lobbyOwner
        onExerciseLeave={openLeaveModal}
        onStartExercise={handleStartExercise}
        participants={participants}
        startExerciseLoading={changeStateLoading}
        withoutPanel={participants.length < 2}
      />
    </>
  );
};

export default QuestionsGroupStudyLobby;
