import { useLazyQuery } from '@apollo/client';
import {
  IOsceMarksheetsWindowData,
  IOsceMarksheetsWindowVar,
  OSCE_MARKSHEET_WINDOW,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useLazyOsceWindowQuery = (
  soloMode: boolean,
  updatedAt?: number | Date
) => {
  const [getStations, { data, loading, client }] = useLazyQuery<
    IOsceMarksheetsWindowData,
    IOsceMarksheetsWindowVar
  >(OSCE_MARKSHEET_WINDOW, {
    variables: {
      filter: {
        limit: 100,
        solo: soloMode,
        updatedAt: updatedAt === 0 ? undefined : updatedAt,
        period: Number(7),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [getStations, { data, loading, client }] as const;
};

export default useLazyOsceWindowQuery;
