import React from 'react';
import {
  EOsceType,
  EPaceMarkType,
  IUserNote,
  OSCE_GLOBAL_RATING,
} from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Control } from 'react-hook-form';

import { Markdown } from 'components/Markdown';
import { StyledMarkdown } from './StyledMarkdown';
import { ExpandableSection } from 'components/ExpandableSection';
import { FormField } from 'components/TextField';
import { Body } from 'components/Typography';
import { FallbackContainer, UserNotes } from 'components/UserNotes';
import { LABELS } from 'types/osce';
import { LockOutlineIcon } from 'components/Icons';

const Header = styled(Body)(({ theme: { spacing, palette } }) => ({
  color: palette.text.primary,
  margin: spacing(8, 0, 4, 0),
}));

const Fields = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(8),
}));

const InputAdornment = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 'fit-content',
  gap: spacing(2),
}));

const FeedbackFallback = (): JSX.Element => (
  <FallbackContainer>
    <Body bold>No feedback yet.</Body>
    <Body>
      Add your feedback here. Provide public feedback for the candidate.
    </Body>
  </FallbackContainer>
);

export interface MarkschemeFeedbackData {
  cumulativeScore: string | number;
  globalScore?: string | number;
}
export const GLOBAL_SCORE_MAX = 5;

const SATISFACTORY_MARK_WEIGTH = 2;

export interface MarkScoreProps {
  feedback: string;
  isCompleted: boolean;
  isPaces: boolean;
  isExaminer: boolean;
  expandedAll?: boolean;
  onFeedbackSave: (feedback: string) => void;
  stationType?: EOsceType;
  totalMarks: number;
  control: Control<MarkschemeFeedbackData>;
}

const MarkScore = ({
  feedback,
  isCompleted,
  isPaces,
  isExaminer,
  onFeedbackSave,
  stationType,
  totalMarks,
  control,
  expandedAll = true,
}: MarkScoreProps) => {
  const expandableSectionVariant = isCompleted ? 'normal' : 'info';

  return (
    <>
      <ExpandableSection
        expanded={expandedAll}
        size="small"
        title="Score"
        variant={expandableSectionVariant}
      >
        <Fields>
          <FormField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {`maximum: ${
                    isPaces ? totalMarks * SATISFACTORY_MARK_WEIGTH : totalMarks
                  }`}
                  <LockOutlineIcon />
                </InputAdornment>
              ),
            }}
            control={control}
            disabled
            fullWidth
            label="Cumulative Score"
            name="cumulativeScore"
          />
          {!isPaces ? (
            <FormField
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    maximum: {GLOBAL_SCORE_MAX}{' '}
                    {isCompleted ? <LockOutlineIcon /> : null}
                  </InputAdornment>
                ),
              }}
              control={control}
              disabled={isCompleted}
              fullWidth
              label="Global Score"
              name="globalScore"
            />
          ) : null}
        </Fields>
        <Header>{isPaces ? 'Cumulative Score:' : 'Global Score:'}</Header>
        {stationType && !isPaces ? (
          <Markdown
            text={OSCE_GLOBAL_RATING[stationType]}
            wrapper={StyledMarkdown}
          />
        ) : null}
        {isPaces ? (
          <p>
            {LABELS[EPaceMarkType.SATISFACTORY]} (2 marks),{' '}
            {LABELS[EPaceMarkType.BORDERLINE]} (1 mark),{' '}
            {LABELS[EPaceMarkType.UN_SATISFACTORY]} (0 marks)
          </p>
        ) : null}
      </ExpandableSection>
      <ExpandableSection
        expanded={expandedAll}
        size="small"
        title="Examiner feedback"
        variant={expandableSectionVariant}
      >
        <UserNotes
          fallback={<FeedbackFallback />}
          notes={{ note: feedback } as IUserNote}
          onSaveNotes={onFeedbackSave}
          readOnly={isCompleted || !isExaminer}
        />
      </ExpandableSection>
    </>
  );
};

export default MarkScore;
