import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { clsx } from 'clsx';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { ArrowRightIcon, LockOutlineIcon } from 'components/Icons';
import { Nullable, Undefinable } from 'types';
import StatusMenu from './StatusMenu';
import { getReadingStatusLabel } from '../utils';
import { LearningMaterialItem } from '../types';
import { useDemo } from 'Auth';

export const Row = styled(Box)(
  ({ theme: { palette, spacing, transitions, breakpoints } }) => ({
    minHeight: spacing(16),
    backgroundColor: palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(5, 4),
    transition: transitions.create(['baclground-color']),
    borderBottom: `1px solid ${palette.stroke.main}`,
    gap: spacing(2),

    [breakpoints.up('md')]: {
      padding: spacing(5, 8),
    },

    '&.header': {
      backgroundColor: palette.background.default,
    },

    '&:not(.header,.locked):hover': {
      backgroundColor: palette.background.default,
      cursor: 'pointer',
    },

    '& .MuiIconButton-root': {
      margin: spacing(-2, 0),
    },

    '& .right-arrow': {
      color: palette.primary.main,
    },

    '&.locked': {
      '& .lock-icon': {
        color: palette.icon.light,
      },
    },
  })
);

const Label = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

interface ItemRowProps<T> {
  useInDemo?: boolean;
  status?: Nullable<EUserLearningStatus>;
  id?: number;
  name?: string;
  header?: boolean;
  items?: LearningMaterialItem[];
  video?: boolean;
  onClick?: () => void;
  onStatusChange?: (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => Undefinable<Promise<T>>;
  count?: number;
}

function ItemRow<T>({
  id,
  items,
  name,
  header = false,
  onClick,
  onStatusChange,
  status,
  useInDemo = false,
  video = false,
  count,
}: ItemRowProps<T>): JSX.Element {
  const isDemo = useDemo();

  const locked = isDemo && !useInDemo;

  return (
    <Row
      className={clsx({ header, locked })}
      onClick={locked ? undefined : onClick}
    >
      <Label>
        {items ? (
          <StatusMenu
            add={count !== undefined}
            disabled={locked}
            id={id}
            items={items}
            onStatusChange={onStatusChange}
            status={status}
            video={video}
          />
        ) : null}
        {name ? name : null}
        {count ? `${getReadingStatusLabel(status, video)} (${count})` : null}
      </Label>
      {!header && onClick && !locked ? (
        <ArrowRightIcon className="right-arrow" />
      ) : null}
      {!header && locked ? <LockOutlineIcon className="lock-icon" /> : null}
    </Row>
  );
}

export default ItemRow;
