import { useMutation } from '@apollo/client';
import {
  BUILD_QUESTION_MARKSHEET,
  IBuildQuestionMarksheetData,
  IBuildQuestionMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { paths } from 'Router';

const useBuildQuestionMarksheet = () => {
  const navigate = useNavigate();
  const [build, { loading }] = useMutation<
    IBuildQuestionMarksheetData,
    IBuildQuestionMarksheetVar
  >(BUILD_QUESTION_MARKSHEET, {
    onCompleted: ({ restricted }) => {
      const { id } = restricted.buildQuestionMarksheet;

      navigate(`${paths.questions.root}/solo/quiz/${id}`);
    },
  });

  const buildMarksheet = useCallback(
    (questionIds: number[]) =>
      build({
        variables: {
          questionIds,
        },
      }),
    [build]
  );

  return { buildMarksheet, loading };
};

export default useBuildQuestionMarksheet;
