import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { MarkdownContentStylesProps } from 'components/Markdown/MarkdownContentStyles';
import { Markdown } from 'components/Markdown';
import { ChevronDownIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import { HotKey } from 'components/HotKey';

interface QuestionChoiceLabelProps
  extends Pick<MarkdownContentStylesProps, 'isCrossOut'> {
  text: string;
  showAnswers: boolean;
  choiceVariant: number | string;
}

const QuestionChoiceContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  justifyContent: 'space-between',
  alignItems: 'center',

  '&> .MuiButtonBase-root': {
    padding: 0,
  },
}));

const StyledHotKey = styled(HotKey)(({ theme: { breakpoints, spacing } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'flex',
    margin: spacing(3),
  },
}));

const QuestionChoiceLabel = ({
  text,
  isCrossOut = false,
  showAnswers,
  choiceVariant,
}: QuestionChoiceLabelProps): JSX.Element => (
  <QuestionChoiceContainer>
    <Markdown
      markdownStylesProps={{ bottomOffset: false, isCrossOut }}
      text={text}
    />

    {showAnswers ? (
      <IconButton disableRipple icon={<ChevronDownIcon />} />
    ) : (
      <StyledHotKey className="hot-key secondary" hotKey={choiceVariant} />
    )}
  </QuestionChoiceContainer>
);

export default QuestionChoiceLabel;
