import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormField } from 'components/TextField';
import { CloseIcon, SearchIcon } from 'components/Icons';
import { IconButton, IconButtonProps } from 'components/IconButton';

interface SearchForm {
  searchString: string;
}

const EndAdornmentButton = (
  props: Omit<IconButtonProps, 'end' | 'icon' | 'children'>
): JSX.Element => <IconButton edge="end" icon={<CloseIcon />} {...props} />;

export type FilterFieldProps = {
  onSearch?: (text: string) => void;
  resetSearch?: boolean;
  searchLabel?: string;
  loading?: boolean;
  fullWidth?: boolean;
  searchTerm?: string;
};

const FilterField = ({
  onSearch,
  resetSearch,
  loading,
  fullWidth = true,
  searchLabel = 'Search',
  searchTerm = '',
}: FilterFieldProps): JSX.Element => {
  const { control, formState, reset, watch, setValue } = useForm<SearchForm>({
    defaultValues: {
      searchString: '',
    },
    mode: 'onTouched',
  });
  const { isDirty } = formState;

  const handleResetSearch = () => {
    reset();
  };

  useEffect(() => {
    setValue('searchString', searchTerm);
  }, [searchTerm, setValue]);

  useEffect(() => {
    if (resetSearch) {
      reset();
    }
  }, [reset, resetSearch]);

  useEffect(() => {
    if (onSearch) {
      let timeout = 0;
      const subscription = watch(data => {
        clearTimeout(timeout);

        const { searchString = '' } = data;

        timeout = window.setTimeout(() => {
          onSearch(searchString);
        }, 500);
      });

      return () => {
        subscription.unsubscribe();
        clearTimeout(timeout);
      };
    }
  }, [watch, onSearch]);

  return (
    <>
      <FormField
        InputProps={{
          endAdornment:
            isDirty || searchTerm ? (
              <EndAdornmentButton onClick={handleResetSearch} />
            ) : null,
          startAdornment: <SearchIcon />,
        }}
        control={control}
        disabled={loading}
        fullWidth={fullWidth}
        name="searchString"
        placeholder={searchLabel}
      />
    </>
  );
};

export default FilterField;
