import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { ToggleButton } from 'components/ToggleButton';
import { AccountGroupIcon, AccountIcon } from 'components/Icons';
import { ExerciseMode } from 'types';
import { constSize } from 'utils';
import { Body, H5 } from 'components/Typography';

const Header = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'block',
  justifyContent: 'space-between',
  alignItems: 'center',

  [breakpoints.up('md')]: {
    display: 'flex',
    height: '56px',
  },
}));

const DateBox = styled(Body)(({ theme: { spacing } }) => ({
  display: 'flex',
  marginTop: spacing(2),
}));

const DateLabel = styled('span')(({ theme: { palette, spacing } }) => ({
  color: palette.secondary.main,
  paddingRight: spacing(2),
}));

const ButtonCol = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  margin: spacing(4, 0),

  '& .MuiSvgIcon-root': {
    ...constSize('20px'),
  },

  '& .MuiBox-root:nth-of-type(2)': {
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

interface IHistoryCardHeader {
  noData: boolean;
  selectedDate: string;
  setMode: (mode: ExerciseMode) => void;
  exerciseMode: ExerciseMode;
}

const TrackerCardHeader = ({
  noData,
  selectedDate,
  setMode,
  exerciseMode,
}: IHistoryCardHeader): JSX.Element => {
  const handleSwitchMode = (mode: ExerciseMode) => () => {
    setMode(mode);
  };

  return (
    <Header>
      <Box>
        <H5>Progress Tracker</H5>
        {noData ? null : (
          <DateBox>
            <DateLabel>Selected date:</DateLabel>
            {selectedDate}
          </DateBox>
        )}
      </Box>
      <ButtonCol>
        <ToggleButton
          icon={<AccountIcon />}
          label="Solo study"
          onChange={handleSwitchMode(ExerciseMode.Solo)}
          selected={exerciseMode === ExerciseMode.Solo}
          value={ExerciseMode.Solo}
        />
        <ToggleButton
          icon={<AccountGroupIcon />}
          label="Group study"
          onChange={handleSwitchMode(ExerciseMode.Group)}
          selected={exerciseMode === ExerciseMode.Group}
          value={ExerciseMode.Group}
        />
      </ButtonCol>
    </Header>
  );
};

export default TrackerCardHeader;
