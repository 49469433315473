import { MouseEvent, TouchEvent, useCallback, useEffect, useRef } from 'react';

interface UseLongPressOptions<T extends HTMLElement> {
  duration?: number;
  onClick?: (event: MouseEvent<T> | TouchEvent<T>) => void;
  onLongPress?: (event: MouseEvent<T> | TouchEvent<T>) => void;
}

export const useLongPress = <T extends HTMLElement>(
  options: UseLongPressOptions<T>
) => {
  const { duration = 1000, onClick, onLongPress } = options || {};
  const timerRef = useRef<number>(-1);
  const isLongPressRef = useRef(false);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent<T> | TouchEvent<T>) => {
      if (isLongPressRef.current) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }
      onClick?.(event);
    },
    [onClick]
  );

  const handleLongPress = useCallback(
    (event: MouseEvent<T> | TouchEvent<T>) => {
      isLongPressRef.current = false;
      timerRef.current = window.setTimeout(() => {
        isLongPressRef.current = true;
        onLongPress?.(event);
      }, duration);
    },
    [duration, onLongPress]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent<T> | TouchEvent<T>) => {
      handleLongPress(event);
    },
    [handleLongPress]
  );

  const handleMouseUp = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  const handleTouchStart = useCallback(
    (event: MouseEvent<T> | TouchEvent<T>) => {
      handleLongPress(event);
      event.preventDefault();
      event.stopPropagation();
    },
    [handleLongPress]
  );

  const handleTouchEnd = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  return {
    onTouchEnd: handleTouchEnd,
    onTouchStart: handleTouchStart,
    onMouseUp: handleMouseUp,
    onMouseDown: handleMouseDown,
    onClick: handleClick,
  };
};
