import React, { useEffect, useMemo, useState } from 'react';
import { EAppType, IProduct } from '@quesmed/types-rn/models';

import { keys, parseProducts } from 'utils';
import { GroupedProducts } from 'types';
import ProductDurationTabs from './ProductDurationTabs';
import ProductCards from './ProductCards';
import { PricingSubscriptions } from 'hooks/useSubscriptions';

interface ProductsProps {
  appType: EAppType;
  onCheckout: (priceId: string) => void;
  products: IProduct[];
  subscriptions: PricingSubscriptions;
  paymentLoading: boolean;
}

const Products = ({
  appType,
  onCheckout,
  products: productsData,
  subscriptions,
  paymentLoading,
}: ProductsProps): JSX.Element => {
  const [selectedDuration, setSelectedDuration] = useState<number>();

  const products = useMemo(() => parseProducts(productsData), [productsData]);

  useEffect(() => {
    if (products && Object.keys(products).length) {
      const [firstKey, defaultKey] = keys(products);
      if (defaultKey) {
        setSelectedDuration(products[defaultKey][0].duration);
      } else {
        setSelectedDuration(products[firstKey][0].duration);
      }
    }
    // We just want to select on the first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ProductDurationTabs
        items={keys<GroupedProducts>(products)}
        selectItem={setSelectedDuration}
        selectedItem={selectedDuration}
      />
      {selectedDuration ? (
        <ProductCards
          appType={appType}
          onCheckout={onCheckout}
          paymentLoading={paymentLoading}
          products={Object.values(products[selectedDuration])}
          subscriptions={subscriptions}
        />
      ) : null}
    </>
  );
};

export default Products;
