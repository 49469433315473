import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { EOsceRoles, IOsceMarksheetMember } from '@quesmed/types-rn/models';
import {
  CHANGE_OSCE_ROLE,
  IChangeOsceRoleData,
  IChangeOsceRoleVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { Nullable } from 'types';

const useStationRoles = (
  sessionId: string,
  members: IOsceMarksheetMember[]
) => {
  const [changeOsceRole, { loading }] = useMutation<
    IChangeOsceRoleData,
    IChangeOsceRoleVar
  >(CHANGE_OSCE_ROLE);

  const assignRole = useCallback(
    (selectedRole: Nullable<EOsceRoles>) => {
      if (members) {
        // When user closes modal the first unassigned
        // role is assigned to them by default
        const role = selectedRole
          ? selectedRole
          : members.find(({ user }) => !user)?.role;

        changeOsceRole({
          variables: {
            sessionId,
            role: role as EOsceRoles,
          },
        });
      }
    },
    [changeOsceRole, sessionId, members]
  );

  return { assignRole, loading };
};

export default useStationRoles;
