import { QuestionAnswerStatus } from 'types';

export const choiceStatus = (
  label: string,
  correctLabel?: string,
  userLabel?: string
) => {
  const correct = correctLabel === label;
  const checked = userLabel === label;
  const caseAnswered = userLabel !== undefined;

  if (correct) {
    return caseAnswered
      ? QuestionAnswerStatus.VALID
      : QuestionAnswerStatus.NOT_ANSWERED;
  }

  if (!correct && checked) {
    return QuestionAnswerStatus.INVALID;
  }

  return QuestionAnswerStatus.CLEAN;
};
