import { ExpandableSectionVariants } from 'components/ExpandableSection/ExpandableSection';

export const getAnswerStatus = (
  correct?: boolean
): ExpandableSectionVariants => {
  switch (correct) {
    case true:
      return 'success';
    case false:
      return 'error';
    case undefined:
      return 'info';
    default:
      return 'normal';
  }
};
