import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { IVideo } from '@quesmed/types-rn/models';

import { Nullable } from 'types';

const Container = styled(Box)(({ theme: { mixins } }) => ({
  maxWidth: mixins.content.maxWidth,
  margin: '0 auto',
}));

const PLAYER_DOM_ID = 'player-container';

interface Props {
  video: IVideo;
  setVideoPlaying?: (playing: boolean) => void;
}

export const VideoContainer = ({ video, setVideoPlaying }: Props) => {
  const [player, setPlayer] = useState<Nullable<MusePlayerInstance>>(null);
  const [disableTimeout, setDisableTimeout] = useState(false);
  const { museId } = video || {};
  useEffect(() => {
    if (museId) {
      setPlayer(
        MusePlayer({
          container: `#${PLAYER_DOM_ID}`,
          video: museId,
          width: '100%',
          height: 'auto',
        })
      );
    }
  }, [museId]);

  useEffect(() => {
    if (player) {
      const playHandler = () => {
        setDisableTimeout(() => true);
        setVideoPlaying?.(true);
      };
      const event = disableTimeout ? player.off : player.on;
      event('play', playHandler);
      event('pause', () => setVideoPlaying?.(false));
      event('ended', () => setVideoPlaying?.(false));
    }
  }, [disableTimeout, player, setVideoPlaying]);

  return (
    <Container>
      <div id={PLAYER_DOM_ID} />
    </Container>
  );
};
