import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { constSize } from 'utils';

const StyledBox = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    ...constSize(spacing(5)),
    borderRadius: 3,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.primary': {
      backgroundColor: palette.icon.dark,
      color: palette.secondary.contrastText,
      ...typography.body1,
    },

    '&.secondary': {
      backgroundColor: palette.toggle.light,
      color: palette.toggle.contrastTextDark,
      ...typography.body2Medium,
    },

    '&.disabled': {
      backgroundColor: palette.icon.light,
      color: palette.secondary.contrastText,
    },

    '&.no-margin': {
      margin: 0,
    },
  })
);

type Variant = 'primary' | 'secondary' | 'disabled';

const getClassFromVariant = (variant: Variant, disabled?: boolean) => {
  if (variant === 'primary') {
    return disabled ? 'disabled' : 'primary';
  }

  return variant;
};

export interface HotKeyProps extends BoxProps {
  variant?: Variant;
  hotKey?: ReactNode;
  disabled?: boolean;
  noMargin?: boolean;
}

const HotKey = ({
  className,
  children,
  disabled,
  noMargin,
  hotKey,
  variant = 'primary',
  ...rest
}: HotKeyProps): JSX.Element => (
  <StyledBox
    className={clsx(
      'hot-key',
      className,
      getClassFromVariant(variant, disabled),
      { 'no-margin': noMargin }
    )}
    component="span"
    {...rest}
  >
    {hotKey || children}
  </StyledBox>
);

export default HotKey;
