import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { EQuestionType, EUserLearningPoint } from '@quesmed/types-rn/models';
import {
  ADD_QUESTION_LEARNING_POINT,
  IAddQuestionLearningPointData,
  IAddQuestionLearningPointVar,
  updateQuestionLearningPoint,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { randomId } from 'utils';
import { useCurrentUser } from 'Auth';

const useAddQuestionLearningPoint = (
  questionId: number,
  typeId: EQuestionType,
  marksheetId?: number
) => {
  const { id: userId } = useCurrentUser();
  const [addLearningPoint, { loading }] = useMutation<
    IAddQuestionLearningPointData,
    IAddQuestionLearningPointVar
  >(ADD_QUESTION_LEARNING_POINT, {
    update: updateQuestionLearningPoint(typeId),
  });

  const addQuestionLearningPoint = useCallback(
    (userPoint: EUserLearningPoint) => {
      const date = Date.now();
      addLearningPoint({
        variables: {
          marksheetId,
          input: [
            {
              questionId,
              userPoint,
            },
          ],
        },
        optimisticResponse: {
          restricted: {
            addQuestionLearningPoint: [
              {
                id: randomId(8),
                createdAt: date,
                updatedAt: date,
                userPoint,
                questionId,
                userId,
                __typename: 'QuestionLearningPoint',
              },
            ],
          },
        },
      });
    },
    [addLearningPoint, marksheetId, questionId, userId]
  );

  return { addQuestionLearningPoint, loading };
};

export default useAddQuestionLearningPoint;
