import React from 'react';
import { styled } from '@mui/material/styles';

import { FlagOutlineIcon, FlagRemoveIcon } from 'components/Icons';
import { Body } from 'components/Typography';

const StyledBody = styled(Body)(({ theme: { spacing, palette } }) => ({
  display: 'inline-flex',
  gap: spacing(2),

  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
  },
}));

interface FlaggedMarkupProps {
  flagged: boolean;
}

export const FlaggedMarkup = ({ flagged }: FlaggedMarkupProps) => (
  <StyledBody>
    {flagged ? (
      <>
        <FlagRemoveIcon />
        Flagged
      </>
    ) : (
      <>
        <FlagOutlineIcon />
        Not flagged
      </>
    )}
  </StyledBody>
);
