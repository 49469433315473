import React from 'react';
import { useParams } from 'react-router-dom';

import {
  useLeaveExerciseState,
  useMarksheetLeavingBlockade,
  useMarksheetQuery,
} from 'hooks';
import MockTestsQuizContainer from './MockTestsQuizContainer';
import QuestionsQuizLeaveModal from 'pages/Questions/QuestionsQuizLeaveModal';

const MockTestsQuizQuery = (): JSX.Element => {
  const { marksheetId = '' } = useParams<{ marksheetId: string }>();
  const { open } = useLeaveExerciseState();
  const { marksheet, loading } = useMarksheetQuery(marksheetId);
  const { completed } = marksheet || {};

  useMarksheetLeavingBlockade({
    marksheetId: Number(marksheetId),
    shouldBlock: Boolean(marksheetId),
    ignoreBlockade: Boolean(completed),
    mockTest: true,
  });

  return (
    <>
      <MockTestsQuizContainer loading={loading} marksheet={marksheet} />
      {open ? (
        <QuestionsQuizLeaveModal
          marksheet={marksheet}
          marksheetId={Number(marksheetId)}
          open={open}
        />
      ) : null}
    </>
  );
};

export default MockTestsQuizQuery;
