import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EProductType,
  EUserLearningStatus,
  IVideo,
} from '@quesmed/types-rn/models';

import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { useOsceVideoLibraryData } from './OsceVideoLibrary';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import { PathName } from 'types';
import {
  SelectionCategories,
  SelectionHeading,
} from 'components/ExerciseBuilder';
import {
  getItemsMap,
  Items,
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbs,
  PanelItems,
  useUpdateVideoLearningStatus,
  VIDEO_SEARCH_LABEL,
} from 'components/LearningMaterials';
import { BookshelfIcon } from 'components/Icons';
import { paths } from 'Router';
import { usePlatform } from 'context/PlatformState';

const { videoLibrary } = paths;

const OsceVideoLibrarySection = () => {
  const { sectionId: categoryId } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const {
    activeCategory,
    activeCategoryLabel,
    allVideos,
    resetSearch,
    categories,
    stations,
    searchBy,
    switchCategory,
    loading,
  } = useOsceVideoLibraryData();
  const [displayedVideos, setDisplayedVideos] = useState<IVideo[]>([]);
  const { updateStatus } = useUpdateVideoLearningStatus();
  const [searchTerm, setSearchTerm] = useState('');
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;

  const category = stations?.find(
    ({ id }) => Number(id) === Number(categoryId)
  );

  const { videos } = category || {};

  const setVideos = useCallback(
    (text: string) => {
      const normalizedSearchString = text.toLowerCase().trim();
      if (allVideos && text) {
        const filtered = allVideos.filter(({ title }) =>
          title.toLowerCase().includes(normalizedSearchString)
        );

        return setDisplayedVideos(filtered);
      }

      setDisplayedVideos(videos || []);
    },
    [videos, allVideos]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      setSearchTerm(searchString);
      setVideos(searchString);
    },
    [setVideos]
  );

  const handleNavigate = useCallback(
    (videoId: number) => () => {
      navigate(
        `${videoLibrary.root}/video/${categoryId}/${categoryId}/${videoId}`
      );
    },
    [navigate, categoryId]
  );

  const handleStatusChange = useCallback(
    async (newStatus: EUserLearningStatus, ids: number[]) =>
      updateStatus(ids, newStatus),
    [updateStatus]
  );

  useEffect(() => {
    setVideos(searchTerm);
  }, [setVideos, searchTerm]);

  const videosMap = useMemo(
    () => getItemsMap(displayedVideos),
    [displayedVideos]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
      },
    ],
    [activeCategoryLabel]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          entitlementId={Number(categoryId)}
          items={stations}
          loading={loading}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={null}
          searchLabel={VIDEO_SEARCH_LABEL}
          showSearch={!isPaces}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.VideoLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader mainHeader={PathName.VideoLibrary} />
          {categories.length ? (
            <>
              <SelectionCategories
                activeCategory={activeCategory}
                categoryOptions={categories}
                onToggleCategory={switchCategory}
              />
              <SelectionHeading
                loading={loading}
                onSearch={isPaces ? undefined : handleSearch}
                resetSearch={resetSearch}
                searchLabel={VIDEO_SEARCH_LABEL}
                title={
                  <LearningMaterialsBreadcrumbs header items={breadcrumbs} />
                }
              />
            </>
          ) : null}

          <Items
            itemsMap={videosMap}
            navigate={handleNavigate}
            onStatusChange={handleStatusChange}
            showFallback={!displayedVideos.length}
            title="Video titles"
            video
          />
        </PreLaunchLayout>
      }
    />
  );
};

export default OsceVideoLibrarySection;
