import { EOsceRoles, IOsceMarksheetMember } from '@quesmed/types-rn/models';

const checkVideoShouldBeEnabled = (
  members?: IOsceMarksheetMember[],
  role?: EOsceRoles | null
): boolean => {
  if (!members || !role) {
    return false;
  }

  const isActor = Boolean(
    members.find(({ user, role }) => user && role === EOsceRoles.ACTOR)
  );

  const hasRequiredRole =
    role === EOsceRoles.CANDIDATE ||
    role === EOsceRoles.ACTOR ||
    (role === EOsceRoles.EXAMINER && !isActor);

  return hasRequiredRole;
};

export default checkVideoShouldBeEnabled;
