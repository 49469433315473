import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Body } from 'components/Typography';
import { Modal } from 'components/Modal';

interface RemoveCardFromDailyStackModalProps {
  removeCardFromDailyStackModalIsOpen: boolean;
  removeCardFromDailyStackCloseModal: () => void;
  onRemove: () => void;
}

const CustomBox = styled(Box)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(7.5),
  paddingRight: spacing(7.5),
}));

const RemoveCardFromDailyStackModal = ({
  removeCardFromDailyStackModalIsOpen,
  removeCardFromDailyStackCloseModal,
  onRemove,
}: RemoveCardFromDailyStackModalProps): JSX.Element => {
  return (
    <Modal
      onClose={removeCardFromDailyStackCloseModal}
      onSubmit={() => {
        onRemove();
      }}
      open={removeCardFromDailyStackModalIsOpen}
      submitLabel="Remove"
      title="Remove Ques Card from daily review?"
    >
      <CustomBox>
        <Body>
          This will remove this card from your daily review stack and remove any
          progress you have saved for it.
        </Body>
      </CustomBox>
    </Modal>
  );
};

export default RemoveCardFromDailyStackModal;
