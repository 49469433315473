import React, { useMemo } from 'react';
import {
  EOsceRoles,
  IOsceMarksheetMember,
  IUser,
} from '@quesmed/types-rn/models';
import { useParams } from 'react-router-dom';

import { useCurrentUser } from 'Auth';
import { useOsceMarksheet } from './hooks';
import { CircularProgress } from 'components/CircularProgress';
import Station from './Station';

interface ExistingOsceMarksheetMember
  extends Omit<IOsceMarksheetMember, 'user'> {
  user: IUser;
}

const StationsReview = (): JSX.Element => {
  const { osceMarksheetId } = useParams<{ osceMarksheetId: string }>();
  const { id: currentUserId } = useCurrentUser();

  const { osceMarksheet } = useOsceMarksheet(false, Number(osceMarksheetId));

  const { members, users, osceStation, solo } = osceMarksheet || {};

  const data = useMemo(() => {
    if (osceMarksheet && members && osceStation) {
      const filteredMembers = members.filter(
        ({ user }) => user
      ) as ExistingOsceMarksheetMember[];

      const { role } = filteredMembers?.find(
        ({ user }) => user.id === currentUserId
      ) as ExistingOsceMarksheetMember;

      return {
        currentUserId: currentUserId ? currentUserId : 1,
        osceMarksheet,
        osceStation,
        isExaminer: solo ? true : role === EOsceRoles.EXAMINER,
        isCandidate: solo ? false : role === EOsceRoles.CANDIDATE,
        members: solo ? undefined : members,
        role: solo ? EOsceRoles.EXAMINER : role,
        review: true,
        users: solo ? undefined : users,
      };
    }

    return undefined;
  }, [currentUserId, members, osceMarksheet, osceStation, solo, users]);

  if (!data) {
    return <CircularProgress description="Getting study data" />;
  }

  return <Station data={data} />;
};

export default StationsReview;
