import { EDifficultyType } from '@quesmed/types-rn/models';
import { IPreBuildUkmlaMarksheetData } from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

import { OnRequestComplete } from 'types';
import { usePrebuildMLAMarksheetQuery } from './index';

interface PreBuildQuestionsParams {
  search?: string;
  source?: string;
  marksheetId?: number;
  onPrebuildComplete?: OnRequestComplete<IPreBuildUkmlaMarksheetData>;
}

const usePreBuildMLAQuestions = (params?: PreBuildQuestionsParams) => {
  const {
    search = '',
    source = 'Questions',
    marksheetId,
    onPrebuildComplete,
  } = params || {};

  const { preBuildLoading, preBuildData, preBuildMarksheet } =
    usePrebuildMLAMarksheetQuery(onPrebuildComplete);

  const preBuildQuestions = useCallback(
    async (
      conditionIds: number[] = [],
      presentationIds: number[] = [],
      difficulty: EDifficultyType[]
    ) =>
      preBuildMarksheet({
        topicIds: [],
        conditionIds,
        presentationIds,
        search,
        source,
        difficulty,
        marksheetId,
      }),
    [preBuildMarksheet, search, source, marksheetId]
  );

  return {
    preBuildData,
    preBuildLoading,
    preBuildQuestions,
  };
};

export default usePreBuildMLAQuestions;
