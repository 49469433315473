import React, { useState } from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'components/Modal';
import { Body } from 'components/Typography';
import {
  AgoraVideo,
  CameraButton,
  SoundButton,
  useActiveSpeaker,
  useAgoraAPI,
  useAgoraState,
} from 'components/Agora';
import { VolumeIcon, VolumeOffIcon } from 'components/Icons';
import { paths } from 'Router';

const { stations } = paths;

const VideoContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  border: `1px solid ${palette.stroke.light}`,
  borderRadius: '9px',
  backgroundColor: palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(8),
}));

const AVControls = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(4),
  display: 'flex',
  justifyContent: 'center',
  gap: spacing(2),
}));

interface ReadyToJoinModalProps {
  connected: boolean;
  displayName?: string;
  loading: boolean;
  participantId?: number;
}

const ReadyToJoinModal = ({
  connected,
  displayName = '',
  loading,
  participantId,
}: ReadyToJoinModalProps) => {
  const [isReady, setIsReady] = useState(false);
  const { mutedUsers } = useAgoraState();
  const { activeSpeaker } = useActiveSpeaker();
  const navigate = useNavigate();
  const { publishVideo, isConnected } = useAgoraAPI();

  const muted = participantId ? mutedUsers.includes(participantId) : false;
  const AudioIcon = muted ? VolumeOffIcon : VolumeIcon;
  const active = Boolean(
    participantId && participantId === activeSpeaker?.uid && activeSpeaker.level
  );

  const handleCancel = () => {
    navigate(stations.root);
  };

  const handleSubmit = async () => {
    if (isConnected()) {
      await publishVideo();
    }
    setIsReady(true);
  };

  if (isReady) {
    return null;
  }

  return (
    <Modal
      cancelLabel="Leave Session"
      closeOnBackdrop={false}
      closeOnEscape={false}
      disableContentScroll
      maxWidth="md"
      noPaddingY
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      open={!isReady}
      sizeVariant="md"
      submitLabel="Join Exercise"
      submitLoading={loading}
      title="Are you ready to join?"
    >
      <Body>
        Before you enter the exercise, let&apos;s make sure that your camera is
        working properly. When you&apos;re satisfied with your camera setup,
        click &quot;Join Exercise&quot;.
      </Body>
      <VideoContainer>
        <AgoraVideo
          active={active && !muted}
          audioIcon={<AudioIcon />}
          currentUser
          displayName={displayName}
          participantId={participantId}
        />
        <AVControls>
          <SoundButton connected={connected} />
          <CameraButton connected={connected} />
        </AVControls>
      </VideoContainer>
    </Modal>
  );
};

export default ReadyToJoinModal;
