import React from 'react';
import { mdiSchool } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const MockTestsIcon = (props: IconProps) => (
  <Icon path={mdiSchool} {...props} />
);

export default MockTestsIcon;
