import React from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import { EProductType, EUserLearningStatus } from '@quesmed/types-rn/models';

import PanelListItem from './PanelListItem';
import PanelSubItemName from './PanelSubItemName';
import { paths } from 'Router';
import { LearningMaterialItem, LearningMaterialsData } from '../types';
import { useExpandedState } from 'components/Layout/useExerciseLayoutState';
import { useIsDesktop } from 'hooks/useDeviceSize';
import { useDemo } from 'Auth';
import { usePlatform } from 'context/PlatformState';
import { getPacesLibraryName } from '../utils';

const { knowledgeLibrary, videoLibrary } = paths;

export const Nesting = styled(Box)(({ theme: { palette, spacing } }) => ({
  borderLeft: `1px solid ${palette.stroke.main}`,
  marginLeft: spacing(4),
}));

interface PanelListSubItemProps {
  items: LearningMaterialItem[];
  itemId: number;
  entitlementId?: number;
}

const PanelListSubItem = ({
  items,
  itemId,
  entitlementId,
}: PanelListSubItemProps) => {
  const { toggleExpanded } = useExpandedState();
  const isDemo = useDemo();
  const { isDesktop } = useIsDesktop();
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;
  const isStationsProduct =
    product === EProductType.PLAB2 || product === EProductType.OSCE || isPaces;

  return (
    <Nesting>
      {items.map(item => {
        const {
          name,
          id,
          status,
          title,
          demo: useInDemo = false,
          osceStation,
        } = item;

        const subItemName = name || title || '';
        const itemName = isPaces
          ? getPacesLibraryName({
              hiddenName: osceStation?.hiddenName,
              shownName: osceStation?.name,
              name: subItemName,
              userViewed: status === EUserLearningStatus.COMPLETED,
            })
          : subItemName;
        const locked = isDemo && !useInDemo;

        const rootPath = title
          ? `${videoLibrary.root}/video`
          : `${knowledgeLibrary.root}/chapter`;

        const path = `${rootPath}/${
          isStationsProduct ? itemId : entitlementId
        }/${itemId}/${id}`;

        return (
          <PanelSubItemName
            key={id}
            locked={locked}
            name={itemName || ''}
            path={path}
            status={status || EUserLearningStatus.UNREAD}
            toggleExpand={isDesktop ? undefined : toggleExpanded}
          />
        );
      })}
    </Nesting>
  );
};

interface PanelListProps {
  item: LearningMaterialsData;
  forceExpanded?: boolean;
  entitlementId?: number;
}

const PanelList = ({
  forceExpanded,
  item,
  entitlementId,
}: PanelListProps): JSX.Element => {
  const { name, id, concepts, videos, stations, entitlement } = item;
  const items = videos || concepts || stations || [];

  return (
    <PanelListItem
      forceExpanded={forceExpanded}
      itemId={Number(id)}
      name={name}
    >
      <PanelListSubItem
        entitlementId={entitlementId || entitlement?.id}
        itemId={Number(id)}
        items={items}
      />
    </PanelListItem>
  );
};

export default PanelList;
