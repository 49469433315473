import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IMarksheetMark } from '@quesmed/types-rn/models';

import { Body, H3, Overline } from 'components/Typography';
import { DEFAULT_TEXT_SCALE } from 'config/constants';
import { PreviewIcon } from 'components/Icons';
import { checkValidity, localStorageTyped } from 'utils';
import { QuestionAnswerStatus } from 'types';

const ScoreProgressContainer = styled(Body)(
  ({ theme: { palette, spacing, typography } }) => ({
    textAlign: 'center',
    marginTop: spacing(6),
    fontSize: `calc(${typography.body1.fontSize} * ${DEFAULT_TEXT_SCALE})`,
    lineHeight: `calc(${typography.body1.lineHeight} * ${DEFAULT_TEXT_SCALE})`,
    padding: spacing(4, 6),
    backgroundColor: palette.background.default,
  })
);

const ScoreProgressTop = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledOverline = styled(Overline)(
  ({ theme: { palette, typography, spacing } }) => ({
    ...typography.button,
    color: palette.text.disabled,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
  })
);

const ClickableOverline = styled(StyledOverline)(
  ({ theme: { palette, transitions } }) => ({
    cursor: 'pointer',
    transition: transitions.create(['color']),

    '&:hover': {
      color: palette.secondary.main,
    },
  })
);

const ScoreProgressScore = styled(H3)(({ theme: { spacing, typography } }) => ({
  marginLeft: spacing(2),
  fontSize: `calc(${typography.h3.fontSize} * ${DEFAULT_TEXT_SCALE})`,
  lineHeight: `calc(${typography.h3.lineHeight} * ${DEFAULT_TEXT_SCALE})`,
}));

const { getItem, setItem } = localStorageTyped<boolean>(
  'currentProgressShowing'
);

const getCurrentScoreFromMarks = (marks: IMarksheetMark[]) => {
  const totalAnswered = marks.reduce(
    (acc, curr) => (curr.isAnswered ? acc + 1 : acc),
    0
  );

  if (totalAnswered === 0) {
    return 0;
  }

  const totalAnsweredCorrectly = marks.reduce(
    (acc, curr) =>
      checkValidity(curr.questionChoiceId, curr.question) ===
      QuestionAnswerStatus.VALID
        ? acc + 1
        : acc,
    0
  );

  return Math.round((100 / totalAnswered) * totalAnsweredCorrectly);
};

interface Props {
  marks: IMarksheetMark[];
}

const ScoreProgress = ({ marks }: Props) => {
  const [currentProgressShowing, setCurrentProgressShowing] = useState(
    getItem()
  );

  const handleCurrentProgressShowing = () => {
    setCurrentProgressShowing(!currentProgressShowing);
    setItem(!currentProgressShowing);
  };

  return (
    <ScoreProgressContainer>
      <ScoreProgressTop>
        <StyledOverline>Score</StyledOverline>
        <ClickableOverline onClick={handleCurrentProgressShowing}>
          {currentProgressShowing ? 'Hide' : 'Show'} <PreviewIcon />
        </ClickableOverline>
      </ScoreProgressTop>
      {currentProgressShowing ? (
        <ScoreProgressScore bold component="span">
          {getCurrentScoreFromMarks(marks)}%
        </ScoreProgressScore>
      ) : null}
    </ScoreProgressContainer>
  );
};

export default ScoreProgress;
