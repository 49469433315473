import { useQuery } from '@apollo/client';
import {
  IUserActivityData,
  IUserActivityVar,
  USER_ACTIVITY,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useUserActivityQuery = ({ days = 120 }: { days?: number }) => {
  const { data, loading } = useQuery<IUserActivityData, IUserActivityVar>(
    USER_ACTIVITY,
    {
      variables: {
        days,
      },
    }
  );

  return {
    data: data?.restricted.userActivity,
    loading,
  };
};

export default useUserActivityQuery;
