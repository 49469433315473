import React, {
  UIEvent as ReactUIEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { useResizeObserver } from 'hooks';
import { Tabs, TabsProps } from 'components/Tabs';
import { Children } from 'types';

const pillHeight = 48;
const pillGapHeight = 16;
const closeButtonHeight = 20;
const pillPlusGapHeight = pillHeight + pillGapHeight;
const listPadding = 16;
const currentScoreHeight = 102;

interface ExercisePillsProps extends TabsProps {
  children: Children;
  currentPillId?: number;
  pillIndex: number;
}

const TabsContainer = styled(Box)({ height: '100%' });

const PillsContainer = styled(Box)({
  height: `calc(100% - ${
    pillPlusGapHeight + currentScoreHeight + closeButtonHeight
  }px)`,
  overflowY: 'auto',
  paddingBottom: `${pillGapHeight}px`,
  display: 'flex',
  flexDirection: 'column',
  gap: `${pillGapHeight}px`,
});

const ExercisePills = ({
  activeTab,
  children,
  onTabChange,
  pillIndex,
  tabs,
}: ExercisePillsProps): JSX.Element => {
  const [setPillsRef, pillsBoxSize, pillsListElement] =
    useResizeObserver<HTMLDivElement>();
  const [scrollTop, setScrollTop] = useState(0);
  const { height: pillsListHeight } = pillsBoxSize;

  useEffect(() => {
    // Base on the amount of questions (pills), container heigth and
    // current container scroll value we calculate if active pill is visible
    // in the container and we calculate and how much the container has to be
    // scrolled to make the pill visible.
    if (pillIndex >= 0 && pillsListElement) {
      // pill's top edge (y) position in the container if it would be big enough to make all pills visible
      const pillTopPosition =
        pillIndex * pillHeight + (pillIndex - 1) * pillGapHeight + listPadding;

      if (scrollTop && pillTopPosition < scrollTop) {
        // check if container is scrolled up and pill is not visible to scroll down the container
        pillsListElement.scrollTo({
          top: pillTopPosition - listPadding,
          behavior: 'smooth',
        });
      } else if (
        pillsListHeight &&
        pillTopPosition + pillPlusGapHeight + listPadding - scrollTop >
          pillsListHeight
      ) {
        // check if top edge (y) position is bigger than list heigth to scroll up the container
        pillsListElement.scrollTo({
          top: pillTopPosition + pillPlusGapHeight - pillsListHeight,
          behavior: 'smooth',
        });
      }
    }
    // We can't add scrollTop to dependecies array as it
    // will cause unneded rerenders or infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillsListHeight, pillIndex, pillsListElement, activeTab]);

  const handleScroll = useCallback(
    (e: ReactUIEvent<HTMLDivElement, UIEvent>) => {
      setScrollTop(e.currentTarget?.scrollTop);
    },
    []
  );

  return (
    <TabsContainer>
      <Tabs activeTab={activeTab} onTabChange={onTabChange} tabs={tabs} />
      <PillsContainer onScroll={handleScroll} ref={setPillsRef}>
        {children}
      </PillsContainer>
    </TabsContainer>
  );
};

export default ExercisePills;
