import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';

import PanelItemName from './PanelItemName';
import { KnowledgeLibraryRouterParams } from '../types';
import { Children } from 'types';

// TODO: replace with new color from design (update palette as well
const ACTIVE_ITEM_COLOR = 'rgba(241, 242, 243, 0.5)';

const Container = styled(Box)(({ theme: { spacing, transitions } }) => ({
  '& .MuiList-root > .MuiBox-root .MuiCollapse-root .MuiBox-root .MuiButtonBase-root':
    {
      marginLeft: spacing(-8),
      paddingLeft: spacing(14),

      '&::before': {
        left: '40px',
      },
    },

  ' & .MuiList-root > div > .MuiBox-root > .MuiListItemButton-root': {
    borderRadius: '9px',
    transition: transitions.create(['background']),
    paddingLeft: spacing(5),
    marginLeft: spacing(-4),

    '&:hover': {
      background: ACTIVE_ITEM_COLOR,
    },
  },

  '& > .MuiListItemButton-root': {
    transition: transitions.create(['background']),

    '&:hover': {
      background: ACTIVE_ITEM_COLOR,
    },
  },

  '& .MuiList-root > div > .MuiBox-root': {
    overflow: 'hidden',
    borderRadius: '9px',
    paddingLeft: spacing(5),
    marginLeft: spacing(-5),
  },
}));

interface PanelListItemProps {
  itemId: number;
  subItemId?: number;
  children: Children;
  name: string;
  forceExpanded?: boolean;
}

const PanelListItem = ({
  itemId,
  subItemId,
  children,
  forceExpanded,
  name,
}: PanelListItemProps): JSX.Element => {
  const { sectionId, chapterId } = useParams<KnowledgeLibraryRouterParams>();
  const sectionIdFromParam = sectionId ? Number(sectionId) : 0;
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleItemExpandState = () => {
    setExpanded(prev => !prev);
  };

  useEffect(() => {
    setExpanded(Boolean(forceExpanded));
  }, [forceExpanded]);

  useEffect(() => {
    if (sectionIdFromParam && itemId) {
      setExpanded(sectionIdFromParam === Number(itemId));
    }
  }, [itemId, sectionIdFromParam]);

  useEffect(() => {
    if (subItemId && chapterId) {
      setExpanded(Number(subItemId) === Number(chapterId));
    }
  }, [subItemId, chapterId]);

  return (
    <Container>
      <PanelItemName
        active={sectionIdFromParam === itemId || subItemId === chapterId}
        expanded={expanded}
        name={name}
        onClick={toggleItemExpandState}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List disablePadding>{children}</List>
      </Collapse>
    </Container>
  );
};

export default PanelListItem;
