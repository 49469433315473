import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IUpsertConceptNoteData,
  IUpsertConceptNoteVar,
  optimisticUpsertConceptNote,
  updateCacheOnUpsertConceptNote,
  UPSERT_CONCEPT_NOTE,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import useCurrentUser from 'Auth/useCurrentUser';

const useUpsertChapterNote = () => {
  const { id: userId } = useCurrentUser();

  const [mutate] = useMutation<IUpsertConceptNoteData, IUpsertConceptNoteVar>(
    UPSERT_CONCEPT_NOTE,
    {
      update: updateCacheOnUpsertConceptNote,
    }
  );

  const upsertNote = useCallback(
    (note: string, conceptId: number, marksheetId?: number) => {
      mutate({
        variables: {
          conceptId,
          note,
          marksheetId,
        },
        optimisticResponse: optimisticUpsertConceptNote({
          conceptId,
          note,
          userId,
          marksheetId,
        }),
      });
    },
    [mutate, userId]
  );

  return upsertNote;
};

export default useUpsertChapterNote;
