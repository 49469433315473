import { Theme } from '@mui/system';
import { Chart } from 'chart.js';

import { formatOrdinals } from 'utils/intl';
import findClosestIndex from '../helpers/findClosestIndex';

const linePlugin = (
  series: [number, number][],
  point: number,
  theme: Theme
) => ({
  id: 'line',
  beforeDraw: (chart: Chart) => {
    const {
      ctx,
      chartArea: { bottom },
    } = chart;
    const index = findClosestIndex(series, point || 0);
    chart.update();
    ctx.beginPath();
    ctx.strokeStyle = theme.palette.primary.main;
    ctx.fillStyle = theme.palette.primary.main;
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);
    ctx.lineTo(
      chart.getDatasetMeta(0).data[index].x,
      chart.getDatasetMeta(0).data[index].y
    );
    ctx.lineTo(chart.getDatasetMeta(0).data[index].x, bottom);
    ctx.stroke();
    ctx.fillText(
      formatOrdinals(Number(((index / series.length) * 100).toFixed())),
      chart.getDatasetMeta(0).data[index].x,
      chart.getDatasetMeta(0).data[index].y - 10
    );
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';
    ctx.closePath();
    ctx.restore();
    ctx.setLineDash([]);
  },
});

export default linePlugin;
