import { useQuery } from '@apollo/client';
import {
  IMockTestsData,
  IMockTestsVar,
  MOCK_TESTS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { EMockTestType, IUserMockTest } from '@quesmed/types-rn/models';
import { useMemo } from 'react';

import { getTestCategoryName, sortMockTestCategories } from 'utils/index';

type TestsMap = {
  [key in EMockTestType]: IUserMockTest[];
};

const useMockTestsQuery = (typeIds: EMockTestType[] = [EMockTestType.ALL]) => {
  const { data, loading } = useQuery<IMockTestsData, IMockTestsVar>(
    MOCK_TESTS,
    {
      variables: { typeIds },
    }
  );

  const { mockTests = [] } = data?.restricted || {};

  const [mappedTests, categories] = useMemo(() => {
    if (!mockTests) {
      return [null, []];
    }

    const testTypes = new Set<EMockTestType>();

    const map = {} as TestsMap;

    mockTests?.forEach(test => {
      if (test.typeId) {
        testTypes.add(test.typeId);

        if (map[test.typeId]) {
          map[test.typeId].push(test);
        } else {
          map[test.typeId] = [test];
        }
      }
    });

    const categories = sortMockTestCategories(
      [...testTypes]?.map(value => ({
        label: getTestCategoryName(value),
        value,
      }))
    );

    return [map, categories];
  }, [mockTests]);

  return { mockTests, loading, mappedTests, categories };
};

export default useMockTestsQuery;
