import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { H6 } from '../Typography';
import { Button } from '../Button';
import { IconButton } from '../IconButton';

export const ButtonRow = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(3),
}));

export const HeaderWrapper = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),

  '&.center': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const LeftControlContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const HeaderContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: spacing(2),
      alignItems: 'flex-start',
    },
  })
);

export const CategoryTitle = styled(Box)(
  ({ theme: { typography, palette, spacing } }) => ({
    ...typography.button,
    color: palette.primary.main,
    marginBottom: spacing(2),
    textAlign: 'left',
  })
);

interface TitleProps {
  scale?: number;
  className?: string;
}

export const Title = styled(H6)<TitleProps>(
  ({
    theme: {
      palette,
      spacing,
      typography: { h6 },
    },
    scale,
  }) => ({
    '&.content-header-title': {
      color: palette.primary.dark,
      fontSize: scale ? `calc(${h6.fontSize} * ${scale})` : h6.fontSize,
      lineHeight: scale ? `calc(${h6.lineHeight} * ${scale})` : h6.lineHeight,

      '&:not(.no-margin)': {
        marginRight: spacing(2),
      },

      '&.with-category-title': {
        color: palette.text.primary,
      },
    },
  })
);

export const RightActionButton = styled(Button)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
    marginLeft: 'auto',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  })
);

export const StyledIconButton = styled(IconButton)(
  ({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&.active .MuiSvgIcon-root': {
      fill: palette.primary.main,
    },
    '&.active .MuiSvgIcon-root:hover': {
      fill: palette.primary.dark,
    },
  })
);
