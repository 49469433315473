import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { correctMark } from '@quesmed/types-rn/utils';
import { IMarksheetMark, IQuestionSelect3 } from '@quesmed/types-rn/models';

import Choices from './Choices';
import { ExpandableSection } from 'components/ExpandableSection';
import { parseJSON } from 'utils';
import { Select3Answer } from 'types';
import { SelectThreeQuestionAnswer } from './SelectThreeQuestionAnswer';

const AnswerContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

const SelectThreeQuestionFeedback = ({ mark }: { mark: IMarksheetMark }) => {
  const { question } = mark;
  const { choices, select3Answer } = question as IQuestionSelect3;
  const chosenAnswers = parseJSON<Select3Answer>(mark?.mark);

  const { correct } = correctMark(mark);

  return (
    <AnswerContainer>
      {correct ? null : (
        <ExpandableSection
          disableContentBackground
          expanded
          title="Incorrect (selected)"
          variant="error"
        >
          <Choices
            choices={choices}
            chosenAnswers={chosenAnswers}
            correctAnswers={select3Answer}
            isAnswered
          />
        </ExpandableSection>
      )}
      <SelectThreeQuestionAnswer
        question={question as IQuestionSelect3}
        title="Correct"
        variant="success"
      />
    </AnswerContainer>
  );
};

export default SelectThreeQuestionFeedback;
