import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar },
      spacing,
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
    gap: spacing(4),

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
    },

    '& > *': {
      textAlign: 'center',
    },
  })
);
