import { useEffect, useRef } from 'react';

// eslint-disable-next-line import/default
import PreloadWorker from 'pwa/PreloadWorker?worker';
import { PreloadedImage, WithPictures } from 'pwa/types';
import { Nullable } from 'types';
import { createImages } from 'utils';

export const usePrealoadImages = (data: WithPictures, terminate = false) => {
  const worker = useRef<Nullable<Worker>>(null);

  useEffect(() => {
    if (window.Worker && data && !(worker.current instanceof Worker)) {
      worker.current = new PreloadWorker();

      worker.current.addEventListener(
        'message',
        async ({ data }: MessageEvent<PreloadedImage[]>) => {
          if (data) {
            await createImages(data);
          }
        }
      );

      worker.current.postMessage(data);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (terminate && worker.current instanceof Worker) {
        worker.current.terminate();
      }
    };
  }, [terminate]);
};
