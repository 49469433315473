import { useApolloClient, useMutation } from '@apollo/client';
import {
  ISaveMarksheetInput,
  ISaveMarksheetsData,
  ISaveMarksheetsVar,
  optimisticSaveMarksheets,
  SAVE_MARKSHEET,
  updateMarksheets,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IMarksheet } from '@quesmed/types-rn/models';
import { MARKSHEET } from '@quesmed/types-rn/resolvers/query/restricted';

import { ERRORS } from 'config/constants';

type OnCompleteCallback = (data: ISaveMarksheetsData) => void;

const useSaveMarksheets = (onCompleteCallback?: OnCompleteCallback) => {
  const client = useApolloClient();

  const [save, { loading }] = useMutation<
    ISaveMarksheetsData,
    ISaveMarksheetsVar
  >(SAVE_MARKSHEET, {
    onError: error => {
      if (error.message === ERRORS.MARKSHEET_MARK_ALREADY_ANSWERED) {
        client.refetchQueries({
          include: [MARKSHEET],
        });
      }
    },
    onCompleted: data => {
      if (onCompleteCallback) {
        onCompleteCallback(data);
      }
    },
    update: updateMarksheets,
  });

  const saveMarksheets = (
    marksheetInput: ISaveMarksheetInput,
    marksheet: IMarksheet,
    index: number
  ) =>
    save({
      variables: { input: [marksheetInput] },
      optimisticResponse: optimisticSaveMarksheets(
        marksheet,
        marksheetInput,
        index
      ),
    });

  return { saveMarksheets, loading };
};

export default useSaveMarksheets;
