//arrow-u-left-bottom

import React from 'react';
import { mdiArrowULeftBottom } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowULeftButtomIcon = (props: IconProps) => (
  <Icon path={mdiArrowULeftBottom} {...props} />
);

export default ArrowULeftButtomIcon;
