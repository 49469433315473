import { useCallback } from 'react';

import { useLeaveExerciseBlockade } from 'hooks';
import { paths } from 'Router';

const { flashcards } = paths;
const { root } = flashcards;

const FLASHCARDS_ROUTES = [`${root}`, `${root}/quiz`];

interface FlashcardsLeavingBlockade {
  ignoreBlockade: boolean;
  shouldBlock: boolean;
}
const useFlashcardLeavingBlockade = ({
  ignoreBlockade,
  shouldBlock,
}: FlashcardsLeavingBlockade) => {
  const ignorePath = useCallback(
    (path: string) =>
      Boolean(FLASHCARDS_ROUTES.some(route => path.includes(route))),
    []
  );

  useLeaveExerciseBlockade({
    ignorePath,
    ignoreBlockade,
    shouldBlock,
  });
};

export default useFlashcardLeavingBlockade;
