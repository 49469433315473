import React, { useEffect } from 'react';
import { CircularProgress, styled } from '@mui/material';
import Box from '@mui/system/Box';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import { Body, H4 } from 'components/Typography';
import { Button } from 'components/Button';
import { SpaceBarHotKey } from 'components/HotKey';
import { AutorenewIcon, WebIcon } from 'components/Icons';
import { ExerciseType, KeyboardKey } from 'types';
import ExerciseLayout from 'components/Layout/ExerciseLayout';
import {
  ParticipantList,
  ParticipantListProps,
} from 'components/ParticipantCard';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { useIsMobile } from 'hooks';
import { useSnackbar } from 'components/Snackbar';
import { useDemo } from 'Auth';
import locales from 'locales';
import { paths } from 'Router';

const { questions, stations } = paths;

const BUTTON_CONTAINER_HEIGHT = 81;

const Container = styled(Box)(
  ({ theme: { spacing, transitions, mixins } }) => ({
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    padding: spacing(8, 10),
    transition: transitions.create(['padding']),
    maxWidth: `calc(${mixins.content.maxWidth}px + 80px )`,
    marginBottom: `${BUTTON_CONTAINER_HEIGHT}px`,
  })
);

const Header = styled(Box)(({ theme: { spacing } }) => ({
  textAlign: 'center',
  marginBottom: spacing(8),
}));

const Title = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExerciseTitle = styled(Body)(({ theme: { palette } }) => ({
  display: 'inline-block',

  span: {
    color: palette.text.secondary,
  },
}));

const ButtonsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  width: '100%',
  gap: spacing(4),

  '& .MuiButton-outlined': {
    gap: spacing(2),
  },
}));

const ParticipantInfo = styled(Box)(({ theme: { typography, spacing } }) => ({
  ...typography.button,
  textAlign: 'center',
  padding: spacing(4),
}));

const ButtonContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: spacing(4),
    background: palette.background.paper,
    borderTop: `1px solid ${palette.stroke.main}`,
    overflow: 'hidden',

    '& .hot-key': {
      display: 'none',

      [breakpoints.up('md')]: {
        display: 'inline-flex',
      },
    },

    [breakpoints.up('md')]: {
      position: 'relative',
      background: 'unset',
      borderTop: 'unset',
      padding: 0,
    },
  })
);

interface LobbyProps extends Omit<ParticipantListProps, 'variant'> {
  exercise: ExerciseType;
  lobbyOwner?: boolean;
  groupStudy?: boolean;
  withoutRole?: boolean;
  joinGroupStudyLoading?: boolean;
  startExerciseLoading?: boolean;
  onStartExercise?: () => void;
  onJoinGroupStudy?: () => void;
  onExerciseLeave?: () => void;
  withoutPanel?: boolean;
}

const Lobby = ({
  currentUserId,
  participants,
  exercise,
  groupStudy = false,
  joinGroupStudyLoading,
  startExerciseLoading,
  withAudio,
  withRoleHeader,
  withVideo,
  onExerciseLeave,
  onSwapRole,
  onChangeRole,
  onStartExercise,
  lobbyOwner = true,
  inviteLink,
  onJoinGroupStudy,
  withoutRole,
  withoutPanel,
}: LobbyProps) => {
  const { isMobile } = useIsMobile();
  const { enqueueSnackbar } = useSnackbar();
  const isDemo = useDemo();
  const navigate = useNavigate();

  useHotkeys(
    KeyboardKey.Space,
    e => {
      e.preventDefault();
      if (onStartExercise) {
        onStartExercise();
      }
    },
    [onStartExercise]
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleJoinGroupStudy = () => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.groupStudy);
    } else {
      onJoinGroupStudy?.();
    }
  };

  const handleNavigateToSolo = () => {
    const quizBuilder =
      exercise === ExerciseType.Stations ? stations.root : questions.root;

    navigate(quizBuilder);
  };

  return (
    <ExerciseLayout
      bottomOffsetVariant={isMobile ? 'medium' : 'tiny'}
      controlPanelContent={
        <ParticipantList
          currentUserId={Number(currentUserId)}
          onChangeRole={onChangeRole}
          onSwapRole={onSwapRole}
          participants={participants}
          variant="panel"
          withAudio={withAudio}
          withRoleHeader={withRoleHeader}
          withVideo={withVideo}
          withoutRole={withoutRole}
        />
      }
      exerciseContent={
        <Container>
          <Header>
            <H4>Start exercise or invite friends</H4>
            <Title>
              <ExerciseTitle bold>
                <span>Chosen exercise: </span> {exercise}
              </ExerciseTitle>
              <Button onClick={handleNavigateToSolo} size="small" tertiary>
                <AutorenewIcon />
                Change to Solo Study
              </Button>
            </Title>
          </Header>
          <ParticipantList
            currentUserId={Number(currentUserId)}
            inviteLink={inviteLink}
            onChangeRole={onChangeRole}
            onSwapRole={onSwapRole}
            participants={participants}
            variant="lobby"
            withAudio={withAudio}
            withStatus
          />
          {lobbyOwner ? (
            <ButtonsContainer>
              {onJoinGroupStudy && (
                <Button
                  fullWidth
                  onClick={handleJoinGroupStudy}
                  secondary
                  size="large"
                >
                  {joinGroupStudyLoading ? (
                    <>
                      <CircularProgress size={20} />
                      Searching for a study group
                    </>
                  ) : (
                    <>
                      <WebIcon />
                      Join a study group
                    </>
                  )}
                </Button>
              )}
              {onStartExercise && (
                <ButtonContainer>
                  <Button
                    fullWidth
                    loading={startExerciseLoading}
                    onClick={onStartExercise}
                    size="large"
                  >
                    Start {participants.length > 1 ? 'group' : 'solo'}
                    <SpaceBarHotKey />
                  </Button>
                </ButtonContainer>
              )}
            </ButtonsContainer>
          ) : (
            <ParticipantInfo>
              Please wait for the examiner to start the exercise...
            </ParticipantInfo>
          )}
        </Container>
      }
      exerciseItemsCount={participants.length}
      onLeave={onExerciseLeave}
      {...PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS}
      withSound={groupStudy}
      withoutPanel={withoutPanel}
    />
  );
};

export default Lobby;
