import { useMemo } from 'react';

import { OsceKnowledgeLibraryData } from '../types';
import { getStationsLearningData } from '../utils';
import { useOsceKnowledgeVideoLibraryQuery } from './useOsceKnowledgeVideoLibraryQuery';

export const useOsceKnowledge = (): OsceKnowledgeLibraryData => {
  const { allStations, loading } = useOsceKnowledgeVideoLibraryQuery();

  const [stationsMap, stations, categories] = useMemo(
    () => getStationsLearningData(allStations),
    [allStations]
  );

  return { stations, loading, stationsMap, categories, allStations };
};
