import React, { useEffect, useMemo, useState } from 'react';
import { useSubscription } from '@apollo/client';
import {
  EOsceMarksheetAction,
  EOsceStage,
  EOsceTimerState,
  IOsceMarksheet,
} from '@quesmed/types-rn/models';
import {
  IOsceMarksheetTimerData,
  IOsceMarksheetTimerVar,
  OSCE_MARKSHEET_TIMER,
} from '@quesmed/types-rn/resolvers/subscription';
import { styled } from '@mui/material/styles';

import { INIT_TIMER } from 'config/constants';
import { usePrevious } from 'hooks';
import { parseDate } from 'utils';
import { useAgoraAPI, useAgoraState } from 'components/Agora';
import TimeIsUpModal from './TimeIsUpModal';
import { useModifyOsceMarksheet, useStationTimer } from 'pages/Stations/hooks';
import { setTimerDataValues } from './clalcTotalTime';
import { ExerciseTimer } from 'components/ExerciseTimer';
import { useStationTimerState } from './useStationTimerState';
import { RoundedButton } from 'components/Button';

const StyledButton = styled(RoundedButton)(({ theme: { spacing } }) => ({
  justifyContent: 'center',
  width: '100%',
  marginTop: spacing(2),
}));

const setDisplayTimer = (
  timeIsUp: boolean,
  loading: boolean,
  review: boolean,
  initTime: string,
  totalTimeString: string,
  totalStationTime?: string,
  timeRemaining?: string
): string => {
  if (review || timeIsUp) {
    return INIT_TIMER;
  }

  if (loading) {
    if (initTime !== INIT_TIMER) {
      return initTime;
    }
  } else {
    if (totalStationTime && totalStationTime !== INIT_TIMER && timeRemaining) {
      return timeRemaining;
    }
  }

  return totalTimeString;
};

interface StationTimerProps {
  onTimeIsUp: () => void;
  osceMarksheet: IOsceMarksheet;
  reviewMode: boolean;
  completed: boolean;
  isSample?: boolean;
  handleLeaveStation: () => void;
  leaveLabel: string;
}

const StationTimer = ({
  osceMarksheet,
  onTimeIsUp,
  reviewMode,
  completed,
  isSample,
  handleLeaveStation,
  leaveLabel,
}: StationTimerProps) => {
  const {
    setCurrentStage,
    setSelectedStage,
    setCompletedStages,
    setIsPaused,
    setPauseAction,
    isPaused,
    timeIsUp,
    setTimeIsUp,
  } = useStationTimerState();
  const [showTimeIsUpModal, setShowTimeIsUpModal] = useState(false);
  const { connect } = useAgoraAPI();
  const { sessionExpired } = useAgoraState();
  const {
    id,
    pausedAt,
    solo,
    endedAt,
    sessionId,
    timeRemaining: initTime,
  } = osceMarksheet;
  const { data, loading } = useSubscription<
    IOsceMarksheetTimerData,
    IOsceMarksheetTimerVar
  >(OSCE_MARKSHEET_TIMER, {
    variables: {
      sessionId,
    },
    fetchPolicy: 'no-cache',
    skip: !sessionId || solo || reviewMode,
    shouldResubscribe: true,
  });
  const { modifyOsceMarksheet } = useModifyOsceMarksheet();

  const prevData = usePrevious(data);
  const { totalTimeString } = useMemo(
    () => setTimerDataValues(osceMarksheet),
    [osceMarksheet]
  );
  const payload = useStationTimer(osceMarksheet, reviewMode, isSample);

  const timer = solo ? payload : data?.osceMarksheetTimer;

  const { stage, timeRemaining, totalStationTime, state } = timer || {};

  const handleTimeIsUp = () => {
    setShowTimeIsUpModal(false);
    onTimeIsUp();
  };

  useEffect(() => {
    if (!solo && !prevData && data) {
      if (!sessionExpired) {
        connect();
      }
    }
  }, [connect, data, prevData, sessionExpired, solo]);

  useEffect(() => {
    if (!data && loading && endedAt) {
      const timeIsUp =
        new Date().getTime() > new Date(parseDate(endedAt)).getTime();

      setTimeIsUp(timeIsUp);
    } else {
      setTimeIsUp(false);
    }
  }, [data, endedAt, loading, setTimeIsUp]);

  useEffect(() => {
    if (!solo) {
      setIsPaused(Boolean(pausedAt));
    }

    return () => {
      setIsPaused(false);
      setPauseAction(false);
      setTimeIsUp(false);
      setCompletedStages([]);
      setSelectedStage(EOsceStage.READING);
      setCurrentStage(EOsceStage.READING);
    };
  }, [
    pausedAt,
    setCompletedStages,
    setCurrentStage,
    setIsPaused,
    setPauseAction,
    setSelectedStage,
    setTimeIsUp,
    solo,
  ]);

  useEffect(() => {
    switch (stage) {
      case undefined:
        break;
      case EOsceStage.STATION:
        setCompletedStages([EOsceStage.READING]);
        break;
      case EOsceStage.FEEDBACK:
        setCompletedStages([EOsceStage.READING, EOsceStage.STATION]);
        break;

      default:
        setCurrentStage(stage);
        setSelectedStage(stage);
    }
  }, [setCompletedStages, setCurrentStage, setSelectedStage, stage]);

  useEffect(() => {
    switch (state) {
      case EOsceTimerState.COMPLETED:
        setTimeIsUp(true);
        setIsPaused(true);
        setCompletedStages([
          EOsceStage.READING,
          EOsceStage.STATION,
          EOsceStage.FEEDBACK,
        ]);
        break;
      case EOsceTimerState.PAUSE:
        setIsPaused(true);
        break;
      case EOsceTimerState.START:
        setIsPaused(false);
        break;
      default:
        setIsPaused(false);
    }
  }, [setCompletedStages, setIsPaused, setTimeIsUp, state]);

  useEffect(() => {
    if (totalStationTime !== INIT_TIMER && timeRemaining === INIT_TIMER) {
      setShowTimeIsUpModal(true);
    }
  }, [timeRemaining, totalStationTime]);

  const displayTime = setDisplayTimer(
    timeIsUp,
    loading,
    reviewMode,
    initTime,
    totalTimeString,
    totalStationTime,
    timeRemaining
  );

  const handlePauseClick = () => {
    if (!completed && !timeIsUp) {
      if (solo) {
        setPauseAction(true);
      } else {
        modifyOsceMarksheet(EOsceMarksheetAction.PAUSE, Number(id));
      }
    }
  };

  const handlePlayClick = () => {
    if (!completed && !timeIsUp) {
      if (solo) {
        setPauseAction(false);
      } else {
        modifyOsceMarksheet(EOsceMarksheetAction.CONTINUE, Number(id));
      }
    }
  };

  return (
    <>
      <ExerciseTimer
        disabled={completed}
        isPaused={isPaused}
        onClick={isPaused ? handlePlayClick : handlePauseClick}
        timeLeft={displayTime}
        withControl
      />
      <StyledButton onClick={handleLeaveStation} variant="outlined">
        {leaveLabel}
      </StyledButton>
      <TimeIsUpModal
        isOpen={showTimeIsUpModal && !reviewMode}
        onSubmit={handleTimeIsUp}
      />
    </>
  );
};

export default StationTimer;
