import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { IConcept } from '@quesmed/types-rn/models';

import { KeyboardKey, PathName } from 'types';
import { ExerciseLayout } from 'components/Layout';
import {
  ChapterContainer,
  ChapterContent,
  KnowledgeLibraryRouterParams,
  LearningMaterialsData,
  PanelItems,
  QBANK_SEARCH_LABEL,
} from 'components/LearningMaterials';
import { useQbankKnowledgeLibraryData } from './QbankKnowledgeLibrary';
import QbankKnowledgeLibraryReading from './QbankKnowledgeLibraryReading';
import { paths } from 'Router';
import useLightboxState from 'components/Lightbox/useLightboxState';

const { knowledgeLibrary } = paths;

const QbankKnowledgeLibraryChapter = () => {
  const {
    activeCategoryLabel,
    loading,
    resetSearch,
    searchBy,
    topics,
    search,
    searchItems,
    searchTerm,
  } = useQbankKnowledgeLibraryData();
  const {
    sectionId: topicId,
    chapterId: conceptId,
    entitlementId,
  } = useParams<KnowledgeLibraryRouterParams>();
  const chapterContainerRef = useRef<HTMLDivElement>(null);
  const [currentConcept, setCurrentConcept] = useState<IConcept | undefined>();
  const { index: lightboxIndex } = useLightboxState();

  const navigate = useNavigate();

  useEffect(() => {
    if (chapterContainerRef.current) {
      chapterContainerRef.current.focus();
      chapterContainerRef.current.scrollTo({ top: 0 });
    }
  }, [conceptId]);

  const selectedTopic = topics?.find(
    topic => Number(topic.id) === Number(topicId)
  );

  const { concepts } = selectedTopic || {};

  useEffect(() => {
    const selectedConcept = concepts?.find(
      concept => Number(concept.id) === Number(conceptId)
    );
    setCurrentConcept(selectedConcept);
  }, [conceptId, concepts]);

  const currentConceptIndex = concepts?.findIndex(
    ({ id }) => currentConcept?.id === id
  );

  const nextConcept =
    currentConceptIndex !== undefined
      ? concepts?.[currentConceptIndex + 1]
      : undefined;

  const previousConcept =
    currentConceptIndex !== undefined
      ? concepts?.[currentConceptIndex - 1]
      : undefined;

  const navigateNext = () => {
    if (nextConcept) {
      navigate(
        `${knowledgeLibrary.root}/chapter/${entitlementId}/${topicId}/${nextConcept.id}`
      );
    }
  };

  const navigatePrevious = () => {
    if (previousConcept) {
      navigate(
        `${knowledgeLibrary.root}/chapter/${entitlementId}/${topicId}/${previousConcept.id}`
      );
    }
  };

  useHotkeys(
    KeyboardKey.ArrowRight,
    () => {
      navigateNext();
    },
    {
      enabled: () => Boolean(nextConcept) && lightboxIndex < 0,
    },
    [currentConcept, nextConcept]
  );

  useHotkeys(
    KeyboardKey.ArrowLeft,
    () => {
      navigatePrevious();
    },
    {
      enabled: () => Boolean(previousConcept) && lightboxIndex < 0,
    },
    [currentConcept, previousConcept]
  );

  return (
    <ExerciseLayout
      collapsedPanel={false}
      controlPanelContent={
        <PanelItems
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchItems}
          searchLabel={QBANK_SEARCH_LABEL}
          searchTerm={searchTerm}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={
        <ChapterContainer ref={chapterContainerRef} tabIndex={0}>
          <ChapterContent>
            <QbankKnowledgeLibraryReading
              activeCategoryLabel={activeCategoryLabel}
              concept={currentConcept}
              loading={loading}
              topics={topics}
            />
          </ChapterContent>
        </ChapterContainer>
      }
    />
  );
};

export default QbankKnowledgeLibraryChapter;
