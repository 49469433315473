import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IOsceMarksheet, IOsceMarksheetMember } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useDemo } from 'Auth';
import { CachedIcon, PreviewIcon } from 'components/Icons';
import { useHover, usePrevious } from 'hooks';
import {
  ProgressLineChart,
  ProgressLineChartProps,
} from 'components/ProgressLineChart';
import { formatDate, round } from 'utils';
import { ItemStatusLabel, StationRole } from 'types';
import { paths } from 'Router';
import { STATION_ROLES } from 'constants/stations';
import StationsTimerModal from 'pages/Stations/StationsTimerModal';
import { ActionPopper } from 'components/ActionPopper';
import { DateCell, PresetCell } from 'components/TableCells';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { TableRowComponent } from './types';

const progressFormatter = ({
  osceStation,
  total,
  correct,
}: IOsceMarksheet): ProgressLineChartProps => ({
  title: osceStation?.name || '',
  data: [
    {
      type: ItemStatusLabel.Confident,
      value: correct,
      label: ItemStatusLabel.Performed,
    },
    {
      type: ItemStatusLabel.NotPerformed,
      value: total - correct,
      label: ItemStatusLabel.NotPerformed,
    },
  ],
  total: total,
});

const StationsHistoryTableRow: TableRowComponent<IOsceMarksheet> = ({
  result,
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [popperRef, popperRefValue] = useHover<HTMLButtonElement>();
  const popperRefPrevValue = usePrevious(popperRefValue);
  const [showStationsModal, setShowStationsModal] = useState(false);
  const navigate = useNavigate();
  const { id: currentUserId } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const isDemo = useDemo();

  const showDemoMessage = () => {
    enqueueSnackbar(locales.common.demo.feature);
  };

  const { endedAt, id, osceStation, members, correct, total } = result;

  const handleRestartStation = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setShowStationsModal(true);
    }
  };

  const handleCloseStationsModal = () => setShowStationsModal(false);

  const handleOpenMenu = () => {
    setIsPopperOpen(true);
  };

  const handleCloseMenu = () => {
    setIsPopperOpen(false);
  };

  useEffect(() => {
    if (isPopperOpen && popperRefPrevValue && !popperRefValue) {
      handleCloseMenu();
    }
  }, [isPopperOpen, popperRefPrevValue, popperRefValue]);

  const handleReview = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      navigate(`${paths.stations.root}/review/${id}`);
    }
  };

  const getUserRole = (userId: number, members?: IOsceMarksheetMember[]) => {
    const userObj = members?.filter(member => member.userId === userId).pop();

    return userObj?.role;
  };

  const userRole = getUserRole(currentUserId, members);
  const percentagePoints = round((correct / total) * 100);

  const actionPopperItems = [
    { icon: <PreviewIcon />, label: 'Review', onClick: handleReview },
    { icon: <CachedIcon />, label: 'Restart', onClick: handleRestartStation },
  ];

  return (
    <TableRow>
      <DateCell>{formatDate(endedAt)}</DateCell>
      <PresetCell>
        <Box>
          [{osceStation?.entitlement?.name}] {osceStation?.name}
        </Box>
        <Box>
          Candidate scored points:&nbsp;
          {`${percentagePoints}% (${correct}/${total})`}
        </Box>
      </PresetCell>
      <TableCell>
        <ProgressLineChart {...progressFormatter(result)} />
      </TableCell>
      <TableCell>{STATION_ROLES[userRole as StationRole]?.name}</TableCell>
      <TableCell onClick={handleOpenMenu} ref={popperRef}>
        <ActionPopper
          currentRef={popperRef.current}
          isPopperOpen={isPopperOpen}
          items={actionPopperItems}
        />
      </TableCell>
      {showStationsModal ? (
        <StationsTimerModal
          onClose={handleCloseStationsModal}
          open={showStationsModal}
          solo
          stationId={Number(osceStation?.id)}
        />
      ) : null}
    </TableRow>
  );
};

export default StationsHistoryTableRow;
