import React from 'react';

import { FlashcardScore } from 'types/qbank';
import { Nullable } from 'types';
import { NumericCircleIcon, RadioBoxBlankIcon } from 'components/Icons';

interface FlashcardScoreMarkProps {
  score: Nullable<FlashcardScore>;
  showAsNotAnswered?: boolean;
}

const FlashcardScoreMark = ({
  score,
  showAsNotAnswered = false,
}: FlashcardScoreMarkProps) => {
  switch (score) {
    default:
    case null:
      return showAsNotAnswered ? <RadioBoxBlankIcon /> : null;
    case FlashcardScore.NotAtAll:
      return <NumericCircleIcon number={FlashcardScore.NotAtAll} />;
    case FlashcardScore.Slightly:
      return <NumericCircleIcon number={FlashcardScore.Slightly} />;
    case FlashcardScore.Moderately:
      return <NumericCircleIcon number={FlashcardScore.Moderately} />;
    case FlashcardScore.KnowWell:
      return <NumericCircleIcon number={FlashcardScore.KnowWell} />;
    case FlashcardScore.Perfectly:
      return <NumericCircleIcon number={FlashcardScore.Perfectly} />;
  }
};

export default FlashcardScoreMark;
