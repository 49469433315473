import React from 'react';
import { mdiHeadSnowflakeOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const HeadSnowflakeOutlineIcon = (props: IconProps) => (
  <Icon path={mdiHeadSnowflakeOutline} {...props} />
);

export default HeadSnowflakeOutlineIcon;
