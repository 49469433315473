import React from 'react';
import { useParams } from 'react-router-dom';

import { useMarksheet } from 'pages/Questions/hooks';
import QuestionsQuizContainer from './QuestionsQuizContainer';

const QuestionsReview = (): JSX.Element => {
  const { marksheetId = '' } = useParams<{ marksheetId: string }>();

  const { marksheet, marksheetLoading, marksheetError } = useMarksheet(
    false,
    Number(marksheetId)
  );

  return (
    <QuestionsQuizContainer
      error={marksheetError}
      isSampleGame={false}
      loading={marksheetLoading || !marksheetId}
      marksheet={marksheet}
      review
    />
  );
};

export default QuestionsReview;
