import React from 'react';

import Markdown, { MarkdownProps } from './Markdown';
import { Lightbox, LightboxProps } from 'components/Lightbox';

type MarkdownWithLightboxProps = MarkdownProps & LightboxProps;

const MarkdownWithLightbox = ({
  pictures,
  showCaptions,
  ...props
}: MarkdownWithLightboxProps): JSX.Element => (
  <>
    <Markdown {...props} />
    {pictures ? (
      <Lightbox pictures={pictures} showCaptions={showCaptions} />
    ) : null}
  </>
);

export default MarkdownWithLightbox;
