import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ExerciseLayout } from 'components/Layout';
import { OSCE_SEARCH_LABEL } from 'components/LearningMaterials/constants';
import {
  ChapterContainer,
  ChapterContent,
  KnowledgeLibraryRouterParams,
  PanelItems,
} from 'components/LearningMaterials';
import { useOsceKnowledgeLibraryData } from './OsceKnowledgeLibrary';
import OsceKnowledgeLibraryReading from './OsceKnowledgeLibraryReading';
import { BookshelfIcon } from 'components/Icons';
import { paths } from 'Router';
import { PathName } from 'types';

const { knowledgeLibrary } = paths;

const OsceKnowledgeLibraryChapter = () => {
  const {
    activeCategoryLabel,
    activeCategory,
    loading,
    resetSearch,
    searchTerm,
    search,
    searchBy,
    stations,
    allStations,
  } = useOsceKnowledgeLibraryData();
  const navigate = useNavigate();

  const handleNavigateToRoot = useCallback(() => {
    navigate(
      `${knowledgeLibrary.root}/section/${activeCategory}/${activeCategory}`
    );
  }, [activeCategory, navigate]);
  const { chapterId: conceptId } = useParams<KnowledgeLibraryRouterParams>();
  const chapterContainerRef = useRef<HTMLDivElement>(null);

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
        onClick: handleNavigateToRoot,
      },
    ],
    [activeCategoryLabel, handleNavigateToRoot]
  );

  useEffect(() => {
    if (chapterContainerRef.current) {
      chapterContainerRef.current.focus();
      chapterContainerRef.current.scrollTo({ top: 0 });
    }
  }, [conceptId]);

  return (
    <ExerciseLayout
      collapsedPanel={false}
      controlPanelContent={
        <PanelItems
          entitlementId={Number(activeCategory)}
          items={allStations}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchTerm ? stations : null}
          searchLabel={OSCE_SEARCH_LABEL}
          searchTerm={searchTerm}
          sectionId={activeCategory}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={
        <ChapterContainer ref={chapterContainerRef} tabIndex={0}>
          <ChapterContent>
            <OsceKnowledgeLibraryReading
              activeCategoryLabel={activeCategoryLabel}
              breadcrumbs={breadcrumbs}
              loading={loading}
              stations={stations}
            />
          </ChapterContent>
        </ChapterContainer>
      }
    />
  );
};

export default OsceKnowledgeLibraryChapter;
