import { MLASelectionState } from 'types';

export const getMLATopics = (
  selectionState: MLASelectionState
): [number[], number[]] => {
  const result: [number[], number[]] = [[], []];

  [...selectionState.values()].forEach(
    ({ selectedConditionsIds, selectedPresentationsIds }) => {
      result[0].push(...selectedConditionsIds);
      result[1].push(...selectedPresentationsIds);
    }
  );

  return [[...new Set(result[0])], [...new Set(result[1])]];
};
