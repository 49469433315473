import { IQuestion } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { QuestionAnswerStatus } from 'types';

function calculateValidityObject(
  index: number | undefined | null,
  question: IQuestion
) {
  if (isNil(index)) return {};
  if (index > -1 && question.choices[index].answer) {
    return { [index]: QuestionAnswerStatus.VALID };
  }
  const validIndex = question.choices.findIndex(({ answer }) => answer);

  return {
    [index]: QuestionAnswerStatus.INVALID,
    [validIndex]: QuestionAnswerStatus.VALID,
  };
}

export default calculateValidityObject;
