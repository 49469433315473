import { IConcept } from '@quesmed/types-rn/models';

import { GameType } from 'types';

export const calcQuestions = (
  selectedConcepts: Set<number>,
  gameType: GameType,
  concepts?: IConcept[]
): [number, number] => {
  if (!concepts) {
    return [0, 0];
  }
  let sum = 0;
  let total = 0;

  concepts.forEach(({ id, totalQuestions, totalCards }) => {
    if (totalQuestions && gameType === GameType.QUESTIONS) {
      total += totalQuestions;
    }
    if (totalCards && gameType === GameType.FLASHCARDS) {
      total += totalCards;
    }
    if (selectedConcepts.has(Number(id))) {
      if (gameType === GameType.QUESTIONS && totalQuestions) {
        sum += totalQuestions;
      }
      if (gameType === GameType.FLASHCARDS && totalCards) {
        sum += totalCards;
      }
    }
  });

  return [sum, total];
};
