import React from 'react';
import { useParams } from 'react-router-dom';

import { FlashcardsRouterParams } from 'types';
import FlashcardsQuizContainer from './FlashcardsQuizContainer';
import { useFlashcardsQuery } from './hooks';

const FlashcardsQuizQuery = (): JSX.Element => {
  const { todoId = '' } = useParams<FlashcardsRouterParams>();

  const { flashcards, loading } = useFlashcardsQuery(Number(todoId));

  return (
    <FlashcardsQuizContainer
      isSampleGame={false}
      loading={loading}
      todo={flashcards}
    />
  );
};

export default FlashcardsQuizQuery;
