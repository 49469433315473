import React, { useEffect, useMemo, useRef } from 'react';
import { ITodoMark } from '@quesmed/types-rn/models';
import { useHotkeys } from 'react-hotkeys-hook';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import { Markdown } from 'components/Markdown';
import { parseFullText } from 'utils';
import ScoreButtons from 'pages/Flashacards/ScoreButtons';
import { CheckboxMarkedCircleOutlineIcon } from 'components/Icons';
import { H6 } from 'components/Typography';
import { ExpandableSection } from 'components/ExpandableSection';
import { Lightbox } from 'components/Lightbox';
import { KeyboardKey, Nullable } from 'types';
import { QuestionContainer } from 'components/QuestionContainer';

const AnswerButtonsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  aligItems: 'center',
  textAlign: 'center',
  gap: spacing(6),
}));

const ExplanationBox = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
}));

interface FlashcardQuestionProps {
  cardNumber: number;
  mark: ITodoMark;
  setScore: (score: number) => void;
  saveTodosLoading: boolean;
  showAnswer: boolean;
  onShowAnswerClick: (isShown: boolean) => void;
}

const FlashcardQuestion = ({
  cardNumber,
  mark,
  saveTodosLoading,
  setScore,
  showAnswer = false,
  onShowAnswerClick,
}: FlashcardQuestionProps): JSX.Element => {
  const { score, card } = mark;
  const { concept, explanation, pictures = [], question } = card || {};
  const { chapter } = concept || {};
  const isQuestionAnswered = score !== null;
  const disabled = (isQuestionAnswered && score > 0) || saveTodosLoading;
  const questionRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    onShowAnswerClick(isQuestionAnswered);
  }, [isQuestionAnswered, onShowAnswerClick]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [cardNumber]);

  const executeSaveScore = (myScore: number) => {
    if (showAnswer && !disabled) {
      setScore(myScore);
    }
  };

  useHotkeys(
    KeyboardKey.One,
    () => {
      executeSaveScore(1);
    },
    [showAnswer, disabled]
  );
  useHotkeys(
    KeyboardKey.Two,
    () => {
      executeSaveScore(2);
    },
    [showAnswer, disabled]
  );
  useHotkeys(
    KeyboardKey.Three,
    () => {
      executeSaveScore(3);
    },
    [showAnswer, disabled]
  );
  useHotkeys(
    KeyboardKey.Four,
    () => {
      executeSaveScore(4);
    },
    [showAnswer, disabled]
  );
  useHotkeys(
    KeyboardKey.Five,
    () => {
      executeSaveScore(5);
    },
    [showAnswer, disabled]
  );

  useEffect(() => {
    if (isQuestionAnswered) {
      onShowAnswerClick(true);
    } else {
      onShowAnswerClick(false);
    }
  }, [isQuestionAnswered, onShowAnswerClick]);

  const images = useMemo(
    () => (chapter?.pictures || []).concat(showAnswer ? pictures : []),
    [chapter?.pictures, pictures, showAnswer]
  );

  return (
    <QuestionContainer ref={questionRef}>
      {question && (
        <Markdown
          showCaption={showAnswer}
          text={parseFullText(question, chapter?.pictures)}
        />
      )}
      <Fade exit={false} in={Boolean(showAnswer && explanation)} unmountOnExit>
        <ExplanationBox>
          {explanation ? (
            <ExpandableSection
              expanded
              icon={CheckboxMarkedCircleOutlineIcon}
              title="Correct answer"
            >
              <Markdown
                showCaption={showAnswer}
                text={parseFullText(explanation, pictures)}
              />
            </ExpandableSection>
          ) : null}
        </ExplanationBox>
      </Fade>
      <Lightbox pictures={images} showCaptions={showAnswer} />
      {showAnswer ? (
        <AnswerButtonsContainer>
          <H6>How well did you know it?</H6>
          <ScoreButtons
            cardScore={score}
            loading={saveTodosLoading}
            onClick={executeSaveScore}
          />
        </AnswerButtonsContainer>
      ) : null}
    </QuestionContainer>
  );
};

export default FlashcardQuestion;
