import { CheckboxState, MLASelectionState, MLATopicMap } from 'types';

export const calcAllMLAQuestions = (
  selection: MLASelectionState,
  topicMap: MLATopicMap
): [number, number, number] => {
  const questionCountSet = new Set();
  let conditionsCount = 0;
  let presentationsCount = 0;

  [...selection.values()].forEach(
    ({ id, topicState, selectedConditionsIds, selectedPresentationsIds }) => {
      const { conditions, presentations } = topicMap[id];
      if (topicState === CheckboxState.UNCHECKED) {
        [...selectedConditionsIds].forEach(id => {
          const { questionIds } = conditions?.[id] || {};
          if (questionIds) {
            questionIds.forEach(questionCountSet.add, questionCountSet);
            conditionsCount++;
          }
        });

        [...selectedPresentationsIds].forEach(id => {
          const { questionIds } = presentations?.[id] || {};
          if (questionIds) {
            questionIds.forEach(questionCountSet.add, questionCountSet);
            presentationsCount++;
          }
        });
      } else {
        conditionsCount += selectedConditionsIds.size;
        presentationsCount += selectedPresentationsIds.size;

        [...selectedConditionsIds].forEach(id => {
          const { questionIds } = conditions?.[id] || {};
          if (questionIds) {
            questionIds.forEach(questionCountSet.add, questionCountSet);
          }
        });
        [...selectedPresentationsIds].forEach(id => {
          const { questionIds } = presentations?.[id] || {};
          if (questionIds) {
            questionIds.forEach(questionCountSet.add, questionCountSet);
          }
        });
      }
    }
  );

  return [questionCountSet.size, presentationsCount, conditionsCount];
};
