import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { IOsceStation } from '@quesmed/types-rn/models';
import {
  IOsceBookData,
  OSCE_KNOWLEDGE_VIDEO_LIBRARY,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { Nullable } from 'types';
import { compareRankNameAsc } from 'utils/index';

export const useOsceKnowledgeVideoLibraryQuery = (
  videosOnly = false
): {
  allStations: Nullable<IOsceStation[]>;
  loading: boolean;
} => {
  const { data, loading } = useQuery<IOsceBookData>(
    OSCE_KNOWLEDGE_VIDEO_LIBRARY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { videosOnly },
    }
  );

  const { osceBook } = data?.restricted || {};

  const allStations = useMemo(
    () => (osceBook ? [...osceBook].sort(compareRankNameAsc) : null),
    [osceBook]
  );

  return { allStations, loading };
};
