import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(() => ({
  '&.MuiPaper-root': {
    marginTop: '100px',
    padding: '10px',
    textAlign: 'center',
  },
}));

const ComponentTestPage = () => {
  return (
    <Container>
      <StyledPaper>
        <h1>This is a page for testing and developing components.</h1>
        <p>Should not be included in the production version.</p>
      </StyledPaper>
    </Container>
  );
};

export default ComponentTestPage;
