import React, { SyntheticEvent, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IPreset } from '@quesmed/types-rn/models';
import { useLocation } from 'react-router-dom';

import SelectionCategories from 'components/ExerciseBuilder/SelectionCategories';
import SelectionHeading from 'components/ExerciseBuilder/SelectionHeading';
import ActivityHistoryTable from 'pages/dashboard/components/Activity/components/ActivityHistoryTable';
import { FLASHCARDS_LEGEND, PRESETS_HEAD_ROW } from './constants';
import { PresetsType } from './types';
import { QuizTopicType, ToggleOptions } from 'types';
import PresetTableRow from './PresetTableRow';
import { isFlashcardsPage } from 'utils';

interface PresetsBuilderProps {
  loading: boolean;
  tableRef?: any;
  noCategories?: boolean;
  onSearch: (searchString: string) => void;
  activeCategory: PresetsType;
  prevPresetCategory: PresetsType;
  categoryOptions: ToggleOptions<PresetsType>;
  onToggleCategory: (
    e: SyntheticEvent,
    type: QuizTopicType | PresetsType
  ) => void;
  category: string;
  data: IPreset[] | undefined;
}

const TableContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    borderRadius: '0 0 7px 7px',
    overflow: 'auto',
    border: `1px solid ${palette.stroke.main}`,
    borderTop: 0,
    backgroundColor: palette.background.paper,
    margin: spacing(0, -6),
    width: `calc(100% + ${spacing(12)})`,

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
    },

    [breakpoints.up('xl')]: {
      overflow: 'hidden',
    },
  })
);

const PresetsBuilder = ({
  loading,
  tableRef,
  onSearch,
  activeCategory,
  prevPresetCategory,
  categoryOptions,
  onToggleCategory,
  category,
  data,
}: PresetsBuilderProps) => {
  const { pathname } = useLocation();

  const headerOptions = useMemo(
    () =>
      isFlashcardsPage(pathname)
        ? { ...PRESETS_HEAD_ROW, legendBox: FLASHCARDS_LEGEND }
        : PRESETS_HEAD_ROW,
    [pathname]
  );

  return (
    <>
      <SelectionCategories
        activeCategory={activeCategory}
        categoryOptions={categoryOptions}
        onToggleCategory={onToggleCategory}
      />
      <SelectionHeading
        loading={loading}
        onSearch={onSearch}
        resetSearch={prevPresetCategory !== activeCategory}
        searchLabel="Search by topic or concept..."
        title={category}
      />
      <TableContainer>
        <ActivityHistoryTable
          headerOptions={headerOptions}
          results={data}
          rowComponent={PresetTableRow}
          tableHeight="580"
          tableRef={tableRef}
        />
      </TableContainer>
    </>
  );
};

export default PresetsBuilder;
