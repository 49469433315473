export const unescape = (text: string) => {
  const chars =
    /&(?:amp|uparrow|downarrow|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;

  const map: Record<string, string> = {
    '&amp;': '&',
    '&uparrow;': '↑',
    '&downarrow;': '↓',
    '&#38;': '&',
    '&lt;': '<',
    '&#60;': '<',
    '&gt;': '>',
    '&#62;': '>',
    '&apos;': "'",
    '&#39;': "'",
    '&quot;': '"',
    '&#34;': '"',
  };

  return text.replaceAll(chars, (subString: string) => map[subString]);
};
