import { useCallback, useEffect } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import {
  ACCEPT_OSCE_MATCHMAKING,
  CANCEL_OSCE_MATCHMAKING,
  IAcceptOsceMatchmakingData,
  IAcceptOsceMatchmakingVar,
  ICancelOsceMatchmakingData,
  ICancelOsceMatchmakingVar,
  IStartOsceMatchmakingData,
  IStartOsceMatchmakingVar,
  START_OSCE_MATCHMAKING,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  IOsceMatchmakingData,
  IOsceMatchmakingVar,
  OSCE_MATCHMAKING,
} from '@quesmed/types-rn/resolvers/subscription';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { Nullable } from 'types';
import { StationModal, useStationModalState } from './useStationModalState';
import useMatchmakingLoadingState from './useMatchmakingLoadingState';

const { stations } = paths;
const { root, groupLobby } = stations;

const useMatchmaking = (
  sessionId?: Nullable<string>,
  marksheetId?: number,
  userId?: number
) => {
  const { closeModal, openModal, shownModal } = useStationModalState();
  const { setLoading, loading } = useMatchmakingLoadingState();
  const navigate = useNavigate();
  const { data } = useSubscription<IOsceMatchmakingData, IOsceMatchmakingVar>(
    OSCE_MATCHMAKING,
    {
      variables: {
        userId: userId as number,
      },
      skip: !userId,
    }
  );

  const { osceMatchmaking } = data || {};

  const [startOsceMatchMaking, { loading: startLoading }] = useMutation<
    IStartOsceMatchmakingData,
    IStartOsceMatchmakingVar
  >(START_OSCE_MATCHMAKING, {
    onError: () => {
      setLoading(false);
    },
  });

  const [acceptOsceMatchMaking] = useMutation<
    IAcceptOsceMatchmakingData,
    IAcceptOsceMatchmakingVar
  >(ACCEPT_OSCE_MATCHMAKING, {
    onCompleted: () => {
      setLoading(false);
      closeModal();

      if (osceMatchmaking && osceMatchmaking.sessionId) {
        navigate(`${root}/${groupLobby}?join=${osceMatchmaking.sessionId}`, {
          state: {
            leave: true,
            join: true,
          },
        });
      }
    },
  });

  const [cancelOsceMatchMaking] = useMutation<
    ICancelOsceMatchmakingData,
    ICancelOsceMatchmakingVar
  >(CANCEL_OSCE_MATCHMAKING);

  const acceptMatchmaking = useCallback(() => {
    if (sessionId) {
      acceptOsceMatchMaking({ variables: { sessionId } });
    }
    setLoading(false);
  }, [acceptOsceMatchMaking, sessionId, setLoading]);

  const stopMatchmaking = useCallback(() => {
    if (sessionId && loading) {
      cancelOsceMatchMaking({ variables: { sessionId } });
    }
    setLoading(false);
  }, [sessionId, loading, setLoading, cancelOsceMatchMaking]);

  const startMatchmaking = useCallback(() => {
    if (!loading && sessionId) {
      setLoading(true);
      startOsceMatchMaking({ variables: { sessionId } });
    }
  }, [sessionId, loading, setLoading, startOsceMatchMaking]);

  useEffect(() => {
    if (osceMatchmaking) {
      const {
        sessionId: newSessionId,
        osceMarksheetId: newOsceMarksheetId,
        matchedUsers,
        grouped,
      } = osceMatchmaking;

      if (grouped && !loading) {
        setLoading(true);
      }

      const newMatchedUsers = matchedUsers !== null;

      const currentMatchedTeam =
        sessionId &&
        newSessionId &&
        sessionId !== newSessionId &&
        marksheetId &&
        newOsceMarksheetId &&
        marksheetId !== newOsceMarksheetId;

      if (
        currentMatchedTeam &&
        newMatchedUsers &&
        shownModal !== StationModal.MatchmakingAcceptModal &&
        shownModal !== StationModal.MatchmakingRejectModal
      ) {
        openModal(StationModal.MatchmakingAcceptModal);
      }
    }
  }, [
    openModal,
    sessionId,
    shownModal,
    loading,
    marksheetId,
    osceMatchmaking,
    setLoading,
  ]);

  useEffect(() => {
    return () => {
      if (loading) {
        setLoading(false);
        stopMatchmaking();
      }
    };
  }, [stopMatchmaking, loading, setLoading]);

  return {
    acceptMatchmaking,
    stopMatchmaking,
    loading: startLoading || loading,
    matchedUsers: osceMatchmaking?.matchedUsers ?? 0,
    startMatchmaking,
  };
};

export default useMatchmaking;
