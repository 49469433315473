import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { ChevronRightIcon } from 'components/Icons';
import { Body } from 'components/Typography';

const ListItem = styled(ListItemButton)(
  ({ theme: { palette, transitions, typography, spacing } }) => ({
    padding: spacing(0, 4, 0, 1),
    height: '40px',
    borderRadius: '9px',
    overflow: 'hidden',
    ...typography.body2Medium,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '.MuiListItemIcon-root': {
      width: '32px',
      minWidth: '32px',
    },

    '.MuiSvgIcon-root': {
      transition: transitions.create(['transform']),
      color: palette.icon.main,
    },

    '&.expanded': {
      '.MuiSvgIcon-root': {
        transform: 'rotate(90deg)',
        color: palette.primary.main,
      },
    },
  })
);

const NameBox = styled(Body)({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
});

interface PanelItemNameProps {
  expandable?: boolean;
  expanded: boolean;
  active?: boolean;
  name: string;
  onClick: () => void;
}

const PanelItemName = ({
  active,
  expanded,
  expandable = true,
  name,
  onClick,
}: PanelItemNameProps): JSX.Element => (
  <ListItem className={clsx({ active, expanded })} onClick={onClick}>
    {expandable ? (
      <ListItemIcon>
        <ChevronRightIcon />
      </ListItemIcon>
    ) : null}
    <ListItemText disableTypography primary={<NameBox>{name}</NameBox>} />
  </ListItem>
);

export default PanelItemName;
