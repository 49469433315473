import React from 'react';

import { LabValues, LabValuesButton } from 'components/LabValues';
import { MarkdownTextSizeSlider } from 'components/Markdown';
import { CalculatorButton } from 'components/Calculator';
import { useMockTestStateValues } from '../mockTestsState';
import { ToolBarControls, TopToolBar } from './MockTestsLayoutStyles';

export const MockTestToolBar = () => {
  const { showTools, testTools } = useMockTestStateValues();

  return (
    <TopToolBar>
      {showTools && (
        <ToolBarControls>
          <CalculatorButton label="Calculator" size="small" white />
          <LabValuesButton label="Lab values" size="small" white />
          <MarkdownTextSizeSlider
            label="Scale text"
            showAsPopper
            size="small"
            white
          />
        </ToolBarControls>
      )}
      {showTools && <LabValues />}
      <ToolBarControls>{testTools}</ToolBarControls>
    </TopToolBar>
  );
};
