import {
  IMarksheetMark,
  IPrescribeAnswer,
  IPrescribeMark,
  IQuestionPrescribe,
} from '@quesmed/types-rn/models';
import { correctMark, ICorrectMarkData } from '@quesmed/types-rn/utils';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import PrescriptionExplanation from './PrescriptionExplanation';
import { entries, values } from 'utils';
import PrescriptionForm from './PrescriptionForm';
import {
  PrescriptionField,
  PrescriptionFieldNames,
  PrescriptionOption,
} from '../types';
import { Wrapper } from './PrescriptionAnswer.styles';

const correctMarkData = {
  correct: false,
  incorrect: false,
  correctIndex: -1,
};

// needed as IPrescribeMark will be extended and this helper type is needed
// for saving answer
export type IPrescribeMarkIds = Pick<
  IPrescribeMark,
  'drugId' | 'doseId' | 'routeId' | 'durationId' | 'frequencyId'
>;

export interface PrescriptionAnswerProps {
  isTestFinished: boolean;
  setUserAnswer: Dispatch<SetStateAction<string>>;
  mark?: string;
  currentMark: IMarksheetMark;
  question: IQuestionPrescribe;
}

const parsePrescribeMark = (fields: IPrescribeAnswer, mark?: string) => {
  const initialAnswer = {} as PrescriptionField;

  if (mark) {
    const userAnswer = JSON.parse(mark)[0] as IPrescribeMark;
    entries(userAnswer).forEach(item => {
      const [key = '', value = ''] = item || [];

      if (key.includes('Label')) {
        const answerKey = key.replace('Label', '') as PrescriptionFieldNames;

        initialAnswer[answerKey] = value;
      }
    });

    return initialAnswer;
  }

  entries(fields).forEach(item => {
    const [key, data] = item;
    if (typeof data === 'string') {
      return;
    }

    if (data.visible) {
      initialAnswer[key] = data.label;
    } else {
      initialAnswer[key] = '';
    }
  });

  return initialAnswer;
};

const PrescriptionAnswer = ({
  question,
  currentMark,
  isTestFinished,
  mark,
  setUserAnswer,
}: PrescriptionAnswerProps): JSX.Element => {
  const { prescribeAnswer } = question;
  const [fields] = prescribeAnswer;
  const [isPrescriptionCorrect, setPrescriptionCorrect] =
    useState<ICorrectMarkData>(correctMarkData);
  const methods = useForm();

  const answers = parsePrescribeMark(fields, mark);

  useEffect(() => {
    if (isTestFinished && mark) {
      const [{ __typename, ...parsedUserAnswer }] = JSON.parse(mark);
      const result = correctMark({
        ...currentMark,
        mark: [parsedUserAnswer],
      });
      setPrescriptionCorrect(result);
    } else {
      setPrescriptionCorrect(correctMarkData);
    }
  }, [currentMark, isTestFinished, mark]);

  const { getValues, reset, control, watch } = useForm<PrescriptionField>({
    defaultValues: answers,
  });

  useEffect(() => {
    if (!mark) {
      const initialUserAnswer = {} as IPrescribeMark;

      entries(fields)
        .filter(([, data]) => data.visible)
        .forEach(([key, data]) => {
          initialUserAnswer[`${key}Id`] = data.value;
        });

      setUserAnswer(JSON.stringify([initialUserAnswer]));
    }
  }, [fields, setUserAnswer, mark]);

  useEffect(() => {
    if (mark) {
      const parsedMark = JSON.parse(mark) as IPrescribeMark;

      const userAnswer = {} as IPrescribeMark;

      entries(parsedMark)
        .filter(item => {
          const [key = ''] = item || [];

          return key.includes('Id');
        })
        .forEach(item => {
          const [key = '', value = 0] = item || [];
          if (key && value) {
            userAnswer[key as keyof IPrescribeMarkIds] = Number(value);
          }
        });

      if (values(userAnswer).length) {
        setUserAnswer(JSON.stringify([userAnswer]));
      }
    }
  }, [setUserAnswer, mark]);

  useEffect(() => {
    reset(answers);
    // On mark/question change only form values have to be reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMark]);

  useEffect(() => {
    const subscription = watch((data, action) => {
      const { name, type } = action;

      if (name && type === 'change') {
        const value = data[
          name as unknown as keyof PrescriptionField
        ] as PrescriptionOption;
        const userAnswer = {} as IPrescribeMark;

        if (mark) {
          const parsedMark = JSON.parse(mark)[0] as IPrescribeMark;

          entries(parsedMark)
            .filter(item => {
              const [key = ''] = item || [];

              return key.includes('Id');
            })
            .forEach(item => {
              const [key = '', value = 0] = item || [];
              if (key && value) {
                userAnswer[key as keyof IPrescribeMarkIds] = Number(value);
              }
            });
        } else {
          entries(fields)
            .filter(([, data]) => data.visible)
            .forEach(([key, data]) => {
              userAnswer[`${key}Id`] = data.value;
            });
        }

        setUserAnswer(prev => {
          const previousAnswer = prev ? JSON.parse(prev)[0] : '';

          return JSON.stringify([
            {
              ...userAnswer,
              ...previousAnswer,
              [`${name}Id`]: value?.value,
            },
          ]);
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [fields, getValues, mark, setUserAnswer, watch]);

  const answersResult = Object.entries(answers).reduce((acc, [key, value]) => {
    return { ...acc, [key]: { value } };
  }, {} as IPrescribeAnswer);

  const { correct } = isPrescriptionCorrect;

  return (
    <Wrapper>
      {isTestFinished ? (
        <FormProvider {...methods}>
          <PrescriptionForm
            answer={answersResult}
            control={control}
            correct={correct}
            defaultExpanded
            questionId={question.id}
            title={`${answersResult.drug.value} (your answer)`}
          />
          <PrescriptionExplanation question={question} />
        </FormProvider>
      ) : (
        <PrescriptionForm
          answer={fields}
          control={control}
          defaultExpanded
          questionId={question.id}
          title="Prescription form"
        />
      )}
    </Wrapper>
  );
};

export default PrescriptionAnswer;
