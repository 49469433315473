import React from 'react';

import { TableData } from 'types/general';
import SelectionRow from './SelectionRow';
import SelectionTableHead from './SelectionTableHead';
import { ClickHandler, SelectionColumn, SelectItemHandler } from './types';

export interface SelectionColumnDataProps<T extends TableData> {
  data: T;
  locked: boolean | undefined;
  globalLock?: boolean;
  handleOpenModal?: (subItemId: number) => ClickHandler;
  onSelectSubRow: (subItemId: number) => SelectItemHandler;
  nestedItemsKey: keyof T;
  selectedSubitems?: Set<number>;
  withOffset?: boolean;
  columnsHeader: SelectionColumn<T>[];
  columnsData: SelectionColumn<T>[];
}

const SelectionColumnData = <T extends TableData>({
  columnsHeader,
  columnsData,
  globalLock,
  data,
  handleOpenModal,
  locked,
  nestedItemsKey,
  onSelectSubRow,
  selectedSubitems,
  withOffset,
}: SelectionColumnDataProps<T>): JSX.Element => (
  <>
    <SelectionTableHead
      columns={columnsHeader}
      nested
      nestedLabel={nestedItemsKey}
      withOffset={withOffset}
    />
    {data[nestedItemsKey]?.map((subItem: TableData[keyof TableData]) => (
      <SelectionRow
        checked={selectedSubitems?.has(Number(subItem.id))}
        columns={columnsData}
        data={subItem}
        globalLock={globalLock}
        handleOpenModal={
          handleOpenModal && handleOpenModal(Number(subItem.concepts[0]?.id))
        }
        key={subItem.id}
        locked={locked}
        onSelect={onSelectSubRow(Number(subItem.id))}
        parentId={data.id}
        withOffset={withOffset}
      />
    ))}
  </>
);

export default SelectionColumnData;
