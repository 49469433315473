import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_FLAGGED_QUESTION,
  IAddFlaggedQuestionData,
  IAddFlaggedQuestionVar,
  IRemoveFlaggedQuestionData,
  IRemoveFlaggedQuestionVar,
  optimisticAddFlaggedQuestion,
  optimisticRemoveFlaggedQuestion,
  REMOVE_FLAGGED_QUESTION,
  updateCacheAddFlaggedQuestion,
  updateCacheRemoveFlaggedQuestion,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  FLAGGED_QUESTIONS,
  IFlaggedQuestionsData,
  IFlaggedQuestionsVar,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

import { useAuth } from 'Auth';
import { randomId } from 'utils';

export const useFlagQuestionQuery = (limit = 3, offset = 0) => {
  const { isAuthenticated } = useAuth();
  const {
    data,
    loading: flaggedQuestionsLoading,
    error: flaggedQuestionError,
  } = useQuery<IFlaggedQuestionsData, IFlaggedQuestionsVar>(FLAGGED_QUESTIONS, {
    skip: !isAuthenticated,
    variables: { filter: { limit, offset } },
    fetchPolicy: 'cache-and-network',
  });

  const { results, total } = data?.restricted.flaggedQuestions || {};

  return {
    flaggedQuestionsLoading,
    flaggedQuestions: results,
    flaggedQuestionError,
    total,
  };
};

interface FlagQuestionParams {
  markId: number;
  marksheetId: number;
  questionId: number;
}

export const useFlagQuestion = (params: FlagQuestionParams) => {
  const { markId, questionId, marksheetId } = params;

  const [flagQuestion, { loading: loadingFlagQuestion }] = useMutation<
    IAddFlaggedQuestionData,
    IAddFlaggedQuestionVar
  >(ADD_FLAGGED_QUESTION, {
    update: updateCacheAddFlaggedQuestion,
  });

  const [removeFlaggedQuestion, { loading: loadingRemoveFlaggedQuestion }] =
    useMutation<IRemoveFlaggedQuestionData, IRemoveFlaggedQuestionVar>(
      REMOVE_FLAGGED_QUESTION,
      {
        update: updateCacheRemoveFlaggedQuestion,
      }
    );

  const handleFlagQuestion = useCallback(
    async () =>
      flagQuestion({
        variables: {
          marksheetId: Number(marksheetId),
          markId,
          questionId: Number(questionId),
        },
        optimisticResponse: optimisticAddFlaggedQuestion(randomId(8), markId),
      }),
    [flagQuestion, marksheetId, markId, questionId]
  );

  const handleUnflagQuestion = useCallback(
    async () =>
      removeFlaggedQuestion({
        variables: {
          questionId: Number(questionId),
          marksheetId: Number(marksheetId),
        },
        optimisticResponse: optimisticRemoveFlaggedQuestion(
          randomId(8),
          markId
        ),
      }),
    [removeFlaggedQuestion, questionId, marksheetId, markId]
  );

  const flagQuestionLoading =
    loadingFlagQuestion || loadingRemoveFlaggedQuestion;

  const toggleFlagQuestion = useCallback(
    async (isFlagged: boolean) => {
      if (flagQuestionLoading) {
        return;
      }
      if (isFlagged) {
        return handleUnflagQuestion();
      }

      return handleFlagQuestion();
    },
    [handleFlagQuestion, handleUnflagQuestion, flagQuestionLoading]
  );

  return {
    handleFlagQuestion,
    loadingFlagQuestion,
    handleRemoveFlaggedQuestion: handleUnflagQuestion,
    loadingRemoveFlaggedQuestion,
    flagQuestionLoading,
    toggleFlagQuestion,
  };
};
export default useFlagQuestion;
