import { useQuery } from '@apollo/client';
import { IClassYear } from '@quesmed/types-rn/models';
import {
  IUserStatsData,
  IUserStatsVar,
  USER_STATS,
} from '@quesmed/types-rn/resolvers/query/restricted';

const usePerformanceChartQuery = (
  withArgs: boolean,
  classYear: IClassYear,
  universityId: number
) => {
  const { data, loading } = useQuery<IUserStatsData, IUserStatsVar>(
    USER_STATS,
    {
      variables: withArgs
        ? {
            classYear: classYear,
            universityId: universityId,
          }
        : {},
      fetchPolicy: 'cache-and-network',
    }
  );

  const { userStats } = data?.restricted || {};

  return { performanceChartData: userStats, loading };
};

export default usePerformanceChartQuery;
