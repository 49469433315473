import React from 'react';
import { mdiTextBoxOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const TextIcon = (props: IconProps) => (
  <Icon path={mdiTextBoxOutline} {...props} />
);

export default TextIcon;
