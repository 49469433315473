export const OSCEHEADROW = {
  initial: 'Date',
  exercise: 'Station Names',
  legendBox: ['Performed', 'Not Performed'],
  type: 'My Role',
  actions: 'Actions',
};

export const QBANKHEADROW = {
  initial: 'Date',
  exercise: 'Preset Name',
  legendBox: ['Correct', 'Incorrect', 'Not Answered'],
  type: 'Preset Type',
  actions: 'Actions',
};
