import { EPlatformId } from '@quesmed/types-rn';
import { useEffect } from 'react';

import { useAgoraSetState } from './AgoraState';

const useSetAgoraControlProps = (
  platform: EPlatformId,
  initSession: boolean,
  initAudio: boolean,
  initVideo: boolean,
  inProgress: boolean,
  userId?: number,
  channelId?: string | null
) => {
  const {
    setInitSession,
    setInitAudio,
    setInitVideo,
    setChannelId,
    setUserId,
    setPreview,
    setPlatform,
  } = useAgoraSetState();

  useEffect(() => {
    if (userId) {
      setUserId(Number(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (channelId) {
      setChannelId(channelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    if (platform !== undefined) {
      setPlatform(platform);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    setInitSession(initSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSession]);

  useEffect(() => {
    setInitAudio(initAudio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAudio]);

  useEffect(() => {
    setPreview(initVideo && !inProgress);
    setInitVideo(initVideo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, initVideo]);
};

export default useSetAgoraControlProps;
