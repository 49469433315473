import React from 'react';
import { mdiEyeOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const PreviewIcon = (props: IconProps) => (
  <Icon path={mdiEyeOutline} {...props} />
);

export default PreviewIcon;
