import { ExerciseProgressStatus, FlashcardScore, Nullable } from 'types';
import { FLASHCARD_SCORES } from 'config/constants';

export const getScoreLabel = (
  score: Nullable<FlashcardScore>
): string | undefined => {
  if (score) {
    return FLASHCARD_SCORES.find(scoreData => scoreData.score === score)?.label;
  }

  return ExerciseProgressStatus.NotAnswered;
};
