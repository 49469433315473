import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { setProductPeriod } from 'utils';
import { Nullable } from 'types';

const Tabs = styled(Box)(({ theme: { palette, spacing, breakpoints } }) => ({
  width: ' 100%',
  borderRadius: '6px',
  display: 'flex',
  backgroundColor: palette.toggle.light,
  padding: spacing(1),

  [breakpoints.up('md')]: {
    maxWidth: '410px',
  },
}));

const TabItem = styled(Box)(({ theme: { palette, typography, spacing } }) => ({
  width: ' 100%',
  maxWidth: '410px',
  borderRadius: '6px',
  padding: spacing(3),
  ...typography.button,
  textAlign: 'center',
  cursor: 'pointer',
  color: palette.text.disabled,

  '&.active': {
    backgroundColor: palette.background.paper,
    color: palette.text.primary,
  },
}));

interface ProductDurationTabsProps {
  selectItem: (value: number) => void;
  selectedItem?: Nullable<number>;
  items: number[];
  valueFormatter?: (value: number) => string;
}

const ProductDurationTabs = ({
  items,
  selectItem,
  selectedItem,
  valueFormatter = setProductPeriod,
}: ProductDurationTabsProps) => {
  const handleSelect = (item: number) => () => {
    selectItem(item);
  };

  return (
    <Tabs>
      {items.map(item => {
        const itemId = Number(item);

        return (
          <TabItem
            className={clsx({ active: itemId === selectedItem })}
            key={itemId}
            onClick={handleSelect(itemId)}
          >
            {valueFormatter ? valueFormatter(itemId) : itemId}
          </TabItem>
        );
      })}
    </Tabs>
  );
};

export default ProductDurationTabs;
