import React, { useMemo } from 'react';
import {
  EEntitlementType,
  EQuestionType,
  IQuestion,
} from '@quesmed/types-rn/models';

import { uniqueArrayObjects } from 'utils';
import { KnowledgeLibraryIcon } from 'components/Icons';
import { Lightbox } from 'components/Lightbox';
import { QuestionLike } from 'components/QuestionLike';
import { useQuestionLikeMutation } from 'components/Likes/useQuestionLikeMutation';
import { Comments } from 'components/Comments';
import { Separator } from 'components/Separator';
import { QbankConceptReading } from 'pages/KnowledgeLibrary';
import { ExpandableSection } from 'components/ExpandableSection';
import { QuestionAdditionalContent } from 'components/Layout';
import { useUpsertChapterNote } from 'pages/KnowledgeLibrary/hooks';
import LearningPoints from 'components/LearningPoints/LearningPoints';
import locales from 'locales';
import { useSnackbar } from 'components/Snackbar';
import { QuestionTags } from 'components/QuestionTags';

export interface QuestionRelatedContentProps {
  question: IQuestion;
  marksheetId: number;
  showAnswers: boolean;
  questionType: EQuestionType;
  entitlementId?: EEntitlementType;
  isSample?: boolean;
  expandedReading?: boolean;
}

const QuestionRelatedContent = ({
  entitlementId,
  question,
  marksheetId,
  showAnswers,
  questionType,
  isSample = false,
  expandedReading,
}: QuestionRelatedContentProps): JSX.Element => {
  const { handleLikeQuestion, likes, dislikes } = useQuestionLikeMutation(
    question,
    marksheetId
  );
  const upsertNote = useUpsertChapterNote();
  const showRelatedContent =
    questionType !== EQuestionType.RANKING_ANSWER &&
    questionType !== EQuestionType.SELECT_THREE_ANSWER;

  const {
    concept,
    conceptId,
    isLikedByMe,
    pictures = [],
    learningPoint,
    id: questionId,
    typeId,
    userPoint,
  } = question;

  const {
    chapter,
    name: conceptName = '',
    status,
    topic,
    videos,
    userNote,
    id,
  } = concept || {};
  const {
    pictures: chapterPictures = [],
    explanation,
    id: chapterId,
    highlights,
  } = chapter || {};
  const { name: topicName = 'Topic name', id: topicId } = topic || {};
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  const handleSaveNotes = (value: string) => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      upsertNote(value, Number(conceptId || id), Number(marksheetId));
    }
  };

  const images = useMemo(
    () =>
      uniqueArrayObjects(
        (pictures || []).concat(showAnswers ? chapterPictures : []),
        'id'
      ),
    [pictures, showAnswers, chapterPictures]
  );

  return (
    <>
      <Lightbox pictures={images} showCaptions={showAnswers} />
      {showAnswers ? (
        <>
          <QuestionTags question={question} />
          <QuestionLike
            dislikes={dislikes}
            handleLike={handleLikeQuestion}
            isLikedByMe={isLikedByMe}
            isSample={isSample}
            likes={likes}
          />
          <Separator title="Learn more ↓" />
          <QuestionAdditionalContent>
            {learningPoint ? (
              <LearningPoints
                isSample={isSample}
                learningPoint={learningPoint}
                marksheetId={Number(marksheetId)}
                questionId={Number(questionId)}
                questionType={typeId}
                userPoint={userPoint}
              />
            ) : null}
            <Comments
              isSample={isSample}
              marksheetId={Number(marksheetId)}
              question={question}
            />
            {showRelatedContent ? (
              <ExpandableSection
                expanded={showAnswers}
                icon={KnowledgeLibraryIcon}
                title={`${topicName}: ${conceptName}`}
              >
                <QbankConceptReading
                  chapterHighlights={highlights}
                  chapterId={Number(chapterId)}
                  conceptId={Number(conceptId)}
                  conceptName={conceptName}
                  entitlementId={entitlementId}
                  expandedReading={expandedReading}
                  explanation={explanation}
                  hideExerciseInvitation
                  hideFlashcardInvitation
                  isSample={isSample}
                  marksheetId={Number(marksheetId)}
                  nested
                  onSaveNotes={handleSaveNotes}
                  pictures={chapterPictures}
                  showAll
                  status={status}
                  topicId={topicId}
                  topicName={topicName}
                  userNote={userNote}
                  videos={videos}
                  withQuestionImage
                />
              </ExpandableSection>
            ) : null}
          </QuestionAdditionalContent>
        </>
      ) : null}
    </>
  );
};

export default QuestionRelatedContent;
