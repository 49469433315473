import React from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { IUserSettings } from '@quesmed/types-rn/models';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Modal } from 'components/Modal';
import { DataPickerField } from 'components/TextField';
import { parseDate } from 'utils';
import { useUpdateUserSettings } from 'hooks';

interface ExamCountdownModalProps {
  examDate?: IUserSettings['productSettings']['examDate'];
  onClose: () => void;
  open: boolean;
}

const DatePicker = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
}));

const schema = Joi.object<IUserSettings['productSettings']>({
  examDate: Joi.date(),
});

const ExamCountdownModal = ({
  examDate,
  onClose,
  open,
}: ExamCountdownModalProps) => {
  const { loading, updateUserSettings } = useUpdateUserSettings();

  const setDefaultDate = (
    examDate?: IUserSettings['productSettings']['examDate']
  ) => {
    if (examDate instanceof Date) {
      return examDate;
    } else if (typeof examDate === 'number') {
      return new Date(parseDate(examDate));
    }
  };
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm<IUserSettings['productSettings']>({
    defaultValues: {
      examDate: setDefaultDate(examDate),
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const updateExamDate = (data: IUserSettings['productSettings']) => {
    updateUserSettings(
      {
        productSettings: {
          examDate: dayjs(new Date(data?.examDate as Date)).unix(),
        },
      },
      {
        successCallback: () => {
          reset(data);
          onClose();
        },
        successMsg: 'Updated successfully.',
      }
    );
  };

  return (
    <Modal
      noPaddingY
      onClose={onClose}
      onSubmit={handleSubmit(updateExamDate)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={!isValid || !isDirty}
      submitLabel="Edit exam date"
      submitLoading={loading}
      title="Edit exam date"
    >
      <DatePicker>
        <DataPickerField
          control={control}
          inputProps={{
            fullWidth: true,
          }}
          name="examDate"
        />
      </DatePicker>
    </Modal>
  );
};

export default ExamCountdownModal;
