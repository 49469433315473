import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import isNil from 'lodash/isNil';
import { useSearchBox } from 'react-instantsearch';
import { EProductType, IConcept } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialItem,
  PacesKnowledgeLibraryState,
} from 'components/LearningMaterials';
import { Nullable, ToggleEvent } from 'types';
import { usePrevious } from 'hooks/usePrevious';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { TopicHit, useSearchResults } from 'components/Search';
import { usePaceKnowledgeLibraryQuery } from 'components/LearningMaterials/hooks/usePaceKnowledgeLibraryQuery';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const { knowledgeLibrary } = paths;

export const usePacesKnowledgeLibraryData = () => {
  return useOutletContext<PacesKnowledgeLibraryState>();
};

const setSearchConcepts = (
  hits: TopicHit[],
  concepts: Nullable<IConcept[]>
): Nullable<LearningMaterialItem[]> => {
  if (!concepts) {
    return null;
  }

  const conceptIds = new Set<number>();
  const conceptOrder: number[] = [];

  hits.forEach(({ conceptId }) => {
    const validConceptId = Number(conceptId);
    conceptIds.add(validConceptId);
    conceptOrder.push(validConceptId);
  });

  return concepts
    .filter(({ id }) => conceptIds.has(Number(id)))
    .sort((a, b) => {
      const { id: aId } = a as IConcept;
      const { id: bId } = b as IConcept;

      return (
        conceptOrder.indexOf(Number(aId)) - conceptOrder.indexOf(Number(bId))
      );
    });
};

const useSearchConcepts = (concepts: Nullable<IConcept[]>) => {
  const results = useSearchResults<TopicHit[]>();

  return results ? setSearchConcepts(results, concepts) : null;
};

const PacesKnowledgeLibrary = (): JSX.Element => {
  const { loading, concepts, conceptsMap, categories } =
    usePaceKnowledgeLibraryQuery();
  const { refine, clear, query } = useSearchBox();
  const navigate = useNavigate();
  const { sectionId: categoryId, entitlementId } =
    useParams<KnowledgeLibraryRouterParams>();
  const [activeCategory, setActiveCategory] = useState(
    entitlementId ? Number(entitlementId) : categories[0]?.value
  );
  const { chapterId } = useParams<KnowledgeLibraryRouterParams>();

  const previousCategory = usePrevious(activeCategory);
  const previousTopicId = usePrevious(categoryId);
  const prevChapterId = usePrevious(chapterId);
  const [displayConcepts, setDisplayConcepts] =
    useState<Nullable<IConcept[]>>(null);
  const searchItems = useSearchConcepts(concepts);

  const resetSearch =
    (Boolean(displayConcepts) && previousCategory !== activeCategory) ||
    previousTopicId !== categoryId ||
    prevChapterId !== chapterId;

  useEffect(() => {
    if (resetSearch || !displayConcepts) {
      clear();
      setDisplayConcepts(conceptsMap ? conceptsMap[activeCategory] : null);
    }
  }, [activeCategory, clear, displayConcepts, resetSearch, conceptsMap]);

  useEffect(() => {
    if (activeCategory) {
      setDisplayConcepts(conceptsMap ? conceptsMap[activeCategory] : null);
    }
  }, [activeCategory, displayConcepts, conceptsMap]);

  useEffect(() => {
    if (!entitlementId) {
      const [category] = categories || [];
      const { value } = category || {};

      if (!isNil(value)) {
        navigate(`${knowledgeLibrary.root}/section/${value}/${value}`);
      }
    } else {
      setActiveCategory(Number(entitlementId));
    }
  }, [activeCategory, categories, navigate, entitlementId]);

  const handleSearch = useCallback(
    (searchString: string) => {
      refine(searchString);
    },
    [refine]
  );

  const handleActiveCategory = useCallback(
    (_: ToggleEvent, type: EProductType) => {
      navigate(`${knowledgeLibrary.root}/section/${type}/${type}`);
    },
    [navigate]
  );

  const allConcepts = useMemo(() => {
    return concepts;
  }, [concepts]);

  const activeCategoryLabel =
    categories.find(({ value }) => value === activeCategory)?.label || '';

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!displayConcepts) {
    return <ErrorMessage />;
  }

  return (
    <Outlet
      context={{
        activeCategory,
        activeCategoryLabel,
        categories,
        allConcepts,
        concepts: displayConcepts,
        loading,
        resetSearch,
        search: handleSearch,
        searchTerm: query,
        searchItems,
        searchBy: 'concepts',
        switchCategory: handleActiveCategory,
      }}
    />
  );
};

export default PacesKnowledgeLibrary;
