import React, { useCallback, useEffect, useState } from 'react';
import { EOsceRoles, IOsceMarksheetMember } from '@quesmed/types-rn/models';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { Modal } from 'components/Modal';
import { STATION_ROLES } from 'constants/stations';
import { ButtonOnClickHandler, Nullable } from 'types';
import { CardButton } from 'components/Button/index';
import {
  CheckboxMarkedCircleOutlineIcon,
  RadioBoxBlankIcon,
  RadioBoxMarkedIcon,
} from 'components/Icons';
import { constSize } from 'utils';
import { useStationRoles } from './hooks';

const outlinedClass = '.MuiLoadingButton-root.MuiButton-outlinedSecondary';

const RolesContainer = styled(Box)(
  ({ theme: { palette, spacing, typography, breakpoints } }) => ({
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(4),
    ...typography.body1Medium,
    color: palette.text.primary,
    backgroundColor: palette.background.paper,

    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
    },

    [`& ${outlinedClass}`]: {
      width: '100%',
      maxWidth: 'initial',
      minWidth: 'auto',

      [breakpoints.up('md')]: {
        minWidth: spacing(36),
      },

      '& .icon-box': {
        '.MuiSvgIcon-root': {
          ...constSize('17px'),
        },
      },
    },

    [`& ${outlinedClass}.selected:not(.MuiLoadingButton-loading)`]: {
      border: `1px solid ${palette.primary.main}`,

      '&:hover': {
        border: `1px solid ${palette.primary.main}`,
      },

      '& .icon-box': {
        '.MuiSvgIcon-root': {
          color: palette.primary.main,
        },
      },
    },

    [`& ${outlinedClass}.choosen:not(.MuiLoadingButton-loading)`]: {
      color: palette.text.secondary,
      border: `1px solid ${palette.success.dark}`,
      backgroundColor: palette.success.extraLight,

      '&:hover': {
        border: `1px solid ${palette.success.dark}`,
      },

      '& .icon-box': {
        '.MuiSvgIcon-root': {
          color: palette.success.dark,
        },
      },
    },

    [`& ${outlinedClass}.Mui-disabled`]: {
      color: palette.text.primary,
    },
  })
);

const IconBox = styled(Box)(({ theme: { spacing } }) => ({
  position: 'absolute',
  top: spacing(3),
  right: spacing(3),
}));

const RoleContent = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(2.5),
}));

const getRoleCheckIcon = (choosen: boolean, selected: boolean): JSX.Element => {
  if (selected) {
    return <RadioBoxMarkedIcon />;
  }

  if (choosen) {
    return <CheckboxMarkedCircleOutlineIcon />;
  }

  return <RadioBoxBlankIcon />;
};

export interface StationRolesModalProps {
  open: boolean;
  sessionId: string;
  members: IOsceMarksheetMember[];
  onClose: () => void;
  roleAssigned: boolean;
  inviteLink: string;
}

const StationRolesModal = ({
  open,
  sessionId,
  members,
  onClose,
  roleAssigned,
}: StationRolesModalProps): JSX.Element => {
  const [selectedRole, setSelectedRole] = useState<Nullable<EOsceRoles>>(null);
  const { assignRole, loading } = useStationRoles(sessionId, members);

  useEffect(() => {
    if (!open) {
      setSelectedRole(null);
    }
  }, [open]);

  const handleAssignRole = useCallback(() => {
    assignRole(selectedRole);
    onClose();
  }, [assignRole, onClose, selectedRole]);

  const handleSelectRole = useCallback(
    (role: Nullable<EOsceRoles>): ButtonOnClickHandler =>
      () => {
        if (role) {
          setSelectedRole(role);
        }
      },
    []
  );

  return (
    <Modal
      dialogSx={{ width: 'fit-content', margin: 'auto' }}
      maxWidth="md"
      noPaddingY
      onClose={roleAssigned ? onClose : handleAssignRole}
      onSubmit={handleAssignRole}
      open={open}
      showCloseButton={roleAssigned}
      submitLabel="Confirm"
      submitLoading={loading}
      title="Select your role"
      wrapperSx={{ width: 'fit-content' }}
    >
      <RolesContainer>
        <Grid container spacing={4}>
          {members.map(({ role, user }) => {
            const { icon = null, name = '' } = role ? STATION_ROLES[role] : {};
            const choosen = Boolean(user);
            const selected = role === selectedRole;

            return (
              <Grid item key={role} md={3} sm={6} xs={12}>
                <CardButton
                  className={clsx('role', {
                    selected,
                    choosen,
                  })}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  disabled={choosen}
                  key={role}
                  onClick={handleSelectRole(role)}
                >
                  <RoleContent>
                    {icon}
                    {name}
                  </RoleContent>
                  <IconBox className="icon-box">
                    {getRoleCheckIcon(choosen, selected)}
                  </IconBox>
                </CardButton>
              </Grid>
            );
          })}
        </Grid>
      </RolesContainer>
    </Modal>
  );
};

export default StationRolesModal;
