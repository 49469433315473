import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { ExerciseMode, ExerciseType } from 'types';
import {
  ExerciseModeLabel,
  InfoLabel,
  InfoValue,
  MainHeader,
  MainHeaderContainer,
  Separator,
  StyledBox,
} from './ExercisePreBuildHeaderStyle';
import { AutorenewIcon, NoteEditIcon } from 'components/Icons';
import { paths } from 'Router';

const { questions, stations, practiceMaterials } = paths;

interface IExerciePreBuildHeaderProps {
  mainHeader?: string;
  notesButton?: boolean;
  exerciseType?: ExerciseType;
  exerciseMode?: ExerciseMode;
  icon?: React.ReactNode;
  buttonLabel?: string;
  saveAsPreset?: () => void;
}

const ExerciePreBuildHeader = ({
  mainHeader,
  notesButton,
  buttonLabel = 'load preset',
  exerciseType,
  exerciseMode,
  icon,
  saveAsPreset,
}: IExerciePreBuildHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const hideLobbyLink =
    exerciseType === ExerciseType.Flashcards ||
    exerciseType === ExerciseType.MockTests ||
    exerciseMode === ExerciseMode.Group;

  const handleNavigateToGroup = () => {
    navigate(
      exerciseType === ExerciseType.Stations
        ? `${stations.root}/group`
        : `${questions.root}/group`
    );
  };

  const handleNavigateToMyNotes = () => {
    navigate(`${practiceMaterials.root}/${practiceMaterials.myNotes}`);
  };

  return exerciseType === ExerciseType.QuestionsMLA ? (
    <StyledBox>
      <Box>
        <MainHeader>{mainHeader}</MainHeader>
      </Box>
    </StyledBox>
  ) : (
    <StyledBox>
      <Box sx={{ width: '100%' }}>
        <MainHeaderContainer>
          {mainHeader ? <MainHeader>{mainHeader}</MainHeader> : null}
          {notesButton ? (
            <Button
              color="secondary"
              onClick={handleNavigateToMyNotes}
              secondary
            >
              <NoteEditIcon />
              My Notes
            </Button>
          ) : null}
        </MainHeaderContainer>
        {exerciseType && exerciseMode && icon ? (
          <Box className="info">
            <InfoLabel>Chosen exercise:</InfoLabel>
            <InfoValue>
              {exerciseType} <Separator>|</Separator> {icon}
              <ExerciseModeLabel>{exerciseMode}</ExerciseModeLabel>
            </InfoValue>
            {hideLobbyLink ? null : (
              <Button onClick={handleNavigateToGroup} size="small" tertiary>
                <AutorenewIcon />
                Change to Group Study
              </Button>
            )}
          </Box>
        ) : null}
      </Box>
      {saveAsPreset ? (
        <Button onClick={saveAsPreset}>{buttonLabel}</Button>
      ) : null}
    </StyledBox>
  );
};
export default ExerciePreBuildHeader;
