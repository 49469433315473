import React from 'react';
import { mdiSelectionRemove } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RemoveSelectionIcon = (props: IconProps) => (
  <Icon path={mdiSelectionRemove} {...props} />
);

export default RemoveSelectionIcon;
