import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { EOsceStage } from '@quesmed/types-rn/models';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';

import InfoStatus from './InfoStatus';
import Markscheme, { MarkSchemeProp } from './Markscheme';
import {
  OsceKnowledgeLibraryStation,
  parseOsceReading,
  parseQbankReading,
} from 'components/LearningMaterials';
import { ContentHeader } from 'components/ContentHeader';
import { CollapseVerticalIcon, ExpandVerticalIcon } from 'components/Icons';
import StationTabBriefs from './StationTabBriefs';
import { Lightbox } from 'components/Lightbox';
import { QuestionContainer } from 'components/QuestionContainer';
import { Nullable } from 'types';

const TabContent = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  '& > .MuiBox-root': {
    marginBottom: spacing(4),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

interface StudyTabsContentProps extends MarkSchemeProp {
  station: OsceKnowledgeLibraryStation;
  currentTab: string;
  isCandidate: boolean;
  runningStep: EOsceStage;
  setHasError: (val: boolean) => void;
  solo: boolean;
  isSample?: boolean;
}

const StationTabsContent = ({
  control,
  currentTab,
  feedback,
  isCandidate,
  isCompleted,
  isExaminer,
  isPaces,
  isSample,
  loading,
  marks,
  notStarted,
  runningStep,
  stationType,
  solo,
  station,
  setFeedback,
}: StudyTabsContentProps): JSX.Element => {
  const [expandedAll, setExpandedAll] = useState<boolean>(true);
  const showMarkScheme = !isCandidate || solo || isCompleted;
  const showContent = !isCandidate || solo || isCompleted;
  const stationRef = useRef<Nullable<HTMLDivElement>>(null);

  const message =
    runningStep === EOsceStage.FEEDBACK || !isCompleted
      ? `Please wait while the examiner finishes grading ${
          isCandidate ? 'your' : 'the'
        } performance`
      : 'Let the Candidate do the work';

  const [parsedBriefs, pictures] = useMemo(() => {
    const [briefs, , pictures] = parseOsceReading(station);

    const parsedBriefs = briefs.map(({ content, ...rest }) => ({
      content: parseQbankReading(content),
      ...rest,
    }));

    return [parsedBriefs, pictures];
  }, [station]);

  useEffect(() => {
    if (stationRef.current) {
      stationRef.current.scrollIntoView(true);
    }
  }, [currentTab]);

  const handleExpandAll = () => setExpandedAll(true);

  const handleCollapseAll = () => setExpandedAll(false);

  return (
    <QuestionContainer ref={stationRef}>
      {parsedBriefs.length ? (
        parsedBriefs.map(
          ({ title, content, pictures }) =>
            title.includes(currentTab) && (
              <TabContent key={title}>
                <ContentHeader
                  buttonIcon={
                    expandedAll ? CollapseVerticalIcon : ExpandVerticalIcon
                  }
                  buttonText={expandedAll ? 'Hide All' : 'Show All'}
                  onButtonClick={
                    expandedAll ? handleCollapseAll : handleExpandAll
                  }
                  scallable
                  title={title}
                />
                {(showContent || isCandidate) && content?.length ? (
                  <StationTabBriefs
                    content={content}
                    expandedAll={expandedAll}
                    pictures={pictures}
                  />
                ) : showMarkScheme ? (
                  <Markscheme
                    control={control}
                    expandedAll={expandedAll}
                    feedback={feedback}
                    isCompleted={isCompleted}
                    isExaminer={isExaminer}
                    isPaces={isPaces}
                    isSample={isSample}
                    loading={loading}
                    marks={marks}
                    notStarted={notStarted}
                    setFeedback={setFeedback}
                    stationType={stationType}
                  />
                ) : (
                  <InfoStatus>{message}</InfoStatus>
                )}
              </TabContent>
            )
        )
      ) : (
        <InfoStatus>There is no brief for this station</InfoStatus>
      )}
      <Lightbox
        pictures={pictures}
        showCaptions={!isCandidate || isCompleted}
      />
    </QuestionContainer>
  );
};

export default memo(StationTabsContent);
