import { IPreset } from '@quesmed/types-rn/models';

import { ExtendedTopic, Nullable, SharedSelection } from 'types';

export const editSelection = (
  preset: IPreset,
  activeTopics: Nullable<ExtendedTopic[]>
) => {
  const sharedSelection: SharedSelection = {};
  preset.concepts.forEach(({ id, topicId }) => {
    if (sharedSelection[topicId]) {
      sharedSelection[topicId].conceptsSelected.push(Number(id));
    } else {
      sharedSelection[topicId] = {
        conceptsSelected: [Number(id)],
        conceptsAvailable:
          activeTopics?.find(({ id }) => Number(topicId) === Number(id))
            ?.concepts?.length || 0,
      };
    }
  });

  return JSON.stringify(sharedSelection);
};
