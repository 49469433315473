import { memo } from 'react';
import { useQuery } from '@apollo/client';
import {
  HEARTBEAT_QBANK,
  IQBankHeartBeatData,
  IQbankHeartbeatVar,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { Nullable } from 'types';

interface HeartbeatProps {
  sessionId?: Nullable<string>;
}

const Heartbeat = ({ sessionId }: HeartbeatProps): null => {
  useQuery<IQBankHeartBeatData, IQbankHeartbeatVar>(HEARTBEAT_QBANK, {
    variables: {
      sessionId: sessionId as string,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 2000,
    fetchPolicy: 'no-cache',
    skip: !sessionId,
  });

  return null;
};

export default memo(Heartbeat);
