import { EProductType } from '@quesmed/types-rn/models';

import { Nullable } from 'types/index';
import { isStationsProduct } from 'utils/index';

interface IToolTipBody {
  after: any[];
  before: any[];
  lines: string[];
}

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.className += 'tooltip-wrapper';

    const table = document.createElement('table');
    table.className += 'tooltip-table';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: any,
  product?: Nullable<EProductType>
) => {
  const { chart, tooltip } = context;

  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;

    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: IToolTipBody) => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach((title: string) => {
      const tr = document.createElement('tr');
      tr.className += 'table-row';

      const th = document.createElement('th');
      th.className += 'table-head';

      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');

    bodyLines.forEach((body: any) => {
      const span = document.createElement('span');
      span.className += 'colour-mark';

      const tr = document.createElement('tr');
      tr.className += 'line-row';

      const td = document.createElement('td');
      td.className += 'line-cell';
      td.appendChild(span);

      const textContent = body.join(' ');
      const words = textContent?.split(' ');

      words.forEach((word: string) => {
        const spanElement = document.createElement('span');

        if (!isStationsProduct(product)) {
          word.includes('%')
            ? (spanElement.className += 'percentage')
            : (spanElement.className += 'label');
        } else {
          word.includes('(')
            ? (spanElement.className += 'percentage')
            : (spanElement.className += 'label');
        }

        const textNode = document.createTextNode(word);
        spanElement.appendChild(textNode);
        td.appendChild(spanElement);
        td.appendChild(document.createTextNode(' '));
      });

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      if (td) {
        const { childNodes } = td;

        // Iterate over child nodes and remove non-span elements
        for (let i = childNodes.length - 1; i >= 0; i--) {
          const node = childNodes[i];
          if (node.nodeName !== 'SPAN') {
            td.removeChild(node);
          }
        }
      }

      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;

  /* Tooltip positioning code START */
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';

    return;
  }

  const [activeItem] = tooltip.dataPoints;

  if (activeItem) {
    const { y } = activeItem.element;

    tooltipEl.style.top = y + 100 + 'px';
  }

  /* Tooltip positioning code END */
};
export const qBankLabelTransform = (context: any, sumOfQuestions: number[]) => {
  const totalNumQuestions = sumOfQuestions[context.dataIndex];

  let label = context.dataset.label || '';

  if (label) {
    label += ': ';
  }
  if (context.parsed.y !== null) {
    const percentageQuestions = `${
      (context.parsed.y / totalNumQuestions) * 100
    }%`;
    label += `${context.parsed.y}/${totalNumQuestions} (${Math.floor(
      parseInt(percentageQuestions)
    )}%)`;
  }

  return label;
};

export const osceLabelTransform = (
  context: any,
  checkedMarks: any,
  totalMarks: any
) => {
  const { date } = checkedMarks[context.dataIndex];
  const allMarksPerDay = totalMarks[date];
  const sumPerDay = checkedMarks[context.dataIndex].sum;

  let label = context.dataset.label || '';

  if (label) {
    label += ': ';
  }

  if (context.parsed.y !== null && label.includes('Performed')) {
    label += `${context.parsed.y}% (${sumPerDay}/${allMarksPerDay})`;
  }

  if (label.includes('Not performed')) {
    label += `${context.parsed.y}% (${
      allMarksPerDay - sumPerDay
    }/${allMarksPerDay})`;
  }

  return label;
};
