import React from 'react';
import { EProductType } from '@quesmed/types-rn/models';

import { usePlatform } from 'context/PlatformState';
import { StationNotes } from './StationNotes';
import { ConceptNotes } from './ConceptNotes';

const MyNotes = () => {
  const { product } = usePlatform();

  switch (product) {
    case EProductType.OSCE:
    case EProductType.PLAB2:
    case EProductType.PACES:
      return <StationNotes />;
    default:
      return <ConceptNotes />;
  }
};

export default MyNotes;
