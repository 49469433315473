import React, { ChangeEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IConcept } from '@quesmed/types-rn/models';

import { Modal } from 'components/Modal';
import { Body, Caption } from 'components/Typography';
import { TextField } from 'components/TextField';
import { ModalVariant } from './types';
import {
  useDeletePresetMutation,
  useDuplicatePresetMutation,
  useEditPresetMutation,
} from './hooks';
import { APP_URL } from 'config/constants';
import { useSnackbar } from 'components/Snackbar';
import { usePlatform } from 'context/PlatformState';
import { copyToClipboard } from 'utils';
import useResetCategoryProgress from './hooks/useResetCategoryProgress';

interface ControllerProps {
  entitlementId: number;
  presetId: number;
  presetName: string;
  variant: string;
  open: boolean;
  setOpen: (state: string) => void;
  onClose: () => void;
  concepts: IConcept[];
}

const RenameInput = styled(TextField)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

const LinkBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  marginTop: spacing(6),
  padding: spacing(4, 3),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'block',
  whiteSpace: 'nowrap',
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: '4px',
}));

const SmallLabel = styled(Caption)(({ theme: { spacing } }) => ({
  padding: spacing(1, 0, 0, 4),
}));

const switchModalContent = (
  variant: string,
  presetId: number,
  presetName: string,
  name: string,
  section: string,
  product: number,
  handlePresetNameChange: (event: ChangeEvent<HTMLInputElement>) => void
) => {
  const path = `${APP_URL}/${section}/preset/share/${product}/${presetId}`;

  switch (variant) {
    case ModalVariant.RENAME:
      return (
        <RenameInput
          defaultValue={presetName}
          id="outlined-required"
          label="Preset name"
          name="preset-rename"
          onChange={handlePresetNameChange}
          required
          value={name}
        />
      );
    case ModalVariant.DELETE:
      return (
        <Body component="p">
          Are you sure you want to remove? Once deleted, it cannot be restored.
          Make sure you no longer need this preset before proceeding.
        </Body>
      );
    case ModalVariant.LINK:
      return (
        <Body component="p">
          Send a link to this preset to a friend by copying it from the box
          below. Your friend will receive a copy of the preset on their own
          profile.
          <LinkBox component="span">{path}</LinkBox>
          <SmallLabel> Copy and share the link with your friends.</SmallLabel>
        </Body>
      );
    case ModalVariant.RESET:
      return (
        <>
          <Body component="p">Reset the progress of your preset</Body>
          <br />
          <Body component="p" small>
            * Please note that reseting your progress on one preset will reset
            your progress on all your other presets.
          </Body>
        </>
      );
    case ModalVariant.DUPLICATE:
    default:
      return (
        <Body component="p">
          Create a duplicate of the current preset and save it in your My
          presets. The duplicate file will be separate from the original, but
          will retain the same statistics for each question.
        </Body>
      );
  }
};

const switchSubmitLabel = (variant: string) => {
  switch (variant) {
    case ModalVariant.RENAME:
      return 'Rename Preset';
    case ModalVariant.DELETE:
      return 'Remove Preset';
    case ModalVariant.LINK:
      return 'Copy Link';
    case ModalVariant.RESET:
      return 'Reset Progress';
    case ModalVariant.DUPLICATE:
    default:
      return 'Duplicate';
  }
};

const switchModalTitle = (variant: string, presetName: string) => {
  switch (variant) {
    case ModalVariant.RENAME:
      return 'Rename Preset';
    case ModalVariant.DELETE:
      return `Delete: ${presetName}`;
    case ModalVariant.LINK:
      return `Share preset: ${presetName}`;
    case ModalVariant.RESET:
      return `Reset preset progress: ${presetName}`;
    case ModalVariant.DUPLICATE:
    default:
      return `Duplicate: ${presetName}`;
  }
};

const ModalActionController = ({
  concepts,
  entitlementId,
  presetId,
  presetName,
  variant,
  open,
  setOpen,
  onClose,
}: ControllerProps) => {
  const { product } = usePlatform();
  const [name, setName] = useState<string>(presetName);
  const { deletePreset } = useDeletePresetMutation(presetId);
  const { duplicatePreset } = useDuplicatePresetMutation(presetId);
  const conceptIds = concepts.map(concept => Number(concept?.id) || 0);
  const { editPreset } = useEditPresetMutation(presetId);
  const topicIds = concepts.map(({ topicId }) => Number(topicId));
  const { resetCategoryProgress } = useResetCategoryProgress(
    conceptIds,
    topicIds
  );
  const { enqueueSnackbar } = useSnackbar();
  const pathnameSections = location.pathname.split('/');
  const [, section] = pathnameSections;

  const handlePresetNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const deleteItem = async () => {
    await deletePreset();
    setOpen('');
  };

  const duplicateItem = async () => {
    await duplicatePreset();
    setOpen('');
  };

  const renameItem = async () => {
    await editPreset({ entitlementId, name });

    setOpen('');
  };

  const copyItem = () => {
    copyToClipboard(
      `${APP_URL}/${section}/preset/share/${product}/${presetId}`
    );
    setOpen('');
    enqueueSnackbar('Link copied successfully!');
  };

  const resetItem = () => {
    resetCategoryProgress();
    setOpen('');
  };

  const switchOnSubmit = (variant: string) => {
    switch (variant) {
      case ModalVariant.DELETE:
        return deleteItem;
      case ModalVariant.RENAME:
        return renameItem;
      case ModalVariant.LINK:
        return copyItem;
      case ModalVariant.RESET:
        return resetItem;
      case ModalVariant.DUPLICATE:
      default:
        return duplicateItem;
    }
  };

  return (
    <Modal
      cancelLabel={variant !== ModalVariant.LINK ? 'Cancel' : undefined}
      danger={variant === ModalVariant.DELETE}
      fullWidthSubmit={variant === ModalVariant.LINK}
      noPaddingY
      onClose={onClose}
      onSubmit={switchOnSubmit(variant)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitLabel={switchSubmitLabel(variant)}
      title={switchModalTitle(variant, presetName)}
    >
      {switchModalContent(
        variant,
        presetId,
        presetName,
        name,
        section,
        Number(product),
        handlePresetNameChange
      )}
    </Modal>
  );
};

export default ModalActionController;
