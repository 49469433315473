import { useMemo } from 'react';
import { EProductType, ETopicType } from '@quesmed/types-rn/models';

import { QbankKnowledgeLibraryData, TopicMap } from '../types';
import { compareName, entries, sortCategoriesByTopicType } from 'utils';
import { useQbankKnowledgeVideoLibraryQuery } from './useQbankKnowledgeVideoLibraryQuery';

export const useQbankKnowledge = (): QbankKnowledgeLibraryData => {
  const [topics, loading] = useQbankKnowledgeVideoLibraryQuery([
    ETopicType.ALL,
  ]);

  const [topicsMap, categories] = useMemo(() => {
    if (!topics) {
      return [null, []];
    }

    const topicTypes = {} as {
      [key in EProductType]: string;
    };

    const map: TopicMap = {};
    const typeOrder: number[] = [];

    topics.forEach(topic => {
      const { entitlement, concepts } = topic;
      const sortedTopicConcepts = [...(concepts || [])].sort(compareName);
      if (entitlement.id) {
        if (!typeOrder[entitlement.index]) {
          typeOrder[entitlement.index] = entitlement.id;
        }

        if (!topicTypes[entitlement.id]) {
          topicTypes[entitlement.id] = entitlement.name;
        }

        if (map[entitlement.id]) {
          map[entitlement.id].push({ ...topic, concepts: sortedTopicConcepts });
        } else {
          map[entitlement.id] = [{ ...topic, concepts: sortedTopicConcepts }];
        }
      }
    });

    const categories = sortCategoriesByTopicType(
      entries(topicTypes).map(([key, value]) => ({
        value: Number(key),
        label: value,
      })),
      typeOrder
    );

    return [map, categories];
  }, [topics]);

  return { topics, loading, topicsMap, categories };
};

export default useQbankKnowledge;
