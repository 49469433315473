import { CheckboxState, SelectionState } from 'types';

export const extractConceptIds = (dataMap: SelectionState) => {
  const conceptIdsArray: number[] = [];
  let entitlementId = 0;

  dataMap.forEach(value => {
    if (value.topicState === CheckboxState.INTERMEDIATE) {
      conceptIdsArray.push(...value.selectedConceptsIds);
    }

    if (value.topicState === CheckboxState.CHECKED) {
      conceptIdsArray.push(...value.conceptIds);
    }

    entitlementId = value.type;
  });

  return { conceptIdsArray, entitlementId };
};
