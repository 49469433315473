import { useQuery } from '@apollo/client';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useMarksheetQuery = (marksheetId?: string) => {
  const { data, loading } = useQuery<IMarksheetData, IMarksheetVar>(MARKSHEET, {
    variables: { id: Number(marksheetId) },
    skip: !marksheetId,
  });

  const { marksheet } = data?.restricted || {};

  return {
    marksheet,
    loading,
  };
};

export default useMarksheetQuery;
