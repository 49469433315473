import React from 'react';
import { EProductType } from '@quesmed/types-rn/models';

import AlgoliaProvider, { config } from 'components/algolia/AlgoliaProvider';
import { SearchScopeIndex } from 'components/Search/type';
import { usePlatform } from 'context/PlatformContext';
import { OsceKnowledgeLibrary } from './osce';
import { QbankKnowledgeLibrary } from './qbank';
import { PacesKnowledgeLibrary } from './paces';

const getAlgoliaConfig = (product: EProductType | null) => {
  switch (product) {
    case EProductType.OSCE:
    case EProductType.PLAB2:
      return {
        index: SearchScopeIndex.Oscebook,
        config,
        component: <OsceKnowledgeLibrary />,
      };
    case EProductType.PACES:
      return {
        index: SearchScopeIndex.Pacebook,
        config,
        component: <PacesKnowledgeLibrary />,
      };
    default:
      return {
        index: SearchScopeIndex.Quesbook,
        config,
        component: <QbankKnowledgeLibrary />,
      };
  }
};

const KnowledgeLibrary = (): JSX.Element => {
  const { product } = usePlatform();
  const { config, index, component } = getAlgoliaConfig(product);

  return (
    <AlgoliaProvider config={config} indexName={index}>
      {component}
    </AlgoliaProvider>
  );
};

export default KnowledgeLibrary;
