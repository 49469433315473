import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { List } from '@mui/material';

import { useSnackbar } from 'components/Snackbar';
import { useLearningPointsCheckState } from 'pages/Questions/context';
import { copyToClipboard } from 'utils';
import { Button } from 'components/Button';
import { DotsVerticalIcon } from 'components/Icons';
import { Popper } from 'components/Popper';
import { ListItemButton } from 'components/List';
import { useHover, usePrevious } from 'hooks';
import { ExerciseProgressStatus } from 'types';

const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100%',

  [breakpoints.up('md')]: {
    width: 'unset',
  },
}));

const ExerciseSummaryExportButton = () => {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [popperRef, popperRefValue] = useHover<HTMLButtonElement>();
  const popperRefPrevValue = usePrevious(popperRefValue);
  const { learningPointsSelectionState } = useLearningPointsCheckState();
  const { enqueueSnackbar } = useSnackbar();

  const generateClipboard = (selectedLearningPoints: string[]) => {
    const clipboardText = selectedLearningPoints
      .filter(point => point)
      .map(point => `- ${point}`)
      .join('\n\n');

    copyToClipboard(clipboardText);
    enqueueSnackbar('Learning points copied to clipboard!');
  };

  const exportIncorrect = () => {
    const selectedLearningPoints: string[] = [];

    learningPointsSelectionState.forEach(value => {
      if (value.status === ExerciseProgressStatus.Incorrect) {
        selectedLearningPoints.push(value.learningPoint);
      }
    });

    generateClipboard(selectedLearningPoints);
  };
  const exportCorrect = () => {
    const selectedLearningPoints: string[] = [];

    learningPointsSelectionState.forEach(value => {
      if (value.status === ExerciseProgressStatus.Correct) {
        selectedLearningPoints.push(value.learningPoint);
      }
    });

    generateClipboard(selectedLearningPoints);
  };
  const exportSelected = () => {
    const selectedLearningPoints: string[] = [];

    learningPointsSelectionState.forEach(value => {
      if (value.selected) {
        selectedLearningPoints.push(value.learningPoint);
      }
    });

    generateClipboard(selectedLearningPoints);
  };

  const popperItems = [
    {
      label: 'Export Selected',
      onClick: exportSelected,
    },
    { label: 'Export Incorrect', onClick: exportIncorrect },
    { label: 'Export Correct', onClick: exportCorrect },
  ];

  const handleOpenMenu = () => {
    setIsPopperOpen(true);
  };

  const handleCloseMenu = () => {
    setIsPopperOpen(false);
  };

  useEffect(() => {
    if (isPopperOpen && popperRefPrevValue && !popperRefValue) {
      handleCloseMenu();
    }
  }, [isPopperOpen, popperRefPrevValue, popperRefValue]);

  return (
    <StyledButton
      onClick={handleOpenMenu}
      ref={popperRef}
      secondary
      size="medium"
    >
      <DotsVerticalIcon />
      <Popper
        anchorEl={popperRef.current}
        disablePortal
        onClose={handleCloseMenu}
        open={isPopperOpen}
        paperSx={({ spacing }) => ({
          padding: spacing(0, 2),
          width: spacing(60),
          textTransform: 'capitalize',
        })}
        placement="top"
      >
        <List>
          {popperItems.map(({ label, onClick }) => (
            <ListItemButton
              expanded={1}
              key={label}
              nested={0}
              onClick={onClick}
            >
              {label}
            </ListItemButton>
          ))}
        </List>
      </Popper>
      Export Learning Points
    </StyledButton>
  );
};

export default ExerciseSummaryExportButton;
