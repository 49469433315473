import { useCallback, useEffect, useMemo } from 'react';

import useLeaveStation from './useLeaveStation';
import { paths } from 'Router';
import { isDev } from 'config/constants';
import { useLeaveExerciseBlockade } from 'hooks';

const { stations } = paths;
const { root, groupLobby } = stations;

const SOLO_ROUTES = [`${root}/solo/quiz`, root];
const GROUP_ROUTES = [
  `${root}/${groupLobby}?join=`,
  `${root}/${groupLobby}/study`,
  `${root}/${groupLobby}/builder`,
  `${root}/${groupLobby}/study/quiz`,
  `${root}/${groupLobby}/study/summary`,
];

interface QuestionLeavingBlockade {
  ignoreBlockade: boolean;
  shouldBlock: boolean;
  osceMarksheetId?: number;
  solo?: boolean;
}

const useStationLeavingBlockade = ({
  osceMarksheetId,
  ignoreBlockade,
  shouldBlock,
  solo,
}: QuestionLeavingBlockade) => {
  const { leaveOnClose } = useLeaveStation(osceMarksheetId);

  const routes = useMemo(() => (solo ? SOLO_ROUTES : GROUP_ROUTES), [solo]);

  const ignorePath = useCallback(
    (path: string) =>
      Boolean(osceMarksheetId && routes.some(route => path.includes(route))),
    [osceMarksheetId, routes]
  );

  const handleLeaveOnClose = useCallback(() => {
    if (!ignoreBlockade) {
      leaveOnClose();
    }

    return undefined;
  }, [ignoreBlockade, leaveOnClose]);

  useLeaveExerciseBlockade({
    ignorePath,
    ignoreBlockade,
    shouldBlock,
  });

  useEffect(() => {
    if (solo && !isDev) {
      window.addEventListener('beforeunload', handleLeaveOnClose);

      return () => {
        window.removeEventListener('beforeunload', handleLeaveOnClose);
      };
    }
  }, [handleLeaveOnClose, solo]);
};

export default useStationLeavingBlockade;
