import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EPaceMarkType,
  EProductType,
  EStudyAction,
  IOsceMarksheet,
  IOsceMarksheetMark,
} from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { calculatePercent, formatDuration, round } from 'utils';
import ExerciseLayout from 'components/Layout/ExerciseLayout';
import {
  ExerciseSummary,
  SummaryColumn,
  SummaryTableKey,
  SummaryTabs,
} from 'components/ExerciseSummary';
import {
  CreateOrJoinMutation,
  ExerciseProgressStatus,
  ExerciseType,
  ItemStatusLabel,
} from 'types';
import constants from 'config/constants';
import { setStationsProgressData } from './studyBuilderUtils';
import { paths } from 'Router';
import {
  AlertCircleOutlineIcon,
  CheckboxMarkedCircleOutlineIcon,
  CloseCircleOutlineIcon,
  RadioBoxBlankIcon,
  RadioBoxMarkedIcon,
  StickerTextOutlineIcon,
} from 'components/Icons';
import { Participant, ParticipantList } from 'components/ParticipantCard';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { useAuth, useCurrentUser } from 'Auth';
import { useIsMobile } from 'hooks';
import { usePlatform } from 'context/PlatformState';

const { stations, videoLibrary } = paths;

const { groupLobby, root } = stations;

const { MILISECONDS_IN_SECOND } = constants;

const defaultResult = {
  icon: <RadioBoxMarkedIcon />,
  status: ExerciseProgressStatus.Default,
};

const notAnsweredResult = {
  icon: <RadioBoxBlankIcon />,
  status: ExerciseProgressStatus.NotAnswered,
};

const pacesColumns: SummaryColumn<IOsceMarksheetMark>[] = [
  {
    key: SummaryTableKey.Phases,
    align: 'left',
    label: SummaryTableKey.Phases,
    formatter: mark => mark?.osceStationMark?.header,
    type: 'default',
  },
  {
    key: SummaryTableKey.Satisfactory,
    align: 'left',
    label: SummaryTableKey.Satisfactory,
    labelIcon: <CheckboxMarkedCircleOutlineIcon className="satisfactory" />,
    resultFormatter: mark => {
      if (mark.selectedChoice === EPaceMarkType.SATISFACTORY) {
        return defaultResult;
      }

      return notAnsweredResult;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Borderline,
    align: 'left',
    label: SummaryTableKey.Borderline,
    labelIcon: <AlertCircleOutlineIcon className="borderline" />,
    resultFormatter: mark => {
      if (mark.selectedChoice === EPaceMarkType.BORDERLINE) {
        return defaultResult;
      }

      return notAnsweredResult;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Unsatisfactory,
    align: 'left',
    label: SummaryTableKey.Unsatisfactory,
    labelIcon: <CloseCircleOutlineIcon className="unsatisfactory" />,
    resultFormatter: mark => {
      if (mark.selectedChoice === EPaceMarkType.UN_SATISFACTORY) {
        return defaultResult;
      }

      return notAnsweredResult;
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Chevron,
    align: 'left',
    label: 'chevron',
    type: 'chevron',
  },
];

const osceColumns: SummaryColumn<IOsceMarksheetMark>[] = [
  {
    key: SummaryTableKey.Content,
    align: 'left',
    label: SummaryTableKey.Content,
    formatter: mark => mark?.osceStationMark?.name,
    type: 'default',
  },
  {
    key: SummaryTableKey.Phases,
    align: 'left',
    label: SummaryTableKey.Phases,
    formatter: mark => mark?.osceStationMark?.header,
    type: 'default',
  },
  {
    key: SummaryTableKey.Result,
    align: 'left',
    label: SummaryTableKey.Result,
    resultFormatter: mark => {
      if (mark.mark) {
        return {
          icon: <CheckboxMarkedCircleOutlineIcon />,
          status: ExerciseProgressStatus.Correct,
          label: ItemStatusLabel.Performed,
        };
      }

      return {
        icon: <RadioBoxBlankIcon />,
        status: ExerciseProgressStatus.NotAnswered,
        label: ItemStatusLabel.NotPerformed,
      };
    },
    type: 'default',
  },
  {
    key: SummaryTableKey.Chevron,
    align: 'left',
    label: 'chevron',
    type: 'chevron',
  },
];

const getBackLabel = (
  isSample: boolean,
  isPaces: boolean,
  isAuthenticated: boolean
) => {
  if (isSample) {
    return isAuthenticated ? 'Back to app management' : 'Back to login';
  }

  return isPaces ? 'Back To Video Library' : 'Back To Lobby';
};

interface StationsSummaryProps {
  osceMarksheet?: IOsceMarksheet;
  marksheetLoading: boolean;
  participants?: Participant[];
  restartSession?: CreateOrJoinMutation;
  isSample?: boolean;
}

const tabs = [{ title: SummaryTabs.Content, icon: <StickerTextOutlineIcon /> }];

const StationsSummary = ({
  osceMarksheet,
  marksheetLoading,
  restartSession,
  participants,
  isSample = false,
}: StationsSummaryProps): JSX.Element => {
  const { id } = useCurrentUser();
  const { product } = usePlatform();
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const [activeTab, setActiveTab] = useState(tabs?.[0].title);

  const {
    id: osceMarksheetId,
    solo,
    sessionId,
    timeTaken = 0,
    totalStationTime,
    score = 0,
    marks,
    users = [],
    isTimed,
  } = osceMarksheet || {};
  const isPaces = product === EProductType.PACES;

  let total = 0;
  if (marks) {
    total = isPaces ? marks.length * 2 : marks.length;
  }

  const duration = formatDuration(timeTaken * MILISECONDS_IN_SECOND);
  const progressData = setStationsProgressData(total, score, isPaces);
  const correctMarks = isPaces ? score : round(total * score);

  const handleBackToLobby = () => {
    if (isSample) {
      navigate(isAuthenticated ? paths.appsManagement : paths.login);

      return;
    }

    if (solo) {
      navigate(isPaces ? videoLibrary.root : root);
    } else {
      restartSession?.(EStudyAction.START, id => {
        if (id) {
          navigate(`${root}/${groupLobby}?join=${id}`);
        }
      });
    }
  };

  const handleBackToReview = (mark: IOsceMarksheetMark) => {
    const { id: markId } = mark;

    const index = marks?.findIndex(({ id }) => Number(id) === Number(markId));

    if (!isNil(index)) {
      const path = pathname.includes('group')
        ? `${root}/${groupLobby}/study/${sessionId}/${osceMarksheetId}#${markId}`
        : `${root}/review/${osceMarksheetId}#${markId}`;

      navigate(path);
    }
  };

  return (
    <ExerciseLayout
      bottomOffsetVariant={isMobile ? 'large' : 'tiny'}
      controlPanelContent={
        participants && !solo ? (
          <ParticipantList
            currentUserId={Number(id)}
            participants={participants}
            variant="panel"
            withAudio
            withRoleHeader={!solo}
            withoutRole={!solo}
          />
        ) : null
      }
      exerciseContent={
        <>
          <ExerciseSummary
            activeTab={activeTab}
            backToLobby={handleBackToLobby}
            backToLobbyLabel={getBackLabel(isSample, isPaces, isAuthenticated)}
            correctCount={correctMarks}
            correctLabel="Candidate scored points"
            data={progressData}
            duration={isTimed ? `${duration}/${totalStationTime}` : duration}
            loading={marksheetLoading}
            notAnsweredLabel={ItemStatusLabel.NotPerformed}
            score={calculatePercent(correctMarks, total)}
            setActiveTab={setActiveTab}
            tableData={{
              columns: isPaces ? pacesColumns : osceColumns,
              data: marks,
              onClick: isSample ? undefined : handleBackToReview,
            }}
            tabs={tabs}
            title={ExerciseType.Stations}
            total={total}
          />
          {/* {marks ? (
            <ExerciseSummaryModal
              columns={isPaces ? pacesColumns : osceColumns}
              data={marks}
              labels={labels}
              onClick={isSample ? undefined : handleBackToReview}
              onClose={handleCloseDetails}
              open={showDetailsModal}
              summary={summary}
              title={stationName ? stationName : ''}
            />
          ) : null} */}
        </>
      }
      exerciseItemsCount={users?.length}
      onLeave={handleBackToLobby}
      withSound={!solo}
      withoutVerticalOffset
      {...(!solo && PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS)}
    />
  );
};

export default StationsSummary;
