import React from 'react';
import { mdiFlagRemove } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FlagRemoveIcon = (props: IconProps) => (
  <Icon path={mdiFlagRemove} {...props} />
);

export default FlagRemoveIcon;
