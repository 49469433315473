import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { CheckboxState, MLASelectionState, Nullable, TableData } from 'types';
import SelectionTableHead from './SelectionTableHead';
import { SelectionColumn } from './types';
import { useMemoizedGenericSelectionComplexRow } from './SelectionComplexRow';
import {
  Fallback,
  TableContainer,
  TableContentWrapper,
} from './SelectionTable.styles';
import TableSkeleton from './TableSkeleton';

export interface MLASelectionTableProps<T extends TableData> {
  allSelected?: CheckboxState;
  loading: boolean;
  selectionState?: MLASelectionState;
  selectedItemId?: Nullable<number>;
  data: T[];
  columns: SelectionColumn<T>[];
  onSelectAll?: () => void;
  onSelectRow?: (itemId: number) => () => void;
  onSelectCondition?: (itemId: number) => (subItem: number) => void;
  onSelectPresentation?: (itemId: number) => (subItem: number) => void;
  nestedItemsKey?: keyof T;
  checkedIcon?: ReactNode;
  icon?: ReactNode;
  withOffset?: boolean;
  selectOnRowClick?: boolean;
  stickyHeader?: boolean;
  widget?: boolean;
  headerLock?: boolean;
  globalLock?: boolean;
  isCandidate?: boolean;
  mlaColumns?: boolean;
  mlaConditionColumns?: SelectionColumn<T>[];
  mlaPatientPresentationColumns?: SelectionColumn<T>[];
}

function MLASelectionTable<T extends TableData>({
  allSelected,
  globalLock,
  headerLock,
  checkedIcon,
  columns,
  data,
  loading,
  icon,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  nestedItemsKey,
  onSelectAll,
  onSelectRow,
  onSelectCondition,
  onSelectPresentation,
  selectedItemId,
  selectionState,
  selectOnRowClick,
  withOffset,
  stickyHeader,
  widget,
}: MLASelectionTableProps<T>): JSX.Element {
  const SelectionComplexRow = useMemoizedGenericSelectionComplexRow<T>();

  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <TableContainer>
      <TableContentWrapper
        className={clsx({ 'sticky-header': stickyHeader, widget })}
      >
        {data.length ? (
          <>
            <SelectionTableHead
              allSelected={allSelected}
              columns={columns}
              headerLock={headerLock}
              onSelectAll={onSelectAll}
              withOffset={withOffset}
            />
            {data.map(item => {
              const itemSelectionState = selectionState?.get(Number(item.id));

              return (
                <SelectionComplexRow
                  checked={selectedItemId === Number(item.id)}
                  checkedIcon={checkedIcon}
                  checkedState={itemSelectionState?.topicState}
                  columns={columns}
                  data={item}
                  globalLock={globalLock}
                  icon={icon}
                  key={item.id}
                  mlaColumns={mlaColumns}
                  mlaConditionColumns={mlaConditionColumns}
                  mlaPatientPresentationColumns={mlaPatientPresentationColumns}
                  nestedItemsKey={nestedItemsKey as string | undefined}
                  onSelectCondition={onSelectCondition?.(Number(item.id))}
                  onSelectPresentation={onSelectPresentation?.(Number(item.id))}
                  onSelectRow={onSelectRow?.(Number(item.id))}
                  selectOnRowClick={selectOnRowClick}
                  selectedMLASubitems={
                    itemSelectionState && [
                      itemSelectionState.selectedPresentationsIds,
                      itemSelectionState.selectedConditionsIds,
                    ]
                  }
                  withOffset={withOffset}
                />
              );
            })}
          </>
        ) : (
          <Fallback>No results found</Fallback>
        )}
      </TableContentWrapper>
    </TableContainer>
  );
}

export default MLASelectionTable;
