import { useSubscription } from '@apollo/client';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import { useCallback, useEffect } from 'react';
import {
  IMarksheetInfo,
  IOnMarksheetChangeData,
  IOnMarksheetChangeVar,
  ON_MARKSHEET_CHANGE,
} from '@quesmed/types-rn/resolvers/subscription';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { useCurrentUser } from 'Auth';
import { useSnackbar } from 'components/Snackbar';

const { questions } = paths;

const useOnMarksheetChange = (
  initialMarksheetId?: number,
  sessionId?: string,
  solo = false,
  refetchMarksheet?: () => void
) => {
  const { id: currentUserId } = useCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar({
    unique: true,
  });

  const { data } = useSubscription<
    IOnMarksheetChangeData,
    IOnMarksheetChangeVar
  >(ON_MARKSHEET_CHANGE, {
    variables: {
      sessionId: sessionId || '',
    },
    skip: !sessionId,
    onData: ({ client, data: subscriptionData }) => {
      const { activeUsers, state, users, marksheetId } =
        subscriptionData.data?.onMarksheetChange || {};
      const finalId = initialMarksheetId || marksheetId;

      if (finalId) {
        const prevData = client.readQuery<IMarksheetData, IMarksheetVar>({
          variables: { id: finalId },
          query: MARKSHEET,
        });
        if (prevData) {
          const { marksheet } = prevData.restricted || {};
          const newMarksheet = { ...marksheet };

          if (!isNil(state)) {
            newMarksheet.state = state;
          }

          if (!isNil(users)) {
            newMarksheet.users = users;
          }

          if (!isNil(activeUsers)) {
            newMarksheet.activeUsers = activeUsers;
          }

          if (
            newMarksheet.solo &&
            !isNil(activeUsers) &&
            activeUsers.length > 1
          ) {
            newMarksheet.solo = false;
          }

          client.writeQuery({
            query: MARKSHEET,
            variables: { id: finalId },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                marksheet: {
                  ...newMarksheet,
                },
              },
            },
          });
        }
      }
    },
  });

  const { onMarksheetChange } = data || {};

  const parseAction = useCallback(
    (currentUserId?: number, actionData?: IMarksheetInfo) => {
      const { action, user } = actionData || {};
      const { displayName, id } = user || {};
      const isNotCurrentUser = id !== currentUserId;

      if (action !== undefined) {
        switch (action) {
          case EMarksheetAction.LEFT:
          case EMarksheetAction.DISCONNECT: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has left the study.`;
              enqueueSnackbar(message);
            }
            break;
          }
          case EMarksheetAction.CONNECT: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has joined the study.`;
              enqueueSnackbar(message);
            }
            break;
          }
          case EMarksheetAction.KICK: {
            navigate(`${questions.root}`, {
              state: { leave: true },
            });
            enqueueSnackbar(
              'Session expired! Please create a new group study.'
            );
            break;
          }
          case EMarksheetAction.END: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has ended the study.`;
              enqueueSnackbar(message);
            }
            break;
          }
          case EMarksheetAction.START: {
            if (isNotCurrentUser) {
              refetchMarksheet?.();
            }
            break;
          }
          default: {
            return;
          }
        }
      }
    },
    [enqueueSnackbar, navigate, refetchMarksheet]
  );

  useEffect(() => {
    if (!solo) {
      parseAction(currentUserId, onMarksheetChange);
    }
  }, [solo, currentUserId, onMarksheetChange, parseAction]);
};

export default useOnMarksheetChange;
