import { Palette, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ItemStatusLabel } from 'types';

export const LineChartContainer = styled(Box)(
  ({ theme: { palette, breakpoints, transitions } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
    border: '1px solid transparent',
    borderRadius: '6px',
    transition: transitions.create(['border-color']),

    '&:hover': {
      borderColor: palette.stroke.dark,
    },
  })
);

export const LineChartStyle = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    height: '8px',
    backgroundColor: palette.background.paper,
    width: '100%',
    borderRadius: '3px',
    display: 'flex',
    gap: spacing(1),

    '&.performance': {
      height: '18px',
    },
  })
);

export const setBackgroundColor = (type: ItemStatusLabel, palette: Palette) => {
  switch (type) {
    case ItemStatusLabel.Confident:
      return palette.success.light;
    case ItemStatusLabel.ToReview:
      return palette.warning.main;
    case ItemStatusLabel.Answered:
    case ItemStatusLabel.ToLearn:
      return palette.error.light;
    case ItemStatusLabel.NotAnswered:
    default:
      return palette.stroke.main;
    case ItemStatusLabel.Revising:
      return palette.primary.main;
  }
};

export const LineChartItemStyle = styled(Box)<{
  percent: number;
  type: ItemStatusLabel;
}>(({ percent, theme: { palette }, type }) => ({
  background: setBackgroundColor(type, palette),
  width: `${percent}%`,
  borderRadius: '3px',
}));
