import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { IHighlightNode } from '@quesmed/types-rn/models';
import {
  ADD_QUESTION_HIGHLIGHT,
  getQuestionHighlightOptimisticResponse,
  IAddQuestionHighlightData,
  IAddQuestionHighlightVar,
  updateQuestionHighlights,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import useSnackbar from 'components/Snackbar/useSnackbar';
import { useCurrentUser } from 'Auth';

const useAddQuestionHighlightMutation = (marksheetId: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: userId } = useCurrentUser();

  const [questionHighlight] = useMutation<
    IAddQuestionHighlightData,
    IAddQuestionHighlightVar
  >(ADD_QUESTION_HIGHLIGHT, {
    onError: () => {
      enqueueSnackbar('Text highlight failed');
    },
  });

  const highlightText = useCallback(
    async (
      questionId: number,
      typeId: number,
      highlights: IHighlightNode[]
    ) => {
      if (marksheetId) {
        return questionHighlight({
          variables: {
            marksheetId,
            input: [
              {
                questionId: Number(questionId),
                highlights: highlights.map(
                  ({ __typename, ...rest }: any) => rest
                ),
              },
            ],
          },
          update: updateQuestionHighlights(typeId),
          optimisticResponse: getQuestionHighlightOptimisticResponse(
            userId,
            highlights,
            questionId
          ),
        });
      }
    },
    [marksheetId, questionHighlight, userId]
  );

  return highlightText;
};

export default useAddQuestionHighlightMutation;
