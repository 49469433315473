import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
  EProductType,
  IOsceMarksheet,
  IOsceStation,
} from '@quesmed/types-rn/models';
import Fade from '@mui/material/Fade';
import { useLocation, useNavigate } from 'react-router-dom';
import isNil from 'lodash/isNil';

import {
  BuilderViewMode,
  ExerciseMode,
  ExerciseType,
  Nullable,
  SelectionBarLabels,
  StationRoleName,
  ToggleEvent,
} from 'types';
import { parseFullText } from 'utils';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import { AccountGroupIcon, AccountIcon, TextIcon } from 'components/Icons';
import { ExerciseBuilder } from 'components/ExerciseBuilder';
import { TRANSITION_TIMEOUT } from 'config/constants';
import {
  BUILDER_VIEWS,
  PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS,
} from 'constants/general';
import { useStationLeavingBlockade, useStationsQuery } from './hooks';
import { useIsMobile, useLeaveExerciseState, usePrevious } from 'hooks';
import { findCategoryLabel, splitPerCategory } from './studyBuilderUtils';
import { DETAILS_COLUMNS, OVERVIEW_COLUMNS } from './studyBuilderConstants';
import CandidateInstructionsModal from './InstructionsModal';
import {
  BottomSelectionBar,
  setBottomBarOffset,
} from 'components/BottomSelectionBar';
import StationsTimerModal from './StationsTimerModal';
import { ParticipantList } from 'components/ParticipantCard';
import { useCurrentUser } from 'Auth';
import StationsLeaveModal from './StationsLeaveModal';
import { paths } from 'Router';
import filterNullStationUsers from './filterNullStationUsers';
import useSelectStationMutation from './hooks/useSelectStationMutation';
import { Modal } from 'components/Modal';
import { Markdown } from 'components/Markdown';

const { dashboard } = paths;

interface RouterState {
  osceMarksheetId?: number;
  category: EProductType;
}

const getOsceMarksheetId = (
  solo?: boolean,
  osceMarksheetId?: number,
  initialOsceMarksheetId?: number
) => {
  if (solo && initialOsceMarksheetId) {
    return initialOsceMarksheetId;
  }

  if (!solo && osceMarksheetId) {
    return osceMarksheetId;
  }
};

interface StudyBuilderProps {
  osceMarksheet?: IOsceMarksheet;
  isExaminer?: boolean;
  isActor?: boolean;
  isCandidate?: boolean;
}

const StudyBuilder = ({
  osceMarksheet,
  isExaminer: isExaminerRole = false,
  isActor,
  isCandidate,
}: StudyBuilderProps): JSX.Element => {
  const { id } = useCurrentUser();
  const {
    members,
    id: osceMarksheetId,
    solo: marksheetSolo,
    osceStationId,
  } = osceMarksheet || {};

  const solo = isNil(marksheetSolo) ? true : marksheetSolo;
  const isExaminer = solo ? true : isExaminerRole;
  const { loading, stations, categories } = useStationsQuery(solo);
  const [selectedStation, setSelectedStation] =
    useState<Nullable<IOsceStation>>(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const { state: routerState } = useLocation<RouterState>();
  const { osceMarksheetId: initialSoloOsceMarksheetId, category } =
    routerState || {};

  const [activeCategory, setActiveCategory] = useState<EProductType>();

  const [activeView, setActiveView] = useState<BuilderViewMode>(
    BUILDER_VIEWS[0].value
  );
  const { open, openLeaveModal } = useLeaveExerciseState();
  const [showSelectionBar, setShowSelectionBar] = useState(false);
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [selectedStationId, setSelectedStationId] =
    useState<Nullable<number>>(null);
  const [previewStationId, setPreviewStationId] =
    useState<Nullable<number>>(null);
  const [previewInstructions, setPreviewInstructions] = useState('');
  const [instructions, setInstructions] = useState('');
  const [stationsToDisplay, setStationsToDisplay] =
    useState<Nullable<IOsceStation[]>>(null);
  const previousCategory = usePrevious(activeCategory);
  const navigate = useNavigate();
  const { selectStation } = useSelectStationMutation();

  const { isMobile } = useIsMobile();

  const activeCategoryLabel = useMemo(
    () => findCategoryLabel(categories, activeCategory),
    [activeCategory, categories]
  );

  useEffect(() => {
    if (categories.length && !activeCategory) {
      setActiveCategory(Number(categories[0]?.value));
    }
  }, [activeCategory, categories]);

  const stationsByCategory = useMemo(
    () => (stations ? splitPerCategory(stations) : null),
    [stations]
  );

  const columns = useMemo(
    () =>
      activeView === BuilderViewMode.Details
        ? DETAILS_COLUMNS
        : OVERVIEW_COLUMNS,
    [activeView]
  );

  useStationLeavingBlockade({
    osceMarksheetId,
    shouldBlock: solo && showSelectionBar,
    ignoreBlockade: !solo || !showSelectionBar,
    solo,
  });

  const {
    name,
    candidateBrief = '',
    candidatePictures = [],
    actorBrief = '',
    actorPictures = [],
    examinerBrief = '',
    examinerPictures = [],
    lastOsceMarksheetId,
  } = selectedStation ?? {};

  const selectedItems = isCandidate ? 'Station selected' : `[${name}] selected`;

  useEffect(() => {
    if (isActor) {
      setInstructions(
        actorBrief ? parseFullText(actorBrief, actorPictures) : actorBrief
      );
    } else if (isExaminer) {
      setInstructions(
        examinerBrief
          ? parseFullText(examinerBrief, examinerPictures)
          : examinerBrief
      );
    } else {
      setInstructions(
        candidateBrief
          ? parseFullText(candidateBrief, candidatePictures)
          : candidateBrief
      );
    }
  }, [
    actorBrief,
    actorPictures,
    candidateBrief,
    candidatePictures,
    examinerBrief,
    examinerPictures,
    isActor,
    isCandidate,
    isExaminer,
    solo,
  ]);

  const customIcon = isExaminer ? undefined : <TextIcon />; // has to be undefined as at the end we pass it to MUI radio which breaks if null is passed

  const getRole = () => {
    if (isExaminer) {
      return StationRoleName.Examiner;
    }
    if (isActor) {
      return StationRoleName.Actor;
    }

    return StationRoleName.Candidate;
  };

  const handleSelectStation = (value: Nullable<number>) => () => {
    if (isExaminer && (osceMarksheetId || initialSoloOsceMarksheetId)) {
      selectStation({
        variables: {
          osceMarksheetId: Number(
            osceMarksheetId || initialSoloOsceMarksheetId
          ),
          osceStationId: Number(value),
        },
      });
    }
    if (solo || isExaminer) {
      setSelectedStationId(value);
    } else if (!isCandidate) {
      setPreviewStationId(value);
      const preview = stationsToDisplay?.find(
        station => Number(station.id) === Number(value)
      );

      const role = getRole();
      if (role === StationRoleName.Actor) {
        setPreviewInstructions(
          preview?.actorBrief
            ? parseFullText(preview?.actorBrief, preview?.actorPictures)
            : preview?.actorBrief || ''
        );
      } else {
        setPreviewInstructions(
          preview?.candidateBrief
            ? parseFullText(preview?.candidateBrief, preview?.candidatePictures)
            : preview?.candidateBrief || ''
        );
      }
    }
  };

  const handleActiveCategory = (e: SyntheticEvent, type: EProductType) =>
    setActiveCategory(Number(type));

  const handleActiveView = (e: ToggleEvent, view: BuilderViewMode) =>
    setActiveView(view);

  const handleOpenInstructions = () => setShowInstructions(true);

  const handleOpenWalkthrough = () => setShowWalkthrough(true);

  const handleOpenTimerModal = () => setShowTimerModal(true);

  const handleCloseTimerModal = () => setShowTimerModal(false);

  const handleReviewStudy = () => {
    if (lastOsceMarksheetId) {
      navigate(`${paths.stations.root}/review/${lastOsceMarksheetId}`, {
        state: { leave: true },
      });
    }
  };

  const handleUnselectStation = () => setSelectedStationId(null);

  const handleCloseInstructions = () => {
    setShowInstructions(false);
    setPreviewStationId(null);
  };

  const handleCloseWalkthrough = () => {
    setShowWalkthrough(false);
    setPreviewStationId(null);
  };

  const handleLeave = () => {
    if (solo) {
      navigate(dashboard);
    } else {
      openLeaveModal();
    }
  };

  const handleSearch = (searchString: string) => {
    const normalizedSearchString = searchString.toLowerCase().trim();
    if (stationsByCategory && searchString && activeCategory) {
      const filtered = stationsByCategory[activeCategory].filter(({ name }) =>
        name.toLowerCase().includes(normalizedSearchString)
      );

      setStationsToDisplay(filtered);

      return;
    }

    setStationsToDisplay(
      activeCategory && stationsByCategory
        ? stationsByCategory[activeCategory]
        : null
    );
  };

  useEffect(() => {
    let timeout = 0;

    if (selectedStationId) {
      const selectedStation = stations?.find(
        ({ id }) => Number(id) === Number(selectedStationId)
      );

      if (selectedStation) {
        if (!isExaminer && !isCandidate) {
          setShowInstructions(Boolean(selectedStationId));
        }
        setShowSelectionBar(true);
        setSelectedStation(selectedStation);
      }
    } else {
      setShowSelectionBar(false);
      if (!isExaminer) {
        setShowInstructions(false);
      }
      timeout = window.setTimeout(() => {
        setSelectedStation(null);
      }, TRANSITION_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [stations, selectedStationId, isExaminer, isCandidate]);

  useEffect(() => {
    setSelectedStationId(osceStationId || null);
  }, [osceStationId]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setStationsToDisplay(
      stationsByCategory && activeCategory
        ? stationsByCategory[activeCategory]
        : null
    );
  }, [stationsByCategory, activeCategory, loading]);

  useEffect(() => {
    if (category !== undefined) {
      setActiveCategory(Number(category));
    }
  }, [category]);

  const participants = members
    ? members
        .filter(filterNullStationUsers)
        .map(({ role, user: { id, displayName } }) => ({
          role,
          id: Number(id),
          displayName,
        }))
    : undefined;

  return (
    <ExerciseLayout
      bottomOffsetVariant={setBottomBarOffset(showSelectionBar, isMobile)}
      controlPanelContent={
        participants && !solo ? (
          <ParticipantList
            currentUserId={Number(id)}
            participants={participants}
            variant="panel"
            withAudio
          />
        ) : null
      }
      exerciseContent={
        <>
          <PreLaunchLayout selection={showSelectionBar ? 1 : 0}>
            <ExerciePreBuildHeader
              exerciseMode={solo ? ExerciseMode.Solo : ExerciseMode.Group}
              exerciseType={ExerciseType.Stations}
              icon={solo ? <AccountIcon /> : <AccountGroupIcon />}
              mainHeader="Select station"
            />
            <ExerciseBuilder
              activeCategory={activeCategory}
              categoryOptions={categories}
              checkedIcon={customIcon}
              columns={columns}
              data={stationsToDisplay ?? []}
              icon={customIcon}
              isCandidate={isCandidate}
              loading={loading || !stationsToDisplay}
              onSearch={handleSearch}
              onSelectRow={handleSelectStation}
              onToggleCategory={handleActiveCategory}
              onToggleView={handleActiveView}
              resetSearch={previousCategory !== activeCategory}
              searchLabel="Search by station names"
              selectedItemId={selectedStationId}
              selectedView={activeView}
              title={activeCategoryLabel}
              viewOptions={BUILDER_VIEWS}
            />
          </PreLaunchLayout>
          <Fade in={showSelectionBar} unmountOnExit>
            <BottomSelectionBar
              continueLabel={SelectionBarLabels.Continue}
              onContinue={isExaminer ? handleOpenTimerModal : null}
              onInstructions={
                !isCandidate || solo ? handleOpenInstructions : null
              }
              onReviewOrSavePreset={
                solo && lastOsceMarksheetId ? handleReviewStudy : null
              }
              onUnselect={isExaminer ? handleUnselectStation : null}
              onWalkthrough={handleOpenWalkthrough}
              reviewOrPresetLabel={SelectionBarLabels.ReviewLastSession}
              selectedItemsLabel={selectedItems}
              unselectlabel={SelectionBarLabels.Unselect}
              withoutPanel={solo}
            />
          </Fade>
          {showTimerModal ? (
            <StationsTimerModal
              marksheetId={getOsceMarksheetId(
                solo,
                osceMarksheetId,
                initialSoloOsceMarksheetId
              )}
              onClose={handleCloseTimerModal}
              open={showTimerModal}
              solo={solo}
              stationId={selectedStationId}
            />
          ) : null}
          <CandidateInstructionsModal
            instructions={previewInstructions || instructions}
            onClose={handleCloseInstructions}
            open={!!previewStationId || showInstructions}
            role={getRole()}
          />
          {selectedStation && showWalkthrough ? (
            <Modal
              maxWidth="md"
              noPaddingY
              onClose={handleCloseWalkthrough}
              open={showWalkthrough}
              showCloseButton
              sizeVariant="md"
              title="Walkthrough"
            >
              <Markdown text={selectedStation.explanation} />
            </Modal>
          ) : null}
          {solo && open ? (
            <StationsLeaveModal
              discard
              open={open}
              osceMarksheetId={osceMarksheetId}
            />
          ) : null}
        </>
      }
      exerciseItemsCount={participants?.length}
      floatingPanel
      onLeave={handleLeave}
      withSound={!solo}
      withoutPanel={solo}
      {...(!solo && PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS)}
    />
  );
};

export default StudyBuilder;
