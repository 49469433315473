import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { IMarksheet, IOsceMarksheet } from '@quesmed/types-rn/models';

import useLazyMarksheetsWindowQuery from '../hooks/lazyUseMarksheetsWindowQuery';
import useLazyOsceWindowQuery from '../hooks/lazyOsceWindowQuery';
import NoDataCard from './NoDataCard';
import { ClipboardTextClockOutlineIcon } from 'components/Icons';
import { isNear } from 'utils/scroll/handleScroll';
import HistoryCardHeader from './HistoryCardHeader';
import ActivityHistoryTable from './ActivityHistoryTable';
import { ActivityData } from 'types';
import { OSCEHEADROW, QBANKHEADROW } from './constants';
import { Card } from 'components/Card';
import { usePlatform } from 'context/PlatformContext';
import {
  isMarksheets,
  isOsceMarksheets,
  isStationsProduct,
  uniqueArrayObjects,
} from 'utils';
import StationsHistoryTableRow from './StationsHistoryTableRow';
import QuestionsHistoryTableRow from './QuestionsHistoryTableRow';
import { SkeletonList } from 'components/Skeleton';

interface IHistoryCardProps {
  solo: boolean;
  data: ActivityData;
}

const LIMIT = 20;

const setFilter = (updatedAt: number | Date, isSolo: boolean) => ({
  filter: {
    limit: LIMIT,
    solo: isSolo,
    updatedAt,
    period: 0,
  },
});

const setLazyFilter = (updatedAt: number | Date, isSolo: boolean) => ({
  filter: {
    limit: LIMIT,
    solo: isSolo,
    updatedAt,
    period: 0,
  },
});

const flattenResults = (data: ActivityData) =>
  data.flatMap(({ result }) => (result.length ? [...result] : []));

const HistoryCard = ({ solo, data }: IHistoryCardProps) => {
  const flattenedData = flattenResults(data);
  const { product } = usePlatform();
  const numberOfInitialItems = flattenedData?.length ?? 0;
  const isStationProduct = isStationsProduct(product);
  const [localData, setLocaldata] =
    useState<(IMarksheet | IOsceMarksheet)[]>(flattenedData);

  // To show filtered data using search option in history tab
  const [displayData, setDisplayData] =
    useState<(IMarksheet | IOsceMarksheet)[]>(flattenedData);
  const [preventFetch, setPreventFetch] = useState(false);

  const tableRef = useRef<HTMLTableElement>();
  const [lastItemUpdatedAt, setLastItemUpdatedAt] = useState<number>(0);

  const [getMarksheets, { loading: qbankLoading }] =
    useLazyMarksheetsWindowQuery(solo, lastItemUpdatedAt);

  const [getStations, { loading: stationsLoading }] = useLazyOsceWindowQuery(
    solo,
    lastItemUpdatedAt
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollDebounce = useCallback(
    debounce(() => {
      const near = isNear(tableRef);
      if (near) {
        setLastItemUpdatedAt(
          (displayData?.[displayData?.length - 1].updatedAt || 0) as number
        );
      }
    }, 100),
    [displayData]
  );

  useEffect(() => {
    tableRef.current?.addEventListener('scroll', handleScrollDebounce, true);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tableRef.current?.removeEventListener(
        'scroll',
        handleScrollDebounce,
        true
      );
    };
  }, [handleScrollDebounce]);

  const handleOnCompleted = useCallback((results: ActivityData) => {
    const flattenedResults = flattenResults(results);
    setLocaldata(prev =>
      uniqueArrayObjects([...prev, ...flattenedResults], 'id')
    );

    if (flattenedResults.length < LIMIT) {
      setPreventFetch(true);
    }
  }, []);

  useEffect(() => {
    /**
     * This hook is set to execute whenever the history for
     * the last 7 days has less than 8 items it makes it seem
     * like the user doesn't need to scroll any further.
     */

    if (data && numberOfInitialItems >= 0 && numberOfInitialItems < 8) {
      if (isStationProduct) {
        getStations({
          variables: setFilter(data[data.length - 1].date, solo),
          onCompleted: data => {
            const { results } = data?.restricted?.osceMarksheetsWindow || {};
            handleOnCompleted(results);
          },
        });
      } else {
        getMarksheets({
          variables: setFilter(data[data.length - 1].date, solo),
          onCompleted: data => {
            const { results } = data?.restricted?.marksheetsWindow || {};
            handleOnCompleted(results);
          },
        });
      }
    }
    // TODO we should rebuild activity widget just base on cache
    // and these useEffect will be unnecessary for now to provide
    // quick fix for PROD I ony replace global rule disabling
    // andd added rules in place to lint other places in the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, numberOfInitialItems, isStationProduct]);

  useEffect(() => {
    if (lastItemUpdatedAt !== 0 && !preventFetch) {
      if (isStationProduct) {
        getStations({
          variables: setLazyFilter(lastItemUpdatedAt, solo),
          onCompleted: data => {
            const { results } = data?.restricted?.osceMarksheetsWindow || {};
            handleOnCompleted(results);
          },
        });
      } else {
        getMarksheets({
          variables: setLazyFilter(lastItemUpdatedAt, solo),

          onCompleted: data => {
            const { results } = data?.restricted?.marksheetsWindow || {};
            handleOnCompleted(results);
          },
        });
      }
    }
    // TODO we should rebuild activity widget just base on cache
    // and these useEffect will be unnecessary for now to provide
    // quick fix for PROD I ony replace global rule disabling
    // andd added rules in place to lint other places in the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastItemUpdatedAt, isStationProduct, preventFetch]);

  useEffect(() => {
    setDisplayData(localData);
  }, [localData]);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setPreventFetch(true);
    } else {
      setPreventFetch(false);
    }

    if (data) {
      if (isMarksheets(localData)) {
        const filtered = localData.filter(({ topicNames }) =>
          topicNames?.length
            ? topicNames
                .join('')
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            : false
        );
        setDisplayData(filtered);

        return;
      }

      if (isOsceMarksheets(localData)) {
        const filtered = localData.filter(({ osceStation }) =>
          osceStation?.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setDisplayData(filtered);
      }
    }
  };

  if (isOsceMarksheets(displayData)) {
    return (
      <Card>
        <HistoryCardHeader onSearch={handleSearch} />
        <ActivityHistoryTable
          headerOptions={OSCEHEADROW}
          results={displayData}
          rowComponent={StationsHistoryTableRow}
          tableHeight="480"
          tableRef={tableRef}
        />
      </Card>
    );
  }

  if (isMarksheets(displayData)) {
    return (
      <Card>
        <HistoryCardHeader onSearch={handleSearch} />
        <ActivityHistoryTable
          headerOptions={QBANKHEADROW}
          results={displayData}
          rowComponent={QuestionsHistoryTableRow}
          tableHeight="480"
          tableRef={tableRef}
        />
      </Card>
    );
  }

  if (qbankLoading || stationsLoading) {
    return <SkeletonList count={6} height={56} spacing={4} />;
  }

  return (
    <Card>
      <HistoryCardHeader onSearch={handleSearch} />
      <NoDataCard
        icon={<ClipboardTextClockOutlineIcon />}
        text="Every time you complete an exercise, we'll save it here so you can easily pick up where you left off."
        title={`No ${isStationProduct ? 'stations' : 'questions'} history yet`}
      />
    </Card>
  );
};

export default HistoryCard;
