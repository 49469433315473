import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CheckboxState, TableData } from 'types';
import { GridRow } from 'components/GridRow';
import { GridCell } from 'components/GridCell';
import { setSummaryColumnTemplate } from './setSummaryColumnTemplate';
import { SummaryColumn, SummaryTableKey } from './types';
import { Checkbox } from 'components/Checkbox';
import { useLearningPointsCheckState } from 'pages/Questions/context';
import { Tooltip } from 'components/Tooltip';

const Label = styled(Box)(({ theme: { palette, spacing, typography } }) => ({
  color: palette.text.primary,
  ...typography.body2Medium,
  display: 'flex',
  gap: spacing(1),

  '&::first-letter': {
    textTransform: 'uppercase',
  },

  '& .satisfactory': {
    color: palette.success.main,
  },

  '& .borderline': {
    color: palette.warning.main,
  },

  '& .unsatisfactory': {
    color: palette.error.main,
  },
}));

interface ExerciseSummaryTableHeadProps<T extends TableData> {
  columns: SummaryColumn<T>[];
  withoutOffset?: boolean;
}

function ExerciseSummaryTableHead<T extends TableData>({
  columns,
  withoutOffset,
}: ExerciseSummaryTableHeadProps<T>): JSX.Element {
  const { allSelected, deselectAll, selectAll, selectAllAnswered } =
    useLearningPointsCheckState();

  const toggleSelectAll = () => {
    if (allSelected === CheckboxState.UNCHECKED) {
      selectAllAnswered();
    } else if (allSelected === CheckboxState.INTERMEDIATE) {
      selectAll();
    } else {
      deselectAll();
    }
  };

  const getSelectAllTooltip = () => {
    if (allSelected === CheckboxState.UNCHECKED) {
      return 'Select all (answered)';
    } else if (allSelected === CheckboxState.INTERMEDIATE) {
      return 'Select all';
    }

    return 'Deselect all';
  };

  const getHeaders = (key: SummaryTableKey, labelIcon: ReactNode) => {
    switch (key) {
      case SummaryTableKey.Chevron:
        return null;
      case SummaryTableKey.Checkbox:
        return (
          <Tooltip placement="top" textCenter title={getSelectAllTooltip()}>
            <Checkbox
              checkedState={allSelected}
              controlSx={{ marginRight: 0 }}
              onChange={toggleSelectAll}
            />
          </Tooltip>
        );
      default:
        return (
          <Label>
            {key} {labelIcon}
          </Label>
        );
    }
  };

  return (
    <GridRow
      className={withoutOffset ? '' : 'with-offset'}
      columns={columns}
      setColumnTemplate={setSummaryColumnTemplate}
    >
      {columns.map(({ align, key, type, labelIcon }) => (
        <GridCell align={align} key={key as string} type={type}>
          {getHeaders(key, labelIcon)}
        </GridCell>
      ))}
    </GridRow>
  );
}

export default ExerciseSummaryTableHead;
