import React from 'react';
import {
  EProductType,
  IMarksheet,
  IOsceMarksheet,
} from '@quesmed/types-rn/models';

import { ActivityData, ExerciseMode, Nullable } from 'types/index';
import ActivityHistoryTable from './ActivityHistoryTable';
import {
  formatDate,
  isMarksheets,
  isOsceMarksheets,
  isStationsProduct,
} from 'utils';
import NoDataCard from './NoDataCard';
import { OSCEHEADROW, QBANKHEADROW } from './constants';
import { BullseyeArrowIcon } from 'components/Icons';
import StationsHistoryTableRow from './StationsHistoryTableRow';
import QuestionsHistoryTableRow from './QuestionsHistoryTableRow';

interface IGraphControllerProps {
  data: ActivityData;
  clickedBar: string;
  exerciseMode: ExerciseMode;
  product?: Nullable<EProductType>;
}

interface WithDate {
  date: number | Date;
  result: IMarksheet[] | IOsceMarksheet[];
}

const changeText = (exerciseMode: ExerciseMode) =>
  `This feature will make it easier for you to keep track of your ${exerciseMode.toLowerCase()} statistics from the past week.`;

const CardTableController = ({
  data,
  clickedBar,
  exerciseMode,
  product,
}: IGraphControllerProps) => {
  const results = (data as WithDate[]).find(
    day => formatDate(day.date) === clickedBar
  )?.result;

  if (results?.length) {
    if (isOsceMarksheets(results)) {
      return (
        <ActivityHistoryTable
          headerOptions={OSCEHEADROW}
          results={results}
          rowComponent={StationsHistoryTableRow}
        />
      );
    }

    if (isMarksheets(results)) {
      return (
        <ActivityHistoryTable
          headerOptions={QBANKHEADROW}
          results={results}
          rowComponent={QuestionsHistoryTableRow}
        />
      );
    }
  }

  const isStationProduct = isStationsProduct(product);

  return (
    <NoDataCard
      icon={<BullseyeArrowIcon />}
      isTrackerTab
      text={changeText(exerciseMode)}
      title={`You haven't ${
        isStationProduct ? 'completed any stations' : 'answered any questions'
      } in the past week`}
    />
  );
};

export default CardTableController;
