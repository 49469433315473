import React from 'react';
import { styled } from '@mui/system';
import { EQuestionLike } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';

import LikeButton from './LikeButton';
import { Undefinable } from 'types';

type LikeCountType = Undefinable<number>;

export interface LikesProps {
  likes: LikeCountType;
  dislikes: LikeCountType;
  isLikedByMe: Undefinable<EQuestionLike>;
  handleLike: (like: boolean) => () => void;
  showCount?: boolean;
}

export const Row = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
}));

const Likes = ({
  likes,
  dislikes,
  isLikedByMe = EQuestionLike.NONE,
  handleLike,
  showCount,
}: LikesProps): JSX.Element => (
  <Row>
    <LikeButton
      like={isLikedByMe}
      likesCount={likes}
      onClick={handleLike(true)}
      showCount={showCount}
      type={EQuestionLike.LIKE}
    />
    <LikeButton
      like={isLikedByMe}
      likesCount={dislikes}
      onClick={handleLike(false)}
      showCount={showCount}
      type={EQuestionLike.DISLIKE}
    />
  </Row>
);

export default Likes;
