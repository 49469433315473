import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Body } from 'components/Typography';

export const Name = styled(Body)<{ scale?: number }>(
  ({ theme: { palette, spacing, typography }, scale = 1 }) => ({
    marginRight: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(2),
    fontSize: `calc(${typography.body1.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body1.lineHeight} * ${scale})`,

    '&.admin': {
      color: palette.products.AMS.light,
    },

    '& .you': {
      color: palette.text.secondary,
    },
  })
);

export const CommentDate = styled(Body)<{ scale?: number }>(
  ({ theme: { typography }, scale = 1 }) => ({
    fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,
  })
);

export const CommentBodyContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    paddingRight: spacing(2),
    overflow: 'hidden',
    width: '100%',

    '&.reply': {
      paddingRight: spacing(2),
      marginBottom: spacing(2),
      paddingLeft: spacing(6),
      borderLeft: `1px solid ${palette.stroke.main}`,

      '&:first-of-type': {
        marginTop: spacing(2),
      },

      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  })
);

export const NameDateContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const StyledCommentText = styled(Body)<{ scale?: number }>(
  ({ theme: { typography, spacing }, scale = 1 }) => ({
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,
    paddingBottom: spacing(2),
  })
);

export const Actions = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  button: {
    gap: spacing(2),
  },
}));

export const ActionsLeft = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
}));
