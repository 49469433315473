import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserLearningStatus, IVideo } from '@quesmed/types-rn/models';

import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { useQbankVideoLibraryData } from './QbankVideoLibrary';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import {
  getItemsMap,
  Items,
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbs,
  LearningMaterialsData,
  PanelItems,
  useUpdateVideoLearningStatus,
  VIDEO_SEARCH_LABEL,
} from 'components/LearningMaterials';
import { Nullable, PathName } from 'types';
import {
  SelectionCategories,
  SelectionHeading,
} from 'components/ExerciseBuilder';
import { BookshelfIcon, FolderOpenOutlineIcon } from 'components/Icons';
import { paths } from 'Router';

const { videoLibrary } = paths;

const QbankVideoLibrarySection = () => {
  const { sectionId: topicId } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const {
    activeCategory,
    activeCategoryLabel,
    resetSearch,
    topics,
    searchBy,
    switchCategory,
    categories,
    loading,
  } = useQbankVideoLibraryData();
  const [displayedVideos, setDisplayedVideos] =
    useState<Nullable<IVideo[]>>(null);
  const { updateStatus } = useUpdateVideoLearningStatus();
  const [term, setTerm] = useState('');

  const topic = topics?.find(({ id }) => Number(id) === Number(topicId));

  const { videos, name = '', entitlement } = topic || {};

  const setVideos = useCallback(
    (text: string) => {
      const normalizedSearchString = text.toLowerCase().trim();
      if (videos && text) {
        const filtered = videos.filter(({ title }) =>
          title.toLowerCase().includes(normalizedSearchString)
        );

        return setDisplayedVideos(filtered);
      }

      setDisplayedVideos(videos || null);
    },
    [videos]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      setTerm(searchString);
      setVideos(searchString);
    },
    [setVideos]
  );

  const handleNavigate = useCallback(
    (videoId: number) => () => {
      navigate(
        `${videoLibrary.root}/video/${entitlement?.id}/${topicId}/${videoId}`
      );
    },
    [entitlement?.id, navigate, topicId]
  );

  const handleNavigateBack = useCallback(() => {
    navigate(videoLibrary.root);
  }, [navigate]);

  const handleStatusChange = useCallback(
    async (
      newStatus: EUserLearningStatus,
      ids: number[],
      currentStatus?: Nullable<EUserLearningStatus>
    ) => updateStatus(ids, newStatus, currentStatus),
    [updateStatus]
  );

  useEffect(() => {
    setVideos(term);
  }, [setVideos, term]);

  const videosMap = useMemo(
    () => getItemsMap(displayedVideos),
    [displayedVideos]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
        onClick: handleNavigateBack,
      },
      { icon: FolderOpenOutlineIcon, label: name },
    ],
    [activeCategoryLabel, handleNavigateBack, name]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          entitlementId={entitlement?.id}
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchLabel={VIDEO_SEARCH_LABEL}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.VideoLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader mainHeader={PathName.VideoLibrary} />
          <SelectionCategories
            activeCategory={activeCategory}
            categoryOptions={categories}
            onToggleCategory={switchCategory}
          />
          <SelectionHeading
            loading={loading}
            onSearch={handleSearch}
            resetSearch={resetSearch}
            searchLabel={VIDEO_SEARCH_LABEL}
            title={<LearningMaterialsBreadcrumbs header items={breadcrumbs} />}
          />
          <Items
            itemsMap={videosMap}
            navigate={handleNavigate}
            onStatusChange={handleStatusChange}
            showFallback={!displayedVideos?.length}
            title="Video titles"
            video
          />
        </PreLaunchLayout>
      }
    />
  );
};

export default QbankVideoLibrarySection;
