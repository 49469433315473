import React from 'react';
import { mdiClipboardTextClockOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ClipboardTextClockOutlineIcon = (props: IconProps) => (
  <Icon path={mdiClipboardTextClockOutline} {...props} />
);

export default ClipboardTextClockOutlineIcon;
