import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { AutocompleteField } from 'components/AutocompleteField';
import { TextInputRef } from 'components/TextField';
import { Body } from 'components/Typography';

export const PrescriptionContainer = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  gap: spacing(6),
  display: 'flex',
  flexWrap: 'wrap',
}));

export const Units = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.disabled,
}));

export const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
}));

export const StyledInputField = styled(TextInputRef)(
  ({ theme: { palette } }) => ({
    width: 'calc(50% - 12px)',
    minWidth: '120px',
    flexGrow: 1,

    '& .MuiInputLabel-root': {
      color: palette.text.disabled,
    },

    '&.drug, &.duration': {
      width: '100%',
    },

    '&.drug': {
      order: -6,
    },

    '&.duration': {
      order: 1,
    },
    '&.dose': {
      order: -3,
    },
    '&.units': {
      order: -2,
    },
    '&.full-width': {
      width: '100%',
    },
    // disabling the up/down arrows on type number inputs
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    'input[type=number]': {
      MozAppearance: 'textfield' /* Firefox */,
    },
  })
);

export const StyledAutocompleteField = styled(AutocompleteField)(
  ({ theme: { palette } }) => ({
    width: 'calc(50% - 12px)',
    minWidth: '120px',
    flexGrow: 1,

    '& .MuiInputLabel-root': {
      color: palette.text.disabled,
    },

    '&.drug, &.duration': {
      width: '100%',
    },

    '&.drug': {
      order: -6,
    },

    '&.duration': {
      order: 1,
    },
    '&.dose': {
      order: -3,
    },
    '&.units': {
      order: -2,
    },
    '&.full-width': {
      width: '100%',
    },
    // disabling the up/down arrows on type number inputs
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    'input[type=number]': {
      MozAppearance: 'textfield' /* Firefox */,
    },
  })
);
