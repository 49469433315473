import { LineChartItem } from 'components/ProgressLineChart';
import { ItemStatusLabel, Nilable } from 'types';

export const setQuestionsProgressData = (
  correctQuestions: Nilable<number>,
  incorrectQuestions: Nilable<number>
): LineChartItem[] => [
  {
    type: ItemStatusLabel.Confident,
    value: correctQuestions,
    label: ItemStatusLabel.Correct,
  },
  {
    type: ItemStatusLabel.ToLearn,
    value: incorrectQuestions,
    label: ItemStatusLabel.Incorrect,
  },
];
