import { IHighlightNode, IPicture } from '@quesmed/types-rn/models';

import { addLeadingZeros } from './addLeadingZeros';
import { insertCharAtIndex } from './insertCharAtIndex';
import { parseFullText } from './parseReading';

/**
 * This function will add mark html tags at start and end position to highlight text
 * After each iteration, string length will change, so start and end position is calculated respectively
 * addLeadingZeros is added to id, so that id length will always be same for all numbers such as [1->00001 ,11-> 00011, 100-> 00100]
 */

const closingTag = '</mark>';

export function addMarkTagsInMarkdown(
  markdown: string,
  highlights: IHighlightNode[],
  pictures: IPicture[] | undefined,
  withQuestionImage?: boolean
) {
  if (!highlights.length) {
    return parseFullText(markdown, pictures, withQuestionImage);
  }

  let newText = `${markdown}`;
  const orderedHighlights = [...highlights].sort((a, b) => a.start - b.start);

  for (let i = 0; i < orderedHighlights.length; i++) {
    const obj = orderedHighlights[i];
    if (markdown.slice(obj.start, obj.end + 1) !== obj.text) {
      continue;
    }
    const id = addLeadingZeros(i, 5);
    const openingTag = `<mark id=${id}>`;
    const start = obj.start + i * closingTag.length + i * openingTag.length;
    const end =
      obj.end + i * closingTag.length + (i + 1) * openingTag.length + 1;
    newText = insertCharAtIndex(newText, start, openingTag);
    newText = insertCharAtIndex(newText, end, closingTag);
  }

  return parseFullText(newText, pictures, withQuestionImage);
}
