import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { Content, ViewContainer } from './MockTestsLayoutStyles';
import { MockTestsFooter } from './MockTestsFooter';
import { MockTestsTopBar } from './MockTestsTopBar';
import { MockTestToolBar } from './MockTestsToolBar';
import { ErrorBoundary } from 'components/Error';
import { FeedbackModal, useFeedbackModal } from 'components/FeedbackModal';

export const MockTestsLayout = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { open, closeFeedbackModal } = useFeedbackModal();

  if (ref?.current) {
    ref.current.scroll({ top: 0 });
  }

  return (
    <>
      <MockTestsTopBar />
      <MockTestToolBar />
      <Content ref={ref}>
        <ViewContainer>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </ViewContainer>
      </Content>
      <MockTestsFooter />
      {open ? <FeedbackModal onClose={closeFeedbackModal} open={open} /> : null}
    </>
  );
};
