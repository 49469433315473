import { useCallback } from 'react';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import { MODIFY_MARKSHEET_MARK_QUESTIONCHOICEID } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { MODIFY_MARKSHEET_MARK_QUESTIONCHOICEID_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetMarkMutation from './useModifyMarksheetMarkMutation';

const useSelectChoice = (marksheetId: number, markId: number) => {
  const [modify] = useModifyMarksheetMarkMutation(
    MODIFY_MARKSHEET_MARK_QUESTIONCHOICEID,
    MODIFY_MARKSHEET_MARK_QUESTIONCHOICEID_FRAGMENT
  );

  const selectChoice = useCallback(
    (questionChoiceId?: number) => {
      if (marksheetId) {
        modify({
          variables: {
            input: {
              action: EMarksheetAction.CHOICE_SELECTED,
              markId,
              questionChoiceId,
              marksheetId,
            },
          },
        });
      }
    },
    [marksheetId, markId, modify]
  );

  return selectChoice;
};

export default useSelectChoice;
