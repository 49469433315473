import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

import {
  TextField,
  TextFieldProps,
  TextInputProps,
} from 'components/TextField/TextField';

type FormFieldProps<T extends FieldValues> = TextFieldProps<T> &
  TextInputProps &
  PatternFormatProps;

export function NumberField<T extends FieldValues>({
  control,
  defaultValue,
  rules,
  name,
  helperText,
  shouldUnregister,
  format,
  ...rest
}: FormFieldProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => (
        <PatternFormat
          {...rest}
          customInput={TextField}
          error={Boolean(error)}
          format={format}
          helperText={error ? error.message : helperText}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
