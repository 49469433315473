import { useQuery } from '@apollo/client';
import { ESortOrder } from '@quesmed/types-rn';
import {
  IOsceMarksheetsWindowData,
  IOsceMarksheetsWindowVar,
  OSCE_MARKSHEET_WINDOW,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useOsceWindowQuery = (soloMode: boolean, updatedAt?: number | Date) => {
  const { data, loading, client } = useQuery<
    IOsceMarksheetsWindowData,
    IOsceMarksheetsWindowVar
  >(OSCE_MARKSHEET_WINDOW, {
    variables: {
      filter: {
        limit: 300,
        solo: soloMode,
        updatedAt: updatedAt === 0 ? undefined : updatedAt,
        period: 7,
        order: ESortOrder.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { data, loading, client };
};

export default useOsceWindowQuery;
