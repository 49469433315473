import React, { SyntheticEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Rating as MuiRating } from '@mui/material';

const RatingContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(2),

  '&.margin-bottom': {
    marginBottom: spacing(6),
  },
}));

interface RatingProps {
  className?: string;
  onRatingChange: (rating: number | null) => void;
}

const Rating = ({ className, onRatingChange }: RatingProps) => {
  const [value, setValue] = useState<number | null>(null);

  const handleChange = (_: SyntheticEvent, newValue: number | null) => {
    onRatingChange(newValue);
    setValue(newValue);
  };

  return (
    <RatingContainer className={className}>
      <MuiRating
        max={10}
        name="hover-feedback"
        onChange={handleChange}
        size="large"
        value={value}
      />
    </RatingContainer>
  );
};

export default Rating;
