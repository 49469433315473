import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { Body, H4 } from 'components/Typography';
import { PathName } from 'types';
import { ExpandableSection } from 'components/ExpandableSection';
import { paths } from 'Router';
import { FilterField } from 'components/FilterField';
import { useStationNotesQuery } from '../hooks';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { isNear } from 'utils/scroll/handleScroll';

const { knowledgeLibrary } = paths;
const { root } = knowledgeLibrary;

const Container = styled(Box)(
  ({ theme: { breakpoints, mixins, transitions, spacing } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    margin: '0 auto',
    maxWidth: `calc(${mixins.container.maxWidth}px + 48px)`,
    padding: spacing(8, 6, 8),
    transition: transitions.create(['padding']),
    height: `calc(100dvh - ${mixins.appBar.mobile.minHeight}px)`,
    overflow: 'auto',

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${mixins.appBar.desktop.minHeight}px)`,
    },

    [breakpoints.up('xl')]: {
      maxWidth: `calc(${mixins.container.maxWidth}px + 80px)`,
      padding: spacing(8, 10, 8),
    },
  })
);

const Header = styled(H4)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(2),
}));

const ContentContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: '7px',
    backgroundColor: palette.background.paper,
    padding: spacing(4),

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
      flexWrap: 'nowrap',
      gap: spacing(4),
    },

    [breakpoints.up('xl')]: {
      flexWrap: 'nowrap',
      padding: spacing(6, 8, 8),
    },
  })
);

const SearchContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
}));

const NotesContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

const StyledNote = styled(Body)({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  margin: 0,
  padding: 0,
  overflow: 'hidden',
});

const BreadcrumbItemComponent = styled(Box)(({ theme: { palette } }) => ({
  textDecoration: 'underline',
  color: palette.text.primary,
}));

const StationNotes = () => {
  const [offset, setOffset] = useState(20);
  const [limitReached, setLimitReached] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { notes, searchStationNotes, searchedNotes, loadMoreStationNotes } =
    useStationNotesQuery({
      onAllStationNotesFetched: () => setLimitReached(true),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollDebounce = useCallback(
    debounce(() => {
      const near = isNear(containerRef);
      if (near && !limitReached) {
        loadMoreStationNotes(offset);
        setOffset(prev => prev + 20);
      }
    }, 100),
    [offset, limitReached]
  );

  useEffect(() => {
    const ref = containerRef.current;
    ref?.addEventListener('scroll', handleScrollDebounce, true);

    return () => {
      ref?.removeEventListener('scroll', handleScrollDebounce, true);
    };
  }, [handleScrollDebounce]);

  const handleSearch = (text: string) => {
    if (text) {
      searchStationNotes(text);
    }
    setSearchTerm(text);
  };

  return (
    <Container ref={containerRef}>
      <Header>{PathName.MyNotes}</Header>
      <ContentContainer>
        <SearchContainer>
          <FilterField onSearch={handleSearch} searchLabel="Search" />
        </SearchContainer>
        <NotesContainer>
          {(searchTerm ? searchedNotes : notes).map(
            ({ station, stationId, id, note }) => {
              const items = [
                {
                  label: station.entitlement?.name || '',
                  onClick: () =>
                    navigate(
                      `${root}/section/${station.entitlement?.id}/${station.entitlement?.id}`
                    ),
                },
                {
                  label: station.name,
                  onClick: () =>
                    navigate(
                      `${root}/chapter/${station.entitlement?.id}/${station.entitlement?.id}/${stationId}`,
                      { state: { fromMyNotes: true } }
                    ),
                },
              ];

              return (
                <ExpandableSection
                  clickableTitle
                  expanded
                  key={id}
                  title={
                    <Breadcrumbs
                      breadcrumbItemComponent={BreadcrumbItemComponent}
                      items={items}
                    />
                  }
                >
                  <StyledNote>{note}</StyledNote>
                </ExpandableSection>
              );
            }
          )}
        </NotesContainer>
      </ContentContainer>
    </Container>
  );
};

export default StationNotes;
