import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { IMarksheetMark } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal, ModalProps } from 'components/Modal/Modal';
import { ExerciseSummaryTable } from 'components/ExerciseSummary';
import { getSummaryColumns } from './helpers';
import { paths } from 'Router';
import { MockTestRouter } from 'types';

const { mockTests } = paths;

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing(1, -8, 0),
  gap: spacing(6),
}));

interface MockTestNavigatorModalProps
  extends Pick<ModalProps, 'open' | 'onClose'> {
  title: string;
  marks: IMarksheetMark[];
  isReview: boolean;
}

export const MockTestNavigatorModal = ({
  open,
  title,
  onClose,
  marks,
  isReview,
}: MockTestNavigatorModalProps) => {
  const { marksheetId = '', mockTestId = '' } = useParams<MockTestRouter>();
  const columns = useMemo(() => getSummaryColumns(isReview, true), [isReview]);
  const navigate = useNavigate();

  const handleGoToQuestion = (mark: IMarksheetMark) => {
    const { id: markId, question } = mark;
    const { id: questionId } = question;

    const index = marks?.findIndex(({ id }) => Number(id) === Number(markId));

    if (!isNil(index)) {
      navigate(
        `${mockTests.root}/test/${mockTestId}/${marksheetId}/${markId}/${questionId}`
      );
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      disableContentScroll
      headerSx={({ spacing }) => ({
        padding: { xs: spacing(0, 4), md: spacing(0, 8) },
        minHeight: { xs: spacing(16), md: spacing(0, 16) },
      })}
      maxWidth="xl"
      noPaddingY
      onClose={onClose}
      open={open}
      showCloseButton
      showControls={false}
      sizeVariant="xl"
      title={title}
    >
      <StyledBox>
        <ExerciseSummaryTable
          columns={columns}
          data={marks}
          onClick={handleGoToQuestion}
          withPagination={false}
        />
      </StyledBox>
    </Modal>
  );
};
