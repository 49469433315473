export const correctCase = (
  emqAnswer: [string, string][],
  parsedAnswer: [string, string][],
  label: string
) => {
  const userOrder = new Map(parsedAnswer);
  const correctOrder = new Map(emqAnswer);

  return userOrder.get(label) === correctOrder.get(label);
};
