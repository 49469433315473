import { useMemo } from 'react';
import {
  EProductType,
  ETopicType,
  IEntitlement,
  IVideo,
} from '@quesmed/types-rn/models';

import {
  QbankVideoLibraryData,
  VideoTopic,
  VideoTopicMap,
} from 'components/LearningMaterials/types';
import { Nullable } from 'types';
import {
  compareTitles,
  entries,
  sortCategoriesByTopicType,
  values,
} from 'utils';
import { useQbankKnowledgeVideoLibraryQuery } from './useQbankKnowledgeVideoLibraryQuery';

const getVideos = (
  topicId: number,
  entitlement: Nullable<IEntitlement>,
  concepts?: { videos?: IVideo[] }[]
) => {
  if (!concepts) {
    return;
  }

  const videosData: {
    [key: number]: IVideo & {
      topicId: number;
      entitlement: Nullable<IEntitlement>;
    };
  } = {};

  concepts.forEach(({ videos }) => {
    videos?.forEach(video => {
      const { id } = video;
      if (!videosData[id]) {
        videosData[id] = { ...video, topicId, entitlement };
      }
    });
  });

  return values(videosData).sort(compareTitles);
};

const filterTopicByVideo = (topics?: Nullable<VideoTopic[]>) => {
  if (!topics) {
    return null;
  }

  return topics
    .map(({ concepts, id, entitlement, ...rest }) => ({
      ...rest,
      entitlement,
      id,
      concepts,
      videos: getVideos(id, entitlement, concepts),
    }))
    .filter(({ videos }) => videos?.length);
};

export const useQbankVideoLibrary = (): QbankVideoLibraryData => {
  const [topics, loading] = useQbankKnowledgeVideoLibraryQuery(
    [ETopicType.ALL],
    true
  );

  const filteredTopics = useMemo(() => filterTopicByVideo(topics), [topics]);

  const [map, categories] = useMemo(() => {
    if (!filteredTopics) {
      return [null, []];
    }

    const topicTypes = {} as {
      [key in EProductType]: string;
    };

    const map: VideoTopicMap = {};
    const typeOrder: number[] = [];

    filteredTopics.forEach(topic => {
      const { entitlement } = topic;

      if (entitlement.id) {
        if (!typeOrder[entitlement.index]) {
          typeOrder[entitlement.index] = entitlement.id;
        }

        if (!topicTypes[entitlement.id]) {
          topicTypes[entitlement.id] = entitlement.name;
        }

        if (map[entitlement.id]) {
          map[entitlement.id].push(topic);
        } else {
          map[entitlement.id] = [topic];
        }
      }
    });

    const categories = sortCategoriesByTopicType(
      entries(topicTypes).map(([key, value]) => ({
        value: Number(key),
        label: value,
      })),
      typeOrder
    );

    return [map, categories];
  }, [filteredTopics]);

  return {
    topics: filteredTopics,
    loading,
    topicsMap: map,
    categories,
  };
};
