import { EQuestionLike } from '@quesmed/types-rn/models';

export const setLikeState = (
  likeClick: boolean,
  isLikedByMe?: EQuestionLike
): EQuestionLike => {
  if (likeClick) {
    return isLikedByMe === EQuestionLike.LIKE
      ? EQuestionLike.NONE
      : EQuestionLike.LIKE;
  }

  return isLikedByMe === EQuestionLike.DISLIKE
    ? EQuestionLike.NONE
    : EQuestionLike.DISLIKE;
};
