import { useQuery } from '@apollo/client';
import {
  HEARTBEAT,
  IHeartBeatData,
  IHeartbeatVar,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { memo } from 'react';

import { Nullable } from 'types';

interface HeartbeatProps {
  sessionId?: Nullable<string>;
}

const Heartbeat = ({ sessionId }: HeartbeatProps): null => {
  useQuery<IHeartBeatData, IHeartbeatVar>(HEARTBEAT, {
    variables: {
      sessionId: sessionId as string,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 2000,
    fetchPolicy: 'no-cache',
    skip: !sessionId,
  });

  return null;
};

export default memo(Heartbeat);
