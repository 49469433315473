import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  END_OSCE_MARKSHEET,
  IEndOsceMarksheetData,
  IEndOsceMarksheetVar,
  optimisticEndOsceMarksheet,
  updateCacheOnEndOsceMarksheet,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  EOsceMarksheetAction,
  EOsceMarksheetState,
  EProductType,
  IOsceMarksheet,
} from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import useModifyOsceMarksheet from './useModifyOsceMarksheet';
import { usePlatform } from 'context/PlatformState';

const { stations } = paths;

const { root } = stations;

interface EndStationConfig {
  state: EOsceMarksheetState;
  osceMarksheet: IOsceMarksheet;
  solo?: boolean;
}

export const useEndStation = ({
  state,
  osceMarksheet,
  solo,
}: EndStationConfig) => {
  const { id } = osceMarksheet;
  const [isCompleted, setIsCompleted] = useState(false);
  const navigate = useNavigate();
  const { modifyOsceMarksheet } = useModifyOsceMarksheet();
  const { product } = usePlatform();
  const [endOsceStation, { loading: endStationLoading }] = useMutation<
    IEndOsceMarksheetData,
    IEndOsceMarksheetVar
  >(END_OSCE_MARKSHEET, {
    onCompleted: () => {
      if (solo) {
        setIsCompleted(true);
      }
    },
    update: updateCacheOnEndOsceMarksheet(product || EProductType.OSCE),
    optimisticResponse: optimisticEndOsceMarksheet(osceMarksheet),
  });

  const endStation = useCallback(
    async (payload?: Omit<IEndOsceMarksheetVar, 'osceMarksheetId'>) => {
      if (endStationLoading || state !== EOsceMarksheetState.STATION) {
        return;
      }

      const variables: IEndOsceMarksheetVar = {
        osceMarksheetId: Number(id),
        ...payload,
      };

      await endOsceStation({ variables });

      if (solo) {
        navigate(`${root}/solo/study/summary/${id}`, {
          state: { leave: true },
        });
      } else {
        modifyOsceMarksheet(EOsceMarksheetAction.END, id);
      }
    },
    [
      endOsceStation,
      endStationLoading,
      id,
      modifyOsceMarksheet,
      navigate,
      solo,
      state,
    ]
  );

  return { endStation, isCompleted, endStationLoading };
};
