import { QuestionAnswerStatus } from 'types';

const setValidationClass = (
  state: QuestionAnswerStatus
): string | undefined => {
  switch (state) {
    case QuestionAnswerStatus.INVALID:
      return 'invalid';
    case QuestionAnswerStatus.VALID:
      return 'valid';
    case QuestionAnswerStatus.NOT_ANSWERED:
      return 'not-answered';
    case QuestionAnswerStatus.SELECTED:
      return 'selected';
    case QuestionAnswerStatus.CLEAN:
    default:
      return undefined;
  }
};

export default setValidationClass;
