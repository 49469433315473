import React from 'react';
import { mdiClipboardTextOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ReadInstructionIcon = (props: IconProps) => (
  <Icon path={mdiClipboardTextOutline} {...props} />
);

export default ReadInstructionIcon;
