import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { ProgressButton } from 'components/ProgressButton';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import { QuestionButtonType } from 'types';
import { CircularProgress } from 'components/CircularProgress';
import useResizeObserver from 'hooks/useResizeObserver';
import { constSize } from 'utils';

const MIN_QUESTIONS_BUTTON_WIDTH = 40;

const BUTTON_GAP = 8;

const StyledBox = styled(Box)(
  ({ theme: { mixins, palette, breakpoints, transitions, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 0),
    borderRadius: 0,
    backgroundColor: palette.background.paper,
    height: `${mixins.progressbar.minHeight}px`,
    color: palette.text.primary,
    borderBottom: `1px solid ${palette.stroke.main}`,
    width: '100%',
    transition: transitions.create(['width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),

    [breakpoints.up('md')]: {
      flexShrink: 0,
    },

    '&:hover': {
      boxShadow: 'none',
    },
    svg: {
      verticalAlign: 'middle',
    },
    'svg:hover': {
      color: palette.secondary.dark,
      cursor: 'pointer',
    },

    '& > .MuiIconButton-root': {
      ...constSize('48px'),
    },
  })
);

const ButtonsContainer = styled(Box)({
  width: '100%',
  overflow: 'hidden',
});

const ButtonsBox = styled(Box)(({ theme: { spacing, transitions } }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  gap: spacing(2),
  transition: transitions.create(['margin-left'], {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  }),
}));

const calcItemSizeAndCount = (
  containerWidth: number,
  itemsCount: number,
  minButtonWidth: number,
  gap = 8
) => {
  let finalCount = itemsCount;

  while (finalCount > 0) {
    const gapsWidth = (finalCount - 1) * gap;
    const availableWidth = containerWidth - gapsWidth;
    const size = availableWidth / finalCount;

    if (size >= minButtonWidth) {
      return [size, finalCount];
    }

    finalCount -= 1;
  }

  return [minButtonWidth, finalCount];
};

export interface ProgressBarData {
  id: number;
  name?: string;
}

interface ProgressBarProps<T extends ProgressBarData> {
  activeItemNumber: number;
  activeItemId: number;
  disabledColors?: boolean;
  minButtonWidth?: number;
  withName?: boolean;
  data: T[];
  onClick: (index: number) => void;
  setButtonClass: (
    item: T,
    currentItemId: number
  ) => QuestionButtonType | undefined;
  review?: boolean;
}

export function ProgressBar<T extends ProgressBarData>({
  data,
  activeItemId,
  activeItemNumber,
  disabledColors,
  onClick,
  setButtonClass,
  minButtonWidth = MIN_QUESTIONS_BUTTON_WIDTH,
  review,
  withName,
}: ProgressBarProps<T>): JSX.Element {
  const [barRef, { width: containerWidth }] =
    useResizeObserver<HTMLDivElement>();
  const [btnSize, setBtnSize] = useState(minButtonWidth);
  const [visibleItemsCount, setVisibleItemsCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const disablePrevious = offset === 0;
  const disableNext = offset + visibleItemsCount >= data.length;

  useEffect(() => {
    if (containerWidth) {
      const [size, count] = calcItemSizeAndCount(
        containerWidth,
        data.length,
        minButtonWidth
      );
      if (count) {
        if (count === data.length) {
          setOffset(0);
        } else if (offset && visibleItemsCount && count > visibleItemsCount) {
          if (offset - count + visibleItemsCount >= 0) {
            setOffset(prev => prev - count + visibleItemsCount);
          }
        }

        setBtnSize(size);
        setVisibleItemsCount(count);
      }
    }
  }, [containerWidth, data.length, minButtonWidth, offset, visibleItemsCount]);

  useEffect(() => {
    if (!visibleItemsCount) {
      return;
    }

    const lastVisibleNumber = visibleItemsCount + offset;
    const firstVisible = offset + 1;

    if (visibleItemsCount === 1) {
      return;
    }

    if (activeItemNumber > lastVisibleNumber) {
      setOffset(prev => prev - lastVisibleNumber + activeItemNumber);

      return;
    }
    if (activeItemNumber < firstVisible && activeItemNumber !== 0) {
      setOffset(prev => prev - firstVisible + activeItemNumber);

      return;
    }
    // We can't add offset to dependecies array as we won't be able to use
    // next/prev buttons
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemNumber, visibleItemsCount]);

  const handleClick = (index: number) => () => {
    onClick(index);
  };

  const handlePrevious = useCallback(() => {
    if (!disablePrevious) {
      setOffset(prev => prev - 1);
    }
  }, [disablePrevious]);

  const handleNext = useCallback(() => {
    if (!disableNext) {
      setOffset(prev => prev + 1);
    }
  }, [disableNext]);

  return (
    <StyledBox>
      <IconButton
        disableFocusRipple
        disableRipple
        disabled={disablePrevious}
        icon={<ArrowLeftIcon />}
        onClick={handlePrevious}
      />
      <ButtonsContainer ref={barRef}>
        <ButtonsBox
          sx={{
            marginLeft: `${-1 * offset * (btnSize + BUTTON_GAP)}px`,
            '& .MuiButtonBase-root.MuiButton-root': {
              minWidth: `${btnSize}px`,
            },
          }}
        >
          {data.length ? (
            data.map((item, index) => {
              return (
                <ProgressButton
                  className={clsx(setButtonClass(item, activeItemId), {
                    review,
                  })}
                  disabledColors={disabledColors}
                  key={item.id}
                  label={withName ? item.name : `${index + 1}`}
                  onClick={handleClick(index)}
                />
              );
            })
          ) : (
            <CircularProgress color="primary" size={15} />
          )}
        </ButtonsBox>
      </ButtonsContainer>
      <IconButton
        disableFocusRipple
        disableRipple
        disabled={disableNext}
        icon={<ArrowRightIcon />}
        onClick={handleNext}
      />
    </StyledBox>
  );
}
