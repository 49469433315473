import {
  EQuestionType,
  IQuestion,
  IQuestionEMQ,
  IQuestionMultiQ,
  IQuestionPrescribe,
  IQuestionQA,
  IQuestionRanking,
  IQuestionSelect3,
} from '@quesmed/types-rn/models';
import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Comments } from 'components/Comments';
import { Markdown } from 'components/Markdown';
import { Lightbox } from 'components/Lightbox';
import {
  calcVotesPercentage,
  compareByField,
  parseFullText,
  uniqueArrayObjects,
} from 'utils';
import { QuestionChoices } from 'components/QuestionChoices';
import { QuestionLike } from 'components/QuestionLike';
import { useQuestionLikeMutation } from 'components/Likes/useQuestionLikeMutation';
import {
  ExerciseLayout,
  QuestionAdditionalContent,
  View,
} from 'components/Layout';
import { ContentHeader } from 'components/ContentHeader';
import {
  BookshelfIcon,
  BrainIcon,
  EditIcon,
  FolderOpenOutlineIcon,
  KnowledgeLibraryIcon,
} from 'components/Icons';
import { Separator } from 'components/Separator';
import { ExpandableSection } from 'components/ExpandableSection';
import { QbankConceptReading } from 'pages/KnowledgeLibrary';
import PrescriptionExplanation from 'pages/MockTests/PrescriptionAnswer/PrescriptionExplanation';
import MultiAnswerContent from 'pages/MockTests/MultiAnswer/MultiAnswerContent';
import MockTestsExplanation from 'pages/MockTests/MockTestsExplanation';
import OpenAnswerField from 'pages/MockTests/OpenAnswer/OpenAnswerSection';
import MatchingQuestionCase from './MatchingQuestion/MatchingQuestionCase';
import { MatchingQuestionChoicesList } from './MatchingQuestion';
import { choiceStatus } from './MatchingQuestion/choiceStatus';
import { MatchingAnswersWrapper } from './MatchingQuestion/MatchingQuestion';
import { RankingQuestionAnswer } from './RankingQuestion/RankingQuestionAnswer';
import { SelectThreeQuestionAnswer } from './SelectThreeQuestion/index';
import LearningPoints from 'components/LearningPoints/LearningPoints';
import { QuestionTags } from 'components/QuestionTags';
import { useUserSettings } from 'hooks';

export const Row = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: spacing(0, 2),
  boxSizing: 'border-box',
  alignItems: 'center',
  height: '52px',

  [breakpoints.down('md')]: {
    padding: spacing(0, 6),
  },
}));

const Container = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  padding: spacing(4, 0),

  [breakpoints.up('lg')]: {
    padding: spacing(8, 0),
  },
}));

export interface IMarksheetQuestionProps {
  question: IQuestion;
}

const QuestionPreview = ({
  question,
}: IMarksheetQuestionProps): JSX.Element => {
  const {
    cases,
    choices,
    concept,
    id,
    pictures = [],
    question: questionText,
    totalVotes,
    typeId,
    topic,
    explanation: questionExplanation,
    isLikedByMe,
    learningPoint,
    userPoint,
  } = question;

  const {
    chapter,
    name: conceptName = '',
    id: conceptId,
    videos,
  } = concept || {};
  const {
    name: topicName = 'Topic name',
    id: topicId = 1,
    entitlement,
  } = topic || {};
  const { pictures: chapterPictures = [], explanation } = chapter || {};

  const votes = choices ? calcVotesPercentage(choices, totalVotes) : [];
  const { handleLikeQuestion, likes, dislikes } =
    useQuestionLikeMutation(question);
  const { expandedReading } = useUserSettings();

  const getMultiAnswerAnswer = () => {
    const {
      multiAnswer: [firstColumn, secondColumn],
    } = question as IQuestionMultiQ;

    return {
      firstColumn,
      secondColumn,
    };
  };

  const getOpenQuestionAnswer = () => {
    const qaQuestion = question as IQuestionQA;
    if (qaQuestion.qaAnswer) {
      const { qaAnswer } = qaQuestion;
      const [{ dose, units }] = qaAnswer;

      return { dose, units };
    }

    return null;
  };

  const { dose, units } = getOpenQuestionAnswer() || {};

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: entitlement?.name || '',
      },
      { icon: FolderOpenOutlineIcon, label: topicName },
    ],
    [entitlement?.name, topicName]
  );

  const images = useMemo(
    () =>
      uniqueArrayObjects((pictures || []).concat(chapterPictures || []), 'id'),
    [pictures, chapterPictures]
  );

  const options = useMemo(
    () =>
      choices
        .map(({ label }) => ({
          label,
          value: label,
        }))
        .sort(compareByField('label')),
    [choices]
  );

  const isSBALikeQuestion =
    typeId === EQuestionType.SINGLE_BEST_ANSWER ||
    typeId === EQuestionType.SBA_WITH_MULTIPLE_CHOICES;

  const matchingAnswer =
    typeId === EQuestionType.EXTENDED_MATCHING_ANSWER
      ? new Map((question as IQuestionEMQ).emqAnswer)
      : null;

  return (
    <ExerciseLayout
      exerciseContent={
        <View>
          <Container>
            <ContentHeader title="Question" />
            {typeId === EQuestionType.EXTENDED_MATCHING_ANSWER && (
              <MatchingQuestionChoicesList choices={choices} />
            )}
            {question.question ? (
              <Markdown text={parseFullText(questionText, pictures)} />
            ) : null}
            {isSBALikeQuestion ? (
              <QuestionChoices
                choices={choices}
                isPreview
                name={`Question, (id: ${id})`}
                showAnswers
                votes={votes}
              />
            ) : null}
            {typeId === EQuestionType.RANKING_ANSWER && (
              <RankingQuestionAnswer
                choices={choices}
                correctAnswer={(question as IQuestionRanking).rankingAnswer}
                explanation={question.explanation}
                preview
                title="Correct answer"
                variant="normal"
              />
            )}
            {typeId === EQuestionType.SELECT_THREE_ANSWER && (
              <SelectThreeQuestionAnswer
                preview
                question={question as IQuestionSelect3}
                title="Correct answer"
                variant="normal"
              />
            )}
            {matchingAnswer &&
            cases &&
            typeId === EQuestionType.EXTENDED_MATCHING_ANSWER ? (
              <MatchingAnswersWrapper>
                {cases.map(
                  ({ id, label: caseLabel, explanation, ...rest }, index) => (
                    <MatchingQuestionCase
                      caseContent={rest.case}
                      caseId={id}
                      explanation={explanation}
                      icon={EditIcon}
                      key={id}
                      options={options.map(({ label, value }) => ({
                        label,
                        value,
                        status: choiceStatus(
                          label,
                          matchingAnswer.get(caseLabel)
                        ),
                      }))}
                      questionId={question.id}
                      title={`Case ${index + 1}`}
                    />
                  )
                )}
              </MatchingAnswersWrapper>
            ) : null}
            {typeId === EQuestionType.PRESCRIPTION_ANSWER ? (
              <PrescriptionExplanation
                isPreview
                question={question as IQuestionPrescribe}
              />
            ) : null}
            {typeId === EQuestionType.MULTIPLE_ANSWERS ? (
              <MultiAnswerContent
                combinedAnswer={getMultiAnswerAnswer()}
                isPreview
                isTestFinished
                question={question as IQuestionMultiQ}
              />
            ) : null}
            {typeId === EQuestionType.QUESTION_ANSWER ? (
              <OpenAnswerField
                correct
                defaultValue={dose}
                isTestFinished
                preview
                units={units}
              />
            ) : null}
            <Lightbox pictures={images} />
            <QuestionTags question={question} />
            <QuestionLike
              dislikes={dislikes}
              handleLike={handleLikeQuestion}
              isLikedByMe={isLikedByMe}
              likes={likes}
            />
            <Separator title="Learn more ↓" />
            <QuestionAdditionalContent>
              {learningPoint ? (
                <LearningPoints
                  learningPoint={learningPoint}
                  questionId={Number(id)}
                  questionType={typeId}
                  userPoint={userPoint}
                />
              ) : null}
              <Comments question={question} showComments />
              {questionExplanation ? (
                <ExpandableSection icon={BrainIcon} title="Explanation">
                  <MockTestsExplanation
                    explanation={questionExplanation}
                    pictures={pictures}
                  />
                </ExpandableSection>
              ) : null}
              {explanation ? (
                <ExpandableSection
                  expanded
                  icon={KnowledgeLibraryIcon}
                  title={`${topicName}: ${conceptName}`}
                >
                  <QbankConceptReading
                    breadcrumbs={breadcrumbs}
                    conceptId={Number(conceptId)}
                    conceptName={conceptName}
                    expandedReading={expandedReading}
                    explanation={explanation}
                    hideExerciseInvitation
                    pictures={chapterPictures}
                    showAll
                    topicId={topicId}
                    topicName={topicName}
                    videos={videos}
                    withQuestionImage
                  />
                </ExpandableSection>
              ) : null}
            </QuestionAdditionalContent>
          </Container>
        </View>
      }
      withoutPanel
    />
  );
};

export default QuestionPreview;
