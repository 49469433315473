import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { paths } from 'Router';
import { usePlatform } from 'context/PlatformContext';
import { Children } from 'types';
import { isStationsProduct } from 'utils';

const { questions, stations } = paths;

const JoinLink = styled(Link)(
  ({ theme: { spacing, typography, palette } }) => ({
    padding: spacing(4),
    ...typography.button,
    color: palette.text.primary,
    backgroundColor: palette.background.paper,
    borderRadius: '7px',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${palette.stroke.main}`,
    textDecoration: 'none',
  })
);

interface InfoProps {
  to: string;
  message?: Children;
}

const Info = ({ message, to }: InfoProps): JSX.Element => (
  <JoinLink to={to}>{message}</JoinLink>
);

interface ContinueInfoLinkProps {
  sessionId: string;
  solo?: boolean;
}

export const ContinueStudyLink = ({
  sessionId,
  solo,
}: ContinueInfoLinkProps): JSX.Element => {
  const { product } = usePlatform();

  const isStations = isStationsProduct(product);

  const message = `Continue your last ${solo ? '' : 'group'} ${
    isStations ? 'station' : 'quiz'
  }`;

  const baseRoute = isStations
    ? `${stations.root}/${stations.groupLobby}`
    : `${questions.root}/${questions.groupLobby}`;

  return (
    <Info
      message={message}
      to={
        solo
          ? `/questions/solo/quiz/${sessionId}`
          : `${baseRoute}?join=${sessionId}`
      }
    />
  );
};
