import React from 'react';
import { mdiCardRemoveOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RemoveCardIcon = (props: IconProps) => (
  <Icon path={mdiCardRemoveOutline} {...props} />
);

export default RemoveCardIcon;
