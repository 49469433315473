import React from 'react';

import QuestionsQuizBuilder from './QuestionsQuizBuilder';
import { useQuestionGroupStudyData } from './QuestionsGroupStudyController';
import { CircularProgress } from 'components/CircularProgress';

const QuestionsGroupStudyQuizBuilder = () => {
  const { marksheet } = useQuestionGroupStudyData();

  return marksheet ? (
    <QuestionsQuizBuilder marksheet={marksheet} />
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default QuestionsGroupStudyQuizBuilder;
