import React from 'react';
import { mdiFlagOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FlagOutlineIcon = (props: IconProps) => (
  <Icon path={mdiFlagOutline} {...props} />
);

export default FlagOutlineIcon;
