import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';

import { Popper } from 'components/Popper';
import { ListItemButton } from 'components/List';
import { DotsVerticalIcon } from 'components/Icons';

export const ActionPopperWrapper = styled(Popper)(({ theme: { spacing } }) => ({
  '& .MuiPaper-root': {
    width: '200px',
    padding: spacing(2),
  },
}));

export const PopperItem = styled(ListItemButton)(
  ({ theme: { palette, spacing } }) => ({
    gap: spacing(2),
    '&.footer': {
      '&.MuiListItemButton-root .MuiSvgIcon-root': {
        fill: palette.error.main,
      },
    },
  })
);

export const PopperOpener = styled(DotsVerticalIcon)({
  cursor: 'pointer',
});

export const SeparatorLine = styled(Divider)(
  ({ theme: { palette, spacing } }) => ({
    margin: spacing(0, 2),
    backgroundColor: palette.stroke.main,
  })
);
