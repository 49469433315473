import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import Joi from 'joi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { EProductType } from '@quesmed/types-rn/models';

import { Modal, ModalProps } from 'components/Modal';
import { NumberField } from 'components/NumberField';
import { Body } from 'components/Typography';
import { splitIntoHoursAndMinutes } from 'utils';
import { usePlatform } from 'context/PlatformState';

interface MockTestsTimerModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'loading'> {
  onBuildTest: (hours: number, minutes: number) => void;
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(1),
  marginBottom: spacing(6),
  gap: spacing(6),
}));

export interface MockTestsFormInput {
  exerciseTime: string;
}

const DEFAULT_EXERCISE_TIME = '01:40';
const TIME_FORMAT_REGEX = /^(?!00:00)([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

const schema = Joi.object<MockTestsFormInput>({
  exerciseTime: Joi.string().required().regex(TIME_FORMAT_REGEX).messages({
    'string.empty': 'Exercise time should not be empty',
    'string.pattern.base': 'Please pass correct time format',
  }),
});

const MockTestsTimerModal = ({
  loading,
  open,
  onClose,
  onBuildTest,
}: MockTestsTimerModalProps): JSX.Element => {
  const { product } = usePlatform();
  const { control, formState, handleSubmit, reset } =
    useForm<MockTestsFormInput>({
      defaultValues: {
        exerciseTime: DEFAULT_EXERCISE_TIME,
      },
      resolver: joiResolver(schema),
      mode: 'onBlur',
    });

  const handleFormSubmit: SubmitHandler<MockTestsFormInput> = data => {
    const { exerciseTime } = data;

    if (exerciseTime) {
      const [hours, minutes] = splitIntoHoursAndMinutes(exerciseTime);

      onBuildTest(Number(hours), Number(minutes));
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    reset();
  };

  const getBody = () => {
    switch (product) {
      case EProductType.QBANK:
        return 'We recommend setting 1 hour for every 50 questions for finals mock tests in keeping with most medical finals exams. For the PSA, you will have 120 minutes to complete 60 questions. You can edit the time by simply entering your preferred time range, if for example you are entitled to extra time.';
      case EProductType.ANATOMY:
        return 'The way in which anatomy questions are tested can vary by medical school. We suggest that you refer to information from you medical school when deciding on the how long you give yourself to complete this mock test. There are 50 questions in this test and as a guide you should aim for no more than 1 minute per question and so we recommend an exercise time of 50 minutes.';
      default:
        return 'The MRCP written exams are made of two papers, each with 100 questions lasting 3 hours. We suggest you allocate yourself no more than 3 hours when completing these mock tests in order to replicate exam conditions as closely as possible.';
    }
  };

  return (
    <Modal
      loading={loading}
      maxWidth="sm"
      noPaddingY
      onClose={handleClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      open={open}
      showCloseButton
      sizeVariant="sm"
      submitDisabled={!formState.isValid}
      submitLabel="start exercise"
      title="Set a mock timer"
    >
      <StyledBox>
        <NumberField
          control={control}
          format="##:##"
          fullWidth
          label="Exercise time"
          name="exerciseTime"
          placeholder="hh:mm"
        />
        <Body>{getBody()}</Body>
      </StyledBox>
    </Modal>
  );
};

export default MockTestsTimerModal;
