import { IQuestionChoice } from '@quesmed/types-rn/models';

import { roundTo100 } from '../math/roundCollection';

export const calcVotesPercentage = (
  choices: IQuestionChoice[],
  totalVotes = 0,
  currentChoiceId?: number | null
) => {
  const finalTotalVotes = totalVotes + (currentChoiceId ? 1 : 0);

  return roundTo100(
    choices.map(({ id, votes = 0 }) => {
      if (finalTotalVotes === 0) {
        return 0;
      }

      const finalVotes = currentChoiceId === Number(id) ? votes + 1 : votes;

      return (finalVotes * 100) / finalTotalVotes;
    })
  );
};
