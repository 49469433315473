import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CREATE_NEW_SUBSCRIPTION_CHECKOUT_SESSION,
  ICreateNewSubscriptionCheckoutSessionData,
  ICreateNewSubscriptionCheckoutSessionVar,
} from '@quesmed/types-rn/resolvers/mutation/validUserToken';

import { constants } from 'config';

const { STRIPE_KEY } = constants;

const usePayment = () => {
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [createNewCheckoutSession, { loading: checkoutLoading }] = useMutation<
    ICreateNewSubscriptionCheckoutSessionData,
    ICreateNewSubscriptionCheckoutSessionVar
  >(CREATE_NEW_SUBSCRIPTION_CHECKOUT_SESSION);

  const handlePayment = useCallback(
    async (priceId: string) => {
      setPaymentLoading(true);

      return createNewCheckoutSession({ variables: { priceId } })
        .then(response => {
          const { errors, data } = response;

          if (errors && errors.length > 0) {
            throw new Error(errors[0].message);
          }

          const { createNewSubscriptionCheckoutSession: sessionId } =
            data?.validUserToken || {};

          if (STRIPE_KEY && sessionId) {
            return loadStripe(constants.STRIPE_KEY).then(stripe =>
              Promise.resolve({ stripe, sessionId })
            );
          }
          throw new Error('Creating checkout failed');
        })
        .then(({ sessionId, stripe }) =>
          stripe?.redirectToCheckout({
            sessionId,
          })
        )
        .finally(() => {
          setPaymentLoading(false);
        });
    },

    [createNewCheckoutSession]
  );

  return { handlePayment, loading: checkoutLoading || paymentLoading };
};

export default usePayment;
