import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IQuestionCommentsLikeData,
  IQuestionCommentsLikeVar,
  optimisticCommentLike,
  QUESTION_COMMENT_LIKE,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  EQuestionLike,
  IQuestion,
  IQuestionComment,
} from '@quesmed/types-rn/models';

import { sortByCreatedAt } from 'utils';
import { setLikeState } from './setLikeState';
import { Nullable } from 'types';

export const useCommentsLikeMutation = (
  question: IQuestion,
  questionCommentsLoading: boolean,
  marksheetId?: number
) => {
  const {
    comments: sourceComments = [],
    id: questionId,
    dislikes,
    likes,
  } = question;
  const comments = sortByCreatedAt<IQuestionComment>(sourceComments);

  const [commentLike, { loading: questionCommentLikeLoading }] = useMutation<
    IQuestionCommentsLikeData,
    IQuestionCommentsLikeVar
  >(QUESTION_COMMENT_LIKE);

  const handleLikeComment = useCallback(
    (
        likeClick: boolean,
        commentId: number,
        parentId: Nullable<number>,
        isLikedByMe?: EQuestionLike
      ) =>
      () => {
        if (questionCommentLikeLoading || questionCommentsLoading) {
          return;
        }

        const variables = {
          questionId: Number(questionId),
          marksheetId,
          commentId: Number(commentId),
          like: setLikeState(likeClick, isLikedByMe),
        };

        commentLike({
          variables,
          optimisticResponse: optimisticCommentLike(
            comments,
            variables,
            parentId
          ),
        });
      },

    [
      commentLike,
      comments,
      marksheetId,
      questionCommentLikeLoading,
      questionCommentsLoading,
      questionId,
    ]
  );

  return {
    handleLikeComment,
    likes,
    dislikes,
  };
};
