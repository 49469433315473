const VALENTINES = {
  month: 1,
  day: 14,
};

const isValentinesDay = () => {
  const now = new Date();

  const isValentines =
    now.getMonth() === VALENTINES.month && now.getDate() === VALENTINES.day;

  return isValentines;
};

export default isValentinesDay;
