import { IUser } from '@quesmed/types-rn/models';

export const getUsersToastText = (
  users: IUser[],
  userId: number | undefined
) => {
  let toastText = 'Welcome to the group! Say hello to';
  const otherUsers = users.filter(user => user.id !== userId);

  const getPrefix = (arrayLength: number, index: number) => {
    if (index === 0) return ' ';
    else if (index === arrayLength - 1) return ' and ';

    return ', ';
  };

  otherUsers.forEach((user, index, array) => {
    const prefix = getPrefix(array.length, index);
    toastText = toastText.concat(`${prefix}${user.displayName}`);
  });

  return toastText;
};
