// TODO remove all comments from this when updated mock tests are working
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { EMockTestType, IUserMockTest } from '@quesmed/types-rn/models';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import {
  calculateRemainingTestTime,
  checkIfCategoryExist,
  getTestCategoryName,
  parseDate,
  round,
} from 'utils';
import {
  BuilderViewMode,
  ExerciseMode,
  ExerciseType,
  ItemStatusLabel,
  Nullable,
  SelectionBarLabels,
  ToggleEvent,
  UIColumn,
} from 'types';
import { useMockTestsQuery } from './hooks';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import {
  AccountIcon,
  ProgressCorrectIcon,
  ProgressIncorrectIcon,
  ProgressInitialIcon,
} from 'components/Icons';
import { MAX_SCORE, TRANSITION_TIMEOUT } from 'config/constants';
import { BUILDER_VIEWS } from 'constants/general';
import { usePrevious } from 'hooks/usePrevious';
import {
  ExerciseBuilder,
  KeyField,
  QUESTIONS_PROGRESS_LABELS,
  SelectionColumn,
  SelectionInputCellLabel,
} from 'components/ExerciseBuilder';
import {
  BadgeScore,
  ProgressScoreBadgeProps,
} from 'components/ProgressScoreBadge';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import {
  BottomSelectionBar,
  setBottomBarOffset,
} from 'components/BottomSelectionBar';
// import MockTestsTimerModal from './MockTestsTimerModal';
import {
  useIsMobile,
  useLeaveExerciseState,
  // useMarksheetLeavingBlockade,
} from 'hooks';
import QuestionsQuizLeaveModal from 'pages/Questions/QuestionsQuizLeaveModal';

type MockTestColumn = IUserMockTest & UIColumn;

const RadioLabelFormatter = ({ title, totalQuestions }: IUserMockTest) => (
  <SelectionInputCellLabel
    labelOfTotal="Questions"
    name={title}
    total={totalQuestions}
  />
);

const noAnsweresFormatter = ({
  correct,
  incorrect,
  totalQuestions,
}: IUserMockTest) => {
  const answeredQuestionsCount = (correct ?? 0) - (incorrect ?? 0);
  const notAnsweredQuestionCount = totalQuestions - answeredQuestionsCount;

  return totalQuestions ? notAnsweredQuestionCount : 0;
};

const scoreFormatter = ({
  correct,
  totalQuestions,
  passingMark,
}: IUserMockTest): ProgressScoreBadgeProps => {
  const total = totalQuestions ?? 0;
  const score = total ? round(((correct ?? 0) * MAX_SCORE) / total) : 0;

  let status = BadgeScore.NotStarted;
  let icon = <ProgressInitialIcon />;
  if (score >= passingMark) {
    status = BadgeScore.Correct;
    icon = <ProgressCorrectIcon />;
  } else if (score > 0) {
    status = BadgeScore.Incorrect;
    icon = <ProgressIncorrectIcon />;
  }

  return { score, icon, status };
};

const progressFormatter = ({
  correct,
  incorrect,
  title,
  totalQuestions,
}: IUserMockTest): ProgressLineChartProps => ({
  title,
  data: [
    {
      type: ItemStatusLabel.Confident,
      value: correct,
      label: ItemStatusLabel.Correct,
    },
    {
      type: ItemStatusLabel.ToLearn,
      value: incorrect,
      label: ItemStatusLabel.Incorrect,
    },
  ],
  total: totalQuestions,
});

const detailsColumns: SelectionColumn<MockTestColumn>[] = [
  {
    align: 'left',
    key: KeyField.Radio,
    label: 'Exam names',
    type: 'input',
  },
  {
    align: 'left',
    formatter: RadioLabelFormatter,
    key: KeyField.Label,
    label: 'Exam names',
    type: 'label',
  },
  {
    align: 'left',
    key: 'correct',
    ...QUESTIONS_PROGRESS_LABELS[0],
    type: 'default',
  },
  {
    align: 'left',
    key: 'incorrect',
    ...QUESTIONS_PROGRESS_LABELS[1],
    type: 'default',
  },
  {
    align: 'left',
    formatter: noAnsweresFormatter,
    key: KeyField.NotAnswered,
    ...QUESTIONS_PROGRESS_LABELS[2],
    type: 'default',
  },
  {
    align: 'left',
    scoreFormatter: scoreFormatter,
    key: KeyField.Score,
    label: 'Score',
    type: 'default',
  },
];

const overviewColumns: SelectionColumn<MockTestColumn>[] = [
  {
    align: 'left',
    key: KeyField.Radio,
    label: 'Exam names',
    type: 'input',
  },
  {
    align: 'left',
    formatter: RadioLabelFormatter,
    key: KeyField.Label,
    label: 'Exam names',
    type: 'label',
  },
  {
    align: 'left',
    key: KeyField.Progress,
    label: 'Progress',
    type: 'bar',
    progressLabels: QUESTIONS_PROGRESS_LABELS,
    progressFormatter,
  },
];

const MockTestsQuizBuilder = (): JSX.Element => {
  const { mockTests, loading, categories, mappedTests } = useMockTestsQuery();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const [selectionInfo, setSelectionInfo] = useState<string>('');
  const [activeCategory, setActiveCategory] = useState(categories[0]?.value);
  const [activeView, setActiveView] = useState(BUILDER_VIEWS[0].value);
  // const [showMockTestModal, setShowMockTestModal] = useState(false);
  const [showSelectionBar, setShowSelectionBar] = useState(false);
  const [displayedTests, setDisplayedTests] = useState<IUserMockTest[]>();
  const [selectedTestId, setSelectedTestId] = useState<Nullable<number>>(null);
  const previousCategory = usePrevious(activeCategory);

  const exists = checkIfCategoryExist(categories, activeCategory);

  // const { buildMockTest, loading: buildLoading } =
  //   useBuildMockTestMutation(selectedTestId);
  const { open } = useLeaveExerciseState();

  // useMarksheetLeavingBlockade({
  //   shouldBlock: showSelectionBar,
  //   ignoreBlockade: !showSelectionBar,
  //   mockTest: true,
  // });

  const columns = useMemo(
    () =>
      activeView === BuilderViewMode.Details ? detailsColumns : overviewColumns,
    [activeView]
  );

  const selectedTest = mockTests.find(
    ({ id }) => Number(id) === Number(selectedTestId)
  );

  const { lastMarksheetId, lastMarksheetEndedAt } = selectedTest || {};

  const remainingTime = lastMarksheetId
    ? calculateRemainingTestTime(parseDate(lastMarksheetEndedAt))
    : 0;

  const showReturnToSession = lastMarksheetId && remainingTime > 0;
  const showReview = lastMarksheetId && !showReturnToSession;

  const handleReview = () => {
    if (showReview) {
      navigate(
        `${paths.mockTests.root}/test/${selectedTestId}/${lastMarksheetId}`,
        { state: { leave: true } }
      );
    }
  };

  const handleReturnToSession = () => {
    if (showReturnToSession) {
      navigate(
        `${paths.mockTests.root}/test/${selectedTestId}/${lastMarksheetId}`,
        { state: { leave: true } }
      );
    }
  };

  // const handleBuildMockTest = (hours: number, minutes: number) =>
  //   buildMockTest(hours, minutes);

  const handleStartMockTest = () => {
    navigate(`${paths.mockTests.root}/intro/${selectedTestId}`);
  };

  // const handleCloseMockTestModal = () => setShowMockTestModal(false);

  const handleActiveCategory = (e: SyntheticEvent, type: EMockTestType) =>
    setActiveCategory(Number(type));

  const handleActiveView = (e: ToggleEvent, view: BuilderViewMode) =>
    setActiveView(view);

  const handleSearch = (searchString: string) => {
    const normalizedSearchString = searchString.toLowerCase().trim();
    if (mappedTests && searchString) {
      const filtered = mappedTests[activeCategory].filter(({ title }) =>
        title.toLowerCase().includes(normalizedSearchString)
      );
      setDisplayedTests(filtered);

      return;
    }

    setDisplayedTests(mappedTests ? mappedTests[activeCategory] : undefined);
  };

  const handlseSelectTest = (value: Nullable<number>) => () => {
    setSelectedTestId(value);
  };

  useEffect(() => {
    if (categories.length && (!activeCategory || !exists)) {
      setActiveCategory(Number(categories[0].value));
    }
  }, [categories, activeCategory, exists]);

  useEffect(() => {
    setDisplayedTests(mappedTests ? mappedTests[activeCategory] : undefined);
  }, [mappedTests, activeCategory]);

  useEffect(() => {
    let timeout = 0;

    if (selectedTestId) {
      setShowSelectionBar(true);
      const selectedTest = mockTests?.find(
        ({ id }) => Number(id) === Number(selectedTestId)
      );
      if (selectedTest) {
        const { title, totalQuestions } = selectedTest;
        const info = isMobile
          ? `[${title}] selected`
          : `[${title}] (${totalQuestions} questions) selected`;
        setSelectionInfo(info);
      }
    } else {
      setShowSelectionBar(false);
      timeout = window.setTimeout(() => {
        setSelectionInfo('');
      }, TRANSITION_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isMobile, mockTests, selectedTestId]);

  return (
    <ExerciseLayout
      bottomOffsetVariant={setBottomBarOffset(showSelectionBar, isMobile)}
      exerciseContent={
        <>
          <PreLaunchLayout selection={showSelectionBar ? 1 : 0}>
            <ExerciePreBuildHeader
              exerciseMode={ExerciseMode.Solo}
              exerciseType={ExerciseType.MockTests}
              icon={<AccountIcon />}
              mainHeader="Select exam"
            />
            <ExerciseBuilder
              activeCategory={activeCategory}
              categoryOptions={categories}
              columns={columns}
              data={displayedTests || []}
              loading={loading}
              onSearch={handleSearch}
              onSelectRow={handlseSelectTest}
              onToggleCategory={handleActiveCategory}
              onToggleView={handleActiveView}
              resetSearch={previousCategory !== activeCategory}
              searchLabel="Search exam by name"
              selectedItemId={selectedTestId}
              selectedView={activeView}
              title={getTestCategoryName(activeCategory)}
              viewOptions={BUILDER_VIEWS}
            />
          </PreLaunchLayout>
          <Fade in={showSelectionBar} unmountOnExit>
            <BottomSelectionBar
              continueLabel={
                showReturnToSession
                  ? SelectionBarLabels.ReturnToSession
                  : SelectionBarLabels.Continue
              }
              onContinue={
                showReturnToSession
                  ? handleReturnToSession
                  : handleStartMockTest
              }
              onReviewOrSavePreset={showReview ? handleReview : null}
              onUnselect={handlseSelectTest(null)}
              reviewOrPresetLabel={SelectionBarLabels.ReviewLastSession}
              selectedItemsLabel={selectionInfo}
              unselectlabel={SelectionBarLabels.Unselect}
              withoutPanel
            />
          </Fade>
          {/* <MockTestsTimerModal
            loading={buildLoading}
            onBuildTest={handleBuildMockTest}
            onClose={handleCloseMockTestModal}
            open={showMockTestModal}
          /> */}
          {open ? <QuestionsQuizLeaveModal discard open={open} /> : null}
        </>
      }
      withoutPanel
    />
  );
};

export default MockTestsQuizBuilder;
