import { IOsceMarksheetMember, IUser } from '@quesmed/types-rn/models';

import { StationRole } from 'types';

const filterNullStationUsers = (
  member: IOsceMarksheetMember
): member is IOsceMarksheetMember & { user: IUser; role: StationRole } => {
  const { user } = member;

  return user !== null && user !== undefined;
};

export default filterNullStationUsers;
