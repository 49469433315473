import React from 'react';

import { useQbankVideoLibraryData } from './QbankVideoLibrary';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import {
  LearningMaterialsData,
  PanelItems,
  VIDEO_SEARCH_LABEL,
} from 'components/LearningMaterials';
import VideoDetails from 'components/LearningMaterials/components/VideoDetails';
import { PathName } from 'types';

const QbankVideoLibraryVideoDetails = () => {
  const { activeCategoryLabel, resetSearch, topics, searchBy, loading } =
    useQbankVideoLibraryData();

  return (
    <ExerciseLayout
      collapsedPanel={false}
      controlPanelContent={
        <PanelItems
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchLabel={VIDEO_SEARCH_LABEL}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.VideoLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          {topics?.length ? (
            <VideoDetails
              activeCategoryLabel={activeCategoryLabel}
              enableArrowNavigation
              items={topics as unknown as LearningMaterialsData[]}
            />
          ) : null}
        </PreLaunchLayout>
      }
    />
  );
};

export default QbankVideoLibraryVideoDetails;
