import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITodoMark } from '@quesmed/types-rn/models';

import { calculatePercent } from 'utils';
import { ExerciseType, FlashcardScore, FlashcardsRouterParams } from 'types';
import {
  ExerciseSummary,
  SummaryColumn,
  SummaryTableKey,
  SummaryTabs,
} from 'components/ExerciseSummary';
import { useFlashcardsQuery } from './hooks';
import { setFlashcardsProgressData } from './setFlashcardsProgressData';
import { ExerciseLayout } from 'components/Layout';
import FlashcardsSummaryModal from './FlashcardsSummaryModal';
import SavePresetsModal from 'components/PresetsBuilder/SavePresetsModal';
import { StickerTextOutlineIcon } from 'components/Icons';
import { getScoreStatusClassname } from './getScoreStatusClassname';
import { getScoreLabel } from './getScoreLabel';
import FlashcardScoreMark from './FlashcardScoreMark';
import {
  CoreCellLabelProps,
  FLASHCARDS_PROGRESS_LABELS,
} from 'components/GridCell';

export const checkResult = (marks?: ITodoMark[]) => {
  let confident = 0;
  let learn = 0;
  let review = 0;

  if (!marks) {
    return { confident, learn, review, total: 0 };
  }

  marks.forEach(({ score }) => {
    switch (score) {
      case FlashcardScore.NotAtAll:
      case FlashcardScore.Slightly:
      default:
        learn += 1;
        break;
      case FlashcardScore.Moderately:
        review += 1;
        break;
      case FlashcardScore.KnowWell:
      case FlashcardScore.Perfectly:
        confident += 1;
        break;
    }
  });

  return { confident, learn, review, total: marks.length };
};

const [confidentLabel, toReviewLabel, toLearnLabel, notAnsweredLabel] =
  FLASHCARDS_PROGRESS_LABELS;

const columns: SummaryColumn<ITodoMark>[] = [
  {
    key: SummaryTableKey.Number,
    align: 'left',
    label: SummaryTableKey.Number,
    formatter: (_, number) => `Flashcard ${number}`,
    type: 'default',
  },
  {
    key: SummaryTableKey.Content,
    align: 'left',
    label: SummaryTableKey.Content,
    formatter: ({ card }) => (card ? card.question : ''),
    type: 'default',
  },
  {
    key: SummaryTableKey.Topic,
    align: 'left',
    label: SummaryTableKey.Topic,
    formatter: ({ card }) => card?.concept?.topic?.name || 'Topic name',
    type: 'default',
  },
  {
    key: SummaryTableKey.Concept,
    align: 'left',
    label: SummaryTableKey.Concept,
    formatter: ({ card }) => card?.concept?.name || 'Concept name',
    type: 'default',
  },
  {
    key: SummaryTableKey.Result,
    align: 'left',
    label: SummaryTableKey.Result,
    resultFormatter: ({ score }) => {
      const status = getScoreStatusClassname(score);
      const scoreLabel = getScoreLabel(score);

      return {
        icon: <FlashcardScoreMark score={score} />,
        status,
        label: scoreLabel,
      };
    },
    type: 'default',
  },
];

const extractConceptIdsFromMarks = (marks: ITodoMark[] = []): number[] => [
  ...new Set(marks.map(({ card }) => Number(card?.concept.id) || 0)),
];

const tabs = [{ title: SummaryTabs.Content, icon: <StickerTextOutlineIcon /> }];

const FlashcardsSummary = (): JSX.Element => {
  const { todoId = '' } = useParams<FlashcardsRouterParams>();
  const { flashcards, loading } = useFlashcardsQuery(Number(todoId));
  const { marks } = flashcards || {};
  const { confident, review, learn, total } = checkResult(marks);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [openSavePresetModal, setOpenSavePresetModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs?.[0].title);

  const handleCloseDetails = () => setShowDetailsModal(false);

  const { entitlement } = flashcards?.marks[0].card?.concept.topic || {};

  const handleClosePresetModal = () => {
    setOpenSavePresetModal(false);
  };

  const conceptIds = extractConceptIdsFromMarks(marks);

  const labels: CoreCellLabelProps[] = [
    {
      ...confidentLabel,
      labelValue: confident,
    },
    {
      ...toReviewLabel,
      labelValue: review,
    },
    {
      ...toLearnLabel,
      labelValue: learn,
    },
    {
      ...notAnsweredLabel,
      labelValue: total - learn - review - confident,
    },
  ];

  return (
    <ExerciseLayout
      exerciseContent={
        <>
          <ExerciseSummary
            activeTab={activeTab}
            correctCount={confident}
            correctLabel="Confident answers"
            data={setFlashcardsProgressData(confident, learn, review)}
            loading={loading}
            score={calculatePercent(confident, total)}
            setActiveTab={setActiveTab}
            tableData={{
              columns,
              data: marks,
              labels,
              summary: `Flashcards answered: ${
                learn + review + confident
              } out of ${total}`,
            }}
            tabs={tabs}
            title={ExerciseType.Flashcards}
            total={total}
          />
          <SavePresetsModal
            closePresetModal={handleClosePresetModal}
            conceptIds={conceptIds}
            entitlementId={entitlement?.id}
            loading={loading}
            openSavePresetModal={openSavePresetModal}
          />
          {marks ? (
            <FlashcardsSummaryModal
              data={marks}
              onClose={handleCloseDetails}
              open={showDetailsModal}
              title="Flashcards summary"
            />
          ) : null}
        </>
      }
      withoutPanel
      withoutVerticalOffset
    />
  );
};

export default FlashcardsSummary;
