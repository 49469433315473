import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalProps } from 'components/Modal';
import { ButtonOnClickHandler, ExerciseType, Nullable } from 'types';
import { FileDocumentIcon, FlashcardsIcon } from 'components/Icons';
import { paths } from 'Router';
import ExerciseModeButton from './ExerciseModeButton';

const { questions: questionsPath, flashcards: flashcardsPath } = paths;

interface SelectExerciseModalProps extends ModalProps {
  open: boolean;
  onClose: () => void;
}

const PathContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: spacing(6),

  [breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const SelectExerciseModal = ({
  onClose,
  open,
}: SelectExerciseModalProps): JSX.Element => {
  const [exerciseType, setExerciseType] =
    useState<Nullable<ExerciseType>>(null);
  const navigate = useNavigate();
  const handleAccountType = useCallback(
    (type: Nullable<ExerciseType>): ButtonOnClickHandler =>
      () => {
        setExerciseType(type);
      },
    [setExerciseType]
  );

  const handleSubmit = () => {
    const path =
      exerciseType === ExerciseType.Questions
        ? questionsPath.root
        : flashcardsPath.root;
    navigate(path);
  };

  const isQuestionSelected = exerciseType === ExerciseType.Questions;
  const isFlashcardSelected = exerciseType === ExerciseType.Flashcards;

  return (
    <Modal
      noPaddingY
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      showCloseButton
      sizeVariant="sm"
      submitDisabled={!exerciseType}
      submitLabel="Start Solo"
      title="Select exercise mode"
    >
      <PathContainer>
        <ExerciseModeButton
          onClick={handleAccountType(ExerciseType.Questions)}
          selected={isQuestionSelected}
        >
          <FileDocumentIcon /> Questions
        </ExerciseModeButton>
        <ExerciseModeButton
          onClick={handleAccountType(ExerciseType.Flashcards)}
          selected={isFlashcardSelected}
        >
          <FlashcardsIcon /> Flashcards
        </ExerciseModeButton>
      </PathContainer>
    </Modal>
  );
};

export default SelectExerciseModal;
