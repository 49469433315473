import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { GridRow } from 'components/GridRow';
import { GridCell } from 'components/GridCell';
import setMultiAnswerColumnTemplate from './setMultiAnswerColumnTemplate';
import { MultiAnswerColumn } from '../types';

const Label = styled(Box)(({ theme: { palette, typography } }) => ({
  color: palette.text.primary,
  ...typography.body2Medium,
  '&::first-letter': {
    textTransform: 'uppercase',
  },
}));

export const TableCell = styled(GridCell)(({ theme: { spacing } }) => ({
  '&:nth-of-type(1):not(.chevron)': {
    padding: spacing(0, 4, 0, 8),
  },
  '&:first-of-type': {
    padding: spacing(0, 4, 0, 8),
  },
}));

interface MultiAnswerTableHeadProps {
  columns: MultiAnswerColumn[];
}

const MultiAnswerTableHead = ({
  columns,
}: MultiAnswerTableHeadProps): JSX.Element => {
  return (
    <GridRow columns={columns} setColumnTemplate={setMultiAnswerColumnTemplate}>
      {columns.map(({ align, key }) => (
        <TableCell align={align} key={key as string}>
          <Label>{key}</Label>
        </TableCell>
      ))}
    </GridRow>
  );
};

export default MultiAnswerTableHead;
