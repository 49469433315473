import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { FolderLockOutlineIcon, FolderOutlineIcon } from 'components/Icons';
import { useDemo } from 'Auth';

interface LabelProps {
  name: string;
  demo?: boolean;
}

const Wrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),

  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
  },

  '&.locked': {
    '& .MuiSvgIcon-root': {
      color: palette.icon.light,
    },
  },
}));

const LibraryLabel = ({ name, demo: useInDemo }: LabelProps): JSX.Element => {
  const isDemo = useDemo();

  const locked = isDemo && !useInDemo;

  return (
    <Wrapper className={clsx({ locked })}>
      {locked ? <FolderLockOutlineIcon /> : <FolderOutlineIcon />}
      {name}
    </Wrapper>
  );
};

export default LibraryLabel;
