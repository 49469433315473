import React from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { FlagIcon } from 'components/Icons';
import StyledPill from './StyledPill';
import { ExerciseProgressStatus } from 'types';
import QuestionValidationMark from 'pages/Questions/QuestionValidationMark';
import { useMarkdownTextScale } from 'components/Markdown';

interface QuestionPillsProps {
  label: string;
  marked: boolean;
  status: ExerciseProgressStatus;
  selected: boolean;
  onClick: () => void;
}

const QuestionPill = ({
  label,
  marked,
  status,
  selected,
  onClick,
}: QuestionPillsProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const active = selected ? `${status}-active` : status;

  return (
    <StyledPill
      className={clsx(active)}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={onClick}
      scale={scale}
    >
      <Box className="left-side">
        {label}
        {marked ? (
          <span className="start-adornment">
            <FlagIcon />
          </span>
        ) : null}
      </Box>
      <Box className="end-adornment">
        <QuestionValidationMark showAsNotAnswered status={status} />
      </Box>
    </StyledPill>
  );
};

export default QuestionPill;
