import { Theme } from '@mui/material/styles';
import { ActiveDataPoint, ChartEvent, ChartOptions } from 'chart.js';
import { EProductType } from '@quesmed/types-rn/models';

import { formatDate } from 'utils';
import {
  externalTooltipHandler,
  osceLabelTransform,
} from '../utils/TooltipHandlers';
import {
  ItemStatusLabel,
  Nullable,
  StationsActivity,
  Undefinable,
} from 'types';

export const setChartOptions = (
  theme: Theme,
  checkedMarks: {
    date: string;
    sum: number;
  }[],
  totalMarks: Record<string, number>,
  handleBarClick: (event: ChartEvent, elements: ActiveDataPoint[]) => void,
  product?: Nullable<EProductType>
): ChartOptions<'bar'> => ({
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      intersect: true,
      enabled: false,
      // TODO: Research Chart.js context interface.
      external: (context: any) => externalTooltipHandler(context, product),
      callbacks: {
        // TODO: Research Chart.js context interface.
        label: (context: any) =>
          osceLabelTransform(context, checkedMarks, totalMarks),
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  onClick: handleBarClick,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      border: {
        display: false,
      },
      grid: {
        color: (context: any) => {
          if (context.tick.value > 0) {
            return theme.palette.stroke.main;
          }

          return theme.palette.stroke.main;
        },
      },
      ticks: {
        stepSize: 0.5,
        count: 3,
      },
      max: 100,
    },
  },
});

export const setChartData = (
  data: Undefinable<number>[][],
  labels: string[],
  theme: Theme,
  isMobile: boolean,
  clickedIndex: Nullable<number>
) => {
  const { palette } = theme;

  const performedBGColor = data[0].map((_, index: number) => {
    return index === clickedIndex
      ? palette.success.light
      : palette.chart.successLight;
  });

  return {
    labels,
    datasets: [
      {
        label: ItemStatusLabel.Performed,
        data: data[0],
        backgroundColor: performedBGColor,
        borderColor: palette.background.paper,
        hoverBackgroundColor: palette.success.light,
        hoverBorderColor: palette.background.paper,
        borderWidth: isMobile ? 2 : 3,
        barThickness: isMobile ? 25 : 85,

        borderSkipped: false,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8,
        },
      },
      {
        label: ItemStatusLabel.NotPerformed,
        data: data[1],
        backgroundColor: palette.chart.background,
        borderColor: palette.background.paper,
        hoverBackgroundColor: palette.chart.background,
        hoverBorderColor: palette.background.paper,
        borderWidth: isMobile ? 2 : 3,
        barThickness: isMobile ? 25 : 85,

        borderSkipped: false,
        borderRadius: 8,
      },
    ],
  };
};
// TODO: Remove and use the utls one when added.
export const detectAndSum = (
  osceMarksheets: StationsActivity
): { date: string; sum: number }[] => {
  const result: { [key: string]: number } = {};

  if (osceMarksheets) {
    for (const day of osceMarksheets) {
      const formattedDate = formatDate(day.date);
      result[formattedDate] = 0;

      for (const osceMarksheet of day.result) {
        const { correct } = osceMarksheet;
        result[formattedDate] += correct;
      }
    }
  }

  //sum of all checked objects
  return Object.entries(result).map(([date, sum]) => ({
    date,
    sum,
  }));
};
// TODO: Move to utils file.
export const allMarks = (osceMarksheets: StationsActivity) => {
  const results: Record<string, number> = {};

  for (const day of osceMarksheets) {
    const formattedDate = formatDate(day.date);
    results[formattedDate] = 0;

    day.result.forEach(result => {
      const { total } = result;
      results[formattedDate] += total;
    });
  }

  return results;
};
