import {
  EQuestionType,
  IMarksheetMark,
  IQuestionChoice,
  IQuestionMultiQ,
} from '@quesmed/types-rn/models';
import { correctMark } from '@quesmed/types-rn/utils';

import {
  ExerciseProgressStatus,
  IAnswerObject,
  MatchingAnswer,
  RankingAnswer,
  Select3Answer,
} from 'types';
import {
  isEMQuestion,
  isQuestionQA,
  isRankingQuestion,
  isSelect3AnsweresQuestion,
} from '../types';
import { parseJSON } from '../json';
import { parseQaAnswer } from './parseQaAnswer';

const checkIsValid =
  (answers: [string[], string[]], combinedAnswer?: IAnswerObject) =>
  (index: number, key: keyof IAnswerObject) =>
    [...answers[index]]
      .sort()
      .every((answer, i) =>
        combinedAnswer ? answer === combinedAnswer[key][i] : false
      );

const checkRankingAnswers = (
  userAnswers: RankingAnswer,
  correctAnswers: string[]
): boolean => userAnswers.every((answer, i) => answer === correctAnswers[i]);

const checkSelect3Answers = (
  userAnswers: Select3Answer,
  correctAnswers: string[]
): boolean => userAnswers.every((answer, i) => answer === correctAnswers[i]);

const checkMatchingAnswers = (
  userAnswers: MatchingAnswer,
  correctAnswers: [string, string][]
) =>
  userAnswers.every((answer, i) => {
    const isCorrect = answer.every(
      (answer, j) => answer === correctAnswers[i][j]
    );

    return isCorrect;
  });

export const setQuestionStatus = (
  mark: IMarksheetMark,
  showStatus = true
): ExerciseProgressStatus => {
  const { questionChoiceId, isAnswered, question } = mark;

  if (!isAnswered) {
    return ExerciseProgressStatus.NotAnswered;
  }

  if (showStatus) {
    if (question.typeId === EQuestionType.PRESCRIPTION_ANSWER) {
      const [{ __typename, ...parsedUserAnswer }] = JSON.parse(
        mark.mark as string
      );
      const result = correctMark({
        ...mark,
        mark: [parsedUserAnswer],
      });

      if (result.correct) {
        return ExerciseProgressStatus.Correct;
      }
    } else if (isQuestionQA(question)) {
      const answerIsCorrect =
        parseQaAnswer(mark.mark as string) === question.qaAnswer[0].dose;
      if (answerIsCorrect) {
        return ExerciseProgressStatus.Correct;
      }
    } else if (question.typeId === EQuestionType.MULTIPLE_ANSWERS) {
      const combinedAnswer = (() => {
        const parsedUserAnswers = parseJSON<IAnswerObject>(mark.mark);

        if (parsedUserAnswers) {
          return {
            firstColumn: parsedUserAnswers[0],
            secondColumn: parsedUserAnswers[1],
          };
        }
      })();

      const isChoiceValid = checkIsValid(
        (question as IQuestionMultiQ).multiAnswer,
        combinedAnswer
      );
      const answerIsCorrect =
        isChoiceValid(1, 'secondColumn') && isChoiceValid(0, 'firstColumn');

      return answerIsCorrect
        ? ExerciseProgressStatus.Correct
        : ExerciseProgressStatus.Incorrect;
    } else if (isSelect3AnsweresQuestion(question)) {
      let answerIsCorrect = false;

      const { select3Answer: correctAnswers } = question;
      const parsedUserAnswers = parseJSON<Select3Answer>(mark.mark);

      if (parsedUserAnswers) {
        parsedUserAnswers.sort();
        answerIsCorrect = checkSelect3Answers(
          parsedUserAnswers,
          correctAnswers
        );
      }

      return answerIsCorrect
        ? ExerciseProgressStatus.Correct
        : ExerciseProgressStatus.Incorrect;
    } else if (isRankingQuestion(question)) {
      let answerIsCorrect = false;

      const correctAnswers = question.rankingAnswer;
      const parsedUserAnswers = parseJSON<RankingAnswer>(mark.mark);

      if (parsedUserAnswers) {
        answerIsCorrect = checkRankingAnswers(
          parsedUserAnswers,
          correctAnswers
        );
      }

      return answerIsCorrect
        ? ExerciseProgressStatus.Correct
        : ExerciseProgressStatus.Incorrect;
    } else if (isEMQuestion(question)) {
      let answerIsCorrect = false;
      const correctAnswer = question.emqAnswer;
      const parsedUserAnswer = parseJSON<MatchingAnswer>(mark.mark);

      if (parsedUserAnswer) {
        parsedUserAnswer.sort((a, b) => a[0].localeCompare(b[0]));
        answerIsCorrect = checkMatchingAnswers(correctAnswer, parsedUserAnswer);
      }

      return answerIsCorrect
        ? ExerciseProgressStatus.Correct
        : ExerciseProgressStatus.Incorrect;
    } else {
      const { answer } =
        question.choices.find(
          (choice: IQuestionChoice) => Number(choice.id) === questionChoiceId
        ) || {};

      if (answer) {
        return ExerciseProgressStatus.Correct;
      }
    }

    return ExerciseProgressStatus.Incorrect;
  }

  return ExerciseProgressStatus.Answered;
};
