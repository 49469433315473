const findClosestIndex = (series: [number, number][], target: number) => {
  const closestIndex = series.reduce(
    (closestIndex, currentValue, currentIndex, array) => {
      const difference = Math.abs(currentValue[0] - target);
      const minDifference = Math.abs(array[closestIndex][0] - target);

      return difference < minDifference ? currentIndex : closestIndex;
    },
    0
  );

  return closestIndex;
};

export default findClosestIndex;
