import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Likes, { LikesProps, Row } from '../Likes/Likes';
import { Button } from 'components/Button';
import { useFeedbackModal } from 'components/FeedbackModal';
import locales from 'locales';
import { useSnackbar } from 'components/Snackbar';
import { useMarkdownTextScale } from 'components/Markdown';

const DEFAULT_LIKE_QUESTION = 'How did you find this question?';

const QuestionLikeWrapper = styled(Box)<{ scale: number }>(
  ({ scale, theme: { spacing, typography, breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: spacing(4),
    margin: spacing(6, 0),
    fontSize: `calc(${typography.body2Medium.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2Medium.lineHeight} * ${scale})`,
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  })
);

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
  '&.MuiButtonBase-root.MuiButton-containedSecondary': {
    background: palette.toggle.light,
  },
}));

interface QuestionLikeProps extends LikesProps {
  question?: string;
  isSample?: boolean;
}

const QuestionLike = ({
  question = DEFAULT_LIKE_QUESTION,
  isLikedByMe,
  isSample,
  handleLike: sourceHandleLike,
  ...props
}: QuestionLikeProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const { openFeedbackModal } = useFeedbackModal();
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  const handleFeedback = () => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      openFeedbackModal();
    }
  };

  const handleLike = (like: boolean) => () => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      sourceHandleLike(like)();
    }
  };

  return (
    <QuestionLikeWrapper scale={scale}>
      {question}
      <Row>
        <Likes
          handleLike={handleLike}
          isLikedByMe={isLikedByMe}
          showCount={Boolean(isLikedByMe)}
          {...props}
        />
        <StyledButton color="secondary" onClick={handleFeedback}>
          Send Feedback
        </StyledButton>
      </Row>
    </QuestionLikeWrapper>
  );
};

export default QuestionLike;
