import React from 'react';
import { mdiCloseBoxOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckboxIncorrectIcon = (props: IconProps) => (
  <Icon path={mdiCloseBoxOutline} {...props} />
);

export default CheckboxIncorrectIcon;
