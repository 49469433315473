import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { EProductType, IUser } from '@quesmed/types-rn/models';

import {
  DashboardHeader,
  DashboardWrapper,
  HeatmapContainer,
  PerformanceAndDailyContainer,
  WidgetsContainer,
} from './dashboard.styles';
import { Performance } from './components/Performance';
import { parseDate } from 'utils';
import { DailyFlashcards } from './components/DailyFlashcards';
import { ExamCountdown } from './components/ExamCountdown';
import { H4 } from 'components/Typography';
import QBankActivity from './components/Activity/QBankActivity';
import { usePerformance } from './components/Performance/usePerformance';
import { Nullable } from 'types';
import { useDashboardQuery, usePerformanceChartQuery } from './hooks';
import { ContinueStudyLink } from 'components/JoinLink';
import { usePlatform } from 'context/PlatformState';
import { PerformanceChart } from './components/PerformanceChart';
import { Heatmap } from './components/Heatmap';

const parseExamDate = (date?: Nullable<number | Date>) =>
  date ? new Date(parseDate(date)) : null;

interface DashboardProps {
  user?: IUser;
  withFlashcards?: boolean;
}

const Dashboard = ({ withFlashcards }: DashboardProps): JSX.Element => {
  const [withArgs, setWithArgs] = useState(false);
  const toggleArgs = () => {
    setWithArgs(prev => !prev);
  };
  const { data, loading } = useDashboardQuery();
  const { product } = usePlatform();

  const { data: performanceData, loading: performanceLoading } =
    usePerformance();

  const { latestSessionId, latestPastMarksheetId, user } = data || {};
  const { settings, classYear, universityId } = user || {};

  const { performanceChartData, loading: performanceChartLoading } =
    usePerformanceChartQuery(
      withArgs,
      classYear || 'Year 1',
      universityId || 0
    );

  const productIsQbankMrcp1OrMrcp2 =
    product === EProductType.QBANK ||
    product === EProductType.MRCP_PART1 ||
    product === EProductType.MRCP_PART2;

  return (
    <DashboardWrapper>
      <DashboardHeader>
        <H4>Dashboard</H4>

        {loading ? (
          <Skeleton height={48} width={300} />
        ) : (
          <ExamCountdown
            examDate={parseExamDate(settings?.productSettings.examDate)}
          />
        )}
      </DashboardHeader>
      {latestPastMarksheetId ? (
        <ContinueStudyLink sessionId={latestPastMarksheetId.toString()} solo />
      ) : null}
      {latestSessionId ? (
        <ContinueStudyLink sessionId={latestSessionId} />
      ) : null}
      <WidgetsContainer>
        <PerformanceAndDailyContainer>
          <Performance
            data={performanceData}
            loading={performanceLoading}
            withTopics={!withFlashcards}
          />
          {withFlashcards ? <DailyFlashcards /> : null}
        </PerformanceAndDailyContainer>
        <HeatmapContainer>
          <Heatmap />
        </HeatmapContainer>
      </WidgetsContainer>
      <QBankActivity />
      {productIsQbankMrcp1OrMrcp2 && (
        <PerformanceChart
          data={performanceChartData}
          loading={performanceChartLoading}
          toggleArgs={toggleArgs}
        />
      )}
    </DashboardWrapper>
  );
};

export default Dashboard;
