import React from 'react';
import { EPrescriptionType, IPrescribeAnswer } from '@quesmed/types-rn/models';
import { Control } from 'react-hook-form';

import { PrescriptionContainer } from './PrescriptionAnswer.styles';
import PrescriptionAnswerField from './PrescriptionAnswerField';
import { PrescriptionField, PrescriptionOption } from '../types';
import { entriesWithoutTypename } from 'utils';
import { getAnswerStatus } from './getAnswerStatus';
import { ExpandableSection } from 'components/ExpandableSection';

const prescriptionFieldToTypeMap = {
  dose: EPrescriptionType.Dose,
  drug: EPrescriptionType.Drug,
  duration: EPrescriptionType.Duration,
  frequency: EPrescriptionType.Frequency,
  route: EPrescriptionType.Route,
};

export interface PrescriptionFormProps {
  questionId: number;
  correct?: boolean;
  title: string;
  answer: IPrescribeAnswer;
  preview?: boolean;
  defaultExpanded?: boolean;
  control?: Control<PrescriptionField>;
}

const PrescriptionForm = ({
  correct,
  title,
  answer,
  preview,
  questionId,
  control,
  defaultExpanded,
}: PrescriptionFormProps): JSX.Element => {
  const answerEntries = entriesWithoutTypename(answer);
  const alwaysDisplay = correct !== undefined;
  const variant = getAnswerStatus(correct);

  return (
    <ExpandableSection
      disableContentBackground
      expandable={!alwaysDisplay}
      expanded={defaultExpanded}
      size="medium"
      title={title}
      variant={preview ? 'normal' : variant}
    >
      <PrescriptionContainer>
        {answerEntries.map(([key, data]) => {
          const { visible: readOnly } = data || ({} as typeof data);

          return (
            <PrescriptionAnswerField
              control={control}
              defaultValue={data as PrescriptionOption}
              inputType="text"
              key={key}
              locked={alwaysDisplay || readOnly}
              name={key}
              questionId={questionId}
              type={prescriptionFieldToTypeMap[key]}
            />
          );
        })}
      </PrescriptionContainer>
    </ExpandableSection>
  );
};

export default PrescriptionForm;
