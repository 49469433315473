const getPacesLibraryName = ({
  name = '',
  title = '',
  hiddenName = '',
  shownName = '',
  userViewed = false,
}) => {
  const itemName = name || title;

  if (userViewed) {
    return shownName || itemName;
  }

  return hiddenName || itemName;
};

export default getPacesLibraryName;
