import React from 'react';
import { mdiContentDuplicate } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const DuplicateIcon = (props: IconProps) => (
  <Icon path={mdiContentDuplicate} {...props} />
);

export default DuplicateIcon;
