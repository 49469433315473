import React, {
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IPicture } from '@quesmed/types-rn/models';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import { QuestionAnswerStatus } from 'types';
import { Radio } from 'components/RadioGroup';
import QuestionChoiceLabel from './QuestionChoiceLabel';
import setAnswerState from './setAnswerState';
import setValidationClass from './setValidationClass';
import setRadioIcon from './setRadioIcon';
import QuestionChoiceExplanation from './QuestionChoiceExplanation';
import QuestionChoiceContainer from './QuestionChoiceContainer';
import { useLongPress } from 'hooks';
import { useMarkdownTextScale } from 'components/Markdown';

const EXPANDED_STATES = [
  QuestionAnswerStatus.INVALID,
  QuestionAnswerStatus.VALID,
  QuestionAnswerStatus.NOT_ANSWERED,
];

interface QuestionChoiceProps {
  checked: boolean;
  choiceVariant: number | string;
  explanation: string | null;
  isAnswered: boolean;
  isChoiceCorrect: boolean;
  isCrossOut?: boolean;
  isPreview?: boolean;
  label: string;
  onContextMenu?: (event: MouseEvent | TouchEvent) => void;
  picture?: IPicture | null;
  showAnswers: boolean;
  value: number;
  votes: number;
}

const QuestionChoice = ({
  checked,
  choiceVariant,
  explanation,
  isChoiceCorrect,
  isAnswered,
  isPreview,
  isCrossOut,
  label,
  onContextMenu,
  picture,
  showAnswers,
  value,
  votes,
}: QuestionChoiceProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();

  const choiceState = setAnswerState(
    showAnswers,
    isAnswered,
    isChoiceCorrect,
    checked
  );
  const [isExplanationVisible, setIsExplanationVisible] = useState(
    EXPANDED_STATES.includes(choiceState)
  );

  const toggleExplanationVisibility = useCallback(
    (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      (e.target as HTMLElement).blur();
      if (showAnswers) {
        e.stopPropagation();
        e.preventDefault();
        setIsExplanationVisible(!isExplanationVisible);
      }
    },
    [isExplanationVisible, showAnswers]
  );

  const handlers = useLongPress<HTMLDivElement>({
    onClick: toggleExplanationVisibility,
    onLongPress: onContextMenu,
  });

  useEffect(() => {
    if ((showAnswers && EXPANDED_STATES.includes(choiceState)) || isPreview) {
      setIsExplanationVisible(true);
    } else setIsExplanationVisible(false);
  }, [showAnswers, isPreview, choiceState]);

  return (
    <QuestionChoiceContainer
      className={setValidationClass(choiceState)}
      expanded={isExplanationVisible ? 1 : 0}
      onContextMenu={onContextMenu}
      scale={scale}
      {...handlers}
    >
      <Radio
        checked={checked}
        checkedIcon={setRadioIcon(choiceState)}
        disabled={isPreview}
        icon={setRadioIcon(choiceState)}
        label={
          <QuestionChoiceLabel
            choiceVariant={choiceVariant}
            isCrossOut={isCrossOut}
            showAnswers={showAnswers}
            text={label}
          />
        }
        readOnly={showAnswers}
        value={value}
      />
      <Collapse
        in={isExplanationVisible}
        onClick={e => e.stopPropagation()}
        unmountOnExit
      >
        <Divider />
        <QuestionChoiceExplanation
          explanation={explanation}
          isChosen={checked}
          picture={picture}
          votes={votes}
        />
      </Collapse>
    </QuestionChoiceContainer>
  );
};

export default QuestionChoice;
