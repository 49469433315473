import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  IUkmlaTopicsData,
  IUkmlaTopicsVar,
  UKMLA_TOPICS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { ESortOrder } from '@quesmed/types-rn';

import { compareName, transformMLATopics } from 'utils';
import { ExtendedMLATopic, MLATopicMap, Nullable } from 'types';

interface Topics {
  error?: ApolloError;
  loading: boolean;
  topics: Nullable<ExtendedMLATopic[]>;
  topicMap: MLATopicMap;
}

const useMLATopicQuery = (): Topics => {
  const { loading, data } = useQuery<IUkmlaTopicsData, IUkmlaTopicsVar>(
    UKMLA_TOPICS,
    {
      variables: { order: ESortOrder.ASC },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { ukmlaTopics } = data?.restricted || {};

  const topics = useMemo(() => {
    if (!ukmlaTopics) {
      return null;
    }

    return ukmlaTopics.map(({ name, conditions, presentations, ...rest }) => {
      const sortedConditions = conditions
        ? [...conditions].sort(compareName)
        : undefined;

      const sortedPresentations = presentations
        ? [...presentations].sort(compareName)
        : undefined;

      return {
        ...rest,
        name,
        conditions: sortedConditions,
        presentations: sortedPresentations,
        searchNames: `${name}, ${
          sortedConditions?.map(({ name }) => name).join(',') ?? ''
        }, ${sortedPresentations?.map(({ name }) => name).join(',') ?? ''}`,
      };
    });
  }, [ukmlaTopics]);

  const [topicMap] = useMemo(() => transformMLATopics(topics), [topics]);

  return { topics, loading, topicMap };
};

export default useMLATopicQuery;
