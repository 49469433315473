import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { isNil, isNull } from 'lodash';
import { EMarksheetState, IMarksheet, IUser } from '@quesmed/types-rn/models';

import {
  useJoinSearchParam,
  useLeaveExerciseState,
  useMarksheetLeavingBlockade,
  useRedirect,
} from 'hooks';
import { AgoraProvider } from 'components/Agora';
import Heartbeat from './Heartbeat';
import { StudyAlreadyStartedModal } from 'components/StudyAlreadyStartedModal';
import { paths } from 'Router';
import QuestionsQuizLeaveModal from './QuestionsQuizLeaveModal';
import { QuestionsModal, useGroupStudy, useQuestionsModalState } from './hooks';
import { Nullable, StartOrJoinMutation } from 'types';
import { GroupStudyGuard } from 'components/GroupStudyGuard';
import { CircularProgress } from 'components/CircularProgress';

const { questions } = paths;
const { root, groupLobby } = questions;
interface InitialStudyState {
  activeUsers?: IUser[];
  inviteLink: string;
}

type QuestionGroupStudyContext = {
  sessionId: Nullable<string>;
  marksheet: IMarksheet;
  inviteLink: string;
  loading: boolean;
  restartSession?: StartOrJoinMutation;
  initialState?: InitialStudyState;
};

type GroupStudyRouteParams = {
  sessionId?: string;
  marksheetId?: string;
  markId?: string;
  questionId?: string;
  questionNumber?: string;
};

const STUDY_STATES = [EMarksheetState.STATION, EMarksheetState.RESULT];

export const useQuestionGroupStudyData = () => {
  return useOutletContext<QuestionGroupStudyContext>();
};

const QuestionsGroupStudy = () => {
  const navigate = useNavigate();
  const joinSessionURL = useJoinSearchParam();

  const { sessionId: sessionIdParam } = useParams<GroupStudyRouteParams>();
  const { removeRedirectLink } = useRedirect();
  const { shownModal, closeModal } = useQuestionsModalState();
  const { open } = useLeaveExerciseState();
  const sessionId = sessionIdParam || joinSessionURL || '';
  const { marksheet, inviteLink, restartSession } = useGroupStudy(sessionId);
  const { pathname } = useLocation();

  const { state, id: marksheetId, marks, currentMarkId } = marksheet || {};
  const { state: initialState } = useLocation<InitialStudyState>();

  const questionId =
    marks?.find(mark => Number(mark?.id) === Number(currentMarkId))?.question
      .id || marks?.[0]?.questionId;

  useEffect(() => {
    removeRedirectLink();

    return () => {
      if (!isNull(shownModal)) {
        closeModal();
      }
    };
  }, [closeModal, removeRedirectLink, shownModal]);

  useEffect(() => {
    switch (state) {
      case EMarksheetState.PRESTART:
        if (!pathname.includes(`${root}/${groupLobby}/quiz/builder`)) {
          navigate(
            `${root}/${groupLobby}/quiz/builder/${sessionId}/${marksheetId}`
          );
        }
        break;
      case EMarksheetState.STATION: {
        if (marks?.length && !pathname.includes('summary')) {
          navigate(
            `${root}/${groupLobby}/quiz/${sessionId}/${marksheetId}/${
              currentMarkId || marks?.[0]?.id
            }/${questionId}`
          );
        }

        break;
      }
      case EMarksheetState.RESULT:
        navigate(
          `${root}/${groupLobby}/quiz/summary/${sessionId}/${marksheetId}`
        );
        break;
      default:
        break;
    }
  }, [
    state,
    marksheetId,
    navigate,
    sessionId,
    marks?.length,
    pathname,
    currentMarkId,
    questionId,
    marks,
  ]);

  const ignoreBlockade =
    (!isNil(state) && STUDY_STATES.includes(state)) || !marksheet;

  useMarksheetLeavingBlockade({
    marksheetId,
    shouldBlock: Boolean(marksheet),
    ignoreBlockade,
    solo: false,
  });

  const showStudy = Boolean(initialState || marksheet || state === undefined);

  return (
    <>
      <GroupStudyGuard checkAudio checkTOC noLoading />
      <Heartbeat sessionId={marksheet ? sessionId : null} />
      {showStudy ? (
        <Outlet
          context={{
            marksheet,
            inviteLink,
            sessionId,
            restartSession,
            initialState,
          }}
        />
      ) : (
        <CircularProgress description="Loading group study..." />
      )}
      <StudyAlreadyStartedModal
        isOpen={shownModal === QuestionsModal.ErrorModal}
        onClose={closeModal}
      />
      {open ? (
        <QuestionsQuizLeaveModal
          marksheet={marksheet}
          marksheetId={marksheetId}
          open={open}
        />
      ) : null}
    </>
  );
};

export const QuestionsGroupStudyController = () => (
  <AgoraProvider>
    <QuestionsGroupStudy />
  </AgoraProvider>
);

export default QuestionsGroupStudyController;
