import React, { useEffect, useMemo, useState } from 'react';
import { IFlaggedQuestionDataResults } from '@quesmed/types-rn/resolvers/query/restricted';
import { EProductType, IUserFlaggedQuestion } from '@quesmed/types-rn/models';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { KeyField } from 'components/ExerciseBuilder/types';
import {
  SelectionColumn,
  SelectionHeading,
  SelectionTable,
} from 'components/ExerciseBuilder';
import { BottomSelectionBar } from 'components/BottomSelectionBar';
import {
  CheckboxState,
  SelectionBarLabels,
  TopicSelectionState,
  UIColumn,
} from 'types';
import useBuildQuestionMarksheet from '../hooks/useBuildQuestionMarksheet';
import { formatDate } from 'utils';
import { paths } from 'Router';
import NoDataCard from './NoDataCard';
import FlagOutlineIcon from 'components/Icons/FlagOutlineIcon';
import { useDemo } from 'Auth';
import useFlaggedQuestionsQuery from '../hooks/useFlaggedQuestionsQuery';
import SkeletonList from 'components/Skeleton/SkeletonList';

type Columns = IUserFlaggedQuestion & UIColumn;

const columns: SelectionColumn<Columns>[] = [
  {
    align: 'left',
    key: KeyField.Checkbox,
    type: 'input',
  },
  {
    align: 'left',
    formatter: ({ question }) => question.question,
    key: KeyField.Label,
    type: 'label',
    label: 'Question',
  },
  {
    align: 'left',
    formatter: ({ question }) => {
      const { concept } = question || {};
      const { topic } = concept || {};
      const { name = 'Topic name' } = topic || {};

      return name;
    },
    key: KeyField.Topic,
    type: 'default',
    label: KeyField.Topic,
  },
  {
    align: 'left',
    formatter: ({ question }) => {
      const { concept } = question || {};
      const { name = 'Concept name' } = concept || {};

      return name;
    },
    key: KeyField.Concept,
    type: 'default',
    label: KeyField.Concept,
  },
  {
    align: 'left',
    key: KeyField.Added,
    type: 'default',
    label: KeyField.Added,
    formatter: ({ createdAt = new Date() }) => formatDate(createdAt),
  },
];

const FlagsContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(0, 6),

  [breakpoints.up('md')]: {
    padding: 0,
  },
}));

const createQuestionMap = (questions: IFlaggedQuestionDataResults) => {
  const tempMap = new Map<number, number>();

  questions.forEach(({ id, questionId }) =>
    tempMap.set(Number(id), Number(questionId))
  );

  return tempMap;
};

const Flags = (): JSX.Element => {
  const isDemo = useDemo();
  const [selectedQuestions, setSelectedQuestions] = useState<
    Map<number, TopicSelectionState>
  >(new Map());
  const { data: questions = [], loading } = useFlaggedQuestionsQuery();
  const [filteredData, setFilteredData] = useState(questions);
  const { buildMarksheet } = useBuildQuestionMarksheet();
  const navigate = useNavigate();
  const questionsMap = useMemo(() => createQuestionMap(questions), [questions]);
  const handleSearch = (searchTerm: string) => {
    setFilteredData(
      questions?.filter(
        ({ question }) =>
          question.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          question.concept?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          question.concept?.topic?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setFilteredData(questions);
  }, [questions]);

  const toggleSelectAll = () => {
    if (selectedQuestions.size > 0) {
      return setSelectedQuestions(new Map());
    }
    setSelectedQuestions(prev => {
      questions.map(({ id }) => {
        prev.set(Number(id), {
          id,
          conceptIds: new Set(),
          conceptsAvailable: 5,
          selectedConceptsIds: new Set(),
          topicState: CheckboxState.CHECKED,
          type: EProductType.ALL,
        });
      });

      return new Map(prev);
    });
  };

  const handleSelectRow = (id: number) => () => {
    setSelectedQuestions(prev => {
      if (prev.has(id)) {
        prev.delete(id);
      } else {
        prev.set(id, {
          id,
          conceptIds: new Set([id]),
          conceptsAvailable: 5,
          selectedConceptsIds: new Set([id]),
          topicState: CheckboxState.CHECKED,
          type: EProductType.ALL,
        });
      }

      return new Map(prev);
    });
  };

  const handleReview = () => {
    if (isDemo) {
      return;
    }

    navigate(`${paths.questions.root}/flagged`);
  };

  const handleUnselect = () => {
    setSelectedQuestions(new Map());
  };

  const handleContinue = () => {
    if (isDemo) {
      return;
    }

    buildMarksheet(
      Array.from(selectedQuestions.keys()).map(id => questionsMap.get(id) || 1)
    );
  };

  const selectionInfo = `${selectedQuestions.size} item${
    selectedQuestions.size > 1 ? 's' : ''
  } selected`;

  const allSelected =
    selectedQuestions.size === 0
      ? CheckboxState.UNCHECKED
      : selectedQuestions.size === questions.length
      ? CheckboxState.CHECKED
      : CheckboxState.INTERMEDIATE;

  if (loading) {
    return <SkeletonList count={9} height={56} spacing={1} />;
  }

  if (questions?.length) {
    return (
      <FlagsContainer>
        <SelectionHeading
          loading={loading}
          noCategories
          onSearch={handleSearch}
          searchLabel="Search"
          subtitle="Keep track of important questions by flagging them for later."
          title="Flagged questions"
        />
        <SelectionTable
          allSelected={allSelected}
          columns={columns}
          data={filteredData}
          globalLock={isDemo}
          headerLock={isDemo}
          loading={loading}
          onSelectAll={toggleSelectAll}
          onSelectRow={handleSelectRow}
          selectOnRowClick
          selectionState={selectedQuestions}
          stickyHeader
          widget
        />
        {selectedQuestions.size > 0 ? (
          <BottomSelectionBar
            continueLabel={SelectionBarLabels.Continue}
            onContinue={handleContinue}
            onReviewOrSavePreset={handleReview}
            onUnselect={handleUnselect}
            reviewOrPresetLabel={SelectionBarLabels.Review}
            selectedItemsLabel={selectionInfo}
            unselectlabel={SelectionBarLabels.UnselectAll}
            withoutPanel
          />
        ) : null}
      </FlagsContainer>
    );
  }

  return (
    <NoDataCard
      icon={<FlagOutlineIcon />}
      text="This feature allows you to easily revisit questions you want to focus on or review at a later time."
      title="No questions flagged yet"
    />
  );
};

export default Flags;
