import React from 'react';

import { Nullable, TableData } from 'types';
import SelectionCategories, {
  SelectionCategoriesProps,
} from './SelectionCategories';
import SelectionHeading, { SelectionHeadingProps } from './SelectionHeading';
import MLASelectionTable, { MLASelectionTableProps } from './MLASelectionTable';
import { LearningMaterialItem } from 'components/LearningMaterials';

type ExerciseBuilderProps<
  T extends TableData,
  K,
  N
> = SelectionCategoriesProps<K> &
  SelectionHeadingProps<N> &
  MLASelectionTableProps<T> & {
    searchItems?: Nullable<LearningMaterialItem[]>;
  };

function MLAExerciseBuilder<T extends TableData, K, N>({
  activeCategory,
  allSelected,
  categoryOptions,
  checkedIcon,
  columns,
  data,
  globalLock,
  headerLock,
  icon,
  loading,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  onSelectAll,
  onSelectRow,
  onSelectCondition,
  onSelectPresentation,
  onSearch,
  onToggleCategory,
  onToggleView,
  resetSearch,
  searchLabel,
  selectedView,
  selectedItemId,
  selectionState,
  title,
  subtitle,
  noCategories,
  viewOptions,
  withOffset,
  selectOnRowClick,
  isCandidate,
}: ExerciseBuilderProps<T, K, N>): JSX.Element {
  return (
    <>
      <SelectionCategories
        activeCategory={activeCategory}
        categoryOptions={categoryOptions}
        onToggleCategory={onToggleCategory}
      />
      <SelectionHeading
        loading={loading}
        noCategories={noCategories}
        onSearch={onSearch}
        onToggleView={onToggleView}
        resetSearch={resetSearch}
        searchLabel={searchLabel}
        selectedView={selectedView}
        showViewToggle={!mlaColumns}
        subtitle={subtitle}
        title={title}
        viewOptions={viewOptions}
      />
      <MLASelectionTable
        allSelected={allSelected}
        checkedIcon={checkedIcon}
        columns={columns}
        data={data}
        globalLock={globalLock}
        headerLock={headerLock}
        icon={icon}
        loading={loading}
        mlaColumns={mlaColumns}
        mlaConditionColumns={mlaConditionColumns}
        mlaPatientPresentationColumns={mlaPatientPresentationColumns}
        onSelectAll={onSelectAll}
        onSelectCondition={onSelectCondition}
        onSelectPresentation={onSelectPresentation}
        onSelectRow={onSelectRow}
        selectOnRowClick={selectOnRowClick}
        selectedItemId={!isCandidate ? selectedItemId : undefined}
        selectionState={selectionState}
        withOffset={withOffset}
      />
    </>
  );
}

export default MLAExerciseBuilder;
