import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const StyledProgressButton = styled(Button)(
  ({ theme: { palette, spacing } }) => ({
    height: spacing(8),
    backgroundColor: palette.background.default,
    color: palette.text.primary,
    borderRadius: 0,

    '&:focus': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },

    /* Each class corresponds to the figma designs */
    '&.unknown, &.unknown.review': {
      backgroundColor: palette.primary.extraLight,
      color: palette.text.primary,
    },
    '&.unknown.disabledColors': {
      backgroundColor: palette.background.default,
      color: palette.text.primary,
    },
    '&.unknown:focus, &.unknown-selected, &.unknown:focus.review, &.unknown-selected.review':
      {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
      },
    '&.unknown:focus.disabledColors, &.unknown-selected.disabledColors': {
      backgroundColor: palette.primary.main,
      color: palette.secondary.contrastText,
    },

    '&.incorrect': {
      backgroundColor: palette.error.extraLight,
      color: palette.text.primary,
    },
    '&.incorrect.disabledColors': {
      backgroundColor: palette.background.default,
      color: palette.text.primary,
    },
    '&.incorrect.review': {
      backgroundColor: palette.error.extraLight,
      color: palette.text.primary,
    },
    '&.incorrect-selected, &.incorrect:focus': {
      backgroundColor: palette.error.main,
      color: palette.primary.contrastText,
    },
    '&.incorrect-selected.disabledColors, &.incorrect:focus.disabledColors': {
      backgroundColor: palette.primary.main,
      color: palette.secondary.contrastText,
    },
    '&.incorrect-selected.review, &.incorrect:focus.review': {
      backgroundColor: palette.error.main,
      color: palette.primary.contrastText,
    },

    '&.correct, &.correct.review': {
      backgroundColor: palette.success.extraLight,
      color: palette.text.primary,
    },
    '&.correct.disabledColors': {
      backgroundColor: palette.background.default,
      color: palette.text.primary,
    },
    '&.correct-selected, &.correct:focus, &.correct-selected.review, &.correct:focus.review':
      {
        backgroundColor: palette.success.main,
        color: palette.primary.contrastText,
      },
    '&.correct-selected.disabledColors, &.correct:focus.disabledColors': {
      backgroundColor: palette.primary.main,
      color: palette.secondary.contrastText,
    },

    '&.familiar, &.familiar.review': {
      backgroundColor: palette.warning.extraLight,
      color: palette.text.primary,
    },
    '&.familiar.disabledColors': {
      backgroundColor: palette.background.default,
      color: palette.text.primary,
    },
    '&.familiar-selected, .familiar:focus': {
      backgroundColor: palette.warning.main,
      color: palette.text.primary,
    },
    '&.familiar-selected.disabledColors, &.familiar:focus.disabledColors': {
      backgroundColor: palette.primary.main,
      color: palette.secondary.contrastText,
    },
  })
);

export interface ProgressButtonProps extends ButtonProps {
  label?: string;
  className?: string;
  disabledColors?: boolean;
  onClick: () => void;
}

const ProgressButton = ({
  label,
  className,
  disabledColors,
  onClick,
  sx,
}: ProgressButtonProps): JSX.Element => (
  <StyledProgressButton
    className={clsx(className, { disabledColors })}
    disableFocusRipple
    disableRipple
    disableTouchRipple
    onClick={onClick}
    sx={sx}
  >
    {label}
  </StyledProgressButton>
);

export default ProgressButton;
