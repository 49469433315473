import { useCallback } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  IStationNotesData,
  IStationNotesVar,
  STATION_NOTES,
} from '@quesmed/types-rn/resolvers/query/restricted';

interface StationNotesParams {
  limit?: number;
  offset?: number;
  onAllStationNotesFetched?: () => void;
}

const DEFAULT_INFINITE_SCROLL_LIMIT = 20;

const useStationNotesQuery = (params?: StationNotesParams) => {
  const {
    limit = DEFAULT_INFINITE_SCROLL_LIMIT,
    offset = 0,
    onAllStationNotesFetched,
  } = params || {};

  const { data, fetchMore } = useQuery<IStationNotesData, IStationNotesVar>(
    STATION_NOTES,
    {
      variables: {
        limit,
        offset,
        search: null,
      },
      onCompleted: data =>
        data.restricted.stationNotes.length < DEFAULT_INFINITE_SCROLL_LIMIT &&
        onAllStationNotesFetched
          ? onAllStationNotesFetched()
          : null,
    }
  );

  const loadMoreStationNotes = useCallback(
    (offset: number) => {
      return fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          const { stationNotes } = previousResult.restricted;
          const { stationNotes: moreNotes } = fetchMoreResult.restricted;

          const mergedData = {
            ...fetchMoreResult,
            restricted: {
              ...fetchMoreResult.restricted,
              stationNotes: stationNotes.concat(moreNotes),
            },
          };

          if (
            moreNotes.length < DEFAULT_INFINITE_SCROLL_LIMIT &&
            onAllStationNotesFetched
          ) {
            onAllStationNotesFetched();
          }

          return mergedData;
        },
      });
    },
    [fetchMore, onAllStationNotesFetched]
  );

  const [query, { data: searchData, loading: searchLoading }] = useLazyQuery<
    IStationNotesData,
    IStationNotesVar
  >(STATION_NOTES, {
    variables: {
      limit,
      offset,
      search: null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const searchStationNotes = useCallback(
    (search: string) =>
      query({
        variables: {
          search,
          limit,
          offset,
        },
      }),
    [query, limit, offset]
  );

  return {
    notes: data?.restricted.stationNotes || [],
    searchedNotes: searchData?.restricted.stationNotes || [],
    loadMoreStationNotes,
    searchStationNotes,
    searchLoading,
  };
};

export default useStationNotesQuery;
