import React from 'react';
import { mdiTimerAlertOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const TimerAlertOutlineIcon = (props: IconProps) => (
  <Icon path={mdiTimerAlertOutline} {...props} />
);

export default TimerAlertOutlineIcon;
