import { EProductType, ITopic } from '@quesmed/types-rn/models';

import { ConceptMap, Nilable, ToggleOptions, TopicItem, TopicMap } from 'types';
import { entries, sortCategoriesByTopicType } from 'utils';

export const transformTopics = (
  topics: Nilable<ITopic[]>
): [TopicMap, ToggleOptions<EProductType>] => {
  const map: TopicMap = {};
  const typeOrder: number[] = [];

  const topicTypes = {} as {
    [key in EProductType]: string;
  };

  if (topics) {
    topics.forEach(({ id, name, concepts, entitlement }) => {
      const newItem: TopicItem = { id: Number(id), name };

      if (!typeOrder[entitlement.index]) {
        typeOrder[entitlement.index] = entitlement.id;
      }

      if (!topicTypes[entitlement.id]) {
        topicTypes[entitlement.id] = entitlement.name;
      }

      if (concepts) {
        newItem.concepts = concepts.reduce((acc, cur) => {
          acc[cur.id] = cur;

          return acc;
        }, {} as ConceptMap);
      }

      if (map[id]) {
        map[id].concepts = {
          ...map[id].concepts,
          ...newItem.concepts,
        };
      } else {
        map[id] = newItem;
      }
    });
  }

  const categories = sortCategoriesByTopicType(
    entries(topicTypes).map(([key, value]) => ({
      value: Number(key),
      label: value,
    })),
    typeOrder
  );

  return [map, categories];
};
