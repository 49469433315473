import React from 'react';
import { mdiCheckboxBlankCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RadioBlankIcon = (props: IconProps) => (
  <Icon path={mdiCheckboxBlankCircleOutline} {...props} />
);

export default RadioBlankIcon;
