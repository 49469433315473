import React from 'react';
import { IQuestion } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Button } from 'components/Button';

const TagsContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(2),
}));

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
  '&.MuiButtonBase-root.MuiButton-outlined': {
    borderColor: palette.secondary.dark,
    color: palette.secondary.dark,
    cursor: 'default',

    '&:hover': {
      borderColor: palette.secondary.dark,
      color: palette.secondary.dark,
    },
  },
}));

interface Props {
  question: IQuestion;
}

const QuestionTags = ({ question }: Props) => {
  const { conditions, presentations, concept } = question || {};
  const { topic } = concept || {};
  const { name } = topic || {};

  if (!question) {
    return null;
  }

  return (
    <TagsContainer>
      <StyledButton secondary>
        {conditions?.[0]?.topic.name || presentations?.[0]?.topic.name
          ? 'MLA Area: '
          : 'Topic: '}
        {conditions?.[0]?.topic.name || presentations?.[0]?.topic.name || name}
      </StyledButton>
      {conditions?.length ? (
        <StyledButton secondary>
          MLA Condition: {conditions[0].name}
        </StyledButton>
      ) : null}
      {presentations?.length ? (
        <StyledButton secondary>
          MLA Presentation: {presentations[0].name}
        </StyledButton>
      ) : null}
    </TagsContainer>
  );
};

export default QuestionTags;
