import React from 'react';

import { Modal } from 'components/Modal';
import { Body } from 'components/Typography';

interface TimeIsUpModalProps {
  isOpen: boolean;
  onSubmit: () => void;
}

const TimeIsUpModal = ({
  isOpen,
  onSubmit,
}: TimeIsUpModalProps): JSX.Element => (
  <Modal
    onSubmit={onSubmit}
    open={isOpen}
    showCloseButton={false}
    showHeader={false}
    submitLabel="Check Feedback"
  >
    <Body>Time is up</Body>
  </Modal>
);

export default TimeIsUpModal;
