import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { NavigationHeader } from 'components/Layout';
import { PauseCircleOutlineIcon, PlayIcon } from 'components/Icons';
import { H3 } from 'components/Typography';
import { IconButton } from 'components/IconButton';
import { constSize } from 'utils/index';

const TimerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TimerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
});

const TimerButton = styled(IconButton)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.background.paper,
  ...constSize(spacing(8)),

  '&:hover': {
    backgroundColor: palette.background.paper,
  },
}));

const TimerValue = styled(H3)(({ theme: { spacing } }) => ({
  position: 'relative',
  fontFeatureSettings: "'tnum' on, 'Inum' on",
  height: spacing(16),
  top: `-${spacing(1)}`,
}));

interface TimerProps {
  disabled?: boolean;
  isPaused?: boolean;
  withControl?: boolean;
  timeLeft: string;
  onClick?: () => void;
}

const ExerciseTimer = ({
  disabled = false,
  isPaused,
  onClick,
  timeLeft,
  withControl,
}: TimerProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <TimerContainer>
      <NavigationHeader>TIME LEFT</NavigationHeader>
      <TimerBox>
        {withControl ? (
          <TimerButton disabled={disabled} onClick={handleClick}>
            {isPaused ? <PlayIcon /> : <PauseCircleOutlineIcon />}
          </TimerButton>
        ) : null}
        <TimerValue>{timeLeft}</TimerValue>
      </TimerBox>
    </TimerContainer>
  );
};

export default ExerciseTimer;
