import { ExerciseProgressStatus, FlashcardScore, Nullable } from 'types';

export const getScoreStatusClassname = (score: Nullable<FlashcardScore>) => {
  switch (score) {
    default:
    case null:
      return ExerciseProgressStatus.NotAnswered;
    case FlashcardScore.NotAtAll:
    case FlashcardScore.Slightly:
      return ExerciseProgressStatus.Incorrect;
    case FlashcardScore.Moderately:
      return ExerciseProgressStatus.Moderately;
    case FlashcardScore.KnowWell:
    case FlashcardScore.Perfectly:
      return ExerciseProgressStatus.Correct;
  }
};
