import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import {
  ItemRow,
  LearningMaterialItem,
  useUpdateConceptLearningStatus,
  useUpdateStationLearningStatus,
  useUpdateVideoLearningStatus,
} from 'components/LearningMaterials';
import { paths } from 'Router';
import { Fallback, SearchItemsContainer } from './ExerciseBuilder.styles';

const { knowledgeLibrary, videoLibrary } = paths;

interface SearchItemsListProps {
  searchItems: LearningMaterialItem[];
  title?: string;
  sectionId?: number | string;
}

export const SearchItemsList = ({
  searchItems,
  title,
  sectionId,
}: SearchItemsListProps) => {
  const navigate = useNavigate();
  const { updateStatus: updateConceptStatus } =
    useUpdateConceptLearningStatus();
  const { updateStatus: updateVideoStatus } = useUpdateVideoLearningStatus();
  const { updateStatus: updateStationStatus } =
    useUpdateStationLearningStatus();

  const handleNavigate =
    (
      isVideo: boolean,
      id: number,
      topicId?: number,
      entitlement?: Nullable<{ id: number; name: string }>
    ) =>
    () => {
      if (sectionId || topicId) {
        let path = `${knowledgeLibrary.root}/chapter/${entitlement?.id}/${
          sectionId || topicId
        }/${id}`;

        if (isVideo) {
          path = `${videoLibrary.root}/video/${entitlement?.id}/${
            sectionId || topicId
          }/${id}`;
        }
        navigate(path);
      }
    };

  const handleStatusChange =
    (
      isVideo: boolean,
      isOsceStation: boolean,
      topicId?: number,
      currentStatus?: Nullable<EUserLearningStatus>
    ) =>
    async (newStatus: EUserLearningStatus, ids: number[]) => {
      if (isVideo) {
        updateVideoStatus(ids, newStatus, currentStatus);
      } else if (topicId) {
        updateConceptStatus(
          ids,
          newStatus,
          Number(sectionId || topicId),
          currentStatus
        );
      } else if (isOsceStation) {
        updateStationStatus(ids, newStatus);
      }
    };

  return searchItems?.length ? (
    <SearchItemsContainer>
      {title ? <ItemRow name={title} /> : null}
      {searchItems?.map(
        ({ id, name, status, title, demo, topicId, osceType, entitlement }) => (
          <ItemRow
            id={Number(id)}
            items={searchItems}
            key={id}
            name={name || title}
            onClick={handleNavigate(
              Boolean(title),
              Number(id),
              topicId,
              entitlement || osceType
            )}
            onStatusChange={handleStatusChange(
              Boolean(title),
              Boolean(osceType),
              topicId,
              status
            )}
            status={status}
            useInDemo={demo}
            video={Boolean(title)}
          />
        )
      )}
    </SearchItemsContainer>
  ) : (
    <Fallback>No results found</Fallback>
  );
};
