import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { IAnswerObject } from 'types';
import MultiAnswerTable, { MultiAnswerProps } from './MultiAnswerTable';

const checkIsValid =
  (answers: [string[], string[]], combinedAnswer?: IAnswerObject) =>
  (index: number, key: keyof IAnswerObject) =>
    [...answers[index]]
      .sort()
      .every((answer, i) =>
        combinedAnswer ? answer === combinedAnswer[key][i] : false
      );

const MultiAnswerContentWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
  marginTop: spacing(6),
}));

const MultiAnswerContent = ({
  isTestFinished,
  isPreview = false,
  onChange,
  question,
  combinedAnswer,
}: MultiAnswerProps): JSX.Element => {
  const { multiAnswer } = question;
  const isChoiceValid = checkIsValid(multiAnswer, combinedAnswer);

  const correct =
    isChoiceValid(1, 'secondColumn') && isChoiceValid(0, 'firstColumn');

  return (
    <MultiAnswerContentWrapper>
      <MultiAnswerTable
        combinedAnswer={combinedAnswer}
        correct={correct}
        isPreview={isPreview}
        isTestFinished={isTestFinished}
        onChange={onChange}
        question={question}
      />
    </MultiAnswerContentWrapper>
  );
};

export default MultiAnswerContent;
