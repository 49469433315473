import React, { useCallback, useEffect, useMemo } from 'react';

import {
  ExerciseType,
  ExtendedMLATopic,
  MLASelectionState,
  MLATopicSelection,
  Nilable,
  Nullable,
  QuizTopicType,
  UIColumn,
} from 'types';
import { SelectionColumn } from './types';
import { BUILDER_VIEWS } from 'constants/general';
import { useDemo } from 'Auth';
import { MLAExerciseBuilder } from './index';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader/index';

export type QuestionColumns = ExtendedMLATopic & UIColumn;

interface MLATopicQuizBuilderProps extends MLATopicSelection {
  activeCategory?: QuizTopicType;
  detailsColumns: SelectionColumn<QuestionColumns>[];
  exerciseType: ExerciseType;
  header: string;
  solo?: boolean;
  loading: boolean;
  mlaColumns?: boolean;
  mlaConditionColumns?: SelectionColumn<QuestionColumns>[];
  mlaPatientPresentationColumns?: SelectionColumn<QuestionColumns>[];
  overviewColumns: SelectionColumn<QuestionColumns>[];
  searchLabel: string;
  selectedItemsInfo: string;
  topics: Nullable<ExtendedMLATopic[]>;
  setActiveTopics: (topics: ExtendedMLATopic[]) => void;
  displayTopics?: Nilable<ExtendedMLATopic[]>;
  setDisplayTopics: (topics: Nullable<ExtendedMLATopic[]>) => void;
}

const MLATopicQuizBuilder = ({
  allSelected,
  changeConditionState,
  changePresentationState,
  setActiveTopics,
  changeTopicState,
  loading,
  mlaColumns,
  mlaConditionColumns,
  mlaPatientPresentationColumns,
  overviewColumns,
  searchLabel,
  selectAll,
  selectionState,
  topics,
  displayTopics,
  setDisplayTopics,
}: MLATopicQuizBuilderProps): JSX.Element => {
  const isDemo = useDemo();

  const handleSelect = useCallback(
    (
      selectionState: MLASelectionState,
      all: boolean,
      itemId: number | null,
      subItemId: number | null,
      isCondition: boolean | null
    ) => {
      if (all) {
        return selectAll(selectionState);
      }
      if (subItemId && itemId && isCondition) {
        return changeConditionState(
          selectionState,
          Number(itemId),
          Number(subItemId)
        );
      }

      if (subItemId && itemId && !isCondition) {
        return changePresentationState(
          selectionState,
          Number(itemId),
          Number(subItemId)
        );
      }

      if (itemId && !subItemId) {
        return changeTopicState(selectionState, Number(itemId));
      }
    },
    [changeConditionState, changePresentationState, changeTopicState, selectAll]
  );

  const handleSelectAll = useCallback(
    (selectionState: MLASelectionState) => () => {
      handleSelect(selectionState, true, null, null, null);
    },
    [handleSelect]
  );

  const handleSelectRow = useCallback(
    (selectionState: MLASelectionState) => (itemId: number) => () => {
      handleSelect(selectionState, false, itemId, null, null);
    },
    [handleSelect]
  );

  const handleSelectCondition = useCallback(
    (selectionState: MLASelectionState) =>
      (itemId: number) =>
      (subItemId: number) => {
        handleSelect(
          selectionState,
          false,
          Number(itemId),
          Number(subItemId),
          true
        );
      },
    [handleSelect]
  );

  const handleSelectPresentation = useCallback(
    (selectionState: MLASelectionState) =>
      (itemId: number) =>
      (subItemId: number) => {
        handleSelect(
          selectionState,
          false,
          Number(itemId),
          Number(subItemId),
          false
        );
      },
    [handleSelect]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      const normalizedSearchString = searchString.toLowerCase().trim();
      if (topics && searchString) {
        const filtered = topics
          .filter(({ searchNames }) =>
            searchNames.toLowerCase().includes(normalizedSearchString)
          )
          .map(({ conditions, presentations, ...rest }) => ({
            ...rest,
            conditions: conditions
              ? conditions.filter(({ name }) =>
                  name.toLowerCase().includes(normalizedSearchString)
                )
              : undefined,
            presentations: presentations
              ? presentations.filter(({ name }) =>
                  name.toLowerCase().includes(normalizedSearchString)
                )
              : undefined,
          }));
        setDisplayTopics(filtered);

        return;
      }

      setDisplayTopics(topics || null);
    },
    [setDisplayTopics, topics]
  );

  useEffect(() => {
    if (topics) {
      setDisplayTopics(topics);
      setActiveTopics(topics);
    }
  }, [topics, setActiveTopics, setDisplayTopics]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const mlaLeftColumn = useMemo(
    () => mlaPatientPresentationColumns,
    [mlaPatientPresentationColumns]
  );

  const mlaRightColumn = useMemo(
    () => mlaConditionColumns,
    [mlaConditionColumns]
  );

  return (
    <>
      <ExerciePreBuildHeader mainHeader="MLA Content Map" />
      <MLAExerciseBuilder
        allSelected={allSelected}
        categoryOptions={[]}
        columns={overviewColumns}
        data={displayTopics || []}
        globalLock={isDemo}
        headerLock={isDemo}
        loading={loading}
        mlaColumns={mlaColumns}
        mlaConditionColumns={mlaRightColumn}
        mlaPatientPresentationColumns={mlaLeftColumn}
        onSearch={handleSearch}
        onSelectAll={handleSelectAll(selectionState)}
        onSelectCondition={handleSelectCondition(selectionState)}
        onSelectPresentation={handleSelectPresentation(selectionState)}
        onSelectRow={handleSelectRow(selectionState)}
        searchLabel={searchLabel}
        selectionState={selectionState}
        title="Clinical"
        viewOptions={BUILDER_VIEWS}
      />
    </>
  );
};

export default MLATopicQuizBuilder;
