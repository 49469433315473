const calculateRemainingTestTime = (
  endDate: Date | string | number | undefined
): number => {
  if (!endDate) return 0;

  const timeNow = Date.now();
  const timeEnd = new Date(endDate).getTime();

  return timeEnd - timeNow < 0 ? 0 : timeEnd - timeNow;
};

export default calculateRemainingTestTime;
