import React from 'react';
import { mdiStickerAlertOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const StickerAlertOutlineIcon = (props: IconProps) => (
  <Icon path={mdiStickerAlertOutline} {...props} />
);

export default StickerAlertOutlineIcon;
