import React, { useCallback, useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import isNil from 'lodash/isNil';
import { useSearchBox } from 'react-instantsearch';
import { EProductType, IOsceStation } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialItem,
  OsceKnowledgeLibraryState,
} from 'components/LearningMaterials/types';
import { useOsceKnowledge } from 'components/LearningMaterials/hooks';
import { Nullable, ToggleEvent } from 'types';
import { usePrevious } from 'hooks/usePrevious';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { StationHit, useSearchResults } from 'components/Search';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const { knowledgeLibrary } = paths;

export const useOsceKnowledgeLibraryData = () => {
  return useOutletContext<OsceKnowledgeLibraryState>();
};

const setSearchStations = (
  hits: StationHit[],
  stations: Nullable<IOsceStation[]>
): Nullable<LearningMaterialItem[]> => {
  if (!stations) {
    return null;
  }

  const osceStationIds = new Set<number>();
  const stationOrder: number[] = [];

  hits.forEach(({ osceStationId }) => {
    osceStationIds.add(Number(osceStationId));
    stationOrder.push(Number(osceStationId));
  });

  return stations
    .filter(({ id }) => osceStationIds.has(Number(id)))
    .sort((a, b) => {
      const { id: aId } = a as IOsceStation;
      const { id: bId } = b as IOsceStation;

      return (
        stationOrder.indexOf(Number(aId)) - stationOrder.indexOf(Number(bId))
      );
    });
};

const useSearchStations = (stations: Nullable<IOsceStation[]>) => {
  const results = useSearchResults<StationHit[]>();

  return results ? setSearchStations(results, stations) : null;
};

const OsceKnowledgeLibrary = (): JSX.Element => {
  const { loading, stations, stationsMap, categories, allStations } =
    useOsceKnowledge();
  const { refine, clear, query } = useSearchBox();
  const navigate = useNavigate();
  const { sectionId: categoryId, entitlementId } =
    useParams<KnowledgeLibraryRouterParams>();
  const [activeCategory, setActiveCategory] = useState(
    (entitlementId
      ? Number(entitlementId)
      : categories[0]?.value) as EProductType
  );
  const { chapterId } = useParams<KnowledgeLibraryRouterParams>();

  const previousCategory = usePrevious(activeCategory);
  const previousTopicId = usePrevious(categoryId);
  const prevChapterId = usePrevious(chapterId);
  const [displayStations, setDisplayStations] =
    useState<Nullable<IOsceStation[]>>(null);

  const searchItems = useSearchStations(allStations);

  const resetSearch =
    (Boolean(displayStations) && previousCategory !== activeCategory) ||
    previousTopicId !== categoryId ||
    prevChapterId !== chapterId;

  useEffect(() => {
    if (resetSearch || !displayStations) {
      clear();
      setDisplayStations(stationsMap ? stationsMap[activeCategory] : null);
    }
  }, [activeCategory, clear, displayStations, resetSearch, stationsMap]);

  useEffect(() => {
    if (activeCategory) {
      setDisplayStations(stationsMap ? stationsMap[activeCategory] : null);
    }
  }, [activeCategory, stationsMap]);

  useEffect(() => {
    if (!entitlementId) {
      const [category] = categories || [];
      const { value } = category || {};

      if (!isNil(value)) {
        navigate(`${knowledgeLibrary.root}/section/${value}/${value}`);
      }
    } else {
      setActiveCategory(Number(entitlementId));
    }
  }, [activeCategory, categories, entitlementId, navigate]);

  const handleSearch = useCallback(
    (searchString: string) => {
      refine(searchString);
    },
    [refine]
  );

  const handleActiveCategory = useCallback(
    (_: ToggleEvent, type: EProductType) => {
      navigate(`${knowledgeLibrary.root}/section/${type}/${type}`);
    },
    [navigate]
  );

  const activeCategoryLabel =
    categories.find(({ value }) => value === activeCategory)?.label || '';

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!displayStations) {
    return <ErrorMessage />;
  }

  return (
    <Outlet
      context={{
        activeCategory,
        activeCategoryLabel,
        categories,
        stations: displayStations,
        searchItems,
        allStations: stations,
        loading,
        resetSearch,
        searchTerm: query,
        search: handleSearch,
        searchBy: 'stations',
        switchCategory: handleActiveCategory,
      }}
    />
  );
};

export default OsceKnowledgeLibrary;
