import { useMemo } from 'react';
import { IOsceStation } from '@quesmed/types-rn/models';

import { OsceKnowledgeLibraryData } from 'components/LearningMaterials/types';
import { Nullable } from 'types';
import { getStationsLearningData } from '../utils/index';
import { useOsceKnowledgeVideoLibraryQuery } from './useOsceKnowledgeVideoLibraryQuery';

const filterStationsByVideo = (stations?: Nullable<IOsceStation[]>) => {
  if (!stations) {
    return null;
  }

  return stations.filter(({ videos }) => videos?.length);
};

export const useOsceVideoLibrary = (
  isPaces = false
): OsceKnowledgeLibraryData => {
  const { allStations, loading } = useOsceKnowledgeVideoLibraryQuery(true);

  const filteredStations = useMemo(
    () => filterStationsByVideo(allStations),
    [allStations]
  );

  const [stationsMap, stations, categories, allVideos] = useMemo(
    () => getStationsLearningData(filteredStations, true, isPaces),
    [filteredStations, isPaces]
  );

  return { stations, loading, stationsMap, categories, allStations, allVideos };
};
