import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITopic } from '@quesmed/types-rn/models';

import {
  ExerciseBuilder,
  KeyField,
  SelectionColumn,
} from 'components/ExerciseBuilder';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import { BuilderViewMode, ItemStatusLabel, Nullable, PathName } from 'types';
import { BUILDER_VIEWS } from 'constants/general';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import { READING_STATUS_LABELS } from 'components/GridCell';
import KnowledgeLibraryLabel from '../../../components/LearningMaterials/components/LibraryLabel';
import { useQbankKnowledgeLibraryData } from './QbankKnowledgeLibrary';
import { paths } from 'Router';
import {
  KnowledgeLibraryColumns,
  LearningMaterialsData,
  PanelItems,
} from 'components/LearningMaterials';

const { knowledgeLibrary } = paths;

const progressFormatter = ({
  urgentConcepts,
  revisingConcepts,
  completedConcepts,
  unreadConcepts,
  concepts = [],
  name,
}: ITopic): ProgressLineChartProps => {
  const urgent = urgentConcepts || 0;
  const revising = revisingConcepts || 0;
  const completed = completedConcepts || 0;
  const unread =
    unreadConcepts || concepts.length - urgent - revising - completed || 0;

  return {
    title: name,
    data: [
      {
        type: ItemStatusLabel.ToReview,
        value: urgent,
        label: ItemStatusLabel.Urgent,
      },
      {
        type: ItemStatusLabel.Revising,
        value: revising,
        label: ItemStatusLabel.Revising,
      },
      {
        type: ItemStatusLabel.Confident,
        value: completed,
        label: ItemStatusLabel.Complete,
      },
      {
        type: ItemStatusLabel.NotAnswered,
        value: unread,
        label: ItemStatusLabel.Unread,
      },
    ],
    total: urgent + revising + completed + unread,
  };
};

const detailsColumns: SelectionColumn<KnowledgeLibraryColumns>[] = [
  {
    align: 'left',
    formatter: KnowledgeLibraryLabel,
    key: KeyField.Label,
    label: 'Topics',
    type: 'label',
  },
  {
    align: 'left',
    key: 'urgentConcepts',
    ...READING_STATUS_LABELS[0],
    type: 'default',
  },
  {
    align: 'left',
    key: 'revisingConcepts',
    ...READING_STATUS_LABELS[1],
    type: 'default',
  },
  {
    align: 'left',
    key: 'completedConcepts',
    ...READING_STATUS_LABELS[2],
    type: 'default',
  },
  {
    align: 'left',
    key: 'unreadConcepts',
    ...READING_STATUS_LABELS[3],
    type: 'default',
  },
];

const overviewColumns: SelectionColumn<KnowledgeLibraryColumns>[] = [
  {
    align: 'left',
    formatter: KnowledgeLibraryLabel,
    key: KeyField.Label,
    label: 'Topics',
    type: 'label',
  },
  {
    align: 'left',
    key: KeyField.Progress,
    label: 'Progress',
    type: 'bar',
    progressLabels: READING_STATUS_LABELS,
    progressFormatter,
  },
];

const QbankKnowledgeLibraryHome = (): JSX.Element => {
  const {
    activeView,
    activeCategory,
    activeCategoryLabel,
    categories,
    loading,
    topics,
    resetSearch,
    search,
    searchItems,
    searchBy,
    searchTerm,
    switchCategory,
    switchView,
  } = useQbankKnowledgeLibraryData();
  const navigate = useNavigate();

  const handleSelectTopic = (topicId: Nullable<number>) => () => {
    if (topicId) {
      const entitlementId = topics.find(
        ({ id }) => Number(id) === Number(topicId)
      )?.entitlement.id;
      navigate(`${knowledgeLibrary.root}/section/${entitlementId}/${topicId}`);
    }
  };

  const columns = useMemo(
    () =>
      activeView === BuilderViewMode.Details ? detailsColumns : overviewColumns,
    [activeView]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchItems}
          searchLabel="Search concepts by name"
          searchTerm={searchTerm}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader
            mainHeader={PathName.KnowledgeLibrary}
            notesButton
          />
          <ExerciseBuilder
            activeCategory={activeCategory}
            categoryOptions={categories}
            columns={columns}
            data={topics || []}
            loading={loading}
            onSearch={search}
            onSelectRow={handleSelectTopic}
            onToggleCategory={switchCategory}
            onToggleView={switchView}
            resetSearch={resetSearch}
            searchItems={searchItems}
            searchLabel="Search..."
            searchTerm={searchTerm}
            searchTitle="Concept names"
            selectOnRowClick
            selectedView={activeView}
            title={activeCategoryLabel}
            viewOptions={BUILDER_VIEWS}
            withOffset
          />
        </PreLaunchLayout>
      }
    />
  );
};

export default QbankKnowledgeLibraryHome;
