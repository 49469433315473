import { TableData } from 'types';
import { SelectionColumn } from './types';

const CHEVRON_GRID_COLUMN_SIZE = '56px';
const INPUT_GRID_COLUMN_SIZE = '86px';
const INPUT_WITH_CHEVRON_GRID_COLUMN_SIZE = '30px';
const LABEL_GRID_COLUMN_SIZE = '3fr';
const BAR_GRID_COLUMN_SIZE = '4fr';
const DEFAULT_GRID_COLUMN_SIZE = '1fr';

function setSelectionColumnTemplate<T extends TableData>(
  columns: SelectionColumn<T>[]
): string {
  const columnTypes = new Set(columns.map(({ type }) => type));
  const defaultColumnsCount = columns.filter(
    ({ type }) => type === 'default'
  ).length;
  const chevronColumn = columnTypes.has('chevron')
    ? CHEVRON_GRID_COLUMN_SIZE
    : '';

  const inputColumn = columnTypes.has('input')
    ? chevronColumn
      ? INPUT_WITH_CHEVRON_GRID_COLUMN_SIZE
      : INPUT_GRID_COLUMN_SIZE
    : '';

  const labelColumn = columnTypes.has('label') ? LABEL_GRID_COLUMN_SIZE : '';
  const progressColumn = columnTypes.has('bar') ? BAR_GRID_COLUMN_SIZE : '';
  const defaultCoulms = defaultColumnsCount
    ? `repeat(${defaultColumnsCount}, ${DEFAULT_GRID_COLUMN_SIZE})`
    : '';

  const gridTemplateColumns = `${chevronColumn} ${inputColumn} ${labelColumn} ${progressColumn} ${defaultCoulms}`;

  return gridTemplateColumns;
}

export default setSelectionColumnTemplate;
