import { useMutation } from '@apollo/client';
import { IMarksheetMark } from '@quesmed/types-rn/models';
import {
  IToggleFlaggedMarkData,
  IToggleFlaggedMarkVar,
  optimisticToggleFlaggedQuestion,
  TOGGLE_FLAGGED_MARK,
  updateCacheToggleFlaggedQuestion,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback } from 'react';

export const useFlaggedQuestion = (
  mark: IMarksheetMark,
  marksheetId: number
) => {
  const { id: markId } = mark;
  const [toggleFlagQuestion, { loading }] = useMutation<
    IToggleFlaggedMarkData,
    IToggleFlaggedMarkVar
  >(TOGGLE_FLAGGED_MARK, {
    update: updateCacheToggleFlaggedQuestion,
  });

  const flagQuestion = useCallback(
    async () =>
      toggleFlagQuestion({
        variables: {
          marksheetId,
          markId: markId,
          isFlagged: true,
        },
        optimisticResponse: optimisticToggleFlaggedQuestion(mark, true),
      }),
    [toggleFlagQuestion, marksheetId, markId, mark]
  );

  const unflagQuestion = useCallback(
    async () =>
      toggleFlagQuestion({
        variables: {
          marksheetId,
          markId: markId,
          isFlagged: false,
        },
        optimisticResponse: optimisticToggleFlaggedQuestion(mark, false),
      }),
    [mark, markId, marksheetId, toggleFlagQuestion]
  );

  return {
    flagQuestion,
    loading,
    unflagQuestion,
  };
};

export default useFlaggedQuestion;
