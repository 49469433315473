import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  ISaveTodosData,
  ISaveTodosVar,
  ITodoInput,
  optimisticUpdatedSaveTodo,
  SAVE_TODO,
  saveTodosCache,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { ITodo } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

const useSaveTodos = ({
  todo,
  cardIndex,
  baseUrl,
  onCompleted,
}: {
  todo: ITodo;
  cardIndex: number;
  baseUrl: string;
  onCompleted: () => void;
}) => {
  const navigate = useNavigate();
  const [save, { loading }] = useMutation<ISaveTodosData, ISaveTodosVar>(
    SAVE_TODO,
    {
      update: saveTodosCache,
      onCompleted: data => {
        if (data) {
          const { marks } = todo;
          if (cardIndex < marks.length - 1) {
            const newIndex = cardIndex + 1;
            const { cardId: newCardId, id: newMarkId } = marks[newIndex];
            onCompleted();
            navigate(`${baseUrl}/${newMarkId}/${newCardId}/${newIndex + 1}`);
          }
        }
      },
    }
  );

  const saveTodos = useCallback(
    (todoInput: ITodoInput) =>
      save({
        variables: {
          todos: [todoInput],
        },
        optimisticResponse: optimisticUpdatedSaveTodo(
          todo,
          cardIndex,
          todoInput
        ),
      }),

    [cardIndex, save, todo]
  );

  return { saveTodos, loading };
};

export default useSaveTodos;
