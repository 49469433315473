import React from 'react';
import { mdiDotsVertical } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const DotsVerticalIcon = (props: IconProps) => (
  <Icon path={mdiDotsVertical} {...props} />
);

export default DotsVerticalIcon;
