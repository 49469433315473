import {
  IEndMarksheetData,
  optimisticEndMarksheet,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IMarksheet } from '@quesmed/types-rn/models';
import { useCallback } from 'react';

import useEndMarksheetMutation from './useEndMarksheetMutation';

type OnCompleteCallback = (data: IEndMarksheetData) => void;

const useEndMarksheet = (
  marksheet: IMarksheet,
  onCompleteCallback?: OnCompleteCallback
) => {
  const { id } = marksheet;

  const [end, { loading: endLoading }] = useEndMarksheetMutation({
    onCompleted: data => {
      if (onCompleteCallback) {
        onCompleteCallback(data);
      }
    },
  });

  const endMarksheet = useCallback(async () => {
    if (id) {
      return end({
        variables: {
          marksheetId: Number(id),
        },
        optimisticResponse: optimisticEndMarksheet(marksheet),
      });
    }
  }, [end, marksheet, id]);

  return { endMarksheet, endLoading };
};

export default useEndMarksheet;
