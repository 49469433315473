import React, { useCallback, useEffect, useState } from 'react';

import { formatDuration, parseDate } from 'utils';
import { ExerciseTimer } from 'components/ExerciseTimer';
import { MockTestsTimer } from 'pages/MockTests/MockTestLayout/MockTestsTimer';

const ABOUT_TO_END_TIME = 3 * 60 * 1000; // 3 minutes;

interface QuestionsTimerProps {
  endedAt: number | Date;
  setTimeIsUp: (value: boolean) => void;
  mockTest?: boolean;
}

const QuestionsTimer = ({
  endedAt,
  setTimeIsUp,
  mockTest,
  ...props
}: QuestionsTimerProps): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [aboutToEnd, setAboutToEnd] = useState(false);

  const updateTimeLeft = useCallback(() => {
    const end = new Date(parseDate(endedAt));
    const now = new Date();
    const difference = +end - +now;
    if (difference <= 0) {
      setTimeLeft(0);
      setTimeIsUp(true);
      setAboutToEnd(false);
    } else {
      if (difference <= ABOUT_TO_END_TIME && !aboutToEnd) {
        setAboutToEnd(true);
      }
      setTimeLeft(difference);
    }
  }, [endedAt, setTimeIsUp, aboutToEnd]);

  useEffect(() => {
    let timer: null | number = 0;
    if (timeLeft === 0) {
      updateTimeLeft();
    }

    timer = window.setInterval(updateTimeLeft, 1000);

    return () => {
      if (timer != null) {
        clearInterval(timer);
      }
    };
  }, [timeLeft, updateTimeLeft]);

  return mockTest ? (
    <MockTestsTimer timeLeft={formatDuration(timeLeft)} />
  ) : (
    <ExerciseTimer timeLeft={formatDuration(timeLeft)} {...props} />
  );
};

export default QuestionsTimer;
