import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EQuestionType, IMarksheet } from '@quesmed/types-rn/models';
import { ISaveMarksheetInput } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';

import { calcTimeTaken, values } from 'utils';
import { useEndMockTest, useFlaggedQuestion } from './hooks';
import { useSaveMarksheets } from 'hooks';
import { useChangeQuestion } from 'pages/Questions/hooks';
import { ChangeQuestionArgs } from 'pages/Questions/hooks/useChangeQuestion';
import { KeyboardKey, MockTestRouter } from 'types';
import { QuestionsTimer } from 'components/QuestionsTimer';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FlagOutlineIcon,
  FlagRemoveIcon,
} from 'components/Icons';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { Button } from 'components/Button';
import { useMockTestStateSetters } from './mockTestsState';
import { IconButton } from 'components/IconButton';
import { MockTestNavigatorModal } from './MockTestNavigatorModal';
import { Lightbox } from 'components/Lightbox';
import { MockTestQuizLayout } from './MockTestLayout';

interface MockTestsQuizProps {
  baseUrl: string;
  markId: number;
  marksheet: IMarksheet;
  questionId: number;
  questionIndex: number;
  questionNumber: number;
}

const MockTestsQuiz = ({
  baseUrl,
  markId: currentMarkId,
  marksheet,
  questionId,
  questionIndex,
  questionNumber,
}: MockTestsQuizProps): JSX.Element => {
  const { mockTestId = '' } = useParams<MockTestRouter>();
  const navigate = useNavigate();
  const { endedAt, id: marksheetId, marks, completed } = marksheet;
  const currentMark = marks[questionIndex];
  const {
    timeTaken: prevTimeTaken,
    mark,
    question,
    questionChoiceId,
    flagged,
  } = currentMark;
  const { choices, typeId, pictures } = question;

  const [startTime, setStartTime] = useState<number>(0);
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [selectedChoiceId, setSelectedId] = useState<number | undefined>();
  const [timeIsUp, setTimeIsUp] = useState(false);
  const { endMockTest } = useEndMockTest(marksheet, mockTestId);
  const { saveMarksheets } = useSaveMarksheets(() => setUserAnswer(''));
  const [showNavigator, setShowNavigator] = useState(false);
  const changeQuestion = useChangeQuestion(Number(marksheetId));
  const isAllAnswered = marks.every(({ isAnswered }) => isAnswered);
  const reviewMode = completed || timeIsUp;
  const lastQuestion = questionNumber === marks.length;
  const firstQuestion = questionNumber === 1;
  const noAnswer = Boolean(selectedChoiceId && userAnswer);

  const { flagQuestion, unflagQuestion } = useFlaggedQuestion(
    currentMark,
    Number(marksheetId)
  );

  const {
    setTimer,
    setShowTools,
    setQuestionCount,
    resetState,
    setTestTools,
    setRightFooterControls,
  } = useMockTestStateSetters();

  const timer = useMemo(() => {
    if (!completed) {
      return (
        <QuestionsTimer endedAt={endedAt} mockTest setTimeIsUp={setTimeIsUp} />
      );
    }
  }, [endedAt, completed]);

  const tools = useMemo(
    () => [
      <IconButton
        icon={flagged ? <FlagRemoveIcon /> : <FlagOutlineIcon />}
        key={1}
        label={flagged ? 'Remove flag' : 'Flag for later'}
        onClick={flagged ? unflagQuestion : flagQuestion}
        size="small"
        white
      />,
    ],
    [flagQuestion, flagged, unflagQuestion]
  );

  useEffect(() => {
    resetState();
    setShowTools(true);
  }, [resetState, setShowTools]);

  useEffect(() => {
    setQuestionCount(`${questionNumber}/${marks.length}`);
  }, [marks.length, questionNumber, setQuestionCount]);

  useEffect(() => {
    setTimer(timer);
  }, [setTimer, timer]);

  useEffect(() => {
    setTestTools(tools);
  }, [setTestTools, tools]);

  usePrealoadImages(marksheet);

  useEffect(() => {
    setStartTime(new Date().getTime());
    setUserAnswer('');
    setSelectedId(undefined);

    return () => {
      setStartTime(0);
    };
  }, [currentMarkId, marksheetId, questionId]);

  useEffect(() => {
    if (questionChoiceId) {
      setSelectedId(questionChoiceId || undefined);
    }
  }, [choices, questionChoiceId]);

  useEffect(() => {
    if (timeIsUp && !completed) {
      endMockTest();
    }
  }, [endMockTest, completed, timeIsUp]);

  const handleSaveAnswer = useCallback(() => {
    if (mark === userAnswer) {
      return;
    }

    if (userAnswer) {
      const marksheetInput: ISaveMarksheetInput = {
        markId: Number(currentMark.id),
        marksheetId: Number(marksheetId),
        mark: userAnswer,
        timeTaken: calcTimeTaken(startTime, prevTimeTaken),
      };

      if (
        typeId === EQuestionType.SINGLE_BEST_ANSWER &&
        selectedChoiceId !== undefined
      ) {
        marksheetInput.choiceId = Number(selectedChoiceId);
      }

      return saveMarksheets(marksheetInput, marksheet, questionIndex);
    }
  }, [
    currentMark.id,
    mark,
    userAnswer,
    marksheetId,
    startTime,
    prevTimeTaken,
    typeId,
    selectedChoiceId,
    saveMarksheets,
    marksheet,
    questionIndex,
  ]);

  const showQuizAnswers = useCallback(async () => {
    if (mark !== userAnswer) {
      await handleSaveAnswer();
    }
    endMockTest();
  }, [endMockTest, handleSaveAnswer, mark, userAnswer]);

  const navigateToIndex = useCallback(
    async (questionIndex: number) => {
      if (questionIndex < 0 || questionIndex > marks.length - 1) {
        return;
      }

      const {
        id: newMarkId,
        question: { id: newQuestionId },
      } = marks[questionIndex];

      const payload: ChangeQuestionArgs = {
        markId: Number(currentMark.id),
        newMarkId: Number(newQuestionId),
        timeTaken: calcTimeTaken(startTime, prevTimeTaken),
      };
      if (!completed) {
        await handleSaveAnswer();
        changeQuestion(payload);
      }

      navigate(`${baseUrl}/${newMarkId}/${newQuestionId}`);
    },
    [
      baseUrl,
      changeQuestion,
      completed,
      currentMark.id,
      handleSaveAnswer,
      navigate,
      marks,
      prevTimeTaken,
      startTime,
    ]
  );

  const handleNavigate = useCallback(
    (newQuestionIndex: number, isNext: boolean) => {
      const shouldSave = isNext
        ? newQuestionIndex > marks.length - 1
        : newQuestionIndex < 0;

      if (shouldSave) {
        if (currentMark.question.typeId === EQuestionType.PRESCRIPTION_ANSWER) {
          if (userAnswer) {
            const parsedAnswer = JSON.parse(userAnswer);

            const fieldAnswers = values(parsedAnswer).filter(value => value);

            if (fieldAnswers.length === 6) {
              return handleSaveAnswer();
            }
          }
        } else {
          return handleSaveAnswer();
        }
      }

      navigateToIndex(newQuestionIndex);
    },
    [
      currentMark.question.typeId,
      handleSaveAnswer,
      marks.length,
      navigateToIndex,
      userAnswer,
    ]
  );

  const handleNavigatePrevious = useCallback(() => {
    handleNavigate(questionIndex - 1, false);
  }, [handleNavigate, questionIndex]);

  const handleNavigateNext = useCallback(() => {
    handleNavigate(questionIndex + 1, true);
  }, [handleNavigate, questionIndex]);

  const getButtonHandler = useCallback(
    (isNext: boolean) => {
      if (isAllAnswered && !completed) {
        return showQuizAnswers;
      }

      return isNext ? handleNavigateNext : handleNavigatePrevious;
    },
    [
      completed,
      handleNavigateNext,
      handleNavigatePrevious,
      isAllAnswered,
      showQuizAnswers,
    ]
  );

  const getButtonLabel = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        return lastQuestion && !reviewMode ? (
          'Save'
        ) : (
          <>
            Next <ArrowRightIcon />
          </>
        );
      }

      return (
        <>
          <ArrowLeftIcon />
          Previous
        </>
      );
    },
    [lastQuestion, reviewMode]
  );

  useHotkeys(KeyboardKey.ArrowRight, handleNavigateNext, [handleNavigateNext]);

  useHotkeys(KeyboardKey.ArrowLeft, handleNavigatePrevious, [
    handleNavigatePrevious,
  ]);

  useHotkeys(
    KeyboardKey.Space,
    e => {
      e.preventDefault();

      handleNavigateNext();
    },
    [handleNavigateNext]
  );

  const rightFooterControls = useMemo(() => {
    const buttons = [];
    if (!firstQuestion) {
      buttons.push(
        <Button
          contrast
          key={1}
          onClick={getButtonHandler(false)}
          variant="text"
        >
          {getButtonLabel(false)}
        </Button>
      );
    }

    buttons.push(
      <Button
        contrast
        key={2}
        onClick={() => setShowNavigator(prev => !prev)}
        variant="text"
      >
        Navigator
      </Button>,
      <Button
        contrast
        disabled={lastQuestion && !noAnswer}
        key={3}
        onClick={getButtonHandler(true)}
        variant="text"
      >
        {getButtonLabel(true)}
      </Button>
    );

    return buttons;
  }, [lastQuestion, noAnswer, firstQuestion, getButtonHandler, getButtonLabel]);

  useEffect(() => {
    setRightFooterControls(rightFooterControls);
  }, [setRightFooterControls, rightFooterControls]);

  return (
    <>
      <Lightbox pictures={pictures} showCaptions={reviewMode} />
      <MockTestQuizLayout
        key={question.id}
        marksheet={marksheet}
        question={question}
        selectedChoiceId={selectedChoiceId}
        setSelectedId={setSelectedId}
        setUserAnswer={setUserAnswer}
        timeIsUp={timeIsUp}
      />
      {showNavigator && (
        <MockTestNavigatorModal
          isReview={reviewMode}
          marks={marks}
          onClose={() => setShowNavigator(false)}
          open={showNavigator}
          title="Navigator"
        />
      )}
    </>
  );
};

export default MockTestsQuiz;
