import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { CheckboxState, InputOnChangeHandler, TableData } from 'types';
import { Checkbox } from 'components/Checkbox';
import { KeyField, SelectionColumn } from './types';
import { GridRow } from 'components/GridRow';
import { CellLabel, GridCell, ProgressCellLabels } from 'components/GridCell';
import setSelectionColumnTemplate from './setSelectionColumnTemplate';

const Label = styled(Box)(({ theme: { palette, spacing, typography } }) => ({
  color: palette.text.primary,
  ...typography.body1Medium,
  '&::first-letter': {
    textTransform: 'uppercase',
  },

  '&.text-only': {
    paddingLeft: spacing(8),
  },
}));

function getHeaders<T extends TableData>(
  currentColumn: SelectionColumn<T>,
  onSelectAll?: () => void,
  allSelected?: CheckboxState,
  nested?: boolean,
  headerLock?: boolean,
  nestedLabel = 'Items'
) {
  const { key, label, labelStatus, labelInfo, progressLabels } = currentColumn;

  const handleChange: InputOnChangeHandler = () => {
    if (onSelectAll) {
      onSelectAll();
    }
  };

  switch (key) {
    case KeyField.Checkbox:
      return !nested && !headerLock ? (
        <Checkbox
          checkedState={allSelected}
          controlSx={{ marginRight: 0 }}
          onChange={handleChange}
        />
      ) : (
        <Label className="text-only">{nestedLabel}</Label>
      );
    case KeyField.Label:
    case KeyField.Topic:
    case KeyField.Concept:
    case KeyField.Added:
      return <Label>{nested ? nestedLabel : label}</Label>;
    case KeyField.Chevron:
      return null;
    case KeyField.Progress:
      return !nested ? (
        <ProgressCellLabels labels={progressLabels} nowrap />
      ) : null;
    case KeyField.Radio:
      return null;
    default:
      return !nested ? (
        <CellLabel
          label={label}
          labelInfo={labelInfo}
          labelStatus={labelStatus}
        />
      ) : null;
  }
}

interface TableHeadsProps<T extends TableData> {
  allSelected?: CheckboxState;
  columns: SelectionColumn<T>[];
  headerLock?: boolean;
  onSelectAll?: () => void;
  nested?: boolean;
  nestedLabel?: keyof T | string;
  withOffset?: boolean;
}

function SelectionTableHead<T extends TableData>({
  allSelected,
  columns,
  headerLock,
  onSelectAll,
  nested = false,
  nestedLabel,
  withOffset,
}: TableHeadsProps<T>): JSX.Element {
  return (
    <GridRow
      className={clsx({ 'with-offset': withOffset, sticky: true })}
      columns={columns}
      nested={nested}
      setColumnTemplate={setSelectionColumnTemplate}
    >
      {columns.map(column => {
        const { key, align, type } = column;

        return (
          <GridCell align={align} key={key as string} type={type}>
            {getHeaders(
              column,
              onSelectAll,
              allSelected,
              nested,
              headerLock,
              nestedLabel as string
            )}
          </GridCell>
        );
      })}
    </GridRow>
  );
}

export default SelectionTableHead;
