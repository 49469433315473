import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Fade from '@mui/material/Fade';
import { EMarksheetState, IMarksheet } from '@quesmed/types-rn/models';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { calcAllMLAQuestions, getMLATopics } from 'utils';
import {
  CheckboxState,
  ExerciseType,
  ExtendedMLATopic,
  Nullable,
  UIColumn,
} from 'types';
import {
  useIsMobile,
  useLeaveExerciseState,
  useMarksheetLeavingBlockade,
  useMLASelectionState,
  useMLATopicQuery,
} from 'hooks';
import {
  BottomSelectionBar,
  setBottomBarOffset,
} from 'components/BottomSelectionBar';
import {
  KeyField,
  QUESTIONS_PROGRESS_LABELS,
  SelectionColumn,
} from 'components/ExerciseBuilder';
import { TRANSITION_TIMEOUT } from 'config/constants';
import { paths } from 'Router';
import { useDemo } from 'Auth';
import { useChangeMarksheetState } from '../Questions/hooks';
import QuestionsQuizLeaveModal from '../Questions/QuestionsQuizLeaveModal';
import {
  checkBoxLabelFormatter,
  notAnsweredFormatter,
  progressFormatter,
  scoreFormatter,
} from './mlaContentMapColumnsFormatters';
import MLATopicQuizBuilder from 'components/ExerciseBuilder/MLATopicQuizBuilder';
import MLAPreBuildModal from './MLAPreBuildModal';
import useBuildMLAQuestions from './hooks/useBuildMLAQuestions';

const { dashboard } = paths;

interface RouterState {
  marksheetId?: number;
  isMarksheetOutdated?: boolean;
}

interface QuestionsQuizBuilderProps {
  marksheet?: IMarksheet;
}

export type QuestionColumns = ExtendedMLATopic & UIColumn;

const MLAQuestionsQuizBuilder = ({
  marksheet,
}: QuestionsQuizBuilderProps): JSX.Element => {
  const { id, state, builderConfig } = marksheet || {};
  const marksheetId = id ? Number(id) : undefined;
  const { isMobile } = useIsMobile();
  const { topics, topicMap, loading } = useMLATopicQuery();
  const [displayTopics, setDisplayTopics] =
    useState<Nullable<ExtendedMLATopic[]>>(null);

  const { state: routerState } = useLocation<RouterState>();
  const { marksheetId: isMarksheetOutdated } = routerState || {};
  const { open } = useLeaveExerciseState();
  const [preBuildSelection, setPrebuildSelection] =
    useState<[number[], number[]]>();
  const [showPrebuildModal, setShowPrebuildModal] = useState(false);
  const [showSelectionBar, setShowSelectionBar] = useState(false);
  const isDemo = useDemo();

  const [activeTopics, setActiveTopics] =
    useState<Nullable<ExtendedMLATopic[]>>(null);
  const [selectionCount, setSelectionCount] = useState<
    [number, number, number]
  >([0, 0, 0]);
  const { changeMarksheetState, loading: stateLoading } =
    useChangeMarksheetState(marksheetId);

  const goToQuizBuilder = useCallback(() => {
    changeMarksheetState(EMarksheetState.QUIZ_BUILDER);
  }, [changeMarksheetState]);

  const { preBuildLoading } = useBuildMLAQuestions({
    onPrebuildComplete: goToQuizBuilder,
    marksheetId,
  });

  useMarksheetLeavingBlockade({
    marksheetId: id,
    shouldBlock: showSelectionBar && Boolean(isMarksheetOutdated),
    ignoreBlockade: !showSelectionBar || !isMarksheetOutdated,
  });

  const navigate = useNavigate();
  const selection = useMLASelectionState({
    topics: activeTopics,
    displayTopics,
  });

  const { allSelected, deselectAll, selectionState } = selection;

  const [questionCount] = selectionCount;

  const handleUnselectAll = () => {
    if (allSelected !== CheckboxState.UNCHECKED) {
      deselectAll(selectionState);
    }
  };

  const handleShowPrebuildModal = () => {
    setShowPrebuildModal(true);
  };

  const handleClosePrebuildModal = () => setShowPrebuildModal(false);

  const handleLeave = () => {
    navigate(dashboard);
  };

  useEffect(() => {
    setShowPrebuildModal(state === EMarksheetState.QUIZ_BUILDER);
  }, [state]);

  useEffect(() => {
    setPrebuildSelection(getMLATopics(selectionState));
  }, [selectionState]);

  useEffect(() => {
    let timeout = 0;
    const count = calcAllMLAQuestions(selectionState, topicMap);

    if (count[0]) {
      setShowSelectionBar(true);
      setSelectionCount(count);
    } else {
      setShowSelectionBar(false);
      timeout = window.setTimeout(() => {
        setSelectionCount(count);
      }, TRANSITION_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [selectionState, topicMap]);

  const [
    detailsColumns,
    overviewColumns,
    mlaPatientPresentationColumns,
    mlaConditionColumns,
  ]: SelectionColumn<QuestionColumns>[][] = useMemo(
    () => [
      [
        { align: 'center', key: KeyField.Chevron, type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'area of clinical practice',
        },
        {
          align: 'left',
          key: 'correctQuestions',
          ...QUESTIONS_PROGRESS_LABELS[0],
          type: 'default',
        },
        {
          align: 'left',
          key: 'incorrectQuestions',
          ...QUESTIONS_PROGRESS_LABELS[1],
          type: 'default',
        },
        {
          align: 'left',
          formatter: notAnsweredFormatter,
          key: KeyField.NotAnswered,
          ...QUESTIONS_PROGRESS_LABELS[2],
          type: 'default',
        },
        {
          align: 'left',
          scoreFormatter: scoreFormatter,
          key: KeyField.Score,
          label: 'Score',
          type: 'default',
        },
      ],
      [
        { align: 'center', key: 'chevron', type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'area of clinical practice',
        },
        {
          align: 'left',
          key: KeyField.Progress,
          label: 'Progress',
          type: 'bar',
          progressLabels: QUESTIONS_PROGRESS_LABELS,
          progressFormatter,
        },
      ],
      [
        { align: 'center', key: 'chevron', type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState, true),
          key: KeyField.LabelIcon,
          type: 'label',
          label: 'topics',
        },
        { align: 'right', key: 'default', type: 'default' },
      ],
      [
        { align: 'center', key: 'chevron', type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState, true),
          key: KeyField.LabelIcon,
          type: 'label',
          label: 'topics',
        },
        {
          align: 'right',
          formatter: mlaContentMap => {
            return Boolean(mlaContentMap.concepts?.length);
          },
          key: KeyField.KnowledgeIcon,
          type: 'default',
        },
      ],
    ],
    [selectionState]
  );

  const selectedItemsInfo = isDemo
    ? 'Start exercise with free questions'
    : showPrebuildModal
    ? ''
    : `${questionCount} questions selected`;

  return (
    <ExerciseLayout
      bottomOffsetVariant={setBottomBarOffset(showSelectionBar, isMobile)}
      exerciseContent={
        <>
          <PreLaunchLayout selection={showSelectionBar || isDemo ? 1 : 0}>
            <MLATopicQuizBuilder
              detailsColumns={detailsColumns}
              displayTopics={displayTopics}
              exerciseType={ExerciseType.QuestionsMLA}
              header="MLA Content Map"
              loading={loading}
              mlaColumns
              mlaConditionColumns={mlaConditionColumns}
              mlaPatientPresentationColumns={mlaPatientPresentationColumns}
              overviewColumns={overviewColumns}
              searchLabel="Search by presentation or condition"
              selectedItemsInfo={selectedItemsInfo}
              setActiveTopics={setActiveTopics}
              setDisplayTopics={setDisplayTopics}
              solo
              topics={topics}
              {...selection}
            />
          </PreLaunchLayout>
          <Fade in={showSelectionBar || isDemo} unmountOnExit>
            <BottomSelectionBar
              onContinue={handleShowPrebuildModal}
              onContinueLoading={preBuildLoading || stateLoading}
              onUnselect={isDemo ? undefined : handleUnselectAll}
              selectedItemsLabel={selectedItemsInfo}
              withoutPanel
            />
          </Fade>
          {showPrebuildModal ? (
            <MLAPreBuildModal
              builderConfig={builderConfig}
              marksheetId={marksheetId}
              onClose={handleClosePrebuildModal}
              open={showPrebuildModal}
              selection={preBuildSelection}
              source={isDemo ? 'demo' : 'mla'}
            />
          ) : null}
          {open ? (
            <QuestionsQuizLeaveModal
              discard
              marksheet={marksheet}
              marksheetId={id}
              open={open}
            />
          ) : null}
        </>
      }
      floatingPanel
      onLeave={handleLeave}
      withoutPanel
    />
  );
};

export default MLAQuestionsQuizBuilder;
