import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { FireIcon, HeartIcon, PaletteOutlineIcon } from 'components/Icons';
import {
  WidgetCard,
  WidgetCardType,
} from 'pages/dashboard/components/WidgetCard';
import { Overline } from 'components/Typography';
import { HeatmapContent, HeatmapFooter, HeatmapInfo } from './components';
import { useHover, useIsDesktop, useIsMobile, usePrevious } from 'hooks';
import useUserActivityQuery from './useUserActivityQuery';
import { Popper } from 'components/Popper';
import { isValentinesDay, localStorageTyped } from 'utils';
import { Nullable } from 'types';
import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';

const SkeletonWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(12),
  marginTop: spacing(8),
}));

const RootContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
  padding: spacing(6, 0, 0),
  flex: 1,
}));

const StyledOverline = styled(Overline)(
  ({ theme: { palette, typography } }) => ({
    display: 'block',
    ...typography.button,
    color: palette.contrast.light,
  })
);

const HeatmapContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const HeatmapSectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const ColorOption = styled(Box)(({ theme: { spacing } }) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: spacing(3),

  '&:hover > div': {
    opacity: 0.8,
  },
}));

const ColorBox = styled(Box)<{
  colorname: string;
}>(({ colorname, theme: { palette } }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '7px',
  backgroundColor:
    palette.heatmap[colorname as unknown as keyof typeof palette.heatmap].high,
}));

const COLOR_OPTIONS = [
  {
    name: 'reliable-green',
  },
  {
    name: 'encouraging-lime',
  },
  {
    name: 'amber',
  },
  {
    name: 'innovative-blue',
  },
  {
    name: 'magenta',
  },
];

const { getItem, setItem: setHeatmapColour } =
  localStorageTyped<string>('heatmapColorScheme');

const Heatmap = () => {
  const { data, loading } = useUserActivityQuery({});
  const { isDesktop } = useIsDesktop();
  const { isMobile } = useIsMobile();
  const [heatmapColourName, setHeatmapColourName] =
    useState<Nullable<string>>('');
  const [, isPopupHover, , setPopupRef] = useHover<HTMLDivElement>();
  const [colorPickerRef, isColorPickerHover] = useHover<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const prevIsColorPickerHover = usePrevious(isColorPickerHover);
  const prevIsPopupHover = usePrevious(isPopupHover);
  const isOpen = Boolean(anchorEl);
  const noHover = !isPopupHover && !isColorPickerHover;
  const wasHover = prevIsPopupHover || prevIsColorPickerHover;

  const handleOpenPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChooseColor = (name: string) => () => {
    setHeatmapColour(name);
    setHeatmapColourName(name);
    setAnchorEl(null);
  };

  const {
    avgAttempted,
    avgScore,
    currentStreak,
    longestStreak,
    dailyProgress,
  } = data || {};

  useEffect(() => {
    if (isOpen && noHover && wasHover) {
      setAnchorEl(null);
    }
  }, [isOpen, noHover, wasHover]);

  useEffect(() => {
    setHeatmapColourName(getItem());
  }, []);

  return (
    <WidgetCard
      cardIcon={isValentinesDay() ? <HeartIcon /> : <FireIcon />}
      className="heatmap"
      content={
        <RootContainer>
          <HeatmapInfo
            avgQuestions={avgAttempted || 0}
            avgScore={avgScore || 0}
            currentStreak={currentStreak || 0}
            longestStreak={longestStreak || 0}
          />
          <HeatmapContainer>
            <StyledOverline>
              Hea{isValentinesDay() ? 'r' : ''}tmap (last{' '}
              {isDesktop ? '120' : isMobile ? '60' : '90'} days)
            </StyledOverline>
            <HeatmapSectionContainer>
              <HeatmapContent data={dailyProgress} />
              <HeatmapFooter />
            </HeatmapSectionContainer>
          </HeatmapContainer>
        </RootContainer>
      }
      headerAction={
        <>
          <Tooltip title="Choose colour scheme">
            <Button onClick={handleOpenPopper} ref={colorPickerRef} secondary>
              <PaletteOutlineIcon />
            </Button>
          </Tooltip>
          <Popper
            anchorEl={anchorEl}
            disablePortal
            open={isOpen}
            paperSx={({ spacing }) => ({
              display: 'flex',
              flexDirection: 'column',
              padding: spacing(2, 4),
              gap: spacing(2),
            })}
            placement="bottom"
            ref={setPopupRef}
          >
            {COLOR_OPTIONS.map(({ name }) => (
              <ColorOption key={name} onClick={handleChooseColor(name)}>
                <ColorBox colorname={name} />
              </ColorOption>
            ))}
          </Popper>
        </>
      }
      heatmapcolourname={heatmapColourName}
      loading={loading}
      loadingSkeleton={
        <SkeletonWrapper>
          <Skeleton height={64} variant="rounded" width="100%" />
          <Skeleton height={232} variant="rounded" width="100%" />
          <Skeleton height={32} variant="rounded" width="100%" />
        </SkeletonWrapper>
      }
      title={isValentinesDay() ? 'Heartmap' : 'Heatmap'}
      type={WidgetCardType.Heatmap}
    />
  );
};

export default Heatmap;
