import { Theme } from '@mui/material/styles';
import { ActiveDataPoint, ChartEvent, ChartOptions } from 'chart.js';

import { formatDate } from 'utils';
import {
  externalTooltipHandler,
  qBankLabelTransform,
} from '../utils/TooltipHandlers';
import { ItemStatusLabel, Nullable, QuestionsActivity } from 'types';

// TODO: Try to extract and combine with OsceBarchartHelpers.
export const setChartOptions = (
  theme: Theme,
  sumOfQuestions: number[],
  handleBarClick: (event: ChartEvent, elements: ActiveDataPoint[]) => void
): ChartOptions<'bar'> => ({
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      intersect: true,
      enabled: false,
      external: (context: any) => externalTooltipHandler(context),
      callbacks: {
        label: (context: any) => qBankLabelTransform(context, sumOfQuestions),
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  onClick: handleBarClick,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      border: {
        display: false,
      },
      grid: {
        color: (context: any) => {
          if (context.tick.value > 0) {
            return theme.palette.stroke.main;
          }

          return theme.palette.stroke.main;
        },
      },
      ticks: {
        stepSize: 0.5,
        count: 3,
      },
    },
  },
});

export const setChartData = (
  data: number[][],
  labels: string[],
  theme: Theme,
  isMobile: boolean,
  clickedIndex: Nullable<number>
) => {
  const { palette } = theme;

  const correctBGColor = data[0].map((_, index: number) => {
    return index === clickedIndex
      ? palette.success.light
      : palette.chart.successLight;
  });

  const incorrectBGColor = data[1].map((_, index: number) => {
    return index === clickedIndex
      ? palette.error.light
      : palette.chart.errorLight;
  });

  return {
    labels,
    datasets: [
      {
        label: ItemStatusLabel.Correct,
        data: data[0],
        backgroundColor: correctBGColor,
        borderColor: palette.background.paper,
        hoverBackgroundColor: palette.success.light,
        hoverBorderColor: palette.background.paper,
        borderWidth: isMobile ? 2 : 3,
        barThickness: isMobile ? 25 : 85,

        borderSkipped: false,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8,
        },
      },
      {
        label: ItemStatusLabel.Incorrect,
        data: data[1],
        backgroundColor: incorrectBGColor,
        borderColor: palette.background.paper,
        hoverBackgroundColor: palette.error.light,
        hoverBorderColor: palette.background.paper,
        borderWidth: isMobile ? 2 : 3,
        barThickness: isMobile ? 25 : 85,

        borderSkipped: false,
        borderRadius: 8,
      },
    ],
  };
};

// TODO: Move to utils file.
export const detectAndSum = (
  array: QuestionsActivity,
  attribute: 'correct' | 'incorrect'
): { date: string; sum: number }[] => {
  const result: { [key: string]: number } = {};

  for (const day of array) {
    const formattedDate = formatDate(day.date);
    result[formattedDate] = 0;

    for (const obj of day.result) {
      const value = obj[attribute] || 0;
      result[formattedDate] += value;
    }
  }

  // Convert the result dictionary to an array of { date, sum } objects
  return Object.entries(result).map(([date, sum]) => ({
    date,
    sum,
  }));
};
