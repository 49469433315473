import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { kebabCase } from 'lodash';
import { EAppType } from '@quesmed/types-rn/models';

import { H6 } from 'components/Typography';
import { usePlatform } from 'context/PlatformContext';
import {
  AppProductType,
  ProductState,
  QuesmedAppData,
  QuesmedAppName,
  QuesmedAppNames,
} from 'types';
import { paths } from 'Router';
import { getProductOptions } from 'components/Layout/ProductSwitcher';
import { ProductItem } from 'components/ProductItem';
import { setAppImage } from 'components/Illustrations';

export const CARD_HEIGHT = 652;
export const CARD_WIDTH = 432;

const AppCardContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    border: `1px solid ${palette.stroke.main}`,
    background: palette.background.paper,
    borderRadius: '9px',
    padding: spacing(4),
    width: '100%',

    [breakpoints.up('md')]: {
      maxWidth: `${CARD_WIDTH}px`,
      padding: spacing(6),
      minHeight: `${CARD_HEIGHT}px`,
    },

    [breakpoints.up('xl')]: {
      maxWidth: `${CARD_WIDTH}px`,
      width: `calc((100% - ${spacing(12)}) / 3)`,
    },
  })
);

const CardHeader = styled(H6)({
  textAlign: 'center',
});

const ProductsHeaderContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing(2),
}));

const Illustration = styled(Box)(({ theme: { spacing } }) => ({
  borderRadius: '8px',
  margin: spacing(4, 0, 6, 0),

  '& .app-image': {
    width: '100%',
    minHeight: '235px',
  },
}));

const ProductsHeader = styled(Box)(({ theme: { typography, palette } }) => ({
  ...typography.button,
  color: palette.text.disabled,
}));

interface AppCardProps {
  products: QuesmedAppData['products'];
  appType: EAppType;
  uniqueProductTypes: Set<AppProductType>;
}

const AppCard = ({
  products,
  appType,
  uniqueProductTypes,
}: AppCardProps): JSX.Element => {
  const { availableProducts, productStates } = usePlatform();
  const navigate = useNavigate();
  const title =
    appType === EAppType.MSRA ? QuesmedAppName.MSRA : QuesmedAppNames[appType];

  const parsedAppType = kebabCase(QuesmedAppNames[appType]);

  const redirectToPricing = (product: AppProductType) => () => {
    navigate(`${paths.pricing.root}/${parsedAppType}`, { state: { product } });
  };

  const productOptions = getProductOptions(products, availableProducts).filter(
    ({ value }) => uniqueProductTypes.has(value)
  );

  const AppImage = setAppImage(appType);

  return (
    <AppCardContainer>
      <CardHeader>{title}</CardHeader>
      {AppImage ? (
        <Illustration>
          <AppImage />
        </Illustration>
      ) : null}
      <ProductsHeaderContainer>
        <ProductsHeader>Products</ProductsHeader>
      </ProductsHeaderContainer>
      {productOptions.map(({ label, value: product, locked, active }) => (
        <ProductItem
          active={active}
          app={appType}
          expired={
            productStates
              ? productStates[product] === ProductState.Expired
              : false
          }
          key={label}
          label={label}
          locked={locked}
          onClick={redirectToPricing(product)}
          product={product}
        />
      ))}
    </AppCardContainer>
  );
};

export default AppCard;
