import { RefObject } from 'react';

export const scrollToElement = <T extends HTMLElement>(
  parentRef: RefObject<T>,
  elementOffsetTop: number,
  marginTop = 16
) => {
  const parentElement = parentRef.current;
  if (parentElement) {
    parentElement.scrollTo({
      behavior: 'smooth',
      top: elementOffsetTop - parentElement.offsetTop - marginTop,
    });
  }
};
