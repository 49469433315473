import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  EOsceMarksheetAction,
  EOsceMarksheetState,
  IOsceMarksheet,
} from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { useJoinSearchParam, useLeaveExerciseState, useRedirect } from 'hooks';
import { AgoraProvider } from 'components/Agora';
import {
  GroupStudyGuard,
  useGroupStudyGuardState,
} from 'components/GroupStudyGuard';
import Heartbeat from './Heartbeat';
import { paths } from 'Router';
import {
  StationModal,
  useGroupStudy,
  useStationLeavingBlockade,
  useStationModalState,
} from './hooks';
import StationRolesModal from './StationRolesModal';
import { Matchmaking } from './Matchmaking';
import { CircularProgress } from 'components/CircularProgress';
import StationsLeaveModal from './StationsLeaveModal';
import { CreateOrJoinMutation } from 'types';
import { Participant } from 'components/ParticipantCard';
import { StudyAlreadyStartedModal } from 'components/StudyAlreadyStartedModal';
import { isDev } from 'config/constants';

const { stations } = paths;
const { root, groupLobby } = stations;

type StationGroupStudyContext = {
  acton: EOsceMarksheetAction;
  osceMarksheet: IOsceMarksheet;
  inviteLink: string;
  isActor: boolean;
  isExaminer: boolean;
  isObserver: boolean;
  isCandidate: boolean;
  loading: boolean;
  restartSession?: CreateOrJoinMutation;
  participants: Participant[];
};

type GroupStudyRouteParams = {
  sessionId?: string;
  osceMarksheetId?: string;
};

export const useStationGroupStudyData = () => {
  return useOutletContext<StationGroupStudyContext>();
};

const StationsGroupStudy = () => {
  const navigate = useNavigate();
  const joinSessionURL = useJoinSearchParam();
  const { sessionId: sessionIdParam } = useParams<GroupStudyRouteParams>();
  const { open } = useLeaveExerciseState();
  const { removeRedirectLink } = useRedirect();
  const { shownModal, closeModal } = useStationModalState();
  const sessionId = sessionIdParam || joinSessionURL || '';
  const { guardActive } = useGroupStudyGuardState();
  const { hash, search } = useLocation();

  const {
    action,
    osceMarksheet,
    loading,
    restartSession,
    participants,
    inviteLink,
    isActor,
    isExaminer,
    isObserver,
    isCandidate,
    roleAssigned,
  } = useGroupStudy(sessionId);

  const {
    state,
    id: osceMarksheetId,
    members,
    users,
    completed,
  } = osceMarksheet || {};

  useEffect(() => {
    removeRedirectLink();
  }, [closeModal, removeRedirectLink, shownModal]);

  useEffect(() => {
    switch (state) {
      case EOsceMarksheetState.LOBBY:
        if (!search) {
          navigate(`${root}/${groupLobby}?join=${sessionId}`);
        }
        break;
      case EOsceMarksheetState.STATION_BUILDER:
        navigate(
          `${root}/${groupLobby}/builder/${sessionId}/${osceMarksheetId}`
        );
        break;
      case EOsceMarksheetState.PRESTART: {
        const prestartPath = `${root}/${groupLobby}/study${
          isCandidate ? '/waiting-room' : ''
        }/${sessionId}/${osceMarksheetId}`;
        navigate(prestartPath);
        break;
      }
      case EOsceMarksheetState.STATION:
        if (!isCandidate) {
          navigate(
            `${root}/${groupLobby}/study/${sessionId}/${osceMarksheetId}`
          );
        }
        break;
      case EOsceMarksheetState.RESULT:
        if (completed && hash) {
          navigate(
            `${root}/${groupLobby}/study/${sessionId}/${osceMarksheetId}${hash}`
          );
          break;
        }
        navigate(
          `${root}/${groupLobby}/study/summary/${sessionId}/${osceMarksheetId}`
        );
        break;
      default:
        break;
    }
  }, [
    state,
    osceMarksheetId,
    navigate,
    sessionId,
    isCandidate,
    completed,
    hash,
    search,
  ]);

  const ignoreBlockade =
    (!isNil(state) && state === EOsceMarksheetState.RESULT) ||
    !osceMarksheetId ||
    guardActive;

  useStationLeavingBlockade({
    osceMarksheetId,
    shouldBlock: Boolean(osceMarksheet) && !guardActive,
    ignoreBlockade,
    solo: false,
  });

  const showStudy = Boolean(osceMarksheet || state === undefined);
  const inProgress = loading || state === undefined;

  return (
    <>
      <GroupStudyGuard checkAudio checkTOC noLoading />
      <Heartbeat sessionId={osceMarksheet ? sessionId : null} />

      {showStudy ? (
        <>
          <Outlet
            context={{
              action,
              loading: inProgress,
              osceMarksheet,
              inviteLink: inviteLink,
              participants,
              isExaminer,
              isObserver,
              isActor,
              isCandidate,
              restartSession,
            }}
          />
          {members && osceMarksheetId && (
            <StationRolesModal
              inviteLink={inviteLink}
              members={members}
              onClose={closeModal}
              open={shownModal === StationModal.RolesModal}
              roleAssigned={roleAssigned}
              sessionId={sessionId}
            />
          )}
          {/* TODO remove checks when matchmaking is finally finished */}
          {isDev ? (
            <Matchmaking
              marksheetId={osceMarksheetId}
              members={users}
              sessionId={sessionId}
            />
          ) : null}
          <StudyAlreadyStartedModal
            isOpen={shownModal === StationModal.ErrorModal}
            onClose={closeModal}
          />
          {open ? (
            <StationsLeaveModal open={open} osceMarksheetId={osceMarksheetId} />
          ) : null}
        </>
      ) : (
        <CircularProgress description="Loading group study..." />
      )}
    </>
  );
};

export const StationsGroupStudyController = () => (
  <AgoraProvider>
    <StationsGroupStudy />
  </AgoraProvider>
);

export default StationsGroupStudyController;
