import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';

import { Button, ButtonProps } from 'components/Button';
import { SpaceBarHotKey } from 'components/HotKey';

interface ExerciseLaunchedButtonProps extends ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  children: JSX.Element | string;
  hotKey?: JSX.Element;
  hotKeyPosition?: 'start' | 'end';
}

const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100%',

  [breakpoints.up('md')]: {
    width: 'unset',
  },

  '& .hot-key': {
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
}));

const ExerciseLaunchedButton = forwardRef<
  HTMLButtonElement,
  ExerciseLaunchedButtonProps
>(
  (
    {
      disabled = false,
      loading = false,
      onClick,
      size = 'small',
      children,
      hotKey,
      hotKeyPosition = 'end',
      ...props
    },
    ref
  ) => {
    const hotKeyContent = hotKey ? (
      hotKey
    ) : (
      <SpaceBarHotKey disabled={disabled} />
    );

    return (
      <StyledButton
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        ref={ref}
        size={size}
        {...props}
      >
        {hotKeyPosition === 'start' ? hotKeyContent : null}
        {children}
        {hotKeyPosition === 'end' ? hotKeyContent : null}
      </StyledButton>
    );
  }
);

ExerciseLaunchedButton.displayName = 'ExerciseLaunchButton';

export default ExerciseLaunchedButton;
