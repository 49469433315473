import React from 'react';
import {
  EQuestionLike,
  IAccessLevel,
  IQuestionComment,
} from '@quesmed/types-rn/models';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { useCurrentUser } from 'Auth';
import { parseDate } from 'utils';
import { Likes } from 'components/Likes';
import { Button } from 'components/Button';
import DeleteOutlineIcon from 'components/Icons/DeleteOutlineIcon';
import {
  Actions,
  ActionsLeft,
  CommentBodyContainer,
  CommentDate,
  Name,
  NameDateContainer,
} from './Comment.styles';
import { Nullable } from 'types';
import { CommentText } from './CommentText';
import { formatCommentDate } from './formatCommentDate';
import { LogoImage } from 'components/Logo/components';
import { useMarkdownTextScale } from 'components/Markdown';

export interface CommentProps extends IQuestionComment {
  removeComment: (commentId: number) => void;
  removeCommentLoading: boolean;
  showReplyInput?: () => void;
  likeComment: (
    likeClick: boolean,
    commentId: number,
    parentId: Nullable<number>,
    isLikedByMe?: EQuestionLike
  ) => () => void;
  reply?: boolean;
}

const Comment = ({
  comment,
  id,
  createdAt,
  dislikes,
  isLikedByMe,
  likeComment,
  likes,
  parentId,
  removeComment,
  removeCommentLoading,
  user,
  showReplyInput,
  reply,
}: CommentProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const _createdAt = createdAt
    ? dayjs(parseDate(createdAt)).format('MMM DD, YYYY, h:mm:ss a')
    : undefined;
  const { id: currentUserId } = useCurrentUser();
  const { displayName = '', id: userId, accessLevel } = user || {};
  const isYou = userId === currentUserId;
  const isAdmin = accessLevel === IAccessLevel.ADMINISTRATOR;

  const handleRemoveComment = () => {
    removeComment(id);
  };

  const handleLike = (like: boolean) =>
    likeComment(like, id, parentId || null, isLikedByMe);

  return (
    <CommentBodyContainer className={reply ? 'reply' : ''}>
      <NameDateContainer>
        <Name bold className={clsx({ admin: isAdmin })} scale={scale}>
          {displayName}
          {isYou && <span className="you"> (you)</span>}
          {isAdmin && <LogoImage className="logo-image" size={24} />}
        </Name>
        {_createdAt ? (
          <CommentDate scale={scale} small>
            {formatCommentDate(_createdAt)}
          </CommentDate>
        ) : null}
      </NameDateContainer>
      <CommentText scale={scale} text={comment} />
      <Actions>
        <ActionsLeft>
          <Likes
            dislikes={dislikes}
            handleLike={handleLike}
            isLikedByMe={isLikedByMe}
            likes={likes}
          />
          {showReplyInput && (
            <Button onClick={showReplyInput} variant="text">
              Reply
            </Button>
          )}
        </ActionsLeft>

        {isYou && (
          <Button
            color="secondary"
            disabled={removeCommentLoading}
            loading={removeCommentLoading}
            onClick={handleRemoveComment}
            secondary
          >
            Remove <DeleteOutlineIcon />
          </Button>
        )}
      </Actions>
    </CommentBodyContainer>
  );
};

export default Comment;
