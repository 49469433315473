import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { CellAllignment, Children } from 'types';

const GridBox = styled(Box)({
  '&.nowrap': {
    whiteSpace: 'nowrap',
  },
});

interface GridCellProps extends Omit<BoxProps, 'align' | 'children'> {
  className?: string;
  align: CellAllignment;
  type?: string;
  children?: Children;
}

export const GridCell = forwardRef<HTMLDivElement, GridCellProps>(
  ({ children, className, align, type, ...props }, ref): JSX.Element => (
    <GridBox className={clsx(align, type, className)} {...props} ref={ref}>
      {children}
    </GridBox>
  )
);

GridCell.displayName = 'GridCell';

export default GridCell;
