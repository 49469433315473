import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMarksheet, IMarksheetMark } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { calcQuizTimeTaken, calculatePercent, formatDuration } from 'utils';
import { ExerciseType, MockTestRouter } from 'types';
import { ExerciseSummary, SummaryTabs } from 'components/ExerciseSummary';
import { useMarksheetQuery } from 'hooks';
import { setQuestionsProgressData } from '../Questions/setQuestionsProgressData';
import constants from 'config/constants';
import { ExerciseLayout } from 'components/Layout';
import { paths } from 'Router';
import { useMockTestStateSetters } from './mockTestsState';
import { Button } from 'components/Button';
import { getSummaryColumns } from './helpers';
import { StickerTextOutlineIcon } from 'components/Icons';

const { mockTests, dashboard } = paths;

const { MILISECONDS_IN_SECOND } = constants;

const tabs = [{ title: SummaryTabs.Content, icon: <StickerTextOutlineIcon /> }];

const MockTestSummary = (): JSX.Element => {
  const { marksheetId = '', mockTestId = '' } = useParams<MockTestRouter>();
  const navigate = useNavigate();
  const { marksheet, loading: marksheetLoading } =
    useMarksheetQuery(marksheetId);
  const {
    marks,
    totalQuestions = 0,
    incorrect = 0,
    correct = 0,
    passingMark,
    duration: quizDuration,
    timeTaken,
    completed,
  } = marksheet || ({} as IMarksheet);
  const duration = formatDuration(
    (completed ? timeTaken : calcQuizTimeTaken(marks)) * MILISECONDS_IN_SECOND,
    'HH:mm:ss'
  );
  const [activeTab, setActiveTab] = useState(tabs?.[0].title);

  const columns = useMemo(() => getSummaryColumns(true, false), []);

  const { resetState, setRightFooterControls } = useMockTestStateSetters();

  // TODO resolve problem with cache
  // there is an issue when we refresh the summary page - endedAt value is changed and duration value is incorrect
  // but initially when user finishes the test and goes to summary page - duration is correct
  const maxTestDuration = formatDuration(
    quizDuration * MILISECONDS_IN_SECOND,
    'HH:mm:ss'
  );

  const handleGoToReview = (mark: IMarksheetMark) => {
    const { id: markId, question } = mark;
    const { id: questionId } = question;

    const index = marks?.findIndex(({ id }) => Number(id) === Number(markId));

    if (!isNil(index)) {
      navigate(
        `${mockTests.root}/test/${mockTestId}/${marksheetId}/${markId}/${questionId}`
      );
    }
  };
  const score = calculatePercent(correct, totalQuestions);
  const passed = score >= passingMark;

  const loading = !marksheet || marksheetLoading;

  const headerSubtitle = passingMark
    ? `Passing this mock exam requires a minimum score of ${passingMark}%`
    : '';

  const footerControls = useMemo(
    () => [
      <Button
        contrast
        key={1}
        onClick={() => navigate(dashboard)}
        variant="text"
      >
        Quit
      </Button>,
    ],
    [navigate]
  );

  useEffect(() => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    setRightFooterControls(footerControls);
  }, [setRightFooterControls, footerControls]);

  return (
    <ExerciseLayout
      exerciseContent={
        <>
          <ExerciseSummary
            activeTab={activeTab}
            correctCount={correct}
            correctLabel="Correct answers"
            data={setQuestionsProgressData(correct, incorrect)}
            duration={`${duration}/${maxTestDuration}`} // not available to fetch the marksheet maximum duration time - only time taken is provided
            headerSubtitle={headerSubtitle}
            loading={loading}
            passed={passed}
            score={score}
            setActiveTab={setActiveTab}
            tableData={{
              columns,
              data: marks,
              onClick: handleGoToReview,
            }}
            tabs={tabs}
            title={ExerciseType.Questions}
            total={totalQuestions}
          />
        </>
      }
      withoutPanel
      withoutVerticalOffset
    />
  );
};

export default MockTestSummary;
