import React, { forwardRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, Divider, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Button } from 'components/Button';
import { H6 } from 'components/Typography';
import { Nullable, SelectionBarLabels } from 'types';
import {
  ContentSaveOutlineIcon,
  DotsVerticalIcon,
  PreviewIcon,
  ReadInstructionIcon,
  RemoveSelectionIcon,
} from 'components/Icons';
import { Popper } from 'components/Popper';
import { ListItemButton } from 'components/List';
import { useHover, useIsMobile, usePrevious } from 'hooks';

interface BottomSelectionBarProps {
  selectedItemsLabel: string;
  unselectlabel?: SelectionBarLabels.UnselectAll | SelectionBarLabels.Unselect;
  onUnselect?: Nullable<() => void>;
  onContinue: Nullable<() => void>;
  onContinueLoading?: boolean;
  continueLabel?:
    | SelectionBarLabels.Continue
    | SelectionBarLabels.ReturnToSession
    | SelectionBarLabels.SaveChanges;
  reviewOrPresetLabel?:
    | SelectionBarLabels.ReviewLastSession
    | SelectionBarLabels.Review;
  savePresetLabel?: SelectionBarLabels.SaveAsPreset;
  onReviewOrSavePreset?: Nullable<() => void>;
  onInstructions?: Nullable<() => void>;
  onWalkthrough?: Nullable<() => void>;
  saveAsPreset?: boolean;
  onSavePreset?: () => void;
  withoutPanel?: boolean;
}

const StyledSelectionBar = styled(Box)(
  ({ theme: { palette, mixins, spacing, zIndex, breakpoints } }) => ({
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    bottom: 0,
    left: 0,
    alignItems: 'center',
    borderRadius: 0,
    zIndex: zIndex.appBar,
    margin: 0,
    backgroundColor: palette.secondary.dark,
    color: palette.contrast.contrastText,
    width: '100%',
    padding: spacing(4),
    flexDirection: 'column',
    height: `${mixins.bottombar.maxHeight}px`,
    gap: spacing(4),

    '& .continue-button': {
      width: '100%',
    },

    '& .review-button, & .instructions-button, & .unselect-button': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      padding: spacing(0, 12),
      flexDirection: 'row',
      height: '64px',
      gap: 0,

      '& .continue-button': {
        width: 'auto',
      },
    },

    [breakpoints.up('xl')]: {
      width: `calc(100% + ${mixins.controlPanel.collapsed.minWidth}px)`,
      '& .review-button, & .instructions-button, & .unselect-button': {
        display: 'flex',
      },
    },

    '& .left-box': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      marginRight: 0,
      h6: {
        paddingRight: spacing(6),
      },

      '& .MuiLoadingButton-root.MuiButton-text': {
        padding: 0,
        color: palette.secondary.light,

        '&:hover': {
          color: palette.secondary.contrastText,
        },
      },

      [breakpoints.up('xl')]: {
        width: 'auto',
        marginRight: 0,
        alignItems: 'baseline',
        justifyContent: 'initial',
      },

      [breakpoints.up('md')]: {
        marginRight: spacing(8),
      },
    },
    '& .right-box': {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
      gap: spacing(6),

      '& .MuiLoadingButton-root.MuiButton-text': {
        padding: 0,
        color: palette.secondary.light,

        '&:hover': {
          color: palette.secondary.contrastText,
        },

        '&.MuiLoadingButton-loading': {
          color: alpha(palette.secondary.light, 0.3),

          '& .MuiCircularProgress-root': {
            color: palette.primary.contrastText,
          },
        },
      },

      [breakpoints.up('md')]: {
        width: 'auto',
      },
    },

    '&.withoutPanel': {
      width: '100%',
    },
  })
);

const StyledListItemText = styled(ListItemText)(
  ({ theme: { typography } }) => ({
    whiteSpace: 'nowrap',
    ...typography.body1,

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  })
);

const PopperContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .MuiPaper-root': {
    width: '100%',
  },

  [breakpoints.up('xl')]: {
    display: 'none',
  },
}));

const ContinueButton = styled(Button)(({ theme: { palette } }) => ({
  '&.MuiLoadingButton-root.MuiButton-outlined': {
    borderColor: palette.secondary.light,
    color: palette.secondary.light,

    '&:hover': {
      borderColor: palette.secondary.light,
      color: palette.text.primary,
      backgroundColor: palette.secondary.light,
    },
  },
}));
const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

interface PopperListItemProps {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
}
const PopperListItem = ({ onClick, label, icon }: PopperListItemProps) => (
  <ListItemButton expanded={1} nested={0} onClick={onClick}>
    <ListItemIcon>{icon}</ListItemIcon>
    <StyledListItemText disableTypography primary={label} />
  </ListItemButton>
);

const BottomSelectionBar = forwardRef<HTMLDivElement, BottomSelectionBarProps>(
  (
    {
      withoutPanel,
      selectedItemsLabel,
      onContinue,
      onContinueLoading,
      onUnselect,
      unselectlabel = SelectionBarLabels.UnselectAll,
      continueLabel = SelectionBarLabels.Continue,
      reviewOrPresetLabel = SelectionBarLabels.ReviewLastSession,
      savePresetLabel = SelectionBarLabels.SaveAsPreset,
      onReviewOrSavePreset,
      onInstructions,
      onWalkthrough,
      saveAsPreset = false,
      onSavePreset,
    },
    ref
  ): JSX.Element => {
    const { isMobile } = useIsMobile();

    const [isOpen, setIsOpen] = useState(false);
    const [popperRef, value] = useHover<HTMLButtonElement>();
    const prevValue = usePrevious(value);

    const handleOpenMenu = () => {
      setIsOpen(true);
    };

    const handleCloseMenu = () => {
      setIsOpen(false);
    };

    useEffect(() => {
      if (isOpen && prevValue && !value) {
        handleCloseMenu();
      }
    }, [value, isOpen, prevValue]);

    const showDivider = Boolean(
      onUnselect && (onReviewOrSavePreset || onSavePreset || onInstructions)
    );

    return (
      <StyledSelectionBar className={clsx({ withoutPanel })} ref={ref}>
        <Box className="left-box">
          <H6>{selectedItemsLabel}</H6>
          {onUnselect ? (
            <Button
              className="unselect-button"
              disableFocusRipple
              disableRipple
              onClick={onUnselect}
              tertiary
            >
              {unselectlabel}
            </Button>
          ) : null}
          {onUnselect || onInstructions || onReviewOrSavePreset ? (
            <PopperContainer onClick={handleOpenMenu} ref={popperRef}>
              <DotsVerticalIcon />
              <Popper
                anchorEl={popperRef.current}
                disablePortal
                onClose={handleCloseMenu}
                open={isOpen}
                paperSx={({ spacing }) => ({
                  padding: spacing(0, 2),
                  width: spacing(50),
                })}
                placement="top-end"
              >
                <List>
                  {onUnselect ? (
                    <PopperListItem
                      icon={<RemoveSelectionIcon />}
                      label={SelectionBarLabels.RemoveSelection}
                      onClick={onUnselect}
                    />
                  ) : null}
                  {showDivider ? <StyledDivider /> : null}
                  {reviewOrPresetLabel && onReviewOrSavePreset ? (
                    <PopperListItem
                      icon={<PreviewIcon />}
                      label={SelectionBarLabels.ReviewLastSession}
                      onClick={onReviewOrSavePreset}
                    />
                  ) : null}
                  {saveAsPreset && onSavePreset ? (
                    <PopperListItem
                      icon={<ContentSaveOutlineIcon />}
                      label={SelectionBarLabels.SaveAsPreset}
                      onClick={onSavePreset}
                    />
                  ) : null}
                  {onInstructions ? (
                    <PopperListItem
                      icon={<ReadInstructionIcon />}
                      label={SelectionBarLabels.ReadInstructions}
                      onClick={onInstructions}
                    />
                  ) : null}
                  {onWalkthrough ? (
                    <PopperListItem
                      icon={<ReadInstructionIcon />}
                      label={SelectionBarLabels.ReadWalkthrough}
                      onClick={onWalkthrough}
                    />
                  ) : null}
                </List>
              </Popper>
            </PopperContainer>
          ) : null}
        </Box>
        <Box className="right-box">
          {onWalkthrough ? (
            <Button
              className="instructions-button"
              disableFocusRipple
              disableRipple
              onClick={onWalkthrough}
              tertiary
            >
              {SelectionBarLabels.ReadWalkthrough}
            </Button>
          ) : null}
          {onInstructions ? (
            <Button
              className="instructions-button"
              disableFocusRipple
              disableRipple
              onClick={onInstructions}
              tertiary
            >
              {SelectionBarLabels.ReadInstructions}
            </Button>
          ) : null}
          {reviewOrPresetLabel && onReviewOrSavePreset ? (
            <Button
              className="review-button"
              onClick={onReviewOrSavePreset}
              tertiary
            >
              {reviewOrPresetLabel}
            </Button>
          ) : null}
          {onSavePreset && savePresetLabel ? (
            <Button className="review-button" onClick={onSavePreset} tertiary>
              {savePresetLabel}
            </Button>
          ) : null}
          {onContinue ? (
            <ContinueButton
              className="continue-button"
              disableFocusRipple
              disableRipple
              loading={onContinueLoading}
              onClick={onContinue}
              secondary={isMobile}
              tertiary={!isMobile}
            >
              {continueLabel}
            </ContinueButton>
          ) : null}
        </Box>
      </StyledSelectionBar>
    );
  }
);

BottomSelectionBar.displayName = 'BottomSelectionBar';

export default BottomSelectionBar;
