import React from 'react';

import QuestionsSummary from './QuestionsSummary';
import { useQuestionGroupStudyData } from './QuestionsGroupStudyController';
import { CircularProgress } from 'components/CircularProgress';

const QuestionsGroupStudySummary = () => {
  const { marksheet, restartSession } = useQuestionGroupStudyData();

  return marksheet ? (
    <QuestionsSummary
      loading={false}
      marksheet={marksheet}
      restartSession={restartSession}
    />
  ) : (
    <CircularProgress description="Loading..." />
  );
};

export default QuestionsGroupStudySummary;
