import { styled } from '@mui/material';
import Box from '@mui/system/Box';

export const StyledMarkdown = styled(Box)(
  ({ theme: { spacing, typography, palette } }) => ({
    'ul, ol': {
      margin: 0,
      paddingLeft: spacing(4),
      listStyle: 'decimal',
    },

    li: {
      paddingLeft: 0,
      ...typography.body1,
      color: palette.text.primary,
      '&::before': {
        display: 'none',
      },
    },

    'li > ul': {
      marginTop: spacing(2),
    },
  })
);
