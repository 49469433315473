import { useQuery } from '@apollo/client';
import { ESortOrder } from '@quesmed/types-rn';
import {
  IMarksheetsWindowData,
  IMarksheetsWindowVar,
  MARKSHEETS_WINDOW,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useMarksheetsWindowQuery = (
  soloMode: boolean,
  updatedAt?: number | Date
) => {
  const { data, loading, client } = useQuery<
    IMarksheetsWindowData,
    IMarksheetsWindowVar
  >(MARKSHEETS_WINDOW, {
    variables: {
      filter: {
        limit: 300,
        solo: soloMode,
        updatedAt: updatedAt === 0 ? undefined : updatedAt,
        period: Number(7),
        order: ESortOrder.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { data, loading, client };
};

export default useMarksheetsWindowQuery;
