import React from 'react';

import {
  CheckboxMarkedCircleOutlineIcon,
  CloseCircleOutlineIcon,
  RadioBoxBlankIcon,
  RadioBoxMarkedIcon,
} from 'components/Icons';
import { QuestionAnswerStatus } from 'types';

const setRadioIcon = (state: QuestionAnswerStatus) => {
  switch (state) {
    case QuestionAnswerStatus.INVALID:
      return <CloseCircleOutlineIcon />;
    case QuestionAnswerStatus.VALID:
    case QuestionAnswerStatus.NOT_ANSWERED:
      return <CheckboxMarkedCircleOutlineIcon />;
    case QuestionAnswerStatus.SELECTED:
      return <RadioBoxMarkedIcon />;
    default:
      return <RadioBoxBlankIcon />;
  }
};

export default setRadioIcon;
