import { useCallback } from 'react';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import {
  IModifyMarksheetMarkVarInput,
  MODIFY_CURRENT_MARKSHEET_MARK,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import isNil from 'lodash/isNil';
import { MODIFY_CURRENT_MARKSHEET_MARK_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetMarkMutation from './useModifyMarksheetMarkMutation';

export interface ChangeQuestionArgs {
  newMarkId?: number;
  timeTaken?: number;
  markId: number;
}

const useChangeQuestion = (marksheetId: number) => {
  const [modify] = useModifyMarksheetMarkMutation(
    MODIFY_CURRENT_MARKSHEET_MARK,
    MODIFY_CURRENT_MARKSHEET_MARK_FRAGMENT
  );

  const changeQuestion = useCallback(
    async ({ newMarkId, timeTaken, markId }: ChangeQuestionArgs) => {
      const input: IModifyMarksheetMarkVarInput = {
        marksheetId,
        markId,
        action: EMarksheetAction.QUESTION_CHANGE,
      };

      if (!isNil(timeTaken)) {
        input.timeTaken = timeTaken;
      }

      if (!isNil(newMarkId)) {
        input.newMarkId = newMarkId;
      }

      if (marksheetId) {
        return modify({
          variables: {
            input,
          },
        });
      }
    },
    [marksheetId, modify]
  );

  return changeQuestion;
};

export default useChangeQuestion;
