import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const DashboardWrapper = styled(Box)(
  ({
    theme: {
      spacing,
      breakpoints,
      mixins: { container },
    },
  }) => ({
    maxWidth: `calc(${container.maxWidth}px + ${spacing(8)})`,
    width: '100%',
    margin: '0 auto',
    padding: spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),

    [breakpoints.up('md')]: {
      padding: spacing(8),
      gap: spacing(8),
      maxWidth: `calc(${container.maxWidth}px + ${spacing(16)})`,
    },

    [breakpoints.up('xl')]: {
      padding: spacing(8, 12),
      maxWidth: `calc(${container.maxWidth}px + ${spacing(24)})`,
    },
  })
);

export const DashboardHeader = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),

    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export const WidgetsContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    gap: spacing(6),
    display: 'flex',
    flexWrap: 'wrap',

    '& > *': {
      minWidth: '100%',
      flex: '1 0 30%',
    },

    [breakpoints.up('md')]: {
      flexDirection: 'column',

      '& > *': {
        minWidth: '382px',

        '&:last-of-type': {
          minWidth: '100%',
        },
      },
    },

    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& > *:last-of-type': {
        minWidth: '382px',
      },
    },
  })
);

export const PerformanceAndDailyContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    gap: spacing(6),
    flexDirection: 'column',
    flex: 1,

    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    [breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  })
);

export const HeatmapContainer = styled(Box)({
  flex: 2,
});
