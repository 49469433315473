import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EAppType, IConcept, ITopic } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbsProps,
  ReadingContentContainer,
} from 'components/LearningMaterials';
import { Nullable, WithDemo } from 'types';
import { SkeletonList } from 'components/Skeleton';
import { BookshelfIcon, FolderOpenOutlineIcon } from 'components/Icons';
import { Lightbox } from 'components/Lightbox';
import QbankConceptReading from './QbankConceptReading';
import { paths } from 'Router';
import { useDemo } from 'Auth';
import { DemoGuard } from 'components/Demo';
import { usePlatform } from 'context/PlatformState';
import { useUpsertChapterNote } from '../hooks';
import { useUserSettings } from 'hooks';

const { knowledgeLibrary } = paths;

interface QbankKnowledgeLibraryReadingProps {
  topics: Nullable<ITopic[]>;
  loading?: boolean;
  concept?: IConcept;
  activeCategoryLabel: string;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
  noBreadcrumbs?: boolean;
}

const QbankKnowledgeLibraryReading = ({
  activeCategoryLabel,
  topics,
  loading,
  concept,
  breadcrumbs,
  noBreadcrumbs,
}: QbankKnowledgeLibraryReadingProps): JSX.Element => {
  const isDemo = useDemo();
  const { app } = usePlatform();
  const {
    sectionId: topicId,
    chapterId: conceptId,
    entitlementId,
  } = useParams<KnowledgeLibraryRouterParams>();
  const [currentConcept, setCurrentConcept] = useState(concept);
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const navigate = useNavigate();
  const { expandedReading } = useUserSettings();

  const selectedTopic = topics?.find(
    topic => Number(topic.id) === Number(topicId)
  );

  const { concepts, name: topicName = '' } = selectedTopic || {};

  useEffect(() => {
    if (concept) {
      setCurrentConcept(concept);

      return;
    }

    const selectedConcept = concepts?.find(
      concept => Number(concept.id) === Number(conceptId)
    );
    setCurrentConcept(selectedConcept);
  }, [concept, conceptId, concepts]);

  const {
    chapter,
    name = '',
    videos,
    status,
    demo: useInDemo = true,
    id,
    userNote,
    userChapter,
  } = (currentConcept || {}) as WithDemo<IConcept>;
  const { explanation, pictures } = chapter || {};
  const upsertNote = useUpsertChapterNote();

  const handleSaveNotes = (value: string) => {
    upsertNote(value, Number(conceptId || id));
  };

  const handleNavigateToRoot = useCallback(() => {
    navigate(knowledgeLibrary.root);
  }, [navigate]);

  const handleNavigateToCategory = useCallback(() => {
    navigate(`${knowledgeLibrary.root}/section/${entitlementId}/${topicId}`);
  }, [navigate, entitlementId, topicId]);

  const readingBreadcrumbs = useMemo(
    () =>
      breadcrumbs || [
        {
          icon: BookshelfIcon,
          label: activeCategoryLabel,
          onClick: handleNavigateToRoot,
        },
        {
          icon: FolderOpenOutlineIcon,
          label: topicName,
          onClick: handleNavigateToCategory,
        },
      ],
    [
      activeCategoryLabel,
      handleNavigateToCategory,
      handleNavigateToRoot,
      topicName,
      breadcrumbs,
    ]
  );

  const locked = isDemo && !useInDemo;
  const hideFlashcardInvitation =
    app === EAppType.MRCP || app === EAppType.MSRA || app === EAppType.PLAB;

  return (
    <>
      <ReadingContentContainer ref={containerRef}>
        {loading && !conceptId ? (
          <SkeletonList height={128} spacing={4} />
        ) : (
          <>
            {locked ? (
              <DemoGuard contentName="Concept" />
            ) : (
              <>
                <QbankConceptReading
                  autoFocus
                  breadcrumbs={noBreadcrumbs ? undefined : readingBreadcrumbs}
                  conceptId={Number(conceptId || concept?.id)}
                  conceptName={name}
                  entitlementId={Number(entitlementId)}
                  expandedReading={expandedReading}
                  explanation={
                    userChapter?.explanation
                      ? userChapter.explanation
                      : explanation
                  }
                  hideExerciseInvitation={app === EAppType.INTERVIEW}
                  hideFlashcardInvitation={hideFlashcardInvitation}
                  onSaveNotes={handleSaveNotes}
                  pictures={pictures}
                  status={status}
                  topicId={Number(topicId)}
                  userNote={userNote}
                  videos={videos}
                />
                <Lightbox pictures={pictures} />
              </>
            )}
          </>
        )}
      </ReadingContentContainer>
    </>
  );
};

export default QbankKnowledgeLibraryReading;
