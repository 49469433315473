import { SyntheticEvent } from 'react';
import {
  EProductType,
  ETopicType,
  EUserLearningStatus,
  IConcept,
  IEntitlement,
  IEntitlementType,
  IOsceStation,
  IOsceStationMark,
  IOsceType,
  IPicture,
  ITopic,
  IUserConceptNote,
  IVideo,
} from '@quesmed/types-rn/models';

import {
  BuilderViewMode,
  Nullable,
  StationCategory,
  ToggleEvent,
  ToggleOptions,
  UIColumn,
} from 'types';

// Common
export type LearningStatus = Exclude<
  EUserLearningStatus,
  EUserLearningStatus.ALL
>;

export enum LearningStatusLabels {
  Urgent = 'Urgent',
  Revising = 'Revising',
  Complete = 'Complete',
  Watched = 'Watched',
  Unwatched = 'Unwatched',
  Unread = 'Unread',
}
export interface LearningMaterialsData {
  id: number;
  name: string;
  entitlement?: Nullable<IEntitlementType>;
  concepts?: LearningMaterialItem[];
  stations?: LearningMaterialItem[];
  videos?: LearningMaterialItem[];
}

export type LearningMaterialItemMap = {
  [key in LearningStatus]: LearningMaterialItem[];
};

export type LearningMaterialItems = [LearningStatus, LearningMaterialItem[]][];

export interface LearningMaterialItem {
  topicId?: number;
  id: number;
  status?: Nullable<EUserLearningStatus>;
  name?: string;
  title?: string;
  demo?: boolean;
  osceType?: IOsceType;
  osceStation?: IOsceStation;
  userViewed?: boolean;
  entitlement?: Nullable<IEntitlement>;
}

export type LearningMaterialsDataItemsField = keyof Pick<
  LearningMaterialsData,
  'concepts' | 'stations' | 'videos'
>;

// QBANK Knowledge Library
export type VideoTopic = ITopic & {
  videos?: IVideo[];
};

export type KnowledgeLibraryTopicType = Exclude<
  ETopicType,
  ETopicType.ALL | ETopicType.OSCE | ETopicType.PSA | ETopicType.MOCK_TEST
>;

export type KnowledgeLibraryColumns = ITopic & UIColumn;

export type VideoLibraryColumns = VideoTopic & UIColumn;

export type TopicMap = {
  [key: number]: ITopic[];
};

export type VideoTopicMap = {
  [key in number]: VideoTopic[];
};

export interface QbankKnowledgeLibraryData {
  topics: Nullable<ITopic[]>;
  topicsMap: Nullable<TopicMap>;
  loading: boolean;
  categories: ToggleOptions<EProductType>;
}

export interface QbankVideoLibraryData {
  topics: Nullable<VideoTopic[]>;
  topicsMap: Nullable<VideoTopicMap>;
  loading: boolean;
  categories: ToggleOptions<EProductType>;
}

export type KnowledgeLibraryRouterParams = {
  entitlementId?: string;
  sectionId?: string;
  chapterId?: string;
  videoId?: string;
};

export interface QbankKnowledgeLibraryState {
  activeCategory: EProductType;
  activeCategoryLabel: string;
  activeView: BuilderViewMode;
  categories: ToggleOptions<EProductType>;
  searchItems: LearningMaterialItem[];
  loading: boolean;
  resetSearch: boolean;
  topics: ITopic[];
  search: (searchString: string) => void;
  switchCategory: (e: SyntheticEvent, type: EProductType) => void;
  switchView: (e: ToggleEvent, view: BuilderViewMode) => void;
  searchBy: LearningMaterialsDataItemsField;
  searchTerm: string;
  notes: IUserConceptNote[];
}

export interface QbankVideoLibraryState {
  activeCategory: EProductType;
  activeCategoryLabel: string;
  activeView: BuilderViewMode;
  categories: ToggleOptions<EProductType>;
  loading: boolean;
  resetSearch: boolean;
  searchItems: Nullable<LearningMaterialItem[]>;
  topics: VideoTopic[];
  search: (searchString: string) => void;
  switchCategory: (e: SyntheticEvent, type: EProductType) => void;
  switchView: (e: ToggleEvent, view: BuilderViewMode) => void;
  searchBy: LearningMaterialsDataItemsField;
  searchTerm: string;
}

// OSCE Knowledge Library
export type OsceKnowledgeLibraryColumns = IOsceStation & UIColumn;

export interface StationCategories {
  id: EProductType;
  name: string;
  stations: IOsceStation[];
  videos?: IVideo[];
}

export type OsceCategoriesNames = {
  [key: number]: StationCategory;
};

export type StationMap = {
  [key in EProductType]: IOsceStation[];
};

export interface OsceKnowledgeLibraryData {
  stations: Nullable<LearningMaterialsData[]>;
  stationsMap: Nullable<StationMap>;
  loading: boolean;
  categories: ToggleOptions<EProductType>;
  allStations: Nullable<IOsceStation[]>;
  allVideos?: Nullable<IVideo[]>;
}

export type StationKey = keyof OsceKnowledgeLibraryStation;

export interface OsceKnowledgeLibraryState {
  activeCategory: EProductType;
  activeCategoryLabel: string;
  categories: ToggleOptions<EProductType>;
  loading: boolean;
  resetSearch: boolean;
  searchItems: LearningMaterialItem[];
  stations: IOsceStation[];
  allStations: StationCategories[];
  searchTerm?: string;
  search: (searchString: string) => void;
  switchCategory: (e: SyntheticEvent, type: EProductType) => void;
  searchBy: LearningMaterialsDataItemsField;
}

export interface PacesKnowledgeLibraryState {
  activeCategory: EProductType;
  activeCategoryLabel: string;
  categories: ToggleOptions<EProductType>;
  loading: boolean;
  resetSearch: boolean;
  searchItems: LearningMaterialItem[];
  searchTerm?: string;
  concepts: IConcept[];
  allConcepts: IConcept[];
  search: (searchString: string) => void;
  switchCategory: (e: SyntheticEvent, type: EProductType) => void;
  searchBy: LearningMaterialsDataItemsField;
}

export interface OsceVideoLibraryState {
  activeCategory: EProductType;
  activeCategoryLabel: string;
  loading: boolean;
  resetSearch: boolean;
  stations: StationCategories[];
  categories: ToggleOptions<EProductType>;
  search: (searchString: string) => void;
  switchCategory: (e: SyntheticEvent, type: EProductType) => void;
  searchBy: LearningMaterialsDataItemsField;
  allVideos?: Nullable<IVideo[]>;
}

export interface OsceKnowledgeLibraryStation
  extends Pick<IOsceStation, 'osceType' | 'osceTypeId'> {
  id: number;
  name: string;
  osceTypeId: number;
  candidateBrief: string;
  examinerBrief: string;
  actorBrief: string;
  walkthroughBrief?: string;
  explanation: string;
  candidatePictures?: IPicture[];
  actorPictures?: IPicture[];
  examinerPictures?: IPicture[];
  walkthroughPictures?: IPicture[];
  lastOsceMarksheetId?: number;
  marks?: IOsceStationMark[];
  videos?: IVideo[];
}
