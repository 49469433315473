import React from 'react';

import { OsceVideoLibrary } from './osce';
import { QbankVideoLibrary } from './qbank';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct } from 'utils';

const VideoLibrary = (): JSX.Element => {
  const { product } = usePlatform();

  return isStationsProduct(product) ? (
    <OsceVideoLibrary />
  ) : (
    <QbankVideoLibrary />
  );
};

export default VideoLibrary;
