import { EMarksheetState, EStudyAction } from '@quesmed/types-rn/models';
import { EPlatformId } from '@quesmed/types-rn';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'Auth';
import { usePrevious } from 'hooks/usePrevious';
import { useAgoraAPI, useSetAgoraControlProps } from 'components/Agora';
import useMarksheet from './useMarksheet';
import useStartOrJoin from './useStartOrJoin';
import useOnMarksheetChange from './useOnMarksheetChange';
import useOnPreStartMarksheetChange from './useOnPreStartMarksheetChange';
import { paths } from 'Router';

const { questions } = paths;
const { root, groupLobby } = questions;

const useGroupStudy = (sessionIdURL: string) => {
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const { id } = useCurrentUser();

  const {
    isNewMarksheet,
    agoraId,
    marksheetId,
    startOrJoin,
    inviteLink,
    startLoading,
    sessionId,
  } = useStartOrJoin(sessionIdURL);
  const { marksheet, marksheetLoading, refetchMarksheet } = useMarksheet(
    startLoading,
    marksheetId
  );
  const { activeUsers, state } = marksheet || {};
  const currentUserId = id ? Number(id) : undefined;

  const prevState = usePrevious(state);

  useEffect(() => {
    if (sessionIdURL) {
      startOrJoin(EStudyAction.JOIN);
    }
  }, [sessionIdURL, startOrJoin]);

  useEffect(() => {
    if (activeUsers && activeUsers.length > 1 && !init) {
      setInit(true);
    }
  }, [activeUsers, init]);

  useOnMarksheetChange(undefined, sessionId, false, refetchMarksheet);
  useOnPreStartMarksheetChange(sessionId);
  useSetAgoraControlProps(
    EPlatformId.QBANK,
    init,
    init,
    false,
    false,
    currentUserId,
    agoraId
  );
  const { connect } = useAgoraAPI();

  useEffect(() => {
    if (
      prevState === EMarksheetState.QUIZ_BUILDER &&
      state === EMarksheetState.STATION &&
      init
    ) {
      connect();
    }
  }, [connect, init, prevState, state]);

  useEffect(() => {
    if (
      prevState === EMarksheetState.QUIZ_BUILDER &&
      state === EMarksheetState.STATION
    ) {
      refetchMarksheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, init]);

  useEffect(() => {
    if (marksheetId && isNewMarksheet) {
      connect();
      navigate(`/${root}/${groupLobby}?join=${sessionIdURL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marksheetId]);

  return {
    startLoading,
    marksheetLoading,
    marksheet,
    restartSession: startOrJoin,
    inviteLink,
  };
};

export default useGroupStudy;
