import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

export const TableWrapper = styled(Box)(
  ({ theme: { palette, breakpoints } }) => ({
    borderTop: `1px solid ${palette.stroke.main}`,
    position: 'relative',

    [breakpoints.down('md')]: {
      overflowY: 'auto',
    },
  })
);

export const TableScroll = styled(Box)(() => ({
  overflow: 'auto',
}));

export const LegendBox = styled(TableCell)(
  ({ theme: { typography, breakpoints, spacing } }) => ({
    ...typography.body2,
    '.correct, .incorrect': {
      marginRight: spacing(4),
    },

    '.MuiBox-root': {
      padding: spacing(0, 1),
      display: 'inline-flex',
      alignItems: 'center',
    },

    [breakpoints.down('md')]: {
      padding: 0,
    },
  })
);

export const Icon = styled(Box)(({ theme: { palette, spacing } }) => ({
  marginRight: spacing(2),
  height: '8px',
  width: '8px',
  borderRadius: '3px',

  '&.correct, &.performed, &.confident': {
    backgroundColor: palette.success.light,
  },

  '&.incorrect, &.to-learn': {
    backgroundColor: palette.error.light,
  },

  '&.to-review': {
    backgroundColor: palette.warning.main,
  },

  '&.not-answered, &.not-performed': {
    backgroundColor: palette.stroke.main,
  },
}));
