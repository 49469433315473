import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserLearningStatus, IConcept } from '@quesmed/types-rn/models';

import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { useQbankKnowledgeLibraryData } from './QbankKnowledgeLibrary';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import {
  getItemsMap,
  Items,
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbs,
  LearningMaterialsData,
  PanelItems,
  QBANK_SEARCH_LABEL,
  useUpdateConceptLearningStatus,
} from 'components/LearningMaterials';
import { Nullable, PathName } from 'types';
import {
  SelectionCategories,
  SelectionHeading,
} from 'components/ExerciseBuilder';
import { BookshelfIcon, FolderOpenOutlineIcon } from 'components/Icons';
import { paths } from 'Router';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { SearchItemsList } from 'components/ExerciseBuilder/SearchItemsList';

const { knowledgeLibrary } = paths;

const QbankKnowledgeLibrarySection = () => {
  const { sectionId: topicId } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const {
    categories,
    activeCategory,
    activeCategoryLabel,
    resetSearch,
    topics,
    search,
    searchTerm: query,
    searchItems,
    searchBy,
    switchCategory,
    loading,
  } = useQbankKnowledgeLibraryData();
  const [displayConcepts, setDisplayedConcepts] =
    useState<Nullable<IConcept[]>>(null);
  const { updateStatus } = useUpdateConceptLearningStatus();
  const [searchTerm, setSearchTerm] = useState('');

  usePrealoadImages(topics);

  const topic = topics?.find(({ id }) => Number(id) === Number(topicId));

  const { concepts, name = '' } = topic || {};

  const setTopics = useCallback(
    (text: string) => {
      const normalizedSearchString = text.toLowerCase().trim();
      if (concepts && text) {
        const filtered = concepts.filter(({ name }) =>
          name.toLowerCase().includes(normalizedSearchString)
        );

        return setDisplayedConcepts(filtered);
      }

      setDisplayedConcepts(concepts || null);
    },
    [concepts]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      setSearchTerm(searchString);
      setTopics(searchString);
    },
    [setTopics]
  );

  const handleNavigate = useCallback(
    (conceptId: number) => () => {
      const entitlementId = topic?.entitlement?.id;
      navigate(
        `${knowledgeLibrary.root}/chapter/${entitlementId}/${topicId}/${conceptId}`
      );
    },
    [navigate, topic, topicId]
  );

  const handleNavigateBack = useCallback(() => {
    navigate(knowledgeLibrary.root);
  }, [navigate]);

  const handleStatusChange = useCallback(
    async (
      newStatus: EUserLearningStatus,
      ids: number[],
      currentStatus?: Nullable<EUserLearningStatus>
    ) => updateStatus(ids, newStatus, Number(topicId), currentStatus),

    [updateStatus, topicId]
  );

  useEffect(() => {
    setTopics(searchTerm);
  }, [setTopics, searchTerm]);

  const conceptsMap = useMemo(
    () => getItemsMap(displayConcepts),
    [displayConcepts]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
        onClick: handleNavigateBack,
      },
      { icon: FolderOpenOutlineIcon, label: name },
    ],
    [activeCategoryLabel, handleNavigateBack, name]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchItems}
          searchLabel={QBANK_SEARCH_LABEL}
          searchTerm={query}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader mainHeader={PathName.KnowledgeLibrary} />
          <SelectionCategories
            activeCategory={activeCategory}
            categoryOptions={categories}
            onToggleCategory={switchCategory}
          />
          <SelectionHeading
            loading={loading}
            onSearch={search || handleSearch}
            resetSearch={resetSearch}
            searchLabel="Search..."
            searchTerm={searchItems ? query : undefined}
            title={<LearningMaterialsBreadcrumbs header items={breadcrumbs} />}
          />
          {searchItems ? (
            <SearchItemsList searchItems={searchItems} title="Concept names" />
          ) : (
            <Items
              itemsMap={conceptsMap}
              navigate={handleNavigate}
              onStatusChange={handleStatusChange}
              showFallback={!displayConcepts?.length}
              title="Concept names"
            />
          )}
        </PreLaunchLayout>
      }
    />
  );
};

export default QbankKnowledgeLibrarySection;
