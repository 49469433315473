import { useCallback, useEffect, useState } from 'react';
import { EPrescriptionType, IPrescriptionItem } from '@quesmed/types-rn/models';
import { debounce } from 'lodash';

import { useGetPrescriptionsQuery } from './useGetPrescriptionsQuery';
import { noop } from 'utils';
import { PrescriptionOption } from 'pages/MockTests/types';

const transformPrescription = ({
  name,
  id,
}: IPrescriptionItem): PrescriptionOption => ({
  value: id,
  label: name,
});

export const useGetPrescriptionOptions = (isNewQuestion: boolean) => {
  const [message, setMessage] = useState<string>(
    'Write at least 2 characters...'
  );
  const [options, setOptions] = useState<PrescriptionOption[]>([]);
  const { getPrescriptions, loading } = useGetPrescriptionsQuery();

  const getOptions = useCallback(
    async (
      type: EPrescriptionType,
      value: string,
      options: PrescriptionOption[]
    ) => {
      try {
        if (options.find(({ label }) => label === value)) {
          return;
        }
        const isDosePrescription = type === EPrescriptionType.Dose;
        const prescriptionText = isDosePrescription
          ? 'Write at least 1 number...'
          : 'Write at least 2 characters...';

        if (!isDosePrescription && value.length < 2) {
          setMessage(prescriptionText);
          setOptions([]);

          return;
        }

        if (isDosePrescription && value.length < 1) {
          setMessage(prescriptionText);
          setOptions([]);

          return;
        }

        const response = await getPrescriptions(type, value);

        const { result } = response.data?.restricted.prescriptions || {};

        setMessage(value ? 'No options' : prescriptionText);

        if (!result?.length) {
          setOptions([]);

          return;
        }

        const transformedResults = result.map(transformPrescription);

        setOptions(transformedResults);
      } catch {
        noop();
      }
    },
    [getPrescriptions]
  );

  const debouncedGetOptions = debounce(getOptions, 300);

  useEffect(() => {
    return () => {
      debouncedGetOptions.cancel();
    };
  }, [debouncedGetOptions]);

  useEffect(() => {
    if (isNewQuestion) {
      setOptions([]);
    }
  }, [isNewQuestion]);

  return {
    options,
    loading,
    getOptions: debouncedGetOptions,
    message,
  };
};
