import React, { useEffect, useMemo, useState } from 'react';
import {
  ActiveDataPoint,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';

import StyledBox from './QBankBarchartStyle';
import {
  detectAndSum,
  setChartData,
  setChartOptions,
} from './QBankBartchartHelpers';
import { formatDate } from 'utils';
import { useIsMobile } from 'hooks';
import { Nullable, QuestionsActivity } from 'types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IQBankBarChart {
  clickedBar: string;
  data: QuestionsActivity;
  chartRef: any;
  onBarClick: (index: string) => void;
}

const QBankBarChart = ({
  data,
  clickedBar,
  chartRef,
  onBarClick,
}: IQBankBarChart) => {
  const theme = useTheme();
  const { isMobile } = useIsMobile();

  const labelsSet: Set<string> = useMemo(() => {
    return new Set(data.map(result => formatDate(result.date)));
  }, [data]);

  const [clickedIndex, setClickedIndex] = useState<Nullable<number>>(
    [...labelsSet].findIndex(label => label === clickedBar)
  );
  useEffect(() => {
    setClickedIndex([...labelsSet].findIndex(label => label === clickedBar));
  }, [clickedBar, labelsSet]);

  const desktopLabels = [...labelsSet];
  const mobileLabels = desktopLabels.map((label: string) => {
    return formatDate(new Date(label), 'D');
  });

  const allCorrectAnswers = detectAndSum(data, 'correct');
  const correctAnswers = allCorrectAnswers
    ? allCorrectAnswers.map(({ sum }) => sum)
    : [];

  const allIncorrectAnswers = detectAndSum(data, 'incorrect');
  const incorrectAnswers = allIncorrectAnswers
    ? allIncorrectAnswers.map(({ sum }) => sum)
    : [];

  const dataset = [correctAnswers, incorrectAnswers];

  // add up correct and incorrect questions per bar into array so we can index them.
  const sumOfQuestions = correctAnswers.map(
    (number: number, index: number) => number + incorrectAnswers[index]
  );

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (getElementAtEvent(chartRef.current, event).length > 0) {
      const datasetIndexNum = getElementAtEvent(chartRef.current, event)[0]
        .index;
      onBarClick(desktopLabels[datasetIndexNum]);
    }
  };

  const labels = isMobile ? mobileLabels : desktopLabels;

  const handleBarClick = (event: ChartEvent, elements: ActiveDataPoint[]) => {
    setClickedIndex(elements.length ? elements[0].index : null);
  };

  return (
    <StyledBox>
      <Bar
        data={setChartData(dataset, labels, theme, isMobile, clickedIndex)}
        onClick={onClick}
        options={setChartOptions(theme, sumOfQuestions, handleBarClick)}
        ref={chartRef}
      />
    </StyledBox>
  );
};

export default QBankBarChart;
