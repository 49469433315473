import React, { ReactNode } from 'react';
import { ITodoMark } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';

import FlashCardPill from './FlashCardPill';
import { useExpandedState } from 'components/Layout/useExerciseLayoutState';
import { useIsDesktop } from 'hooks/useDeviceSize';

interface FlascardPillsListProps {
  pills: ITodoMark[];
  pillsMap: Map<number, [number, number]>;
  fallback: ReactNode;
  onClick: (index: number) => void;
  currentPillId: number;
}

const FlascardPillsList = ({
  currentPillId,
  fallback,
  onClick,
  pills,
  pillsMap,
}: FlascardPillsListProps): JSX.Element => {
  const { toggleExpanded } = useExpandedState();
  const { isDesktop } = useIsDesktop();

  const handleClick = (index: number) => () => {
    if (!isDesktop) {
      toggleExpanded();
    }
    onClick(index);
  };

  return pills.length ? (
    <>
      {pills.map(({ id, score }) => {
        const [pillIndex, pillNumber] = pillsMap.get(id) || [0, 1];

        return (
          <FlashCardPill
            key={id}
            label={`Flashcard ${pillNumber}`}
            onClick={handleClick(pillIndex)}
            score={score}
            selected={currentPillId === id}
          />
        );
      })}
    </>
  ) : (
    <Box>{fallback}</Box>
  );
};

export default FlascardPillsList;
