import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import {
  getItemsMap,
  Items,
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbs,
  useUpdateConceptLearningStatus,
} from 'components/LearningMaterials';
import { Nullable, PathName } from 'types';
import {
  SelectionCategories,
  SelectionHeading,
} from 'components/ExerciseBuilder';
import { BookshelfIcon } from 'components/Icons';
import { paths } from 'Router';
import { usePacesKnowledgeLibraryData } from './PacesKnowledgeLibrary';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { PacesKnowledgeLibraryLayout } from './PacesKnowledgeLibraryLayout';
import { SearchItemsList } from 'components/ExerciseBuilder/SearchItemsList';

const { knowledgeLibrary } = paths;

const PacesKnowledgeLibrarySection = () => {
  const { sectionId: categoryId } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const {
    activeCategory,
    activeCategoryLabel,
    categories,
    concepts,
    search,
    searchTerm,
    searchItems,
    resetSearch,
    switchCategory,
    loading,
  } = usePacesKnowledgeLibraryData();
  const { updateStatus } = useUpdateConceptLearningStatus();

  usePrealoadImages(concepts);

  const handleNavigate = useCallback(
    (conceptId: number) => () => {
      const entitlementId = concepts.find(
        ({ id }) => Number(id) === Number(conceptId)
      )?.entitlement?.id;
      navigate(
        `${knowledgeLibrary.root}/chapter/${entitlementId}/${categoryId}/${conceptId}`
      );
    },
    [concepts, navigate, categoryId]
  );

  const handleStatusChange = useCallback(
    async (
      newStatus: EUserLearningStatus,
      ids: number[],
      currentStatus?: Nullable<EUserLearningStatus>
    ) => updateStatus(ids, newStatus, Number(categoryId), currentStatus),
    [categoryId, updateStatus]
  );

  const conceptsStatusMap = useMemo(() => getItemsMap(concepts), [concepts]);

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
      },
    ],
    [activeCategoryLabel]
  );

  return (
    <PacesKnowledgeLibraryLayout>
      <PreLaunchLayout selection={0}>
        <ExerciePreBuildHeader
          mainHeader={PathName.KnowledgeLibrary}
          notesButton
        />
        <SelectionCategories
          activeCategory={activeCategory}
          categoryOptions={categories}
          onToggleCategory={switchCategory}
        />
        <SelectionHeading
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchLabel="Search..."
          searchTerm={searchTerm}
          title={<LearningMaterialsBreadcrumbs header items={breadcrumbs} />}
        />
        {searchTerm ? (
          <SearchItemsList
            searchItems={searchItems}
            sectionId={categoryId}
            title="Concept names"
          />
        ) : (
          <Items
            itemsMap={conceptsStatusMap}
            navigate={handleNavigate}
            onStatusChange={handleStatusChange}
            showFallback={!concepts?.length}
            title="Concept names"
          />
        )}
      </PreLaunchLayout>
    </PacesKnowledgeLibraryLayout>
  );
};

export default PacesKnowledgeLibrarySection;
