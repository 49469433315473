import { MutationHookOptions, useMutation } from '@apollo/client';
import {
  END_MARKSHEET,
  IEndMarksheetData,
  IEndMarksheetVar,
  updateCacheOnEndMarksheet,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useEndMarksheetMutation = (
  baseOptions?: MutationHookOptions<IEndMarksheetData, IEndMarksheetVar>
) => {
  const options = { ...baseOptions };

  return useMutation<IEndMarksheetData, IEndMarksheetVar>(END_MARKSHEET, {
    ...options,
    update: updateCacheOnEndMarksheet,
  });
};

export default useEndMarksheetMutation;
