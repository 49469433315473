import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMarksheet, IQuestion } from '@quesmed/types-rn/models';

import { Markdown } from 'components/Markdown';
import { useDisableHighlighting } from 'hooks/useDisableHighlighting';
import useQuestionHighlight from 'pages/Questions/hooks/useQuestionHighlight';
import { MockTestRouter, Undefinable } from 'types';
import { addMarkTagsInMarkdown, unescape } from 'utils';
import { MockTestQuestionAnswerController } from '../MockTestQuestionAnswerController';
import { MockTestQuestionRelatedContent } from '../MockTestQuestionRelatedContent';
import {
  LeftQuizColumn,
  QuizContainer,
  RightQuizColumn,
} from './MockTestsLayoutStyles';
import { useMockTestStateSetters } from '../mockTestsState';
import { paths } from 'Router';
import { EndExerciseModal } from 'components/ExerciseModals';
import { useEndMockTest } from '../hooks';
import { Button } from 'components/Button';

const { mockTests } = paths;

interface MockTestQuizLayoutProps {
  question: IQuestion;
  marksheet: IMarksheet;
  selectedChoiceId: Undefinable<number>;
  setSelectedId: React.Dispatch<React.SetStateAction<Undefinable<number>>>;
  setUserAnswer: React.Dispatch<React.SetStateAction<string>>;
  timeIsUp: boolean;
}

export const MockTestQuizLayout = ({
  marksheet,
  question,
  selectedChoiceId,
  setSelectedId,
  setUserAnswer,
  timeIsUp,
}: MockTestQuizLayoutProps) => {
  const [showEndQuizModal, setShowEndQuizModal] = useState(false);
  const { questionId = '', mockTestId = '' } = useParams<MockTestRouter>();
  const { id: marksheetId, marks, completed } = marksheet;
  const { typeId, pictures, highlights = [] } = question;
  const { handleAddHighlight, handleRemoveHighlight } = useQuestionHighlight(
    Number(marksheetId),
    question.id,
    question.typeId,
    highlights
  );
  const { disabledHighlighting } = useDisableHighlighting();
  const { setLeftFooterControls } = useMockTestStateSetters();
  const { endMockTest, loading } = useEndMockTest(marksheet, mockTestId);
  const navigate = useNavigate();

  const reviewMode = completed || timeIsUp;
  const questionIndex = marks.findIndex(
    ({ question }) => Number(question.id) === Number(questionId)
  );
  const currentMark = marks[questionIndex];
  const leaveLabel = reviewMode ? 'Go to Summary' : 'End & Summary';
  const isAllAnswered = marks.every(({ isAnswered }) => isAnswered);

  const handleShowEndQuizModal = () => setShowEndQuizModal(true);

  const handleHideEndQuizModal = () => setShowEndQuizModal(false);

  const handleLeave = useCallback(() => {
    if (reviewMode || isAllAnswered) {
      navigate(`${mockTests.root}/test/summary/${mockTestId}/${marksheetId}`, {
        state: { leave: true },
      });

      if (isAllAnswered) {
        endMockTest();
      }
    } else {
      handleShowEndQuizModal();
    }
  }, [
    endMockTest,
    isAllAnswered,
    marksheetId,
    mockTestId,
    navigate,
    reviewMode,
  ]);

  const fullMarkdown = useMemo(
    () => unescape(question.question).replace(/(  +)(\w)/g, ' $2'),
    [question.question]
  );

  const text = useMemo(
    () => addMarkTagsInMarkdown(fullMarkdown, highlights, pictures, true),
    [fullMarkdown, highlights, pictures]
  );

  const leftFooterControls = useMemo(
    () => [
      <Button contrast key={3} onClick={handleLeave} variant="text">
        {leaveLabel}
      </Button>,
    ],
    [handleLeave, leaveLabel]
  );
  useEffect(() => {
    setLeftFooterControls(leftFooterControls);
  }, [setLeftFooterControls, leftFooterControls]);

  return (
    <>
      <QuizContainer>
        <LeftQuizColumn questiontype={typeId}>
          {question.question ? (
            <Markdown
              enableTextSelection={!disabledHighlighting}
              fullMarkdown={fullMarkdown}
              highlights={highlights}
              onHighlightClick={handleRemoveHighlight}
              onMouseUp={handleAddHighlight}
              showCaption={reviewMode}
              text={text}
            />
          ) : null}
          {reviewMode && (
            <MockTestQuestionRelatedContent
              marksheetId={marksheetId}
              question={question}
            />
          )}
        </LeftQuizColumn>
        <RightQuizColumn questiontype={typeId}>
          <MockTestQuestionAnswerController
            completed={completed}
            currentMark={currentMark}
            questionNumber={questionIndex + 1}
            reviewMode={reviewMode}
            selectedChoiceId={selectedChoiceId}
            setSelectedId={setSelectedId}
            setUserAnswer={setUserAnswer}
          />
        </RightQuizColumn>
      </QuizContainer>
      {showEndQuizModal ? (
        <EndExerciseModal
          onClose={handleHideEndQuizModal}
          onSubmit={endMockTest}
          open={showEndQuizModal}
          submitLoading={loading}
        />
      ) : null}
    </>
  );
};
