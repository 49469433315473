import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { navigate, paths } from 'Router';
import { Button } from 'components/Button';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct } from 'utils';

const StyledCard = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: '402px',
  padding: spacing(22.5, 0),
}));

const IconWrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: palette.stroke.light,
  padding: spacing(2),
  borderRadius: spacing(2),
  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
  },
}));

const TitleBox = styled(Box)(({ theme: { typography, spacing } }) => ({
  ...typography.h5,
  margin: spacing(4, 0, 2, 0),
  textAlign: 'center',
}));

const MainText = styled(Box)(({ theme: { typography, spacing } }) => ({
  ...typography.body1,
  textAlign: 'center',
  margin: spacing(2, 0, 6, 0),
}));

const Wrapper = styled(Box)(({ theme: { spacing, palette } }) => ({
  borderTop: `1px solid ${palette.stroke.main}`,
  padding: spacing(4),
  display: 'flex',
  justifyContent: 'center',
}));

const NoDataWrapper = styled(Wrapper)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: '7px',
}));

interface ICardProps {
  icon: JSX.Element;
  text: string;
  title: string;
  isTrackerTab?: boolean;
}

const NoDataCard = ({ icon, text, title, isTrackerTab }: ICardProps) => {
  const { product } = usePlatform();
  const isStationProduct = isStationsProduct(product);

  const handleClick = () => {
    const path = isStationProduct ? paths.stations.root : paths.questions.root;
    navigate(path);
  };

  const CustomWrapper = isTrackerTab ? Wrapper : NoDataWrapper;

  return (
    <CustomWrapper>
      <StyledCard>
        <IconWrapper>{icon}</IconWrapper>
        <TitleBox>{title}</TitleBox>
        <MainText>{text}</MainText>
        <Button onClick={handleClick}>Start Exercise</Button>
      </StyledCard>
    </CustomWrapper>
  );
};

export default NoDataCard;
