import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import ExerciseDetailCard from './ExerciseDetailCard';
import ExerciseSummaryProgress, {
  ExerciseSummaryProgressProps,
} from './ExerciseSummaryProgress';
import ExerciseSummaryLayout from './ExerciseSummaryLayout';
import ExerciseSummaryHeader, {
  ExerciseSummaryHeaderProps,
} from './ExerciseSummaryHeader';
import ExerciseSummaryDetailsTitle, {
  ExerciseSummaryDetailsTitleProps,
} from './ExerciseSummaryDetailsTitle';
import ExerciseSummaryActions, {
  ExerciseSummaryActionsProps,
} from './ExerciseSummaryActions';
import ExerciseSummaryTable from './ExerciseSummaryTable';
import { Card } from 'components/Card';
import { SummaryTabs } from './types';
import ExerciseSummaryExportButton from './ExerciseSummaryExportButton';
import { useIsMobile } from 'hooks';

const TabsLevel = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TabsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',

  '> button': {
    padding: spacing(2, 4),
  },
}));

const Tab = styled(Button)(({ theme: { palette, spacing } }) => ({
  textTransform: 'uppercase',
  color: palette.secondary.main,
  display: 'flex',
  alignItems: 'center',

  ':hover': {
    background: 'none',
    color: palette.primary.main,
    cursor: 'pointer',
  },
  '&.active': {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
    borderRadius: 0,
  },
  ':focus': {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
    borderRadius: 0,
  },

  ' > svg': {
    marginRight: spacing(2),
  },
}));

const isTabActive = (clickedTab: string | undefined, tab: string) => {
  return clickedTab === tab ? 'active' : '';
};

interface ExerciseSummaryProps
  extends ExerciseSummaryProgressProps,
    ExerciseSummaryDetailsTitleProps,
    ExerciseSummaryHeaderProps,
    ExerciseSummaryActionsProps {
  loading?: boolean;
  duration?: string;
  tableData?: any; // TODO: Fix this in the future
  tabs?: { title: SummaryTabs; icon: JSX.Element }[];
  activeTab?: string;
  setActiveTab?: (tab: SummaryTabs) => void;
}

const ExerciseSummary = ({
  activeTab,
  setActiveTab,
  backToLobby,
  backToLobbyLabel,
  backToLobbyLoading,
  showExport,
  tableData,
  duration,
  loading,
  correctCount,
  correctLabel,
  detailsTitle,
  title,
  total,
  data,
  score,
  headerSubtitle,
  headerTitle,
  passed,
  notAnsweredLabel,
  incorrectCount,
  tabs,
}: ExerciseSummaryProps): JSX.Element => {
  const { isMobile } = useIsMobile();
  const handleSwitchTab = (tab: SummaryTabs) => () => {
    setActiveTab?.(tab);
  };

  return (
    <ExerciseSummaryLayout className={clsx({ withBackToLobby: backToLobby })}>
      <ExerciseSummaryHeader
        headerSubtitle={headerSubtitle}
        headerTitle={headerTitle}
      />
      {duration ? (
        <ExerciseDetailCard title="Duration" value={duration} />
      ) : null}
      {loading ? (
        <Skeleton height={128} variant="rectangular" />
      ) : (
        <ExerciseSummaryProgress
          correctCount={correctCount}
          correctLabel={correctLabel}
          data={data}
          incorrectCount={incorrectCount}
          notAnsweredLabel={notAnsweredLabel}
          passed={passed}
          score={score}
          title={title}
          total={total}
        />
      )}
      {loading ? (
        <Skeleton height={256} variant="rectangular" />
      ) : (
        <>
          <ExerciseSummaryDetailsTitle detailsTitle={detailsTitle} />
          <TabsLevel>
            <TabsContainer>
              {tabs?.map(tab => (
                <Tab
                  className={isTabActive(activeTab, tab.title)}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  key={tab.title}
                  onClick={handleSwitchTab(tab.title)}
                >
                  {tab.icon} {tab.title}
                </Tab>
              ))}
            </TabsContainer>
            {!isMobile && showExport ? <ExerciseSummaryExportButton /> : null}
          </TabsLevel>
          <Card>
            <ExerciseSummaryTable {...tableData} />
          </Card>
        </>
      )}
      {loading ? (
        <Skeleton height={80} variant="rectangular" />
      ) : (
        <>
          <ExerciseSummaryActions
            backToLobby={backToLobby}
            backToLobbyLabel={backToLobbyLabel}
            backToLobbyLoading={backToLobbyLoading}
            showExport={showExport}
          />
        </>
      )}
    </ExerciseSummaryLayout>
  );
};

export default ExerciseSummary;
