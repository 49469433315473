import { styled } from '@mui/material/styles';

import { Body } from 'components/Typography';

const FilterFallback = styled(Body)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '240px',
});

export default FilterFallback;
