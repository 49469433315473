import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import isNil from 'lodash/isNil';

import { StatusDot } from 'components/StatusDot/';
import { Tooltip } from 'components/Tooltip';
import InfoIcon from 'components/Icons/InfoIcon';
import { Body } from 'components/Typography';
import { ItemStatusLabel } from 'types';

export const STATION_PROGRESS_LABELS: CoreCellLabelProps[] = [
  {
    label: ItemStatusLabel.Performed,
    labelStatus: ItemStatusLabel.Confident,
  },
  {
    label: ItemStatusLabel.NotPerformed,
    labelStatus: ItemStatusLabel.NotAnswered,
  },
];

export const QUESTIONS_PROGRESS_LABELS: CoreCellLabelProps[] = [
  {
    label: ItemStatusLabel.Correct,
    labelStatus: ItemStatusLabel.Confident,
  },
  {
    label: ItemStatusLabel.Incorrect,
    labelStatus: ItemStatusLabel.ToLearn,
  },
  {
    label: ItemStatusLabel.NotAnswered,
    labelStatus: ItemStatusLabel.NotAnswered,
  },
];

export const FLASHCARDS_PROGRESS_LABELS: CoreCellLabelProps[] = [
  {
    label: ItemStatusLabel.Confident,
    labelStatus: ItemStatusLabel.Confident,
  },
  {
    label: ItemStatusLabel.ToReview,
    labelStatus: ItemStatusLabel.ToReview,
  },
  {
    label: ItemStatusLabel.ToLearn,
    labelStatus: ItemStatusLabel.ToLearn,
  },
  {
    label: ItemStatusLabel.NotAnswered,
    labelStatus: ItemStatusLabel.NotAnswered,
  },
];

const STATUS_LABELS: CoreCellLabelProps[] = [
  {
    label: ItemStatusLabel.Urgent,
    labelStatus: ItemStatusLabel.ToReview,
  },
  {
    label: ItemStatusLabel.Revising,
    labelStatus: ItemStatusLabel.Revising,
  },
];

export const READING_STATUS_LABELS: CoreCellLabelProps[] = [
  ...STATUS_LABELS,
  {
    label: ItemStatusLabel.Complete,
    labelStatus: ItemStatusLabel.Confident,
  },
  {
    label: ItemStatusLabel.Unread,
    labelStatus: ItemStatusLabel.NotAnswered,
  },
];

export const WATCH_STATUS_LABELS: CoreCellLabelProps[] = [
  ...STATUS_LABELS,
  {
    label: ItemStatusLabel.Watched,
    labelStatus: ItemStatusLabel.Confident,
  },
  {
    label: ItemStatusLabel.Unwatched,
    labelStatus: ItemStatusLabel.NotAnswered,
  },
];

const StyledBox = styled(Box)(
  ({ theme: { palette, spacing, typography, transitions } }) => ({
    ...typography.body2Medium,
    display: 'inline-flex',
    gap: spacing(2),
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      transition: transitions.create(['color']),
      color: palette.icon.main,

      '&:hover': {
        color: palette.icon.dark,
      },
    },
  })
);

export interface CellLabelProps {
  label?: string;
  labelStatus?: ItemStatusLabel;
  labelValue?: number;
  labelInfo?: string;
}

export type CoreCellLabelProps = Omit<CellLabelProps, 'labelInfo'>;

export const CellLabel = ({
  label,
  labelStatus,
  labelValue,
  labelInfo,
  ...props
}: CellLabelProps): JSX.Element | null =>
  label ? (
    <StyledBox component="span" {...props}>
      {labelStatus ? <StatusDot status={labelStatus} /> : null}
      <Body bold sx={{ whiteSpace: 'nowrap' }}>
        {label}
        {!isNil(labelValue) ? `: ${labelValue}` : ''}
      </Body>
      {labelInfo ? (
        <Tooltip placement="top" title={labelInfo}>
          <InfoIcon />
        </Tooltip>
      ) : null}
    </StyledBox>
  ) : null;
