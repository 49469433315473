import React from 'react';
import { ITodoMark } from '@quesmed/types-rn/models';

import {
  SummaryColumn,
  SummaryTableKey,
} from 'components/ExerciseSummary/types';
import { ExerciseSummaryModal } from 'components/ExerciseSummary';
import {
  CoreCellLabelProps,
  FLASHCARDS_PROGRESS_LABELS,
} from 'components/GridCell/CellLabel';
import { FlashcardScore } from 'types/qbank';
import { ExerciseType } from 'types';
import FlashcardScoreMark from './FlashcardScoreMark';
import { getScoreStatusClassname } from './getScoreStatusClassname';
import { getScoreLabel } from './getScoreLabel';

const [confidentLabel, toReviewLabel, toLearnLabel, notAnsweredLabel] =
  FLASHCARDS_PROGRESS_LABELS;

export const checkResult = (marks?: ITodoMark[]) => {
  let confident = 0;
  let learn = 0;
  let review = 0;
  let notAnswered = 0;

  if (!marks) {
    return { confident, learn, review, notAnswered, total: 0 };
  }

  marks.forEach(({ score }) => {
    switch (score) {
      case FlashcardScore.NotAtAll:
      case FlashcardScore.Slightly:
        learn += 1;
        break;
      case FlashcardScore.Moderately:
        review += 1;
        break;
      case FlashcardScore.KnowWell:
      case FlashcardScore.Perfectly:
        confident += 1;
        break;
      default:
        notAnswered += 1;
        break;
    }
  });

  return { confident, learn, review, notAnswered, total: marks.length };
};

const columns: SummaryColumn<ITodoMark>[] = [
  {
    key: SummaryTableKey.Number,
    align: 'left',
    label: SummaryTableKey.Number,
    formatter: (_, number) => `Flashcard ${number}`,
    type: 'default',
  },
  {
    key: SummaryTableKey.Content,
    align: 'left',
    label: SummaryTableKey.Content,
    formatter: ({ card }) => (card ? card.question : ''),
    type: 'default',
  },
  {
    key: SummaryTableKey.Topic,
    align: 'left',
    label: SummaryTableKey.Topic,
    formatter: ({ card }) => card?.concept?.topic?.name || 'Topic name',
    type: 'default',
  },
  {
    key: SummaryTableKey.Concept,
    align: 'left',
    label: SummaryTableKey.Concept,
    formatter: ({ card }) => card?.concept?.name || 'Concept name',
    type: 'default',
  },
  {
    key: SummaryTableKey.Result,
    align: 'left',
    label: SummaryTableKey.Result,
    resultFormatter: ({ score }) => {
      const status = getScoreStatusClassname(score);
      const scoreLabel = getScoreLabel(score);

      return {
        icon: <FlashcardScoreMark score={score} />,
        status,
        label: scoreLabel,
      };
    },
    type: 'default',
  },
];

interface FlashcardsSummaryModalProps {
  data: ITodoMark[];
  onClose: () => void;
  open: boolean;
  title: string;
}

const FlashcardsSummaryModal = ({
  data,
  onClose,
  open,
  title,
}: FlashcardsSummaryModalProps): JSX.Element => {
  const { confident, review, learn, notAnswered, total } = checkResult(data);
  const summary = `${ExerciseType.Flashcards} answered: ${
    confident + learn + review
  } out of ${total}`;

  const labels: CoreCellLabelProps[] = [
    {
      ...confidentLabel,
      labelValue: confident,
    },
    {
      ...toReviewLabel,
      labelValue: review,
    },
    {
      ...toLearnLabel,
      labelValue: learn,
    },
    {
      ...notAnsweredLabel,
      labelValue: notAnswered,
    },
  ];

  return (
    <ExerciseSummaryModal
      columns={columns}
      data={data}
      labels={labels}
      onClose={onClose}
      open={open}
      summary={summary}
      title={title}
    />
  );
};

export default FlashcardsSummaryModal;
