import {
  EOsceStage,
  EOsceTimerState,
  IOsceMarksheet,
  IOsceMarksheetTimer,
} from '@quesmed/types-rn/models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { formatSeconds, localStorageTyped } from 'utils';
import {
  setTimerDataValues,
  TimerDataValues,
} from 'pages/Stations/clalcTotalTime';
import { useStationTimerState } from 'pages/Stations/useStationTimerState';
import { SoloTimerData } from 'types';

const setInitialTimerValue = (
  totalTime: number,
  id: number,
  timerState: SoloTimerData | null
): number => {
  if (!timerState || timerState.id !== id) {
    return totalTime;
  }

  if (timerState.paused) {
    return timerState.timerValue;
  }

  return Math.max(timerState.timerValue - (dayjs().unix() - timerState.now), 0);
};

const setStateAndStage = (
  timerValue: number,
  timerDataValues: TimerDataValues,
  marksheet: IOsceMarksheet,
  isPaused: boolean
): { stage: EOsceStage; state: EOsceTimerState } => {
  const { completed } = marksheet;
  const { readingTimeSec, stationTimeSec, totalTimeNumber } = timerDataValues;
  const currentTime = totalTimeNumber - timerValue;
  const readingEndTime = readingTimeSec + 1;
  const stationEndTime = readingEndTime + stationTimeSec;
  let stage: EOsceStage = EOsceStage.READING;

  let state: EOsceTimerState =
    isPaused || marksheet.pausedAt
      ? EOsceTimerState.PAUSE
      : EOsceTimerState.START;

  switch (true) {
    case currentTime === 0:
    case 0 < currentTime && currentTime < readingEndTime:
      stage = EOsceStage.READING;
      break;
    case currentTime === readingEndTime:
    case readingEndTime < currentTime && currentTime < stationEndTime:
      stage = EOsceStage.STATION;
      break;
    case currentTime === stationEndTime:
    case stationEndTime < currentTime && currentTime < totalTimeNumber:
      stage = EOsceStage.FEEDBACK;
      break;
    case currentTime >= totalTimeNumber:
      stage = EOsceStage.FEEDBACK;
      state = EOsceTimerState.COMPLETED;
      break;
    default:
      stage = EOsceStage.READING;
  }

  if (completed) {
    state = EOsceTimerState.COMPLETED;
  }

  return { state, stage };
};

const checkIfTimerPausedOnInit = (
  id: number,
  timerState: SoloTimerData | null
): boolean => timerState?.id === id && timerState.paused;

const { getItem, setItem } = localStorageTyped<SoloTimerData>('soloTimerValue');

const useStationTimer = (
  osceMarksheet: IOsceMarksheet,
  reviewMode: boolean,
  isSample?: boolean
): IOsceMarksheetTimer | undefined => {
  const { id } = osceMarksheet;

  const timerDataValues = useMemo(
    () => setTimerDataValues(osceMarksheet, isSample),

    [osceMarksheet, isSample]
  );

  const { totalTimeString: totalStationTime, totalTimeNumber } =
    timerDataValues;

  const { pauseAction: shouldBePaused, setPauseAction } =
    useStationTimerState();
  const [timerPayload, setTimerPayload] = useState<
    IOsceMarksheetTimer | undefined
  >();
  const [timerValue, setTimerValue] = useState(
    setInitialTimerValue(totalTimeNumber, osceMarksheet.id, getItem())
  );
  const countInterval = useRef(0);
  const { sessionId } = useParams();

  const pause = useCallback(
    (countdownEnd = false) => {
      clearInterval(countInterval.current);
      countInterval.current = 0;
      if (countdownEnd) {
        setItem({ id, timerValue: 0, paused: true, now: dayjs().unix() });
      } else {
        setItem({ id, timerValue, paused: true, now: dayjs().unix() });
      }
    },
    [id, timerValue]
  );

  const play = useCallback(() => {
    if (countInterval.current === 0 && !reviewMode) {
      countInterval.current = window.setInterval(() => {
        setTimerValue(prevTimerValue => prevTimerValue - 1);
      }, 1000);
    }
  }, [reviewMode]);

  const reset = () => {
    clearInterval(countInterval.current);
    countInterval.current = 0;
  };

  useEffect(() => {
    if (timerValue === 0) {
      pause(true);
    } else {
      const timer = getItem();

      if (timer && timer.id === id) {
        setItem({ ...timer, timerValue, now: dayjs().unix() });
      } else {
        setItem({
          id,
          timerValue,
          paused: shouldBePaused,
          now: dayjs().unix(),
        });
      }
    }
  }, [id, pause, shouldBePaused, timerValue]);

  useEffect(() => {
    setTimerPayload({
      timeRemaining: formatSeconds(timerValue),
      stageTime: '',
      totalStationTime,
      osceMarksheetId: id,
      sessionId: sessionId || '',
      ...setStateAndStage(
        timerValue,
        timerDataValues,
        osceMarksheet,
        shouldBePaused
      ),
    });
  }, [
    timerValue,
    shouldBePaused,
    osceMarksheet,
    totalStationTime,
    id,
    timerDataValues,
    sessionId,
  ]);

  useEffect(() => {
    setTimerValue(setInitialTimerValue(totalTimeNumber, id, getItem()));
  }, [id, totalTimeNumber]);

  useEffect(() => {
    setPauseAction(checkIfTimerPausedOnInit(id, getItem()));

    return reset;
  }, [id, setPauseAction]);

  useEffect(() => {
    const timer = getItem();
    if (timer && timer.id === id) {
      setItem({ ...timer, paused: shouldBePaused });
    }

    if (shouldBePaused) {
      pause();
    } else {
      play();
    }
  }, [id, pause, play, shouldBePaused]);

  return timerPayload;
};

export default useStationTimer;
