import React from 'react';
import { IPicture } from '@quesmed/types-rn/models';

import { Markdown } from 'components/Markdown';
import { parseFullText } from 'utils';
import { ExpandableSection } from 'components/ExpandableSection';
import { QuesbookBrief } from 'types';
import { ReadingBriefTitle } from 'components/LearningMaterials';

const StationTabBriefs = ({
  content,
  expandedAll,
  pictures,
}: {
  content: QuesbookBrief[];
  expandedAll: boolean;
  pictures?: IPicture[];
}) => (
  <>
    {content.map(({ title = 'Brief', content: parsedContent }) =>
      parsedContent ? (
        <ExpandableSection
          expanded={expandedAll}
          key={title}
          size="small"
          title={<ReadingBriefTitle title={title} />}
        >
          <Markdown
            markdownStylesProps={{
              bottomOffset: false,
            }}
            text={parseFullText(parsedContent, pictures)}
          />
        </ExpandableSection>
      ) : null
    )}
  </>
);

export default StationTabBriefs;
