import React from 'react';
import { Control } from 'react-hook-form';

import OpenAnswerField from './OpenAnswerField';
import { InputOnChangeHandler } from 'types';
import { ExpandableSection } from 'components/ExpandableSection';
import { PrescriptionField } from '../types';
import { getAnswerStatus } from '../PrescriptionAnswer/getAnswerStatus';

interface OpenAnswerSectionProps {
  isTestFinished: boolean;
  control?: Control<PrescriptionField>;
  onChange?: InputOnChangeHandler;
  units?: string;
  defaultValue?: number | string;
  preview?: boolean;
  correct?: boolean;
}

const OpenAnswerSection = ({
  isTestFinished,
  control,
  onChange,
  units,
  defaultValue,
  correct,
  preview,
}: OpenAnswerSectionProps): JSX.Element => {
  const variant = isTestFinished ? getAnswerStatus(correct) : 'info';

  return (
    <ExpandableSection
      disableContentBackground
      expandable={!isTestFinished}
      expanded
      size="medium"
      title="Prescription form"
      variant={preview ? 'normal' : variant}
    >
      <OpenAnswerField
        control={control}
        defaultValue={parseFloat(defaultValue as string)}
        display={isTestFinished}
        fullWidth
        name={'dose'}
        onChange={onChange}
        type="number"
        units={units}
      />
    </ExpandableSection>
  );
};

export default OpenAnswerSection;
