import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Modal, ModalProps } from 'components/Modal';
import { Markdown } from 'components/Markdown';
import { StationRoleName } from 'types';

interface InstructionsModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  instructions: string;
  role?: StationRoleName;
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(1),
  marginBottom: spacing(6),
  gap: spacing(6),
}));

export interface StationsTimerFormInput {
  readingTime: number;
  stationTime: number;
  feedbackTime: number;
}

const InstructionsModal = ({
  role = StationRoleName.Candidate,
  instructions,
  open,
  onClose,
}: InstructionsModalProps): JSX.Element => {
  return (
    <Modal
      maxWidth="md"
      noPaddingY
      onClose={onClose}
      open={open}
      showCloseButton
      sizeVariant="md"
      title={`${role} instructions`}
    >
      <StyledBox>
        <Markdown text={instructions} />
      </StyledBox>
    </Modal>
  );
};

export default InstructionsModal;
