import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Button } from 'components/Button';
import { DotsVerticalIcon, FlashcardsIcon } from 'components/Icons';
import { Popper } from 'components/Popper';
import { useHover, usePrevious } from 'hooks';
import { ListItemButton } from 'components/List';
import DailyFlashcardsLimitModal from './DailyFlashcardsLimitModal';
import useDailyFlashcardLimit from './useDailyFlashcardLimit';
import useDailyFlashcards from './useDailyFlashcards';
import SelectExerciseModal from './SelectExerciseModal';
import { paths } from 'Router';
import { useFlashcardsQuery } from 'pages/Flashacards/hooks';
import { FlashcardsSummaryModal } from 'pages/Flashacards';
import {
  WidgetCard,
  WidgetCardType,
} from 'pages/dashboard/components/WidgetCard';
import { formatDate } from 'utils';
import useRefreshDailyTask from './useRefreshDailyTask';
import { useSnackbar } from 'components/Snackbar';
import { ProgressLineChart } from 'components/ProgressLineChart';
import { ItemStatusLabel } from 'types';
import { Body } from 'components/Typography';

const SkeletonWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(8),
  marginTop: spacing(8),
}));

const Content = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flex: 1,
  textAlign: 'center',
  margin: spacing(8, 0),

  [breakpoints.up('lg')]: {
    margin: spacing(8, 0, 6),
  },
}));

const PopperContainer = styled(Box)({
  cursor: 'pointer',
});

const ProgressLine = styled(Box)({
  width: '100%',
});

const ProgressInfo = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const SecondaryBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const COPY = {
  button: {
    new: 'Start Exercises',
    active: 'Start Daily Flashcards',
    refresh: 'Refresh Daily Flashcards',
  },
};

const DailyFlashcards = (): JSX.Element => {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const [isDailyFlashcardsLimitModalOpen, setIsDailyFlashcardsLimitModalOpen] =
    useState<boolean>(false);
  const [isSelectExerciseModalOpen, setIsSelectExerciseModalOpen] =
    useState<boolean>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { dailyFlashcardLimit, loading: loadingDailyFlashcardLimit } =
    useDailyFlashcardLimit();
  const { dailyFeed, loading: dailyFeedLoading } = useDailyFlashcards();
  const { flashcards, loading: loadingFlashcards } = useFlashcardsQuery(
    dailyFeed?.id
  );
  const { refreshDailyTask, loading: refreshLoading } = useRefreshDailyTask();
  const { marks = [] } = flashcards || {};

  const [popperRef, popperRefValue] = useHover<HTMLButtonElement>();
  const popperRefPrevValue = usePrevious(popperRefValue);

  const handleOpenMenu = () => {
    setIsPopperOpen(true);
  };

  const handleCloseMenu = () => {
    setIsPopperOpen(false);
  };

  const handleCloseModal = () => {
    setIsDailyFlashcardsLimitModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsDailyFlashcardsLimitModalOpen(true);
  };

  const answeredCardsCount = useMemo(
    () =>
      dailyFeed?.marks.reduce((prev, curr) => {
        if (curr.score !== null) {
          return prev + 1;
        }

        return prev;
      }, 0) || 0,
    [dailyFeed?.marks]
  );

  const allCardsAreAnswered =
    answeredCardsCount === marks.length && marks.length > 0;

  const handleButtonClick = () => {
    if (allCardsAreAnswered) {
      return refreshDailyTask();
    }
    if (!dailyFeed) {
      return setIsSelectExerciseModalOpen(true);
    }
    navigate(`${paths.flashcards.root}/quiz/${dailyFeed.id}`);
  };

  const handleShowDetails = () => {
    if (dailyFeed) {
      setIsDetailsModalOpen(true);
    } else {
      enqueueSnackbar('No details available. Start exercise first...');
    }
  };

  const handleCloseDetails = () => {
    setIsDetailsModalOpen(false);
  };

  const handleSelectExerciseModalClose = () => {
    setIsSelectExerciseModalOpen(false);
  };

  useEffect(() => {
    if (
      (isPopperOpen && popperRefPrevValue && !popperRefValue) ||
      isDailyFlashcardsLimitModalOpen ||
      isDetailsModalOpen
    ) {
      handleCloseMenu();
    }
  }, [
    isPopperOpen,
    isDailyFlashcardsLimitModalOpen,
    popperRefPrevValue,
    popperRefValue,
    isDetailsModalOpen,
  ]);

  const getButtonLabel = () => {
    if (!dailyFeed) {
      return COPY.button.new;
    }
    if (allCardsAreAnswered) {
      return COPY.button.refresh;
    }

    return COPY.button.active;
  };

  const date = formatDate(new Date());

  const loading =
    dailyFeedLoading || loadingDailyFlashcardLimit || loadingFlashcards;

  return (
    <>
      <WidgetCard
        cardIcon={<FlashcardsIcon />}
        content={
          <>
            <Content>
              {loading ? (
                <Skeleton height={140} variant="rectangular" width={220} />
              ) : (
                <ProgressLine>
                  <ProgressLineChart
                    data={[
                      {
                        type: ItemStatusLabel.Answered,
                        value: answeredCardsCount,
                        label: ItemStatusLabel.Answered,
                      },
                    ]}
                    performanceWidget
                    total={marks.length}
                  />
                  <ProgressInfo>
                    <SecondaryBody small>
                      {answeredCardsCount} total answered
                    </SecondaryBody>
                    <SecondaryBody small>out of {marks.length}</SecondaryBody>
                  </ProgressInfo>
                </ProgressLine>
              )}
            </Content>
            <Button
              disabled={refreshLoading}
              fullWidth
              onClick={handleButtonClick}
              secondary
              size="medium"
            >
              {getButtonLabel()}
            </Button>
          </>
        }
        headerAction={
          <PopperContainer onClick={handleOpenMenu} ref={popperRef}>
            <DotsVerticalIcon />
            <Popper
              anchorEl={popperRef.current}
              disablePortal
              open={isPopperOpen}
              paperSx={({ spacing }) => ({
                width: '200px',
                padding: spacing(2),
              })}
            >
              <ListItemButton expanded={1} nested={0} onClick={handleModalOpen}>
                Edit cards limit
              </ListItemButton>
              <ListItemButton
                expanded={1}
                nested={0}
                onClick={handleShowDetails}
              >
                Show details
              </ListItemButton>
            </Popper>
          </PopperContainer>
        }
        loading={loading}
        loadingSkeleton={
          <SkeletonWrapper>
            <Skeleton height={48} variant="rounded" width="100%" />
            <Skeleton height={40} variant="rounded" width="100%" />
          </SkeletonWrapper>
        }
        title="Daily flashcards"
        type={WidgetCardType.DailyFlashcards}
      />
      {isDailyFlashcardsLimitModalOpen ? (
        <DailyFlashcardsLimitModal
          flashcardsLimit={dailyFlashcardLimit}
          onClose={handleCloseModal}
          open={isDailyFlashcardsLimitModalOpen}
        />
      ) : null}
      <SelectExerciseModal
        onClose={handleSelectExerciseModalClose}
        open={isSelectExerciseModalOpen}
      />
      {dailyFeed && isDetailsModalOpen ? (
        <FlashcardsSummaryModal
          data={marks}
          onClose={handleCloseDetails}
          open={isDetailsModalOpen}
          title={`Flashcards summary (${date})`}
        />
      ) : null}
    </>
  );
};

export default DailyFlashcards;
