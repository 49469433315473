import { EProductType, IOsceStation, IVideo } from '@quesmed/types-rn/models';

import { Nullable, ToggleOptions } from 'types';
import { keys } from 'utils';
import { PACES_TYPE_ORDER, STATION_TYPE_ORDER } from '../constants';
import { StationCategories, StationMap } from '../types';
import { getVideos } from './getVideos';

export const getStationsLearningData = (
  sourceStations: Nullable<IOsceStation[]>,
  withVideos = false,
  isPaces = false
): [
  Nullable<StationMap>,
  Nullable<StationCategories[]>,
  ToggleOptions<EProductType>,
  Nullable<IVideo[]>
] => {
  if (!sourceStations) {
    return [null, null, [], null];
  }
  const sortedStations = {} as StationMap;
  const entitlements = {} as {
    [key in EProductType]: string;
  };
  const allVideos = [] as IVideo[];

  sourceStations.forEach(station => {
    const { entitlement } = station;
    const { id, name } = entitlement || {};

    if (id) {
      if (sortedStations[id]) {
        sortedStations[id].push(station);
      } else {
        sortedStations[id] = [station];
      }

      if (!entitlements[id]) {
        entitlements[id] = name;
      }
    }
  });

  const stationCategories: StationCategories[] = [];

  keys(sortedStations).forEach(key => {
    if (withVideos) {
      const sortedVideos = isPaces
        ? getVideos(sortedStations[key])?.sort(
            (a, b) => Number(a.id) - Number(b.id)
          )
        : getVideos(sortedStations[key]);
      const videos = withVideos && sortedVideos;

      if (videos?.length) {
        allVideos.push(...videos);
        stationCategories.push({
          id: key,
          name: entitlements[key],
          stations: sortedStations[key],
          videos,
        });
      }
    } else if (sortedStations[key].length) {
      stationCategories.push({
        id: key,
        name: entitlements[key],
        stations: sortedStations[key],
      });
    }
  });

  const pacesCategories = PACES_TYPE_ORDER.filter(
    key => sortedStations[key]?.length
  ).map(category => ({
    value: Number(category),
    label: entitlements[category],
  }));
  const filteredStationCategories = STATION_TYPE_ORDER.filter(
    key => sortedStations[key]?.length
  ).map(category => ({
    value: Number(category),
    label: entitlements[category],
  }));

  return [
    sortedStations,
    stationCategories,
    isPaces ? pacesCategories : filteredStationCategories,
    withVideos ? allVideos : null,
  ];
};
