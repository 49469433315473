import { EOsceRoles, EOsceStage } from '@quesmed/types-rn/models';

export const STUDY_STAGES = {
  [EOsceStage.READING]: 'Reading',
  [EOsceStage.STATION]: 'Station',
  [EOsceStage.FEEDBACK]: 'Feedback',
};

export const TABS = {
  CANDIDATE: 'Candidate',
  ACTOR: 'Actor',
  EXAMINER: 'Examiner',
  MARKSCHEME: 'Markscheme',
  WALKTHROUGH: 'Walkthrough Guide',
};

export const ALL_TABS = [
  TABS.EXAMINER,
  TABS.CANDIDATE,
  TABS.ACTOR,
  TABS.MARKSCHEME,
  TABS.WALKTHROUGH,
];

export const TABS_MAP = {
  [EOsceRoles.EXAMINER]: {
    [EOsceStage.READING]: ALL_TABS,
    [EOsceStage.STATION]: ALL_TABS,
    [EOsceStage.FEEDBACK]: ALL_TABS,
  },
  [EOsceRoles.CANDIDATE]: {
    [EOsceStage.READING]: [TABS.CANDIDATE],
    [EOsceStage.STATION]: [TABS.CANDIDATE],
    [EOsceStage.FEEDBACK]: ALL_TABS,
  },
  [EOsceRoles.ACTOR]: {
    [EOsceStage.READING]: ALL_TABS,
    [EOsceStage.STATION]: ALL_TABS,
    [EOsceStage.FEEDBACK]: ALL_TABS,
  },
  [EOsceRoles.OBSERVER]: {
    [EOsceStage.READING]: ALL_TABS,
    [EOsceStage.STATION]: ALL_TABS,
    [EOsceStage.FEEDBACK]: ALL_TABS,
  },
};

export const SOLO_TABS = [
  TABS.CANDIDATE,
  TABS.ACTOR,
  TABS.EXAMINER,
  TABS.MARKSCHEME,
  TABS.WALKTHROUGH,
];

export const FIELDS_TO_TABS = {
  [TABS.CANDIDATE]: 'candidateBrief',
  [TABS.ACTOR]: 'actorBrief',
  [TABS.EXAMINER]: 'examinerBrief',
  [TABS.MARKSCHEME]: 'marks',
  [TABS.WALKTHROUGH]: 'explanation',
};
