import { useMutation } from '@apollo/client';
import {
  ISelectOsceMarksheetStationData,
  ISelectOsceMarksheetStationVar,
  SELECT_OSCE_STATION,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useSelectStationMutation = () => {
  const [selectStation] = useMutation<
    ISelectOsceMarksheetStationData,
    ISelectOsceMarksheetStationVar
  >(SELECT_OSCE_STATION);

  return {
    selectStation,
  };
};

export default useSelectStationMutation;
