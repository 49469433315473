import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { EEntitlementType, EProductType } from '@quesmed/types-rn/models';

import {
  CheckboxState,
  ExerciseType,
  ExtendedTopic,
  GameType,
  Nullable,
  PreBuildData,
  SelectionBarLabels,
  SelectionState,
} from 'types';
import {
  useIsMobile,
  useLeaveExerciseState,
  useTopicQuery,
  useTopicSelection,
} from 'hooks';
import { calcAllQuestions, calcQuestions, getConcepts, round } from 'utils';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import {
  BottomSelectionBar,
  setBottomBarOffset,
} from 'components/BottomSelectionBar';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import {
  FLASHCARDS_PROGRESS_LABELS,
  KeyField,
  QuestionColumns,
  SelectionColumn,
  SelectionInputCellLabel,
  TopicQuizBuilder,
} from 'components/ExerciseBuilder';
import {
  BadgeScore,
  ProgressScoreBadgeProps,
} from 'components/ProgressScoreBadge';
import {
  ProgressCorrectIcon,
  ProgressInitialIcon,
  ProgressStartedIcon,
} from 'components/Icons';
import FlashCardsPreBuildModal from './FlashcardsPreBuildModal';
import { MAX_SCORE, TRANSITION_TIMEOUT } from 'config/constants';
import { setFlashcardsProgressData } from './setFlashcardsProgressData';
import { useFlashcardLeavingBlockade } from './hooks';
import FlashcardsLeaveModal from './FlashcardsLeaveModal';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import {
  useCreatePresetMutation,
  useEditPresetMutation,
} from 'components/PresetsBuilder/hooks';
import { Modal } from 'components/Modal';
import { TextField } from 'components/TextField';
import { Body } from 'components/Typography';
import { editSelection, extractConceptIds } from 'utils/marksheet';
import { useDemo } from 'Auth';
import { usePlatform } from 'context/PlatformState';

const NameInputField = styled(TextField)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

const checkBoxLabelFormatter = (selectionState: SelectionState) => {
  const Label = (
    { id, name, concepts, totalCards }: ExtendedTopic,
    parentId?: number
  ): JSX.Element => {
    let counts = [0, 0];
    const selectedConceptsWithinTopic =
      selectionState.get(Number(parentId || id))?.selectedConceptsIds ||
      new Set();

    if (parentId) {
      const total = totalCards ?? 0;
      counts = [selectedConceptsWithinTopic.has(Number(id)) ? total : 0, total];
    } else {
      counts = calcQuestions(
        selectedConceptsWithinTopic,
        GameType.FLASHCARDS,
        concepts
      );
    }

    const [count, total] = counts;

    return <SelectionInputCellLabel count={count} name={name} total={total} />;
  };

  return Label;
};

const notAnsweresFormatter = ({
  greenCards,
  yellowCards,
  redCards,
  totalCards,
}: ExtendedTopic) =>
  totalCards
    ? totalCards - (greenCards ?? 0) - (yellowCards ?? 0) - (redCards ?? 0)
    : 0;

const progressFormatter = ({
  greenCards,
  redCards,
  yellowCards,
  name,
  totalCards,
}: ExtendedTopic): ProgressLineChartProps => ({
  title: name,
  data: setFlashcardsProgressData(greenCards, redCards, yellowCards),
  total: totalCards,
});

const scoreFormatter = ({
  greenCards,
  totalCards,
}: ExtendedTopic): ProgressScoreBadgeProps => {
  const total = totalCards ?? 0;
  const score = total ? round(((greenCards ?? 0) * MAX_SCORE) / total) : 0;
  let status = BadgeScore.NotStarted;
  let icon = <ProgressInitialIcon />;
  if (score === MAX_SCORE) {
    status = BadgeScore.Correct;
    icon = <ProgressCorrectIcon />;
  } else if (score > 0) {
    status = BadgeScore.InProgress;
    icon = <ProgressStartedIcon />;
  }

  return { score, icon, status };
};

const FlashcardsQuizBuilder = (): JSX.Element => {
  const { loading, topics, topicMap, categories } = useTopicQuery({
    gameType: GameType.FLASHCARDS,
  });
  const [preBuildSelection, setPrebuildSelection] = useState<PreBuildData>();
  const [showPrebuildModal, setShowPrebuildModal] = useState(false);
  const [showSelectionBar, setShowSelectionBar] = useState(false);
  const [showSavePresetModal, setShowSavePresetModal] = useState(false);
  const [showEditPresetModal, setShowEditPresetModal] = useState(false);
  const [presetName, setPresetName] = useState<string>('');
  const [selectionCount, setSelectionCount] = useState<[number, number]>([
    0, 0,
  ]);
  const [displayTopics, setDisplayTopics] =
    useState<Nullable<ExtendedTopic[]>>(null);
  const [activeTopics, setActiveTopics] =
    useState<Nullable<ExtendedTopic[]>>(null);
  const isDemo = useDemo();
  const { product } = usePlatform();

  const showSaveAsPreset = !isDemo && product === EProductType.QBANK;

  const { preset, isPresetEditOpen, setIsPresetEditOpen } =
    usePresetGlobalState();
  const { id: presetId, name, entitlementId } = preset;

  const { open } = useLeaveExerciseState();

  const { isMobile } = useIsMobile();

  const sharedSelection =
    preset && isPresetEditOpen
      ? editSelection(preset, activeTopics)
      : undefined;

  const selection = useTopicSelection({
    topics: activeTopics,
    displayTopics,
    sharedSelection,
  });

  const { allSelected, deselectAll, selectionState } = selection;

  const [questionCount] = selectionCount;

  const selected = extractConceptIds(selectionState);

  const { createPreset } = useCreatePresetMutation(
    presetName,
    selected.entitlementId,
    selected.conceptIdsArray
  );

  const { editPreset } = useEditPresetMutation(presetId);

  const handleUnselectAll = () => {
    if (allSelected !== CheckboxState.UNCHECKED) {
      deselectAll(selectionState);
    }
  };

  useFlashcardLeavingBlockade({
    shouldBlock: showSelectionBar,
    ignoreBlockade: !showSelectionBar,
  });

  const handlePresetNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPresetName(event.target.value);
  };

  const handleShowSavePresetModal = () => {
    setShowSavePresetModal(true);
  };

  const handleCloseSavePresetModal = () => {
    setShowSavePresetModal(false);
    setPresetName('');
  };

  const handleSubmitSavePreset = async () => {
    await createPreset();
    handleUnselectAll();
    setPresetName('');
    setShowSavePresetModal(false);
  };

  const handleShowPrebuildModal = () => {
    setShowPrebuildModal(true);
  };

  const handleClosePrebuildModal = () => {
    setShowPrebuildModal(false);
  };

  useEffect(() => {
    if (allSelected !== CheckboxState.UNCHECKED || isDemo) {
      setPrebuildSelection(getConcepts(selectionState));
    }
  }, [allSelected, selectionState, isDemo]);

  useEffect(() => {
    let timeout = 0;
    const count = calcAllQuestions(
      selectionState,
      topicMap,
      GameType.FLASHCARDS
    );

    if (count[0]) {
      setShowSelectionBar(true);
      setSelectionCount(count);
    } else {
      setShowSelectionBar(false);
      timeout = window.setTimeout(() => {
        setSelectionCount(count);
      }, TRANSITION_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [selectionState, topicMap]);

  const handleOpenEditModal = () => {
    setShowEditPresetModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditPresetModal(false);
  };

  const handleSubmitEditPrset = async () => {
    await editPreset({
      name,
      entitlementId,
      conceptIds: selected.conceptIdsArray,
    });
    setShowEditPresetModal(false);
    setIsPresetEditOpen(false);
  };

  const [
    detailsColumns,
    overviewColumns,
  ]: SelectionColumn<QuestionColumns>[][] = useMemo(
    () => [
      [
        { align: 'center', key: KeyField.Chevron, type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'topics',
        },
        {
          align: 'left',
          key: 'greenCards',
          ...FLASHCARDS_PROGRESS_LABELS[0],
          type: 'default',
        },
        {
          align: 'left',
          key: 'yellowCards',
          ...FLASHCARDS_PROGRESS_LABELS[1],
          type: 'default',
        },
        {
          align: 'left',
          key: 'redCards',
          ...FLASHCARDS_PROGRESS_LABELS[2],
          type: 'default',
        },
        {
          align: 'left',
          formatter: notAnsweresFormatter,
          key: KeyField.NotAnswered,
          ...FLASHCARDS_PROGRESS_LABELS[3],
          type: 'default',
        },
        {
          align: 'left',
          scoreFormatter: scoreFormatter,
          key: KeyField.Score,
          label: 'Score',
          type: 'default',
        },
      ],
      [
        { align: 'center', key: KeyField.Chevron, type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'topics',
        },
        {
          align: 'left',
          key: KeyField.Progress,
          label: 'Progress',
          type: 'bar',
          progressLabels: FLASHCARDS_PROGRESS_LABELS,
          progressFormatter,
        },
      ],
    ],
    [selectionState]
  );

  const selectedItemsInfo = isDemo
    ? 'Start exercise with free flashcards'
    : `${questionCount} flashcards selected`;

  const [activeTopic] = activeTopics || [];
  const { entitlement } = activeTopic || {};

  return (
    <>
      <ExerciseLayout
        bottomOffsetVariant={setBottomBarOffset(showSelectionBar, isMobile)}
        exerciseContent={
          <>
            <PreLaunchLayout selection={showSelectionBar || isDemo ? 1 : 0}>
              <TopicQuizBuilder
                categories={categories}
                detailsColumns={detailsColumns}
                displayTopics={displayTopics}
                exerciseType={ExerciseType.Flashcards}
                loading={loading}
                nestedItemsKey="concepts"
                overviewColumns={overviewColumns}
                searchLabel="Search by topic and concept"
                selectedItemsInfo={selectedItemsInfo}
                setActiveTopics={setActiveTopics}
                setDisplayTopics={setDisplayTopics}
                solo
                topics={topics}
                {...selection}
              />
            </PreLaunchLayout>
            <Fade in={showSelectionBar || isDemo} unmountOnExit>
              {isPresetEditOpen ? (
                <BottomSelectionBar
                  continueLabel={SelectionBarLabels.SaveChanges}
                  onContinue={handleOpenEditModal}
                  onUnselect={handleUnselectAll}
                  selectedItemsLabel={selectedItemsInfo}
                  withoutPanel
                />
              ) : (
                <BottomSelectionBar
                  onContinue={handleShowPrebuildModal}
                  onSavePreset={
                    showSaveAsPreset ? handleShowSavePresetModal : undefined
                  }
                  onUnselect={isDemo ? undefined : handleUnselectAll}
                  saveAsPreset={showSaveAsPreset}
                  savePresetLabel={SelectionBarLabels.SaveAsPreset}
                  selectedItemsLabel={selectedItemsInfo}
                  withoutPanel
                />
              )}
            </Fade>
          </>
        }
        withoutPanel
      />
      {showPrebuildModal ? (
        <FlashCardsPreBuildModal
          entitlementId={entitlement?.id as unknown as EEntitlementType}
          onClose={handleClosePrebuildModal}
          open={showPrebuildModal}
          selection={preBuildSelection}
          source={isDemo ? 'demo' : ''}
        />
      ) : null}
      {showSavePresetModal ? (
        <Modal
          cancelLabel="Cancel"
          noPaddingY
          onClose={handleCloseSavePresetModal}
          onSubmit={handleSubmitSavePreset}
          open={showSavePresetModal}
          showCloseButton
          sizeVariant="md"
          submitLabel="Save as preset"
          title="Save as preset"
        >
          <NameInputField
            id="outlined-required"
            label="Preset name"
            name="preset-name"
            onChange={handlePresetNameChange}
            required
            value={presetName}
          />
        </Modal>
      ) : null}
      {showEditPresetModal ? (
        <Modal
          cancelLabel="Cancel"
          noPaddingY
          onClose={handleCloseEditModal}
          onSubmit={handleSubmitEditPrset}
          open={showEditPresetModal}
          showCloseButton
          sizeVariant="md"
          submitLabel="Confirm"
          title="Confirm changes"
        >
          <Body component="p">
            You made some changes to this preset. Are you sure you want to save
            them? Saving will replace the existing preset with your changes.
          </Body>
        </Modal>
      ) : null}
      {open ? <FlashcardsLeaveModal discard open={open} /> : null}
    </>
  );
};

export default FlashcardsQuizBuilder;
