import React from 'react';
import Box from '@mui/material/Box';

import { Modal, ModalProps } from 'components/Modal/Modal';

interface SelectionCateorySwitchModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onSubmit'> {
  selectedItemsInfo: string;
  newCategory: string;
  currentSelectionCategory: string;
}

const SelectionCateorySwitchModal = ({
  currentSelectionCategory,
  newCategory,
  onClose,
  onSubmit,
  open,
  selectedItemsInfo,
}: SelectionCateorySwitchModalProps): JSX.Element => (
  <Modal
    maxWidth="md"
    noPaddingY
    onClose={onClose}
    onSubmit={onSubmit}
    open={open}
    showCloseButton
    sizeVariant="md"
    submitLabel="Confirm"
    title="Category switch"
  >
    <Box>
      {`You are about to select an item from a different category (${newCategory}). Your current
        selection that includes ${selectedItemsInfo} from ${currentSelectionCategory} category will be
        lost and overwritten. Are you sure you want to continue?`}
    </Box>
  </Modal>
);

export default SelectionCateorySwitchModal;
