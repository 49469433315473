import removeMd from 'remove-markdown';

export const getFirstTextLines = (text?: string): string => {
  if (!text) {
    return '';
  }

  const [firstParagraph, secondParagraph] = text
    .split('\n')
    .map(t => t.replace(/\n+/, '').trim())
    .filter(t => t.trim());

  return removeMd(`${firstParagraph} ${secondParagraph || ''}`);
};
