import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  BUILD_MOCK_TEST_MARKSHEET,
  IBuildMockTestMarksheetData,
  IBuildMockTestMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { STORAGE_KEYS } from 'config/constants';
import { useSnackbar } from 'components/Snackbar';
import { constants } from 'config';
import { Nullable } from 'types';

const { SECONDS_PER_MINUTE, SECONDS_PER_HOUR } = constants;
const { mockTests } = paths;

const useBuildMockTestMutation = (mockTestId: Nullable<number>) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [build, { loading }] = useMutation<
    IBuildMockTestMarksheetData,
    IBuildMockTestMarksheetVar
  >(BUILD_MOCK_TEST_MARKSHEET, {
    onCompleted: data => {
      if (data && mockTestId) {
        const { id: marksheetId } = data.restricted.buildMockTestMarksheet;
        sessionStorage.removeItem(STORAGE_KEYS.USER_NOTES);
        navigate(`${mockTests.root}/test/${mockTestId}/${marksheetId}`, {
          state: { leave: true },
        });
      } else {
        enqueueSnackbar('Creating mock test failed');
        navigate(mockTests.root);
      }
    },
  });

  const buildMockTest = useCallback(
    async (hours = 0, minutes = 0) => {
      if (mockTestId) {
        return build({
          variables: {
            // pass duration in seconds
            duration: hours * SECONDS_PER_HOUR + minutes * SECONDS_PER_MINUTE,
            mockTestId: Number(mockTestId),
          },
        });
      }
    },
    [build, mockTestId]
  );

  return { buildMockTest, loading };
};

export default useBuildMockTestMutation;
