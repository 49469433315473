import React from 'react';
import { IQuestionMultiQ } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

import { IAnswerObject } from 'types';
import { Checkbox } from 'components/Checkbox';
import MultiAnswerTableHead from './MultiAnswerTableHead';
import MultiAnswerTableRow from './MultiAnswerTableRow';
import { MultiAnswerColumn, MultiAnswerTableKey } from '../types';
import {
  ExpandableSection,
  ExpandableSectionVariants,
} from 'components/ExpandableSection/ExpandableSection';
import MultiAnswerCheckboxIcon from './MultiAnswerCheckboxIcon';

const Scrollable = styled(Box)(({ theme: { breakpoints, mixins } }) => ({
  width: `calc(${mixins.content.maxWidth}px - 2px)`,

  [breakpoints.up('md')]: {
    width: '100%',
  },

  [breakpoints.up('lg')]: {
    width: `calc(${mixins.content.maxWidth}px - 2px)`,
  },

  [breakpoints.up('xl')]: {
    width: '100%',
  },
}));

const Container = styled(Box)({
  overflowX: 'auto',
});

const isCheckboxValid = (
  label: string,
  isChecked: boolean,
  answers: string[]
) => {
  if (answers.includes(label)) {
    return isChecked;
  }

  return !isChecked;
};

const checkIsChecked =
  (isFinished: boolean, answers: [string[], string[]]) =>
  (label: string, checkedByUser: boolean, index: number) =>
    checkedByUser || (isFinished && answers[index].includes(label));

const columns: MultiAnswerColumn[] = [
  {
    key: MultiAnswerTableKey.Medicine,
    align: 'left',
    label: MultiAnswerTableKey.Medicine,
  },
  {
    key: MultiAnswerTableKey.Dose,
    align: 'left',
    label: MultiAnswerTableKey.Dose,
  },
  {
    key: MultiAnswerTableKey.Route,
    align: 'left',
    label: MultiAnswerTableKey.Route,
  },
  {
    key: MultiAnswerTableKey.Frequency,
    align: 'left',
    label: MultiAnswerTableKey.Frequency,
  },
  {
    key: MultiAnswerTableKey.A,
    align: 'left',
    label: MultiAnswerTableKey.A,
  },
  {
    key: MultiAnswerTableKey.B,
    align: 'left',
    label: MultiAnswerTableKey.B,
  },
];

const getQuestionStatus = (correct?: boolean): ExpandableSectionVariants => {
  switch (correct) {
    case true:
      return 'success';
    case false:
      return 'error';
    case undefined:
      return 'info';
    default:
      return 'normal';
  }
};

export interface MultiAnswerProps {
  onChange?: (isFirstColumn: boolean, markLabel: string) => void;
  combinedAnswer?: IAnswerObject;
  question: IQuestionMultiQ;
  isTestFinished: boolean;
  isPreview?: boolean;
}

export interface MultiAnswerTableProps extends MultiAnswerProps {
  correct: boolean;
}

const MultiAnswerTable = ({
  isTestFinished,
  isPreview = false,
  onChange,
  question,
  combinedAnswer,
  correct,
}: MultiAnswerTableProps): JSX.Element => {
  const { choices, multiAnswer } = question;
  const handleMarkCheck = (isFirstColumn: boolean, markLabel: string) => () => {
    if (onChange) {
      onChange(isFirstColumn, markLabel);
    }
  };

  const isCheckboxChecked = checkIsChecked(isTestFinished, multiAnswer);
  const getVariant = (): ExpandableSectionVariants => {
    if (!isTestFinished) {
      return 'info';
    } else if (isPreview) {
      return 'normal';
    }

    return getQuestionStatus(correct);
  };

  const answerLimitReachedA =
    (combinedAnswer?.firstColumn?.length || 0) >= multiAnswer[0].length;
  const answerLimitReachedB =
    (combinedAnswer?.secondColumn?.length || 0) >= multiAnswer[1].length;

  return (
    <ExpandableSection
      disableContentBackground
      expandable={!isTestFinished}
      expanded
      key={question.id}
      size="medium"
      title="Current prescription"
      variant={getVariant()}
      withoutPadding
    >
      <Container>
        <Scrollable>
          <MultiAnswerTableHead columns={columns} />

          {choices.map(({ label, name, id }) => {
            const splitValues = name.split(';');
            // There will be always at least 4 elements to split (Medicine, Dose, Route, Frequency).
            if (!splitValues.length || splitValues.length < 4) {
              return null;
            }
            const firstChecked = combinedAnswer
              ? combinedAnswer.firstColumn.includes(label)
              : false;
            const secondChecked = combinedAnswer
              ? combinedAnswer.secondColumn.includes(label)
              : false;

            const validA = isCheckboxValid(label, firstChecked, multiAnswer[0]);
            const validB = isCheckboxValid(
              label,
              secondChecked,
              multiAnswer[1]
            );
            const checkedA = isCheckboxChecked(label, firstChecked, 0);
            const checkedB = isCheckboxChecked(label, secondChecked, 1);

            const checkboxA = (
              <Checkbox
                checked={checkedA}
                checkedIcon={
                  <MultiAnswerCheckboxIcon
                    isChecked={firstChecked}
                    isPreview={isPreview}
                    isTestFinished={isTestFinished}
                    valid={validA}
                  />
                }
                disabled={isTestFinished || (answerLimitReachedA && !checkedA)}
                onClick={handleMarkCheck(true, label)}
              />
            );

            const checkboxB = (
              <Checkbox
                checked={checkedB}
                checkedIcon={
                  <MultiAnswerCheckboxIcon
                    isChecked={secondChecked}
                    isPreview={isPreview}
                    isTestFinished={isTestFinished}
                    valid={validB}
                  />
                }
                disabled={isTestFinished || (answerLimitReachedB && !checkedB)}
                onClick={handleMarkCheck(false, label)}
              />
            );

            const values = [...splitValues, checkboxA, checkboxB];

            return (
              <MultiAnswerTableRow columns={columns} key={id} values={values} />
            );
          })}
        </Scrollable>
      </Container>
    </ExpandableSection>
  );
};

export default MultiAnswerTable;
