import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  IPreBuildUkmlaMarksheetData,
  IPreBuildUkmlaMarksheetVar,
  PRE_BUILD_UKMLA_MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { QUESTION_DIFFICULTY_LEVELS } from 'config/constants';
import { OnRequestComplete } from 'types';

const usePrebuildMLAMarksheetQuery = (
  onPrebuildComplete?: OnRequestComplete<IPreBuildUkmlaMarksheetData>
) => {
  const [preBuild, { data, loading }] = useLazyQuery<
    IPreBuildUkmlaMarksheetData,
    IPreBuildUkmlaMarksheetVar
  >(PRE_BUILD_UKMLA_MARKSHEET, {
    onCompleted: data => {
      if (onPrebuildComplete && data) {
        onPrebuildComplete(data);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { preBuildUkmlaMarksheet } = data?.restricted || {};

  const preBuildMarksheet = useCallback(
    async (params: IPreBuildUkmlaMarksheetVar) => {
      const {
        topicIds = [],
        conditionIds = [],
        presentationIds = [],
        search = '',
        source = '',
        marksheetId,
        difficulty = QUESTION_DIFFICULTY_LEVELS,
      } = params;

      const variables: IPreBuildUkmlaMarksheetVar = {
        topicIds,
        conditionIds,
        presentationIds,
        search,
        source,
        difficulty,
      };

      if (marksheetId) {
        variables.marksheetId = marksheetId;
      }

      return preBuild({
        variables,
      });
    },

    [preBuild]
  );

  return {
    preBuildMarksheet,
    preBuildData: preBuildUkmlaMarksheet,
    preBuildLoading: loading,
  };
};

export default usePrebuildMLAMarksheetQuery;
