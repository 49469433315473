import { useMutation } from '@apollo/client';
import {
  IRefreshDailyTaskData,
  IRefreshDailyTaskVar,
  REFRESH_DAILY_TASK,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useRefreshDailyTask = () => {
  const [refreshDailyTask, { loading }] = useMutation<
    IRefreshDailyTaskData,
    IRefreshDailyTaskVar
  >(REFRESH_DAILY_TASK);

  return { refreshDailyTask, loading };
};

export default useRefreshDailyTask;
