import { useApolloClient, useMutation } from '@apollo/client';
import {
  IResetCategoryProgressData,
  IResetCategoryProgressVar,
  RESET_CATEGORY_PROGRESS,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  PRE_DEFINED_PRESETS,
  PRESETS,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { useSnackbar } from 'components/Snackbar';

const useResetCategoryProgress = (conceptIds: number[], topicIds: number[]) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [resetCategoryProgress] = useMutation<
    IResetCategoryProgressData,
    IResetCategoryProgressVar
  >(RESET_CATEGORY_PROGRESS, {
    variables: {
      conceptIds,
      topicIds,
    },
    onCompleted: () => {
      enqueueSnackbar('Preset progress reset successful!');
      // TODO we get boolean as a response so we are not able
      // to update progress for all affected presets
      client.refetchQueries({
        include: [PRESETS, PRE_DEFINED_PRESETS],
      });
    },
  });

  return { resetCategoryProgress };
};
export default useResetCategoryProgress;
