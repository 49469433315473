import React from 'react';
import { mdiKeyboardSpace } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const SpaceBarIcon = (props: IconProps) => (
  <Icon path={mdiKeyboardSpace} {...props} />
);

export default SpaceBarIcon;
