import React, { useCallback, useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import isNil from 'lodash/isNil';
import { EProductType } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  OsceVideoLibraryState,
  useOsceVideoLibrary,
} from 'components/LearningMaterials';
import { ToggleEvent } from 'types';
import { usePrevious } from 'hooks';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { usePlatform } from 'context/PlatformState';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const { videoLibrary } = paths;

export const useOsceVideoLibraryData = () => {
  return useOutletContext<OsceVideoLibraryState>();
};

const OsceVideoLibrary = (): JSX.Element => {
  const { product } = usePlatform();
  const { loading, categories, stations, allVideos } = useOsceVideoLibrary(
    product === EProductType.PACES
  );
  const {
    sectionId: categoryId,
    entitlementId,
    videoId,
  } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState(
    entitlementId ? Number(entitlementId) : categories[0]?.value
  );

  const previousCategory = usePrevious(activeCategory);
  const previousTopicId = usePrevious(categoryId);
  const previousVideoId = usePrevious(videoId);

  const resetSearch =
    Boolean(categories) &&
    (previousCategory !== activeCategory ||
      previousTopicId !== categoryId ||
      previousVideoId !== videoId);

  const activeCategoryLabel =
    categories.find(({ value }) => value === activeCategory)?.label || '';

  useEffect(() => {
    if (!entitlementId || !categoryId) {
      const [category] = categories || [];
      const { value } = category || {};
      if (!isNil(value)) {
        navigate(`${videoLibrary.root}/section/${value}/${value}`);
      }
    } else {
      setActiveCategory(Number(entitlementId));
    }
  }, [activeCategory, entitlementId, navigate, categories, categoryId]);

  const handleActiveCategory = useCallback(
    (_: ToggleEvent, type: EProductType) => {
      navigate(`${videoLibrary.root}/section/${type}/${type}`);
    },
    [navigate]
  );

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!categories) {
    return <ErrorMessage />;
  }

  return (
    <Outlet
      context={{
        activeCategory,
        activeCategoryLabel,
        allVideos,
        categories,
        stations,
        loading,
        resetSearch,
        searchBy: 'videos',
        switchCategory: handleActiveCategory,
      }}
    />
  );
};

export default OsceVideoLibrary;
