import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const RootContainer = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { dashboardWidget },
      palette,
      spacing,
    },
  }) => ({
    backgroundColor: palette.background.paper,
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: spacing(2),
    maxHeight: dashboardWidget.mobile.maxHeight,
    padding: dashboardWidget.mobile.padding,
    paddingBottom: dashboardWidget.mobile.paddingBottom,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',

    '&.heatmap': {
      height: '100%',
      maxHeight: '100%',
      paddingBottom: spacing(6),
      justifyContent: 'unset',
    },

    [breakpoints.up('md')]: {
      padding: dashboardWidget.desktop.padding,
      paddingBottom: dashboardWidget.desktop.paddingBottom,
      flex: 1,
      maxHeight: dashboardWidget.desktop.maxHeight,
    },
  })
);

export const ContentContainer = styled(Box)({
  width: '100%',
  margin: '0 auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '&.fullWidth': {
    maxWidth: '100%',
    width: '100%',
  },

  '&.questions': {
    justifyContent: 'flex-start',
  },

  '&.performance': {
    justifyContent: 'center',
  },

  '&.overflow': {
    overflow: 'hidden',
  },
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TitleContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(4),
}));

export const CardIconContainer = styled(Box)<{
  heatmapcolourname?: string | null;
}>(
  ({
    heatmapcolourname,
    theme: {
      palette: { dashboardWidget, error, heatmap },
      spacing,
    },
  }) => ({
    backgroundColor: dashboardWidget.defaultBackground,
    color: dashboardWidget.defaultColor,
    borderRadius: spacing(4),
    padding: spacing(2),
    width: spacing(10),
    height: spacing(10),

    '&.daily-flashcards': {
      backgroundColor: dashboardWidget.flashcardsBackground,
      color: dashboardWidget.flashcardsColor,
    },

    '&.daily-streak': {
      backgroundColor: dashboardWidget.heatmap,
      color: dashboardWidget.heatmapColor,
    },

    '&.heatmap': {
      backgroundColor: heatmapcolourname
        ? heatmap[heatmapcolourname as unknown as keyof typeof heatmap].med
        : dashboardWidget.heatmap,
      color: heatmapcolourname
        ? heatmap[heatmapcolourname as unknown as keyof typeof heatmap].high
        : dashboardWidget.heatmapColor,

      '&.heart': {
        backgroundColor: heatmapcolourname
          ? heatmap[heatmapcolourname as unknown as keyof typeof heatmap].med
          : error.extraLight,
        color: heatmapcolourname
          ? heatmap[heatmapcolourname as unknown as keyof typeof heatmap].high
          : error.dark,
      },
    },
  })
);
