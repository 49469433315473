import { IPreset } from '@quesmed/types-rn/models';

import { paths } from 'Router';

export const extractIds: (preset: IPreset) => Set<number> = (
  preset: IPreset
) => {
  const conceptIdSet = new Set<number>();

  preset.concepts.forEach(({ id }) => {
    conceptIdSet.add(Number(id));
  });

  return conceptIdSet;
};

export const switchPath = (section: string) =>
  section === 'flashcards' ? paths.flashcards.root : paths.questions.root;
