import React from 'react';
import { IPicture } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';

import { MarkdownWithLightbox } from 'components/Markdown';
import { parseFullText } from 'utils';
import { ChartIcon } from 'components/Icons';

interface QuestionChoiceExplanationProps {
  explanation: string | null;
  picture?: IPicture | null;
  votes: number;
  isChosen: boolean;
}

const QuestionChoiceExplanation = ({
  explanation,
  isChosen,
  picture,
  votes,
}: QuestionChoiceExplanationProps): JSX.Element => {
  const images = picture ? [picture] : undefined;

  return (
    <Box sx={({ spacing }) => ({ padding: spacing(4) })}>
      {explanation && (
        <MarkdownWithLightbox
          markdownStylesProps={{ bottomOffset: false }}
          pictures={images}
          text={parseFullText(explanation, images)}
        />
      )}

      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          alignItems: 'center',
          marginTop: spacing(4),
          svg: { marginRight: spacing(2) },
        })}
      >
        <ChartIcon />
        {`${
          isChosen ? 'You and ' : ''
        }${votes}% of users selected this answer.`}
      </Box>
    </Box>
  );
};

export default QuestionChoiceExplanation;
