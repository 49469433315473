import { IOsceStation, IVideo } from '@quesmed/types-rn/models';

import { compareTitles, values } from 'utils';

export const getVideos = (stations?: IOsceStation[]) => {
  if (!stations) {
    return;
  }

  const videosData: { [key: number]: IVideo } = {};

  stations.forEach(({ videos }) => {
    videos?.forEach(video => {
      const { id } = video;
      if (!videosData[id]) {
        videosData[id] = video;
      }
    });
  });

  return values(videosData).sort(compareTitles);
};
