import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IUpsertUserConceptStatusData,
  IUpsertUserConceptStatusVar,
  optimisticConceptLearningStatus,
  updateCacheOnConceptLearningStatusUpdate,
  UPSERT_USER_CONCEPT_STATUS,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import { randomId } from 'utils';
import { useCurrentUser } from 'Auth';

export const useUpdateConceptLearningStatus = () => {
  const [upsertStatus, { loading }] = useMutation<
    IUpsertUserConceptStatusData,
    IUpsertUserConceptStatusVar
  >(UPSERT_USER_CONCEPT_STATUS);
  const { id: userId } = useCurrentUser();

  const updateStatus = useCallback(
    (
      conceptIds: number[],
      status: EUserLearningStatus,
      topicId: number,
      prevStatus?: Nullable<EUserLearningStatus>
    ) =>
      upsertStatus({
        variables: { conceptIds, status },
        update: updateCacheOnConceptLearningStatusUpdate(topicId, prevStatus),
        optimisticResponse: optimisticConceptLearningStatus(
          randomId(8),
          conceptIds,
          status,
          userId
        ),
      }),
    [upsertStatus, userId]
  );

  return { loading, updateStatus };
};
