import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EPaceMarkType, IOsceMarksheetMark } from '@quesmed/types-rn/models';

import { PacesMarkschemeMark } from './PacesMarkschemeMark';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(8),
}));

export interface StationPacesMarkschemeProp {
  isCompleted: boolean;
  disabled: boolean;
  expandedAll?: boolean;
  marks: IOsceMarksheetMark[];
  onMarkChange: (markId: number) => (value: EPaceMarkType) => void;
}

const StationPacesMarkscheme = ({
  isCompleted,
  disabled,
  marks,
  expandedAll,
  onMarkChange,
}: StationPacesMarkschemeProp) => (
  <Container>
    {marks.map(({ id, ...rest }) => (
      <PacesMarkschemeMark
        disabled={disabled}
        expanded={expandedAll}
        isCompleted={isCompleted}
        key={id}
        onClick={onMarkChange(id)}
        osceMark={{ id, ...rest }}
      />
    ))}
  </Container>
);

export default StationPacesMarkscheme;
