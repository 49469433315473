import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IQuestion } from '@quesmed/types-rn/models';
import Collapse from '@mui/material/Collapse';
import { useHotkeys } from 'react-hotkeys-hook';

import { scrollToBottom, scrollToElement } from 'utils';
import { ChevronDownIcon, CommentsIcon } from 'components/Icons';
import { useCommentsLikeMutation } from 'components/Likes/useCommentsLikeMutation';
import { IconButton } from 'components/IconButton';
import CommentInput from './CommentInput/CommentInput';
import CommentWithReplies from './CommentsWithReplies/CommentWithReplies';
import useComment from './useComment';
import CommentsEmpty from './CommentsEmpty';
import {
  CommentInputBorderBox,
  CommentInputContainer,
  CommentsContainer,
  CommentsHeader,
  CommentsHeading,
  CommentsHeadingContainer,
  CommentsListContainer,
} from './Comments.styles';
import { KeyboardKey } from 'types';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { useMarkdownTextScale } from 'components/Markdown';

interface CommentsProps {
  question: IQuestion;
  marksheetId?: number;
  showComments?: boolean;
  isSample?: boolean;
}

const Comments = ({
  question,
  marksheetId,
  showComments = false,
  isSample = false,
}: CommentsProps): JSX.Element => {
  const {
    comments,
    addComment,
    addReply,
    removeComment,
    removeReply,
    questionCommentsLoading,
    commentLoading,
    checkIsReplyLoading,
    checkIsRemoveCommentLoading,
  } = useComment(question, marksheetId);
  const { scale } = useMarkdownTextScale();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const commentInputRef = useRef<HTMLInputElement>(null);
  const commentsListContainerRef = useRef<HTMLDivElement>(null);

  const focusCommentInput = useCallback((e: KeyboardEvent) => {
    const textarea = commentInputRef.current;
    const isFocused = document.activeElement === textarea;

    if (e.key === KeyboardKey.C && textarea && !isFocused) {
      e.preventDefault();
      textarea.focus();
    }
  }, []);

  useHotkeys(KeyboardKey.C, focusCommentInput);

  const { handleLikeComment } = useCommentsLikeMutation(
    question,
    questionCommentsLoading,
    marksheetId
  );
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(
    isSample ? false : showComments
  );

  useEffect(() => {
    if (!isSample) {
      setIsCommentsExpanded(showComments);
    }
  }, [isSample, showComments]);

  const toggleComments = useCallback(() => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      setIsCommentsExpanded(prevValue => !prevValue);
    }
  }, [enqueueSnackbar, isSample]);

  const scrollToComment = (elementOffsetTop: number) => {
    scrollToElement<HTMLDivElement>(commentsListContainerRef, elementOffsetTop);
  };

  const handleAddComment = (comment: string) => {
    addComment(comment).then(() => {
      scrollToBottom(commentsListContainerRef);
    });
  };

  return (
    <CommentsContainer>
      <CommentsHeader
        expanded={isCommentsExpanded ? 1 : 0}
        onClick={toggleComments}
      >
        <CommentsHeadingContainer>
          <CommentsHeading scale={scale}>
            <CommentsIcon />
            Comments ({comments?.length})
          </CommentsHeading>
        </CommentsHeadingContainer>
        <IconButton disableRipple icon={<ChevronDownIcon />} />
      </CommentsHeader>
      {!isSample ? (
        <Collapse in={isCommentsExpanded} unmountOnExit>
          {comments.length ? (
            <CommentsListContainer ref={commentsListContainerRef}>
              {comments.map(comment => (
                <CommentWithReplies
                  addCommentLoading={checkIsReplyLoading(comment.id)}
                  addReply={addReply}
                  checkIsRemoveCommentLoading={checkIsRemoveCommentLoading}
                  commentData={comment}
                  key={comment.id}
                  likeComment={handleLikeComment}
                  removeComment={removeComment}
                  removeCommentLoading={checkIsRemoveCommentLoading(comment.id)}
                  removeReply={removeReply}
                  scrollToComment={scrollToComment}
                />
              ))}
            </CommentsListContainer>
          ) : (
            <CommentsEmpty />
          )}

          <CommentInputBorderBox>
            <CommentInputContainer>
              <CommentInput
                helperText="Comment Required!"
                label="Write your comment here..."
                loading={commentLoading}
                onSubmit={handleAddComment}
                ref={commentInputRef}
              />
            </CommentInputContainer>
          </CommentInputBorderBox>
        </Collapse>
      ) : null}
    </CommentsContainer>
  );
};

export default Comments;
