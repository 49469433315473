import React from 'react';
import MuiBox, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Box = styled(MuiBox)(({ theme: { palette, shape } }) => ({
  borderRadius: shape.borderRadius,
  backgroundColor: palette.background.paper,
  border: `1px solid ${palette.stroke.main}`,
  overflow: 'hidden',
}));

const Card = ({ children, ...props }: BoxProps): JSX.Element => (
  <Box {...props}>{children}</Box>
);

export default Card;
