import React, { useEffect } from 'react';
import { EOsceMarksheetState, EStudyAction } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import { ExerciseType } from 'types';
import { useCurrentUser, useDemo } from 'Auth';
import { useStationGroupStudyData } from './StationsGroupStudyController';
import { Lobby } from 'components/Lobby';
import {
  StationModal,
  useCreateOrJoin,
  useMatchmakingLoadingState,
  useOnStudyAction,
  useStationModalState,
  useStationRoles,
} from './hooks';
import { useLeaveExerciseState } from 'hooks/index';
import useChangeOsceMarksheetState from './hooks/useChangeOsceMarksheetState';
import { isDev } from 'config/constants';

const StationsGroupStudyLobby = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    osceMarksheet,
    isExaminer,
    participants: studyParticipants,
  } = useStationGroupStudyData();
  const { loading } = useMatchmakingLoadingState();
  const { openModal } = useStationModalState();
  const { openLeaveModal } = useLeaveExerciseState();
  const { id, displayName } = useCurrentUser();
  const isDemo = useDemo();
  const { sessionId, activeUsers, createOrJoin, osceMarksheetId, inviteLink } =
    useCreateOrJoin();

  useOnStudyAction(sessionId, osceMarksheetId, false);

  const groupStudyPath = `?join=${sessionId}`;

  const participants = studyParticipants.length
    ? studyParticipants
    : null ||
      osceMarksheet?.activeUsers ||
      activeUsers || [{ id, displayName }];

  const { members } = osceMarksheet || {};

  const { assignRole } = useStationRoles(sessionId || '', members);

  const { changeMarksheetState } = useChangeOsceMarksheetState(
    Number(osceMarksheet?.id)
  );

  const handleStartExercise = () => {
    changeMarksheetState(EOsceMarksheetState.STATION_BUILDER);
  };

  const handleRoleChange = () => {
    openModal(StationModal.RolesModal);
  };

  // TODO matchmaking will be added in separate task
  const handleStartMatchmaking = () => {
    openModal(StationModal.MatchmakingStartModal);
  };

  useEffect(() => {
    if (!sessionId) {
      createOrJoin(EStudyAction.START);
    }
  }, [sessionId, createOrJoin]);

  useEffect(() => {
    if (activeUsers.length > 1) {
      navigate(groupStudyPath, { state: { activeUsers, inviteLink } });
    }
  }, [activeUsers, groupStudyPath, inviteLink, navigate]);

  return (
    <Lobby
      currentUserId={id}
      exercise={ExerciseType.Stations}
      groupStudy
      inviteLink={inviteLink}
      joinGroupStudyLoading={loading}
      lobbyOwner={isExaminer}
      onChangeRole={handleRoleChange}
      onExerciseLeave={openLeaveModal}
      // TODO remove checks when matchmaking is finally finished
      onJoinGroupStudy={isDev && !isDemo ? handleStartMatchmaking : undefined}
      onStartExercise={handleStartExercise}
      onSwapRole={assignRole}
      participants={participants}
      withoutPanel={participants.length < 2}
    />
  );
};

export default StationsGroupStudyLobby;
