import React from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';

import { Body } from 'components/Typography';
import { Markdown } from 'components/Markdown';

const Container = styled(Box)(
  ({ theme: { typography, breakpoints, spacing, mixins } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4, 0),
    width: '100%',
    maxWidth: mixins.content.maxWidth,
    margin: '0 auto',

    [breakpoints.up('md')]: {
      padding: spacing(6, 0),
    },

    [breakpoints.up('lg')]: {
      padding: spacing(8, 0),
    },

    '& .markdown-reading': {
      h5: {
        ...typography.h5,
        textAlign: 'center',
      },
      img: {
        display: 'block',
        margin: '0 auto',
      },
    },
  })
);

const BodyWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

interface PreExaminationGuidanceProps {
  guidance: string;
}

export const PreExaminationGuidance = ({
  guidance,
}: PreExaminationGuidanceProps) => (
  <Container>
    {guidance ? (
      <Markdown simpleImage text={guidance} withLightbox={false} />
    ) : null}
    <BodyWrapper>
      <Body>Click the Next button when you are ready to begin</Body>
    </BodyWrapper>
  </Container>
);
