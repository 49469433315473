import { useCallback, useEffect, useState } from 'react';

import {
  ButtonOnClickHandler,
  CheckboxState,
  InputOnChangeHandler,
  MLASelectionState,
  MLATopicSelection,
  MLATopicSelectionParams,
  MLATopicSelectionState,
} from 'types';
import { getCheckboxState } from 'utils/selectionstate/index';

const partition = (array: MLATopicSelectionState[]) => {
  const result = [[], [], []] as MLATopicSelectionState[][];

  array.forEach(element => {
    const { topicState } = element;
    if (topicState === CheckboxState.CHECKED) {
      result[0].push(element);
    } else if (topicState === CheckboxState.UNCHECKED) {
      result[1].push(element);
    } else {
      result[2].push(element);
    }
  });

  return result;
};

const useMLASelectionState = ({
  topics,
  displayTopics,
}: MLATopicSelectionParams): MLATopicSelection => {
  const [selectionState, setSelectionState] = useState<MLASelectionState>(
    new Map<number, MLATopicSelectionState>()
  );
  const [allSelected, setAllSelected] = useState<CheckboxState>(
    CheckboxState.UNCHECKED
  );
  useEffect(() => {
    if (topics) {
      const stateMap = new Map<number, MLATopicSelectionState>();

      topics.forEach(({ id, conditions, presentations }) => {
        const { conditionsSelected = [], presentationsSelected = [] } = {};
        stateMap.set(Number(id), {
          id: Number(id),
          topicState: getCheckboxState(
            conditionsSelected.length + presentationsSelected.length,
            conditions?.length || presentations?.length || 0
          ),
          selectedConditionsIds: new Set<number>(
            conditionsSelected.map(id => Number(id))
          ),
          conditionIds: new Set(
            conditions ? conditions.map(({ id }) => Number(id)) : []
          ),
          conditionsAvailable: conditions?.length || 0,
          selectedPresentationsIds: new Set<number>(
            presentationsSelected.map(id => Number(id))
          ),
          presentationsIds: new Set(
            presentations ? presentations.map(({ id }) => Number(id)) : []
          ),
          presentationsAvailable: presentations?.length || 0,
        });
      });

      setSelectionState(stateMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics]);

  useEffect(() => {
    const items = [...selectionState.values()];
    const [checked, unchecked] = partition(items);

    if (unchecked.length === items.length) {
      setAllSelected(CheckboxState.UNCHECKED);

      return;
    }

    if (checked.length === items.length) {
      setAllSelected(CheckboxState.CHECKED);

      return;
    }

    setAllSelected(CheckboxState.INTERMEDIATE);
  }, [selectionState]);

  const selectAll = useCallback(
    (selectionState: MLASelectionState) => {
      const isAnyTopicSelected = [...selectionState.values()].some(
        ({ topicState }) => topicState !== CheckboxState.UNCHECKED
      );

      const displayTopicIds = new Set(
        displayTopics?.map(displayTopic => Number(displayTopic.id))
      );
      let topicState = CheckboxState.UNCHECKED;

      if (!isAnyTopicSelected) {
        topicState =
          displayTopicIds.size === selectionState.size
            ? CheckboxState.CHECKED
            : CheckboxState.INTERMEDIATE;
      }
      const stateMap = new Map<number, MLATopicSelectionState>();

      selectionState.forEach((value, key) => {
        const { conditions, presentations } =
          displayTopics?.find(topic => Number(topic.id) === Number(key)) || {};

        const conditionsIds = new Set(conditions?.map(({ id }) => Number(id)));
        const presentationsIds = new Set(
          presentations?.map(({ id }) => Number(id))
        );

        stateMap.set(key, {
          ...value,
          topicState:
            conditions || presentations ? topicState : value.topicState,
          selectedConditionsIds: new Set<number>(
            isAnyTopicSelected ? [] : [...conditionsIds]
          ),
          selectedPresentationsIds: new Set<number>(
            isAnyTopicSelected ? [] : [...(presentationsIds ?? [])]
          ),
        });
      });
      setSelectionState(stateMap);
    },
    [displayTopics]
  );

  const handleSelectAll = useCallback(
    (selectionState: MLASelectionState): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        selectAll(selectionState);
      },
    [selectAll]
  );

  const deselectAll = useCallback(
    (selectionState: MLASelectionState) => {
      const stateMap = new Map<number, MLATopicSelectionState>();

      selectionState.forEach((value, key) => {
        stateMap.set(key, {
          ...value,
          topicState: CheckboxState.UNCHECKED,
          selectedConditionsIds: new Set<number>(),
          selectedPresentationsIds: new Set<number>(),
        });
      });

      setSelectionState(stateMap);

      return stateMap;
    },
    [setSelectionState]
  );

  const handleDeselectAll = useCallback(
    (selectionState: MLASelectionState): ButtonOnClickHandler =>
      event => {
        event.stopPropagation();

        deselectAll(selectionState);
      },
    [deselectAll]
  );

  const changeTopicState = useCallback(
    (selectionState: MLASelectionState, topicId: number) => {
      const currentState = selectionState.get(topicId);

      const { conditions, presentations } =
        displayTopics?.find(({ id }) => Number(id) === Number(topicId)) || {};

      const conditionsIds = new Set(conditions?.map(({ id }) => Number(id)));
      const presentationsIds = new Set(
        presentations?.map(({ id }) => Number(id))
      );

      if (currentState) {
        const {
          topicState: currentTopicState,
          conditionIds: currentConditionIds,
          presentationsIds: currentPresentationsIds,
        } = currentState;

        let topicState = CheckboxState.UNCHECKED;
        if (currentTopicState === CheckboxState.UNCHECKED) {
          topicState =
            conditionsIds.size === currentConditionIds.size &&
            presentationsIds.size === currentPresentationsIds.size
              ? CheckboxState.CHECKED
              : CheckboxState.INTERMEDIATE;
        }

        setSelectionState(
          prev =>
            new Map(
              prev.set(topicId, {
                ...currentState,
                topicState,
                selectedConditionsIds: new Set<number>(
                  currentTopicState === CheckboxState.UNCHECKED
                    ? [...conditionsIds]
                    : []
                ),
                selectedPresentationsIds: new Set<number>(
                  currentTopicState === CheckboxState.UNCHECKED
                    ? [...presentationsIds]
                    : []
                ),
              })
            )
        );
      }
    },
    [displayTopics]
  );

  const handleSelectTopic = useCallback(
    (selectionState: MLASelectionState) =>
      (topicId: number): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        changeTopicState(selectionState, topicId);
      },
    [changeTopicState]
  );

  const changeConditionState = useCallback(
    (
      selectionState: MLASelectionState,
      topicId: number,
      conditionId: number
    ) => {
      const currentState = selectionState.get(topicId);

      if (currentState) {
        const {
          selectedConditionsIds,
          conditionIds,
          presentationsIds,
          selectedPresentationsIds,
        } = currentState;

        const temp = new Set(selectedConditionsIds);

        if (temp.has(conditionId)) {
          temp.delete(conditionId);
        } else {
          temp.add(conditionId);
        }

        const tempWithPresentations = new Set(temp);

        selectedPresentationsIds.forEach(id => tempWithPresentations.add(id));

        setSelectionState(
          prev =>
            new Map(
              prev.set(topicId, {
                ...currentState,
                topicState: getCheckboxState(
                  tempWithPresentations.size,
                  conditionIds.size + presentationsIds.size
                ),
                selectedConditionsIds: temp,
              })
            )
        );
      }
    },
    [setSelectionState]
  );

  const changePresentationState = useCallback(
    (
      selectionState: MLASelectionState,
      topicId: number,
      patientPresentationId: number
    ) => {
      const currentState = selectionState.get(topicId);

      if (currentState) {
        const {
          presentationsIds,
          selectedPresentationsIds,
          conditionIds,
          selectedConditionsIds,
        } = currentState;

        const temp = new Set(selectedPresentationsIds);

        if (temp.has(patientPresentationId)) {
          temp.delete(patientPresentationId);
        } else {
          temp.add(patientPresentationId);
        }

        const tempWithConditions = new Set(temp);
        selectedConditionsIds.forEach(id => tempWithConditions.add(id));

        setSelectionState(
          prev =>
            new Map(
              prev.set(topicId, {
                ...currentState,
                topicState: getCheckboxState(
                  tempWithConditions.size,
                  conditionIds.size + presentationsIds.size
                ),
                selectedPresentationsIds: temp,
              })
            )
        );
      }
    },
    [setSelectionState]
  );

  const handleSelectCondition = useCallback(
    (selectionState: MLASelectionState) =>
      (topicId: number) =>
      (conceptId: number): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        changeConditionState(selectionState, topicId, conceptId);
      },
    [changeConditionState]
  );

  const handleSelectPresentation = useCallback(
    (selectionState: MLASelectionState) =>
      (topicId: number) =>
      (presentationId: number): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        changePresentationState(selectionState, topicId, presentationId);
      },
    [changePresentationState]
  );

  return {
    allSelected,
    selectionState,
    handleSelectAll,
    handleDeselectAll,
    handleSelectTopic,
    handleSelectCondition,
    handleSelectPresentation,
    selectAll,
    deselectAll,
    changeTopicState,
    changeConditionState,
    changePresentationState,
    setSelectionState,
  };
};

export default useMLASelectionState;
