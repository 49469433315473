import { useMutation } from '@apollo/client';
import {
  ILeaveOsceMarksheetData,
  ILeaveOsceMarksheetVar,
  LEAVE_OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePlatform } from 'context/PlatformContext';
import { paths } from 'Router';
import { useAppSetState } from 'context/AppState';
import { useAgoraAPI } from 'components/Agora';
import { useLeaveExerciseState } from 'hooks';
import { GQL_ENDPOINT } from 'config/constants';
import { useAuth } from 'Auth';

const { dashboard, stations } = paths;

const useLeaveStation = (osceMarksheetId?: number) => {
  const { setLogoutCollback } = useAppSetState();
  const { leaveState, setCanLeave } = useLeaveExerciseState();
  const { token } = useAuth();
  const { endSession } = useAgoraAPI();
  const { setProduct } = usePlatform();
  const { path, state = {} } = leaveState;
  const { newProduct } = state;
  const navigate = useNavigate();
  const [leave, { loading }] = useMutation<
    ILeaveOsceMarksheetData,
    ILeaveOsceMarksheetVar
  >(LEAVE_OSCE_MARKSHEET);

  const leveStation = useCallback(
    async (redirect = true) => {
      if (!osceMarksheetId || loading) {
        return;
      }

      if (osceMarksheetId && !loading) {
        if (endSession) {
          await endSession();
        }

        await leave({
          variables: {
            osceMarksheetId: Number(osceMarksheetId),
          },
        });

        setCanLeave(true);

        if (newProduct !== undefined) {
          setProduct(newProduct);
        }

        if (redirect) {
          const targetPath =
            path && path !== stations.groupStudy ? path : dashboard;
          navigate(targetPath, { state: { leave: true } });
        }
      }
    },

    [
      osceMarksheetId,
      loading,
      leave,
      endSession,
      setCanLeave,
      newProduct,
      setProduct,
      path,
      navigate,
    ]
  );

  const leaveStationtOnLogout = useCallback(async () => {
    if (osceMarksheetId) {
      if (endSession) {
        endSession();
      }

      return leave({
        variables: {
          osceMarksheetId: Number(osceMarksheetId),
        },
      });
    }
  }, [endSession, leave, osceMarksheetId]);

  const leaveOnClose = useCallback(() => {
    if (osceMarksheetId) {
      fetch(GQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          query: `mutation LeaveOsceMarksheet($osceMarksheetId: Int!) {
            restricted {
              leaveOsceMarksheet(osceMarksheetId: $osceMarksheetId) {
                id
              }
            }
          }`,
          variables: {
            marksheetId: Number(osceMarksheetId),
          },
        }),
        keepalive: true,
      });
    }

    return undefined;
  }, [osceMarksheetId, token]);

  useEffect(() => {
    setLogoutCollback(leaveStationtOnLogout);

    return () => {
      setLogoutCollback(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveStationtOnLogout]);

  return { leveStation, loading, leaveStationtOnLogout, leaveOnClose };
};

export default useLeaveStation;
