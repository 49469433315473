import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { noop } from 'utils';
import { InputOnChangeHandler } from 'types';
import {
  CalculationHelperSpan,
  CommentTextField,
  InputBox,
  SubmitButton,
} from './CommentInput.styles';
import { useResizeObserver } from '../../../hooks';

interface CommentInputProps {
  label: string;
  helperText: string;
  loading: boolean;
  onSubmit: (comment: string) => void;
}
// Defines summed gaps between textarea text, post button and textarea borders
// (leftPadding + gap between text and button + rightPadding)(12+12+12)
const COMMENT_TEXT_FIELD_GAPS = 36;

const CommentInput = forwardRef<HTMLInputElement, CommentInputProps>(
  ({ helperText, label, loading, onSubmit }, ref) => {
    const [comment, setComment] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [multiline, setMultiline] = useState(false);
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const helperSpanRef = useRef<HTMLSpanElement>(null);
    const commentDisabled = !comment.trim();
    const [inputBoxRef, rect, inputBox] = useResizeObserver<HTMLDivElement>();

    // Calculates the width of the comment text field content
    const calculateTextFieldContentWidth = (textContent: string) => {
      if (helperSpanRef && helperSpanRef.current) {
        const helperSpan = helperSpanRef.current;
        helperSpan.textContent = textContent || '';
        const leftPaddingWidth = 12;

        return helperSpan.offsetWidth + leftPaddingWidth;
      }

      return 0;
    };

    // Checks if the  comment text field is long enough to show the submit button under the text field
    const checkWhetherToShowButtonUnderInput = useCallback(
      (textContent: string) => {
        if (!inputBox || !submitButtonRef || !submitButtonRef.current) {
          return;
        }
        const textFieldContainerWidth = rect.width;
        const submitButtonWidth = submitButtonRef.current.offsetWidth;

        const calculatedHelperSpanWidth =
          calculateTextFieldContentWidth(textContent);

        return (
          calculatedHelperSpanWidth >=
          textFieldContainerWidth - submitButtonWidth - COMMENT_TEXT_FIELD_GAPS
        );
      },
      [inputBox, rect.width]
    );
    useEffect(() => {
      const longComment = checkWhetherToShowButtonUnderInput(comment);
      if (longComment) {
        setMultiline(true);
      } else {
        setMultiline(false);
      }
    }, [checkWhetherToShowButtonUnderInput, comment, rect]);

    const handleChange: InputOnChangeHandler = useCallback(
      ({ target }) => {
        const nonEmptyComment = comment !== '';
        if (nonEmptyComment) {
          setIsError(false);
        }

        setComment(target.value);
      },
      [comment]
    );

    const handleSubmit = useCallback(async () => {
      if (isError || loading || commentDisabled) {
        return;
      }

      try {
        onSubmit(comment);
        setComment('');
        setIsError(false);
      } catch {
        noop();
      }
    }, [isError, loading, commentDisabled, onSubmit, comment]);

    const inputAdornmet = useMemo(
      () => (
        <SubmitButton
          disabled={commentDisabled}
          loading={loading}
          onClick={handleSubmit}
          ref={submitButtonRef}
        >
          Post
        </SubmitButton>
      ),
      [handleSubmit, commentDisabled, loading]
    );

    const handleFocus = () => {
      setIsSubmitVisible(true);
      if (commentDisabled) {
        setMultiline(false);
      }
    };

    const handleBlur = () => {
      if (!comment) {
        setIsSubmitVisible(false);
      }
    };

    return (
      <InputBox ref={inputBoxRef}>
        <CommentTextField
          InputProps={{
            endAdornment: isSubmitVisible && inputAdornmet,
            disableUnderline: true,
            className: multiline ? 'multiline-active' : '',
          }}
          error={isError}
          fullWidth
          helperText={isError && helperText}
          inputRef={ref}
          maxRows={10}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={label}
          value={comment}
          variant="filled"
        />
        <CalculationHelperSpan ref={helperSpanRef} />
      </InputBox>
    );
  }
);

export default CommentInput;

CommentInput.displayName = 'CommentInput';
