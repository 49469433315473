import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface QuestionChoiceContainerProps extends BoxProps {
  expanded: 0 | 1;
  scale: number;
}

const QuestionChoiceContainer = styled(Box)<QuestionChoiceContainerProps>(
  ({ expanded, scale, theme: { palette, spacing, transitions } }) => ({
    borderRadius: 9,
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    transition: transitions.create(['background-color', 'border-color']),
    minHeight: spacing(14),
    overflow: 'hidden',

    '& > .MuiBox-root': {
      '& > .MuiFormControlLabel-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        flexGrow: 1,

        '.MuiRadio-root': {
          alignSelf: 'baseline',
          padding: spacing(4 * scale, 2 * scale, 4 * scale, 4 * scale),
        },

        '& > .MuiTypography-root': {
          flexGrow: 1,
          padding: spacing(4, 4, 4, 0),

          '.hot-key': {
            margin: spacing(0, 0, 0, 4),
          },
        },
      },
    },

    '& .MuiIconButton-root': {
      '& .MuiSvgIcon-root': {
        transform: expanded ? 'rotate(0)' : 'rotate(-90deg)',
        transition: transitions.create(['color', 'transform']),
      },
    },

    '&:hover': {
      '& .MuiIconButton-root': {
        '& .MuiSvgIcon-root': {
          color: palette.icon.dark,
        },
      },
    },

    '&:hover:not(.valid):not(.invalid)': {
      borderColor: palette.stroke.dark,
    },

    '&.selected:not(.valid):not(.invalid)': {
      borderColor: palette.primary.main,

      '& .MuiFormControlLabel-root .MuiRadio-root': {
        '& .MuiSvgIcon-root': {
          color: palette.primary.main,
        },
      },
    },

    '&.invalid': {
      backgroundColor: palette.error.extraLight,
      borderColor: palette.error.main,

      '& .MuiFormControlLabel-root .MuiRadio-root': {
        '& .MuiSvgIcon-root': {
          color: palette.error.main,
        },
      },
    },

    '&.valid': {
      backgroundColor: palette.success.extraLight,
      borderColor: palette.success.dark,

      '& .MuiFormControlLabel-root .MuiRadio-root': {
        '& .MuiSvgIcon-root': {
          color: palette.success.dark,
        },
      },
    },
  })
);

export default QuestionChoiceContainer;
