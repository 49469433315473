import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Body, H4 } from 'components/Typography';

export const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: spacing(0, 0, 6, 0),
  width: '100%',

  '& .info': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const InfoLabel = styled(Body)(
  ({ theme: { palette, spacing, typography } }) => ({
    color: palette.contrast.light,
    ...typography.body1Medium,
    paddingRight: spacing(2),
  })
);

export const InfoValue = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    textAlign: 'center',
    ...typography.body1Medium,
    gap: spacing(2),
    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
    },
  })
);

export const MainHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const MainHeader = styled(H4)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(2),
}));

export const ExerciseModeLabel = styled('span')(
  ({ theme: { breakpoints } }) => ({
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'inline',
    },
  })
);

export const Separator = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  color: palette.text.disabled,
  display: 'inline',

  [breakpoints.up('md')]: {
    display: 'inline',
  },
}));
