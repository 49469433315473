import React, { useCallback, useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { EProductType } from '@quesmed/types-rn/models';

import {
  KnowledgeLibraryRouterParams,
  LearningMaterialItem,
  QbankVideoLibraryState,
  useQbankVideoLibrary,
} from 'components/LearningMaterials';
import { BuilderViewMode, Nullable, ToggleEvent } from 'types';
import { usePrevious } from 'hooks';
import { BUILDER_VIEWS } from 'constants/general';
import { paths } from 'Router';
import { CircularProgress } from 'components/CircularProgress';
import { ErrorMessage } from 'components/Error/ErrorMessage';

export const useQbankVideoLibraryData = () => {
  return useOutletContext<QbankVideoLibraryState>();
};

const QbankVideoLibrary = (): JSX.Element => {
  const { loading, topicsMap, categories } = useQbankVideoLibrary();
  const {
    sectionId: topicId,
    entitlementId,
    chapterId,
  } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const [activeView, setActiveView] = useState(BUILDER_VIEWS[0].value);
  const [activeCategory, setActiveCategory] = useState(
    entitlementId ? Number(entitlementId) : categories[0]?.value
  );
  const previousCategory = usePrevious(activeCategory);
  const previousTopicId = usePrevious(topicId);
  const previousChapterId = usePrevious(chapterId);
  const [searchItems, setSearchItems] =
    useState<Nullable<LearningMaterialItem[]>>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const displayTopics = topicsMap ? topicsMap[activeCategory] : null;
  const resetSearch =
    Boolean(displayTopics) &&
    (previousCategory !== activeCategory ||
      previousTopicId !== topicId ||
      previousChapterId !== chapterId);

  const activeCategoryLabel =
    categories.find(({ value }) => value === activeCategory)?.label || '';

  useEffect(() => {
    if (resetSearch) {
      setSearchItems(null);
    }
  }, [resetSearch]);

  useEffect(() => {
    if (categories.length && !activeCategory) {
      if (entitlementId) {
        setActiveCategory(Number(entitlementId));
      } else {
        setActiveCategory(Number(categories[0].value));
      }
    }
  }, [categories, activeCategory, entitlementId]);

  const handleActiveView = (e: ToggleEvent, view: BuilderViewMode) =>
    setActiveView(view);

  const setTopics = useCallback(
    (text: string) => {
      const normalizedSearchString = text.toLowerCase().trim();
      if (topicsMap && text) {
        const videoIds = new Set<number>();

        const filteredVideos = topicsMap[activeCategory].flatMap(
          ({ videos = [] }) =>
            videos.filter(({ title, id }) => {
              const shouldStay =
                !videoIds.has(Number(id)) &&
                title.toLowerCase().includes(normalizedSearchString);
              videoIds.add(Number(id));

              return shouldStay;
            })
        );

        return setSearchItems(filteredVideos);
      }

      setSearchItems(null);
    },
    [activeCategory, topicsMap]
  );

  useEffect(() => {
    setTopics(searchTerm);
  }, [searchTerm, setTopics]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setSearchTerm(searchString);
      setTopics(searchString);
    },
    [setTopics]
  );

  const handleActiveCategory = useCallback(
    (_: ToggleEvent, type: EProductType) => {
      setActiveCategory(Number(type));
      navigate(paths.videoLibrary.root);
    },
    [navigate]
  );

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!categories.length || displayTopics === null) {
    return <ErrorMessage />;
  }

  return (
    <Outlet
      context={{
        activeCategory,
        activeView,
        activeCategoryLabel,
        categories,
        topics: displayTopics,
        loading,
        resetSearch,
        searchItems: searchItems,
        search: handleSearch,
        searchBy: 'videos',
        switchCategory: handleActiveCategory,
        switchView: handleActiveView,
        searchTerm,
      }}
    />
  );
};

export default QbankVideoLibrary;
