import React from 'react';

import { ItemStatusLabel, Nilable } from 'types';
import { calculatePercent } from 'utils';
import {
  LineChartContainer,
  LineChartItemStyle,
  LineChartStyle,
} from './LineChartStyles';
import ProgressLineChartTooltip from './ProgressLineChartTooltip';
import { LineChartItem } from './types';

export interface ProgressLineChartProps {
  total: Nilable<number>;
  title?: string;
  data: LineChartItem[];
  notAnsweredLabel?: ItemStatusLabel;
  performanceWidget?: boolean;
}

const parseData = (
  data: LineChartItem[],
  total: number,
  notAnsweredLabel?: ItemStatusLabel
) => {
  const notAnswered = data.reduce((a, b) => a - (b.value ?? 0), total);

  return notAnswered
    ? [
        ...data,
        {
          type: ItemStatusLabel.NotAnswered,
          value: notAnswered,
          label: notAnsweredLabel
            ? notAnsweredLabel
            : ItemStatusLabel.NotAnswered,
        } as LineChartItem,
      ]
    : data;
};

const ProgressLineChart = ({
  data,
  title,
  total,
  notAnsweredLabel,
  performanceWidget = false,
}: ProgressLineChartProps) => {
  const numberTotal = total ?? 0;
  const progressChartData = parseData(data, numberTotal, notAnsweredLabel);
  const performanceTotal = data.reduce((a, b) => a + (b.value ?? 0), 0);

  return (
    <ProgressLineChartTooltip
      data={performanceWidget ? data : progressChartData}
      name={title}
      total={performanceWidget ? performanceTotal : numberTotal}
    >
      <LineChartContainer>
        <LineChartStyle
          className={performanceWidget ? 'performance' : undefined}
        >
          {progressChartData.map(({ type, value, label }) => {
            const numberValue = value ?? 0;
            const percent = calculatePercent(numberValue, numberTotal);

            return (
              percent > 0 && (
                <LineChartItemStyle key={label} percent={percent} type={type} />
              )
            );
          })}
        </LineChartStyle>
      </LineChartContainer>
    </ProgressLineChartTooltip>
  );
};

export default ProgressLineChart;
