import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { getReadingStatusIcon, getReadingStatusLabel } from '../utils/index';
import { constSize } from 'utils';
import { Nullable } from 'types';

const IconBox = styled(Box)(({ theme: { palette, transitions } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: transitions.create(['color']),

  '&.small .MuiSvgIcon-root': {
    ...constSize('20px'),
  },

  '&.normal .MuiSvgIcon-root': {
    ...constSize('24px'),
  },

  '&.urgent': {
    color: palette.warning.main,

    '&:hover': {
      color: palette.warning.dark,
    },
  },

  '&.revising': {
    color: palette.primary.main,

    '&:hover': {
      color: palette.primary.dark,
    },
  },

  '&.complete, &.watched': {
    color: palette.success.light,

    '&:hover': {
      color: palette.success.main,
    },
  },

  '&.unread, &.unwatched': {
    color: palette.icon.main,

    '&:hover': {
      color: palette.icon.dark,
    },
  },
}));

interface ReadingStatusIconProps {
  status?: Nullable<EUserLearningStatus>;
  size?: 'small' | 'normal';
  add?: boolean;
}

const ReadingStatusIcon = ({
  size = 'normal',
  status,
  add = true,
}: ReadingStatusIconProps) => {
  const Icon = getReadingStatusIcon(status, add);

  return (
    <IconBox
      className={clsx(getReadingStatusLabel(status).toLowerCase(), size)}
    >
      {<Icon />}
    </IconBox>
  );
};

export default ReadingStatusIcon;
