import React from 'react';
import { mdiFolderOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FolderOutlineIcon = (props: IconProps) => (
  <Icon path={mdiFolderOutline} {...props} />
);

export default FolderOutlineIcon;
