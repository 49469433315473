import { useSubscription } from '@apollo/client';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import {
  IMarksheetMarkData,
  IOnMarksheetMarkChangeData,
  IOnMarksheetMarkChangeVar,
  ON_MARKSHEET_MARK_CHANGE,
} from '@quesmed/types-rn/resolvers/subscription';
import { useCallback, useEffect } from 'react';

import { useCurrentUser } from 'Auth';
import { useSnackbar } from 'components/Snackbar';

const useOnMarksheetMarkChange = (sessionId = '') => {
  const { id: currentUserId } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar({
    unique: true,
  });
  const { data } = useSubscription<
    IOnMarksheetMarkChangeData,
    IOnMarksheetMarkChangeVar
  >(ON_MARKSHEET_MARK_CHANGE, {
    variables: {
      sessionId,
    },
    skip: !sessionId,
    onData: ({ client, data: subscriptionData }) => {
      const {
        mark,
        markId,
        marksheetId,
        newMarkId,
        questionChoiceId,
        isAnswered,
        timeTaken,
        striked,
        action,
      } = subscriptionData.data?.onMarksheetMarkChange || {};

      if (marksheetId) {
        const prevData = client.readQuery<IMarksheetData, IMarksheetVar>({
          variables: { id: marksheetId },
          query: MARKSHEET,
        });
        if (prevData) {
          const { marksheet } = prevData.restricted || {};

          const newMarksheet = { ...marksheet };
          const { marks } = newMarksheet;
          const index = marks.findIndex(
            ({ id }) => Number(id) === Number(markId)
          );

          if (index < 0 || index >= marks.length) {
            return;
          }

          let updatedMark = marks[index];

          if (action === EMarksheetAction.CHOICE_SELECTED) {
            updatedMark = {
              ...updatedMark,
              questionChoiceId: questionChoiceId ? questionChoiceId : null,
            };
          }

          if (action === EMarksheetAction.QUESTION_CHANGE) {
            newMarksheet.currentMarkId = newMarkId;

            updatedMark = {
              ...updatedMark,
              questionChoiceId: updatedMark.isAnswered
                ? updatedMark.questionChoiceId
                : null,
              timeTaken: timeTaken || 0,
            };
          }

          if (action === EMarksheetAction.ANSWER_SELECTED) {
            updatedMark = {
              ...updatedMark,
              timeTaken: timeTaken || 0,
              isAnswered: isAnswered || false,
              mark: mark || null,
            };
          }

          if (action === EMarksheetAction.MARK_CHECK_UNCHECK) {
            updatedMark = {
              ...updatedMark,
              striked: striked || [],
            };
          }

          newMarksheet.marks = marks.map(mark =>
            markId === mark.id ? updatedMark : mark
          );

          client.writeQuery({
            query: MARKSHEET,
            variables: { id: marksheetId },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                marksheet: {
                  ...newMarksheet,
                },
              },
            },
          });
        }
      }
    },
  });

  const { onMarksheetMarkChange } = data || {};

  const parseAction = useCallback(
    (currentUserId?: number, actionData?: IMarksheetMarkData) => {
      const { action, user } = actionData || {};
      const { displayName, id } = user || {};
      const isNotCurrentUser = id !== currentUserId;

      if (action !== undefined) {
        switch (action) {
          case EMarksheetAction.CHOICE_SELECTED: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has selected an answer.`;
              enqueueSnackbar(message);
            }
            break;
          }
          case EMarksheetAction.QUESTION_CHANGE: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has changed the question.`;
              enqueueSnackbar(message);
            }
            break;
          }
          case EMarksheetAction.ANSWER_SELECTED: {
            if (isNotCurrentUser && user) {
              const message = `${displayName} has answered the question.`;
              enqueueSnackbar(message);
            }
            break;
          }
          default: {
            return;
          }
        }
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    parseAction(currentUserId, onMarksheetMarkChange);
  }, [currentUserId, onMarksheetMarkChange, parseAction]);
};

export default useOnMarksheetMarkChange;
