import { IPicture } from '@quesmed/types-rn/models';

import { IMAGE_URL } from 'config/constants';
import { sliceUri } from '../string/cutText';
import { logError } from '../error/logError';

const lightgalleryRegex = /\[(lightgallery){1}[0-9]{0,}\]/i;
const lightgalleryText = '[lightgallery';

export enum ImageSize {
  IMG_FULL,
  IMG_256,
  IMG_512,
}

const getImagePathBySize = (picture: IPicture, size: ImageSize): string => {
  const { path, path512, path256 } = picture;

  let url = '';

  switch (size) {
    case ImageSize.IMG_256:
      url = path256;
      break;
    case ImageSize.IMG_512:
      url = path512;
      break;
    case ImageSize.IMG_FULL:
    default:
      url = path;
      break;
  }

  return sliceUri(url);
};

const parseBrief =
  (size: ImageSize = ImageSize.IMG_FULL) =>
  (end?: number) =>
  (
    source: string | null,
    pictures?: IPicture[],
    withQuestionImage?: boolean
  ): string => {
    if (!source) {
      return '';
    }

    const text = source.replaceAll(/lightgallery/gi, 'lightgallery');

    const galleryStart = text.indexOf(lightgalleryText);

    if (galleryStart < 0) {
      return text;
    }

    const galleryEnd = galleryStart + lightgalleryText.length;
    const isLightgallery = lightgalleryRegex.test(text);

    if (!pictures || pictures.length < 1) {
      return end ? text.substring(0, end) : text;
    }

    if (end && end < galleryStart) {
      return text.substring(0, end);
    }
    const markdownImages = pictures.map((picture, index) => {
      const path = `${IMAGE_URL}${getImagePathBySize(picture, size)}`;

      const caption = JSON.stringify({
        path256: `${IMAGE_URL}${getImagePathBySize(
          picture,
          ImageSize.IMG_256
        )}`,
        path512: `${IMAGE_URL}${getImagePathBySize(
          picture,
          ImageSize.IMG_512
        )}`,
        caption: picture.caption || '',
      });

      return {
        pictureId: picture.id,
        pictureIdx: picture.index,
        img: `![${caption || ''}](${path} "${
          withQuestionImage ? Number(index) + 1 : index
        }")`,
      };
    });

    let finalText = end
      ? text.substring(0, galleryEnd >= 400 ? galleryEnd : end)
      : text;

    if (isLightgallery) {
      markdownImages.forEach(({ pictureId, pictureIdx, img }) => {
        const tagToReplace = `[lightgallery${!pictureIdx ? '' : pictureIdx}]`;
        if (finalText.indexOf(lightgalleryText) >= 0) {
          finalText = finalText.replace(tagToReplace, img);
        } else {
          logError({
            message: 'Incorrect picture index',
            pictureId,
            pictureIdx,
          });
        }
      });

      return finalText;
    }

    return end ? finalText : finalText + '\n\n' + markdownImages[0] + '\n';
  };

const getPath =
  (uri: string) =>
  (size: ImageSize) =>
  (pictures?: IPicture[]): string => {
    if (!pictures || pictures.length < 1) {
      return '';
    }
    const [picture] = pictures;

    return `${uri}/${getImagePathBySize(picture, size)}`;
  };

export const getFullImagePath = getPath(IMAGE_URL)(ImageSize.IMG_FULL);
export const parseTrimmedText = parseBrief(ImageSize.IMG_256)(400);
export const parseFullText = parseBrief(ImageSize.IMG_FULL)();
