import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { IQuestionChoice } from '@quesmed/types-rn/models';

import Choice from './Choice';
import setAnswerState from './setAnswerState';
import { Nullable } from 'types';

interface ChoicesProps {
  choices: IQuestionChoice[];
  chosenAnswers?: Nullable<string[]>;
  checked?: string[];
  onChange?: (id: string) => void;
  isAnswered?: boolean;
  correctAnswers: string[];
  preview?: boolean;
}

const ChoicesContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
}));

const Choices = ({
  choices,
  chosenAnswers,
  onChange,
  checked = [],
  isAnswered = false,
  correctAnswers,
  preview,
}: ChoicesProps): JSX.Element => {
  const handleChange = (label: string) => () => {
    onChange?.(label);
  };

  return (
    <ChoicesContainer>
      {choices.map(({ id, name, explanation, label }, index) => (
        <Choice
          checked={checked.includes(label)}
          choiceVariant={index + 1}
          explanation={explanation}
          id={id}
          isAnswered={isAnswered}
          key={id}
          name={name}
          onChange={handleChange(label)}
          preview={preview}
          state={setAnswerState(
            isAnswered,
            correctAnswers.includes(label),
            label,
            chosenAnswers
          )}
        />
      ))}
    </ChoicesContainer>
  );
};

export default Choices;
