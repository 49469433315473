import React, { useEffect, useState } from 'react';
import {
  IMarksheetMark,
  IQuestionChoice,
  IQuestionRanking,
} from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { arrayMove, List, OnChangeMeta } from 'react-movable';

import RankingQuestionChoice from './RankingQuestionChoice';
import RankingQuestionFeedback from './RankingQuestionFeedback';
import { parseJSON } from 'utils';
import { RankingAnswer } from 'types';

const ListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export interface RankingQuestionProps {
  mark: IMarksheetMark;
  setUserAnswer: (answer: string) => void;
  showAnswers: boolean;
}

const RankingQuestion = ({
  mark,
  setUserAnswer,
  showAnswers,
}: RankingQuestionProps): JSX.Element => {
  const [items, setItems] = useState<IQuestionChoice[]>([]);
  const { question, mark: userAnswer } = mark;
  const { choices, rankingAnswer, explanation } = question as IQuestionRanking;

  useEffect(() => {
    setItems(choices);
  }, [choices]);

  useEffect(() => {
    const answer = userAnswer ? JSON.stringify(userAnswer) : '';

    setUserAnswer(answer);

    if (answer) {
      const parsedAnswer = parseJSON<RankingAnswer>(answer);

      if (parsedAnswer) {
        setItems(
          [...choices].sort(
            (a, b) =>
              parsedAnswer.indexOf(a.label) - parsedAnswer.indexOf(b.label)
          )
        );
      }
    }
  }, [userAnswer, setUserAnswer, choices]);

  const handleChange = ({ oldIndex, newIndex }: OnChangeMeta) => {
    const newItems = arrayMove(items, oldIndex, newIndex);
    setItems(newItems);
    setUserAnswer(JSON.stringify(newItems.map(({ label }) => label)));
  };

  return (
    <Box>
      {showAnswers ? (
        <RankingQuestionFeedback
          choices={choices}
          correctAnswer={rankingAnswer}
          explanation={explanation}
          mark={mark}
          userAnswer={userAnswer}
        />
      ) : (
        <List
          onChange={handleChange}
          renderItem={({
            value: { id, name },
            isDragged,
            index = 0,
            props,
          }) => (
            <RankingQuestionChoice
              index={index}
              isDragged={isDragged}
              key={id}
              name={name}
              props={props}
            />
          )}
          renderList={({ children, props }) => (
            <ListContainer {...props}>{children}</ListContainer>
          )}
          values={items}
        />
      )}
    </Box>
  );
};

export default RankingQuestion;
