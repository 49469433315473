import React, { useCallback, useEffect, useState } from 'react';
import { IMarksheetMark, IQuestionSelect3 } from '@quesmed/types-rn/models';
import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardKey, Select3Answer } from 'types';
import Choices from './Choices';
import SelectThreeQuestionFeedback from './SelectThreeQuestionFeedback';
import { parseJSON } from 'utils';

interface SelectThreeQuestionProps {
  mark: IMarksheetMark;
  setUserAnswer: (answer: string) => void;
  showAnswers: boolean;
}

const SelectThreeQuestion = ({
  mark,
  showAnswers,
  setUserAnswer,
}: SelectThreeQuestionProps): JSX.Element => {
  const [checked, setChecked] = useState<string[]>([]);
  const { question, mark: userAnswer } = mark;
  const { choices, select3Answer } = question as IQuestionSelect3;

  useEffect(() => {
    setChecked(parseJSON<Select3Answer>(userAnswer) || []);
  }, [userAnswer, setUserAnswer]);

  useEffect(() => {
    setUserAnswer(checked.length === 3 ? JSON.stringify(checked) : '');
  }, [checked, setUserAnswer]);

  const handleChange = useCallback(
    (choiceLabel: string) => {
      if (checked.includes(choiceLabel)) {
        setChecked(checked.filter(item => item !== choiceLabel));
      } else {
        setChecked([...checked, choiceLabel]);
      }
    },
    [checked, setChecked]
  );
  const handleChangeByIndex = useCallback(
    (index: number) => () => {
      if (showAnswers) {
        return;
      }
      const { label } = choices[index];
      if (label) {
        handleChange(label);
      }
    },
    [choices, handleChange, showAnswers]
  );
  useHotkeys(
    KeyboardKey.One,
    handleChangeByIndex(0),
    {
      enabled: !showAnswers && choices.length > 0,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Two,
    handleChangeByIndex(1),
    {
      enabled: !showAnswers && choices.length > 1,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Three,
    handleChangeByIndex(2),
    {
      enabled: !showAnswers && choices.length > 2,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Four,
    handleChangeByIndex(3),
    {
      enabled: !showAnswers && choices.length > 3,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Five,
    handleChangeByIndex(4),
    {
      enabled: !showAnswers && choices.length > 4,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Six,
    handleChangeByIndex(5),
    {
      enabled: !showAnswers && choices.length > 5,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Seven,
    handleChangeByIndex(6),
    {
      enabled: !showAnswers && choices.length > 6,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Eight,
    handleChangeByIndex(7),
    {
      enabled: !showAnswers && choices.length > 7,
    },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Nine,
    handleChangeByIndex(8),
    {
      enabled: !showAnswers && choices.length === 9,
    },
    [handleChangeByIndex]
  );

  if (showAnswers) {
    return <SelectThreeQuestionFeedback mark={mark} />;
  }

  return (
    <Choices
      checked={checked}
      choices={choices}
      correctAnswers={select3Answer}
      onChange={handleChange}
    />
  );
};

export default React.memo(SelectThreeQuestion);
