import { useQuery } from '@apollo/client';
import {
  IQuestionData,
  IQuestionVar,
  QUESTION,
} from '@quesmed/types-rn/resolvers/query/restricted';
import React from 'react';
import { useParams } from 'react-router-dom';

import QuestionPreview from './QuestionPreview';
import { CircularProgress } from 'components/CircularProgress';
import { ErrorMessage } from 'components/Error/ErrorMessage';

const QuestionQuery = (): JSX.Element => {
  const { questionId = '' } = useParams<{ questionId: string }>();

  const { data, loading } = useQuery<IQuestionData, IQuestionVar>(QUESTION, {
    skip: !questionId,
    variables: {
      id: Number(questionId),
    },
  });

  const { question } = data?.restricted || {};

  if (loading) {
    return <CircularProgress description="Loading data..." />;
  }

  if (!question) {
    return <ErrorMessage />;
  }

  return <QuestionPreview question={question} />;
};

export default QuestionQuery;
