import React from 'react';

import { Modal, ModalProps } from 'components/Modal';

interface AcceptJoinMatchedGroupModalProps extends ModalProps {
  participantsCount: number;
}

const AcceptJoiningMatchedGroupModal = ({
  participantsCount,
  open,
  onClose,
  onSubmit,
}: AcceptJoinMatchedGroupModalProps): JSX.Element => (
  <Modal
    description={`We have found a team of ${participantsCount} players that matches your preferences. Join the team and start playing together.`}
    maxWidth="md"
    noPaddingY
    onClose={onClose}
    onSubmit={onSubmit}
    open={open}
    showCloseButton
    sizeVariant="md"
    submitLabel="Join team"
    title="Join a study group"
  />
);

export default AcceptJoiningMatchedGroupModal;
