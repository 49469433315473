import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ExerciseLaunchedButton } from 'components/ExerciseLaunchedButton';
import { HotKey } from 'components/HotKey';
import { ArrowLeftThinIcon, ArrowRightThinIcon } from 'components/Icons';

const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  width: '100%',
  minWidth: '260px',
}));

interface ExerciseReviewNavigationProps {
  nextDisabled: boolean;
  onClickNext: () => void;
  prevDisabled: boolean;
  onClickPrev: () => void;
}

const ExerciseReviewNavigation = ({
  onClickNext,
  onClickPrev,
  prevDisabled,
  nextDisabled,
}: ExerciseReviewNavigationProps): JSX.Element => (
  <Wrapper>
    <ExerciseLaunchedButton
      disabled={prevDisabled}
      hotKey={
        <HotKey
          disabled={prevDisabled}
          hotKey={<ArrowLeftThinIcon viewBox="-2 -2 28 28" />}
          noMargin
        />
      }
      hotKeyPosition="start"
      onClick={onClickPrev}
    >
      Previous
    </ExerciseLaunchedButton>
    <ExerciseLaunchedButton
      disabled={nextDisabled}
      hotKey={
        <HotKey
          disabled={nextDisabled}
          hotKey={<ArrowRightThinIcon viewBox="-2 -2 28 28" />}
          noMargin
        />
      }
      onClick={onClickNext}
    >
      Next
    </ExerciseLaunchedButton>
  </Wrapper>
);

export default ExerciseReviewNavigation;
