import { IMarksheet } from '@quesmed/types-rn/models';
import isNil from 'lodash/isNil';

import { parseDate } from 'utils/dataTime';

export const checkIfFinished = (quiz: IMarksheet) => {
  const {
    correct,
    endedAt,
    incorrect,
    completed,
    totalQuestions,
    isTestMarksheet,
  } = quiz;

  if (!isNil(completed)) {
    return completed;
  }

  const allAnswered =
    !isNil(correct) &&
    !isNil(incorrect) &&
    !isNil(totalQuestions) &&
    correct + incorrect === totalQuestions;

  if (isTestMarksheet && !allAnswered) {
    return new Date().getTime() > new Date(parseDate(endedAt)).getTime();
  }

  return allAnswered;
};
