import React, { useCallback, useEffect } from 'react';
import { IUser } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { StationModal, useMatchmaking, useStationModalState } from '../hooks';
import { useCurrentUser } from 'Auth';
import ControlMatchmakingModal from './ControlMatchmakingModal';
import AcceptJoiningMatchedGroupModal from './AcceptJoiningMatchedGroupModal';
import RejectMatchmakingModal from './RejectMatchmakingModal';

const MAX_NUMBER_OF_BUDDIESSTUDY_SLOTS = 4;
const { stations } = paths;
const { root } = stations;
interface MatchmakingProps {
  sessionId?: string;
  marksheetId?: number;
  members?: IUser[];
}

const Matchmaking = ({
  members,
  sessionId,
  marksheetId,
}: MatchmakingProps): JSX.Element | null => {
  const navigate = useNavigate();
  const { shownModal, closeModal, openModal } = useStationModalState();
  const { id: currentUserId } = useCurrentUser();
  const participantsSlots =
    MAX_NUMBER_OF_BUDDIESSTUDY_SLOTS - (members?.length || 0);
  const open =
    shownModal === StationModal.MatchmakingStartModal ||
    shownModal === StationModal.MatchmakingRejectModal ||
    shownModal === StationModal.MatchmakingAcceptModal;

  const {
    loading,
    matchedUsers,
    acceptMatchmaking,
    stopMatchmaking,
    startMatchmaking,
  } = useMatchmaking(sessionId, marksheetId, currentUserId);

  const handleShowRejectModal = useCallback(
    () => openModal(StationModal.MatchmakingRejectModal),
    [openModal]
  );

  const handleShowAcceptModal = useCallback(
    () => openModal(StationModal.MatchmakingAcceptModal),
    [openModal]
  );

  const handleStopMatchmaking = useCallback(() => {
    stopMatchmaking();
    closeModal();
  }, [closeModal, stopMatchmaking]);

  const handleRejectJoining = () => {
    closeModal();
    navigate(root);
  };

  useEffect(() => {
    return () => {
      if (open) {
        closeModal();
      }
    };
  }, [closeModal, open]);

  return open ? (
    <>
      <ControlMatchmakingModal
        loading={loading}
        onClose={closeModal}
        open={shownModal === StationModal.MatchmakingStartModal}
        participantsCount={matchedUsers}
        participantsSlots={participantsSlots}
        showCloseButton
        startMatchmaking={startMatchmaking}
        stopMatchmaking={handleStopMatchmaking}
      />
      <AcceptJoiningMatchedGroupModal
        onClose={handleShowRejectModal}
        onSubmit={acceptMatchmaking}
        open={shownModal === StationModal.MatchmakingAcceptModal}
        participantsCount={matchedUsers}
      />
      <RejectMatchmakingModal
        onBack={handleShowAcceptModal}
        onSubmit={handleRejectJoining}
        open={shownModal === StationModal.MatchmakingRejectModal}
      />
    </>
  ) : null;
};

export default Matchmaking;
