import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { H3, H6 } from 'components/Typography';
import {
  ProgressLineChart,
  ProgressLineChartProps,
} from 'components/ProgressLineChart';
import { Card } from 'components/Card';
import StatusDot from 'components/StatusDot/StatusDot';
import { ProgressCorrectIcon, ProgressIncorrectIcon } from 'components/Icons';
import { ItemStatusLabel, Nullable } from 'types';
import { constSize, round } from 'utils';

const Container = styled(Box)(({ theme: { palette, spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  color: palette.text.primary,
}));

const Details = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
  gap: spacing(2),
}));

const Score = styled(H3)({ fontFeatureSettings: "'tnum' on, 'Inum' on" });

const Data = styled(Box)(({ theme: { spacing, typography } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
  ...typography.body1,
}));

const Result = styled(Box)(({ theme: { palette, spacing, breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
  position: 'relative',

  '& .MuiSvgIcon-root': {
    ...constSize('32px'),
    marginTop: spacing(1),
    color: palette.error.main,
  },

  '&.passed': {
    '.MuiSvgIcon-root': {
      color: palette.success.main,
    },
  },

  [breakpoints.up('md')]: {
    position: 'absolute',
    right: 0,
  },
}));

type IconOrNull = Nullable<JSX.Element>;

const getResultIcon = (passed: Nullable<boolean>): IconOrNull => {
  if (passed === null) {
    return null;
  }

  return passed ? <ProgressCorrectIcon /> : <ProgressIncorrectIcon />;
};

export interface ExerciseSummaryProgressProps extends ProgressLineChartProps {
  correctCount: number;
  correctLabel: string;
  incorrectCount?: number;
  header?: string;
  detatilsTitle?: string;
  passed?: Nullable<boolean>;
  score: number;
  notAnsweredLabel?: ItemStatusLabel;
}

const ExerciseSummaryProgress = ({
  detatilsTitle,
  score,
  header = 'Session score',
  correctLabel,
  correctCount,
  incorrectCount,
  passed = null,
  total,
  ...rest
}: ExerciseSummaryProgressProps): JSX.Element => (
  <Card sx={{ p: 8 }}>
    <Container>
      <Details>
        <H6>{`${header}:`}</H6>
        <Result className={clsx({ passed })}>
          {getResultIcon(passed)}
          <Score>{`${round(
            incorrectCount
              ? (100 / (correctCount + incorrectCount)) * correctCount
              : score
          )}%`}</Score>
        </Result>
        <Data>
          <StatusDot status={ItemStatusLabel.Confident} />
          {`${correctLabel}: ${correctCount}/${total}`}
        </Data>
      </Details>
      <ProgressLineChart total={total} {...rest} />
    </Container>
  </Card>
);

export default ExerciseSummaryProgress;
