import { BottomOffsetVariant } from 'types';

const setBottomBarOffset = (
  visible: boolean,
  mobile: boolean
): BottomOffsetVariant => {
  if (!visible) {
    return 'tiny';
  }

  return mobile ? 'large' : 'small';
};

export default setBottomBarOffset;
