import React from 'react';
import { IMarksheetMark } from '@quesmed/types-rn/models';

import { setQuestionStatus } from 'utils';
import { SummaryColumn, SummaryTableKey } from 'components/ExerciseSummary';
import constants from 'config/constants';
import QuestionValidationMark from '../Questions/QuestionValidationMark';
import { FlaggedMarkup } from './FlaggedMarkup';

const { MILISECONDS_IN_SECOND } = constants;

export const getSummaryColumns = (
  isReview = false,
  isNavigator = false
): SummaryColumn<IMarksheetMark>[] => {
  const columns: SummaryColumn<IMarksheetMark>[] = [
    {
      key: SummaryTableKey.Number,
      align: 'left',
      label: SummaryTableKey.Number,
      formatter: (mark, number) => `Question ${number}`,
      type: 'default',
    },
    {
      key: SummaryTableKey.Content,
      align: 'left',
      label: SummaryTableKey.Content,
      formatter: ({ question }) => question.question,
      type: 'default',
    },
    {
      ...((!isNavigator && {
        key: SummaryTableKey.Topic,
        align: 'left',
        label: SummaryTableKey.Topic,
        formatter: ({ question }) => {
          const { topic } = question.concept || {};
          const { name = 'Topic name' } = topic || {};

          return name;
        },
        type: 'default',
      }) as SummaryColumn<IMarksheetMark>),
    },
    {
      ...((!isNavigator && {
        key: SummaryTableKey.Concept,
        align: 'left',
        label: SummaryTableKey.Concept,
        formatter: ({ question }) => {
          const { concept } = question;
          const { name = '' } = concept || {};

          return name;
        },
        type: 'default',
      }) as SummaryColumn<IMarksheetMark>),
    },
    {
      ...((!isNavigator && {
        key: SummaryTableKey.Duration,
        align: 'left',
        label: SummaryTableKey.Duration,
        formatter: ({ timeTaken }) => timeTaken * MILISECONDS_IN_SECOND || 0,
        type: 'default',
      }) as SummaryColumn<IMarksheetMark>),
    },
    {
      key: SummaryTableKey.Result,
      align: 'left',
      label: SummaryTableKey.Result,
      resultFormatter: mark => {
        const status = setQuestionStatus(mark, isReview);

        return {
          icon: <QuestionValidationMark showAsNotAnswered status={status} />,
          status,
          label: status.replace('-', ' '),
        };
      },
      type: 'default',
    },
    {
      ...((isNavigator && {
        key: SummaryTableKey.Flagged,
        align: 'left',
        label: SummaryTableKey.Flagged,
        formatter: ({ flagged }) => <FlaggedMarkup flagged={flagged} />,
        type: 'default',
      }) as SummaryColumn<IMarksheetMark>),
    },
    {
      key: SummaryTableKey.Chevron,
      align: 'left',
      label: 'chevron',
      type: 'chevron',
    },
  ];

  return columns.filter(col => Object.keys(col).length);
};
