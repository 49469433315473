import React, { memo, useEffect, useRef } from 'react';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import { EUserLearningStatus } from '@quesmed/types-rn/models';
import { NavLink, useLocation } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import { isNil } from 'lodash';

import { LockOutlineIcon } from 'components/Icons';
import ReadingStatusIcon from './ReadingStatusIcon';
import { Nullable } from 'types';
import { constSize } from 'utils';
import { Body } from 'components/Typography';

export type ItemProps<C extends React.ElementType = React.ElementType> =
  ListItemButtonProps<C, { component?: C }>;

const Name = styled(Body)({
  width: '100%',
});

const StyledItem = styled(ListItemButton)<ItemProps>(
  ({ theme: { palette, transitions, typography, spacing } }) => ({
    padding: spacing(2, 4, 2, 10),
    boxSizing: 'border-box',
    minHeight: '40px',
    color: palette.text.secondary,
    ...typography.body2Medium,
    marginLeft: spacing(-4),
    transition: transitions.create(['color', 'background-color']),
    borderRadius: '9px',

    '&:hover': {
      backgroundColor: palette.opacity.panelItem,
    },
  })
);
const StyledItemText = styled(ListItemText)({
  margin: 0,
});

const IconsBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  gap: spacing(1),

  '& .lock-icon': {
    ...constSize('20px'),
    color: palette.icon.light,
  },
}));

const StyledLink = styled(NavLink)(
  ({ theme: { palette, transitions, typography, spacing } }) => ({
    boxSizing: 'border-box',
    backgroundColor: palette.background.paper,
    color: palette.text.secondary,
    ...typography.body2Medium,
    borderRadius: '9px',
    display: 'inline-flex',
    gap: spacing(2),
    transition: transitions.create(['color', 'background-color']),

    '&:hover': {
      backgroundColor: palette.opacity.panelItem,
    },

    '&.active': {
      color: palette.primary.dark,
      backgroundColor: palette.opacity.panelItem,
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        width: '4px',
        height: '4px',
        borderRadius: '10px',
        background: palette.primary.dark,
        left: '24px',
      },
    },

    '&.MuiListItemButton-root.Mui-disabled': {
      color: palette.text.secondary,
      opacity: 1,
    },
  })
);

const showStatusIcon = (status?: Nullable<EUserLearningStatus>) =>
  !isNil(status) &&
  status !== EUserLearningStatus.ALL &&
  status !== EUserLearningStatus.UNREAD;

interface PanelSubItemNameProps {
  name: string;
  locked?: boolean;
  path: string;
  status: Nullable<EUserLearningStatus>;
  toggleExpand?: () => void;
}

const PanelSubItemName = ({
  name,
  path,
  locked,
  status,
  toggleExpand,
}: PanelSubItemNameProps): JSX.Element => {
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current && pathname === path) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [path, pathname, ref]);

  return (
    <StyledItem
      component={StyledLink}
      disabled={locked}
      onClick={toggleExpand}
      ref={ref}
      to={path}
    >
      <StyledItemText disableTypography primary={<Name>{name}</Name>} />
      <IconsBox>
        {showStatusIcon(status) ? (
          <ReadingStatusIcon size="small" status={status} />
        ) : null}
        {locked ? <LockOutlineIcon className="lock-icon" /> : null}
      </IconsBox>
    </StyledItem>
  );
};

export default memo(PanelSubItemName);
