import React from 'react';
import { IMarksheetMark, IQuestionEMQ } from '@quesmed/types-rn/models';

import { RadioGroupProps } from 'components/RadioGroup';
import MatchingQuestionCase from './MatchingQuestionCase';
import { correctCase } from './correctCase';
import { choiceStatus } from './choiceStatus';

export interface MatchingQuestionFeedbackProps
  extends Pick<IMarksheetMark, 'mark'>,
    Pick<IQuestionEMQ, 'cases' | 'emqAnswer'> {
  options: RadioGroupProps['options'];
  questionId: number;
}

const MatchingQuestionFeedback = ({
  mark: userAnswer,
  cases,
  emqAnswer,
  options,
  questionId,
}: MatchingQuestionFeedbackProps): JSX.Element => {
  const parsedAnswer = userAnswer
    ? (JSON.parse(userAnswer as string) as [string, string][])
    : [];

  const userAnswers = new Map(parsedAnswer);
  const correctAnswers = new Map(emqAnswer);

  return (
    <>
      {cases?.map(({ id, label: caseLabel, explanation, ...rest }) => {
        const isCaseCorrect = correctCase(emqAnswer, parsedAnswer, caseLabel);

        return (
          <MatchingQuestionCase
            caseContent={rest.case}
            caseId={id}
            explanation={explanation}
            key={id}
            options={options.map(({ label, value }) => ({
              label,
              value,
              status: choiceStatus(
                label,
                correctAnswers.get(caseLabel),
                userAnswers.get(caseLabel)
              ),
            }))}
            questionId={questionId}
            title={
              isCaseCorrect ? 'Correct (selected)' : 'Incorrect (selected)'
            }
            variant={isCaseCorrect ? 'success' : 'error'}
          />
        );
      })}
    </>
  );
};

export default MatchingQuestionFeedback;
