import { useMutation } from '@apollo/client';
import {
  DELETE_PRESET,
  IDeletePresetData,
  IDeletePresetVar,
  updateCacheOnDeletePrest,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';

const useDeletePresetMutation = (presetId: number) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deletePreset] = useMutation<IDeletePresetData, IDeletePresetVar>(
    DELETE_PRESET,
    {
      variables: {
        presetId: Number(presetId),
      },
      onCompleted: () => {
        enqueueSnackbar('Preset deleted successfully!');
      },
      update: updateCacheOnDeletePrest(),
    }
  );

  return { deletePreset };
};
export default useDeletePresetMutation;
