import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalProps } from 'components/Modal';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import { useLeaveStation } from './hooks';
import { usePlatform } from 'context/PlatformContext';
import { LeaveExerciseModal } from 'components/ExerciseModals';
import { useLeaveExerciseState } from 'hooks';
import { paths } from 'Router';

const { dashboard } = paths;

interface StationsLeaveModalProps
  extends Omit<
    ModalProps,
    'cancelLabel' | 'submitLabel' | 'onSubmit' | 'title'
  > {
  osceMarksheetId?: number;
  discard?: boolean;
}

const StationsLeaveModal = ({
  onClose,
  osceMarksheetId,
  discard,
  open,
}: StationsLeaveModalProps): JSX.Element => {
  const { leveStation, loading } = useLeaveStation(osceMarksheetId);
  const { setProduct } = usePlatform();
  const { leaveState, setLeaveState, setCanLeave, closeLeaveModal } =
    useLeaveExerciseState();
  const { path, state = {} } = leaveState;
  const { newProduct } = state;
  const navigate = useNavigate();

  const handleClose = () => {
    if (loading) {
      return;
    }

    setLeaveState({ path: '' });

    if (onClose) {
      onClose();
    } else {
      closeLeaveModal();
    }
  };

  const handleSubmit = () => {
    if (osceMarksheetId) {
      return leveStation();
    }

    setCanLeave(true);

    if (newProduct !== undefined) {
      setProduct(newProduct);
    }

    navigate(path || dashboard, { state: { leave: true } });
  };

  useEffect(() => {
    return () => {
      closeLeaveModal();
    };
  }, [closeLeaveModal]);

  return discard ? (
    <DiscardChangesModal
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
      showBackButton={false}
      submitLoading={loading}
    />
  ) : (
    <LeaveExerciseModal
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};

export default StationsLeaveModal;
