import { useCallback } from 'react';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import { MODIFY_TOPIC_SELECTION } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { MODIFY_TOPIC_SELECTION_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetInfoMutation from 'pages/Questions/hooks/useModifyMarksheetMutation';
import { SelectionState, SharedSelection } from 'types';

const stringifySelection = (data: SelectionState): string => {
  const temp: SharedSelection = {};

  [...data.values()].forEach(
    ({ id, selectedConceptsIds, conceptsAvailable }) => {
      temp[id] = {
        conceptsAvailable,
        conceptsSelected: [...selectedConceptsIds],
      };
    }
  );

  return JSON.stringify(temp);
};

const useShareTopicSelection = (marksheetId?: number) => {
  const [modify] = useModifyMarksheetInfoMutation(
    MODIFY_TOPIC_SELECTION,
    MODIFY_TOPIC_SELECTION_FRAGMENT
  );

  const shareSelection = useCallback(
    (selectionState: SelectionState) => {
      if (marksheetId) {
        modify({
          variables: {
            input: {
              action: EMarksheetAction.TOPIC_CONCEPT_SELECTION,
              marksheetId,
              preStartData: {
                topicConceptData: stringifySelection(selectionState),
              },
            },
          },
        });
      }
    },
    [marksheetId, modify]
  );

  return shareSelection;
};

export default useShareTopicSelection;
