import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/system/Box';

interface CommentsHeaderContainerProps extends BoxProps {
  expanded: 0 | 1;
}

export const CommentsContainer = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: '9px',
  border: `1px solid ${palette.stroke.main}`,
  backgroundColor: palette.background.paper,
  overflowX: 'hidden',
}));

export const CommentsHeader = styled(Box)<CommentsHeaderContainerProps>(
  ({ theme: { spacing, palette, transitions }, expanded }) => ({
    display: 'flex',
    width: '100%',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0, 4),
    cursor: 'pointer',

    '& .MuiIconButton-root': {
      transform: expanded ? 'rotate(-180deg)' : 'rotate(0)',
      transition: transitions.create(['color', 'transform', 'fill']),
      fill: palette.icon.main,
      padding: '0',
    },

    '&:hover': {
      '& .MuiIconButton-root': {
        '.MuiSvgIcon-root': {
          fill: palette.icon.dark,
        },
      },
    },

    '& > div': {
      display: 'flex',
    },

    '& > *:not(:last-child)': {
      marginRight: spacing(2),
    },
  })
);

export const CommentsHeadingContainer = styled(Box)(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(4),
  })
);

export const CommentsHeading = styled(Box)<{ scale: number }>(
  ({ scale, theme: { typography, spacing } }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: spacing(2),
    fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,
  })
);

export const CommentsListContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    padding: spacing(6, 6, 0, 6),
    maxHeight: '600px',
    overflowY: 'auto',
    borderTop: `1px solid ${palette.stroke.main}`,
    scrollBehavior: 'smooth',
  })
);

export const CommentInputBorderBox = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    borderTop: `1px solid ${palette.stroke.main}`,
    marginTop: spacing(6),
  })
);

export const CommentInputContainer = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: spacing(6),
  margin: spacing(0, 6, 6, 6),
}));
