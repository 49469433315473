import React from 'react';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Modal, ModalProps } from 'components/Modal';
import { getReadingStatusLabel } from '../utils';

interface ReadingStatusModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onSubmit' | 'loading'> {
  status: EUserLearningStatus;
  video?: boolean;
}

const ReadingStatusModal = ({
  open,
  onClose,
  onSubmit,
  loading,
  status,
  video,
}: ReadingStatusModalProps): JSX.Element => {
  const newStatusName = getReadingStatusLabel(status, video).toLowerCase();
  const text = `Mark all as ${newStatusName}`;

  return (
    <Modal
      cancelLabel="cancel"
      maxWidth="sm"
      noPaddingY
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitLabel={text}
      submitLoading={loading}
      title={text}
    >{`Are you sure you want to mark all ${
      video ? 'videos' : 'articles'
    } in this group as ${newStatusName}?`}</Modal>
  );
};

export default ReadingStatusModal;
