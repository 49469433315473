export const findUserIp = async (): Promise<string | null> => {
  try {
    const response = await fetch('https://api.ipify.org?format=json', {
      method: 'GET',
    });

    if (response.ok) {
      const data = await response.json();

      return data.ip;
    }
  } catch (error) {
    console.error('error', error);
  }

  return null;
};
