import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Fade from '@mui/material/Fade';
import {
  EEntitlementType,
  EMarksheetState,
  EProductType,
  IMarksheet,
} from '@quesmed/types-rn/models';
import { useLocation, useNavigate } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { styled } from '@mui/material/styles';

import ExerciseLayout from 'components/Layout/ExerciseLayout';
import PreLaunchLayout from 'components/Layout/PreLaunchLayout';
import { calcAllQuestions, calcQuestions, getConcepts, round } from 'utils';
import {
  CheckboxState,
  ExerciseType,
  ExtendedTopic,
  GameType,
  Nullable,
  PreBuildData,
  SelectionBarLabels,
  SelectionState,
} from 'types';
import {
  useIsMobile,
  useLeaveExerciseState,
  useMarksheetLeavingBlockade,
  useTopicQuery,
} from 'hooks';
import useTopicSelection from 'hooks/useTopicSelection';
import {
  BottomSelectionBar,
  setBottomBarOffset,
} from 'components/BottomSelectionBar';
import {
  KeyField,
  QuestionColumns,
  QUESTIONS_PROGRESS_LABELS,
  SelectionColumn,
  SelectionInputCellLabel,
  TopicQuizBuilder,
} from 'components/ExerciseBuilder';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import {
  BadgeScore,
  ProgressScoreBadgeProps,
} from 'components/ProgressScoreBadge';
import ProgressInitialIcon from 'components/Icons/ProgressInitialIcon';
import ProgressCorrectIcon from 'components/Icons/ProgressCorrectIcon';
import { ProgressStartedIcon } from 'components/Icons';
import {
  MAX_SCORE,
  QUESTION_DIFFICULTY_LEVELS,
  TRANSITION_TIMEOUT,
} from 'config/constants';
import QuestionsPreBuildModal from './QuestionsPreBuildModal';
import { setQuestionsProgressData } from './setQuestionsProgressData';
import { paths } from 'Router';
import { ParticipantList } from 'components/ParticipantCard';
import { useCurrentUser, useDemo } from 'Auth';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { useChangeMarksheetState, usePreBuildQuestions } from './hooks';
import QuestionsQuizLeaveModal from './QuestionsQuizLeaveModal';
import { Modal } from 'components/Modal';
import { TextField } from 'components/TextField';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import { Body } from 'components/Typography';
import {
  useCreatePresetMutation,
  useEditPresetMutation,
} from 'components/PresetsBuilder/hooks';
import { editSelection, extractConceptIds } from 'utils/marksheet';
import { usePlatform } from 'context/PlatformState';

const { dashboard } = paths;

const NameInputField = styled(TextField)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

const checkBoxLabelFormatter = (selectionState: SelectionState) => {
  const Label = (
    { id, name, concepts, totalQuestions }: ExtendedTopic,
    parentId?: number
  ): JSX.Element => {
    let counts = [0, 0];
    const selectedConceptsWithinTopic =
      selectionState.get(Number(parentId || id))?.selectedConceptsIds ||
      new Set();

    if (parentId) {
      const total = totalQuestions ?? 0;
      counts = [selectedConceptsWithinTopic.has(Number(id)) ? total : 0, total];
    } else {
      counts = calcQuestions(
        selectedConceptsWithinTopic,
        GameType.QUESTIONS,
        concepts
      );
    }

    const [count, total] = counts;

    return <SelectionInputCellLabel count={count} name={name} total={total} />;
  };

  return Label;
};

const notAnsweresFormatter = ({
  correctQuestions,
  incorrectQuestions,
  totalQuestions,
}: ExtendedTopic) =>
  totalQuestions
    ? totalQuestions - (correctQuestions ?? 0) - (incorrectQuestions ?? 0)
    : 0;

const progressFormatter = ({
  correctQuestions,
  incorrectQuestions,
  name,
  totalQuestions,
}: ExtendedTopic): ProgressLineChartProps => ({
  title: name,
  data: setQuestionsProgressData(correctQuestions, incorrectQuestions),
  total: totalQuestions,
});

const scoreFormatter = ({
  correctQuestions,
  incorrectQuestions,
}: ExtendedTopic): ProgressScoreBadgeProps => {
  const totalQuestions = (incorrectQuestions || 0) + (correctQuestions || 0);
  const total = totalQuestions ?? 0;
  const score = total
    ? round(((correctQuestions ?? 0) * MAX_SCORE) / total)
    : 0;
  let status = BadgeScore.NotStarted;
  let icon = <ProgressInitialIcon />;
  if (score === MAX_SCORE) {
    status = BadgeScore.Correct;
    icon = <ProgressCorrectIcon />;
  } else if (score > 0) {
    status = BadgeScore.InProgress;
    icon = <ProgressStartedIcon />;
  }

  return { score, icon, status };
};

const getBuildMarksheetId = (
  solo?: boolean,
  marksheetId?: number,
  initialSoloMarksheetId?: number
) => {
  if (solo && initialSoloMarksheetId) {
    return initialSoloMarksheetId;
  }

  if (!solo && marksheetId) {
    return marksheetId;
  }
};

interface RouterState {
  marksheetId?: number;
  isMarksheetOutdated?: boolean;
}

interface QuestionsQuizBuilderProps {
  marksheet?: IMarksheet;
}

const QuestionsQuizBuilder = ({
  marksheet,
}: QuestionsQuizBuilderProps): JSX.Element => {
  const {
    activeUsers,
    id,
    solo: marksheetSolo,
    state,
    topicConceptData,
    preBuildData,
    builderConfig,
  } = marksheet || {};
  const solo = isNil(marksheetSolo) ? true : marksheetSolo;
  const marksheetId = id ? Number(id) : undefined;
  const { isMobile } = useIsMobile();
  const {
    loading: topicLoading,
    topics,
    topicMap,
    categories,
  } = useTopicQuery();
  const { state: routerState } = useLocation<RouterState>();
  const { marksheetId: initialSoloMarksheetId, isMarksheetOutdated } =
    routerState || {};
  const { id: currentUserId } = useCurrentUser();
  const { open, openLeaveModal } = useLeaveExerciseState();
  const [preBuildSelection, setPrebuildSelection] = useState<PreBuildData>();
  const [showPrebuildModal, setShowPrebuildModal] = useState(false);
  const [showSelectionBar, setShowSelectionBar] = useState(false);
  const [showSavePresetModal, setShowSavePresetModal] = useState(false);
  const [showEditPresetModal, setShowEditPresetModal] = useState(false);
  const [presetName, setPresetName] = useState<string>('');
  const isDemo = useDemo();
  const { preset, isPresetEditOpen, setIsPresetEditOpen } =
    usePresetGlobalState();
  const { product } = usePlatform();

  const showSaveAsPreset = !isDemo && product === EProductType.QBANK;

  const { id: presetId, name, entitlementId } = preset;
  const [displayTopics, setDisplayTopics] =
    useState<Nullable<ExtendedTopic[]>>(null);
  const [activeTopics, setActiveTopics] =
    useState<Nullable<ExtendedTopic[]>>(null);
  const [selectionCount, setSelectionCount] = useState<[number, number]>([
    0, 0,
  ]);
  const { changeMarksheetState, loading: stateLoading } =
    useChangeMarksheetState(marksheetId);

  const goToQuizBuilder = useCallback(() => {
    changeMarksheetState(EMarksheetState.QUIZ_BUILDER);
  }, [changeMarksheetState]);

  const { preBuildLoading, preBuildQuestions } = usePreBuildQuestions({
    onPrebuildComplete: goToQuizBuilder,
    marksheetId,
  });

  useMarksheetLeavingBlockade({
    marksheetId: id,
    shouldBlock: solo && showSelectionBar && Boolean(isMarksheetOutdated),
    ignoreBlockade: !solo || !showSelectionBar || !isMarksheetOutdated,
    solo,
  });

  const sharedSelection =
    preset.id !== 0 ? editSelection(preset, activeTopics) : topicConceptData;

  const navigate = useNavigate();
  const selection = useTopicSelection({
    topics: activeTopics,
    displayTopics,
    marksheetId,
    sharedSelection,
  });

  const { allSelected, deselectAll, selectionState } = selection;

  const [questionCount] = selectionCount;

  const handleUnselectAll = () => {
    if (allSelected !== CheckboxState.UNCHECKED) {
      deselectAll(selectionState);
    }
  };

  const selected = extractConceptIds(selectionState);

  const handleShowPrebuildModal = () => {
    if (solo) {
      setShowPrebuildModal(true);
    } else if (preBuildSelection) {
      preBuildQuestions(
        preBuildSelection,
        QUESTION_DIFFICULTY_LEVELS,
        selected.entitlementId
      );
    }
  };

  const { createPreset } = useCreatePresetMutation(
    presetName,
    selected.entitlementId,
    selected.conceptIdsArray
  );

  const { editPreset } = useEditPresetMutation(presetId);

  const handlePresetNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPresetName(event.target.value);
  };
  const handleClosePrebuildModal = () => setShowPrebuildModal(false);

  const handleShowSavePresetModal = () => {
    setShowSavePresetModal(true);
  };

  const handleCloseSavePresetModal = () => {
    setShowSavePresetModal(false);
    setPresetName('');
  };

  const handleSubmitSavePreset = async () => {
    await createPreset();
    handleUnselectAll();
    setPresetName('');
    setShowSavePresetModal(false);
  };

  const handleLeave = () => {
    if (solo) {
      navigate(dashboard);
    } else {
      openLeaveModal();
    }
  };

  useEffect(() => {
    setShowPrebuildModal(state === EMarksheetState.QUIZ_BUILDER);
  }, [state]);

  useEffect(() => {
    setPrebuildSelection(getConcepts(selectionState));
  }, [selectionState]);

  useEffect(() => {
    let timeout = 0;
    const count = calcAllQuestions(selectionState, topicMap);

    if (count[0]) {
      setShowSelectionBar(true);
      setSelectionCount(count);
    } else {
      setShowSelectionBar(false);
      timeout = window.setTimeout(() => {
        setSelectionCount(count);
      }, TRANSITION_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [selectionState, topicMap]);

  const [
    detailsColumns,
    overviewColumns,
  ]: SelectionColumn<QuestionColumns>[][] = useMemo(
    () => [
      [
        { align: 'center', key: KeyField.Chevron, type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'topics',
        },
        {
          align: 'left',
          key: 'correctQuestions',
          ...QUESTIONS_PROGRESS_LABELS[0],
          type: 'default',
        },
        {
          align: 'left',
          key: 'incorrectQuestions',
          ...QUESTIONS_PROGRESS_LABELS[1],
          type: 'default',
        },
        {
          align: 'left',
          formatter: notAnsweresFormatter,
          key: KeyField.NotAnswered,
          ...QUESTIONS_PROGRESS_LABELS[2],
          type: 'default',
        },
        {
          align: 'left',
          scoreFormatter: scoreFormatter,
          key: KeyField.Score,
          label: 'Score',
          type: 'default',
        },
      ],
      [
        { align: 'center', key: 'chevron', type: 'chevron' },
        {
          align: 'left',
          key: KeyField.Checkbox,
          type: 'input',
          label: 'topics',
        },
        {
          align: 'left',
          formatter: checkBoxLabelFormatter(selectionState),
          key: KeyField.Label,
          type: 'label',
          label: 'topics',
        },
        {
          align: 'left',
          key: KeyField.Progress,
          label: 'Progress',
          type: 'bar',
          progressLabels: QUESTIONS_PROGRESS_LABELS,
          progressFormatter,
        },
      ],
    ],
    [selectionState]
  );

  const handleOpenEditModal = () => {
    setShowEditPresetModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditPresetModal(false);
  };

  const handleSubmitEditPrset = async () => {
    await editPreset({
      entitlementId,
      name,
      conceptIds: selected.conceptIdsArray,
    });
    setShowEditPresetModal(false);
    setIsPresetEditOpen(false);
  };

  const selectedItemsInfo = isDemo
    ? 'Start exercise with free questions'
    : `${questionCount} questions selected`;

  const [activeTopic] = activeTopics || [];
  const { entitlement } = activeTopic || {};

  return (
    <ExerciseLayout
      bottomOffsetVariant={setBottomBarOffset(showSelectionBar, isMobile)}
      controlPanelContent={
        activeUsers && !solo ? (
          <ParticipantList
            currentUserId={currentUserId}
            participants={activeUsers}
            variant="panel"
            withAudio
          />
        ) : null
      }
      exerciseContent={
        <>
          <PreLaunchLayout selection={showSelectionBar || isDemo ? 1 : 0}>
            <TopicQuizBuilder
              categories={categories}
              detailsColumns={detailsColumns}
              displayTopics={displayTopics}
              exerciseType={ExerciseType.Questions}
              loading={topicLoading}
              nestedItemsKey="concepts"
              overviewColumns={overviewColumns}
              searchLabel="Search by topic and concept"
              selectedItemsInfo={selectedItemsInfo}
              setActiveTopics={setActiveTopics}
              setDisplayTopics={setDisplayTopics}
              solo={solo}
              topics={topics}
              {...selection}
            />
          </PreLaunchLayout>
          <Fade in={showSelectionBar || isDemo} unmountOnExit>
            {isPresetEditOpen ? (
              <BottomSelectionBar
                continueLabel={SelectionBarLabels.SaveChanges}
                onContinue={handleOpenEditModal}
                onContinueLoading={preBuildLoading || stateLoading}
                onUnselect={handleUnselectAll}
                selectedItemsLabel={selectedItemsInfo}
                withoutPanel={solo}
              />
            ) : (
              <BottomSelectionBar
                onContinue={handleShowPrebuildModal}
                onContinueLoading={preBuildLoading || stateLoading}
                onSavePreset={
                  showSaveAsPreset ? handleShowSavePresetModal : undefined
                }
                onUnselect={isDemo ? undefined : handleUnselectAll}
                saveAsPreset={showSaveAsPreset}
                savePresetLabel={SelectionBarLabels.SaveAsPreset}
                selectedItemsLabel={selectedItemsInfo}
                withoutPanel={solo}
              />
            )}
          </Fade>
          {showPrebuildModal ? (
            <QuestionsPreBuildModal
              builderConfig={builderConfig}
              entitlementId={entitlement?.id as unknown as EEntitlementType}
              marksheetId={getBuildMarksheetId(
                solo,
                marksheetId,
                initialSoloMarksheetId
              )}
              onClose={handleClosePrebuildModal}
              open={showPrebuildModal}
              preBuildData={preBuildData}
              selection={preBuildSelection}
              solo={solo}
              source={isDemo ? 'demo' : ''}
            />
          ) : null}
          {showSavePresetModal ? (
            <Modal
              cancelLabel="Cancel"
              noPaddingY
              onClose={handleCloseSavePresetModal}
              onSubmit={handleSubmitSavePreset}
              open={showSavePresetModal}
              showCloseButton
              sizeVariant="md"
              submitLabel="Save as preset"
              title="Save as preset"
            >
              <NameInputField
                id="outlined-required"
                label="Preset name"
                name="preset-name"
                onChange={handlePresetNameChange}
                required
                value={presetName}
              />
            </Modal>
          ) : null}
          {showEditPresetModal ? (
            <Modal
              cancelLabel="Cancel"
              noPaddingY
              onClose={handleCloseEditModal}
              onSubmit={handleSubmitEditPrset}
              open={showEditPresetModal}
              showCloseButton
              sizeVariant="md"
              submitLabel="Confirm"
              title="Confirm changes"
            >
              <Body component="p">
                You made some changes to this preset. Are you sure you want to
                save them? Saving will replace the existing preset with your
                changes.
              </Body>
            </Modal>
          ) : null}
          {solo && open ? (
            <QuestionsQuizLeaveModal
              discard
              marksheet={marksheet}
              marksheetId={id}
              open={open}
            />
          ) : null}
        </>
      }
      exerciseItemsCount={activeUsers?.length}
      floatingPanel
      onLeave={handleLeave}
      withSound={!solo}
      withoutPanel={solo}
      {...(!solo && PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS)}
    />
  );
};

export default QuestionsQuizBuilder;
