import React, { useCallback } from 'react';
import {
  EPaceMarkType,
  EProductType,
  IOsceMarksheetMark,
} from '@quesmed/types-rn/models';
import { optimisticMarkOsceMarksheetMark } from '@quesmed/types-rn/resolvers/mutation/restricted';
import groupBy from 'lodash/groupBy';
import { useApolloClient } from '@apollo/client';
import { checkSampleOsceMark } from '@quesmed/types-rn/resolvers/mutation';

import { InputOnChangeHandler, Nullable } from 'types';
import MarkScore, { MarkScoreProps } from './MarkScore';
import StationMarkschemeContent from './StationMarkschemeContent';
import StationPacesMarkscheme from './StationPacesMarkscheme';
import useMarkOsceMarksheetMark from './hooks/useMarkOsceMarksheetMark';
import { usePlatform } from 'context/PlatformState';

export const groupMarks = (marks?: IOsceMarksheetMark[]) =>
  marks
    ? groupBy(marks, ({ osceStationMark }) => osceStationMark?.header)
    : null;

export interface MarkSchemeProp
  extends Pick<
    MarkScoreProps,
    'feedback' | 'stationType' | 'control' | 'feedback'
  > {
  isCompleted: boolean;
  isExaminer: boolean;
  isSample?: boolean;
  isPaces: boolean;
  expandedAll?: boolean;
  loading: boolean;
  notStarted: boolean;
  marks: IOsceMarksheetMark[];
  setFeedback: (value: string) => void;
}

const Markscheme = ({
  control,
  feedback,
  isCompleted,
  isSample,
  isExaminer,
  isPaces,
  loading,
  marks,
  notStarted,
  stationType,
  expandedAll,
  setFeedback,
}: MarkSchemeProp) => {
  const client = useApolloClient();
  const { product } = usePlatform();
  const groupedMarks = groupMarks(marks);
  const { markOsceMark } = useMarkOsceMarksheetMark();

  const handleSaveFeedback = useCallback(
    (feedback: string) => {
      if (isCompleted || !isExaminer) {
        return;
      }

      setFeedback(feedback);
    },
    [isCompleted, isExaminer, setFeedback]
  );

  const handleMarkCheck = useCallback(
    (prevChecked: Nullable<number>): InputOnChangeHandler =>
      ({ target }) => {
        if (loading || isCompleted) {
          return;
        }
        const { value } = target;
        const id = Number(value);

        const markVariables = {
          osceMarksheetMarkId: id,
          mark: prevChecked ? 0 : 1,
        };

        if (isSample) {
          checkSampleOsceMark(client, isPaces, markVariables);
        } else {
          markOsceMark({
            variables: markVariables,
            optimisticResponse: optimisticMarkOsceMarksheetMark(
              markVariables,
              product as EProductType
            ),
          });
        }
      },
    [loading, isCompleted, isSample, client, isPaces, markOsceMark, product]
  );

  const handleMarkChoice = useCallback(
    (markId: number) => (mark: EPaceMarkType) => {
      if (!isCompleted) {
        const markVariables = {
          osceMarksheetMarkId: Number(markId),
          mark,
        };

        if (isSample) {
          checkSampleOsceMark(client, isPaces, markVariables);
        } else {
          markOsceMark({
            variables: markVariables,
            optimisticResponse: optimisticMarkOsceMarksheetMark(
              { mark, osceMarksheetMarkId: Number(markId) },
              product as EProductType
            ),
          });
        }
      }
    },
    [isCompleted, isSample, client, isPaces, markOsceMark, product]
  );

  return (
    <>
      {isPaces ? (
        <StationPacesMarkscheme
          disabled={isCompleted || notStarted || !isExaminer}
          expandedAll={expandedAll}
          isCompleted={isCompleted}
          marks={marks}
          onMarkChange={handleMarkChoice}
        />
      ) : (
        <StationMarkschemeContent
          disabled={isCompleted || notStarted || !isExaminer}
          expandedAll={expandedAll}
          isCompleted={isCompleted}
          marks={groupedMarks}
          onMarkChange={handleMarkCheck}
        />
      )}
      <MarkScore
        control={control}
        expandedAll={expandedAll}
        feedback={feedback}
        isCompleted={isCompleted}
        isExaminer={isExaminer}
        isPaces={isPaces}
        onFeedbackSave={handleSaveFeedback}
        stationType={stationType}
        totalMarks={marks?.length || 0}
      />
    </>
  );
};

export default Markscheme;
