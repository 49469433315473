import React, { ReactNode } from 'react';

import { Nullable } from 'types';
import {
  ActionPopperWrapper,
  PopperItem,
  PopperOpener,
  SeparatorLine,
} from './ActionPopper.styles';

interface Item {
  icon?: ReactNode;
  onClick: () => void;
  label?: Nullable<string>;
  footerItem?: boolean;
}

interface IActionPopperProps {
  isPopperOpen: boolean;
  currentRef: Nullable<HTMLElement>;
  items: Item[];
}

const ActionPopper = ({
  isPopperOpen,
  currentRef,
  items,
}: IActionPopperProps) => {
  const hasFooter = items.some(item => item.footerItem);

  return (
    <>
      <PopperOpener />
      <ActionPopperWrapper
        anchorEl={currentRef}
        disablePortal
        open={isPopperOpen}
      >
        {items.map(item => {
          if (!item.footerItem) {
            return (
              <PopperItem
                expanded={1}
                key={item.label}
                nested={0}
                onClick={item.onClick}
              >
                {item.icon} {item.label}
              </PopperItem>
            );
          }
        })}
        {hasFooter ? <SeparatorLine /> : null}
        {items.map(item => {
          if (item.footerItem) {
            return (
              <PopperItem
                className="footer"
                expanded={1}
                key={item.label}
                nested={0}
                onClick={item.onClick}
              >
                {item.icon} {item.label}
              </PopperItem>
            );
          }
        })}
      </ActionPopperWrapper>
    </>
  );
};

export default ActionPopper;
