import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { IMarksheetMark, IQuestionEMQ } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { EditIcon } from 'components/Icons';
import { compareByField, parseJSON } from 'utils';
import MatchingQuestionCase from './MatchingQuestionCase';
import MatchingQuestionFeedback from './MatchingQuestionFeedback';
import { MatchingAnswer, Nullable } from 'types';

export const MatchingAnswersWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),

  '& .MuiFormControl-root': {
    width: '100%',
  },

  '& .MuiFormGroup-root': {
    width: '100%',
    display: 'grid',
    gap: spacing(4),
    gridTemplateColumns: 'repeat(auto-fit, minmax(128px, 1fr))',
  },
}));

export interface MatchingQuestionProps {
  mark: IMarksheetMark;
  setUserAnswer: (answer: string) => void;
  showAnswers: boolean;
}

const MatchingQuestion = ({
  mark,
  setUserAnswer,
  showAnswers,
}: MatchingQuestionProps): Nullable<JSX.Element> => {
  const [matchingAnswer, setMatchingAnswer] = useState<Map<string, string>>(
    new Map()
  );
  const { question, mark: userAnswer } = mark;
  const { cases, choices, emqAnswer } = question as IQuestionEMQ;

  const handleChange =
    (caseLabel: string) =>
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setMatchingAnswer(previous => new Map(previous.set(caseLabel, value)));
    };

  useEffect(() => {
    if (matchingAnswer.size && !showAnswers) {
      setUserAnswer(JSON.stringify(Array.from(matchingAnswer)));
    }
  }, [matchingAnswer, setUserAnswer, showAnswers]);

  useEffect(() => {
    if (!userAnswer) {
      setMatchingAnswer(new Map());
    } else {
      const parsed = parseJSON<MatchingAnswer>(userAnswer);

      if (parsed) {
        setMatchingAnswer(new Map(parsed));
      }
    }
  }, [userAnswer, setUserAnswer]);

  const options = useMemo(
    () =>
      choices
        .map(({ label }) => ({
          label,
          value: label,
        }))
        .sort(compareByField('label')),
    [choices]
  );

  if (!cases) {
    return null;
  }

  return (
    <MatchingAnswersWrapper>
      {showAnswers ? (
        <MatchingQuestionFeedback
          cases={cases}
          emqAnswer={emqAnswer}
          mark={userAnswer}
          options={options}
          questionId={question.id}
        />
      ) : (
        <>
          {cases.map(({ id, label, ...rest }, index) => (
            <MatchingQuestionCase
              caseContent={rest.case}
              caseId={id}
              icon={EditIcon}
              key={id}
              onChange={handleChange(label)}
              options={options}
              questionId={question.id}
              selectedValue={matchingAnswer.get(label)}
              title={`Case ${index + 1}`}
            />
          ))}
        </>
      )}
    </MatchingAnswersWrapper>
  );
};

export default MatchingQuestion;
