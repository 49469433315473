import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { IUserNote } from '@quesmed/types-rn/models';

import { Body } from 'components/Typography';
import { Button } from 'components/Button';
import { FormField, StyledInput } from 'components/TextField';
import { getRelativeTime, parseDate, scrollToBottom } from 'utils';
import { Nullable } from 'types';

export const FallbackContainer = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justofyContent: 'center',
  alignItems: 'center',
  gap: spacing(2),

  '& > .MuiTypography-root': {
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center',
  },
}));

const NotesFallback = ({ learningMaterials = false }): JSX.Element => (
  <FallbackContainer>
    <Body bold>No notes yet.</Body>
    <Body>
      Add your own notes here.{' '}
      {!learningMaterials
        ? 'Please keep in mind these notes will only bevisible to you.'
        : ''}
    </Body>
  </FallbackContainer>
);

const NotesContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  margin: spacing(-6, -8),

  '& > .MuiBox-root:nth-last-of-type(1)': {
    borderTop: `1px solid ${palette.stroke.main}`,
  },
}));

const NotesSection = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(6),
}));

const NotesEditDate = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: palette.text.disabled,
    ...typography.body1Medium,
    width: '100%',
    marginTop: spacing(4),
    paddingRight: spacing(3),
  })
);

const EditButton = styled(Box)(
  ({ theme: { palette, spacing, transitions, typography } }) => ({
    height: '56px',
    borderRadius: '4px',
    backgroundColor: palette.background.default,
    border: `1px solid ${palette.stroke.main}`,
    color: palette.text.disabled,
    ...typography.body1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0, 3),
    transition: transitions.create(['border-color']),

    '&:hover': {
      borderColor: palette.stroke.dark,
      cursor: 'pointer',
    },
  })
);

const Buttons = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const NotesInput = styled(StyledInput)(
  ({ theme: { palette, spacing, transitions, typography } }) => ({
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: palette.background.default,
      ...typography.body1Medium,
      transition: transitions.create([
        'background-color',
        'padding',
        'border-radius',
        'width',
      ]),
    },

    '& .MuiOutlinedInput-root': {
      padding: spacing(4, 0.25, 4, 3),
    },

    '& .MuiInputBase-readOnly': {
      backgroundColor: palette.background.paper,

      fieldset: {
        borderColor: palette.background.paper,
      },

      '&:hover': {
        fieldset: {
          borderColor: palette.background.paper,
        },
      },

      '&.Mui-focused': {
        fieldset: {
          borderColor: palette.background.paper,
        },
      },

      '&.MuiOutlinedInput-root': {
        padding: spacing(1, 0.25, 1, 1),
      },
    },
  })
);

interface NotesForm {
  notes: string;
}

export interface UserNotesProps {
  notes?: Nullable<IUserNote>;
  editDate?: Nullable<Date>;
  onSaveNotes?: (notes: string) => void;
  onFocusNotes?: () => void;
  readOnly?: boolean;
  fallback?: JSX.Element;
  learningMaterials?: boolean;
}

const UserNotes = ({
  notes: sourceNotes,
  editDate,
  onFocusNotes,
  onSaveNotes,
  readOnly,
  learningMaterials,
  fallback = <NotesFallback learningMaterials={learningMaterials} />,
}: UserNotesProps): JSX.Element => {
  const notes = sourceNotes?.note ?? '';
  const [editable, setEditable] = useState(false);
  const { control, handleSubmit, reset } = useForm<NotesForm>({
    defaultValues: {
      notes,
    },
    mode: 'onTouched',
  });
  const lastEditDate = useMemo(
    () => (notes && editDate ? getRelativeTime(parseDate(editDate)) : 'now'),
    [editDate, notes]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const saveNotes = handleSubmit((data: NotesForm) => {
    const { notes } = data;

    const newValue = notes.trim();

    if (onSaveNotes) {
      onSaveNotes(newValue);
    }
    setEditable(false);
  });

  const handleCancelEdit = () => {
    setEditable(false);
    reset();
  };

  const handleStartEdit = () => setEditable(true);

  useEffect(() => {
    if (editable) {
      const textarea = inputRef.current;
      if (textarea) {
        textarea.setSelectionRange(notes.length, notes.length);
        textarea.focus({ preventScroll: true });
        scrollToBottom(inputRef);
      }

      if (onFocusNotes) {
        onFocusNotes();
      }
    }
  }, [editable, onFocusNotes, notes.length]);

  return (
    <NotesContainer>
      <NotesSection>
        {notes || editable ? (
          <FormField
            InputProps={{
              endAdornment: editable ? (
                <NotesEditDate>{lastEditDate}</NotesEditDate>
              ) : null,
              readOnly: !editable,
            }}
            component={NotesInput}
            control={control}
            fullWidth
            maxRows={10}
            minRows={2}
            multiline
            name="notes"
            ref={inputRef}
          />
        ) : (
          fallback
        )}
      </NotesSection>
      {readOnly ? null : (
        <NotesSection>
          {editable ? (
            <Buttons>
              <Button onClick={handleCancelEdit} secondary>
                Cancel
              </Button>
              <Button onClick={saveNotes}>Save Notes</Button>
            </Buttons>
          ) : (
            <EditButton onClick={handleStartEdit}>
              <Box>Edit your notes...</Box>
              {notes ? <Box>{lastEditDate}</Box> : null}
            </EditButton>
          )}
        </NotesSection>
      )}
    </NotesContainer>
  );
};

export default UserNotes;
