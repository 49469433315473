import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';

import { Card } from 'components/Card';
import { ActivityHeader, ActivityTabs, TrackerCard } from './components';
import { ExerciseMode } from 'types';
import { formatDate } from 'utils';
import { Tabs } from './components/types';
import HistoryCard from './components/HistoryCard';
import useOsceWindowQuery from './hooks/useOsceWindowQuery';

const ActivityContainer = styled(Card)(
  ({ theme: { breakpoints, spacing } }) => ({
    padding: spacing(4),
    position: 'relative',
    overflow: 'hidden',

    [breakpoints.up('md')]: {
      padding: spacing(6),
    },
  })
);

const OsceActivity = (): JSX.Element => {
  const [tab, setTab] = useState<Tabs>(Tabs.Tracker);
  const [clickedBar, setClickedBar] = useState<string>('');
  const [exerciseMode, setExerciseMode] = useState<ExerciseMode>(
    ExerciseMode.Solo
  );

  const isModeSolo = exerciseMode === ExerciseMode.Solo;
  const { data, loading } = useOsceWindowQuery(
    tab === Tabs.History || isModeSolo
  );
  const { results = [] } = data?.restricted?.osceMarksheetsWindow || {};

  const lastAvailable = [...results]
    .reverse()
    .findIndex(({ result }) => result?.length);

  useEffect(() => {
    if (results.length) {
      setClickedBar(
        formatDate(
          results[results.length - 1 - (lastAvailable > -1 ? lastAvailable : 0)]
            .date
        )
      );
    }
  }, [results, lastAvailable]);

  const renderWidget = useMemo(() => {
    switch (tab) {
      case Tabs.History:
        return <HistoryCard data={[...results].reverse()} solo={isModeSolo} />;
      case Tabs.Tracker:
      default:
        return (
          <TrackerCard
            clickedBar={clickedBar}
            data={results}
            exerciseMode={exerciseMode}
            loading={loading}
            setClickedBar={setClickedBar}
            setExerciseMode={setExerciseMode}
          />
        );
    }
  }, [tab, results, isModeSolo, clickedBar, exerciseMode, loading]);

  return (
    <ActivityContainer>
      <ActivityHeader />
      <ActivityTabs activeTab={tab} switchTab={setTab} />
      {renderWidget}
    </ActivityContainer>
  );
};

export default OsceActivity;
