import React, { MouseEvent } from 'react';
import { EEntitlementType, IVideo } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { paths } from 'Router';
import SearchResultItem from 'components/Search/SearchResultItem';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';

const { videoLibrary } = paths;
const { root } = videoLibrary;

export const Container = styled(Box)(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: palette.background.paper,
  gap: spacing(6),
}));

export interface RelatedVideosProps {
  videos: IVideo[];
  conceptId: number;
  entitlementId?: EEntitlementType;
  isSample?: boolean;
  topicId?: number;
}

const RelatedVideos = ({
  videos,
  entitlementId,
  topicId,
  isSample,
}: RelatedVideosProps): JSX.Element => {
  const slicedVideos = videos.slice(0, 6);
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const isDemo = useDemo();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isDemo || isSample) {
      e.preventDefault();
      enqueueSnackbar(locales.common.demo.resource);
    }
  };

  return (
    <Container>
      {slicedVideos.map(({ id, title }) => (
        <SearchResultItem
          isNewTab
          key={id}
          label={title}
          onClick={handleClick}
          path={`${root}/video/${entitlementId}/${topicId}/${id}`}
        />
      ))}
    </Container>
  );
};

export default RelatedVideos;
