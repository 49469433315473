import React from 'react';
import { EAppType, EProductType } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import clsx from 'clsx';

import {
  AppProductType,
  APPS,
  ExtendedProduct,
  SubscriptionNames,
} from 'types';
import { constSize } from 'utils';
import { H4, H6 } from 'components/Typography';
import { Button } from 'components/Button';
import { CreditCardCheckIcon } from 'components/Icons';
import { ProductImage } from 'components/Illustrations';
import { useSubscription } from 'Auth';
import { PricingSubscriptions } from 'hooks/useSubscriptions';
import Markdown from 'components/Markdown/Markdown';

export const ProductsContainer = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(6),

    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'initial',
      justifyContent: 'center',
    },
  })
);

const Product = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.background.paper,
  borderRadius: '9px',
  border: `1px solid ${palette.stroke.main}`,
  padding: spacing(6),
  maxWidth: '296px',
  width: '100%',

  '&.bundle': {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,

    '& .MuiSvgIcon-root': {
      color: palette.primary.contrastText,
    },
  },
}));

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
  '&.MuiButtonBase-root.MuiButton-outlined': {
    backgroundColor: palette.background.paper,
    color: palette.primary.main,

    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: palette.background.paper,
      color: palette.primary.dark,
    },
  },
}));

const ProductHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Image = styled(Box)({
  ...constSize('64px'),
});

const Name = styled(H6)(({ theme: { spacing } }) => ({
  margin: spacing(4, 0, 2, 0),
}));

const BestValue = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    background: palette.background.paper,
    borderRadius: '100px',
    color: palette.primary.main,
    padding: spacing(2, 4),
    ...typography.button,
  })
);

const Features = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(6),
  gap: spacing(2),
}));

const Price = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
  '& .MuiTypography-h4': {
    display: 'inline',
  },
}));

interface ButtonLabelProps {
  hasProduct: boolean;
  inactive: boolean;
}
const ButtonLabel = ({
  hasProduct,
  inactive,
}: ButtonLabelProps): JSX.Element => {
  if (hasProduct) {
    return (
      <>
        Access Granted <CreditCardCheckIcon />
      </>
    );
  }

  return <>{inactive ? 'Available soon' : 'Get Access'}</>;
};

interface ProductCardProps extends Omit<ExtendedProduct, 'typeId'> {
  appType: EAppType;
  onCheckout: (priceId: string, productId: EProductType) => void;
  paymentLoading: boolean;
  subscriptions: PricingSubscriptions;
  typeId: AppProductType;
}

const ProductCard = ({
  appType,
  typeId,
  formattedPrice,
  features,
  formattedDuration,
  bundle,
  onCheckout,
  stripePriceId,
  paymentLoading,
  subscriptions,
}: ProductCardProps): JSX.Element => {
  const productName = SubscriptionNames[typeId as AppProductType];
  const data = useSubscription();
  const { products: boughtProducts = [] } = data || {};

  const currentSubscription = subscriptions[appType][0]?.subscription;

  const inactive =
    typeId === EProductType.PACES &&
    !APPS[EAppType.MRCP].products[EProductType.PACES]?.active;

  const hasProduct =
    currentSubscription === EProductType.BUNDLE ||
    currentSubscription === EProductType.ANATOMY_BUNDLE ||
    boughtProducts.includes(typeId);

  const handleCheckout = () => {
    onCheckout(stripePriceId, typeId);
  };

  return (
    <Product className={clsx({ bundle })} key={typeId}>
      <ProductHeader>
        <Image>
          <ProductImage product={typeId} variant="subscription" />
        </Image>
        {bundle && <BestValue>Best Value</BestValue>}
      </ProductHeader>
      <Name>{productName}</Name>
      <Price>
        <H4>{formattedPrice}</H4>/{formattedDuration}
      </Price>
      <StyledButton
        disabled={hasProduct || inactive}
        loading={paymentLoading}
        onClick={handleCheckout}
        secondary={!hasProduct && bundle}
      >
        <ButtonLabel hasProduct={hasProduct} inactive={inactive} />
      </StyledButton>
      {features && (
        <Features>
          <Markdown
            isPricing
            markdownStylesProps={{ isPricing: true }}
            text={features}
          />
        </Features>
      )}
    </Product>
  );
};

export default ProductCard;
