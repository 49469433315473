import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ChapterContainer,
  ChapterContent,
  KnowledgeLibraryRouterParams,
} from 'components/LearningMaterials';
import { usePacesKnowledgeLibraryData } from './PacesKnowledgeLibrary';
import { BookshelfIcon } from 'components/Icons';
import { paths } from 'Router';
import PacesKnowledgeLibraryReading from './PacesKnowledgeLibraryReading';
import { PacesKnowledgeLibraryLayout } from './PacesKnowledgeLibraryLayout';

const { knowledgeLibrary } = paths;

const PacesKnowledgeLibraryChapter = () => {
  const { activeCategoryLabel, activeCategory, loading, concepts } =
    usePacesKnowledgeLibraryData();
  const navigate = useNavigate();

  const handleNavigateToRoot = useCallback(() => {
    navigate(
      `${knowledgeLibrary.root}/section/${activeCategory}/${activeCategory}`
    );
  }, [activeCategory, navigate]);
  const { chapterId: conceptId } = useParams<KnowledgeLibraryRouterParams>();
  const chapterContainerRef = useRef<HTMLDivElement>(null);

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
        onClick: handleNavigateToRoot,
      },
    ],
    [activeCategoryLabel, handleNavigateToRoot]
  );

  useEffect(() => {
    if (chapterContainerRef.current) {
      chapterContainerRef.current.focus();
      chapterContainerRef.current.scrollTo({ top: 0 });
    }
  }, [conceptId]);

  const concept = concepts?.find(({ id }) => Number(id) === Number(conceptId));

  return (
    <PacesKnowledgeLibraryLayout>
      <ChapterContainer ref={chapterContainerRef} tabIndex={0}>
        <ChapterContent>
          <PacesKnowledgeLibraryReading
            activeCategoryLabel={activeCategoryLabel}
            breadcrumbs={breadcrumbs}
            concept={concept}
            loading={loading}
          />
        </ChapterContent>
      </ChapterContainer>
    </PacesKnowledgeLibraryLayout>
  );
};

export default PacesKnowledgeLibraryChapter;
