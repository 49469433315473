import React from 'react';
import { TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Tooltip } from 'components/Tooltip';
import { round } from 'utils';
import { setBackgroundColor } from './LineChartStyles';
import { LineChartItem } from './types';
import { ItemStatusLabel } from 'types';

const StyledTooltip = styled(Tooltip)({
  padding: 0,
  overflow: 'hidden',
  textTransform: 'initial',
  width: '294px',
});

const TooltipContentContainer = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(3, 4),
}));

const TooltipItem = styled(Box)<{
  type: ItemStatusLabel;
}>(({ theme: { spacing, typography, palette }, type }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1.5),
  width: '100%',
  marginBottom: spacing(2),
  ...typography.body2,

  '&:last-child': {
    marginBottom: 0,
  },
  '&::before': {
    content: '""',
    width: '8px',
    height: '8px',
    background: setBackgroundColor(type, palette),
    borderRadius: '20px',
  },
}));

const TooltipContentHeader = styled(Box)(
  ({ theme: { palette, typography, spacing } }) => ({
    backgroundColor: palette.secondary.main,
    padding: spacing(3, 4),
    width: '100%',
    ...typography.body2Medium,
  })
);

interface TooltipContentProps {
  name?: string;
  total: number;
  data: LineChartItem[];
}

type TopicProgressBarTooltipProps = TooltipContentProps &
  Pick<TooltipProps, 'children'>;

const TooltipContent = ({ name, data, total }: TooltipContentProps) => {
  const totalAnswered = (data[0]?.value ?? 0) + (data[1]?.value ?? 0);
  const roundedPercentValues = data.map(item => {
    if (total === 0 || totalAnswered === 0) {
      return 0;
    }

    if (
      item.type === ItemStatusLabel.Confident ||
      item.type === ItemStatusLabel.ToLearn
    ) {
      return round(((item?.value || 0) / totalAnswered) * 100);
    }

    return round(((item?.value || 0) / total) * 100);
  });

  return (
    <>
      {name ? <TooltipContentHeader>{name}</TooltipContentHeader> : null}
      <TooltipContentContainer>
        {data.map((item, index) => {
          const { type, value, label } = item;
          const numberValue = value ?? 0;
          const percent = roundedPercentValues[index];

          return (
            <TooltipItem key={type} type={type}>
              {`${label}: ${percent}% (${numberValue})`}
            </TooltipItem>
          );
        })}
      </TooltipContentContainer>
    </>
  );
};

const ProgressLineChartTooltip = ({
  name,
  data,
  children,
  total,
}: TopicProgressBarTooltipProps) => (
  <StyledTooltip
    placement="top"
    title={<TooltipContent data={data} name={name} total={total} />}
  >
    {children}
  </StyledTooltip>
);

export default ProgressLineChartTooltip;
