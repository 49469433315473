import React from 'react';

import { Children } from 'types';
import { ActiveSpeakerProviders } from './AgoraActiveSpeaker';
import { AgoraAPIProvider } from './AgoraAPI';
import { AgoraStateProviders } from './AgoraState';
import useAgoraEvents from './useAgoraEvents';
import useAgoraSession from './useAgoraSession';

interface AgoraProviderPros {
  children: Children;
}

const AgoraController = ({ children }: AgoraProviderPros): JSX.Element => {
  useAgoraSession();
  useAgoraEvents();

  return <>{children}</>;
};

export const AgoraProvider = ({ children }: AgoraProviderPros): JSX.Element => (
  <AgoraStateProviders>
    <ActiveSpeakerProviders>
      <AgoraAPIProvider>
        <AgoraController>{children}</AgoraController>
      </AgoraAPIProvider>
    </ActiveSpeakerProviders>
  </AgoraStateProviders>
);
