import { noop } from '../function';

// function parses qaAnswer field from marksheet.question
export const parseQaAnswer = (userAnswer?: string): string => {
  let answer: string[] = [];

  try {
    if (userAnswer) {
      answer = JSON.parse(userAnswer);
    }
  } catch {
    noop();
  }
  const [nestedAnswer] = answer;

  return nestedAnswer;
};
