import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { Nullable } from 'types';
import {
  LearningMaterialItem,
  LearningMaterialItemMap,
  LearningMaterialItems,
  LearningStatus,
} from '../types';

const getItemsMap = (
  items: Nullable<LearningMaterialItem[]>
): Nullable<LearningMaterialItems> => {
  if (!items) {
    return null;
  }

  const map: LearningMaterialItemMap = {
    [EUserLearningStatus.URGENT]: [],
    [EUserLearningStatus.REVISING]: [],
    [EUserLearningStatus.COMPLETED]: [],
    [EUserLearningStatus.UNREAD]: [],
  };

  items.forEach(item => {
    const status = !item.status ? EUserLearningStatus.UNREAD : item.status;

    map[status as LearningStatus].push(item);
  });

  const orderedItems: LearningMaterialItems = [
    [EUserLearningStatus.URGENT, map[EUserLearningStatus.URGENT]],
    [EUserLearningStatus.REVISING, map[EUserLearningStatus.REVISING]],
    [EUserLearningStatus.COMPLETED, map[EUserLearningStatus.COMPLETED]],
    [EUserLearningStatus.UNREAD, map[EUserLearningStatus.UNREAD]],
  ];

  return orderedItems;
};

export default getItemsMap;
