import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LockOutlineIcon } from 'components/Icons';

const IconBox = styled(Box)(({ theme: { palette } }) => ({
  color: palette.icon.light,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CellLockIcon = () => (
  <IconBox>
    <LockOutlineIcon />
  </IconBox>
);
