import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CONTENT_MAX_WIDTH } from 'config/constants';
import { Children } from 'types';

interface ReadingContentContainerProps {
  children: Children;
}

const Container = styled(Box)({ height: '100%' });

const Content = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  maxWidth: `${CONTENT_MAX_WIDTH}px`,
  margin: `0 auto ${spacing(8)}`,

  '& > *:nth-of-type(1)': {
    marginBottom: spacing(4),
  },
}));

const ReadingContentContainer = forwardRef<
  HTMLDivElement,
  ReadingContentContainerProps
>(
  ({ children }, ref): JSX.Element => (
    <Container ref={ref}>
      <Content>{children}</Content>
    </Container>
  )
);

ReadingContentContainer.displayName = 'ReadingContentContainer';

export default ReadingContentContainer;
