import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { constSize } from 'utils';

export const Container = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),

  [breakpoints.down('md')]: {
    '&.nested': {
      gap: 0,
    },

    '& .normal.small.clickable.nested': {
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
    },
  },
}));

export const ExerciseInvitationContainer = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: spacing(6),
    marginTop: spacing(2),

    [breakpoints.down('md')]: {
      padding: spacing(4),
    },
  })
);

export const Buttons = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),

  '& .MuiSvgIcon-root': {
    ...constSize('16px'),
    marginLeft: spacing(1),
  },
}));
