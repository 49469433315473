import React from 'react';
import Box from '@mui/material/Box';
import { Palette, styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import { CardButton } from 'components/Button';
import { HotKey } from 'components/HotKey';
import { NumericCircleIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import { FlashcardScore } from 'types';
import { useMarkdownTextScale } from 'components/Markdown';

const setIconColor = (palette: Palette, score: FlashcardScore) => {
  switch (score) {
    case FlashcardScore.NotAtAll:
    case FlashcardScore.Slightly:
      return palette.error.main;
    case FlashcardScore.Moderately:
      return palette.warning.dark;
    case FlashcardScore.KnowWell:
    case FlashcardScore.Perfectly:
      return palette.success.dark;
    default:
      return '';
  }
};

interface ScoreButtonProps {
  className: string;
  disabled: boolean;
  label: string | number;
  loading: boolean;
  onClick: () => void;
  score: number;
  isBottomBar?: boolean;
}

const ScoreButtonContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  width: '100%',

  '&.bottom-bar': {
    justifyContent: 'center',
  },

  [breakpoints.up('xl')]: {
    flexDirection: 'column',
  },

  '& .hot-key': {
    display: 'none',
    marginLeft: 'auto',

    [breakpoints.up('lg')]: {
      display: 'inline-flex',
    },

    [breakpoints.up('xl')]: {
      margin: 0,
    },
  },

  '&.bottom-bar .hot-key': {
    padding: 0,
    display: 'none',
  },

  '&.loading': {
    opacity: 0.3,
  },
}));

const StyledBody = styled(Body)<{ scale: number }>(
  ({ scale, theme: { typography } }) => ({
    whiteSpaces: 'no-wrap',
    fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,

    '&.bottom-bar': {
      display: 'none',
    },
  })
);

const ScoreButton = ({
  className,
  disabled,
  label,
  loading,
  onClick,
  score,
  isBottomBar,
}: ScoreButtonProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();
  const { palette } = useTheme();

  return (
    <CardButton
      className={clsx(className, { 'bottom-bar': isBottomBar })}
      disableRipple
      disabled={disabled}
      isBottomBar={isBottomBar}
      loading={loading}
      onClick={onClick}
    >
      <ScoreButtonContainer
        className={clsx({ 'bottom-bar': isBottomBar, loading })}
      >
        <NumericCircleIcon
          color={setIconColor(palette, score)}
          number={score}
        />
        <StyledBody
          bold
          className={clsx({ 'bottom-bar': isBottomBar })}
          scale={scale}
        >
          {label}
        </StyledBody>
        <HotKey className="secondary" hotKey={score} />
      </ScoreButtonContainer>
    </CardButton>
  );
};

export default ScoreButton;
