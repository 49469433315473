import React, { ReactElement, useMemo } from 'react';
import { EProductType, IConcept } from '@quesmed/types-rn/models';

import { ExerciseLayout } from 'components/Layout';
import {
  OSCE_SEARCH_LABEL,
  PACES_TYPE_ORDER,
  PanelItems,
} from 'components/LearningMaterials';
import { PathName } from 'types';
import { usePacesKnowledgeLibraryData } from './PacesKnowledgeLibrary';

interface PacesKnowledgeLibraryLayoutProps {
  children: ReactElement | ReactElement[];
}

interface ConceptMap {
  [key: number]: IConcept[];
}

export const PacesKnowledgeLibraryLayout = ({
  children,
}: PacesKnowledgeLibraryLayoutProps) => {
  const {
    activeCategory,
    allConcepts,
    searchBy,
    resetSearch,
    loading,
    searchTerm,
    searchItems,
    search,
  } = usePacesKnowledgeLibraryData();

  const items = useMemo(() => {
    const sortedConcepts: ConceptMap = {};
    const entitlements = {} as {
      [key in EProductType]: string;
    };

    allConcepts.forEach(concept => {
      const { entitlement } = concept;

      if (entitlement) {
        if (sortedConcepts[entitlement.id]) {
          sortedConcepts[entitlement.id].push(concept);
        } else {
          sortedConcepts[entitlement.id] = [concept];
        }

        if (!entitlements[entitlement.id]) {
          entitlements[entitlement.id] = entitlement.name;
        }
      }
    });

    return PACES_TYPE_ORDER.filter(key => sortedConcepts[key]?.length).map(
      category => ({
        id: category,
        name: entitlements[category],
        concepts: sortedConcepts[category],
      })
    );
  }, [allConcepts]);

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          entitlementId={activeCategory}
          items={items}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchTerm ? searchItems : null}
          searchLabel={OSCE_SEARCH_LABEL}
          searchTerm={searchTerm}
          sectionId={activeCategory}
          title="Paces"
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={children}
    />
  );
};
