import React, { useEffect, useMemo, useState } from 'react';
import { EAppType, EProductType, IProduct } from '@quesmed/types-rn/models';
import { useLocation } from 'react-router-dom';

import { keys, localStorageTyped, parseMRCPProducts } from 'utils';
import { AppProductType, Nullable, SubscriptionNames } from 'types';
import ProductDurationTabs from './ProductDurationTabs';
import ProductCard from './ProductCard';
import { PricingSubscriptions } from 'hooks/useSubscriptions';

const formatTabValue = (item: AppProductType) =>
  SubscriptionNames[item].replace('MRCP ', '');

interface MRCPProductsProps {
  appType: EAppType;
  onCheckout: (priceId: string) => void;
  products: IProduct[];
  subscriptions: PricingSubscriptions;
  paymentLoading: boolean;
}

const { getItem: getProduct } = localStorageTyped<EProductType>('product');

const MRCPProducts = ({
  appType,
  onCheckout,
  products: productsData,
  subscriptions,
  paymentLoading,
}: MRCPProductsProps): JSX.Element => {
  const [selectedDuration, setSelectedDuration] = useState<number>();
  const [durations, setDurations] = useState<number[]>();
  const { state } = useLocation<{ product: EProductType }>();
  const [selectedProduct, setSelectedProduct] = useState<
    Nullable<EProductType>
  >(state?.product || getProduct() || EProductType.MRCP_PART1);
  const products = useMemo(
    () => parseMRCPProducts(productsData),
    [productsData]
  );

  useEffect(() => {
    if (products && selectedProduct) {
      const durations = products[selectedProduct].map(
        ({ duration }) => duration
      );
      setDurations(durations);
    }
  }, [products, selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      const durations = products[selectedProduct].map(
        ({ duration }) => duration
      );

      if (!durations.includes(Number(selectedDuration))) {
        setSelectedDuration(durations[0]);
      }
    }
  }, [products, selectedDuration, selectedProduct]);

  const handleSelectProduct = (product: EProductType) => {
    setSelectedProduct(product);
  };

  const product =
    products && selectedProduct && selectedDuration
      ? products[selectedProduct].find(
          ({ duration }) => duration === selectedDuration
        )
      : null;

  return (
    <>
      <ProductDurationTabs
        items={keys(products)}
        selectItem={handleSelectProduct}
        selectedItem={selectedProduct}
        valueFormatter={formatTabValue}
      />
      {durations ? (
        <ProductDurationTabs
          items={durations}
          selectItem={setSelectedDuration}
          selectedItem={selectedDuration}
        />
      ) : null}
      {product ? (
        <ProductCard
          appType={appType}
          onCheckout={onCheckout}
          paymentLoading={paymentLoading}
          subscriptions={subscriptions}
          {...product}
          typeId={product.typeId as AppProductType}
        />
      ) : null}
    </>
  );
};

export default MRCPProducts;
