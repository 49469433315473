import React from 'react';

import { Modal, ModalProps } from 'components/Modal';

const RejectMatchmakingModal = (props: ModalProps): JSX.Element => (
  <Modal
    closeOnBackdrop={false}
    closeOnEscape={false}
    danger
    description="It looks like the team has been found, if you leave now you will lose access to it."
    maxWidth="md"
    noPaddingY
    sizeVariant="md"
    submitLabel="Leave session"
    title="Are you sure you want to exit?"
    {...props}
  />
);

export default RejectMatchmakingModal;
