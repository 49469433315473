import React, { useCallback, useEffect, useRef } from 'react';
import {
  EProductType,
  IMarksheet,
  IMarksheetMark,
} from '@quesmed/types-rn/models';
import { useHotkeys } from 'react-hotkeys-hook';

import { QuestionChoices } from 'components/QuestionChoices';
import { useSelectChoice, useToggleStrikeChoice } from './hooks/index';
import { calcVotesPercentage } from 'utils';
import { KeyboardKey, Nilable, Nullable } from 'types';
import { usePlatform } from 'context/PlatformState';

export interface SingleBestAnswerQuestionProps {
  mark: IMarksheetMark;
  marksheet: IMarksheet;
  questionNumber: number;
  selectedChoiceId: Nilable<number>;
  setSelectedChoiceId: (id: Nilable<number>) => void;
  showAnswers: boolean;
  solo: boolean;
  isSample?: boolean;
}

const SingleBestAnswerQuestion = ({
  mark,
  marksheet,
  questionNumber,
  selectedChoiceId,
  setSelectedChoiceId,
  showAnswers,
  solo,
  isSample,
}: SingleBestAnswerQuestionProps): JSX.Element => {
  const { product } = usePlatform();
  const { id: marksheetId } = marksheet;
  const { id: markId, question, isAnswered, striked } = mark;
  const footerButtonRef = useRef<Nullable<HTMLButtonElement>>(null);
  const selectChoice = useSelectChoice(Number(marksheetId), Number(markId));

  const toggleStrikeChoice = useToggleStrikeChoice(
    Number(marksheetId),
    Number(markId),
    isSample
  );

  const { totalVotes, choices } = question;

  const handleChange = useCallback(
    async (choiceId: number) => {
      if (striked.includes(Number(choiceId))) {
        await toggleStrikeChoice(
          striked.filter(id => id !== Number(choiceId)),
          questionNumber - 1
        );
      }
      if (solo) {
        setSelectedChoiceId(choiceId);
      } else {
        selectChoice(choiceId);
      }
    },
    [
      questionNumber,
      selectChoice,
      setSelectedChoiceId,
      solo,
      striked,
      toggleStrikeChoice,
    ]
  );

  const handleChangeByIndex = useCallback(
    (index: number) => () => {
      if (showAnswers) {
        return;
      }
      const { id } = choices[index];
      handleChange(Number(id));
    },
    [choices, handleChange, showAnswers]
  );

  useHotkeys(
    KeyboardKey.One,
    handleChangeByIndex(0),
    { enabled: product !== EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Two,
    handleChangeByIndex(1),
    { enabled: product !== EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Three,
    handleChangeByIndex(2),
    { enabled: product !== EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Four,
    handleChangeByIndex(3),
    { enabled: product !== EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.Five,
    handleChangeByIndex(4),
    { enabled: product !== EProductType.MSRA },
    [handleChangeByIndex]
  );

  useHotkeys(
    KeyboardKey.A,
    handleChangeByIndex(0),
    { enabled: product === EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.B,
    handleChangeByIndex(1),
    { enabled: product === EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.C,
    handleChangeByIndex(2),
    { enabled: product === EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.D,
    handleChangeByIndex(3),
    { enabled: product === EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.E,
    handleChangeByIndex(4),
    { enabled: product === EProductType.MSRA },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.F,
    handleChangeByIndex(5),
    { enabled: product === EProductType.MSRA && choices.length > 5 },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.G,
    handleChangeByIndex(6),
    { enabled: product === EProductType.MSRA && choices.length > 6 },
    [handleChangeByIndex]
  );
  useHotkeys(
    KeyboardKey.H,
    handleChangeByIndex(7),
    { enabled: product === EProductType.MSRA && choices.length > 7 },
    [handleChangeByIndex]
  );

  const handleStrikeOption = useCallback(
    async (choiceId: number) => {
      let newData = [...(striked ?? [])];
      const isStriked = striked.includes(Number(choiceId));
      if (isStriked) {
        newData = newData.filter(id => id !== Number(choiceId));
      } else {
        newData.push(Number(choiceId));
      }
      await toggleStrikeChoice(newData, questionNumber - 1);

      if (
        !isStriked &&
        selectedChoiceId &&
        Number(choiceId) === selectedChoiceId
      ) {
        if (solo) {
          setSelectedChoiceId(undefined);
        } else {
          selectChoice(undefined);
        }
      }
    },
    [
      striked,
      toggleStrikeChoice,
      questionNumber,
      selectedChoiceId,
      solo,
      setSelectedChoiceId,
      selectChoice,
    ]
  );

  useEffect(() => {
    if (footerButtonRef.current) {
      footerButtonRef.current.focus();
    }
  }, [selectedChoiceId]);

  const votes = calcVotesPercentage(
    question.choices,
    totalVotes,
    showAnswers ? selectedChoiceId : undefined
  );

  return (
    <QuestionChoices
      choices={question.choices}
      crossedOutChoiceIds={striked}
      isAnswered={isAnswered}
      isSample={isSample}
      name={`Question ${questionNumber}, (id: ${question.id})`}
      onChange={handleChange}
      onStrike={handleStrikeOption}
      selectedChoiceId={selectedChoiceId}
      showAnswers={showAnswers}
      votes={votes}
    />
  );
};

export default SingleBestAnswerQuestion;
