import { useEffect } from 'react';
import { ApolloQueryResult, useSubscription } from '@apollo/client';
import { EOsceMarksheetAction } from '@quesmed/types-rn/models';
import {
  IOsceMarksheetActionData,
  IOsceMarksheetActionVar,
  OSCE_MARKSHEET_ACTION,
} from '@quesmed/types-rn/resolvers/subscription';
import { useNavigate } from 'react-router-dom';
import {
  IOsceMarksheetData,
  IOsceMarksheetVar,
  IOsceStationsData,
  IOsceStationsVar,
  OSCE_MARKSHEET,
  OSCE_STATIONS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import isNil from 'lodash/isNil';

import { useCurrentUser } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';

const { stations } = paths;

// When user is alone the initialMarksheetId is being used to update marksheet
// and initialize group study and move user to group study lobby
const useOnStudyAction = (
  sessionId?: string,
  initialMarksheetId?: number,
  isCandidate?: boolean,
  refetch?: (
    variables?: Partial<IOsceMarksheetVar> | undefined
  ) => Promise<ApolloQueryResult<IOsceMarksheetData>>
) => {
  const { id: currentUserId } = useCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar({
    unique: true,
  });
  const { data } = useSubscription<
    IOsceMarksheetActionData,
    IOsceMarksheetActionVar
  >(OSCE_MARKSHEET_ACTION, {
    variables: {
      sessionId: sessionId || '',
    },
    skip: !sessionId,
    onData: ({ client, data: subscriptionData }) => {
      const {
        osceMarksheetId,
        osceStationId,
        users,
        members,
        activeUsers,
        feedback,
        globalScore,
        marks,
        startedAt,
        endedAt,
        state,
        action,
        score,
        timeTaken,
        completed,
      } = subscriptionData.data?.osceMarksheetAction || {};
      const finalId = initialMarksheetId || osceMarksheetId;

      if (finalId) {
        const prevData = client.readQuery<
          IOsceMarksheetData,
          IOsceMarksheetVar
        >({
          variables: { id: Number(finalId) },
          query: OSCE_MARKSHEET,
        });

        if (prevData) {
          const { osceMarksheet } = prevData.restricted || {};

          const newMarksheet = { ...osceMarksheet };

          if (members) {
            newMarksheet.members = members;
          }

          if (users) {
            newMarksheet.users = users;
          }

          if (activeUsers) {
            newMarksheet.activeUsers = activeUsers;
          }

          if (completed) {
            newMarksheet.completed = completed;
          }

          if (!isNil(state)) {
            newMarksheet.state = state;
          }

          if (marks) {
            newMarksheet.marks = marks;
          }

          if (globalScore) {
            newMarksheet.globalScore = globalScore;
          }

          if (feedback) {
            newMarksheet.feedback = feedback;
          }

          if (endedAt) {
            newMarksheet.endedAt = endedAt;
          }

          if (startedAt) {
            newMarksheet.startedAt = startedAt;
          }

          if (timeTaken) {
            newMarksheet.timeTaken = timeTaken;
          }

          if (score) {
            newMarksheet.score = score;
          }

          if (osceStationId && action === EOsceMarksheetAction.SELECT) {
            const osceStationData = client.readQuery<
              IOsceStationsData,
              IOsceStationsVar
            >({
              variables: { solo: false },
              query: OSCE_STATIONS,
            });

            const { osceStations } = osceStationData?.restricted || {};
            const osceStation = osceStations?.find(
              station => Number(station.id) === Number(osceStationId)
            );

            if (osceStation) {
              newMarksheet.osceStation = osceStation;
              newMarksheet.osceStationId = osceStationId;
            }
          }

          client.writeQuery({
            query: OSCE_MARKSHEET,
            variables: { id: Number(finalId) },
            data: {
              ...prevData,
              restricted: {
                ...prevData.restricted,
                osceMarksheet: {
                  ...newMarksheet,
                },
              },
            },
          });
        }
      }
    },
  });

  const { osceMarksheetAction } = data || {};
  const { action } = osceMarksheetAction || {};

  useEffect(() => {
    const { action, user } = osceMarksheetAction || {};
    const { displayName, id } = user || {};
    const isNotCurrentUser = id !== currentUserId;

    if (action !== undefined) {
      switch (action) {
        case EOsceMarksheetAction.SELECT: {
          if (isCandidate) {
            enqueueSnackbar('Station has been selected.');
          }
          break;
        }
        case EOsceMarksheetAction.PRESTART: {
          if (isCandidate) {
            enqueueSnackbar(
              'Station has been prestarted. You will join there in a moment.'
            );
          }
          break;
        }
        case EOsceMarksheetAction.START: {
          if (isCandidate) {
            enqueueSnackbar('You can join the station now.');
          }
          break;
        }
        case EOsceMarksheetAction.LEFT:
        case EOsceMarksheetAction.DISCONNECT: {
          if (isNotCurrentUser) {
            enqueueSnackbar(`${displayName} has left the station.`);
          }
          break;
        }
        case EOsceMarksheetAction.CONNECT: {
          if (isNotCurrentUser) {
            enqueueSnackbar(`${displayName} has joined the station.`);
          }
          break;
        }
        case EOsceMarksheetAction.KICK: {
          navigate(stations.root, { state: { leave: true } });
          enqueueSnackbar('Session expired! Please create a new station.');
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    osceMarksheetAction,
    currentUserId,
    enqueueSnackbar,
    navigate,
    isCandidate,
    refetch,
  ]);

  return { action };
};

export default useOnStudyAction;
