import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    height: '245px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(8, 0, 0),

    [breakpoints.up('md')]: {
      margin: spacing(8, 0, 6),
    },

    '& .tooltip-wrapper': {
      backgroundColor: palette.text.primary,
      borderRadius: '7px',
      color: palette.primary.contrastText,
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
      transition: 'all .1s ease',
      padding: 0,
    },
    '& .tooltip-table': {
      margin: 0,
      borderSpacing: 0,
    },
    '& .table-head': {
      padding: spacing(2, 4),
      color: palette.tooltip.fontColor,
      fontStyle: 'normal',
      textAlign: 'left',
      backgroundColor: palette.secondary.main,
      borderRadius: '7px 7px 0 0',
      borderWidth: 0,
    },
    '& .colour-mark': {
      marginRight: '10px',
      margin: '3px',
      display: 'inline-block',
      borderColor: palette.primary.contrastText,
      borderRadius: '3px',
      height: spacing(2),
      width: spacing(2),
    },
    '& .line-row': {
      backgroundColor: 'inherit',
      borderWidth: 0,
    },
    '& .line-cell': {
      borderWidth: 0,
      color: palette.tooltip.fontColor,
      padding: spacing(3, 4, 4, 4),
      display: 'flex',
      alignItems: 'center',
    },
    '& tbody > .line-row:nth-of-type(1) > .line-cell': {
      paddingBottom: spacing(2),
    },
    '& tbody > .line-row:nth-of-type(2) > .line-cell': {
      paddingTop: 0,
    },
    '& tbody > .line-row:nth-of-type(1) > .line-cell > .colour-mark': {
      background: palette.success.light,
    },
    '& tbody > .line-row:nth-of-type(2) > .line-cell > .colour-mark': {
      background: palette.error.light,
    },
    '& .label': {
      color: 'inherit',
      paddingLeft: '3px',
    },
    '& .percentage': {
      color: palette.icon.light,
      paddingLeft: '5px',
    },
  })
);

export default StyledBox;
