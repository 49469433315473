import React from 'react';
import { round } from 'lodash';

import {
  SelectionInputCellLabel,
  SelectionInputProgressCellLabel,
} from 'components/ExerciseBuilder';
import {
  ProgressCorrectIcon,
  ProgressInitialIcon,
  ProgressStartedIcon,
} from 'components/Icons';
import { ProgressLineChartProps } from 'components/ProgressLineChart/ProgressLineChart';
import {
  BadgeScore,
  ProgressScoreBadgeProps,
} from 'components/ProgressScoreBadge/ProgressScoreBadge';
import { MAX_SCORE } from 'config/constants';
import { setQuestionsProgressData } from 'pages/Questions/setQuestionsProgressData';
import { ExtendedMLATopic, ItemStatusLabel, MLASelectionState } from 'types';
import { calcMLAQuestions } from 'utils';
import { LineChartItem } from 'components/ProgressLineChart/index';

export const checkBoxLabelFormatter = (
  selectionState: MLASelectionState,
  withIcon?: boolean
) => {
  const Label = (
    {
      id,
      name,
      conditions,
      totalQuestions,
      presentations,
      correctQuestions,
      incorrectQuestions,
    }: ExtendedMLATopic,
    parentId?: number
  ): JSX.Element => {
    let counts = [0, 0];
    const selectedConditionsWithinTopic =
      selectionState.get(Number(parentId || id))?.selectedConditionsIds ||
      new Set();
    const selectedPresentationsWithinTopic =
      selectionState.get(Number(parentId || id))?.selectedPresentationsIds ||
      new Set();

    if (parentId) {
      const total = totalQuestions ?? 0;
      counts = [
        selectedConditionsWithinTopic.has(Number(id)) ||
        selectedPresentationsWithinTopic.has(Number(id))
          ? total
          : 0,
        total,
      ];
    } else {
      counts = calcMLAQuestions(
        selectedConditionsWithinTopic,
        selectedPresentationsWithinTopic,
        conditions,
        presentations
      );
    }

    const [count, total] = counts;
    const data = setQuestionsProgressData(correctQuestions, incorrectQuestions);
    const parseData = (data: LineChartItem[], total: number) => {
      const notAnswered = data.reduce((a, b) => a - (b.value ?? 0), total);

      return notAnswered
        ? [
            ...data,
            {
              type: ItemStatusLabel.NotAnswered,
              value: notAnswered,
              label: ItemStatusLabel.NotAnswered,
            } as LineChartItem,
          ]
        : data;
    };
    const fullData = parseData(data, total);

    return withIcon ? (
      <SelectionInputProgressCellLabel
        correctQuestions={correctQuestions || 0}
        count={count}
        data={fullData}
        name={name}
        total={total}
      />
    ) : (
      <SelectionInputCellLabel count={count} name={name} total={total} />
    );
  };

  return Label;
};

export const notAnsweredFormatter = ({
  correctQuestions,
  incorrectQuestions,
  totalQuestions,
}: ExtendedMLATopic) =>
  totalQuestions
    ? totalQuestions - (correctQuestions ?? 0) - (incorrectQuestions ?? 0)
    : 0;

export const progressFormatter = ({
  correctQuestions,
  incorrectQuestions,
  name,
  totalQuestions,
}: ExtendedMLATopic): ProgressLineChartProps => ({
  title: name,
  data: setQuestionsProgressData(correctQuestions, incorrectQuestions),
  total: totalQuestions,
});

export const scoreFormatter = ({
  correctQuestions,
  incorrectQuestions,
}: ExtendedMLATopic): ProgressScoreBadgeProps => {
  const totalAnsweredQuestions =
    (incorrectQuestions || 0) + (correctQuestions || 0);
  const total = totalAnsweredQuestions ?? 0;
  const score = total
    ? round(((totalAnsweredQuestions ?? 0) * MAX_SCORE) / total)
    : 0;
  let status = BadgeScore.NotStarted;
  let icon = <ProgressInitialIcon />;
  if (score === MAX_SCORE) {
    status = BadgeScore.Correct;
    icon = <ProgressCorrectIcon />;
  } else if (score > 0) {
    status = BadgeScore.InProgress;
    icon = <ProgressStartedIcon />;
  }

  return { score, icon, status };
};
