import { useMutation } from '@apollo/client';
import {
  IReBuildMarksheetData,
  IReBuildMarksheetVar,
  RE_BUILD_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';

export interface IMarksheetFilterInput {
  limit?: number;
  date?: string;
  offset?: number;
  solo?: boolean;
}

const useRebuildMarksheet = () => {
  const navigate = useNavigate();
  const [reBuild, { loading: rebuildLoading }] = useMutation<
    IReBuildMarksheetData,
    IReBuildMarksheetVar
  >(RE_BUILD_MARKSHEET, {
    onCompleted: ({ restricted }) => {
      const { id } = restricted.reBuildMarksheet;

      navigate(`${paths.questions.root}/solo/quiz/${id}`);
    },
  });

  const rebuildMarksheet = useCallback(
    (marksheetId: number) => {
      reBuild({
        variables: {
          marksheetId,
        },
      });
    },
    [reBuild]
  );

  return {
    rebuildMarksheet,
    rebuildLoading,
  };
};

export default useRebuildMarksheet;
