import React from 'react';

import {
  CheckboxBlankIcon,
  CheckboxMarkedIcon,
  CheckIcon,
  CloseIcon,
} from 'components/Icons';
import { QuestionAnswerStatus } from 'types';

const setIcon = (state: QuestionAnswerStatus) => {
  switch (state) {
    case QuestionAnswerStatus.INVALID:
      return <CloseIcon />;
    case QuestionAnswerStatus.VALID:
      return <CheckIcon />;
    case QuestionAnswerStatus.SELECTED:
      return <CheckboxMarkedIcon />;
    default:
      return <CheckboxBlankIcon />;
  }
};

export default setIcon;
