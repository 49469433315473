import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect } from 'react';

import { useSetActiveSpeaker } from './AgoraActiveSpeaker';
import { useAgoraAPI } from './AgoraAPI';
import { useAgoraSetState, useAgoraState } from './AgoraState';
import { AgoraEvent, AgoraInfoMessage, Media, UserVolume } from './types';

const useAgoraEvents = () => {
  const { mutedUsers } = useAgoraState();
  const { setSessionExpired, setMutedUsers, setWillExpired } =
    useAgoraSetState();
  const { setActiveSpeaker } = useSetActiveSpeaker();
  const { client } = useAgoraAPI();

  const handleUserPublished = useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: Media) => {
      await client.subscribe(user, mediaType);

      if (mediaType === Media.AUDIO) {
        const { uid } = user;

        setMutedUsers(mutedUsers.filter(id => id !== Number(uid)));
        user.audioTrack?.play();
      }
    },
    [client, setMutedUsers, mutedUsers]
  );

  const handleUserUnpublished = useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: Media) => {
      if (mediaType === Media.AUDIO) {
        user.audioTrack?.stop();
      }
    },
    []
  );

  const handleTokenWillExpire = useCallback(() => {
    setWillExpired(true);
  }, [setWillExpired]);

  const handleTokenDidExpire = useCallback(() => {
    setWillExpired(false);
    setSessionExpired(true);
  }, [setSessionExpired, setWillExpired]);

  const handleUserInfo = useCallback(
    (uid: number, msg: AgoraInfoMessage) => {
      switch (msg) {
        case AgoraInfoMessage.MUTE:
          setMutedUsers([...mutedUsers, Number(uid)]);
          break;
        case AgoraInfoMessage.UNMUTE:
          setMutedUsers(mutedUsers.filter(id => id !== Number(uid)));
          break;
        default:
          return;
      }
    },
    [setMutedUsers, mutedUsers]
  );

  const handleVolumeIndicator = useCallback(
    (userVolumes: UserVolume[]) => {
      if (userVolumes.length > 0) {
        const activeSpeaker = userVolumes.reduce((usr1, usr2) =>
          usr1.level > usr2.level ? usr1 : usr2
        );
        setActiveSpeaker(activeSpeaker);
      } else {
        setActiveSpeaker(undefined);
      }
    },
    [setActiveSpeaker]
  );

  useEffect(() => {
    return () => {
      client.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    client.on(AgoraEvent.TOKEN_DID_EXPIRE, handleTokenDidExpire);

    return () => {
      client.off(AgoraEvent.TOKEN_DID_EXPIRE, handleTokenDidExpire);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTokenDidExpire]);

  useEffect(() => {
    client.on(AgoraEvent.VOLUME_INDIATOR, handleVolumeIndicator);

    return () => {
      client.off(AgoraEvent.VOLUME_INDIATOR, handleVolumeIndicator);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleVolumeIndicator]);

  useEffect(() => {
    client.on(AgoraEvent.TOKEN_WILL_EXPIRE, handleTokenWillExpire);
    client.on(AgoraEvent.USER_UNPUBLISHED, handleUserUnpublished);

    return () => {
      client.off(AgoraEvent.TOKEN_WILL_EXPIRE, handleTokenWillExpire);
      client.off(AgoraEvent.USER_UNPUBLISHED, handleUserUnpublished);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTokenWillExpire, handleUserUnpublished]);

  useEffect(() => {
    client.on(AgoraEvent.USER_INFO, handleUserInfo);

    return () => {
      client.off(AgoraEvent.USER_INFO, handleUserInfo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUserInfo]);

  useEffect(() => {
    client.on(AgoraEvent.USER_PUBLISHED, handleUserPublished);

    return () => {
      client.off(AgoraEvent.USER_PUBLISHED, handleUserPublished);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUserPublished]);
};

export default useAgoraEvents;
