export const sortByCreatedAt = <T extends { createdAt: number | Date }>(
  dates: T[]
) =>
  [...dates].sort((a, b) => {
    const x =
      typeof a.createdAt !== 'number'
        ? new Date(a.createdAt).getTime()
        : a.createdAt;
    const y =
      typeof b.createdAt !== 'number'
        ? new Date(b.createdAt).getTime()
        : b.createdAt;

    return x - y;
  });
