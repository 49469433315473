import { EOsceType, IOsceStation } from '@quesmed/types-rn/models';

import { KeyField, SelectionColumn } from 'components/ExerciseBuilder';
import { STATION_PROGRESS_LABELS } from 'components/GridCell/CellLabel';
import { DIFFICULTY_OPTIONS } from 'config/constants';
import { StationCategory, StationType, ToggleOptions, UIColumn } from 'types';
import {
  progressFormatter,
  RadioLabelFormatter,
  scoreFormatter,
} from './studyBuilderUtils';

export const CATEGORIES: ToggleOptions<StationType> = [
  {
    label: StationCategory.HistoryTaking,
    value: EOsceType.HISTORY_TAKING,
  },
  {
    label: StationCategory.CommunicationSkills,
    value: EOsceType.COMMUNICATION_SKILLS,
  },
  {
    label: StationCategory.Examination,
    value: EOsceType.EXAMINATION,
  },
  {
    label: StationCategory.ABCDE,
    value: EOsceType.ABCDE,
  },
  {
    label: StationCategory.Procedures,
    value: EOsceType.PROCEDURE,
  },
  {
    label: StationCategory.DataInterpretation,
    value: EOsceType.INTERPRETATION,
  },
];

type StationColumn = IOsceStation & UIColumn;

export const DETAILS_COLUMNS: SelectionColumn<StationColumn>[] = [
  {
    align: 'left',
    key: KeyField.Radio,
    type: 'input',
    label: 'Station names',
  },
  {
    align: 'left',
    formatter: RadioLabelFormatter,
    key: KeyField.Label,
    label: 'Station names',
    type: 'label',
  },
  {
    align: 'left',
    key: 'difficulty',
    label: 'Level',
    formatter: ({ difficulty }: IOsceStation) => DIFFICULTY_OPTIONS[difficulty],
    type: 'default',
  },
  {
    align: 'left',
    key: KeyField.Performed,
    formatter: ({ completedMarks }) => completedMarks,
    ...STATION_PROGRESS_LABELS[0],
    type: 'default',
  },
  {
    align: 'left',
    key: KeyField.NotPerformed,
    formatter: ({ completedMarks, totalMarks }) => totalMarks - completedMarks,
    ...STATION_PROGRESS_LABELS[1],
    type: 'default',
  },
  {
    align: 'left',
    scoreFormatter: scoreFormatter,
    key: KeyField.Score,
    label: 'Candidate score',
    labelInfo: 'Info about Candidate score',
    type: 'default',
  },
];

export const OVERVIEW_COLUMNS: SelectionColumn<StationColumn>[] = [
  {
    align: 'left',
    key: KeyField.Radio,
    type: 'input',
    label: 'Station names',
  },
  {
    align: 'left',
    formatter: RadioLabelFormatter,
    key: KeyField.Label,
    label: 'Station names',
    type: 'label',
  },
  {
    align: 'left',
    key: KeyField.Progress,
    label: 'Progress',
    type: 'bar',
    progressLabels: STATION_PROGRESS_LABELS,
    progressFormatter,
  },
];
