import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IPreset } from '@quesmed/types-rn/models';
import { useLocation } from 'react-router-dom';

import {
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  LinkIcon,
  MockTestsIcon,
  RestoreIcon,
  RocketLaunchIcon,
} from 'components/Icons';
import { useHover, usePrevious } from 'hooks';
import {
  ProgressLineChart,
  ProgressLineChartProps,
} from 'components/ProgressLineChart';
import { formatDate, isFlashcardsPage } from 'utils';
import { ItemStatusLabel, Nullable } from 'types';
import { ActionPopper } from 'components/ActionPopper';
import { answersFormatter } from 'utils/string/answersFormatter';
import { useCurrentUser, useDemo } from 'Auth';
import QuestionsPreBuildModal from 'pages/Questions/QuestionsPreBuildModal';
import ModalActionController from './ModalActionController';
import { extractIds } from './utils';
import { ModalVariant } from './types';
import { usePresetGlobalState } from './state/PresetGlobalState';
import { DateCell, PresetCell } from 'components/TableCells';
import FlashcardsPreBuildModal from 'pages/Flashacards/FlashcardsPreBuildModal';
import { TableRowComponent } from 'pages/dashboard/components/Activity/components/types';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { useQuestionGroupStudyData } from 'pages/Questions/QuestionsGroupStudyController';
import { Tabs } from 'components/ExerciseBuilder/types';

const questionsProgressFormatter = (
  { correctQuestions, totalQuestions, incorrectQuestions }: IPreset,
  numberOfConcepts: Nullable<string>,
  firstConceptName?: string
): ProgressLineChartProps => ({
  title: `${firstConceptName} ${numberOfConcepts}`,
  data: [
    {
      type: ItemStatusLabel.Confident,
      value: correctQuestions,
      label: ItemStatusLabel.Correct,
    },
    {
      type: ItemStatusLabel.ToLearn,
      value: incorrectQuestions,
      label: ItemStatusLabel.Incorrect,
    },
  ],
  total: totalQuestions,
});

const flashcardsProgressFormatter = (
  { greenCards, redCards, yellowCards, totalCards }: IPreset,
  numberOfConcepts: Nullable<string>,
  firstConceptName?: string
): ProgressLineChartProps => ({
  title: `${firstConceptName} ${numberOfConcepts}`,
  data: [
    {
      type: ItemStatusLabel.Confident,
      value: greenCards,
      label: ItemStatusLabel.Correct,
    },
    {
      type: ItemStatusLabel.ToReview,
      value: yellowCards,
      label: ItemStatusLabel.ToReview,
    },
    {
      type: ItemStatusLabel.ToLearn,
      value: redCards,
      label: ItemStatusLabel.Incorrect,
    },
  ],
  total: totalCards,
});

const PresetTableRow: TableRowComponent<IPreset> = ({ result }) => {
  const { pathname } = useLocation();
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [popperRef, popperRefValue] = useHover<HTMLButtonElement>();
  const [showPrebuildModal, setShowPrebuildModal] = useState(false);
  const [modalVariant, setModalVariant] = useState<string>('');
  const { setPreset, setIsPresetEditOpen, setTab } = usePresetGlobalState();
  const flashcardsMode = isFlashcardsPage(pathname);
  const { id } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const isDemo = useDemo();
  const { marksheet } = useQuestionGroupStudyData() || {};
  const { solo = true } = marksheet || {};

  const progressFormatter = flashcardsMode
    ? flashcardsProgressFormatter
    : questionsProgressFormatter;

  const popperRefPrevValue = usePrevious(popperRefValue);

  const handleClosePrebuildModal = () => {
    setShowPrebuildModal(false);
  };

  const {
    createdAt,
    name,
    userId,
    entitlement,
    totalQuestions,
    correctQuestions,
    totalCards,
    greenCards,
    concepts,
  } = result;

  const category = entitlement.name;
  const selections = extractIds(result);

  const handleOpenMenu = () => {
    setIsPopperOpen(true);
  };

  const handleCloseMenu = () => {
    setIsPopperOpen(false);
  };

  useEffect(() => {
    if (isPopperOpen && popperRefPrevValue && !popperRefValue) {
      handleCloseMenu();
    }
  }, [isPopperOpen, popperRefPrevValue, popperRefValue]);

  const numberOfConcepts =
    concepts && concepts?.length > 1 ? `+ ${concepts?.length - 1}` : null;

  const showDemoMessage = () => {
    enqueueSnackbar(locales.common.demo.feature);
  };

  const startExercise = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      if (solo) {
        setShowPrebuildModal(true);
      } else {
        setPreset(result);
        setTab(Tabs.Builder);
      }
    }
  };

  const renamePreset = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setModalVariant(ModalVariant.RENAME);
    }
  };

  const editPreset = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setPreset(result);
      setIsPresetEditOpen(true);
    }
  };

  const duplicatePreset = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setModalVariant(ModalVariant.DUPLICATE);
    }
  };

  const deletePreset = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setModalVariant(ModalVariant.DELETE);
    }
  };

  const copyLink = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setModalVariant(ModalVariant.LINK);
    }
  };

  const resetPreset = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      setModalVariant(ModalVariant.RESET);
    }
  };

  const switchActionItems = (userId: number, presetUserId: number) => {
    if (userId === presetUserId) {
      return [
        {
          icon: <RocketLaunchIcon />,
          label: solo ? 'Start Exercise' : 'Load Preset',
          onClick: startExercise,
        },
        { icon: <MockTestsIcon />, label: 'Edit Preset', onClick: editPreset },
        { icon: <EditIcon />, label: 'Rename Preset', onClick: renamePreset },
        {
          icon: <DuplicateIcon />,
          label: 'Duplicate',
          onClick: duplicatePreset,
        },
        { icon: <LinkIcon />, label: 'Copy Link', onClick: copyLink },
        {
          icon: <RestoreIcon />,
          label: 'Reset Progress',
          onClick: resetPreset,
        },
        {
          icon: <DeleteIcon />,
          label: 'Remove Preset',
          onClick: deletePreset,
          footerItem: true,
        },
      ];
    }

    return [
      {
        icon: <RocketLaunchIcon />,
        label: solo ? 'Start Exercise' : 'Load Preset',
        onClick: startExercise,
      },
      { icon: <DuplicateIcon />, label: 'Duplicate', onClick: duplicatePreset },
      { icon: <RestoreIcon />, label: 'Reset Progress', onClick: resetPreset },
    ];
  };

  return (
    <>
      <TableRow>
        <DateCell>{formatDate(createdAt)}</DateCell>
        <PresetCell>
          <Box>
            [{category}] {name}
          </Box>
          <Box>
            {flashcardsMode ? 'Confident' : 'Correct'} answers:&nbsp;
            {answersFormatter(
              flashcardsMode ? totalCards : totalQuestions,
              flashcardsMode ? greenCards : correctQuestions
            )}
          </Box>
        </PresetCell>
        <TableCell>
          <ProgressLineChart
            {...progressFormatter(
              result,
              numberOfConcepts ? numberOfConcepts : '',
              concepts ? concepts[0].name : ''
            )}
          />
        </TableCell>
        <TableCell>
          {id === userId ? 'My preset' : 'Pre-defined preset'}
        </TableCell>
        <TableCell onClick={handleOpenMenu} ref={popperRef}>
          <ActionPopper
            currentRef={popperRef.current}
            isPopperOpen={isPopperOpen}
            items={switchActionItems(id, userId)}
          />
        </TableCell>
      </TableRow>
      {showPrebuildModal && !flashcardsMode ? (
        <QuestionsPreBuildModal
          entitlementId={Number(entitlement.id)}
          onClose={handleClosePrebuildModal}
          open={showPrebuildModal && !flashcardsMode}
          selection={selections}
          source={`Preset ${name}`}
        />
      ) : null}
      {showPrebuildModal && flashcardsMode ? (
        <FlashcardsPreBuildModal
          entitlementId={Number(entitlement.id)}
          onClose={handleClosePrebuildModal}
          open={showPrebuildModal && flashcardsMode}
          selection={selections}
        />
      ) : null}
      {modalVariant ? (
        <ModalActionController
          concepts={concepts}
          entitlementId={entitlement.id}
          onClose={() => setModalVariant('')}
          open={modalVariant !== ''}
          presetId={result.id}
          presetName={name}
          setOpen={setModalVariant}
          variant={modalVariant}
        />
      ) : null}
    </>
  );
};

export default PresetTableRow;
