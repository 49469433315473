import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Modal, ModalProps } from 'components/Modal';
import { CircularProgress } from 'components/CircularProgress';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(4),
  padding: spacing(2, 0),
}));

const ParticipantsFoundInfo = styled(Box)(
  ({ theme: { palette, typography } }) => ({
    color: palette.text.primary,
    ...typography.button,
  })
);

interface ControlMatchmakingModalProps extends ModalProps {
  participantsSlots: number;
  participantsCount: number;
  startMatchmaking: () => void;
  stopMatchmaking: () => void;
}

const ControlMatchmakingModal = ({
  loading,
  onClose,
  open,
  participantsCount,
  participantsSlots,
  startMatchmaking,
  stopMatchmaking,
}: ControlMatchmakingModalProps): JSX.Element => {
  const init = useRef<boolean>(false);
  useEffect(() => {
    if (!init.current) {
      startMatchmaking();

      if (init) {
        init.current = true;
      }
    }
  }, [loading, startMatchmaking]);

  return (
    <Modal
      complementaryActionLabel={loading ? 'Stop search' : 'Start search'}
      description="You are now in the player search queue. Please wait while we search for other players that match your current class."
      fullWidthComplementary
      maxWidth="md"
      noPaddingY
      onClose={onClose}
      onComplementaryAction={loading ? stopMatchmaking : startMatchmaking}
      open={open}
      showCloseButton
      sizeVariant="md"
      title="Searching for a study group"
    >
      {loading ? (
        <Container>
          <CircularProgress color="primary" fullscreen={false} />
          <ParticipantsFoundInfo>
            Players found {participantsCount}/{participantsSlots}
          </ParticipantsFoundInfo>
        </Container>
      ) : null}
    </Modal>
  );
};

export default ControlMatchmakingModal;
