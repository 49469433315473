import React from 'react';

import { Markdown } from 'components/Markdown';

interface ReadingBriefTitleProps {
  title: string;
}
const ReadingBriefTitle = ({ title }: ReadingBriefTitleProps): JSX.Element => (
  <Markdown markdownStylesProps={{ bottomOffset: false }} text={`# ${title}`} />
);

export default ReadingBriefTitle;
