import React from 'react';
import { mdiStickerPlusOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const StickerPlusOutlineIcon = (props: IconProps) => (
  <Icon path={mdiStickerPlusOutline} {...props} />
);

export default StickerPlusOutlineIcon;
