import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import { EAppType } from '@quesmed/types-rn/models';

import AppsProducts, { Details } from './AppsProducts';
import { Body, H4, H5 } from 'components/Typography';
import {
  AppProductType,
  APPS,
  productsApp,
  ProductState,
  QuesmedAppNames,
  QuesmedProductData,
} from 'types';
import { Button } from 'components/Button';
import { ArrowLeftIcon } from 'components/Icons';
import { useSnackbar } from 'components/Snackbar';
import usePayment from 'hooks/usePayment';
import { useSubscriptions } from 'hooks';
import { ProductsContainer } from './ProductCards';
import { entries, filterObject, isErrorButNotApolloOne } from 'utils';
import { DemoModal } from 'components/Demo';
import { useSubscription } from 'Auth';
import { setAppImage } from 'components/Illustrations';

const LayoutContainer = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  width: '100%',
  padding: spacing(4),
  position: 'relative',

  [breakpoints.up('md')]: {
    padding: spacing(8),
  },

  [breakpoints.up('lg')]: {
    padding: spacing(8, 12),
  },

  '& .MuiSkeleton-root': {
    borderRadius: '9px',
  },
}));

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '936px',
  width: '100%',
  margin: '0 auto',
});

const PricingHeader = styled(H4)(({ theme: { spacing } }) => ({
  textAlign: 'center',
  marginBottom: spacing(4),
}));

const PricingSubHeading = styled(Body)(({ theme: { spacing, palette } }) => ({
  textAlign: 'center',
  maxWidth: '754px',
  marginBottom: spacing(4),
  color: palette.text.secondary,
  margin: `0 auto ${spacing(4)}`,
}));

const DemoContainer = styled(Box)(
  ({ theme: { spacing, breakpoints, palette } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: palette.background.paper,
    marginTop: spacing(6),
    borderRadius: '9px',
    border: `1px solid ${palette.stroke.main}`,

    [breakpoints.down('lg')]: {
      maxWidth: '384px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const DemoInfoContainer = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    textAlign: 'center',

    '& .MuiButton-root': {
      margin: '0 auto',
    },

    [breakpoints.up('lg')]: {
      textAlign: 'left',
      padding: spacing(6),
      maxWidth: '405px',

      '& .MuiButton-root': {
        margin: 0,
      },
    },
  })
);

const DemoSubHeading = styled(Body)(({ theme: { spacing, palette } }) => ({
  margin: spacing(4, 0, 6, 0),
  color: palette.text.secondary,
}));

const Illustration = styled(Box)(({ theme: { palette, spacing } }) => ({
  maxHeight: '236px',
  padding: spacing(4.5, 7),

  '& .app-image': {
    width: '100%',
    maxHeight: '200px',

    '& .app-image__background': {
      fill: palette.background.paper,
    },
  },
}));

const BackButton = styled(Button)(({ theme: { breakpoints, spacing } }) => ({
  position: 'relative',
  left: 'initial',
  top: 'initial',
  margin: `0 auto ${spacing(4)}`,

  [breakpoints.up('lg')]: {
    position: 'absolute',
    left: '40px',
    top: '32px',
  },
}));

const Pricing = (): JSX.Element => {
  const navigate = useNavigate();
  const { appType } = useParams();
  const {
    subscriptions,
    products: productsToBuy,
    loading,
  } = useSubscriptions();
  const [showDemoModal, setShowDemoModal] = useState(false);
  const data = useSubscription();
  const { productStates } = data || {};
  const goBack = () => {
    navigate(-1);
  };

  const handleShowDemoModal = () => setShowDemoModal(true);
  const handleCloseDemoModal = () => setShowDemoModal(false);

  const appEntry = entries(QuesmedAppNames).find(
    ([, value]) => kebabCase(value) === appType
  );

  const appTypeId = appEntry ? appEntry[0] : EAppType.CM_UKMLA;

  const title = QuesmedAppNames[appTypeId];

  const appProducts = productsToBuy?.filter(
    ({ typeId }) => productsApp[typeId as AppProductType] === Number(appTypeId)
  );

  const demoProducts = Object.values<QuesmedProductData>(
    filterObject(APPS[appTypeId].products, product => {
      if (!product?.value) {
        return false;
      }

      return productStates[product.value] === ProductState.Inactive;
    })
  );

  const AppImage = setAppImage(Number(appTypeId));

  const { enqueueSnackbar } = useSnackbar();
  const { handlePayment, loading: paymentLoading } = usePayment();

  const handleCheckout = useCallback(
    async (priceId: string) => {
      if (!priceId) {
        return;
      }
      try {
        await handlePayment(priceId);
      } catch (error) {
        if (isErrorButNotApolloOne(error)) {
          enqueueSnackbar(error.message);
        }
      }
    },
    [enqueueSnackbar, handlePayment]
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <LayoutContainer>
      {!loading && appProducts !== undefined ? (
        <>
          <BackButton color="secondary" onClick={goBack} secondary>
            <ArrowLeftIcon />
            Go Back
          </BackButton>
          <ContentContainer>
            <PricingHeader>{title}</PricingHeader>
            <PricingSubHeading>
              Select one of the products specially selected for you and start
              revising!
            </PricingSubHeading>
            <AppsProducts
              appType={Number(appTypeId)}
              onCheckout={handleCheckout}
              paymentLoading={paymentLoading}
              products={appProducts}
              subscriptions={subscriptions}
            />
            {demoProducts.length ? (
              <DemoContainer>
                <DemoInfoContainer>
                  <Box>
                    <H5>Check out our demo resources</H5>
                    <DemoSubHeading>
                      Try first and decide later! Try out our demo resources and
                      decide if Quesmed is for you!
                    </DemoSubHeading>
                  </Box>
                  <Button
                    color="secondary"
                    onClick={handleShowDemoModal}
                    secondary
                  >
                    Try Out Demo
                  </Button>
                </DemoInfoContainer>
                {AppImage ? (
                  <Illustration>
                    <AppImage />
                  </Illustration>
                ) : null}
              </DemoContainer>
            ) : null}
            {showDemoModal ? (
              <DemoModal
                app={appTypeId}
                onClose={handleCloseDemoModal}
                open={showDemoModal}
                products={demoProducts}
              />
            ) : null}
          </ContentContainer>
        </>
      ) : (
        <LayoutContainer>
          <Details>
            <Skeleton height={48} variant="rectangular" width="410px" />
            <ProductsContainer>
              <Skeleton height={550} variant="rectangular" width="296px" />
              <Skeleton height={550} variant="rectangular" width="296px" />
              <Skeleton height={550} variant="rectangular" width="296px" />
            </ProductsContainer>
          </Details>
        </LayoutContainer>
      )}
    </LayoutContainer>
  );
};

export default Pricing;
