import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { IQuestionSelect3 } from '@quesmed/types-rn/models';

import Choices from './Choices';
import {
  ExpandableSection,
  ExpandableSectionProps,
} from 'components/ExpandableSection';
import { ChoiceExplanation } from 'components/ChoiceExplanation';

const Explanation = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  marginTop: spacing(6),

  [breakpoints.up('md')]: {
    marginTop: spacing(8),
  },
}));

export interface SelectThreeQuestionAnswerProps {
  question: IQuestionSelect3;
  title: string;
  variant?: ExpandableSectionProps['variant'];
  preview?: boolean;
}

export const SelectThreeQuestionAnswer = ({
  question,
  variant,
  title,
  preview,
}: SelectThreeQuestionAnswerProps) => {
  const { choices, select3Answer, explanation } = question;

  return (
    <ExpandableSection
      disableContentBackground
      expanded
      title={title}
      variant={variant}
    >
      <Choices
        choices={choices}
        correctAnswers={select3Answer}
        isAnswered
        preview={preview}
      />
      {explanation ? (
        <Explanation>
          <ChoiceExplanation explanation={explanation} />
        </Explanation>
      ) : null}
    </ExpandableSection>
  );
};
