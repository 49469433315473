import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EQuestionType, EUserLearningPoint } from '@quesmed/types-rn/models';

import { Body } from 'components/Typography';
import { Button } from 'components/Button';
import { BookshelfIcon, LightbulbOn20Icon } from 'components/Icons';
import { constSize } from 'utils';
import { useAddQuestionLearningPoint } from './hooks';
import { Nilable } from 'types';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { Markdown, useMarkdownTextScale } from 'components/Markdown';

const LearningPointsContainer = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    border: `1px solid ${palette.primary.light}`,
    borderRadius: '9px',
    backgroundColor: palette.primary.extraLight,
    padding: spacing(4),
  })
);

const ButtonsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: spacing(2),
  marginTop: spacing(4),
}));

const IconContainer = styled(Box)(({ theme: { spacing } }) => ({
  '& .MuiSvgIcon-root': {
    ...constSize(spacing(4)),
  },
}));

const StyledBody = styled(Body)<{ scale: number }>(
  ({ scale, theme: { typography } }) => ({
    fontSize: `calc(${typography.body1.fontSize} * ${scale})`,
    lineHeight: `calc(${typography.body1.lineHeight} * ${scale})`,
  })
);

interface Props {
  learningPoint?: string;
  marksheetId?: number;
  questionId: number;
  questionType: EQuestionType;
  userPoint: Nilable<EUserLearningPoint>;
  isSample?: boolean;
}

const LearningPoints = ({
  learningPoint,
  marksheetId,
  questionId,
  questionType,
  userPoint,
  isSample,
}: Props) => {
  const { scale } = useMarkdownTextScale();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const { addQuestionLearningPoint, loading } = useAddQuestionLearningPoint(
    questionId,
    questionType,
    marksheetId
  );

  const handleRelevant = () => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      addQuestionLearningPoint(EUserLearningPoint.RELEVANT);
    }
  };

  const handleNotRelevant = () => {
    if (isSample) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      addQuestionLearningPoint(EUserLearningPoint.NOT_RELEVANT);
    }
  };

  return (
    <LearningPointsContainer>
      <StyledBody scale={scale}>
        <Markdown text={learningPoint || ''} />
      </StyledBody>
      <ButtonsContainer>
        <Button
          disabled={loading || userPoint === EUserLearningPoint.NOT_RELEVANT}
          onClick={handleNotRelevant}
          tertiary
        >
          <IconContainer>
            <BookshelfIcon />
          </IconContainer>
          Not Relevant
        </Button>
        <Button
          disabled={loading || userPoint === EUserLearningPoint.RELEVANT}
          onClick={handleRelevant}
        >
          <IconContainer>
            <LightbulbOn20Icon />
          </IconContainer>
          Relevant
        </Button>
      </ButtonsContainer>
    </LearningPointsContainer>
  );
};

export default LearningPoints;
