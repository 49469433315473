import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ChapterContent = styled(Box)(
  ({ theme: { spacing, transitions, mixins, breakpoints } }) => ({
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    padding: spacing(4),
    transition: transitions.create(['padding']),
    maxWidth: `calc(${mixins.content.maxWidth}px + 80px )`,

    [breakpoints.up('md')]: {
      padding: spacing(8, 10),
    },
  })
);
export const ChapterContainer = styled(Box)(
  ({ theme: { mixins, breakpoints } }) => ({
    overflowY: 'auto',
    width: '100%',
    maxHeight: `calc(100dvh - ${mixins.appBar.mobile.minHeight}px)`,

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${mixins.appBar.desktop.minHeight}px)`,
    },
  })
);
