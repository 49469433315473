import { QuestionAnswerStatus } from 'types';

const setAnswerState = (
  showAnswers: boolean,
  isAnswered: boolean,
  isChoiceCorrect: boolean,
  checked: boolean
): QuestionAnswerStatus => {
  if (!showAnswers) {
    return checked ? QuestionAnswerStatus.SELECTED : QuestionAnswerStatus.CLEAN;
  }

  if (!isAnswered) {
    return isChoiceCorrect
      ? QuestionAnswerStatus.NOT_ANSWERED
      : QuestionAnswerStatus.CLEAN;
  }

  if (isChoiceCorrect) {
    return QuestionAnswerStatus.VALID;
  }

  return checked ? QuestionAnswerStatus.INVALID : QuestionAnswerStatus.CLEAN;
};

export default setAnswerState;
