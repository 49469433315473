import React, { ChangeEvent } from 'react';

import {
  ExpandableSection,
  ExpandableSectionProps,
} from 'components/ExpandableSection';
import MatchingQuestionChoices from './MatchingQuestionChoices';
import { RadioGroupProps } from 'components/RadioGroup';

export interface MatchingQuestionCaseProps
  extends Pick<ExpandableSectionProps, 'variant' | 'icon' | 'title'> {
  caseId: number;
  caseContent: string;
  questionId: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  options: RadioGroupProps['options'];
  explanation?: string;
  selectedValue?: string;
}

const MatchingQuestionCase = ({
  caseId,
  caseContent,
  onChange,
  questionId,
  options,
  icon,
  variant = 'info',
  explanation,
  selectedValue,
  title,
}: MatchingQuestionCaseProps): JSX.Element => (
  <ExpandableSection
    disableContentBackground
    expandable
    expanded
    icon={icon}
    key={caseId}
    size="medium"
    title={title}
    variant={variant}
  >
    <MatchingQuestionChoices
      caseContent={caseContent}
      explanation={explanation}
      onChange={onChange}
      options={options}
      radioId={`Question ${questionId}, case: ${caseId})`}
      readOnly={Boolean(!onChange)}
      selectedValue={selectedValue}
    />
  </ExpandableSection>
);

export default MatchingQuestionCase;
