import React from 'react';
import { mdiContentSavePlusOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ContentSaveOutlineIcon = (props: IconProps) => (
  <Icon path={mdiContentSavePlusOutline} {...props} />
);

export default ContentSaveOutlineIcon;
