import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ExerciseProgressStatus, Nullable } from 'types';

const Container = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),

  '& span::first-letter': {
    display: 'inline-block',
    textTransform: 'uppercase',
  },

  '& .MuiSvgIcon-root, &.not-answered .MuiSvgIcon-root': {
    color: palette.icon.main,
  },

  '&.incorrect .MuiSvgIcon-root': {
    color: palette.error.main,
  },

  '&.correct .MuiSvgIcon-root': {
    color: palette.success.dark,
  },

  '&.moderately .MuiSvgIcon-root': {
    color: palette.warning.dark,
  },

  '&.default .MuiSvgIcon-root': {
    color: palette.primary.main,
  },
}));

const Status = styled(Box)({
  display: 'inline-block',

  '&::first-letter': {
    textTransform: 'uppercase',
  },
});

export interface ExerciseSummaryResultProps {
  icon: Nullable<JSX.Element>;
  status: ExerciseProgressStatus;
  label?: string;
}

const ExerciseSummaryResult = ({
  icon,
  status,
  label = '',
}: ExerciseSummaryResultProps): JSX.Element => (
  <Container className={status}>
    {icon} {label ? <Status>{label}</Status> : null}
  </Container>
);

export default ExerciseSummaryResult;
