import { TableData } from 'types';
import { SummaryColumn, SummaryTableKey } from './types';

const CHECKBOX_COLUMN_SIZE = '80px';
const NUMBER_COLUMN_SIZE = '122px';
const CONTENT_COLUMN_SIZE = '2fr';
const LEARNING_POINT_COLUMN_SIZE = '3fr';
const DEFAULT_COLUMN_SIZE = '1fr';
const CHEVRON_COLUMN_SIZE = '24px';

const customColumns = [
  SummaryTableKey.Checkbox,
  SummaryTableKey.Number,
  SummaryTableKey.Content,
  SummaryTableKey.Chevron,
  SummaryTableKey.LearningPoint,
];

export function setSummaryColumnTemplate<T extends TableData>(
  columns: SummaryColumn<T>[]
): string {
  const columnTypes = new Set(columns.map(({ key }) => key));
  const defaultColumnsCount = columns.filter(
    ({ key }) => !customColumns.includes(key)
  ).length;

  const checkboxColumn = columnTypes.has(SummaryTableKey.Checkbox)
    ? CHECKBOX_COLUMN_SIZE
    : '';
  const numberColumn = columnTypes.has(SummaryTableKey.Number)
    ? NUMBER_COLUMN_SIZE
    : '';
  const contentColumn = columnTypes.has(SummaryTableKey.Content)
    ? CONTENT_COLUMN_SIZE
    : '';
  const learningPointColumn = columnTypes.has(SummaryTableKey.LearningPoint)
    ? LEARNING_POINT_COLUMN_SIZE
    : '';
  const defaultColumns = defaultColumnsCount
    ? `repeat(${defaultColumnsCount}, ${DEFAULT_COLUMN_SIZE})`
    : '';

  const chevronColumn = columnTypes.has(SummaryTableKey.Chevron)
    ? CHEVRON_COLUMN_SIZE
    : '';

  const gridTemplateColumns = `${checkboxColumn} ${numberColumn} ${contentColumn} ${learningPointColumn} ${defaultColumns} ${chevronColumn}`;

  return gridTemplateColumns;
}

export function setNavigatorColumnTemplate<T extends TableData>(
  columns: SummaryColumn<T>[]
): string {
  const columnTypes = new Set(columns.map(({ key }) => key));
  const defaultColumnsCount = columns.filter(
    ({ key }) => !customColumns.includes(key)
  ).length;

  const numberColumn = columnTypes.has(SummaryTableKey.Number)
    ? DEFAULT_COLUMN_SIZE
    : '';
  const contentColumn = columnTypes.has(SummaryTableKey.Content)
    ? CONTENT_COLUMN_SIZE
    : '';
  const defaultColumns = defaultColumnsCount
    ? `repeat(${defaultColumnsCount}, ${DEFAULT_COLUMN_SIZE})`
    : '';

  const chevronColumn = columnTypes.has(SummaryTableKey.Chevron)
    ? CHEVRON_COLUMN_SIZE
    : '';

  const gridTemplateColumns = `${numberColumn} ${contentColumn} ${defaultColumns} ${chevronColumn}`;

  return gridTemplateColumns;
}
