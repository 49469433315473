import React from 'react';
import { mdiAccountGroup } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const AccountGroupIcon = (props: IconProps) => (
  <Icon path={mdiAccountGroup} {...props} />
);

export default AccountGroupIcon;
