import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  FLASHCARDS_TOPICS,
  ITopicsData,
  ITopicsVar,
  QUESTION_TOPICS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { EProductType, ETopicType } from '@quesmed/types-rn/models';

import { compareName } from 'utils';
import {
  ExtendedTopic,
  GameType,
  Nullable,
  ToggleOptions,
  TopicMap,
  WithQuestionCards,
} from 'types';
import { transformTopics } from 'utils/marksheet/transformTopics';

const filterEmptyQuestionsOrCards =
  (gameType: GameType) =>
  ({ totalCards, totalQuestions }: WithQuestionCards) =>
    gameType === GameType.QUESTIONS
      ? Boolean(totalQuestions && totalQuestions > 0)
      : Boolean(totalCards && totalCards > 0);

interface UseTopicQueryParams {
  filter?: ETopicType;
  withMap?: boolean;
  gameType: GameType;
}

interface Topics {
  categories: ToggleOptions<EProductType>;
  error?: ApolloError;
  loading: boolean;
  topics: Nullable<ExtendedTopic[]>;
  topicMap: TopicMap;
}

const useTopicQuery = (params?: UseTopicQueryParams): Topics => {
  const { filter = ETopicType.ALL, gameType = GameType.QUESTIONS } =
    params || {};
  const QUERY =
    gameType === GameType.QUESTIONS ? QUESTION_TOPICS : FLASHCARDS_TOPICS;

  const { loading, error, data } = useQuery<ITopicsData, ITopicsVar>(QUERY, {
    variables: { filter },
    fetchPolicy: 'cache-and-network',
  });

  const { topics: sourceTopics } = data?.restricted || {};

  const topics = useMemo(() => {
    if (!sourceTopics) {
      return null;
    }

    return sourceTopics
      .filter(filterEmptyQuestionsOrCards(gameType))
      .map(({ name, concepts, ...rest }) => {
        const sorted = concepts
          ? [...concepts]
              .filter(filterEmptyQuestionsOrCards(gameType))
              .sort(compareName)
          : undefined;

        return {
          ...rest,
          name,
          concepts: sorted,
          searchNames: `${name}, ${
            sorted?.map(({ name }) => name).join(',') ?? ''
          }`,
        };
      });
  }, [gameType, sourceTopics]);

  const [topicMap, categories] = useMemo(
    () => transformTopics(topics),
    [topics]
  );

  return { categories, error, loading, topics, topicMap };
};

export default useTopicQuery;
