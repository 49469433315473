import { useCallback, useEffect, useState } from 'react';

import {
  ButtonOnClickHandler,
  CheckboxState,
  InputOnChangeHandler,
  SelectionState,
  SharedSelection,
  TopicSelection,
  TopicSelectionParams,
  TopicSelectionState,
} from 'types';
import { useShareTopicSelection } from 'pages/Questions/hooks';
import useStateCb from './useStateCb';
import { usePresetGlobalState } from 'components/PresetsBuilder/state/PresetGlobalState';
import { getCheckboxState } from 'utils/selectionstate/index';
import { parseJSON } from 'utils';

// This method checks the state of the global "Select All" checkbox
// as it may change whenever the concept checkbox changes. Since
// the number of concepts can be large the foreach was used instead from reduce.
const partition = (array: TopicSelectionState[]) => {
  const result = [[], [], []] as TopicSelectionState[][];

  array.forEach(element => {
    const { topicState } = element;
    if (topicState === CheckboxState.CHECKED) {
      result[0].push(element);
    } else if (topicState === CheckboxState.UNCHECKED) {
      result[1].push(element);
    } else {
      result[2].push(element);
    }
  });

  return result;
};

const useTopicSelection = ({
  topics,
  displayTopics,
  marksheetId,
  sharedSelection,
}: TopicSelectionParams): TopicSelection => {
  const [selectionState, setSelectionState] = useStateCb<SelectionState>(
    new Map<number, TopicSelectionState>()
  );
  const [allSelected, setAllSelected] = useState<CheckboxState>(
    CheckboxState.UNCHECKED
  );
  const shareSelection = useShareTopicSelection(marksheetId);
  const { resetPreset, isPresetEditOpen } = usePresetGlobalState();

  useEffect(() => {
    if (topics) {
      const stateMap = new Map<number, TopicSelectionState>();

      const data = sharedSelection
        ? parseJSON<SharedSelection>(sharedSelection)
        : undefined;

      topics.forEach(({ id, concepts, entitlement }) => {
        const { conceptsSelected = [] } = data?.[id] || {};

        if (entitlement.id !== null) {
          stateMap.set(Number(id), {
            id: Number(id),
            type: entitlement.id,
            topicState: getCheckboxState(
              conceptsSelected.length,
              concepts?.length || 0
            ),
            selectedConceptsIds: new Set<number>(
              conceptsSelected.map(id => Number(id))
            ),
            conceptIds: new Set(
              concepts ? concepts.map(({ id }) => Number(id)) : []
            ),
            conceptsAvailable: concepts?.length || 0,
          });
        }
      });

      setSelectionState(stateMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedSelection, topics]);

  useEffect(() => {
    const items = [...selectionState.values()];
    const [checked, unchecked] = partition(items);

    if (unchecked.length === items.length) {
      setAllSelected(CheckboxState.UNCHECKED);

      return;
    }

    if (checked.length === items.length) {
      setAllSelected(CheckboxState.CHECKED);

      return;
    }

    shareSelection(selectionState);
    if (!isPresetEditOpen) {
      resetPreset();
    }

    setAllSelected(CheckboxState.INTERMEDIATE);
  }, [isPresetEditOpen, resetPreset, selectionState, shareSelection]);

  const selectAll = useCallback(
    (selectionState: SelectionState) => {
      const isAnyTopicSelected = [...selectionState.values()].some(
        ({ topicState }) => topicState !== CheckboxState.UNCHECKED
      );

      const displayTopicIds = new Set(
        displayTopics?.map(displayTopic => Number(displayTopic.id))
      );
      let topicState = CheckboxState.UNCHECKED;

      if (!isAnyTopicSelected) {
        topicState =
          displayTopicIds.size === selectionState.size
            ? CheckboxState.CHECKED
            : CheckboxState.INTERMEDIATE;
      }

      const stateMap = new Map<number, TopicSelectionState>();

      selectionState.forEach((value, key) => {
        const { concepts } =
          displayTopics?.find(topic => Number(topic.id) === Number(key)) || {};

        const conceptIds = new Set(concepts?.map(({ id }) => Number(id)));

        stateMap.set(key, {
          ...value,
          topicState: concepts ? topicState : value.topicState,
          selectedConceptsIds: new Set<number>(
            isAnyTopicSelected ? [] : [...conceptIds]
          ),
        });
      });

      setSelectionState(stateMap, shareSelection);
    },
    [displayTopics, setSelectionState, shareSelection]
  );

  const handleSelectAll = useCallback(
    (selectionState: SelectionState): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        selectAll(selectionState);
      },
    [selectAll]
  );

  const deselectAll = useCallback(
    (selectionState: SelectionState) => {
      const stateMap = new Map<number, TopicSelectionState>();

      selectionState.forEach((value, key) => {
        stateMap.set(key, {
          ...value,
          topicState: CheckboxState.UNCHECKED,
          selectedConceptsIds: new Set<number>(),
        });
      });

      setSelectionState(stateMap, shareSelection);

      return stateMap;
    },
    [setSelectionState, shareSelection]
  );

  const handleDeselectAll = useCallback(
    (selectionState: SelectionState): ButtonOnClickHandler =>
      event => {
        event.stopPropagation();

        deselectAll(selectionState);
      },
    [deselectAll]
  );

  const changeTopicState = useCallback(
    (selectionState: SelectionState, topicId: number) => {
      const currentState = selectionState.get(topicId);

      const displayConcepts =
        displayTopics?.find(topic => Number(topic.id) === Number(topicId))
          ?.concepts || [];
      const displayConceptIds = new Set(
        displayConcepts?.map(displayConcept => Number(displayConcept.id))
      );

      if (currentState) {
        const { conceptIds, topicState: currentTopicState } = currentState;

        let topicState = CheckboxState.UNCHECKED;
        if (currentTopicState === CheckboxState.UNCHECKED) {
          topicState =
            conceptIds.size === displayConceptIds.size
              ? CheckboxState.CHECKED
              : CheckboxState.INTERMEDIATE;
        }

        setSelectionState(
          prev =>
            new Map(
              prev.set(topicId, {
                ...currentState,
                topicState,
                selectedConceptsIds: new Set<number>(
                  currentTopicState === CheckboxState.UNCHECKED
                    ? [...displayConceptIds]
                    : []
                ),
              })
            ),
          shareSelection
        );
      }
    },
    [displayTopics, setSelectionState, shareSelection]
  );

  const handleSelectTopic = useCallback(
    (selectionState: SelectionState) =>
      (topicId: number): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        changeTopicState(selectionState, topicId);
      },
    [changeTopicState]
  );

  const changeConceptState = useCallback(
    (selectionState: SelectionState, topicId: number, conceptId: number) => {
      const currentState = selectionState.get(topicId);

      if (currentState) {
        const { selectedConceptsIds, conceptIds } = currentState;

        const temp = new Set(selectedConceptsIds);

        if (temp.has(conceptId)) {
          temp.delete(conceptId);
        } else {
          temp.add(conceptId);
        }

        setSelectionState(
          prev =>
            new Map(
              prev.set(topicId, {
                ...currentState,
                topicState: getCheckboxState(temp.size, conceptIds.size),
                selectedConceptsIds: temp,
              })
            ),
          shareSelection
        );
      }
    },
    [setSelectionState, shareSelection]
  );

  const handleSelectConcept = useCallback(
    (selectionState: SelectionState) =>
      (topicId: number) =>
      (conceptId: number): InputOnChangeHandler =>
      event => {
        event.stopPropagation();
        changeConceptState(selectionState, topicId, conceptId);
      },
    [changeConceptState]
  );

  return {
    allSelected,
    selectionState,
    handleSelectAll,
    handleDeselectAll,
    handleSelectTopic,
    handleSelectConcept,
    selectAll,
    deselectAll,
    changeTopicState,
    changeConceptState,
    setSelectionState,
  };
};

export default useTopicSelection;
