import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IMarksheet } from '@quesmed/types-rn/models';
import { useNavigate } from 'react-router-dom';

import {
  ArrowULeftButtomIcon,
  CachedIcon,
  CheckCircleOutlineIcon,
  PauseCircleIcon,
  PreviewIcon,
  TimerAlertOutlineIcon,
} from 'components/Icons';
import { useHover, usePrevious } from 'hooks';
import {
  ProgressLineChart,
  ProgressLineChartProps,
} from 'components/ProgressLineChart';
import { constSize, formatDate } from 'utils';
import { ItemStatusLabel, Nullable, Undefinable } from 'types';
import { paths } from 'Router';
import useRebuildMarksheet from '../hooks/useRebuildMarksheet';
import { ActionPopper } from 'components/ActionPopper';
import { answersFormatter } from 'utils/string/answersFormatter';
import { DateCell, PresetCell } from 'components/TableCells';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { TableRowComponent } from './types';

const CorrectAnswersContainer = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),

    '& .MuiSvgIcon-root': {
      ...constSize('20px'),
    },

    '& .error': {
      color: palette.error.main,
    },

    '& .success': {
      color: palette.success.dark,
    },

    '& .icon': {
      color: palette.icon.main,
    },
  })
);

const progressFormatter = (
  { correct, incorrect, totalQuestions }: IMarksheet,
  numberOfTopics: Nullable<string>,
  firstTopic: Undefinable<string>
): ProgressLineChartProps => ({
  title: `${firstTopic} ${numberOfTopics}`,
  data: [
    {
      type: ItemStatusLabel.Confident,
      value: correct,
      label: ItemStatusLabel.Correct,
    },
    {
      type: ItemStatusLabel.ToLearn,
      value: incorrect,
      label: ItemStatusLabel.Incorrect,
    },
  ],
  total: totalQuestions,
});

const QuestionsHistoryTableRow: TableRowComponent<IMarksheet> = ({
  result,
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [popperRef, popperRefValue] = useHover<HTMLButtonElement>();
  const { rebuildMarksheet } = useRebuildMarksheet();
  const popperRefPrevValue = usePrevious(popperRefValue);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar({ unique: true });

  const {
    correct,
    updatedAt,
    id,
    completed,
    entitlement,
    totalQuestions,
    topicNames,
    isTestMarksheet,
  } = result;
  const isDemo = useDemo();

  const showDemoMessage = () => {
    enqueueSnackbar(locales.common.demo.feature);
  };

  const handleOpenMenu = () => {
    setIsPopperOpen(true);
  };

  const handleCloseMenu = () => {
    setIsPopperOpen(false);
  };

  useEffect(() => {
    if (isPopperOpen && popperRefPrevValue && !popperRefValue) {
      handleCloseMenu();
    }
  }, [isPopperOpen, popperRefPrevValue, popperRefValue]);

  const handleStartExercise = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      rebuildMarksheet(Number(id));
    }
  };

  const handleReview = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      navigate(`${paths.questions.root}/review/${id}`);
    }
  };

  const handleContinue = () => {
    if (isDemo) {
      showDemoMessage();
    } else {
      navigate(`/questions/solo/quiz/${id}`);
    }
  };

  const numberOfTopics =
    topicNames && topicNames?.length > 1 ? `+ ${topicNames?.length - 1}` : null;

  const checkStatus = () => {
    return completed ? <PreviewIcon /> : <ArrowULeftButtomIcon />;
  };

  const actionPopperItems = [
    {
      icon: checkStatus(),
      label: completed ? 'Review' : 'Continue',
      onClick: completed ? handleReview : handleContinue,
    },
    { icon: <CachedIcon />, label: 'Restart', onClick: handleStartExercise },
  ];

  const getExerciseStateIcon = () => {
    if (completed) {
      return <CheckCircleOutlineIcon className="success" />;
    }
    if (isTestMarksheet) {
      return <TimerAlertOutlineIcon className="error" />;
    }

    return <PauseCircleIcon className="icon" />;
  };

  return (
    <TableRow>
      <DateCell>{formatDate(updatedAt)}</DateCell>
      <PresetCell>
        <Box>
          [{entitlement?.name || ''}] {topicNames ? topicNames[0] : null}{' '}
          {numberOfTopics}
        </Box>
        <CorrectAnswersContainer>
          {getExerciseStateIcon()} Correct answers:&nbsp;
          {answersFormatter(totalQuestions, correct)}
        </CorrectAnswersContainer>
      </PresetCell>
      <TableCell>
        <ProgressLineChart
          {...progressFormatter(
            result,
            numberOfTopics ? numberOfTopics : '',
            topicNames ? topicNames[0] : ''
          )}
        />
      </TableCell>
      {/* TODO: Replace this value with real one */}
      <TableCell>Custom set</TableCell>
      <TableCell onClick={handleOpenMenu} ref={popperRef}>
        <ActionPopper
          currentRef={popperRef.current}
          isPopperOpen={isPopperOpen}
          items={actionPopperItems}
        />
      </TableCell>
    </TableRow>
  );
};

export default QuestionsHistoryTableRow;
