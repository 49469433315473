import React, { Dispatch, SetStateAction } from 'react';
import {
  EQuestionType,
  IMarksheetMark,
  IQuestionMultiQ,
  IQuestionPrescribe,
  IQuestionQA,
  IQuestionSBA,
} from '@quesmed/types-rn/models';

import MultiAnswer from './MultiAnswer/MultiAnswer';
import OpenAnswer from './OpenAnswer/OpenAnswer';
import PrescriptionAnswer from './PrescriptionAnswer/PrescriptionAnswer';
import SingleBestAnswer from './SingleBestAnswer/SingleBestAnswer';

interface MockTestQuestionAnswerControllerProps {
  currentMark: IMarksheetMark;
  completed: boolean;
  questionNumber: number;
  selectedChoiceId: number | undefined;
  setSelectedId: (id?: number) => void;
  setUserAnswer: Dispatch<SetStateAction<string>>;
  reviewMode: boolean;
}

export const MockTestQuestionAnswerController = ({
  currentMark,
  questionNumber,
  completed,
  selectedChoiceId,
  setSelectedId,
  setUserAnswer,
}: MockTestQuestionAnswerControllerProps): JSX.Element => {
  const { mark, question, questionChoiceId } = currentMark;
  const { typeId } = question;

  return (
    <>
      {typeId === EQuestionType.SINGLE_BEST_ANSWER && (
        <SingleBestAnswer
          isAnswered={currentMark.isAnswered}
          isTestFinished={completed}
          question={question as IQuestionSBA}
          questionChoiceId={questionChoiceId}
          questionNumber={questionNumber}
          selectedChoiceId={selectedChoiceId}
          setSelectedId={setSelectedId}
          setUserAnswer={setUserAnswer}
        />
      )}
      {typeId === EQuestionType.PRESCRIPTION_ANSWER && (
        <PrescriptionAnswer
          currentMark={currentMark}
          isTestFinished={completed}
          mark={mark as string}
          question={question as IQuestionPrescribe}
          setUserAnswer={setUserAnswer}
        />
      )}
      {typeId === EQuestionType.MULTIPLE_ANSWERS && (
        <MultiAnswer
          isTestFinished={completed}
          question={question as IQuestionMultiQ}
          setUserAnswer={setUserAnswer}
          userAnswer={mark as string}
        />
      )}
      {typeId === EQuestionType.QUESTION_ANSWER && (
        <OpenAnswer
          isTestFinished={completed}
          question={question as IQuestionQA}
          setUserAnswer={setUserAnswer}
          userAnswer={mark as string}
        />
      )}
    </>
  );
};
