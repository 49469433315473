import React from 'react';
import { Skeleton } from '@mui/material';
import { IUser } from '@quesmed/types-rn/models';

import {
  DashboardHeader,
  DashboardWrapper,
  WidgetsContainer,
} from 'pages/dashboard/dashboard.styles';
import { H4 } from 'components/Typography';
import { ExamCountdown } from 'pages/dashboard/components/ExamCountdown';
import { Performance } from 'pages/dashboard/components/Performance';
import { parseDate } from 'utils';
import { Nullable } from 'types';
import { useOsceDashboardQuery } from 'pages/dashboard/hooks';
import OsceActivity from './components/Activity/OsceActivity';
import { ContinueStudyLink } from 'components/JoinLink';
import { useStationsPerformance } from './components/Performance/useStationsPerformance';

const parseExamDate = (date?: Nullable<number | Date>) =>
  date ? new Date(parseDate(date)) : null;

interface OsceDashboardProps {
  user?: IUser;
  userLoading: boolean;
}

const OsceDashboard = ({
  user,
  userLoading,
}: OsceDashboardProps): JSX.Element => {
  const { data } = useOsceDashboardQuery();

  const { data: performanceData, loading: performanceLoading } =
    useStationsPerformance();
  const { lastSessionId } = data || {};

  return (
    <DashboardWrapper>
      <DashboardHeader>
        <H4>Dashboard</H4>

        {userLoading ? (
          <Skeleton height={48} width={300} />
        ) : (
          <ExamCountdown
            examDate={parseExamDate(user?.settings.productSettings.examDate)}
          />
        )}
      </DashboardHeader>
      {lastSessionId ? <ContinueStudyLink sessionId={lastSessionId} /> : null}
      <WidgetsContainer>
        <Performance
          data={performanceData}
          loading={performanceLoading}
          withTopics
        />
      </WidgetsContainer>

      <OsceActivity />
    </DashboardWrapper>
  );
};

export default OsceDashboard;
