import { styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const PresetCell = styled(TableCell)(
  ({ theme: { typography, palette, breakpoints } }) => ({
    ...typography.body2Medium,

    width: '260px',
    [breakpoints.up('md')]: {
      width: '320px',
    },
    [breakpoints.up('xl')]: {
      width: '460px',
    },

    '.MuiBox-root:nth-of-type(2)': {
      color: palette.text.secondary,
      ...typography.body2,
    },
  })
);

export const DateCell = styled(TableCell)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: '100px',
    padding: spacing(4, 4, 4, 8),
    [breakpoints.up('md')]: {
      width: '150px',
    },
  })
);
