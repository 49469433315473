import React from 'react';
import { mdiCached } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const Cached = (props: IconProps): JSX.Element => (
  <Icon path={mdiCached} {...props} />
);

export default Cached;
