import React, { ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ToggleButtonGroup } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';

import { ToggleEvent, ToggleOptions } from 'types';
import { Body, H5 } from 'components/Typography';
import { ToggleButton } from 'components/ToggleButton';
import { FilterField } from 'components/FilterField';
import { SearchIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import { useIsMobile } from 'hooks';

const Container = styled(Box)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    backgroundColor: palette.background.paper,
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: spacing(4),
    borderRight: `1px solid ${palette.stroke.main}`,
    borderLeft: `1px solid ${palette.stroke.main}`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: spacing(0, -6),
    width: `calc(100% + ${spacing(12)})`,

    '& > .MuiBox-root .MuiIconButton-root:nth-last-of-type(1), > .MuiBox-root:nth-last-of-type(1)':
      {
        marginLeft: 'auto',
      },

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
      flexWrap: 'nowrap',
      gap: spacing(4),

      '& > .MuiBox-root > .MuiIconButton-root:nth-last-of-type(1)': {
        display: 'none',
      },
    },

    [breakpoints.up('xl')]: {
      height: '112px',
      flexWrap: 'nowrap',
      padding: spacing(6, 8, 8),
    },

    '& .MuiToggleButtonGroup-root': {
      gap: spacing(4),

      '& .MuiButtonBase-root': {
        width: '40px',

        ' .MuiBox-root:nth-of-type(2)': {
          display: 'none',
        },

        [breakpoints.up('xl')]: {
          width: 'auto',

          '.MuiBox-root:nth-of-type(2)': {
            display: 'inline',
          },
        },
      },
    },

    '&.no-categories': {
      borderTop: `1px solid ${palette.stroke.main}`,
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
    },

    '&.no-side-borders': {
      borderRight: 'none',
      borderLeft: 'none',
    },
  })
);

const SearchBox = styled(Box)(({ theme: { breakpoints } }) => ({
  width: '100%',
  flexGrow: 1,

  [breakpoints.up('md')]: {
    width: 'auto',
    minWidth: '256px',
    maxWidth: '366px',
  },
}));

const InputBox = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  paddingTop: spacing(4),

  [breakpoints.up('md')]: {
    paddingTop: 'initial',
  },
}));

const HeadingContent = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  flexGrow: 1,
  gap: spacing(4),
}));

const StyledH5 = styled(H5)({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
});

export type SelectionHeadingProps<T> = {
  onToggleView?: (event: ToggleEvent, value: T) => void;
  onSearch?: (text: string) => void;
  resetSearch?: boolean;
  searchLabel?: string;
  title: ReactNode;
  subtitle?: ReactNode;
  selectedView?: T;
  viewOptions?: ToggleOptions<T>;
  loading: boolean;
  noCategories?: boolean;
  noSideBorders?: boolean;
  endAdornment?: ReactNode;
  showViewToggle?: boolean;
  searchTerm?: string;
};

function SelectionHeading<T>({
  onSearch,
  onToggleView,
  resetSearch,
  loading,
  title,
  subtitle,
  searchLabel = 'Search',
  selectedView,
  viewOptions,
  noCategories,
  noSideBorders,
  endAdornment,
  searchTerm,
  showViewToggle = true,
}: SelectionHeadingProps<T>): JSX.Element {
  const [searchVisible, setSeachVisible] = useState(false);
  const { isMobile } = useIsMobile();

  const toggleSearchVisivility = () => setSeachVisible(prev => !prev);

  return (
    <Container
      className={clsx({
        'no-categories': noCategories,
        'no-side-borders': noSideBorders,
      })}
    >
      <HeadingContent>
        <Box>
          <StyledH5>{title}</StyledH5>
          {subtitle ? <Body>{subtitle}</Body> : null}
        </Box>
        {showViewToggle && viewOptions && onToggleView ? (
          <ToggleButtonGroup exclusive onChange={onToggleView}>
            {viewOptions.map(({ icon, label, value }) => (
              <ToggleButton
                icon={icon}
                key={label}
                label={label}
                selected={selectedView === value}
                value={value}
              />
            ))}
          </ToggleButtonGroup>
        ) : null}
        {onSearch ? (
          <IconButton
            edge="end"
            icon={<SearchIcon />}
            onClick={toggleSearchVisivility}
          />
        ) : null}
      </HeadingContent>
      {onSearch ? (
        <>
          <SearchBox>
            <Collapse
              collapsedSize={0}
              in={!isMobile || searchVisible}
              unmountOnExit
            >
              <InputBox>
                <FilterField
                  fullWidth
                  loading={loading}
                  onSearch={onSearch}
                  resetSearch={resetSearch}
                  searchLabel={searchLabel}
                  searchTerm={searchTerm}
                />
              </InputBox>
            </Collapse>
          </SearchBox>
        </>
      ) : null}
      {endAdornment ? endAdornment : null}
    </Container>
  );
}

export default SelectionHeading;
