import { ChangeEvent, useEffect, useState } from 'react';

type Data<T> = T[];

const usePagination = <T>(
  itemsPerPage: number,
  total: number,
  data?: Data<T>
) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState<Data<T>>([]);
  const pagesCount = Math.ceil(total / itemsPerPage);

  useEffect(() => {
    if (data) {
      const getPaginatedData = () => {
        const pageStartIndex = currentPage * itemsPerPage;
        const pageEndIndex = pageStartIndex + itemsPerPage;
        const indexedData = data.map((item, index) => ({
          ...item,
          index: index + 1,
        }));

        return indexedData.slice(pageStartIndex, pageEndIndex);
      };
      setPaginatedData(getPaginatedData());
    }
  }, [currentPage, data, itemsPerPage]);

  // type ChangeEvent<unknown> is required by Material UI
  const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
    setOffset(page * itemsPerPage);
    setCurrentPage(page - 1);
  };

  return { paginatedData, currentPage, pagesCount, handlePageChange, offset };
};

export default usePagination;
