import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { EAppType, IProduct } from '@quesmed/types-rn/models';

import MRCPProducts from './MRCPProducts';
import Products from './Products';
import { PricingSubscriptions } from 'hooks/useSubscriptions';

export const Content = styled(Box)(() => ({
  display: 'flex',
}));

export const Details = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: spacing(8),
}));

interface AppsProductsProps {
  appType: EAppType;
  onCheckout: (priceId: string) => void;
  products: IProduct[];
  subscriptions: PricingSubscriptions;
  paymentLoading: boolean;
}

const AppsProducts = ({
  appType,
  onCheckout,
  products: productsData,
  subscriptions,
  paymentLoading,
}: AppsProductsProps): JSX.Element => {
  const ProductsComponent = appType === EAppType.MRCP ? MRCPProducts : Products;

  return (
    <Details>
      <ProductsComponent
        appType={appType}
        onCheckout={onCheckout}
        paymentLoading={paymentLoading}
        products={productsData}
        subscriptions={subscriptions}
      />
    </Details>
  );
};

export default AppsProducts;
