import React, { useRef } from 'react';
import clsx from 'clsx';
import Draggable, { DraggableEvent } from 'react-draggable';
import { Box, Fade, styled } from '@mui/material';

import { IconButton, IconButtonProps } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';
import { CalculatorIcon, DragIcon } from 'components/Icons';
import { UtilCalculator } from 'components/UtilCalculator';
import { constSize } from 'utils';

const Button = styled(IconButton)(({ theme: { spacing, palette } }) => ({
  position: 'absolute',
  top: `-${spacing(3.5)}`,
  right: `-${spacing(3.5)}`,
  backgroundColor: palette.calculator.result,
  ...constSize('32px'),
  cursor: 'grab',
  zIndex: 2000,

  '& .MuiSvgIcon-root': {
    ...constSize('16px'),
  },
}));

const CalculatorContainer = styled(Box)(() => ({
  position: 'relative',
}));

const CalculatorWrapper = styled(Box)(({ theme: { zIndex } }) => ({
  position: 'absolute',
  top: '50px',
  zIndex: zIndex.tooltip,
}));

interface CalculatorButtonProps {
  label?: string;
  size?: IconButtonProps['size'];
  white?: boolean;
}

export const CalculatorButton = ({
  label,
  size,
  white,
}: CalculatorButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleStop = (event: DraggableEvent) => {
    event.stopPropagation();
  };

  const handleB = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <CalculatorContainer>
      <Tooltip placement="bottom" title="Calculator">
        <IconButton
          aria-label="lab values"
          className={clsx({ open })}
          icon={<CalculatorIcon />}
          label={label}
          onClick={handleClick}
          size={size}
          white={white}
        />
      </Tooltip>
      <Fade in={open} unmountOnExit>
        <Box>
          <Draggable
            handle=".calculator-popper"
            nodeRef={ref}
            onStop={handleStop}
          >
            <CalculatorWrapper ref={ref}>
              <UtilCalculator onClose={handleClose} />
              <Button
                className="calculator-popper"
                icon={<DragIcon />}
                onClick={handleB}
              />
            </CalculatorWrapper>
          </Draggable>
        </Box>
      </Fade>
    </CalculatorContainer>
  );
};

export default CalculatorButton;
