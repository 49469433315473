import { LineChartItem } from 'components/ProgressLineChart';
import { ItemStatusLabel, Nilable } from 'types';

export const setFlashcardsProgressData = (
  greenCards: Nilable<number>,
  redCards: Nilable<number>,
  yellowCards: Nilable<number>
): LineChartItem[] => [
  {
    type: ItemStatusLabel.Confident,
    value: greenCards ?? 0,
    label: ItemStatusLabel.Confident,
  },
  {
    type: ItemStatusLabel.ToReview,
    value: yellowCards ?? 0,
    label: ItemStatusLabel.ToReview,
  },
  {
    type: ItemStatusLabel.ToLearn,
    value: redCards ?? 0,
    label: ItemStatusLabel.ToLearn,
  },
];
