import React from 'react';
import {
  IMarksheetMark,
  IMarksheetMarkJSONB,
  IQuestionChoice,
} from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { correctMark } from '@quesmed/types-rn/utils';

import { ExpandableSection } from 'components/ExpandableSection';
import RankingQuestionChoice from './RankingQuestionChoice';
import { parseJSON } from 'utils';
import { RankingAnswer } from 'types';
import { RankingQuestionAnswer } from './RankingQuestionAnswer';

const FeedbackContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

const isChoiceCorrect = (
  choiceIndex: number,
  choiceLabel: string,
  correctAnswers: string[]
) =>
  choiceIndex === correctAnswers.indexOf(choiceLabel) ? 'correct' : 'incorrect';

export interface RankingQuestionFeedbackProps {
  choices: IQuestionChoice[];
  correctAnswer: string[];
  userAnswer: IMarksheetMarkJSONB;
  explanation?: string;
  mark: IMarksheetMark;
}

const RankingQuestionFeedback = ({
  mark,
  choices,
  correctAnswer,
  userAnswer,
  explanation,
}: RankingQuestionFeedbackProps): JSX.Element => {
  const parsedAnswer = parseJSON<RankingAnswer>(userAnswer) || [];

  const { correct } = correctMark(mark);

  const userOrder = [...choices].sort(
    (a, b) => parsedAnswer.indexOf(a.label) - parsedAnswer.indexOf(b.label)
  );

  return (
    <FeedbackContainer>
      {!correct ? (
        <ExpandableSection
          disableContentBackground
          expandable
          expanded
          size="medium"
          title="Incorrect (selected)"
          variant="error"
        >
          {userOrder.map(({ name, id, label }, index) => (
            <RankingQuestionChoice
              index={index + 1}
              isDragged={false}
              key={id}
              name={name}
              status={isChoiceCorrect(index, label, correctAnswer)}
            />
          ))}
        </ExpandableSection>
      ) : null}
      <RankingQuestionAnswer
        choices={choices}
        correctAnswer={correctAnswer}
        explanation={explanation}
        title="Correct"
        variant="success"
      />
    </FeedbackContainer>
  );
};

export default RankingQuestionFeedback;
