import { useQuery } from '@apollo/client';
import {
  ITodoData,
  ITodoVar,
  TODO,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useFlashcardsQuery = (todoId?: number) => {
  const { data, loading } = useQuery<ITodoData, ITodoVar>(TODO, {
    variables: { id: Number(todoId) },
    skip: !todoId,
  });

  const { todo: flashcards } = data?.restricted || {};

  return {
    flashcards,
    loading,
  };
};

export default useFlashcardsQuery;
