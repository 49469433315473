import { useCallback } from 'react';
import {
  ApolloQueryResult,
  QueryResult,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { ESortOrder } from '@quesmed/types-rn';
import { IPreset } from '@quesmed/types-rn/models';
import {
  IPresetsData,
  IPresetsVar,
  PRESETS,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { uniqueArrayObjects } from 'utils';

interface PresetParams {
  globalSearch?: string;
  globalTimeStamp?: number;
  limit?: number;
  onAllPresetsFetched?: () => void;
}

interface PresetsQueryResult {
  searchedPresets?: IPreset[];
  presets: IPreset[];
  searchLoading: boolean;
  loading: boolean;
  searchPresets: (
    search?: string
  ) => Promise<QueryResult<IPresetsData, IPresetsVar>>;
  getMorePresets: (
    timestamp: number
  ) => Promise<ApolloQueryResult<IPresetsData>>;
}

const DEFAULT_INFINITE_SCROLL_LIMIT = 10;

const usePresetsQuery = (params: PresetParams): PresetsQueryResult => {
  const {
    globalSearch,
    globalTimeStamp,
    limit = 100,
    onAllPresetsFetched,
  } = params;

  const { data, loading, fetchMore } = useQuery<IPresetsData, IPresetsVar>(
    PRESETS,
    {
      variables: {
        search: globalSearch,
        timestamp: globalTimeStamp,
        order: ESortOrder.DESC,
      },
    }
  );

  const getMorePresets = useCallback(
    (timestamp: number) => {
      return fetchMore({
        variables: {
          timestamp,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          const { presets } = previousResult.restricted;
          const { presets: morePresets } = fetchMoreResult.restricted;

          if (morePresets.length < DEFAULT_INFINITE_SCROLL_LIMIT) {
            onAllPresetsFetched?.();
          }

          const mergedData = {
            ...fetchMoreResult,
            restricted: {
              ...fetchMoreResult.restricted,
              presets:
                uniqueArrayObjects([...presets, ...morePresets], 'id') || [],
            },
          };

          return mergedData;
        },
      });
    },
    [fetchMore, onAllPresetsFetched]
  );

  const [query, { data: searchData, loading: searchLoading }] = useLazyQuery<
    IPresetsData,
    IPresetsVar
  >(PRESETS, {
    variables: {
      search: globalSearch,
      timestamp: globalTimeStamp,
      order: ESortOrder.DESC,
    },
    // TODO: Improve this with caching. Right now it's taking topics from cache
    // which we don't need
    // fetchPolicy: 'no-cache',
    fetchPolicy: 'cache-and-network',
  });

  const searchPresets = useCallback(
    (search?: string) =>
      query({
        variables: {
          search,
          limit,
        },
      }),
    [query, limit]
  );

  const { presets = [] } = data?.restricted || {};
  const { presets: searchedPresets } = searchData?.restricted || {};

  return {
    searchedPresets,
    loading,
    searchPresets,
    searchLoading,
    getMorePresets,
    presets,
  };
};

export default usePresetsQuery;
