import { useQuery } from '@apollo/client';
import {
  IUserData,
  USER_COMPLETED_DATA,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';

const useCheckDailyFeed = (id: number, skip: boolean) => {
  const { data, loading: checkingLoading } = useQuery<IUserData>(
    USER_COMPLETED_DATA,
    {
      skip,
    }
  );

  const { completedCards = [] } = data?.restricted.user || {};

  const isInDailyFeedAlready = useMemo(
    () =>
      completedCards.some(
        ({ concept }) => !isNil(concept) && Number(concept.id) === id
      ),
    [completedCards, id]
  );

  return { isInDailyFeedAlready, checkingLoading };
};

export default useCheckDailyFeed;
