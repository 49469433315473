import { useMutation } from '@apollo/client';
import {
  IRemoveFromDailyStackData,
  IRemoveFromDailyStackVar,
  REMOVE_FROM_DAILY_STACK,
  updateCacheOnRemoveFromDailyStack,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback } from 'react';

const useRemoveFromDailyStack = () => {
  const [remove, { loading }] = useMutation<
    IRemoveFromDailyStackData,
    IRemoveFromDailyStackVar
  >(REMOVE_FROM_DAILY_STACK);

  const removeFromDailyStack = useCallback(
    (cardId: number, todoId: number) =>
      remove({
        variables: {
          cardId,
          todoId,
        },
        update: updateCacheOnRemoveFromDailyStack,
      }),
    [remove]
  );

  return { removeFromDailyStack, loading };
};

export default useRemoveFromDailyStack;
