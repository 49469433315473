import React from 'react';

import {
  CheckboxMarkedCircleOutlineIcon,
  CloseCircleOutlineIcon,
  QuestionMarkCircleIcon,
  RadioBoxBlankIcon,
} from 'components/Icons';
import { ExerciseProgressStatus } from 'types';

interface QuestionValidationMarkProps {
  status: ExerciseProgressStatus;
  showAsNotAnswered?: boolean;
}

const QuestionValidationMark = ({
  status,
  showAsNotAnswered = false,
}: QuestionValidationMarkProps) => {
  switch (status) {
    case ExerciseProgressStatus.NotAnswered:
      return showAsNotAnswered ? <RadioBoxBlankIcon /> : null;
    case ExerciseProgressStatus.Answered:
      return <QuestionMarkCircleIcon />;
    case ExerciseProgressStatus.Correct:
      return <CheckboxMarkedCircleOutlineIcon />;
    case ExerciseProgressStatus.Incorrect:
      return <CloseCircleOutlineIcon />;
    case ExerciseProgressStatus.TestMode:
      return <QuestionMarkCircleIcon />;
    default:
      return null;
  }
};

export default QuestionValidationMark;
