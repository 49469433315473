import { ICondition, IPresentation } from '@quesmed/types-rn/models';

export const calcMLAQuestions = (
  selectedConditions: Set<number>,
  selectedPresentations: Set<number>,
  conditions?: ICondition[],
  presentations?: IPresentation[]
): [number, number] => {
  if (!conditions && !presentations) {
    return [0, 0];
  }
  const sumSet = new Set();
  const totalSet = new Set();

  conditions?.forEach(({ id, questionIds }) => {
    questionIds.forEach(totalSet.add, totalSet);
    if (questionIds) {
      if (selectedConditions.has(Number(id))) {
        questionIds.forEach(sumSet.add, sumSet);
      }
    }
  });

  presentations?.forEach(({ id, questionIds }) => {
    questionIds.forEach(totalSet.add, totalSet);
    if (questionIds) {
      if (selectedPresentations.has(Number(id))) {
        questionIds.forEach(sumSet.add, sumSet);
      }
    }
  });

  return [sumSet.size, totalSet.size];
};
