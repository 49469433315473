import React from 'react';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledTabs = styled(MuiTabs)(({ theme: { palette, spacing } }) => ({
  height: spacing(10),
  minHeight: spacing(10),

  '& .MuiTabs-scroller': {
    height: spacing(10),
  },

  '& .MuiButtonBase-root.MuiTab-root': {
    height: spacing(10),
    minHeight: spacing(10),
    display: 'inline-flex',
    flexDirection: 'row',
    gap: spacing(2),
    padding: spacing(2),

    '&:hover:not(.Mui-selected)': {
      color: palette.secondary.dark,
    },

    '&.Mui-selected:hover': {
      color: palette.primary.dark,
    },
  },

  '& .MuiTabs-indicator': {
    height: '1.5px',
  },

  '&:hover': {
    '& .MuiTabs-indicator': {
      backgroundColor: palette.primary.dark,
    },
  },
}));

interface TabPanelProps extends BoxProps {
  visible: boolean;
}

export const TabPanel = ({
  children,
  visible,
  ...props
}: TabPanelProps): JSX.Element | null =>
  visible ? <Box {...props}>{children}</Box> : null;

interface Tab {
  label: string;
  value: string;
  icon?: JSX.Element;
}

export interface TabsProps extends Omit<MuiTabsProps, 'value' | 'onChange'> {
  activeTab: string;
  onTabChange?: (tab: any) => void;
  fullWidth?: boolean;
  panelSx?: BoxProps['sx'];
  tabs: Tab[];
}

export const Tabs = ({
  activeTab,
  fullWidth = false,
  onTabChange,
  tabs,
  variant = 'fullWidth',
  panelSx,
  ...props
}: TabsProps) => {
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto', marginBottom: 4 }}>
      <StyledTabs
        onChange={handleChange}
        value={activeTab}
        variant={variant}
        {...props}
      >
        {tabs.map(({ icon, label, value }) => (
          <MuiTab
            color="secondary"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            key={value}
            label={
              <>
                {icon}
                {label}
              </>
            }
            value={value}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
