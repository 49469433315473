import { MultiAnswerColumn, MultiAnswerTableKey } from '../types';

const ANSWER_COLUMNS_COUNT = '2';
const ANSWER_COLUMN_SIZE = '80px';
const DEFAULT_COLUMN_SIZE = '3fr';
const MEDICINE_COLUMN_SIZE = '6fr';

const customColumns = [
  MultiAnswerTableKey.A,
  MultiAnswerTableKey.B,
  MultiAnswerTableKey.Medicine,
];

const setMultiAnswerColumnTemplate = (columns: MultiAnswerColumn[]): string => {
  const columnTypes = new Set(columns.map(({ key }) => key));
  const defaultColumnsCount = columns.filter(
    ({ key }) => !customColumns.includes(key)
  ).length;
  const choiceColumns = defaultColumnsCount
    ? `repeat(${ANSWER_COLUMNS_COUNT}, ${ANSWER_COLUMN_SIZE})`
    : '';
  const medicineColumn = columnTypes.has(MultiAnswerTableKey.Medicine)
    ? MEDICINE_COLUMN_SIZE
    : '';

  const defaultColumns = defaultColumnsCount
    ? `repeat(${defaultColumnsCount}, ${DEFAULT_COLUMN_SIZE})`
    : '';

  const gridTemplateColumns = `${medicineColumn} ${defaultColumns} ${choiceColumns} `;

  return gridTemplateColumns;
};

export default setMultiAnswerColumnTemplate;
