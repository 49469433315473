import { useQuery } from '@apollo/client';
import {
  IPresetData,
  IPresetVar,
  PRESET,
} from '@quesmed/types-rn/resolvers/query/restricted';

const usePresetQuery = (id: number) => {
  const { data, loading, client } = useQuery<IPresetData, IPresetVar>(PRESET, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  return { data, loading, client } as const;
};

export default usePresetQuery;
