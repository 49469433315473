import React from 'react';

import { useOsceVideoLibraryData } from './OsceVideoLibrary';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { PanelItems, VIDEO_SEARCH_LABEL } from 'components/LearningMaterials';
import VideoDetails from 'components/LearningMaterials/components/VideoDetails';
import { PathName } from 'types';

const OsceVideoLibraryVideoDetails = () => {
  const {
    activeCategoryLabel,
    resetSearch,
    searchBy,
    loading,
    stations,
    activeCategory,
  } = useOsceVideoLibraryData();

  return (
    <ExerciseLayout
      collapsedPanel={false}
      controlPanelContent={
        <PanelItems
          entitlementId={activeCategory}
          items={stations}
          loading={loading}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchLabel={VIDEO_SEARCH_LABEL}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.VideoLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <VideoDetails
            activeCategoryLabel={activeCategoryLabel}
            items={stations}
          />
        </PreLaunchLayout>
      }
    />
  );
};

export default OsceVideoLibraryVideoDetails;
