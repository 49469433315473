import { EProductType } from '@quesmed/types-rn/models';

import { NavigationSection } from 'components/Layout';
import {
  DashboardIcon,
  FlashcardsIcon,
  KnowledgeLibraryIcon,
  MockTestsIcon,
  QuestionsIcon,
  StationIcon,
  TableIcon,
  VideoTrainingsIcon,
} from 'components/Icons';
import { PathName } from 'types';
import { paths } from 'Router';

const {
  flashcards,
  questions,
  mockTests,
  stations,
  videoLibrary,
  knowledgeLibrary,
  mlaContentMap,
} = paths;

const DashboardNavItem = {
  icon: DashboardIcon,
  label: PathName.Dashboard,
  path: paths.dashboard,
};

const QuestionsNavItem = {
  icon: QuestionsIcon,
  label: PathName.Questions,
  path: questions.root,
};

const MLAContentMapNavItem = {
  icon: TableIcon,
  label: PathName.ContentMap,
  path: mlaContentMap.root,
};

const FlashcardsNavItem = {
  icon: FlashcardsIcon,
  label: PathName.Flashcards,
  path: flashcards.root,
};

const MockTestsNavItem = {
  icon: MockTestsIcon,
  label: PathName.MockTests,
  path: mockTests.root,
};

const MockTestsNavItemWithPSA = {
  icon: MockTestsIcon,
  label: PathName.MockTestsAndPSA,
  path: mockTests.root,
};

const PastPapersNavItem = {
  icon: MockTestsIcon,
  label: PathName.PastPapers,
  path: mockTests.root,
};

const StationsNavItem = {
  icon: StationIcon,
  label: PathName.Stations,
  path: stations.root,
};

const KnowledgeLibraryNavItem = {
  icon: KnowledgeLibraryIcon,
  label: PathName.KnowledgeLibrary,
  path: knowledgeLibrary.root,
};

const VideoLibraryNavItem = {
  icon: VideoTrainingsIcon,
  label: PathName.VideoLibrary,
  path: videoLibrary.root,
};

const learningMaterials: NavigationSection = {
  title: PathName.LearningMaterials,
  items: [KnowledgeLibraryNavItem, VideoLibraryNavItem],
};

const qbankNavigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [
      DashboardNavItem,
      QuestionsNavItem,
      MLAContentMapNavItem,
      FlashcardsNavItem,
      MockTestsNavItemWithPSA,
    ],
  },
  learningMaterials,
];

const osceNavigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [DashboardNavItem, StationsNavItem],
  },
  learningMaterials,
];

const amsNavigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [
      DashboardNavItem,
      QuestionsNavItem,
      FlashcardsNavItem,
      MockTestsNavItem,
    ],
  },
  learningMaterials,
];

const part1And2Navigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [DashboardNavItem, QuestionsNavItem, PastPapersNavItem],
  },
  learningMaterials,
];

const pacesNavigation: NavigationSection[] = [
  {
    title: PathName.LearningMaterials,
    items: [VideoLibraryNavItem, KnowledgeLibraryNavItem],
  },
];

const msraNavigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [DashboardNavItem, QuestionsNavItem],
  },
  {
    title: PathName.LearningMaterials,
    items: [KnowledgeLibraryNavItem],
  },
];

// TODO: To remove when there are videos in Pre-Clinical
const tempMSNavigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [
      DashboardNavItem,
      QuestionsNavItem,
      FlashcardsNavItem,
      MockTestsNavItem,
    ],
  },
  {
    title: PathName.LearningMaterials,
    items: [KnowledgeLibraryNavItem],
  },
];

const interviewNavigation: NavigationSection[] = [learningMaterials];

const plab1Navigation: NavigationSection[] = [
  {
    title: PathName.Practice,
    items: [
      DashboardNavItem,
      QuestionsNavItem,
      MLAContentMapNavItem,
      MockTestsNavItem,
    ],
  },
  learningMaterials,
];

const interviewNoVideoNavigation: NavigationSection[] = [
  {
    title: PathName.LearningMaterials,
    items: [KnowledgeLibraryNavItem],
  },
];

const plab2Navigation = osceNavigation;

export const NAVIGATION_ENTRIES = {
  [EProductType.ALL]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.BUNDLE]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.ANATOMY_BUNDLE]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.CLINICAL]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.DATA_INTERPRETATION]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.CLINICAL_SUBSET]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.CLINICAL_PROBLEM_SOLVING]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.PROFESSIONAL_DILEMMA]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.HISTORY_TAKING]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.COMMUNICATION_SKILLS]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.EXAMINATION]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.PROCEDURE]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.INTERPRETATION]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.ABCDE]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.PRESCRIBING]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.MIXED]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.ABDOMINAL]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.CARDIOVASCULAR]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.COMMUNICATION]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.CONSULTATION]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.NEUROLOGY]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.RESPIRATORY]: undefined, // Just to satisfied TS it is not possible to choose this
  [EProductType.PLAB1]: plab1Navigation,
  [EProductType.PLAB2]: plab2Navigation,
  [EProductType.QBANK]: qbankNavigation,
  [EProductType.OSCE]: osceNavigation,
  [EProductType.MEDICAL_SCIENCES]: tempMSNavigation,
  [EProductType.ANATOMY]: amsNavigation,
  [EProductType.MRCP_PART1]: part1And2Navigation,
  [EProductType.MRCP_PART2]: part1And2Navigation,
  [EProductType.PACES]: pacesNavigation,
  [EProductType.INTERVIEW_ANAESTHETICS]: interviewNavigation,
  [EProductType.INTERVIEW_RADIOLOGY]: interviewNavigation,
  [EProductType.INTERVIEW_CST]: interviewNavigation,
  [EProductType.INTERVIEW_IMT]: interviewNavigation,
  [EProductType.INTERVIEW_PAEDIATRICS]: interviewNoVideoNavigation,
  [EProductType.MSRA]: msraNavigation,
};
