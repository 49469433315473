import { ApolloError } from '@apollo/client';
import { ITodo } from '@quesmed/types-rn/models';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorBox } from 'components/Error';
import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';
import { FlashcardsRouterParams } from 'types';
import { FlashcardsQuiz } from './FlashcardsQuiz';
import { usePrevious } from 'hooks';
import { parseMarkNumber } from 'utils';
import { CircularProgress } from 'components/CircularProgress';

const { dashboard, flashcards } = paths;

interface FlashcardsQuizContainerProps {
  todo?: ITodo;
  error?: ApolloError;
  loading: boolean;
  isSampleGame: boolean;
}

const FlashcardsQuizContainer = ({
  error,
  loading,
  isSampleGame,
  todo,
}: FlashcardsQuizContainerProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar({ retryError: error });
  const navigate = useNavigate();
  const { todoId, cardId, markId, cardNumber } =
    useParams<FlashcardsRouterParams>();

  const baseUrl = `${flashcards.root}/quiz/${todoId || ''}`;

  const { dailyTask, marks = [] } = todo || {};

  const prevMarks = usePrevious(marks);

  useEffect(() => {
    if (
      (prevMarks.length &&
        prevMarks.length !== marks.length &&
        marks.length === 0) ||
      (dailyTask && !marks.length)
    ) {
      enqueueSnackbar('No Ques Cards in daily feed');
      navigate(dashboard);

      return;
    }

    if ((todo && !cardId && !cardNumber) || prevMarks.length !== marks.length) {
      const firstUnseenCardIndex = marks.findIndex(
        ({ score }) => score === null
      );

      const index = firstUnseenCardIndex <= 0 ? 0 : firstUnseenCardIndex;

      if (index >= 0) {
        const { id, cardId: currentCardId } = marks[index];
        navigate(`${baseUrl}/${id}/${currentCardId}/${index + 1}`, {
          replace: true,
        });
      } else {
        navigate(dashboard);
        enqueueSnackbar('Ques Card not found');
      }
    }
  }, [
    baseUrl,
    cardId,
    dailyTask,
    cardNumber,
    enqueueSnackbar,
    navigate,
    marks,
    prevMarks.length,
    todo,
  ]);

  if (loading || !cardId || !markId || prevMarks.length !== marks.length)
    return <CircularProgress description="Getting Ques Cards Info" />;

  if (error) return <ErrorBox description={error.message} />;

  if (todo) {
    const { marks = [] } = todo;
    const validCardNumber = parseMarkNumber(cardNumber, marks.length);
    const validCardIndex = validCardNumber - 1;
    const { cardId: validCardId } = marks[validCardIndex];

    return (
      <FlashcardsQuiz
        baseUrl={baseUrl}
        cardId={Number(validCardId)}
        cardIndex={validCardIndex}
        isSampleGame={isSampleGame}
        markId={Number(markId)}
        todo={todo}
      />
    );
  }

  return (
    <ErrorBox description="Something wrong happened. Try refreshing page or contact administrator." />
  );
};

export default FlashcardsQuizContainer;
