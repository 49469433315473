import React from 'react';
import {
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiNumeric4CircleOutline,
  mdiNumeric5CircleOutline,
} from '@mdi/js';

import Icon, { IconProps } from './Icon';

interface NumericCircleIconPros extends IconProps {
  number: number;
}
const NUMERIC_ICONS = [
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiNumeric4CircleOutline,
  mdiNumeric5CircleOutline,
];

const NumericCircleIcon = ({ number, ...props }: NumericCircleIconPros) => (
  <Icon path={NUMERIC_ICONS[number - 1]} {...props} />
);

export default NumericCircleIcon;
