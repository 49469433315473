import React from 'react';
import { IQuestionChoice } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  ExpandableSection,
  ExpandableSectionProps,
} from 'components/ExpandableSection';
import RankingQuestionChoice from './RankingQuestionChoice';
import { ChoiceExplanation } from 'components/ChoiceExplanation';

const ContentContainer = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),

  [breakpoints.up('md')]: {
    gap: spacing(8),
  },
}));

export interface RankingQuestionAnswerProps {
  choices: IQuestionChoice[];
  correctAnswer: string[];
  explanation?: string;
  title: string;
  variant?: ExpandableSectionProps['variant'];
  preview?: boolean;
}

export const RankingQuestionAnswer = ({
  choices,
  correctAnswer,
  explanation,
  preview,
  title,
  variant,
}: RankingQuestionAnswerProps) => {
  const correctOrder = [...choices].sort(
    (a, b) => correctAnswer.indexOf(a.label) - correctAnswer.indexOf(b.label)
  );

  return (
    <ExpandableSection
      disableContentBackground
      expandable
      expanded
      size="medium"
      title={title}
      variant={variant}
    >
      <ContentContainer>
        <Box>
          {correctOrder.map(({ name, id }, index) => (
            <RankingQuestionChoice
              index={index}
              isDragged={false}
              key={id}
              name={name}
              preview={preview}
              status="correct"
            />
          ))}
        </Box>
        {explanation ? <ChoiceExplanation explanation={explanation} /> : null}
      </ContentContainer>
    </ExpandableSection>
  );
};
