import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import { usePresetGlobalState } from './state/PresetGlobalState';
import {
  ExerciseModeLabel,
  InfoLabel,
  InfoValue,
  MainHeader,
  StyledBox,
} from 'components/ExerciePreBuildHeader/ExercisePreBuildHeaderStyle';
import { ArrowLeftIcon } from 'components/Icons';
import { isFlashcardsPage } from 'utils';
import QuestionsQuizLeaveModal from 'pages/Questions/QuestionsQuizLeaveModal';
import FlashcardsLeaveModal from 'pages/Flashacards/FlashcardsLeaveModal';

const CategoryHeader = styled(Box)(({ theme: { breakpoints, palette } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '40px',
  borderRadius: 0,
  border: `1px solid ${palette.stroke.main}`,
  overflow: 'hidden',
  backgroundColor: palette.background.paper,

  [breakpoints.up('md')]: {
    borderRadius: '7px 7px 0 0',
  },
}));

const GoBackBox = styled(Box)(
  ({ theme: { breakpoints, palette, typography, spacing } }) => ({
    ...typography.h5,
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: spacing(0, 0, 6, 0),
    '& .MuiSvgIcon-root': {
      margin: spacing(0, 4, 0, 0),
      color: palette.icon.main,
    },

    [breakpoints.up('md')]: {
      borderRadius: '7px 7px 0 0',
    },
  })
);

const EditPresetHeader = ({ name }: { name: string }) => (
  <StyledBox>
    <Box>
      <MainHeader>Edit Preset</MainHeader>
      <Box className="info">
        <InfoLabel>Preset name:</InfoLabel>
        <InfoValue>
          <ExerciseModeLabel>{name}</ExerciseModeLabel>
        </InfoValue>
      </Box>
    </Box>
  </StyledBox>
);

const EditPreset = () => {
  const { pathname } = useLocation();
  const { preset } = usePresetGlobalState();
  const [open, setOpen] = useState(false);
  const flashcardsMode = isFlashcardsPage(pathname);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <GoBackBox onClick={handleOpenModal}>
        <ArrowLeftIcon /> Go Back
      </GoBackBox>

      <EditPresetHeader name={preset.name} />
      <CategoryHeader>{preset.entitlement.name}</CategoryHeader>
      {flashcardsMode ? (
        <FlashcardsLeaveModal
          discard
          fromBackButton
          onClose={handleCloseModal}
          open={open}
        />
      ) : (
        <QuestionsQuizLeaveModal
          discard
          fromBackButton
          onClose={handleCloseModal}
          open={open}
        />
      )}
    </>
  );
};

export default EditPreset;
