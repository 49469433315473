import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { EPrescriptionType } from '@quesmed/types-rn/models';
import {
  IPrescriptionsData,
  IPrescriptionsVar,
  PRESCRIPTIONS,
} from '@quesmed/types-rn/resolvers/query/restricted';

export const useGetPrescriptionsQuery = () => {
  const [getPrescriptionsData, { loading }] = useLazyQuery<
    IPrescriptionsData,
    IPrescriptionsVar
  >(PRESCRIPTIONS);

  const getPrescriptions = useCallback(
    (typeId: EPrescriptionType, query: string) =>
      getPrescriptionsData({
        variables: {
          typeId,
          query,
          limit: 50,
        },
      }),
    [getPrescriptionsData]
  );

  return { getPrescriptions, loading };
};
