import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { Body } from 'components/Typography';
import { isValentinesDay, localStorageTyped } from 'utils';

const Container = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  flexDirection: 'column',
  alignItems: 'center',

  [breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const LegendContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  alignItems: 'center',
  justifyContent: 'center',
}));

const LegendItemContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
}));

const LegendBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.disabled,
}));

const LegendItem = styled(Box)<{ heatmapcolourname?: string | null }>(
  ({ heatmapcolourname, theme: { breakpoints, palette } }) => ({
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: '4px',
    width: '48px',
    height: '20px',
    backgroundColor: palette.background.default,

    '&.intensity-medium': {
      backgroundColor: heatmapcolourname
        ? palette.heatmap[
            heatmapcolourname as unknown as keyof typeof palette.heatmap
          ].med
        : palette.heatmap.amber.med,
    },

    '&.intensity-high': {
      backgroundColor: heatmapcolourname
        ? palette.heatmap[
            heatmapcolourname as unknown as keyof typeof palette.heatmap
          ].high
        : palette.heatmap.amber.high,
    },

    '&.heart': {
      backgroundColor: 'unset',
      clipPath: 'polygon(-41% 0, 50% 91%, 141% 0)',
      borderImage: `radial-gradient(${palette.background.default} 69%, #0000 70%) 84.5%/50%`,
      height: '50px',

      [breakpoints.up('md')]: {
        width: '40px',
        height: '40px',
      },

      '&.intensity-medium': {
        borderImage: `radial-gradient(${
          heatmapcolourname
            ? palette.heatmap[
                heatmapcolourname as unknown as keyof typeof palette.heatmap
              ].high
            : palette.error.main
        } 69%, #0000 70%) 84.5%/50%`,
      },

      '&.intensity-high': {
        borderImage: `radial-gradient(${
          heatmapcolourname
            ? palette.heatmap[
                heatmapcolourname as unknown as keyof typeof palette.heatmap
              ].high
            : palette.error.dark
        } 69%, #0000 70%) 84.5%/50%`,
      },
    },
  })
);

const { getItem } = localStorageTyped<string>('heatmapColorScheme');

const HeatmapFooter = () => (
  <Container>
    <Body>Number of questions completed by day:</Body>
    <LegendContainer>
      <LegendBody>0</LegendBody>
      <LegendItemContainer>
        <LegendItem
          className={clsx('intensity-none', { heart: isValentinesDay() })}
        />
        <LegendItem
          className={clsx('intensity-medium', { heart: isValentinesDay() })}
          heatmapcolourname={getItem()}
        />
        <LegendItem
          className={clsx('intensity-high', { heart: isValentinesDay() })}
          heatmapcolourname={getItem()}
        />
      </LegendItemContainer>
      <LegendBody>∞</LegendBody>
    </LegendContainer>
  </Container>
);

export default HeatmapFooter;
