import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import isNil from 'lodash/isNil';

import { Body } from 'components/Typography';
import { ProgressQuestionIcon } from 'components/Icons';
import { constSize } from 'utils';
import { LineChartItem } from 'components/ProgressLineChart/types';
import { ProgressLineChartTooltip } from 'components/ProgressLineChart';

const StyledBox = styled(Box)({
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',
});

const SelectionCount = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const StyledIcon = styled(ProgressQuestionIcon)(
  ({ theme: { palette, spacing } }) => ({
    '&:hover': {
      cursor: 'pointer',
    },
    '&.icon': {
      ...constSize(spacing(4)),
    },
    '&.high': {
      color: `${palette.success.light}`,
    },
    '&.medium': {
      color: `${palette.warning.main}`,
    },
    '&.low': {
      color: `${palette.chart.main}`,
    },
  })
);

const BodyIcon = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '2px',
});

const BodyIconText = styled(Box)(({ theme: { typography } }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...typography.body1Medium,
}));

export interface SelectionInputCellLabelProps {
  name: string;
  total: number;
  correctQuestions: number;
  count: number;
  labelOfTotal?: string;
  data: LineChartItem[];
}

const SelectionInputProgressCellLabel = ({
  name,
  total,
  correctQuestions,
  count,
  labelOfTotal,
  data,
}: SelectionInputCellLabelProps): JSX.Element => {
  const breakpointLowMedium = 0.5;
  const breakpointMediumHigh = 0.7;
  const colorCalculation =
    correctQuestions === 0 ? 0 : correctQuestions / total;
  const color =
    colorCalculation < breakpointLowMedium
      ? 'low'
      : colorCalculation < breakpointMediumHigh
      ? 'medium'
      : 'high';

  return (
    <StyledBox component="span">
      <BodyIcon component="span">
        <BodyIconText component="span">{name}</BodyIconText>
        <ProgressLineChartTooltip data={data} name={name} total={total}>
          <StyledIcon className={`icon ${color}`} />
        </ProgressLineChartTooltip>
      </BodyIcon>
      {!isNil(count) && !isNil(total) ? (
        <SelectionCount component="span" small>
          {`Selected ${count}/${total}`}
        </SelectionCount>
      ) : null}
      {!isNil(total) && labelOfTotal ? (
        <SelectionCount component="span" small>
          {labelOfTotal} {total}
        </SelectionCount>
      ) : null}
    </StyledBox>
  );
};

export default SelectionInputProgressCellLabel;
