import React from 'react';
import { mdiCheckboxMarked } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckboxCorrectIcon = (props: IconProps) => (
  <Icon path={mdiCheckboxMarked} {...props} />
);

export default CheckboxCorrectIcon;
