import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import {
  BullseyeArrowIcon,
  ClipboardTextClockOutlineIcon,
  FlagOutlineIcon,
} from 'components/Icons';
import { Tabs } from './types';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct } from 'utils';

const Wrapper = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(6, 0),
  display: 'flex',

  '> button': {
    padding: spacing(2, 4),
  },
}));

const Tab = styled(Button)(({ theme: { palette, spacing } }) => ({
  textTransform: 'uppercase',
  color: palette.secondary.main,
  display: 'flex',
  alignItems: 'center',

  ':hover': {
    background: 'none',
    color: palette.primary.main,
    cursor: 'pointer',
  },
  '&.active': {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
    borderRadius: 0,
  },
  ':focus': {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
    borderRadius: 0,
  },

  ' > svg': {
    marginRight: spacing(2),
  },
}));

interface IActivityTabs {
  switchTab: (tab: Tabs) => void;
  activeTab: Tabs;
}

interface ITab {
  title: Tabs;
  icon: JSX.Element;
}

const isTabActive = (clickedTab: Tabs, tab: Tabs) => {
  return clickedTab === tab ? 'active' : '';
};

const ActivityTabs = ({ switchTab, activeTab }: IActivityTabs): JSX.Element => {
  const { product } = usePlatform();

  const tabFields = () =>
    isStationsProduct(product)
      ? [
          { title: Tabs.Tracker, icon: <BullseyeArrowIcon /> },
          { title: Tabs.History, icon: <ClipboardTextClockOutlineIcon /> },
        ]
      : [
          { title: Tabs.Tracker, icon: <BullseyeArrowIcon /> },
          { title: Tabs.Flags, icon: <FlagOutlineIcon /> },
          { title: Tabs.History, icon: <ClipboardTextClockOutlineIcon /> },
        ];

  const handleSwitchtab = (tab: Tabs) => () => {
    switchTab(tab);
  };

  return (
    <Wrapper>
      {tabFields().map((tab: ITab) => (
        <Tab
          className={isTabActive(activeTab, tab.title)}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          key={tab.title}
          onClick={handleSwitchtab(tab.title)}
        >
          {tab.icon} {tab.title}
        </Tab>
      ))}
    </Wrapper>
  );
};

export default ActivityTabs;
