import React, { ReactNode } from 'react';
import { IMarksheetMark } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';

import { setQuestionStatus } from 'utils';
import QuestionPill from './QuestionPill';
import { useIsDesktop } from 'hooks/useDeviceSize';
import { useExpandedState } from 'components/Layout/useExerciseLayoutState';

interface QuestionPillsListProps {
  pills: IMarksheetMark[];
  pillsMap: Map<number, [number, number]>;
  fallback: ReactNode;
  onClick: (index: number) => void;
  currentPillId: number;
  showPillStatus: boolean;
}

const QuestionPillsList = ({
  currentPillId,
  fallback,
  onClick,
  pills,
  pillsMap,
  showPillStatus,
}: QuestionPillsListProps): JSX.Element => {
  const { toggleExpanded } = useExpandedState();
  const { isDesktop } = useIsDesktop();

  const handleClick = (index: number) => () => {
    if (!isDesktop) {
      toggleExpanded();
    }
    onClick(index);
  };

  return pills.length ? (
    <>
      {pills.map(pill => {
        const [pillIndex, pillNumber] = pillsMap.get(pill.id) || [0, 1];

        return (
          <QuestionPill
            key={pill.id}
            label={`Question ${pillNumber}`}
            marked={pill.flagged}
            onClick={handleClick(pillIndex)}
            selected={currentPillId === pill.id}
            status={setQuestionStatus(pill, showPillStatus)}
          />
        );
      })}
    </>
  ) : (
    <Box>{fallback}</Box>
  );
};

export default QuestionPillsList;
