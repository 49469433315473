import { useMutation } from '@apollo/client';
import {
  IQuickBuildMarksheetData,
  IQuickBuildMarksheetVar,
  QUICK_BUILD_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import paths from 'Router/paths';

export const useQuickMarksheet = () => {
  const navigate = useNavigate();

  const [buildQuickMarksheet, { loading }] = useMutation<
    IQuickBuildMarksheetData,
    IQuickBuildMarksheetVar
  >(QUICK_BUILD_MARKSHEET, {
    onCompleted: data => {
      if (data) {
        const { id } = data.restricted.quickBuildMarksheet;

        navigate(`${paths.questions.root}/solo/quiz/${id}`);
      }
    },
  });

  const startQuickMarksheet = useCallback(
    (input?: IQuickBuildMarksheetVar['input']) =>
      buildQuickMarksheet({ variables: { input: { ...input } } }),
    [buildQuickMarksheet]
  );

  return { startQuickMarksheet, loading };
};
