import { ReactNode } from 'react';

import { CellAllignment, CellType, CellValue, TableData } from 'types';
import { ExerciseSummaryResultProps } from './ExerciseSummaryResult';

export enum SummaryTableKey {
  Number = 'No.',
  Content = 'Content',
  Result = 'Result',
  Phases = 'Phases',
  Duration = 'Time Spent',
  Topic = 'Topic',
  Concept = 'Concept',
  Chevron = 'Chevron',
  Satisfactory = 'Satisfactory',
  Borderline = 'Borderline',
  Unsatisfactory = 'Unsatisfactory',
  Flagged = 'Flagged',
  Checkbox = 'Checkbox',
  LearningPoint = 'Learning Point',
}

export interface SummaryColumn<T extends TableData> {
  key: SummaryTableKey;
  label: string;
  value?: CellValue;
  formatter?: (value: T, number: number) => CellValue;
  resultFormatter?: (value: T) => ExerciseSummaryResultProps;
  align: CellAllignment;
  type: CellType;
  labelIcon?: ReactNode;
}

export interface ExerciseSummaryTableProps<T extends TableData> {
  summary: string;
  legend: ReactNode;
  data: T[];
  columns: SummaryColumn<T>[];
}

export enum SummaryTabs {
  LearningPoints = 'Learning Points',
  Content = 'Content',
}
