import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ETopicType, ITopic } from '@quesmed/types-rn/models';
import {
  IQuesBookData,
  IQuesBookVar,
  QBANK_KNOWLEDGE_VIDEO_LIBRARY,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { Nullable } from 'types';

export const useQbankKnowledgeVideoLibraryQuery = (
  typeId: ETopicType[],
  videosOnly = false
): [Nullable<ITopic[]>, boolean] => {
  const { data, loading } = useQuery<IQuesBookData, IQuesBookVar>(
    QBANK_KNOWLEDGE_VIDEO_LIBRARY,
    {
      variables: { typeId, videosOnly },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { quesBook } = data?.restricted || {};

  const topics = useMemo(() => (quesBook ? quesBook : null), [quesBook]);

  return [topics, loading];
};
