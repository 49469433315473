import React from 'react';

import { StyledCommentText } from './Comment.styles';

interface CommentText2Props {
  text: string;
  scale?: number;
}

export const CommentText = ({ text, scale }: CommentText2Props) => (
  <StyledCommentText scale={scale} small>
    {text}
  </StyledCommentText>
);
