import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ExerciseBuilder,
  KeyField,
  SelectionColumn,
} from 'components/ExerciseBuilder';
import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import { BuilderViewMode, ItemStatusLabel, Nullable, PathName } from 'types';
import { BUILDER_VIEWS } from 'constants/general';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import { WATCH_STATUS_LABELS } from 'components/GridCell';
import {
  LearningMaterialsData,
  VideoLibraryColumns,
  VideoTopic,
} from 'components/LearningMaterials/types';
import { useQbankVideoLibraryData } from './QbankVideoLibrary';
import { paths } from 'Router';
import {
  LibraryLabel,
  PanelItems,
  VIDEO_SEARCH_LABEL,
} from 'components/LearningMaterials';

const { videoLibrary } = paths;

const progressFormatter = ({
  urgentVideos,
  revisingVideos,
  completedVideos,
  unwatchedVideos,
  videos = [],
  name,
}: VideoTopic): ProgressLineChartProps => {
  const urgent = urgentVideos || 0;
  const revising = revisingVideos || 0;
  const completed = completedVideos || 0;
  const unwatched =
    unwatchedVideos || videos.length - urgent - revising - completed || 0;

  return {
    title: name,
    data: [
      {
        type: ItemStatusLabel.ToReview,
        value: urgent,
        label: ItemStatusLabel.Urgent,
      },
      {
        type: ItemStatusLabel.Revising,
        value: revising,
        label: ItemStatusLabel.Revising,
      },
      {
        type: ItemStatusLabel.Confident,
        value: completed,
        label: ItemStatusLabel.Watched,
      },
      {
        type: ItemStatusLabel.NotAnswered,
        value: unwatched,
        label: ItemStatusLabel.Unwatched,
      },
    ],
    total: urgent + revising + completed + unwatched,
  };
};

const detailsColumns: SelectionColumn<VideoLibraryColumns>[] = [
  {
    align: 'left',
    formatter: LibraryLabel,
    key: KeyField.Label,
    label: 'Topics',
    type: 'label',
  },
  {
    align: 'left',
    key: 'urgentVideos',
    ...WATCH_STATUS_LABELS[0],
    type: 'default',
  },
  {
    align: 'left',
    key: 'revisingVideos',
    ...WATCH_STATUS_LABELS[1],
    type: 'default',
  },
  {
    align: 'left',
    key: 'completedVideos',
    ...WATCH_STATUS_LABELS[2],
    type: 'default',
  },
  {
    align: 'left',
    key: 'unwatchedVideos',
    ...WATCH_STATUS_LABELS[3],
    type: 'default',
  },
];

const overviewColumns: SelectionColumn<VideoLibraryColumns>[] = [
  {
    align: 'left',
    formatter: LibraryLabel,
    key: KeyField.Label,
    label: 'Topics',
    type: 'label',
  },
  {
    align: 'left',
    key: KeyField.Progress,
    label: 'Progress',
    type: 'bar',
    progressLabels: WATCH_STATUS_LABELS,
    progressFormatter,
  },
];

const QbankVideoLibraryHome = (): JSX.Element => {
  const {
    activeView,
    activeCategory,
    activeCategoryLabel,
    categories,
    loading,
    topics,
    resetSearch,
    search,
    searchBy,
    switchCategory,
    switchView,
    searchItems,
  } = useQbankVideoLibraryData();
  const navigate = useNavigate();

  const handleSelectTopic = (topicId: Nullable<number>) => () => {
    if (topicId) {
      const entitlementId = topics.find(
        ({ id }) => Number(id) === Number(topicId)
      )?.entitlement.id;
      navigate(`${videoLibrary.root}/section/${entitlementId}/${topicId}`);
    }
  };

  const columns = useMemo(
    () =>
      activeView === BuilderViewMode.Details ? detailsColumns : overviewColumns,
    [activeView]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          entitlementId={activeCategory}
          items={topics as unknown as LearningMaterialsData[]}
          loading={loading}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchItems}
          searchLabel={VIDEO_SEARCH_LABEL}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.VideoLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader mainHeader={PathName.VideoLibrary} />
          <ExerciseBuilder
            activeCategory={activeCategory}
            categoryOptions={categories}
            columns={columns}
            data={topics || []}
            loading={loading}
            onSearch={search}
            onSelectRow={handleSelectTopic}
            onToggleCategory={switchCategory}
            onToggleView={switchView}
            resetSearch={resetSearch}
            searchItems={searchItems}
            searchLabel={VIDEO_SEARCH_LABEL}
            selectOnRowClick
            selectedView={activeView}
            title={activeCategoryLabel}
            viewOptions={BUILDER_VIEWS}
            withOffset
          />
        </PreLaunchLayout>
      }
    />
  );
};

export default QbankVideoLibraryHome;
