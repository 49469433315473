import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Markdown } from 'components/Markdown';
import { LightbulbOn20Icon } from 'components/Icons';
import { Body } from 'components/Typography';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
}));

const Title = styled(Body)(({ theme: { palette, spacing } }) => ({
  color: palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
}));

interface ChoiceExplanationProps {
  explanation: string;
}

const ChoiceExplanation = ({
  explanation,
}: ChoiceExplanationProps): JSX.Element => (
  <Container>
    <Title bold>
      <LightbulbOn20Icon /> Explanation
    </Title>
    <Box>
      <Markdown
        markdownStylesProps={{
          bottomOffset: false,
        }}
        text={explanation}
      />
    </Box>
  </Container>
);

export default ChoiceExplanation;
