import { FunctionComponent, memo, useRef } from 'react';

// React memo doesn't return generic component. Using React.memo and useRef
// we are able to memoize generic compoennts without loosing dynamic types
const useMemoizedGeneric = <P extends object>(
  Component: FunctionComponent<P>,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean
) => {
  const memoizedGeneric = useRef(memo(Component, propsAreEqual));

  return memoizedGeneric.current;
};

export default useMemoizedGeneric;
