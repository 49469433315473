import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IMarksheet, IOsceMarksheet, IPreset } from '@quesmed/types-rn/models';

import {
  Icon,
  LegendBox,
  TableScroll,
  TableWrapper,
} from './ActivityHistoryTableStyle';
import { TableRowComponent } from './types';
import { DateCell } from 'components/TableCells';

interface TableHeaderOptions {
  initial: string;
  exercise: string;
  legendBox: string[];
  type: string;
  actions: string;
}

interface ActivityHistoryTableProps<
  T extends IMarksheet | IOsceMarksheet | IPreset
> {
  results?: T[];
  headerOptions: TableHeaderOptions;
  tableHeight?: string;
  tableRef?: any;
  rowComponent: TableRowComponent<T>;
}

const classNameRegex = (word: string) => word.replace(/ +/g, '-').toLowerCase();

function ActivityHistoryTable<T extends IMarksheet | IOsceMarksheet | IPreset>({
  results,
  headerOptions,
  tableHeight,
  tableRef,
  rowComponent: DataRowComponent,
}: ActivityHistoryTableProps<T>): JSX.Element {
  return (
    <TableWrapper>
      <TableScroll
        ref={tableRef}
        sx={{ height: tableHeight ? `${tableHeight}px` : '282px' }}
      >
        <Table
          aria-label="customized table"
          stickyHeader
          sx={{ minWidth: 768 }}
        >
          <TableHead>
            <TableRow>
              <DateCell>{headerOptions.initial}</DateCell>
              <TableCell>{headerOptions.exercise}</TableCell>
              <LegendBox align="right">
                {headerOptions.legendBox?.map(legend => (
                  <Box className={classNameRegex(legend)} key={legend}>
                    <Icon className={classNameRegex(legend)} />
                    {legend}
                  </Box>
                ))}
              </LegendBox>
              <TableCell>{headerOptions.type}</TableCell>
              <TableCell>{headerOptions.actions}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ overflowY: 'scroll' }}>
            {results?.map(result => (
              <DataRowComponent
                key={String(result.updatedAt) + result.id}
                result={result}
              />
            ))}
          </TableBody>
        </Table>
      </TableScroll>
    </TableWrapper>
  );
}

export default ActivityHistoryTable;
