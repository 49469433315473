import React from 'react';
import { Control } from 'react-hook-form';
import clsx from 'clsx';
import { EPrescriptionType } from '@quesmed/types-rn/models';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

import { StyledAutocompleteField } from './PrescriptionAnswer.styles';
import { capitalizeFirst } from 'utils';
import { TextInputProps } from 'components/TextField';
import {
  PrescriptionField,
  PrescriptionFieldNames,
  PrescriptionOption,
} from '../types';
import PrescriptionFieldEndAdornment from './PrescriptionFieldEndAdornment';
import { useGetPrescriptionOptions } from './hooks/useGetPrescriptionsOptions';
import { usePrevious } from 'hooks';

export interface PrescriptionAnswerFieldProps {
  locked?: boolean;
  fullWidth?: boolean;
  name: PrescriptionFieldNames;
  defaultValue?: PrescriptionOption;
  units?: string;
  control?: Control<PrescriptionField>;
  inputType?: TextInputProps['type'];
  questionId: number;
  type: EPrescriptionType;
}

type InputChangeHandler = MuiAutocompleteProps<
  PrescriptionOption,
  boolean,
  boolean,
  boolean
>['onInputChange'];

const StyledCircularProgress = styled(MuiCircularProgress)(
  ({ theme: { palette } }) => ({
    '&.MuiCircularProgress-root': {
      '&.MuiCircularProgress-colorPrimary': {
        color: palette.primary.main,
      },
      '&.MuiCircularProgress-colorSecondary': {
        color: palette.secondary.main,
      },
    },
  })
);

const normalizeString = (str: string) =>
  str?.toLowerCase().replace(/\s+/g, ' ').trim();

const filterOptions = (
  options: PrescriptionOption[],
  { inputValue }: { inputValue: string }
) => {
  const normalizedInput = normalizeString(inputValue);

  return options.filter(option => {
    const normalizedOption = normalizeString(option.label);

    // 1. Exact match (e.g., "2 g" should match "2 g")
    if (normalizedOption === normalizedInput) {
      return true;
    }

    // 2. Ignore spaces for short numbers/units (e.g., "2g" should match "2 g")
    if (
      normalizedOption.replace(/\s/g, '') === normalizedInput.replace(/\s/g, '')
    ) {
      return true;
    }

    // 3. Word-based matching for long strings (e.g., "morphine sulph in" should match "Morphine sulphate something injection")
    const inputWords = normalizedInput.split(' ');

    return inputWords.every(word => normalizedOption.includes(word));
  });
};

const PrescriptionAnswerField = ({
  locked,
  name,
  defaultValue,
  control,
  inputType,
  units,
  fullWidth,
  questionId,
  type,
}: PrescriptionAnswerFieldProps): JSX.Element => {
  const label = capitalizeFirst(name === 'drug' ? 'medicine' : name);
  const displayAnswer = Boolean(locked && Boolean(defaultValue));
  const { _formValues } = control || ({} as Control<PrescriptionField>);
  const fieldValue = _formValues && _formValues[name];

  const prevQuestionId = usePrevious(questionId);

  const { loading, message, getOptions, options } = useGetPrescriptionOptions(
    questionId !== prevQuestionId
  );

  const handleChange: InputChangeHandler = (_, value) => {
    if (!displayAnswer) {
      getOptions(type, value, options);
    }
  };

  const getInputProps = () => {
    if (loading || locked) {
      return {
        InputProps: {
          autoComplete: 'false',
          endAdornment: loading ? (
            <StyledCircularProgress color="primary" size={20} thickness={3.6} />
          ) : (
            <PrescriptionFieldEndAdornment locked={locked} units={units} />
          ),
        },
      };
    }

    return {};
  };

  return (
    <StyledAutocompleteField
      autoComplete={false}
      className={clsx(name, { 'full-width': fullWidth })}
      control={control}
      disableClearable={false}
      disabled={locked}
      fieldProps={getInputProps()}
      filterOptions={(options, state) =>
        filterOptions(options as PrescriptionOption[], state)
      }
      label={label}
      name={name}
      noOptionsText={message}
      onInputChange={handleChange}
      options={displayAnswer ? [defaultValue] : options}
      type={inputType}
      value={displayAnswer ? defaultValue?.value : fieldValue ?? ''}
    />
  );
};

export default PrescriptionAnswerField;
