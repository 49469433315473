import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { Body } from 'components/Typography';

const CommentsEmptyContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    padding: spacing(6, 6, 0, 6),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    alignItems: 'center',
    borderTop: `1px solid ${palette.stroke.main}`,
  })
);

const Message = styled(Body)({
  maxWidth: '410px',
});

const CommentsEmpty = () => (
  <CommentsEmptyContainer>
    <Body bold>No comments yet.</Body>
    <Message>
      Be the first one to share your thoughts! This comment section is waiting
      for your opinion.
    </Message>
  </CommentsEmptyContainer>
);

export default CommentsEmpty;
