import { ReactNode } from 'react';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { Nullable } from 'types';

interface MockTestState {
  timer: Nullable<ReactNode>;
  leftFooterControls: Nullable<ReactNode[]>;
  rightFooterControls: Nullable<ReactNode[]>;
  questionCount: Nullable<string>;
  showTools?: boolean;
  showCalc?: boolean;
  testTools?: Nullable<ReactNode[]>;

  resetState: () => void;
  setTimer: (arg: Nullable<ReactNode>) => void;
  setLeftFooterControls: (arg: Nullable<ReactNode[]>) => void;
  setRightFooterControls: (arg: Nullable<ReactNode[]>) => void;
  setTestTools: (arg: Nullable<ReactNode[]>) => void;
  setQuestionCount: (arg: Nullable<string>) => void;
  setShowTools: (arg: boolean) => void;
}

export const mockTestState = create<MockTestState>()(set => ({
  timer: null,
  leftFooterControls: null,
  rightFooterControls: null,
  questionCount: null,
  showTools: false,
  testTools: null,

  setTimer: timer => {
    set({ timer });
  },
  setLeftFooterControls: leftFooterControls => {
    set({ leftFooterControls });
  },
  setRightFooterControls: rightFooterControls => {
    set({ rightFooterControls });
  },
  setTestTools: testTools => {
    set({ testTools });
  },
  setQuestionCount: questionCount => {
    set({ questionCount });
  },
  setShowTools: showTools => {
    set({ showTools });
  },

  resetState: () => {
    set({
      testTools: null,
      timer: null,
      leftFooterControls: null,
      rightFooterControls: null,
      questionCount: null,
      showTools: false,
    });
  },
}));

export const useMockTestStateValues = () =>
  mockTestState(
    useShallow(
      ({
        testTools,
        timer,
        leftFooterControls,
        rightFooterControls,
        questionCount,
        showTools,
        showCalc,
      }) => ({
        timer,
        testTools,
        leftFooterControls,
        rightFooterControls,
        questionCount,
        showTools,
        showCalc,
      })
    )
  );

export const useMockTestStateSetters = () =>
  mockTestState(
    useShallow(
      ({
        setTimer,
        setTestTools,
        setLeftFooterControls,
        setRightFooterControls,
        setQuestionCount,
        setShowTools,
        resetState,
      }) => ({
        setTimer,
        setTestTools,
        setLeftFooterControls,
        setRightFooterControls,
        setQuestionCount,
        setShowTools,
        resetState,
      })
    )
  );
