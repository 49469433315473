import React from 'react';
import Box from '@mui/material/Box';
import kebabCase from 'lodash/kebabCase';
import { useLocation, useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { useDuplicatePresetMutation, usePresetQuery } from './hooks';
import { Modal } from 'components/Modal';
import { usePlatform } from 'context/PlatformState';
import { AppProductType, productsApp, QuesmedAppNames } from 'types';

interface ReceivePresetLinkProps {
  open: boolean;
  setOpen: (status: boolean) => void;
  productType: number;
  presetId: number;
}
const { pricing } = paths;

const defaultMessage =
  "You've been sent a link to a preset. To view the preset, you must first confirm that you wish to create a copy in your profile.";
const subscribeMessage =
  "It appears you don't have a subscription for this product. Please purchase a plan if you wish you copy this preset.";

const ReceivePresetLink = ({
  open,
  setOpen,
  productType,
  presetId,
}: ReceivePresetLinkProps) => {
  const { availableProducts, app } = usePlatform();
  const { key, pathname } = useLocation();
  const navigate = useNavigate();
  const { data } = usePresetQuery(Number(presetId));
  const { preset } = data?.restricted || {};
  const userHasProduct = availableProducts.includes(Number(productType));

  const { duplicatePreset } = useDuplicatePresetMutation(Number(presetId));

  const requiredApp = productsApp[productType as AppProductType];

  const handleSubmit = async () => {
    if (userHasProduct) {
      await duplicatePreset();
      navigate(pathname, { replace: true });
      setOpen(false);
    } else {
      if (app) {
        navigate(`${pricing.root}/${kebabCase(QuesmedAppNames[requiredApp])}`, {
          replace: true,
        });
        setOpen(false);
      }
    }
  };

  const handleCloseModal = () => {
    if (userHasProduct) {
      setOpen(false);
      // This is needed to reset router state, otherwise
      // on page reload the modal will be shown again
      navigate(pathname, { replace: true });
    } else if (key === 'default') {
      navigate(paths.dashboard, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <Modal
      cancelLabel="Cancel"
      noPaddingY
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitLabel={userHasProduct ? 'Add Preset' : 'Subscribe'}
      title={
        userHasProduct ? `Add preset: ${preset?.name}` : 'Purchase subscription'
      }
    >
      <Box>{userHasProduct ? defaultMessage : subscribeMessage}</Box>
    </Modal>
  );
};

export default ReceivePresetLink;
