import React, { useEffect, useMemo, useState } from 'react';
import {
  ActiveDataPoint,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';

import { formatDate, round } from 'utils';
import { useIsMobile } from 'hooks';
import StyledBox from './OsceBarchartStyle';
import {
  allMarks,
  detectAndSum,
  setChartData,
  setChartOptions,
} from './OsceBarchartHelpers';
import { usePlatform } from 'context/PlatformContext';
import { Nullable, StationsActivity, Undefinable } from 'types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IQBankBarChart {
  clickedBar: string;
  data: StationsActivity;
  chartRef: any;
  onBarClick: (index: string) => void;
}

const OsceBarchart = ({
  data,
  clickedBar,
  chartRef,
  onBarClick,
}: IQBankBarChart) => {
  const theme = useTheme();
  const { product } = usePlatform();
  const { isMobile } = useIsMobile();

  const labelsSet: Set<string> = useMemo(() => {
    return new Set(data.map(result => formatDate(result.date)));
  }, [data]);

  const [clickedIndex, setClickedIndex] = useState<Nullable<number>>(
    [...labelsSet].findIndex(label => label === clickedBar)
  );
  useEffect(() => {
    setClickedIndex([...labelsSet].findIndex(label => label === clickedBar));
  }, [clickedBar, labelsSet]);

  const desktopLabels = [...labelsSet];

  const mobileLabels = desktopLabels.map((label: string) => {
    return formatDate(new Date(label), 'D');
  });

  const checkedMarks = detectAndSum(data);
  const totalMarks = allMarks(data);

  const avgStations = () => {
    const totalMarkDates = Object.keys(totalMarks);

    return checkedMarks.map((mark, index) => {
      if (mark.date === totalMarkDates[index]) {
        const percentageMarks = round(
          (mark.sum / totalMarks[totalMarkDates[index]]) * 100
        );

        return { ...mark, sum: percentageMarks };
      }
    });
  };

  const marksAvgSum: Undefinable<number>[] = avgStations().map(
    mark => mark?.sum
  );

  const remainer = marksAvgSum ? marksAvgSum.map((sum: any) => 100 - sum) : [];

  const dataset = [marksAvgSum, remainer];

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (getElementAtEvent(chartRef.current, event).length > 0) {
      const datasetIndexNum = getElementAtEvent(chartRef.current, event)[0]
        .index;
      onBarClick(desktopLabels[datasetIndexNum]);
    }
  };

  const labels = isMobile ? mobileLabels : desktopLabels;

  const handleBarClick = (event: ChartEvent, elements: ActiveDataPoint[]) => {
    setClickedIndex(elements.length ? elements[0].index : null);
  };

  return (
    <StyledBox>
      <Bar
        data={setChartData(dataset, labels, theme, isMobile, clickedIndex)}
        onClick={onClick}
        options={setChartOptions(
          theme,
          checkedMarks,
          totalMarks,
          handleBarClick,
          product
        )}
        ref={chartRef}
      />
    </StyledBox>
  );
};

export default OsceBarchart;
