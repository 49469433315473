import dayjs from 'dayjs';

import { findUserIp } from './findUserIp';
import { PIXEL_ID, PUBLIC_ACCESS_KEY } from 'config/constants';

export const fetchPageViewEvent = async (event_name = 'PageView') => {
  try {
    const ip = await findUserIp();

    if (ip) {
      await fetch(
        `https://graph.facebook.com/v18.0/${PIXEL_ID}/events?access_token=${PUBLIC_ACCESS_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: [
              {
                event_name,
                event_id: 'https://app.quesmed.com/payment-complete',
                event_time: dayjs().unix(),
                action_source: 'webapp',
                user_data: {
                  client_user_agent: navigator.userAgent,
                  client_ip_address: ip || '0.0.0.0',
                },
              },
            ],
          }),
        }
      );
    }
  } catch (error) {
    console.error('error', error);
  }
};
