import React from 'react';
import { useParams } from 'react-router-dom';

import StationsSummary from './StationsSummary';
import { useOsceMarksheet } from './hooks';

const StationsSoloStudySummary = () => {
  const { osceMarksheetId } = useParams<{ osceMarksheetId: string }>();

  const { osceMarksheet, osceMarksheetLoading } = useOsceMarksheet(
    false,
    Number(osceMarksheetId)
  );

  return (
    <StationsSummary
      marksheetLoading={osceMarksheetLoading}
      osceMarksheet={osceMarksheet}
    />
  );
};

export default StationsSoloStudySummary;
