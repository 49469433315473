import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';

import { Markdown } from 'components/Markdown';
import { RadioGroupProps } from 'components/RadioGroup';
import { ChoiceExplanation } from 'components/ChoiceExplanation';
import { MatchingQuestionChoice } from './MatchingQuestionChoice';

const MatchingContent = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

export interface MatchingQuestionProps {
  caseContent: string;
  radioId: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  options: RadioGroupProps['options'];
  explanation?: string;
  readOnly?: boolean;
  selectedValue?: string;
}

const MatchingQuestionChoices = ({
  caseContent,
  radioId,
  onChange,
  options,
  explanation,
  readOnly,
  selectedValue,
}: MatchingQuestionProps): JSX.Element => (
  <MatchingContent>
    <Markdown
      markdownStylesProps={{
        bottomOffset: false,
      }}
      text={caseContent}
    />
    <RadioGroup id={radioId} onChange={onChange} row>
      {options.map(({ label, value, image, status }) => (
        <MatchingQuestionChoice
          checked={value === selectedValue}
          image={image ? image : undefined}
          key={label}
          label={label}
          labelPlacement="start"
          readOnly={readOnly}
          status={status}
          value={value}
        />
      ))}
    </RadioGroup>
    {explanation ? <ChoiceExplanation explanation={explanation} /> : null}
  </MatchingContent>
);

export default MatchingQuestionChoices;
