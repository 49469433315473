import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { TableData } from 'types';
import { SummaryColumn, SummaryTableKey } from './types';
import { formatDuration, getFirstTextLines } from 'utils';
import ExerciseSummaryResult from './ExerciseSummaryResult';
import { ChevronRightIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { Checkbox } from 'components/Checkbox';
import { useLearningPointsCheckState } from 'pages/Questions/context';

const Ellipsis = styled(Box)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const LearningPoint = styled(Box)({
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
});

interface ExerciseSummaryTableCellProps<T extends TableData>
  extends Omit<SummaryColumn<T>, 'align' | 'key'> {
  item: T;
  number: number;
  columnKey: SummaryTableKey;
}

function ExerciseSummaryTableCell<T extends TableData>({
  item,
  number,
  value,
  columnKey,
  formatter,
  resultFormatter,
}: ExerciseSummaryTableCellProps<T>) {
  const { learningPointsSelectionState, toggleSelectLearningPoint } =
    useLearningPointsCheckState();
  const result = formatter ? formatter(item, number) : value;

  switch (columnKey) {
    case SummaryTableKey.Checkbox:
      return (
        <Checkbox
          checked={Boolean(learningPointsSelectionState.get(item.id)?.selected)}
          controlSx={{ marginRight: 0 }}
          onChange={() => toggleSelectLearningPoint(item.id)}
        />
      );
    case SummaryTableKey.Number:
    case SummaryTableKey.Phases:
    default:
      return <>{result}</>;
    case SummaryTableKey.Topic:
    case SummaryTableKey.Concept:
      return (
        <Tooltip placement="top" textCenter textunset={1} title={result}>
          <Ellipsis>{result}</Ellipsis>
        </Tooltip>
      );
    case SummaryTableKey.Duration:
      return <>{formatDuration(result as number)}</>;
    case SummaryTableKey.Content:
      return <Ellipsis>{getFirstTextLines(result as string)}</Ellipsis>;
    case SummaryTableKey.LearningPoint:
      return (
        <Tooltip placement="top" textCenter textunset={1} title={result}>
          <LearningPoint>{result}</LearningPoint>
        </Tooltip>
      );
    case SummaryTableKey.Result:
    case SummaryTableKey.Satisfactory:
    case SummaryTableKey.Borderline:
    case SummaryTableKey.Unsatisfactory:
      return resultFormatter ? (
        <ExerciseSummaryResult {...resultFormatter(item)} />
      ) : null;
    case SummaryTableKey.Chevron:
      return <ChevronRightIcon />;
  }
}

export default ExerciseSummaryTableCell;
