import React from 'react';
import { mdiPauseCircle } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const PauseCircleIcon = (props: IconProps) => (
  <Icon path={mdiPauseCircle} {...props} />
);

export default PauseCircleIcon;
