import { IPicture } from '@quesmed/types-rn/models';
import React from 'react';

import { MarkdownWithLightbox } from 'components/Markdown';
import { parseFullText } from 'utils';

interface ExplanationProps {
  explanation: string;
  pictures?: IPicture[];
}

const MockTestsExplanation = ({
  explanation,
  pictures,
}: ExplanationProps): JSX.Element => (
  <MarkdownWithLightbox
    pictures={pictures}
    text={parseFullText(explanation, pictures)}
  />
);

export default MockTestsExplanation;
