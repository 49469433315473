import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ISaveMarksheetInput } from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  EEntitlementType,
  EMarksheetState,
  EQuestionType,
  IMarksheet,
  IMarksheetMark,
} from '@quesmed/types-rn/models';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ErrorBox } from 'components/Error';
import SingleBestAnswerQuestion from './SingleBestAnswerQuestion';
import { RankingQuestion } from './RankingQuestion';
import {
  FlagOutlineIcon,
  FlagRemoveIcon,
  FlashcardsIcon,
  RemoveFlashcardsIcon,
} from 'components/Icons';
import {
  useEndMarksheet,
  useExerciseState,
  useIsMobile,
  useSaveMarksheets,
  useUserSettings,
} from 'hooks';
import { paths } from 'Router';
import { useChangeQuestion, useFlagQuestion } from './hooks';
import {
  addMarkTagsInMarkdown,
  calcTimeTaken,
  checkValidity,
  filterMarks,
  getChoiceAnswer,
  unescape,
} from 'utils';
import { ChangeQuestionArgs } from 'pages/Questions/hooks/useChangeQuestion';
import useAddToDailyStack from './hooks/useAddToDailyStack';
import { KeyboardKey, Nilable, Nullable, QuestionAnswerStatus } from 'types';
import {
  ExerciseBottomBar,
  ExerciseContent,
  ExerciseLayout,
  HelperControl,
} from 'components/Layout';
import { QuestionsTimer } from 'components/QuestionsTimer';
import { QuestionPillsList } from 'components/ProgressPills';
import { ExercisePills } from 'components/ExercisePills';
import { PANELS, TABS } from 'constants/marksheet';
import { useSnackbar } from 'components/Snackbar';
import { ParticipantList } from 'components/ParticipantCard';
import { useCurrentUser, useDemo } from 'Auth';
import QuestionRelatedContent from './QuestionRelatedContent';
import { QuestionContainer } from 'components/QuestionContainer';
import { Markdown } from 'components/Markdown';
import { SelectThreeQuestion } from './SelectThreeQuestion';
import {
  MatchingQuestion,
  MatchingQuestionChoicesList,
} from './MatchingQuestion';
import { usePlatform } from 'context/PlatformContext';
import { FLASHCARDS_PRODUCTS } from 'config/constants';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import locales from 'locales';
import { QuizProgressBar } from 'components/QuizProgressBar';
import { Button } from 'components/Button';
import { SpaceBarHotKey } from 'components/HotKey';
import { ExerciseRviewNavigation } from 'components/ExerciseReviewNavigation/index';
import ExerciseLaunchedButton from 'components/ExerciseLaunchedButton/ExerciseLaunchedButton';
import useQuestionHighlight from './hooks/useQuestionHighlight';
import { useDisableHighlighting } from 'hooks/useDisableHighlighting';
import { ScoreProgress } from 'components/ScoreProgress';
import useLightboxState from 'components/Lightbox/useLightboxState';

const { questions } = paths;

const { root } = questions;

export interface QuestionsQuizProps {
  baseUrl: string;
  marksheet: IMarksheet;
  questionIndex: number;
  review: boolean;
}

export const findPillIndex = (pills: IMarksheetMark[], currentMarkId: number) =>
  pills.findIndex(({ id }) => currentMarkId === Number(id));

const getControlPanelProps = (
  marksCount: number,
  participantsCount: number,
  showPills: boolean
) =>
  showPills
    ? {
        exerciseItemsCount: marksCount,
        exerciseLabel: 'Questions',
      }
    : {
        exerciseItemsCount: participantsCount,
        exerciseLabel: 'Participants',
      };

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

export const StyledButton = styled(Button)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(3, 8),
    marginTop: spacing(8),
    marginBottom: spacing(2),

    '& .hot-key': {
      display: 'none',

      [breakpoints.up('md')]: {
        display: 'inline-flex',
      },
    },
  })
);

const QuestionsQuiz = ({
  marksheet,
  questionIndex,
  baseUrl,
  review: sourceReview,
}: QuestionsQuizProps): JSX.Element => {
  const {
    sessionId,
    activeUsers = [],
    id,
    endedAt,
    isTestMarksheet = false,
    marks = [],
    solo: marksheetSolo,
    entitlement,
    state,
    users,
    completed: sourceCompleted,
  } = marksheet;
  const currentMark = marks[questionIndex];
  const {
    flagged,
    id: currentMarkId,
    mark: userQuestionAnswer,
    question,
    isAnswered,
    questionChoiceId,
    timeTaken: prevTimeTaken,
  } = currentMark;
  const {
    pictures = [],
    question: questionContent,
    typeId,
    choices,
    concept,
    highlights = [],
    conceptId,
  } = question;
  const solo = isNil(marksheetSolo) ? true : marksheetSolo;
  const navigate = useNavigate();
  const { product } = usePlatform();
  const { id: currentUserId } = useCurrentUser();
  const [isComplete, setIsComplete] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [showSummary, setShowSummary] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS[0].value);
  const [activePanel, setActivePanel] = useState(PANELS[0].value);
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [selectedChoiceId, setSelectedChoiceId] =
    useState<Nilable<number>>(questionChoiceId);
  const questionRef = useRef<Nullable<HTMLDivElement>>(null);
  const showDailyStackFeature = Boolean(
    product && FLASHCARDS_PRODUCTS.includes(product) && isAnswered
  );
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const isDemo = useDemo();
  const { disabledHighlighting } = useDisableHighlighting();
  const { setLeaveLabel, setOnLeave } = useExerciseState();
  const ref = useRef<HTMLDivElement>();
  const { expandedReading } = useUserSettings();
  const { index: lightboxIndex } = useLightboxState();

  const maxMarksIndex = marks.length - 1;

  const { isMobile } = useIsMobile();

  const { toggleFlagQuestion } = useFlagQuestion({
    marksheetId: Number(id),
    markId: Number(currentMarkId),
    questionId: Number(question?.id),
  });

  const toggleFlaggedState = useCallback(() => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      toggleFlagQuestion(flagged);
    }
  }, [isDemo, flagged, toggleFlagQuestion, enqueueSnackbar]);

  const handleShowSummary = () => {
    navigate(`${root}/solo/quiz/summary/${id}`);
  };

  const completedTimedTest = isTestMarksheet && isComplete;

  const changeQuestion = useChangeQuestion(Number(id));

  const allAnswered = !marks.some(({ isAnswered }) => !isAnswered);

  const completed = sourceCompleted || allAnswered || completedTimedTest;

  const review = sourceReview || completed || (isTestMarksheet && timeIsUp);
  const participants = review ? users : activeUsers;

  const isSBALikeQuestion =
    typeId === EQuestionType.SINGLE_BEST_ANSWER ||
    typeId === EQuestionType.SBA_WITH_MULTIPLE_CHOICES;

  const isSummaryVisible =
    !review && (showSummary || state === EMarksheetState.RESULT);

  const totalCards = concept?.totalCards ?? 0;
  const enableAddingCardsToStack = totalCards > 0;
  const answeredIncorrectly =
    enableAddingCardsToStack &&
    isAnswered &&
    !isTestMarksheet &&
    checkValidity(marks[questionIndex].questionChoiceId, question) ===
      QuestionAnswerStatus.INVALID;

  const { addedToDailyStack, toggleDailyStack } = useAddToDailyStack(
    Number(conceptId),
    answeredIncorrectly,
    !showDailyStackFeature
  );

  const { handleAddHighlight, handleRemoveHighlight } = useQuestionHighlight(
    Number(id),
    question?.id,
    question.typeId,
    highlights
  );

  const handleToggleDailyStack = (id: number) => () => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.feature);
    } else {
      toggleDailyStack(id);
    }
  };

  const { endLoading, endMarksheet } = useEndMarksheet(
    marksheet,
    solo ? handleShowSummary : undefined
  );

  const handlePanelChange = useCallback(() => {
    setActivePanel(prev =>
      prev === PANELS[0].value ? PANELS[1].value : PANELS[0].value
    );
  }, []);

  const handleLeave = useCallback(() => {
    if (allAnswered) {
      endMarksheet();
    } else {
      const path = baseUrl.includes('group')
        ? `${root}/group/quiz/summary/${sessionId}/${id}`
        : `${root}/solo/quiz/summary/${id}`;

      navigate(path);
    }
  }, [allAnswered, baseUrl, endMarksheet, id, navigate, sessionId]);

  const navigateToQuestion = (newQuestionIndex: number) => {
    const newMark = marks[newQuestionIndex];

    const { id: newMarkId, question: newQuestion } = newMark;
    const { id: newQuestionId } = newQuestion;

    if (!review) {
      const payload: ChangeQuestionArgs = {
        markId: Number(currentMarkId),
        newMarkId: Number(newMarkId),
      };

      if (!review && !completed && (!isAnswered || isTestMarksheet)) {
        payload.timeTaken = calcTimeTaken(startTime, prevTimeTaken);
      }

      changeQuestion(payload);
    }

    if (solo || review) {
      navigate(
        `${baseUrl}/${newMarkId}/${newQuestionId}/${newQuestionIndex + 1}`
      );
    }
  };

  const navigateToNext = async (questionIndex: number) => {
    if (questionIndex < marks.length) {
      return navigateToQuestion(questionIndex);
    } else if (review) {
      navigateToQuestion(0);
    }
  };

  const navigateAfterSave = () => {
    if (isTestMarksheet) {
      navigateToNext(questionIndex + 1);
    }
  };

  const { saveMarksheets, loading: saveLoading } =
    useSaveMarksheets(navigateAfterSave);

  const navigateToIndex = async (questionIndex: number) => {
    navigateToQuestion(questionIndex);
  };

  const handleNavigateNext = () => {
    if (questionIndex < maxMarksIndex) {
      navigateToIndex(questionIndex + 1);
    }
  };

  const handleNavigatePrevious = () => {
    if (questionIndex > 0) {
      navigateToIndex(questionIndex - 1);
    }
  };

  const fullMarkdown = useMemo(
    () => unescape(question.question).replace(/(  +)(\w)/g, ' $2'),
    [question.question]
  );

  const text = useMemo(
    () => addMarkTagsInMarkdown(fullMarkdown, highlights, pictures),
    [fullMarkdown, highlights, pictures]
  );

  const handleSaveAnswer = ({
    choiceId,
    mark,
  }: Pick<ISaveMarksheetInput, 'choiceId' | 'mark'>) => {
    const marksheetInput: ISaveMarksheetInput = {
      marksheetId: Number(id),
      markId: Number(currentMarkId),
      timeTaken: calcTimeTaken(startTime, prevTimeTaken),
    };
    if (choiceId && isSBALikeQuestion) {
      marksheetInput.choiceId = choiceId;
      marksheetInput.mark = JSON.stringify([
        getChoiceAnswer(choiceId, choices),
      ]);
    } else if (mark) {
      marksheetInput.mark = mark;
    }

    return saveMarksheets(marksheetInput, marksheet, questionIndex);
  };

  useEffect(() => {
    if (completed) {
      setIsComplete(completed);
    }
  }, [completed]);

  useEffect(() => {
    setLeaveLabel(
      sourceReview || !allAnswered ? 'Go to Summary' : 'End & Summary'
    );
    setOnLeave(handleLeave);
  }, [allAnswered, handleLeave, setLeaveLabel, setOnLeave, sourceReview]);

  const marksMap = useMemo(() => {
    const map: Map<number, [number, number]> = new Map();

    marks.forEach(({ id }, i) => {
      map.set(id, [i, i + 1]);
    });

    return map;
    // marks changes on every question change, as the map is very simplified
    // we can relay only on marks.length to produce the new map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marks.length]);

  const [pills, pillsFallback] = useMemo(
    () => filterMarks(marks, activeTab),
    [activeTab, marks]
  );

  useEffect(() => {
    if (review || completed) {
      return;
    }

    setStartTime(new Date().getTime());

    return () => {
      setStartTime(0);
    };
  }, [completed, questionIndex, review]);

  useHotkeys(
    KeyboardKey.ArrowRight,
    handleNavigateNext,
    {
      enabled: () => lightboxIndex < 0,
    },
    [handleNavigateNext]
  );

  useHotkeys(
    KeyboardKey.ArrowLeft,
    handleNavigatePrevious,
    {
      enabled: () => lightboxIndex < 0,
    },
    [handleNavigatePrevious]
  );

  // As the useEffect callback is fired after the render
  // to avoid UI flashing if this condition is met we
  // setSelectedChoiceId directly in render
  if (
    !isTestMarksheet &&
    questionChoiceId &&
    questionChoiceId !== selectedChoiceId
  ) {
    setSelectedChoiceId(questionChoiceId);
  }

  useEffect(() => {
    setSelectedChoiceId(questionChoiceId);
  }, [currentMarkId, questionChoiceId]);

  useEffect(() => {
    setUserAnswer(userQuestionAnswer ? JSON.stringify(userQuestionAnswer) : '');
  }, [currentMarkId, userQuestionAnswer]);

  const isShowAnswerBtnEnabled =
    review ||
    ((!isNil(selectedChoiceId) || userAnswer) && !saveLoading) ||
    timeIsUp;

  useEffect(() => {
    if (timeIsUp) {
      setIsComplete(true);
    }
  }, [setIsComplete, timeIsUp]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    if (ref.current) {
      ref.current.scrollTo({ top: 0 });
    }
  }, [questionIndex]);

  usePrealoadImages(marksheet);

  useEffect(() => {
    if (isSummaryVisible && solo) {
      navigate(`${root}/solo/quiz/summary/${id}`);
    }
  }, [id, isSummaryVisible, navigate, solo]);

  const timer = useMemo(() => {
    if (!review && isTestMarksheet) {
      return <QuestionsTimer endedAt={endedAt} setTimeIsUp={setTimeIsUp} />;
    }
  }, [endedAt, isTestMarksheet, review]);

  const showPills = solo || activePanel === PANELS[0].value;

  const controlPanelProps = useMemo(
    () => getControlPanelProps(marks.length, participants.length, showPills),
    [showPills, marks.length, participants.length]
  );

  const showQuizAnswers =
    !isTestMarksheet ||
    isComplete ||
    timeIsUp ||
    (isAnswered && !isTestMarksheet) ||
    review;
  const showCurrentAnswer =
    review || isTestMarksheet ? isComplete || timeIsUp : isAnswered;

  const handleShowAnswer = () => {
    if (saveLoading) {
      enqueueSnackbar('Saving answer...');

      return;
    }

    if (
      selectedChoiceId &&
      choices.some(({ id }) => Number(id) === Number(selectedChoiceId))
    ) {
      return handleSaveAnswer({ choiceId: selectedChoiceId });
    }

    if (userAnswer) {
      return handleSaveAnswer({ mark: userAnswer });
    }
  };

  const handleSummary = () => {
    if (isTestMarksheet && timeIsUp) {
      setShowSummary(true);
    } else {
      endMarksheet();
    }
  };

  const buttonLabel = useMemo(() => {
    if (isTestMarksheet) {
      return isComplete ? 'End & Summary' : 'Next';
    }

    return showCurrentAnswer
      ? isComplete
        ? 'End & Summary'
        : 'Next'
      : 'Check Answer';
  }, [isTestMarksheet, isComplete, showCurrentAnswer]);

  const disabled =
    !isShowAnswerBtnEnabled ||
    (questionIndex === maxMarksIndex && isAnswered && !isComplete);

  const shouldShowAnswer =
    questionChoiceId === null ||
    questionChoiceId !== selectedChoiceId ||
    userAnswer === '' ||
    userAnswer !== userQuestionAnswer;

  const handleButtonClick = () => {
    if (isTestMarksheet) {
      return !showCurrentAnswer
        ? shouldShowAnswer
          ? handleShowAnswer
          : handleNavigateNext
        : handleSummary;
    }

    return showCurrentAnswer
      ? isComplete
        ? handleSummary
        : handleNavigateNext
      : handleShowAnswer;
  };

  useHotkeys(
    KeyboardKey.Space,
    e => {
      if (!saveLoading) {
        handleButtonClick()();
      } else {
        enqueueSnackbar('Saving answer...');
      }
      e.preventDefault();
    },
    {
      enabled: choices.some(
        ({ id }) => Number(id) === Number(selectedChoiceId)
      ),
    }
  );

  if ((questionIndex && questionIndex > marks.length) || !marks.length)
    return <ErrorBox description="Invalid URL" />;

  const flagQuestionHelpers: HelperControl[] = [
    {
      icon: flagged ? <FlagRemoveIcon /> : <FlagOutlineIcon />,
      active: flagged,
      onClick: toggleFlaggedState,
      label: flagged ? 'Remove flag' : 'Flag for later',
    },
  ];

  const dailyStackHelpers: HelperControl[] = [
    {
      icon: addedToDailyStack ? <FlashcardsIcon /> : <RemoveFlashcardsIcon />,
      active: addedToDailyStack,
      onClick: handleToggleDailyStack(question.conceptId),
      label: addedToDailyStack ? 'Remove cards' : 'Add cards',
      tip: addedToDailyStack
        ? 'Remove cards from daily flashcards'
        : 'Add cards to daily flashcards',
    },
  ];

  return (
    <ExerciseLayout
      activeToggleOption={activePanel}
      bottomOffsetVariant={isMobile ? 'medium' : 'tiny'}
      collapsedPanel={false}
      controlPanelContent={
        showPills ? (
          <>
            <ExercisePills
              activeTab={activeTab}
              onTabChange={setActiveTab}
              pillIndex={findPillIndex(pills, Number(currentMarkId))}
              tabs={TABS}
            >
              <QuestionPillsList
                currentPillId={currentMarkId}
                fallback={pillsFallback}
                onClick={navigateToIndex}
                pills={pills}
                pillsMap={marksMap}
                showPillStatus={showQuizAnswers}
              />
            </ExercisePills>
          </>
        ) : (
          <>
            <ParticipantList
              currentUserId={currentUserId}
              participants={participants || []}
              variant="panel"
              withAudio
            />
          </>
        )
      }
      controlPanelScoreProgress={<ScoreProgress marks={marks} />}
      controlPanelTitle={solo ? undefined : 'Control Panel'}
      exerciseContent={
        <>
          <ExerciseContent quizProgressBar ref={ref}>
            <QuizProgressBar
              activeItemNumber={questionIndex + 1}
              leftHelpers={
                showDailyStackFeature && enableAddingCardsToStack
                  ? dailyStackHelpers
                  : undefined
              }
              onClickNext={handleNavigateNext}
              onClickPrev={handleNavigatePrevious}
              rightHelpers={flagQuestionHelpers}
              totalQuestions={marks.length}
            />
            <QuestionContainer ref={questionRef}>
              {typeId === EQuestionType.EXTENDED_MATCHING_ANSWER && (
                <MatchingQuestionChoicesList choices={choices} />
              )}
              {questionContent ? (
                <Markdown
                  enableTextSelection={!disabledHighlighting}
                  fullMarkdown={fullMarkdown}
                  highlights={highlights}
                  onHighlightClick={handleRemoveHighlight}
                  onMouseUp={handleAddHighlight}
                  showCaption={showCurrentAnswer}
                  text={text}
                />
              ) : null}
              {isSBALikeQuestion ? (
                <SingleBestAnswerQuestion
                  mark={currentMark}
                  marksheet={marksheet}
                  questionNumber={questionIndex + 1}
                  selectedChoiceId={selectedChoiceId}
                  setSelectedChoiceId={setSelectedChoiceId}
                  showAnswers={showCurrentAnswer}
                  solo={solo}
                />
              ) : null}
              {typeId === EQuestionType.RANKING_ANSWER && (
                <RankingQuestion
                  mark={currentMark}
                  setUserAnswer={setUserAnswer}
                  showAnswers={showCurrentAnswer}
                />
              )}
              {typeId === EQuestionType.SELECT_THREE_ANSWER && (
                <SelectThreeQuestion
                  mark={currentMark}
                  setUserAnswer={setUserAnswer}
                  showAnswers={showCurrentAnswer}
                />
              )}
              {typeId === EQuestionType.EXTENDED_MATCHING_ANSWER && (
                <MatchingQuestion
                  mark={currentMark}
                  setUserAnswer={setUserAnswer}
                  showAnswers={showCurrentAnswer}
                />
              )}
              {disabled || isMobile ? null : (
                <ButtonContainer>
                  <StyledButton
                    disabled={disabled}
                    loading={showCurrentAnswer ? endLoading : saveLoading}
                    onClick={
                      sourceCompleted ? handleNavigateNext : handleButtonClick()
                    }
                  >
                    {buttonLabel}
                    <SpaceBarHotKey />
                  </StyledButton>
                </ButtonContainer>
              )}
              <QuestionRelatedContent
                entitlementId={entitlement?.id as unknown as EEntitlementType}
                expandedReading={expandedReading}
                marksheetId={Number(id)}
                question={question}
                questionType={typeId}
                showAnswers={showCurrentAnswer}
              />
              {showCurrentAnswer ? (
                <ButtonContainer>
                  <StyledButton
                    disabled={disabled}
                    loading={showCurrentAnswer ? endLoading : saveLoading}
                    onClick={
                      sourceCompleted ? handleNavigateNext : handleButtonClick()
                    }
                  >
                    {buttonLabel}
                    <SpaceBarHotKey />
                  </StyledButton>
                </ButtonContainer>
              ) : null}
            </QuestionContainer>
          </ExerciseContent>
          {isMobile ? (
            <ExerciseBottomBar
              primaryControl={
                <>
                  {sourceCompleted ? (
                    <ExerciseRviewNavigation
                      nextDisabled={questionIndex + 1 === marks.length}
                      onClickNext={handleNavigateNext}
                      onClickPrev={handleNavigatePrevious}
                      prevDisabled={questionIndex === 0}
                    />
                  ) : (
                    <ExerciseLaunchedButton
                      disabled={disabled}
                      loading={showCurrentAnswer ? endLoading : saveLoading}
                      onClick={handleButtonClick()}
                    >
                      {buttonLabel}
                    </ExerciseLaunchedButton>
                  )}
                </>
              }
              quizProgressBar
            />
          ) : null}
        </>
      }
      noOverflow
      onLeave={solo ? undefined : handleLeave}
      onToggle={solo ? undefined : handlePanelChange}
      timer={timer}
      toggleLabel={solo ? undefined : 'Questions and Participants'}
      toggleOptions={solo ? undefined : PANELS}
      {...controlPanelProps}
      withSound={!solo}
    />
  );
};

export default QuestionsQuiz;
