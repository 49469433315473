import { useMutation } from '@apollo/client';
import {
  IMarkOsceMarksheetMarkData,
  IMarkOsceMarksheetMarkVar,
  MARK_OSCE_MARKSHEET_MARK,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useMarkOsceMarksheetMark = () => {
  const [markOsceMark, { loading }] = useMutation<
    IMarkOsceMarksheetMarkData,
    IMarkOsceMarksheetMarkVar
  >(MARK_OSCE_MARKSHEET_MARK);

  return {
    markOsceMark,
    loading,
  };
};

export default useMarkOsceMarksheetMark;
