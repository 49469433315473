import React, { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IQuestionSBA } from '@quesmed/types-rn/models';

import { calcVotesPercentage, getChoiceAnswer } from 'utils';
import QuestionChoices from 'components/QuestionChoices/QuestionChoices';
import { KeyboardKey } from 'types';

export interface SingleBestAnswerProps {
  question: IQuestionSBA;
  questionChoiceId: number | null;
  isTestFinished: boolean;
  setUserAnswer: (answer: string) => void;
  selectedChoiceId: number | undefined;
  setSelectedId: (id: number) => void;
  questionNumber: number;
  isAnswered: boolean;
}

const SingleBestAnswer = ({
  isAnswered,
  isTestFinished,
  question,
  questionChoiceId,
  questionNumber,
  setUserAnswer,
  selectedChoiceId,
  setSelectedId,
}: SingleBestAnswerProps): JSX.Element => {
  const { choices, totalVotes } = question;
  const handleChange = useCallback(
    (id: number) => {
      setSelectedId(id);
      setUserAnswer(JSON.stringify([getChoiceAnswer(id, choices)]));
    },
    [setSelectedId, setUserAnswer, choices]
  );

  useEffect(() => {
    if (selectedChoiceId) {
      const answer = getChoiceAnswer(selectedChoiceId, choices);

      if (answer) {
        setUserAnswer(JSON.stringify([answer]));
      }
    }
  }, [selectedChoiceId, choices, setUserAnswer]);

  const handleChangeById = useCallback(
    (id: number) => () => {
      handleChange(Number(id));
    },
    [handleChange]
  );

  useHotkeys(KeyboardKey.One, handleChangeById(choices[0].id), [
    handleChangeById,
  ]);
  useHotkeys(KeyboardKey.Two, handleChangeById(choices[1].id), [
    handleChangeById,
  ]);
  useHotkeys(KeyboardKey.Three, handleChangeById(choices[2].id), [
    handleChangeById,
  ]);
  useHotkeys(KeyboardKey.Four, handleChangeById(choices[3].id), [
    handleChangeById,
  ]);
  useHotkeys(KeyboardKey.Five, handleChangeById(choices[4].id), [
    handleChangeById,
  ]);

  const showChoiceAnswer =
    isTestFinished &&
    (selectedChoiceId === undefined || questionChoiceId !== null);

  const votes = calcVotesPercentage(
    choices,
    totalVotes,
    showChoiceAnswer ? selectedChoiceId : undefined
  );

  return (
    <>
      <QuestionChoices
        choices={choices}
        isAnswered={isAnswered}
        name={`Question ${questionNumber}, (id: ${question.id})`}
        onChange={handleChange}
        selectedChoiceId={selectedChoiceId}
        showAnswers={isTestFinished}
        votes={votes}
      />
    </>
  );
};

export default SingleBestAnswer;
