import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { PollIcon } from 'components/Icons';
import { constSize } from 'utils';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  height: '40px',
  alignItems: 'center',

  '.MuiSvgIcon-root': {
    minHeight: '40px',
    minWidth: '40px',
  },
}));

const IconBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '13px',
  justifyContent: 'center',
  backgroundColor: palette.primary.extraLight,
  color: palette.primary.main,
  ...constSize('40px'),
}));

const StyledIcon = styled(PollIcon)(() => ({
  height: '24px',
  width: '24px',
}));

const StyledTitle = styled(Box)(({ theme: { spacing } }) => ({
  fontWeight: 400,
  fontSize: '20px',
  margin: spacing(0, 0, 0, 4),
}));

const ActivityHeader = (): JSX.Element => (
  <Wrapper>
    <IconBox>
      <StyledIcon />
    </IconBox>
    <StyledTitle>Activity</StyledTitle>
  </Wrapper>
);

export default ActivityHeader;
