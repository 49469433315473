import { Chart as ChartJS } from 'chart.js';
import React, { useRef } from 'react';
import { Skeleton, styled } from '@mui/material';
import Box from '@mui/material/Box';

import { Card } from 'components/Card';
import { QBankBarchart } from 'components/Graphs/QBankBarchart';
import TrackerCardHeader from './TrackerCardHeader';
import { ActivityData, ExerciseMode } from 'types';
import { usePlatform } from 'context/PlatformContext';
import OsceBarchart from 'components/Graphs/OsceBarchart/OsceBarchart';
import CardTableController from './CardTableController';
import { isStationActivity } from 'utils';
import SkeletonList from 'components/Skeleton/SkeletonList';

const GraphSection = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(4),

  [breakpoints.up('md')]: {
    padding: spacing(6, 8, 8),
  },
}));

interface ITrackerCard {
  setExerciseMode: (mode: ExerciseMode) => void;
  exerciseMode: ExerciseMode;
  clickedBar: string;
  setClickedBar: (title: string) => void;
  data: ActivityData;
  loading: boolean;
}

const TrackerCard = ({
  clickedBar,
  setClickedBar,
  setExerciseMode,
  exerciseMode,
  data,
  loading,
}: ITrackerCard): JSX.Element => {
  const { product } = usePlatform();
  const chartRef = useRef<ChartJS<'line', number[], string>>(null);

  const switchGraphs = () =>
    isStationActivity(data) ? (
      <OsceBarchart
        chartRef={chartRef}
        clickedBar={clickedBar}
        data={data}
        onBarClick={setClickedBar}
      />
    ) : (
      <QBankBarchart
        chartRef={chartRef}
        clickedBar={clickedBar}
        data={data}
        onBarClick={setClickedBar}
      />
    );

  const noData = !data.some(({ result }) => result?.length);

  return (
    <Card>
      <GraphSection>
        <TrackerCardHeader
          exerciseMode={exerciseMode}
          noData={noData}
          selectedDate={clickedBar}
          setMode={setExerciseMode}
        />
        {loading ? <Skeleton height={275} /> : !noData ? switchGraphs() : null}
      </GraphSection>
      {loading ? (
        <SkeletonList count={4} height={75} spacing={1} />
      ) : (
        <CardTableController
          clickedBar={clickedBar}
          data={data}
          exerciseMode={exerciseMode}
          product={product}
        />
      )}
    </Card>
  );
};

export default TrackerCard;
