import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  EOsceMarksheetAction,
  EOsceMarksheetState,
} from '@quesmed/types-rn/models';
import {
  IModifyOsceMarksheetData,
  IModifyOsceMarksheetVar,
  MODIFY_OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useModifyOsceMarksheet = () => {
  const [modifyMarksheet, { loading }] = useMutation<
    IModifyOsceMarksheetData,
    IModifyOsceMarksheetVar
  >(MODIFY_OSCE_MARKSHEET);

  const modifyOsceMarksheet = useCallback(
    (
      action: EOsceMarksheetAction,
      osceMarksheetId: number,
      state?: EOsceMarksheetState
    ) => {
      modifyMarksheet({
        variables: {
          input: {
            action,
            osceMarksheetId: Number(osceMarksheetId),
            state,
          },
        },
      });
    },
    [modifyMarksheet]
  );

  return {
    modifyOsceMarksheet,
    loading,
  };
};

export default useModifyOsceMarksheet;
