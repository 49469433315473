export function calculatePercent(percent: number, total: number) {
  if (total === 0) {
    return 0;
  }
  let actualPercent: number;
  actualPercent = (percent * 100) / total;
  if (isNaN(actualPercent)) {
    actualPercent = 0;
  }

  return actualPercent;
}
