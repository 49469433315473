import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import {
  EProductType,
  IOsceStats,
  IProductStats,
} from '@quesmed/types-rn/models';

import { useSnackbar } from 'components/Snackbar';
import { Body } from 'components/Typography';
import { RocketLaunchIcon } from 'components/Icons';
import { ProgressLineChart } from 'components/ProgressLineChart';
import { SkeletonList } from 'components/Skeleton';
import { paths } from 'Router';
import {
  WidgetCard,
  WidgetCardType,
} from 'pages/dashboard/components/WidgetCard';
import PerformanceItemCard from './PerformanceItemCard';
import { ItemStatusLabel } from 'types';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct } from 'utils';
import { Button } from 'components/Button';
import { useQuickMarksheet } from './useQuickMarksheet';

const { questions, stations, videoLibrary } = paths;

const SkeletonWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(8),
  marginTop: spacing(8),
}));

const ProgressLine = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(8, 0, 2),
}));

const ProgressInfo = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const PerformanceItems = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  height: '100%',
  overflow: 'auto',
  margin: spacing(0, -4),
  padding: spacing(0, 4, 4),

  [breakpoints.up('md')]: {
    height: '100%',
    margin: spacing(0, -6),
    padding: spacing(0, 6, 6),
  },
}));

const ContentWrapper = styled(Box)(({ theme: { spacing } }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  height: '100%',
  justifyContent: 'space-between',
}));

const SecondaryBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const ActionButtons = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
}));

interface PerformanceProps {
  data?: IOsceStats | IProductStats;
  loading: boolean;
  withTopics?: boolean;
}

const Performance = ({
  data,
  loading,
  withTopics,
}: PerformanceProps): JSX.Element => {
  const { product } = usePlatform();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const { startQuickMarksheet, loading: quickStartLoading } =
    useQuickMarksheet();
  const {
    completedQuestions,
    correctQuestions,
    incorrectQuestions,
    totalQuestions,
  } = (data as IProductStats) || {};
  const { completed, total } = (data as IOsceStats) || {};

  const getAverageScore = () => {
    if (!correctQuestions || !completedQuestions) {
      return 0;
    }

    return ((correctQuestions * 100) / (completedQuestions || 1)).toFixed(1);
  };

  const qbankData = [
    {
      type: ItemStatusLabel.Confident,
      value: correctQuestions,
      label: ItemStatusLabel.Correct,
    },
    {
      type: ItemStatusLabel.ToLearn,
      value: incorrectQuestions,
      label: ItemStatusLabel.Incorrect,
    },
  ];

  const osceData = [
    {
      type: ItemStatusLabel.Confident,
      value: completed,
      label: ItemStatusLabel.Completed,
    },
  ];

  const isStations = isStationsProduct(product);
  const isPaces = product === EProductType.PACES;

  const handleQuickStart = useCallback(() => {
    startQuickMarksheet();
  }, [startQuickMarksheet]);

  const handleClick = useCallback(
    (category: number) => () => {
      if (quickStartLoading) {
        enqueueSnackbar('Starting questions in progress...');
      } else {
        const path = isStations ? stations.root : questions.root;

        const pacesPath = `${videoLibrary.root}/section/${category}`;
        navigate(isPaces ? pacesPath : path, { state: { category } });
      }
    },
    [enqueueSnackbar, isPaces, isStations, navigate, quickStartLoading]
  );

  const handleSelectTopics = () => {
    const path = isStations ? stations.root : questions.root;
    navigate(path);
  };

  const title = isStations ? (isPaces ? 'Videos' : 'Stations') : 'Questions';

  return (
    <WidgetCard
      cardIcon={<RocketLaunchIcon />}
      content={
        loading ? (
          <SkeletonList count={2} height={48} spacing={4} />
        ) : (
          <ContentWrapper>
            <ProgressLine>
              <ProgressLineChart
                data={isStations ? osceData : qbankData}
                performanceWidget
                title={title}
                total={isStations ? total : totalQuestions}
              />
              <ProgressInfo>
                <SecondaryBody small>
                  <strong>
                    {isStations ? completed : completedQuestions}&nbsp;
                  </strong>
                  {isStations
                    ? isPaces
                      ? 'videos watched'
                      : 'stations attempted'
                    : 'questions answered'}
                </SecondaryBody>
                <SecondaryBody small>
                  out of total {isStations ? total : totalQuestions}
                </SecondaryBody>
              </ProgressInfo>
              {isStations ? null : (
                <ProgressInfo>
                  <SecondaryBody small>Avg. score</SecondaryBody>
                  <SecondaryBody small sx={{ fontWeight: 'bold' }}>
                    {getAverageScore()}%
                  </SecondaryBody>
                </ProgressInfo>
              )}
            </ProgressLine>
            <ActionButtons>
              {!isStations ? (
                <Button
                  fullWidth
                  loading={quickStartLoading}
                  onClick={handleQuickStart}
                >
                  Start
                </Button>
              ) : null}
              {withTopics ? null : (
                <Button fullWidth onClick={handleSelectTopics} secondary>
                  Select Topics
                </Button>
              )}
            </ActionButtons>
            {withTopics ? (
              <PerformanceItems>
                {data?.types?.map(type => (
                  <PerformanceItemCard
                    data={type}
                    key={type?.id || type?.name}
                    onClick={handleClick(type?.id)}
                    product={product}
                  />
                ))}
              </PerformanceItems>
            ) : null}
          </ContentWrapper>
        )
      }
      fullWidth
      loading={loading}
      loadingSkeleton={
        <SkeletonWrapper>
          <Skeleton height={40} variant="rounded" width="100%" />
          {withTopics ? (
            <SkeletonList count={3} height={74} spacing={4} variant="rounded" />
          ) : (
            <Skeleton height={40} variant="rounded" width="100%" />
          )}
        </SkeletonWrapper>
      }
      overflow
      title={title}
      type={WidgetCardType.Questions}
    />
  );
};

export default Performance;
