import { IQuestion } from '@quesmed/types-rn/models';

import { QuestionAnswerStatus } from 'types';
import calculateValidityObject from './calculateValidityObject';

const checkValidity = (
  choiceId: number | null,
  question: IQuestion
): QuestionAnswerStatus => {
  let answer = QuestionAnswerStatus.CLEAN;

  if (choiceId === null) {
    return answer;
  }

  const { choices } = question;
  const index = choices.findIndex(({ id }) => Number(choiceId) === Number(id));

  for (const [key, value] of Object.entries(
    calculateValidityObject(index, question)
  )) {
    if (index === parseInt(key)) {
      answer = value;
    }
  }

  return answer;
};

export default checkValidity;
