import React from 'react';

import { SpaceBarIcon } from 'components/Icons';
import HotKey, { HotKeyProps } from './HotKey';
import { constSize } from 'utils/index';

type SpaceBarHotKeyProps = Omit<HotKeyProps, 'hotKey'>;

const SpaceBarHotKey = ({ ...props }: SpaceBarHotKeyProps): JSX.Element => (
  <HotKey
    sx={({ spacing }) => ({ ...constSize(spacing(13), spacing(4)) })}
    {...props}
  >
    <SpaceBarIcon viewBox="0 6 24 24" />
  </HotKey>
);

export default SpaceBarHotKey;
