const parseMarkNumber = (markNumber: any, max: number) => {
  if (!markNumber) {
    return 1;
  }

  const number = Number(markNumber);

  if (!Number.isInteger(Number(number))) {
    return 1;
  }

  if (number <= 0) {
    return 1;
  }

  if (number > max) {
    return max;
  }

  return number;
};

export default parseMarkNumber;
