import { useQuery } from '@apollo/client';
import {
  IProductStatsData,
  PRODUCT_STATS,
} from '@quesmed/types-rn/resolvers/query/restricted';

export const usePerformance = () => {
  const { data, loading, error } = useQuery<IProductStatsData>(PRODUCT_STATS, {
    fetchPolicy: 'cache-and-network',
  });

  const { productStats } = data?.restricted || {};

  return { data: productStats, error, loading };
};
