import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ExerciseSummaryImage } from 'components/Illustrations';
import { Body, H4 } from 'components/Typography';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: spacing(4),
}));

export interface ExerciseSummaryHeaderProps {
  headerTitle?: string;
  headerSubtitle?: string;
}

const ExerciseSummaryHeader = ({
  headerTitle = 'Exercise completed!',
  headerSubtitle,
}: ExerciseSummaryHeaderProps): JSX.Element => (
  <Container>
    <ExerciseSummaryImage />
    <H4>{headerTitle}</H4>
    {headerSubtitle ? <Body>{headerSubtitle}</Body> : null}
  </Container>
);

export default ExerciseSummaryHeader;
