import { useQuery } from '@apollo/client';
import {
  DASHBOARD_OSCE,
  IDashboardOsceData,
  IDashboardOsceVar,
} from '@quesmed/types-rn/resolvers/query/restricted';

const useOsceDashboardQuery = () => {
  const { data, loading } = useQuery<IDashboardOsceData, IDashboardOsceVar>(
    DASHBOARD_OSCE,
    {
      variables: {
        solo: true,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { dashboardOsce } = data?.restricted || {};

  return { data: dashboardOsce, loading };
};

export default useOsceDashboardQuery;
