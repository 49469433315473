import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import clsx from 'clsx';

import { FLASHCARD_SCORES } from 'config/constants';
import ScoreButton from './ScoreButton';
import { Nilable } from 'types';

const ScoreButtonsContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    width: '100%',
    gap: spacing(4),
    display: 'grid',
    justifyItems: 'center',

    '&.bottom-bar': {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },

    [breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(5, minmax(100px, 155px))',
    },
  })
);

const setClassName = (score: number, activeScore: Nilable<number>): string => {
  if (isNil(activeScore)) {
    return 'initial';
  }

  return activeScore === score ? 'active' : 'inactive';
};

interface ScoreButtonsProps {
  cardScore: Nilable<number>;
  loading: boolean;
  onClick: (score: number) => void;
  isBottomBar?: boolean;
}

const ScoreButtons = ({
  loading,
  cardScore,
  onClick,
  isBottomBar,
}: ScoreButtonsProps): JSX.Element => {
  const [activeScore, setActiveScore] = useState<Nilable<number>>(cardScore);

  const handleSelectScore = (score: number) => () => {
    if (isNil(cardScore)) {
      setActiveScore(score);
      onClick(score);
    }
  };

  useEffect(() => {
    if (!isNil(cardScore)) {
      setActiveScore(cardScore);
    }
  }, [cardScore]);

  return (
    <ScoreButtonsContainer className={clsx({ 'bottom-bar': isBottomBar })}>
      {FLASHCARD_SCORES.map(({ label, score }) => (
        <ScoreButton
          className={setClassName(score, activeScore)}
          disabled={loading}
          isBottomBar={isBottomBar}
          key={label}
          label={label}
          loading={activeScore === score && loading}
          onClick={handleSelectScore(score)}
          score={score}
        />
      ))}
    </ScoreButtonsContainer>
  );
};

export default ScoreButtons;
