import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  EAppType,
  EEntitlementType,
  EProductType,
  EUserLearningStatus,
  IConcept,
  IHighlightNode,
  IPicture,
  IUserConceptNote,
  IVideo,
} from '@quesmed/types-rn/models';
import { clsx } from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import QuestionsPreBuildModal from 'pages/Questions/QuestionsPreBuildModal';
import FlashcardsPreBuildModal from 'pages/Flashacards/FlashcardsPreBuildModal';
import {
  ComplementaryReadings,
  ExerciseType,
  Nullable,
  PreBuildData,
} from 'types';
import ContentHeader from 'components/ContentHeader/ContentHeader';
import {
  CollapseVerticalIcon,
  ExpandVerticalIcon,
  FlashcardsIcon,
  QuestionsIcon,
} from 'components/Icons';
import {
  Buttons,
  Container,
  ExerciseInvitationContainer,
  LearningMaterialsBreadcrumbsProps,
  ReadingBriefTitle,
  RelatedVideos,
  StatusMenu,
} from 'components/LearningMaterials/components';
import { useUpdateConceptLearningStatus } from 'components/LearningMaterials/hooks';
import { parseQbankReading } from 'components/LearningMaterials/utils';
import { ExpandableSection } from 'components/ExpandableSection';
import { addMarkTagsInMarkdown, isVideoLibraryPage, unescape } from 'utils';
import { Markdown } from 'components/Markdown';
import { UserNotes, UserNotesProps } from 'components/UserNotes';
import { Button } from 'components/Button';
import { Body } from 'components/Typography';
import { usePlatform } from 'context/PlatformContext';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import useChapterHighlight from '../hooks/useChapterHighlight';
import { useDisableHighlighting } from 'hooks/useDisableHighlighting';
import { paths } from 'Router';

const { knowledgeLibrary } = paths;

interface QbankConceptReadingProps extends Pick<UserNotesProps, 'onSaveNotes'> {
  autoFocus?: boolean;
  concept?: IConcept;
  topicName?: string;
  topicId?: number;
  conceptName: string;
  videos?: IVideo[];
  pictures?: IPicture[];
  entitlementId?: EEntitlementType;
  explanation?: Nullable<string>;
  showAll?: boolean;
  conceptId: number;
  status?: Nullable<EUserLearningStatus>;
  breadcrumbs?: LearningMaterialsBreadcrumbsProps['items'];
  hideExerciseInvitation?: boolean;
  hideFlashcardInvitation?: boolean;
  nested?: boolean;
  withQuestionImage?: boolean;
  userNote?: Nullable<IUserConceptNote>;
  marksheetId?: number;
  chapterId?: number;
  chapterHighlights?: IHighlightNode[];
  isSample?: boolean;
  expandedReading?: boolean;
}

const DESCRIPTION = 'Description';

const QbankConceptReading = ({
  conceptId,
  topicId,
  status,
  entitlementId,
  expandedReading,
  explanation,
  conceptName,
  onSaveNotes,
  pictures,
  showAll,
  videos,
  nested,
  breadcrumbs,
  hideFlashcardInvitation = false,
  hideExerciseInvitation = false,
  withQuestionImage = false,
  userNote,
  marksheetId,
  chapterId,
  chapterHighlights = [],
  isSample = false,
}: QbankConceptReadingProps): JSX.Element => {
  const { state, pathname } = useLocation<{ fromMyNotes: boolean } | null>();
  const isDemo = useDemo();
  const { app, product } = usePlatform();
  const isMSRA = app === EAppType.MSRA;
  const isPaces = product === EProductType.PACES;
  const isMRCP =
    product === EProductType.MRCP_PART1 || product === EProductType.MRCP_PART2;
  const hideOnMRCPVideoLibrary = isMRCP && isVideoLibraryPage(pathname);
  const [expandedBriefs, setExpandedBriefs] = useState<number[]>([]);
  const { updateStatus } = useUpdateConceptLearningStatus();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const { handleAddHighlight, handleRemoveHighlight } = useChapterHighlight(
    Number(marksheetId),
    Number(chapterId),
    chapterHighlights,
    isSample
  );
  const notesRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { disabledHighlighting } = useDisableHighlighting();
  const navigate = useNavigate();

  const showDemoMessage = () => {
    enqueueSnackbar(locales.common.demo.feature);
  };

  const briefs = useMemo(
    () => (explanation ? parseQbankReading(explanation) : []),
    [explanation]
  );

  const relatedVideosIndex = briefs.findIndex(
    brief => brief.title === ComplementaryReadings.RelatedVideos
  );

  const notesIndex = briefs.length + 1;

  const allExpanded = briefs.length <= expandedBriefs.length;

  const handleExpandAll = useCallback(
    () =>
      setExpandedBriefs([
        ...Array.from({ length: briefs.length }, (value, index) => index),
        relatedVideosIndex,
        notesIndex,
      ]),
    [briefs.length, notesIndex, relatedVideosIndex]
  );

  const handleCollapseAll = () => setExpandedBriefs([]);

  const handleExpandBrief = (idx: number) => () => {
    const newExpandedBriefs = expandedBriefs.includes(idx)
      ? expandedBriefs.filter(i => i !== idx)
      : [...expandedBriefs, idx];
    setExpandedBriefs(newExpandedBriefs);
  };

  useEffect(() => {
    if (conceptId && !showAll) {
      setExpandedBriefs([]);
    }
  }, [conceptId, showAll]);

  useEffect(() => {
    if (briefs.length) {
      if (expandedReading) {
        handleExpandAll();

        return;
      }
      setExpandedBriefs([0]);
    }
  }, [briefs, handleExpandAll, expandedReading]);

  const selection: PreBuildData = new Set([Number(conceptId)]);

  const [showQuestionsPrebuildModal, setShowQuestionsPrebuildModal] =
    useState(false);
  const [showFlashcardsPrebuildModal, setShowFlashcardsPrebuildModal] =
    useState(false);

  const handleShowQuestionsPrebuildModal = () => {
    if (isDemo || isSample) {
      showDemoMessage();
    } else {
      setShowQuestionsPrebuildModal(true);
    }
  };

  const handleCloseQuestionsPrebuildModal = () =>
    setShowQuestionsPrebuildModal(false);

  const handleShowFlashcardsPrebuildModal = () => {
    if (isDemo || isSample) {
      showDemoMessage();
    } else {
      setShowFlashcardsPrebuildModal(true);
    }
  };

  const handleCloseFlashcardsPrebuildModal = () => {
    setShowFlashcardsPrebuildModal(false);
  };

  const handleStatusChange = async (
    newStatus: EUserLearningStatus,
    ids: number[]
  ) => {
    if (isSample) {
      showDemoMessage();
    } else {
      updateStatus(ids, newStatus, Number(topicId), status);
    }
  };

  const handleSaveNotes = (notes: string) => {
    if (isSample) {
      showDemoMessage();
    } else {
      onSaveNotes?.(notes);
    }
  };

  const handleEditClick = () => {
    navigate(
      `${knowledgeLibrary.root}/chapter/${entitlementId}/${topicId}/${conceptId}/edit`
    );
  };

  useEffect(() => {
    if (state?.fromMyNotes && notesRef.current) {
      setExpandedBriefs(prev => [...prev, notesIndex]);
      setTimeout(() => {
        notesRef?.current?.scrollIntoView();
      }, 1000); // Wait for expandable sections to fully expand (animation)
    }
  }, [notesIndex, state?.fromMyNotes]);

  return (
    <Container className={clsx({ nested })} ref={ref} tabIndex={0}>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        buttonIcon={allExpanded ? CollapseVerticalIcon : ExpandVerticalIcon}
        buttonText={allExpanded ? 'Hide All' : 'Show All'}
        className="content-header"
        learningStatus={
          <StatusMenu
            id={conceptId}
            isSample={isSample}
            onStatusChange={handleStatusChange}
            status={status}
          />
        }
        onButtonClick={allExpanded ? handleCollapseAll : handleExpandAll}
        onEditClick={handleEditClick}
        scallable
        title={conceptName}
      />
      {briefs.map(({ title = DESCRIPTION, content }, idx) => {
        const fullMarkdown = unescape(content).replace(/(  +)(\w)/g, ' $2');

        const text = addMarkTagsInMarkdown(
          fullMarkdown,
          chapterHighlights,
          pictures,
          withQuestionImage
        );

        const additionalProps = marksheetId
          ? {
              enableTextSelection: !disabledHighlighting,
              fullMarkdown,
              highlights: chapterHighlights,
              onHighlightClick: handleRemoveHighlight,
              onMouseUp: handleAddHighlight,
            }
          : {};

        // Number 2 came from the edge case when we receive '\n' from backend as a content
        // and because of that we are checking length to be ensured that user will not see empty brief
        return (
          content.length > 2 && (
            <ExpandableSection
              expanded={expandedBriefs.includes(idx)}
              key={title + idx}
              nested={nested}
              onToggle={handleExpandBrief(idx)}
              size="base"
              title={<ReadingBriefTitle title={title} />}
            >
              <Markdown
                hasOverlay={Boolean(pictures?.[idx]?.overlayPath)}
                text={text}
                {...additionalProps}
              />
            </ExpandableSection>
          )
        );
      })}
      {videos?.length && !isMSRA ? (
        <ExpandableSection
          expanded={expandedBriefs.includes(relatedVideosIndex)}
          nested={nested}
          onToggle={handleExpandBrief(relatedVideosIndex)}
          size="base"
          title={
            <ReadingBriefTitle title={ComplementaryReadings.RelatedVideos} />
          }
        >
          <RelatedVideos
            conceptId={conceptId}
            entitlementId={entitlementId}
            isSample={isSample}
            topicId={topicId}
            videos={videos}
          />
        </ExpandableSection>
      ) : null}
      {onSaveNotes ? (
        <ExpandableSection
          expanded={expandedBriefs.includes(notesIndex)}
          nested={nested}
          onToggle={handleExpandBrief(notesIndex)}
          ref={notesRef}
          size="base"
          title={<ReadingBriefTitle title={ComplementaryReadings.MyNotes} />}
        >
          <UserNotes
            learningMaterials
            notes={userNote}
            onSaveNotes={handleSaveNotes}
          />
        </ExpandableSection>
      ) : null}
      {hideExerciseInvitation || isPaces || hideOnMRCPVideoLibrary ? null : (
        <ExerciseInvitationContainer>
          <Body bold>
            Ready to practice? Start questions or flashcards below related to
            the article you&apos;ve just read.
          </Body>
          <Buttons>
            <Button onClick={handleShowQuestionsPrebuildModal} secondary>
              <QuestionsIcon />
              Questions
            </Button>
            {hideFlashcardInvitation ? null : (
              <Button onClick={handleShowFlashcardsPrebuildModal} secondary>
                <FlashcardsIcon />
                Flashcards
              </Button>
            )}
          </Buttons>
        </ExerciseInvitationContainer>
      )}
      {showQuestionsPrebuildModal ? (
        <QuestionsPreBuildModal
          entitlementId={entitlementId}
          onClose={handleCloseQuestionsPrebuildModal}
          open={showQuestionsPrebuildModal}
          selection={selection}
          source={`${conceptName} ${ExerciseType.Questions}`}
        />
      ) : null}
      {showFlashcardsPrebuildModal ? (
        <FlashcardsPreBuildModal
          entitlementId={entitlementId}
          onClose={handleCloseFlashcardsPrebuildModal}
          open={showFlashcardsPrebuildModal}
          selection={selection}
          source={`${conceptName} ${ExerciseType.Flashcards}`}
        />
      ) : null}
    </Container>
  );
};

export default QbankConceptReading;
