import { useQuery } from '@apollo/client';
import {
  CONCEPT,
  IConceptData,
  IConceptVar,
} from '@quesmed/types-rn/resolvers/query/restricted';

interface ConceptParams {
  conceptId: number;
  skip?: boolean;
}

export const useConcept = ({ conceptId }: ConceptParams) => {
  const { data, loading } = useQuery<IConceptData, IConceptVar>(CONCEPT, {
    variables: { id: conceptId },
  });

  const { concept } = data?.restricted || {};

  return { concept, loading };
};

export default useConcept;
