import React, { ComponentType } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import {
  CheckboxCorrectIcon,
  CheckboxIncorrectIcon,
  CheckboxMarkedIcon,
  IconProps,
} from 'components/Icons';

const getCheckboxIcon = (
  isTestFinished: boolean,
  valid: boolean,
  isChecked: boolean,
  isPreview: boolean
): [string, ComponentType<IconProps>] => {
  if (isPreview) {
    return ['unanswered', CheckboxCorrectIcon];
  }

  if (!isTestFinished) {
    return ['marked', CheckboxMarkedIcon];
  }
  if (valid) {
    if (isChecked) {
      return ['correct', CheckboxCorrectIcon];
    }

    return ['unanswered', CheckboxCorrectIcon];
  } else if (isChecked) {
    return ['incorrect', CheckboxIncorrectIcon];
  }

  return ['unanswered', CheckboxCorrectIcon];
};

interface CheckboxIconProps {
  isTestFinished: boolean;
  valid: boolean;
  isChecked: boolean;
  isPreview: boolean;
}

const CheckboxIconWrapper = styled(Box)(({ theme: { palette } }) => ({
  '&.correct': {
    color: palette.success.main,
  },
  '&.incorrect': {
    color: palette.error.main,
  },
  '&.unanswered': {
    color: palette.icon.main,
  },
}));

const MultiAnswerCheckboxIcon = ({
  isTestFinished,
  valid,
  isChecked,
  isPreview,
}: CheckboxIconProps) => {
  const [classname, Icon] = getCheckboxIcon(
    isTestFinished,
    valid,
    isChecked,
    isPreview
  );

  return (
    <CheckboxIconWrapper className={classname}>
      <Icon />
    </CheckboxIconWrapper>
  );
};

export default MultiAnswerCheckboxIcon;
