import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EProductType, EUserLearningStatus } from '@quesmed/types-rn/models';
import clsx from 'clsx';

import { Nullable } from 'types';
import ItemRow from './ItemRow';
import FilterFallback from './FilterFallback';
import { LearningMaterialItems } from '../types';
import { usePlatform } from 'context/PlatformState';
import { getPacesLibraryName } from '../utils';

export const ItemsListCantainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    borderRadius: '0 0 7px 7px',
    overflow: 'auto',
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    margin: spacing(0, -6),
    width: `calc(100% + ${spacing(12)})`,
    display: 'flex',
    flexDirection: 'column',

    '&.no-bottom': {
      borderRadius: 0,
    },

    [breakpoints.up('md')]: {
      width: '100%',
      margin: 'initial',
    },

    [breakpoints.up('xl')]: {
      overflow: 'hidden',
    },
  })
);

const Section = styled(Box)(({ theme: { palette, spacing } }) => ({
  paddingBottom: spacing(8),

  '&.no-bottom': {
    paddingBottom: 0,
  },

  '&:not(:nth-last-of-type(1))': {
    borderBottom: `1px solid ${palette.stroke.main}`,
  },
}));

interface ItemsProps<T> {
  itemsMap: Nullable<LearningMaterialItems>;
  title: string;
  id?: number;
  showFallback?: boolean;
  navigate: (id: number) => () => void;
  onStatusChange?: (
    newStatus: EUserLearningStatus,
    ids: number[],
    currentStatus?: Nullable<EUserLearningStatus>
  ) => Promise<T>;
  onTitleClick?: (topicid: Nullable<number>) => () => void;
  video?: boolean;
  noBottom?: boolean;
}

function Items<T>({
  itemsMap,
  navigate,
  title,
  onStatusChange,
  showFallback,
  video,
  id,
  onTitleClick,
  noBottom,
}: ItemsProps<T>): JSX.Element {
  const { product } = usePlatform();
  const isPaces = product === EProductType.PACES;
  const handleStatusUpdate =
    (currentStatus?: Nullable<EUserLearningStatus>) =>
    (newStatus: EUserLearningStatus, ids: number[]) => {
      if (onStatusChange) {
        return onStatusChange(newStatus, ids, currentStatus);
      }
    };

  return (
    <ItemsListCantainer className={clsx({ 'no-bottom': noBottom })}>
      <ItemRow name={title} onClick={onTitleClick?.(Number(id))} />
      {showFallback ? (
        <FilterFallback>No results found</FilterFallback>
      ) : (
        <>
          {itemsMap
            ? itemsMap.map(([key, items]) =>
                items.length ? (
                  <Section
                    className={clsx({ 'no-bottom': noBottom })}
                    key={key}
                  >
                    <ItemRow
                      count={items.length}
                      header
                      items={items}
                      onStatusChange={handleStatusUpdate(items[0].status)}
                      status={items[0].status}
                      video={video}
                    />
                    {items.map(
                      ({ id, name, status, title, demo, osceStation }) => {
                        const itemName = isPaces
                          ? getPacesLibraryName({
                              hiddenName: osceStation?.hiddenName,
                              shownName: osceStation?.name,
                              name,
                              title,
                              userViewed:
                                status === EUserLearningStatus.COMPLETED,
                            })
                          : name || title;

                        return (
                          <ItemRow
                            id={Number(id)}
                            items={items}
                            key={id}
                            name={itemName}
                            onClick={navigate(Number(id))}
                            onStatusChange={handleStatusUpdate(status)}
                            status={status}
                            useInDemo={demo}
                            video={video}
                          />
                        );
                      }
                    )}
                  </Section>
                ) : null
              )
            : null}
        </>
      )}
    </ItemsListCantainer>
  );
}

export default Items;
