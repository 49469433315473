import React from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import { Box } from '@mui/system';

import { Logo } from 'components/Logo';
import { SampleBox } from 'components/Demo';
import { AppBar, Toolbar } from '../../../components/Layout/LayoutStyles';
import { useMockTestStateValues } from '../mockTestsState';
import { useDemo } from 'Auth';
import {
  QuestionsCount,
  QuestionsCountBox,
  RightControls,
} from './MockTestsLayoutStyles';
import { QuestionsIcon } from 'components/Icons';

export const MockTestsTopBar = ({ ...props }: AppBarProps): JSX.Element => {
  const { timer, questionCount } = useMockTestStateValues();
  const isDemo = useDemo();

  return (
    <AppBar className="mock-test-mode" position="fixed" {...props}>
      <Toolbar sx={({ spacing }) => ({ padding: spacing(0, 0) })}>
        <Logo className="mock-test-mode" />
        <RightControls>
          {/* TODO create updated demo box for new topbar */}
          {isDemo && <Box>demo</Box>}
          <SampleBox />
          {timer}
          {questionCount ? (
            <QuestionsCountBox>
              <QuestionsIcon />
              <QuestionsCount className="label" component="span">
                Question:
              </QuestionsCount>
              <QuestionsCount component="span">{questionCount}</QuestionsCount>
            </QuestionsCountBox>
          ) : null}
        </RightControls>
      </Toolbar>
    </AppBar>
  );
};
