import React from 'react';
import { ArcElement, Chart } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@mui/system';

Chart.register(ArcElement);

interface ProgressChartProps {
  correct: number;
  incorrect: number;
  total: number;
  incomplete: number;
}

const options = {
  cutout: '87%',
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

const SIZE = 48;

const progressPlugin = (percentage: number, textColor: string) => ({
  id: 'progress',
  beforeDraw: (chart: Chart) => {
    const { ctx, height, width } = chart;

    ctx.restore();
    ctx.font = '16px Moderat-Regular';
    ctx.fillStyle = textColor;
    ctx.textBaseline = 'middle';
    const text = `${percentage.toFixed(0)}%`,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  },
});

const ProgressChart = ({
  correct,
  incorrect,
  total,
  incomplete,
}: ProgressChartProps) => {
  const { palette } = useTheme();

  const data = {
    datasets: [
      {
        data: [correct, incorrect, incomplete],
        backgroundColor: [
          palette.success.light,
          palette.error.light,
          palette.stroke.main,
        ],
        borderWidth: 0,
        borderRadius: 48,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      height={SIZE}
      options={options}
      plugins={[
        progressPlugin(
          total ? (correct / total) * 100 : 0,
          palette.text.primary
        ),
      ]}
      redraw
      width={SIZE}
    />
  );
};

export default ProgressChart;
