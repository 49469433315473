import { CellAllignment, CellValue } from 'types';

export type PrescriptionAnswerValue =
  | string
  | string[]
  | number
  | PrescriptionOption;

export interface PrescriptionOption {
  value: number;
  label: string;
}

export type PrescriptionFieldNames =
  | 'drug'
  | 'dose'
  | 'route'
  | 'frequency'
  | 'duration';

export type PrescriptionField = {
  [key in PrescriptionFieldNames]?: PrescriptionAnswerValue;
};

export type MultiAnswerValues = string | CellValue;

export enum MultiAnswerTableKey {
  Medicine = 'Medicine',
  Dose = 'Dose',
  Route = 'Route',
  Frequency = 'Frequency',
  A = 'A',
  B = 'B',
}

export interface MultiAnswerColumn {
  key: MultiAnswerTableKey;
  label: string;
  align: CellAllignment;
}
