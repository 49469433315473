import React from 'react';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { EProductType } from '@quesmed/types-rn/models';
import { useMutation } from '@apollo/client';
import {
  CREATE_PRESET,
  ICreatePresetData,
  ICreatePresetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { FormField } from 'components/TextField/FormField';
import { Modal } from 'components/Modal';

const schema = Joi.object<CreatePresetInput>({
  presetName: Joi.string().required().messages({
    'string.empty': 'Preset name is required',
  }),
});

interface CreatePresetInput {
  presetName: string;
}

interface SavePresetModalProps {
  loading: boolean;
  conceptIds: number[];
  closePresetModal: () => void;
  openSavePresetModal: boolean;
  entitlementId?: EProductType;
}

const SavePresetsModal = ({
  loading,
  conceptIds,
  closePresetModal,
  openSavePresetModal,
  entitlementId,
}: SavePresetModalProps) => {
  const [createPreset] = useMutation<ICreatePresetData, ICreatePresetVar>(
    CREATE_PRESET
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<CreatePresetInput>({
    defaultValues: {
      presetName: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const handleCreatePreset = async ({ presetName }: CreatePresetInput) => {
    if (isValid && entitlementId) {
      await createPreset({
        variables: {
          data: {
            name: presetName,
            entitlementId,
            conceptIds,
          },
        },
      });
      closePresetModal();
    }
  };

  return (
    <Modal
      cancelLabel="Cancel"
      noPaddingY
      onClose={closePresetModal}
      onSubmit={handleSubmit(handleCreatePreset)}
      open={openSavePresetModal}
      showCloseButton
      sizeVariant="md"
      submitLabel="Save as preset"
      submitLoading={loading}
      title="Save as preset"
    >
      <form>
        <FormField
          control={control}
          disabled={loading}
          fullWidth
          label="Preset Name"
          name="presetName"
          placeholder="Preset Name"
          sx={{ marginTop: '8px' }}
        />
      </form>
    </Modal>
  );
};

export default SavePresetsModal;
