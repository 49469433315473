import { styled } from '@mui/material/styles';
import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { constSize } from 'utils';
import { ItemStatusLabel } from 'types';

const Dot = styled(Box)(({ theme: { palette } }) => ({
  ...constSize('8px'),
  display: 'inline-block',
  borderRadius: '3px',

  '&.confident': {
    backgroundColor: palette.success.light,
  },

  '&.to-learn': {
    backgroundColor: palette.error.light,
  },

  '&.to-review': {
    backgroundColor: palette.warning.main,
  },

  '&.revising': {
    backgroundColor: palette.primary.main,
  },

  '&.not-answered': {
    backgroundColor: palette.stroke.main,
  },
}));

interface StatusDotProps extends BoxProps {
  status: ItemStatusLabel;
}

const StatusDot = ({ status, ...props }: StatusDotProps): JSX.Element => {
  return (
    <Dot
      className={status.replace(' ', '-').toLowerCase()}
      component="span"
      {...props}
    />
  );
};

export default StatusDot;
