import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { IUserSettings } from '@quesmed/types-rn/models';

import { CalendarBlankOutlineIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import { formatDate, getRelativeTime } from 'utils';
import ExamCountdownModal from './ExamCountdownModal';
import { useIsMobile } from 'hooks';

interface ExamCountdownProps {
  examDate?: IUserSettings['productSettings']['examDate'];
}

const Container = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    gap: spacing(4),
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: '7px',
    padding: spacing(3, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',

    [breakpoints.up('md')]: {
      padding: spacing(3, 5),
      maxWidth: '485px',
    },
  })
);

const IconAndText = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
}));

const SetExamDateText = styled(Body)(({ theme: { palette } }) => ({
  cursor: 'pointer',
  color: palette.primary.main,
}));

const LargerScreenSpan = styled('span')(({ theme: { breakpoints } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'inline-block',
  },
}));

const now = new Date().getTime();

const getCopy = (
  examDate?: IUserSettings['productSettings']['examDate'],
  isMobile?: boolean
) => {
  if (!examDate) {
    return 'Start by setting exam date.';
  }
  if (examDate < now) {
    return "Time's up! Exam deadline reached.";
  }

  return `Your ${
    isMobile ? '' : formatDate(examDate, 'Do MMM')
  } exam is ${getRelativeTime(examDate, null)} away.`;
};

const ExamCountdown = ({
  examDate: sourceDate,
}: ExamCountdownProps): JSX.Element => {
  const { isMobile } = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);

  const handleSetExamDateClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container onClick={handleSetExamDateClick}>
        <IconAndText>
          <CalendarBlankOutlineIcon />
          {getCopy(sourceDate, isMobile)}
        </IconAndText>
        <SetExamDateText bold>
          {sourceDate && sourceDate >= now ? (
            <>
              Edit&nbsp;
              <LargerScreenSpan>exam date</LargerScreenSpan>
            </>
          ) : (
            'Set up'
          )}
        </SetExamDateText>
      </Container>
      {open ? (
        <ExamCountdownModal
          examDate={sourceDate}
          onClose={onClose}
          open={open}
        />
      ) : null}
    </>
  );
};

export default ExamCountdown;
