import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { SelectionHeading } from 'components/ExerciseBuilder';

interface IHistoryCardHeader {
  onSearch?: (text: string) => void;
}

const Header = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(0, 6),

  [breakpoints.up('md')]: {
    padding: 0,
  },
}));

const HistoryCardHeader = ({ onSearch }: IHistoryCardHeader) => (
  <Header>
    <SelectionHeading
      loading={false}
      noSideBorders
      onSearch={onSearch}
      searchLabel="Search"
      subtitle="Easily access and review your previous exercises."
      title="Set History"
    />
  </Header>
);

export default HistoryCardHeader;
