import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { localStorageTyped } from 'utils';
import { useSubscription } from 'Auth';

const useRedirect = () => {
  const navigate = useNavigate();
  const data = useSubscription();
  const { products } = data || {};
  const { getItem, removeItem } = localStorageTyped<string>('redirectLink');

  const removeRedirectLink = useCallback(() => {
    const redirectLink = getItem();

    if (redirectLink) {
      removeItem();
    }
  }, [getItem, removeItem]);

  const redirect = useCallback(() => {
    const redirectLink = getItem();

    if (redirectLink) {
      navigate(redirectLink);

      if (products) {
        removeItem();
      }
    }
  }, [getItem, navigate, removeItem, products]);

  return { redirect, removeRedirectLink };
};

export default useRedirect;
