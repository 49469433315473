import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { Modal } from 'components/Modal';
import { FormField } from 'components/TextField';
import { Rating } from 'components/Rating';
import { useUpdateUserSettings } from 'hooks';

const StyledInputWrapper = styled(Box)(({ theme: { spacing } }) => ({
  '& .MuiFormControl-root': {
    marginTop: spacing(6),
  },
}));

interface CancelationInput {
  rating: number | null;
  comment?: string;
}

const schema = Joi.object<CancelationInput>({
  rating: Joi.number().min(1).max(10).messages({
    'number.base': 'Rating is required',
  }),
  comment: Joi.string().allow('').optional().max(255).messages({
    'string.max': 'Comment can include up to 255 characters',
  }),
});

const ProductFeedbackModal = () => {
  const [open, setOpen] = useState(true);
  const { updateProductFeedback } = useUpdateUserSettings();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<CancelationInput>({
    defaultValues: {
      rating: null,
      comment: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const onSubmit = ({ comment, rating }: CancelationInput) => {
    updateProductFeedback(
      { rating: rating as number, feedback: comment || '' },
      {
        successCallback: () => {
          setOpen(false);
        },
        successMsg: 'Thank you for your feedback!',
      }
    );
  };

  const handleClose = () => {
    updateProductFeedback(
      {},
      {
        successCallback: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleRatingChange = (val: number | null) => {
    setValue('rating', val, { shouldValidate: true });
  };

  return open ? (
    <Modal
      maxWidth="md"
      noPaddingY
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      open
      showCloseButton
      sizeVariant="lg"
      submitDisabled={!isValid}
      submitLabel="Send Feedback"
      title="How likely are you to recommend Quesmed to a friend?"
    >
      <Rating className="margin-bottom" onRatingChange={handleRatingChange} />
      <Box>Any particular feedback?</Box>
      <StyledInputWrapper>
        <FormField
          control={control}
          fullWidth
          label="Comment"
          multiline
          name="comment"
          rows={5}
          type="text"
        />
      </StyledInputWrapper>
    </Modal>
  ) : null;
};

export default ProductFeedbackModal;
