import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Caption, H6 } from 'components/Typography';
import {
  EventRepeatImage,
  EventUpcomingImage,
  NumbersImage,
  ReadinessScoreImage,
} from 'components/Illustrations';
import { localStorageTyped } from 'utils';

const InfoContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gap: spacing(3),

  [breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  },
}));

const InfoItem = styled(Box)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.background.default,
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: '7px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacing(2),
  flex: 1,
  gap: spacing(0.5),
}));

const InfoItemDetails = styled(H6)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1),
}));

interface HeatmapInfoProps {
  avgQuestions: number;
  avgScore: number;
  currentStreak: number;
  longestStreak: number;
}

const { getItem } = localStorageTyped<string>('heatmapColorScheme');

const HeatmapInfo = ({
  avgQuestions,
  avgScore,
  currentStreak,
  longestStreak,
}: HeatmapInfoProps) => (
  <InfoContainer>
    <InfoItem>
      <Caption>Current streak</Caption>
      <InfoItemDetails>
        <EventUpcomingImage heatmapcolourname={getItem()} />
        {currentStreak} days
      </InfoItemDetails>
    </InfoItem>
    <InfoItem>
      <Caption>Longest streak</Caption>
      <InfoItemDetails>
        <EventRepeatImage heatmapcolourname={getItem()} /> {longestStreak} days
      </InfoItemDetails>
    </InfoItem>
    <InfoItem>
      <Caption>Avg. no. of Q&apos;s</Caption>
      <InfoItemDetails>
        <NumbersImage heatmapcolourname={getItem()} />{' '}
        {Math.round(avgQuestions)} / day
      </InfoItemDetails>
    </InfoItem>
    <InfoItem>
      <Caption>Avg. score</Caption>
      <InfoItemDetails>
        <ReadinessScoreImage heatmapcolourname={getItem()} />{' '}
        {Math.round(avgScore)}%
      </InfoItemDetails>
    </InfoItem>
  </InfoContainer>
);

export default HeatmapInfo;
