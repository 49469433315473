import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserLearningStatus } from '@quesmed/types-rn/models';

import { ExerciseLayout, PreLaunchLayout } from 'components/Layout';
import { ExerciePreBuildHeader } from 'components/ExerciePreBuildHeader';
import {
  getItemsMap,
  Items,
  KnowledgeLibraryRouterParams,
  LearningMaterialsBreadcrumbs,
  OSCE_SEARCH_LABEL,
  PanelItems,
  useUpdateStationLearningStatus,
} from 'components/LearningMaterials';
import { PathName } from 'types';
import {
  SelectionCategories,
  SelectionHeading,
} from 'components/ExerciseBuilder';
import { BookshelfIcon } from 'components/Icons';
import { paths } from 'Router';
import { useOsceKnowledgeLibraryData } from './OsceKnowledgeLibrary';
import { usePrealoadImages } from 'hooks/usePreloadImages';
import { SearchItemsList } from 'components/ExerciseBuilder/SearchItemsList';

const { knowledgeLibrary } = paths;

const OsceKnowledgeLibrarySection = () => {
  const { sectionId: categoryId } = useParams<KnowledgeLibraryRouterParams>();
  const navigate = useNavigate();
  const {
    activeCategory,
    activeCategoryLabel,
    categories,
    resetSearch,
    stations,
    allStations,
    searchItems,
    searchBy,
    searchTerm,
    search,
    switchCategory,
    loading,
  } = useOsceKnowledgeLibraryData();
  const { updateStatus } = useUpdateStationLearningStatus();

  usePrealoadImages(stations);

  const handleNavigate = useCallback(
    (stationId: number) => () => {
      const entitlementId = stations.find(
        ({ id }) => Number(id) === Number(stationId)
      )?.entitlement.id;
      navigate(
        `${knowledgeLibrary.root}/chapter/${entitlementId}/${entitlementId}/${stationId}`
      );
    },
    [stations, navigate]
  );

  const handleStatusChange = useCallback(
    async (newStatus: EUserLearningStatus, ids: number[]) =>
      updateStatus(ids, newStatus),
    [updateStatus]
  );

  const stationsStatusMap = useMemo(() => getItemsMap(stations), [stations]);

  const breadcrumbs = useMemo(
    () => [
      {
        icon: BookshelfIcon,
        label: activeCategoryLabel,
      },
    ],
    [activeCategoryLabel]
  );

  return (
    <ExerciseLayout
      collapsedPanel
      controlPanelContent={
        <PanelItems
          entitlementId={Number(categoryId)}
          items={allStations}
          loading={loading}
          onSearch={search}
          resetSearch={resetSearch}
          searchBy={searchBy}
          searchItems={searchTerm ? searchItems : null}
          searchLabel={OSCE_SEARCH_LABEL}
          searchTerm={searchTerm}
          sectionId={activeCategory}
          title={activeCategoryLabel}
        />
      }
      controlPanelTitle={PathName.KnowledgeLibrary}
      exerciseContent={
        <PreLaunchLayout selection={0}>
          <ExerciePreBuildHeader
            mainHeader={PathName.KnowledgeLibrary}
            notesButton
          />
          <SelectionCategories
            activeCategory={activeCategory}
            categoryOptions={categories}
            onToggleCategory={switchCategory}
          />
          <SelectionHeading
            loading={loading}
            onSearch={search}
            resetSearch={resetSearch}
            searchLabel="Search..."
            title={<LearningMaterialsBreadcrumbs header items={breadcrumbs} />}
          />
          {searchItems ? (
            <SearchItemsList
              searchItems={searchItems}
              sectionId={categoryId}
              title="Station names"
            />
          ) : (
            <Items
              itemsMap={stationsStatusMap}
              navigate={handleNavigate}
              onStatusChange={handleStatusChange}
              showFallback={!stations?.length}
              title="Station names"
            />
          )}
        </PreLaunchLayout>
      }
    />
  );
};

export default OsceKnowledgeLibrarySection;
